import React, { useMemo } from 'react';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ImageMissingIcon } from '../icons';

type Props = {
  src: string;
  alt?: string;
  width?: number;
};

export const ProductImage: React.FC<Props> = (props) => {
  const imageWidth = useMemo(() => `${props.width}px` || '75px', [props.width]);
  const imageHeight = useMemo(() => `${props.width}px` || '75px', [props.width]);

  const containerStyles: SxProps<Theme> = useMemo(
    () => ({
      width: imageWidth,
      height: imageHeight,
      borderRadius: '6px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '& > img': {
        width: imageWidth,
        height: imageHeight,
        objectFit: 'contain',
      },
    }),
    [imageWidth, imageHeight],
  );

  return (
    <Box sx={containerStyles}>
      {props.src && <img loading="lazy" src={props.src} alt={props.alt} />}
      {!props.src && <ImageMissingIcon sx={{ fontSize: '40px' }} />}
    </Box>
  );
};
