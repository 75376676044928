import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ResponseAPIType } from '../../shared/types';

type Potential = {
  key: number;
  name: string;
};

export const useOffersPotential = (): UseQueryResult<Potential[]> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<Potential[]>(
    ['partners', 'offersPotential', currentPartnerId],
    async () => {
      const response = await httpClient.get<ResponseAPIType<Potential[]>>(
        API.offers(currentPartnerId).offersPotential(),
      );
      const fallbackPotentials = [
        { key: 0, name: '0' },
        { key: 1, name: '1-7' },
        { key: 2, name: '8-14' },
        { key: 3, name: '15-34' },
        { key: 4, name: '>34' },
      ];

      return response.data?.data.filter((s) => s.name != null) || fallbackPotentials;
    },
    {
      staleTime: 30000,
      keepPreviousData: false,
      enabled: authorized,
    },
  );
};
