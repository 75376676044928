import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { environment } from '../../environment';

export const bootstrapI18Next = () => {
  if (environment.ENV !== 'test') {
    const i18nextInstance = i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        backend: {
          loadPath: `/locales/{{lng}}/{{ns}}.json?v=${environment.VERSION}`,
        },
        debug: false,
        interpolation: { escapeValue: false },
        lng: 'en',
        fallbackLng: 'en',
        react: {
          bindI18n: 'languageChanged',
          bindI18nStore: '',
          transEmptyNodeValue: '',
          transSupportBasicHtmlNodes: true,
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
          useSuspense: true,
        },
      });

    i18nextInstance.then(async () => {
      await i18n.loadNamespaces([
        'currencies',
        'countries',
        'common',
        'wizard',
        'platforms',
        'reports',
        'offers',
        'products',
        'monitoring',
        'dashboard',
      ]);
    });

    return;
  }

  i18n.use(initReactI18next).init({
    debug: false,
    interpolation: { escapeValue: false },
    lng: 'ns',
    fallbackLng: 'ns',
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
      useSuspense: true,
    },
  });
};
