import { useTranslation } from 'react-i18next';
import {
  InputMaybe,
  OfferGridDtoFilterInput,
  OfferGridDtoSortInput,
  SortEnumTypeAnimusOffers,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useDatagridContext, useDatagridContextGraphqlTranslator } from 'modules/datagrid';
import { useExportDatagridData } from 'modules/shared';
import { FileType, PlatformChannelCode } from 'modules/shared/types';
import { useAuth } from '../../authorization';
import { useGlobalSettingsQuery } from '../../settings-sales-global';
import { OfferGridColumn, OfferGridRowKey } from '../types';

export const getOffersToExport = (columns: string[]) => {
  const parsedColumns = columns.map((x) => `${x}\n`);
  return gql`
    query Offers(
      $partnerId: Int!
      $first: Int
      $after: String
      $sort: [OfferGridDtoSortInput!]
      $filter: OfferGridDtoFilterInput
    ) {
      offers(partnerId: $partnerId, first: $first, after: $after, where: $filter, order: $sort) {
        totalCount
        nodes {
          ...offerNode
        }
        pageInfo {
          ...paginationMetaAnimus
        }
        edges {
          cursor
        }
      }
    }

    fragment offerNode on OfferGridDto {
      ${parsedColumns}
    }

    fragment paginationMetaAnimus on PageInfoAnimusOffers {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  `;
};

type Result = {
  mutate: (fileType: FileType) => Promise<void>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

type ColumnReplacement = {
  name: string;
  replacment?: string[];
};

export const useOffersExport = (
  columns: OfferGridColumn[],
  channel?: PlatformChannelCode,
  importCompatible?: boolean,
): Result => {
  const { currentPartnerId } = useAuth();
  const { visibleOrderedColumns } = useDatagridContext();
  const { data } = useGlobalSettingsQuery();
  const [, i18n] = useTranslation();

  let visibleColumns: string[];

  const gridFilterSort = useDatagridContextGraphqlTranslator<
    OfferGridColumn,
    OfferGridRowKey,
    OfferGridDtoFilterInput,
    OfferGridDtoSortInput
  >(columns, ['productName', 'asin', 'ean', 'sku', 'title']);

  if (importCompatible) {
    visibleColumns = [
      'platformCode',
      'marketplaceCode',
      'channelCode',
      'sku',
      'title',
      'priceManagementMode',
      'currencyCode',
      'fixedMinimumPrice',
      'fixedMaximumPrice',
      'fixedPrice',
    ];
    const importFilters: OfferGridDtoFilterInput[] = [
      {
        priceManagementMode: {
          neq: null,
        },
      },
    ];

    if (gridFilterSort.filter?.and)
      gridFilterSort.filter.and = [...gridFilterSort.filter.and, ...importFilters];
    else gridFilterSort.filter = { and: importFilters };
  } else {
    const columnsToReplace: ColumnReplacement[] = [
      { name: 'dimensions', replacment: ['height', 'width', 'length'] },
      { name: 'offerKey' },
      { name: '__check__' },
      { name: 'potentialRangeKey', replacment: ['potentialRangeName'] },
      { name: 'sharedOfferState', replacment: ['sharedOfferState', 'sharedOfferSubState'] },
    ];

    const offerColumnsToExport = (arr: string[]) => {
      columnsToReplace.forEach((element) => {
        if (arr.includes(element.name)) {
          arr.splice(arr.indexOf(element.name), 1, ...(element.replacment ?? []));
        }
      });

      return arr;
    };

    visibleColumns = offerColumnsToExport([...visibleOrderedColumns[0]]);
  }

  const additionalFilters = channel
    ? {
        or: [
          {
            channelCode: {
              eq: channel,
            },
          },
        ],
      }
    : {};

  const exportDatagridData = useExportDatagridData('offers');

  return {
    ...exportDatagridData,
    mutate: async (fileType) => {
      const parsedFilter: InputMaybe<OfferGridDtoFilterInput> = {
        ...gridFilterSort.filter,
        ...additionalFilters,
      };

      const parsedSort: InputMaybe<OfferGridDtoSortInput> = gridFilterSort.sort
        ? gridFilterSort.sort
        : {
            externalId: SortEnumTypeAnimusOffers.Desc,
          };

      const viewQuery = {
        query: getOffersToExport(visibleColumns),
        variables: {
          partnerId: Number(currentPartnerId),
          first: gridFilterSort.first,
          after: gridFilterSort.after,
          sort: parsedSort,
          filter: parsedFilter,
        },
      };

      return exportDatagridData.mutate({
        columns: visibleColumns,
        language: i18n.language,
        fileType: fileType,
        viewQuery: JSON.stringify(viewQuery),
        partnerBaseCurrency: data?.baseCurrency || 'EUR',
      });
    },
  };
};
