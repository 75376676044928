import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ChevronSelectIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.74 5.841L9.49699 1.669C9.86699 1.259 9.833 0.627 9.423 0.257C9.239 0.092 9 0 8.753 0H1.24499C0.692995 0 0.244995 0.448 0.244995 1C0.244995 1.247 0.336995 1.485 0.501995 1.669L4.253 5.84C4.62299 6.25 5.25399 6.284 5.665 5.914C5.691 5.891 5.71499 5.866 5.739 5.84L5.74 5.841Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
