import * as echarts from 'echarts/core';
import langPL from 'echarts/lib/i18n/langPL';

export const initMonitoringChart = (
  node: HTMLDivElement,
  language: string,
): echarts.EChartsType => {
  if (language === 'PL') {
    echarts.registerLocale('PL', langPL);
  }

  const chart = echarts.init(node, undefined, {
    renderer: 'svg',
    locale: language,
  });

  return chart;
};
