import React, { useCallback } from 'react';
import { Show } from 'components';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { useCommonTranslations } from 'modules/shared';
import { PlatformType } from 'modules/shared/types';
import { MODULE_NAVIGATION_BAR_HEIGHT, nethansaPalette } from 'theme';
import { Box, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ClaimDefenderComponent } from '../../../authorization';
import { usePlatformsContext, usePlatformsSettingsQuery } from '../../hooks';
import { ManageButtonMenu } from './components/manage-button-menu';

export const PlatformsTabs: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const {
    tab: [tab, setTab],
  } = usePlatformsContext();

  const platformsQuery = usePlatformsSettingsQuery();
  const activePlatforms = (platformsQuery.data || []).filter((x) => x.isActive);

  const globalSettingsQuery = useGlobalSettingsQuery();

  const isWizardConfigured = globalSettingsQuery.data?.isConfigured || false;
  const isFetched = globalSettingsQuery.isFetched;

  const rootStyles: SxProps<Theme> = {
    position: 'sticky',
    zIndex: 1,
    top: MODULE_NAVIGATION_BAR_HEIGHT,
    height: '60px',
    backgroundColor: 'nethansa.main.light',
    borderBottom: `2.5px solid ${nethansaPalette.background.title}`,
  };

  const itemStyles: (isActive: boolean) => SxProps<Theme> = useCallback(
    (isActive) => ({
      px: 3,
      minWidth: '160px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '12px',
      height: '100%',
      backgroundColor: isActive ? 'nethansa.background.title' : 'nethansa.main.light',
      cursor: 'pointer',
      transition: 'background-color 0.3s, color 0.3s',
      letterSpacing: '0.22px',
      color: isActive ? 'text.primary' : 'nethansa.main.gray',
      userSelect: 'none',
    }),
    [],
  );

  const handleTabClick = useCallback(
    (isActive: boolean, tabNext: PlatformType) => () => isActive ? setTab(tabNext) : () => null,
    [setTab],
  );

  return (
    <Stack sx={rootStyles} direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" sx={{ height: '100%' }}>
        {activePlatforms.map((x) => (
          <Box
            key={x.platformCode}
            sx={itemStyles(tab === x.platformCode)}
            onClick={handleTabClick(x.isActive, x.platformCode)}
          >
            {tCommon(`platform-code-to-name.${x.platformCode}`)}
          </Box>
        ))}
      </Stack>

      <Box sx={{ mx: 4 }}>
        <ClaimDefenderComponent allowed={'SETTINGS_SALES_INTELLIGENCE_PLATFORMS_EDIT'}>
          <Show when={isFetched && isWizardConfigured}>
            <ManageButtonMenu />
          </Show>
        </ClaimDefenderComponent>
      </Box>
    </Stack>
  );
};
