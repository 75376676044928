import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryUpdateProductTagsArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

const amazonBulkAction = gql`
  query updateProductTags(
    $partnerId: String!
    $tagsToAdd: [String!]!
    $tagsToRemove: [String!]!
    $where: ProductGridDtoFilterInput
  ) {
    updateProductTags(
      partnerId: $partnerId
      where: $where
      tagsToAdd: $tagsToAdd
      tagsToRemove: $tagsToRemove
    ) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

type InputType = Omit<QueryUpdateProductTagsArgs, 'partnerId'>;

export const useBulkTagsMutation = (): UseMutationResult<Query, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryUpdateProductTagsArgs, 'partnerId'>;
    return await graphqlClient.request<QueryUpdateProductTagsArgs>(amazonBulkAction, {
      partnerId: currentPartnerId || '0',
      where: data.where,
      tagsToAdd: data.tagsToAdd,
      tagsToRemove: data.tagsToRemove,
    });
  });
};
