import React from 'react';
import { CardForm, Show } from 'components';
import { ClaimDefenderComponent } from '../authorization';
import { SectionIntegrations, SectionIntegrationsSkeleton } from './components';
import { IntegrationContextProvider } from './contexts';
import { useCarriersListQuery } from './hooks';

export const IntegrationsPage: React.FC = () => {
  const carriersListQuery = useCarriersListQuery();
  const hasAllData = carriersListQuery.isFetched;

  return (
    <ClaimDefenderComponent showInfoBanner allowed={'SETTINGS_CARRIERS_INTEGRATIONS_EDIT'}>
      <IntegrationContextProvider>
        <CardForm>
          <Show when={hasAllData} fallback={<SectionIntegrationsSkeleton />}>
            <SectionIntegrations />
          </Show>
        </CardForm>
      </IntegrationContextProvider>
    </ClaimDefenderComponent>
  );
};
