import React from 'react';
import { ButtonMenu, MenuItemExpander } from 'components';
import { MoreIcon } from 'components/icons';
import { CarrierName, CountryCode } from 'modules/shared/types';
import { ListItem, ListItemText, MenuItem, Typography } from '@mui/material';

type RenderItemOptions = {
  name: string;
  code: CountryCode;
  handleRemove: (countryCode: CountryCode) => void;
  carriersToMove: CarrierName[];
  renderNotIntegrateButton?: boolean;
  moveToCarrier: (country: CountryCode, carrier: CarrierName | 'notIntegrated') => void;
  labels: {
    remove: string;
    notIntegrated: string;
    changeCarrierTo: string;
  };
};

export const renderCountryItem = (params: RenderItemOptions) => {
  return (
    <ListItem
      secondaryAction={
        <ButtonMenu
          edgeIcon="end"
          iconButton
          buttonContent={<MoreIcon sx={{ fontSize: '16px' }} />}
        >
          {(params.carriersToMove.length > 0 || params.renderNotIntegrateButton) && (
            <MenuItemExpander
              menu={
                <>
                  {params.carriersToMove.map((carrierName) => (
                    <MenuItem
                      sx={{ pl: 2.5 }}
                      key={carrierName}
                      onClick={() => params.moveToCarrier(params.code, carrierName)}
                    >
                      {carrierName}
                    </MenuItem>
                  ))}
                  {params.renderNotIntegrateButton && (
                    <MenuItem
                      sx={{ pl: 2.5 }}
                      onClick={() => params.moveToCarrier(params.code, 'notIntegrated')}
                    >
                      {params.labels.notIntegrated}
                    </MenuItem>
                  )}
                </>
              }
            >
              {params.labels.changeCarrierTo}
            </MenuItemExpander>
          )}
          <MenuItem onClick={() => params.handleRemove(params.code)}>
            {params.labels.remove}
          </MenuItem>
        </ButtonMenu>
      }
    >
      <ListItemText>
        <Typography variant="h5">{params.name}</Typography>
      </ListItemText>
    </ListItem>
  );
};
