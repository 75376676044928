import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from '../../../../common/api';
import { useAuth } from '../../../authorization';
import { ResponseAPIDataStatusErrorType, ResponseAPIDataStatusType } from '../../../shared/types';
import { DatagridViewType } from '../../types';
import { useDatagridViewContext } from '../use-datagrid-view-context';

type InputType = {
  name: string;
  public: boolean;
  position: number;
  gridConfiguration: string;
};

export const useViewAddMutation = (): UseMutationResult<
  ResponseAPIDataStatusType<DatagridViewType<string>>,
  ResponseAPIDataStatusErrorType,
  InputType
> => {
  const { currentPartnerId } = useAuth();
  const { tableId } = useDatagridViewContext();

  return useMutation(['add-view'], async (body: InputType) => {
    const response = await httpClient.post<ResponseAPIDataStatusType<DatagridViewType<string>>>(
      API.views().viewAdd(currentPartnerId, tableId),
      body,
    );
    return response.data;
  });
};
