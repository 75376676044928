import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';
import { PriceSettingsInputType } from '../types';
import { usePriceSettingsQuery } from './use-price-settings.query';

type InputType = PriceSettingsInputType;

export const usePriceSettingsMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();
  const { refetch } = usePriceSettingsQuery();

  return useMutation(
    async (body) => {
      return await envelope(httpClient.put(API.offers(currentPartnerId).priceSettings(), body));
    },
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
};
