import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Chip, Typography } from '@mui/material';

type Props = {
  id?: number;
  count?: number;
  title: string;
  showTagsNumber?: boolean;
  color?: string;
  onDelete?: () => void | undefined;
};

export const Tag2: React.FC<Props> = (props) => {
  const { showTagsNumber, title, count, onDelete, color } = props;

  return (
    <Chip
      variant="outlined"
      size="small"
      sx={{
        borderRadius: '6px',
        borderWidth: '2px',
        backgroundColor: color ?? 'nethansa.background.info',
        borderColor: 'nethansa.line.light',
        mr: 1,
        mb: 1,
      }}
      avatar={
        <Avatar
          sx={{
            background: 'none',
            display: showTagsNumber ? '' : 'none',
          }}
        >
          <Box sx={{ color: 'gray' }}>{count}</Box>
        </Avatar>
      }
      onDelete={onDelete}
      deleteIcon={<CloseIcon fontSize={'small'} sx={{ fill: 'nethansa.line.light' }} />}
      label={<Typography variant="h5Bold">{title}</Typography>}
    />
  );
};
