import jwtDecode from 'jwt-decode';

enum AUTH {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  TOKEN_EXPIRED = 'tokenExpired',
  REFRESH_TOKEN_EXPIRED = 'refreshTokenExpired',
  PARTNER_ID = 'TEMP_partnerId',
}

enum OFFER {
  FILTER = 'offersFilter',
}

export const setCredentials = (token: string, refreshToken: string) => {
  window.localStorage.setItem(AUTH.TOKEN, token);
  window.localStorage.setItem(AUTH.REFRESH_TOKEN, refreshToken);
  window.localStorage.setItem(AUTH.TOKEN_EXPIRED, String(Date.now() + 36000));
  window.localStorage.setItem(AUTH.REFRESH_TOKEN_EXPIRED, String(Date.now() + 3600000));
};

export const setTempPartnerId = (partnerId: string) => {
  window.localStorage.setItem(AUTH.PARTNER_ID, partnerId);
};

export const setExternalIdInOfferFilter = (externalId: string) => {
  window.sessionStorage.setItem(
    OFFER.FILTER,
    JSON.stringify([{ field: 'externalId', operator: 'equals', value: externalId }]),
  );
};

export const getCredentials = () => ({
  token: window.localStorage.getItem(AUTH.TOKEN),
  refreshToken: window.localStorage.getItem(AUTH.REFRESH_TOKEN),
  tokenExpired: parseInt(window.localStorage.getItem(AUTH.TOKEN_EXPIRED) || '0'),
  refreshTokenExpired: parseInt(window.localStorage.getItem(AUTH.REFRESH_TOKEN_EXPIRED) || '0'),
});

export const getTempPartnerId = () => window.localStorage.getItem('TEMP_partnerId') || null;

export const clearCredentials = () => {
  window.localStorage.removeItem(AUTH.TOKEN);
  window.localStorage.removeItem(AUTH.REFRESH_TOKEN);
  window.localStorage.removeItem(AUTH.TOKEN_EXPIRED);
  window.localStorage.removeItem(AUTH.REFRESH_TOKEN_EXPIRED);
};

export const clearTempPartnerId = () => window.localStorage.removeItem('TEMP_partnerId');

export const hasValidToken = () => {
  const credentials = getCredentials();
  return !(!credentials || credentials.tokenExpired < Date.now());
};

export const hasValidCredentials = () => {
  const credentials = getCredentials();
  return !(!credentials || credentials.refreshTokenExpired < Date.now());
};

type DecodedTokenType = {
  sub: string;
  email: string;
  iat: string;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string[];
  partnerIds: string | string[];
  unique_name: string;
};

export const decodeToken = (token: string) => {
  if (!token) throw new Error('Can not decode token, because token is missing');
  const decoded = jwtDecode<DecodedTokenType>(token);

  return {
    userId: decoded.sub,
    email: decoded.email,
    roles: decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
    partnerIds: decoded.partnerIds,
  };
};
