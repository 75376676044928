import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from '../../../common/api';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';
import { GlobalsType } from '../types/globals.type';

type InputType = GlobalsType;

export const useGlobalsMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    return await envelope(httpClient.put(API.salesIntelligence(currentPartnerId).globals(), body));
  });
};
