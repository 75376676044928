import React, { useCallback, useContext } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { SelectField } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { ConnectionStatus } from 'modules/shared/enums';
import { usePlatformMarketsQuery } from 'modules/wizard-platforms-connector';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  MenuItemProps,
  Stack,
  Typography,
} from '@mui/material';
import {
  OnboardingWizardContext,
  WizardFormStepType,
} from '../contexts/onboarding-wizard.provider';
import { useWizardPlatformsQuery } from '../hooks/platforms';
import { useProductsWizardMutation } from '../hooks/products/use-products-wizard.mutation';
import { FileOption } from './products/file-option';
import { OtherOption } from './products/other-option';

type ImportType = 'FROM_FILE' | 'FROM_OFFERS' | 'FROM_API';

export type AddProductsFormProps = {
  importType?: ImportType | string;
  fileToUpload?: File;
  fileHasBeenUploaded?: boolean;
  externalImportStarted?: boolean;
};

export const ProductsWizardStep: React.FC = () => {
  const [t] = useTranslation('wizard');
  const [pt] = useTranslation('platforms');
  const { tCommon } = useCommonTranslations();
  const context = useContext(OnboardingWizardContext);
  const productsMutation = useProductsWizardMutation();
  const amazonMarketplaces = usePlatformMarketsQuery('amzeu');
  const platforms = useWizardPlatformsQuery();
  const selectedMarketplace = platforms.data?.some(
    (x) =>
      x.platformCode === 'kauf' &&
      x.connectionStatus === ConnectionStatus[ConnectionStatus.connected],
  )
    ? pt('kaufland.website')
    : pt(
        `amazon.markets.website.${
          amazonMarketplaces.data?.filter((m) => !!m.isManagedByClipperon)[0]?.code || 'DE'
        }`,
      );

  const { control, handleSubmit, getValues } = useForm<AddProductsFormProps>({
    defaultValues: {
      importType: '',
      fileToUpload: undefined,
      fileHasBeenUploaded: false,
    },
  });

  const importTypeValue = useWatch({
    control,
    name: 'importType',
  });

  const externalImportStartedValue = useWatch({
    control,
    name: 'externalImportStarted',
  });

  const handleBackClick = useCallback(() => {
    context.goToFormStep(WizardFormStepType['vat-and-shipping']);
  }, []);

  const submit = (values: AddProductsFormProps) => {
    productsMutation.mutate(
      {
        importMethod: values.importType || '',
      },
      {
        onSuccess: () => {
          context.goToFormStep(WizardFormStepType.summary);
        },
      },
    );
  };

  const views: { [key in ImportType]: JSX.Element | null } = {
    FROM_FILE: <FileOption control={control} getValues={getValues} />,
    FROM_OFFERS: (
      <Box>
        <Typography
          variant="h5"
          sx={{ maxWidth: '672px', whiteSpace: 'pre-line', lineHeight: '18px' }}
        >
          <Trans i18nKey="add-products.from-offers.description" t={t}>
            text
            <a href="mailto:integrations@nethansa.com">email</a>
            text
          </Trans>
        </Typography>
      </Box>
    ),
    FROM_API: <OtherOption />,
  };

  const selectOptions: MenuItemProps<'li', { value: ImportType }>[] = [
    { value: 'FROM_FILE', children: t('add-products.select.from-file') },
    {
      value: 'FROM_OFFERS',
      children: t('add-products.select.from-offers', { marketplace: selectedMarketplace }),
    },
    { value: 'FROM_API', children: t('add-products.select.other') },
  ];

  return (
    <form onSubmit={handleSubmit(submit)}>
      <DialogContent>
        <Box sx={{ py: 4, backgroundColor: 'background.paper', px: 4 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            {t('add-products.header')}
          </Typography>

          <Box sx={{ px: 4 }}>
            <Controller
              control={control}
              name="importType"
              rules={{
                required: {
                  value: true,
                  message: t('form.importType.validation.required'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <SelectField
                    disabled={productsMutation.isLoading || !!externalImportStartedValue}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    tooltipContent={t('form.importType.tooltip.__html')}
                    placeholder={t('form.importType.placeholder')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    options={selectOptions}
                    sx={{ m: 0, mb: 2, minWidth: '400px' }}
                  />
                );
              }}
            />
            {views[importTypeValue as ImportType] || null}
          </Box>
        </Box>
        <DialogActions sx={{ p: 2, borderTop: '1px solid #DDE8F1' }}>
          {productsMutation.isLoading && (
            <Stack justifyContent="center" sx={{ marginRight: 1 }}>
              <SpinnerIcon sx={{ fontSize: '43px', marginTop: '2px', marginBottom: '-2px' }} />
            </Stack>
          )}
          <Button variant="text" disabled={productsMutation.isLoading} onClick={handleBackClick}>
            {tCommon('back')}
          </Button>
          <Button type="submit" disabled={productsMutation.isLoading}>
            {tCommon('next')}
          </Button>
        </DialogActions>
      </DialogContent>
    </form>
  );
};
