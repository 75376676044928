import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import flatten from 'lodash.flatten';
import { applyExternalAccess, sortBy } from '../../../common/utils';
import { DatagridViewsGroupType, DatagridViewType } from '../types';
import {
  useViewEditGridConfigurationMutation,
  useViewsQuery,
  useViewsReorderMutation,
} from './api';
import { useDatagridContext } from './use-datagrid-context';
import { useDatagridViewContext } from './use-datagrid-view-context';

type ViewId = {
  id: string;
};

type ReturnType = {
  currentView: DatagridViewType | null;
  groupedViews: DatagridViewsGroupType[];
  views: DatagridViewType[];
  visibleViews: DatagridViewType[];
  createAndActivateTab: (newTab: DatagridViewType<string>) => void;
  getNextPositionNumber: () => number;
  reorderViews: (tabs: ViewId[]) => void;
  updateViewsOrder: () => void;
  changeViewsVisibleStatus: (tabs: (ViewId & { visible: boolean })[]) => void;
  changeView: (tab: ViewId) => void;
  resetView: () => void;
  saveView: () => void;
  saveAsNewView: () => void;
};

// Use the hook to manage views
export const useDatagridViewsManager = (): ReturnType => {
  const [t] = useTranslation('common');

  const gridViewContext = useDatagridViewContext();
  const gridContext = useDatagridContext();

  const viewsQuery = useViewsQuery(gridViewContext.tableId);
  const viewEditGridConfigurationMutation = useViewEditGridConfigurationMutation();
  const viewsReorderMutation = useViewsReorderMutation();

  if (!gridViewContext.tableId) {
    throw new Error('useReportViewsManager can not work outside GridViewContext');
  }

  const queryKeys = useMemo(() => ['views', gridViewContext.tableId], [gridViewContext.tableId]);

  const { data } = useViewsQuery(gridViewContext.tableId);

  const viewsPersisted = useMemo(() => data || [], [JSON.stringify(data)]);

  const mapViewTab = useCallback((view: DatagridViewsGroupType) => {
    if (view.predefined) {
      return view.tableViews.map((x) => ({
        ...x,
        name: t(`views.list.${x.name}` as 'views.list.all-columns'),
      }));
    }
    return view.tableViews;
  }, []);

  const flattTabs = useCallback(
    (views: DatagridViewsGroupType[], showOnlyVisible = true) => {
      return flatten<DatagridViewType>(views.map(mapViewTab))
        .filter((x) => !showOnlyVisible || x.visible)
        .sort(sortBy('position'));
    },
    [mapViewTab],
  );

  const persistViews = (updatedTabs: DatagridViewsGroupType[]) => {
    const flattedUpdatedTabs = flattTabs(updatedTabs, false);
    viewsReorderMutation.mutate({
      reorderViewCommands: flattedUpdatedTabs.map((x) => ({
        viewId: x.id,
        position: x.position,
        visible: x.visible,
      })),
    });
  };

  const createAndActivateTab = async (newTab: DatagridViewType<string>) => {
    await queryClient.cancelQueries(queryKeys);
    const previousViews = queryClient.getQueryData<DatagridViewsGroupType[]>(queryKeys);

    const updatedViews = previousViews?.map((x) => {
      if (x.name === 'Personal') {
        x.tableViews.push({
          ...newTab,
          gridConfiguration: JSON.parse(newTab.gridConfiguration),
        });
      }
      return x;
    });

    queryClient.setQueryData<DatagridViewsGroupType[]>(queryKeys, () => updatedViews || []);
    gridViewContext.changeView(String(newTab.id));
  };

  const reorderViews = async (tabs: ViewId[]): Promise<void> => {
    await queryClient.cancelQueries(queryKeys);
    const previousViews = queryClient.getQueryData<DatagridViewsGroupType[]>(queryKeys);

    const updatedTabs: DatagridViewsGroupType[] =
      previousViews?.map((x) => ({
        ...x,
        tableViews: x.tableViews.map((y) => {
          const index = tabs.findIndex((x) => x.id === y.id);
          if (index < 0) return { ...y, position: y.position };
          return { ...y, position: index };
        }),
      })) || [];

    queryClient.setQueryData<DatagridViewsGroupType[]>(queryKeys, () => updatedTabs);

    persistViews(updatedTabs);
  };

  const updateViewsOrder = async (): Promise<void> => {
    await queryClient.cancelQueries(queryKeys);
    const previousViews = queryClient.getQueryData<DatagridViewsGroupType[]>(queryKeys);

    const flattenViews = flattTabs(previousViews || [], false).map((x, index) => ({
      ...x,
      position: index,
    }));

    await reorderViews(flattenViews.map((x) => ({ id: x.id })));
    return;
  };

  const changeViewsVisibleStatus = async (tabs: (ViewId & { visible: boolean })[]) => {
    await queryClient.cancelQueries(queryKeys);
    const previousViews = queryClient.getQueryData<DatagridViewsGroupType[]>(queryKeys);

    const updatedTabs: DatagridViewsGroupType[] =
      previousViews?.map((x) => ({
        ...x,
        tableViews: x.tableViews.map((y) => {
          const value = tabs.find((x) => y.id === x.id);
          if (value && value.id === y.id) {
            return { ...y, visible: value.visible };
          }
          return y;
        }),
      })) || [];

    queryClient.setQueryData<DatagridViewsGroupType[]>(queryKeys, () => updatedTabs);

    persistViews(updatedTabs);
  };

  const flattenTabs = useMemo(
    () => flattTabs(viewsPersisted),
    [flattTabs, JSON.stringify(viewsPersisted)],
  );

  const allFlattenTabs = useMemo(
    () => flattTabs(viewsPersisted, false),
    [flattTabs, viewsPersisted],
  );

  const changeView = (tab: ViewId) => {
    gridViewContext.changeView(String(tab.id));
  };

  const resetView = () => {
    gridViewContext.resetView();
  };

  const getCurrentGridConfiguration = (): string => {
    const pinnedColumnsParsed = {
      left: (gridContext.pinnedColumns[0]?.left || [])?.filter((x) => x !== '__check__'),
      right: (gridContext.pinnedColumns[0]?.right || [])?.filter((x) => x !== '__check__'),
    };

    const body = {
      filter: gridContext.filter[0],
      sort: gridContext.sort[0],
      pinnedColumns: pinnedColumnsParsed,
      visibleColumns: gridContext.visibleColumns[0],
      visibleOrderedColumns: gridContext.visibleOrderedColumns[0],
    };

    return JSON.stringify(body);
  };

  const getNextPositionNumber = () => {
    return Math.max(...flattenTabs.map((x) => x.position)) + 1;
  };

  const saveView = () => {
    viewEditGridConfigurationMutation.mutate(
      {
        gridConfiguration: getCurrentGridConfiguration(),
        viewId: gridViewContext.currentView?.id || '',
      },
      {
        onSuccess: () => {
          viewsQuery.refetch();
        },
      },
    );
  };

  const saveAsNewView = () => {
    gridViewContext.createViewModal.change({
      id: gridViewContext.currentView?.id || '',
      name: `Copy of ${gridViewContext.currentView?.name || ''}`,
      tableId: gridViewContext.currentView?.tableId || '',
      gridConfiguration: getCurrentGridConfiguration(),
    });
  };

  useEffect(() => {
    applyExternalAccess({
      getDataGridConfig: getCurrentGridConfiguration,
    });
  }, [getCurrentGridConfiguration]);

  return useMemo(
    () => ({
      currentView: gridViewContext.currentView,
      groupedViews: viewsPersisted,
      visibleViews: flattenTabs,
      views: allFlattenTabs,
      createAndActivateTab,
      getNextPositionNumber,
      reorderViews: reorderViews,
      updateViewsOrder: updateViewsOrder,
      changeViewsVisibleStatus,
      changeView: changeView,
      resetView: resetView,
      saveView,
      saveAsNewView,
    }),
    [
      flattenTabs.length,
      viewsPersisted,
      flattenTabs,
      createAndActivateTab,
      getNextPositionNumber,
      reorderViews,
      updateViewsOrder,
      changeViewsVisibleStatus,
      changeView,
      gridViewContext.currentView,
      resetView,
      saveView,
      saveAsNewView,
    ],
  );
};
