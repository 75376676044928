import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { Alert, Box, Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import {
  OnboardingWizardContext,
  WizardFormStepType,
} from '../contexts/onboarding-wizard.provider';
import { useWizardGlobalsMutation, useWizardGlobalsQuery } from '../hooks/globals';

type FormProps = {
  country: string;
  currency: string;
  formError?: string;
};

export const GlobalsWizardStep: React.FC = () => {
  const [t] = useTranslation('wizard');
  const { tCommon } = useCommonTranslations();
  const context = useContext(OnboardingWizardContext);

  const globalsQuery = useWizardGlobalsQuery();
  const globalsMutation = useWizardGlobalsMutation();

  const { control, handleSubmit, setValue } = useForm<FormProps>({
    defaultValues: {
      country: globalsQuery.data?.mainShippingCountry || '',
      currency: globalsQuery.data?.baseCurrency || '',
    },
  });

  const onSubmit = (values: FormProps) => {
    globalsMutation.mutate(
      { mainShippingCountry: values.country, baseCurrency: values.currency },
      {
        onSuccess: async () => {
          globalsQuery.refetch().then();
          context.goToFormStep(WizardFormStepType.platforms);
        },
      },
    );
  };

  useEffect(() => {
    if (globalsQuery.data?.mainShippingCountry && globalsQuery.data?.baseCurrency) {
      setValue('country', globalsQuery.data?.mainShippingCountry);
      setValue('currency', globalsQuery.data?.baseCurrency);
    }
  }, [globalsQuery.data?.mainShippingCountry, globalsQuery.data?.baseCurrency]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box sx={{ px: 5, py: 4, backgroundColor: 'background.paper' }}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            {t('set-globals-step.header')}
          </Typography>
          <Stack gap={4}>
            <Alert severity="info" sx={{ mb: 2 }}>
              {t('set-globals-step.alert-info')}
            </Alert>
            <Controller
              control={control}
              name="country"
              rules={{
                required: {
                  value: true,
                  message: t('form.country.validation.required'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <SelectField
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    tooltipContent={t('form.country.tooltip.__html')}
                    label={t('form.country.label')}
                    placeholder={t('form.country.placeholder')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    options={
                      globalsQuery.data?.countryCodes.map((x) => ({
                        value: x.id,
                        children: x.label,
                      })) || []
                    }
                    sx={{ mb: 1, ml: 0, width: '400px' }}
                    disabled={globalsQuery.isLoading || globalsMutation.isLoading}
                    isLoading={globalsQuery.isLoading}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="currency"
              rules={{
                required: {
                  value: true,
                  message: t('form.currency.validation.required'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <SelectField
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ ml: 0, width: '400px' }}
                    tooltipContent={t('form.currency.tooltip.__html')}
                    label={t('form.currency.label')}
                    placeholder={t('form.currency.placeholder')}
                    options={
                      globalsQuery.data?.currencyCodes.map((x) => ({
                        value: x.id,
                        children: x.label,
                      })) || []
                    }
                    disabled={globalsQuery.isLoading || globalsMutation.isLoading}
                    isLoading={globalsQuery.isLoading}
                  />
                );
              }}
            />
          </Stack>
          {globalsMutation.error?.message && (
            <Box sx={{ mt: 2, mb: 0 }}>
              <Alert severity="error">{t('form.globals.error')}</Alert>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, borderTop: '1px solid #DDE8F1' }}>
        {globalsMutation.isLoading && (
          <Stack justifyContent="center" sx={{ marginRight: 1 }}>
            <SpinnerIcon sx={{ fontSize: '43px', marginTop: '2px', marginBottom: '-2px' }} />
          </Stack>
        )}
        <Button disabled={globalsQuery.isLoading || globalsMutation.isLoading} type="submit">
          {tCommon('next')}
        </Button>
      </DialogActions>
    </form>
  );
};
