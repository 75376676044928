import { QueryClient } from 'react-query';
import { environment } from '../../environment';
import { EVENT_REACT_LINK_SETTINGS_CHANGED, eventDispatcher } from '../events';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      refetchOnWindowFocus: false,
      retryDelay: 5000,
      retry: environment.ENV !== 'test' ? 3 : 0,
    },
  },
});

// MUTATION DEFAULT
export const settingsDefaultMutationKeys = ['user-settings-updated'];

queryClient.setMutationDefaults(settingsDefaultMutationKeys, {
  onSuccess: () => {
    eventDispatcher(EVENT_REACT_LINK_SETTINGS_CHANGED);
  },
});
