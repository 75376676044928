import React, { ReactElement } from 'react';
import { CloseButton, Dialog, FormButtonsGroup } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, DialogContent, Divider, Typography } from '@mui/material';

type Props = React.PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  logoElement?: ReactElement;
  header?: string;
  submit?: () => void;
  loading?: boolean;
}>;

export const IntegrationGenericModal: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      sx={{ '&  .MuiDialogContent-root': { minWidth: '496px' } }}
    >
      <Box sx={{ position: 'relative', m: 4, mb: 2 }}>
        <Box>{props.logoElement}</Box>
        <CloseButton onClick={props.onClose} sx={{ position: 'absolute', right: '0', top: 0 }} />
      </Box>
      <DialogContent>
        <form onSubmit={props.submit} autoComplete="off">
          <Box sx={{ px: 4, py: 2 }}>
            <Typography variant="h2" sx={{ mb: 6 }}>
              {props.header}
            </Typography>
            {props.children}
          </Box>
          <Divider />
          <FormButtonsGroup sx={{ py: 2, px: 3 }} isLoading={props.loading}>
            <Button size="small" variant="text" onClick={props.onClose} disabled={props.loading}>
              {tCommon('cancel')}
            </Button>
            <Button type="submit" size="small" disabled={props.loading}>
              {tCommon('connect')}
            </Button>
          </FormButtonsGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
};
