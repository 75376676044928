import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Show } from 'components';
import { CreditCardIcon, SpinnerIcon } from 'components/icons';
import { SubscriptionType } from 'modules/settings-billing-data/types';
import { nethansaPalette } from 'theme';
import { Button, Stack, Typography } from '@mui/material';
import { PaymentCardModal } from './payment-card-modal';

type Props = {
  subscription?: SubscriptionType;
};

export const PaymentCardPage: React.FC<Props> = (props) => {
  const [t] = useTranslation('settings');
  const [pending, setPending] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
  };

  const twoLastDigits = (num: number | string) => {
    const s = num.toString();
    if (!s.length) return 'xx';
    if (s.length === 2) return s;
    if (s.length === 1) return `0${s}`;
    return s.slice(-2);
  };

  const expiresDate = `${twoLastDigits(
    props.subscription?.cardDetail?.expiresMonth || 'xx',
  )}/${twoLastDigits(props.subscription?.cardDetail?.expiresYear || 'xx')}`;

  const isPaymentFail: boolean =
    !!props.subscription?.payments.length &&
    props.subscription?.payments[0].paymentStatus !== 'Paid';

  useEffect(() => {
    if (
      props.subscription?.cardDetail?.isCardValid &&
      !!props.subscription?.payments.length &&
      (props.subscription?.payments[0].paymentStatus === 'PaymentInProgress' ||
        props.subscription?.payments[0].paymentStatus === 'Pending')
    ) {
      setPending(true);
    }
  }, []);

  return (
    <>
      <Show when={pending}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', mt: 3 }}>
          <SpinnerIcon sx={{ fill: nethansaPalette.main.gray, height: '32px', width: 'auto' }} />
          <Typography variant="h5">{t('payments.credit-card.state.processing-card')}</Typography>
        </Stack>
      </Show>

      <Show when={props.subscription?.cardDetail !== null && !pending}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, mt: 3 }}>
          <CreditCardIcon
            sx={{
              fill:
                !props.subscription?.cardDetail?.isCardValid || isPaymentFail
                  ? nethansaPalette.main.watermelon
                  : nethansaPalette.main.accent,
            }}
          />
          <Typography
            variant="h5"
            color={
              !props.subscription?.cardDetail?.isCardValid || isPaymentFail
                ? nethansaPalette.main.watermelon
                : nethansaPalette.main.dark
            }
          >
            <Trans
              i18nKey="payments.credit-card.state.card"
              t={t}
              values={{
                type: props.subscription?.cardDetail?.type == 'mc' ? 'MasterCard' : 'VISA',
                endingNumber: props.subscription?.cardDetail?.endingNumber,
                expiresDate: expiresDate,
              }}
            />
          </Typography>
        </Stack>
        <Show when={!props.subscription?.cardDetail?.isCardValid || isPaymentFail}>
          <Typography variant="h5" sx={{ color: nethansaPalette.main.watermelon, mt: 1 }}>
            {t('payments.credit-card.state.card-not-valid')}
          </Typography>
        </Show>
      </Show>

      <Show
        when={
          (!props.subscription?.cardDetail ||
            !props.subscription?.cardDetail.isCardValid ||
            isPaymentFail) &&
          !pending
        }
      >
        <>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              mt: 3,
            }}
          >
            <Button size="small" onClick={handleModalOpen}>
              {t('payments.credit-card.button.add-card')}
            </Button>
          </Stack>
          <PaymentCardModal
            open={open}
            onClose={handleModalClose}
            title={t('payments.credit-card.button.add-card')}
            subscription={props.subscription}
          />
        </>
      </Show>
    </>
  );
};
