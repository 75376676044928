import React from 'react';
import { Show, Tooltip } from 'components';
import { Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type PropsType = {
  label: string;
  tooltip?: string;
  isSmall?: boolean;
  sx?: SxProps<Theme>;
};

export const ColumnHeader: React.FC<PropsType> = (props) => {
  return (
    <Stack sx={{ flexDirection: 'row', gap: 0.5, ...(props.sx || {}) }}>
      <Typography
        variant={props.isSmall ? 'h5' : 'h3'}
        sx={{ whiteSpace: 'pre-line', fontWeight: 700, lineHeight: '18px' }}
      >
        {props.label}
      </Typography>
      <Show when={!!props.tooltip}>
        <Tooltip content={props.tooltip} />
      </Show>
    </Stack>
  );
};
