import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, FormButtonsGroup, NethansaLogo, SelectField } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, Stack } from '@mui/material';
import { useAuth } from './hooks';

type FormProps = {
  partnerId: string;
};

export const PartnerSelectorModal: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const { setPartnerId, partnerIds } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormProps>({
    defaultValues: {
      partnerId: partnerIds[0] || '',
    },
  });

  const submit = async (data: FormProps) => {
    setPartnerId(data.partnerId);
  };

  return (
    <Dialog open={true}>
      <form onSubmit={handleSubmit(submit)}>
        <Box sx={{ p: 4, minWidth: '400px' }}>
          <Stack alignItems="center">
            <NethansaLogo key="splash" sx={{ mb: 5, display: 'flex' }} />
          </Stack>
          <Stack spacing="20px" sx={{ mb: 1 }}>
            <Controller
              control={control}
              name="partnerId"
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => {
                return (
                  <SelectField
                    disabled={isSubmitting}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={'Partner ID'}
                    placeholder={'Select partner id'}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 2, flex: 1 }}
                    options={partnerIds.map((x) => ({ children: x, value: x }))}
                  />
                );
              }}
            />
          </Stack>
          <FormButtonsGroup isLoading={isSubmitting}>
            <Button type="submit" size="small" disabled={isSubmitting}>
              {tCommon('confirm')}
            </Button>
          </FormButtonsGroup>
        </Box>
      </form>
    </Dialog>
  );
};
