import * as echarts from 'echarts/core';
import { CurrencyCode } from 'generated/graphql';
import { nethansaPalette } from 'theme';
import { MonitoredOfferHistory } from '../types';

export const setMonitoringChartOption = (
  node: HTMLDivElement,
  data: MonitoredOfferHistory[],
  t: (label: string) => string,
  option: number,
  currency?: CurrencyCode,
) => {
  const chart = echarts.getInstanceByDom(node);

  const baseOption: echarts.EChartsCoreOption = {
    tooltip: { trigger: 'axis' },
    grid: {
      show: true,
      backgroundColor: nethansaPalette.background.title,
      top: '32',
      bottom: '40',
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter: {
          day: '{dayStyle|{d}-{MMM}}',
        },
        rich: {
          dayStyle: {
            fontWeight: '700',
            color: nethansaPalette.main.accent,
          },
        },
      },
    },
    yAxis: { type: 'value' },
  };

  chart?.setOption(baseOption);

  if (option === 1) {
    chart?.setOption(
      {
        legend: { show: false },
        tooltip: { trigger: 'axis' },
        series: [
          {
            type: 'line',
            smooth: true,
            itemStyle: {
              color: nethansaPalette.button.blueMain,
            },
            name: t('detail-panel.menu.option-1'),
            data: data.map((x) => [new Date(x.updatePricingData || ''), x.ranking?.rank]),
          },
        ],
      },
      { replaceMerge: ['series', 'tooltip', 'legend'] },
    );
  }

  if (option === 2) {
    chart?.setOption(
      {
        legend: {},
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => (value ? `${value.toFixed(2)} ${currency}` : '-'),
        },
        series: [
          {
            type: 'line',
            smooth: true,
            itemStyle: {
              color: nethansaPalette.other.blueDeep,
            },
            name: t('column.lowestPrice.label'),
            data: data.map((x) => [new Date(x.updatePricingData || ''), x.lowestPrice]),
          },
          {
            type: 'line',
            smooth: true,
            itemStyle: {
              color: nethansaPalette.other.greenDeep,
            },
            name: t('column.buyBoxInfoPrice.label'),
            data: data.map((x) => [new Date(x.updatePricingData || ''), x.buyBoxInfo?.price]),
          },
        ],
      },
      { replaceMerge: ['series', 'tooltip', 'legend'] },
    );
  }

  if (option === 3) {
    chart?.setOption(
      {
        legend: { show: false },
        tooltip: { trigger: 'axis' },
        series: [
          {
            type: 'line',
            smooth: true,
            name: t('detail-panel.menu.option-3'),
            data: data?.map((x) => [new Date(x.updatePricingData || ''), x.numberOfOffers]),
            itemStyle: {
              color: nethansaPalette.status.opAfter.dark,
            },
          },
        ],
      },
      { replaceMerge: ['series', 'tooltip', 'legend'] },
    );
  }
};
