import React from 'react';
import { NAVIGATION_APP_HEIGHT } from 'theme';
import { MODULE_NAVIGATION_BAR_HEIGHT } from 'theme';
import { Box, Skeleton, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { emptyArray } from '../../../../common/utils';

export const PlatformsTabsSkeleton: React.FC = () => {
  const rootStyles: SxProps<Theme> = {
    position: 'sticky',
    zIndex: 3,
    top: `calc(${NAVIGATION_APP_HEIGHT} + ${MODULE_NAVIGATION_BAR_HEIGHT})`,
    height: '60px',
    backgroundColor: 'common.white',
    boxShadow: (theme) => `inset 0px 3px 5px ${theme.palette.nethansa.line.light}`,
  };

  return (
    <Stack sx={rootStyles} direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" sx={{ height: '100%', px: 2 }} spacing={1}>
        {emptyArray(2).map((v, i) => (
          <Box key={i}>
            <Skeleton width={'160px'} height="100%" />
          </Box>
        ))}
      </Stack>
      <Box sx={{ mx: 4 }}>
        <Skeleton width="120px" height="46px" />
      </Box>
    </Stack>
  );
};
