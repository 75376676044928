import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImportIcon } from 'components/icons';
import { DatagridWrapper, ToolbarTable } from 'modules/datagrid';
import { TableOffersContext } from 'modules/offers/components/table-offers/contexts';
import { useProductsColumnsDefinition, useProductsQuery } from 'modules/products/hooks';
import { ImportProductsModal } from 'modules/products/modals';
import { ProductGridRow } from 'modules/products/types';
import { Button } from '@mui/material';
import { GridRowsProp, UncapitalizedGridSlotsComponent } from '@mui/x-data-grid-pro';
import { TableProductsExport } from '../table-products-export';
import { TableProductsFooterBulkActions } from '../table-products-footer-bulk-actions';

export const TableProducts: React.FC = () => {
  const columns = useProductsColumnsDefinition();

  const { data, isFetching } = useProductsQuery(columns);
  const [open, setImportOpen] = useState<boolean>(false);

  const [tProducts] = useTranslation('products');

  const rows: GridRowsProp<ProductGridRow> = (data?.products?.nodes || []) as ProductGridRow[];
  // // Important: never pass components to Grid without useMemo
  const components: Partial<UncapitalizedGridSlotsComponent> = useMemo(() => {
    return {
      toolbar: () => (
        <ToolbarTable columns={columns}>
          <Button
            color="flat"
            size="small"
            data-testid="import-button"
            onClick={() => setImportOpen(true)}
            startIcon={<ImportIcon sx={{ height: 24, width: 'auto' }} />}
          >
            {tProducts('modals.buttons.import')}
          </Button>
          <ImportProductsModal isOpen={open} onClose={() => setImportOpen(false)} />

          <TableProductsExport />
        </ToolbarTable>
      ),
      footer: () => <TableProductsFooterBulkActions />,
    };
  }, [columns, open]);

  return (
    <TableOffersContext.Provider value={null}>
      <DatagridWrapper
        enableVirtualization
        rowHeight={96}
        checkboxSelection
        loading={isFetching}
        getRowId={(row) => row.id}
        rows={rows}
        columns={columns}
        components={components}
        disableCenterValign
        forcedPinnedColumns={{
          left: ['__check__'],
        }}
      />
    </TableOffersContext.Provider>
  );
};
