import React from 'react';
import { ChevronIcon } from 'components/icons';
import { IconButton } from '@mui/material';

type Props = {
  onClick: () => void;
  isHidden?: boolean;
  isExpanded?: boolean;
};

export const HideToolbarsButton: React.FC<Props> = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      size="small"
      sx={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: props.isHidden ? 'none' : 'block',
      }}
    >
      <ChevronIcon
        sx={{
          fontSize: 'inherit',
          transform: props.isExpanded ? 'rotate(0.25turn)' : 'rotate(-0.25turn)',
          '& *': {
            stroke: (theme) => theme.palette.nethansa.main.gray,
            strokeWidth: 1.5,
          },
          transition: 'transform 0.2s ease-in-out',
        }}
      />
    </IconButton>
  );
};
