import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormAlert, FormButtonsGroup } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { Button, TextField } from '@mui/material';
import { useOPTCodeVerification } from '../../../../hooks';

type Props = {
  onSuccess: () => void;
};

export const FormCodeOptVerification: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('settings');

  const optCodeVerification = useOPTCodeVerification();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{ code: string }>({
    defaultValues: {
      code: '',
    },
  });

  const codeError = (optCodeVerification.error?.errors?.code?.[0] ||
    'wrongTwoFaCode') as 'wrongTwoFaCode';

  const submit = useCallback(
    (body: { code: string }) => {
      return optCodeVerification.mutate(
        {
          isTwoFactorEnabled: true,
          code: body.code,
        },
        {
          onSuccess: props.onSuccess,
        },
      );
    },
    [optCodeVerification.mutate],
  );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Controller
        control={control}
        name="code"
        rules={{
          required: {
            value: true,
            message: t('form.verification-code.validation.required'),
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              disabled={isSubmitting}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              label={t('form.verification-code.label')}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              sx={{ mt: 5, mb: 2, flex: 1, width: '100%' }}
            />
          );
        }}
      />
      <FormAlert
        show={optCodeVerification.isError}
        isSuccess={false}
        isError={optCodeVerification.isError}
        errorMessage={t(`form.verification-code.validation.${codeError}`)}
      />
      <FormButtonsGroup sx={{ mt: 1 }}>
        <Button type="submit" size="small" disabled={isSubmitting}>
          {tCommon('verify')}
        </Button>
      </FormButtonsGroup>
    </form>
  );
};
