import { getGridDateOperators, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';
import { useCommonTranslations } from '../../shared';
import {
  DateRangePickerOperator,
  NumberInputOperator,
  SelectDateEnumOperator,
} from '../components';

export const useDatagridDateOperators = (): GridFilterOperator[] => {
  const { tCommon } = useCommonTranslations();

  const lastInDaysOperator: GridFilterOperator = {
    label: tCommon('datagrid.operators.date.last'),
    value: 'last',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: { value?: number | string }): boolean => {
        if (!params.value || params.value === 0) return false;
        return Number(params.value) >= Number(filterItem.value);
      };
    },
    InputComponent: NumberInputOperator,
    InputComponentProps: { type: 'date' },
  };

  const periodOperator: GridFilterOperator = {
    label: tCommon('datagrid.operators.date.period'),
    value: 'period',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: { value?: [Date | null, Date | null] }): boolean => {
        return Boolean(params.value?.[0]) || Boolean(params.value?.[1]);
      };
    },
    InputComponent: SelectDateEnumOperator,
  };

  const betweenOperator: GridFilterOperator = {
    label: tCommon('datagrid.operators.date.between'),
    value: 'between',
    InputComponentProps: {
      sx: {
        width: '320px',
      },
    },
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: { value?: string }): boolean => {
        return Boolean(params.value);
      };
    },
    InputComponent: DateRangePickerOperator,
  };

  return [...getGridDateOperators(), lastInDaysOperator, periodOperator, betweenOperator];
};
