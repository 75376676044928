import { DatagridEventName } from './events';

export type DatagridEventHandlerResult = {
  clear: () => void;
};

export const datagridEventHandler = (
  event: DatagridEventName,
  callback: () => void,
): DatagridEventHandlerResult => {
  window.addEventListener(event, callback);

  return {
    clear: () => window.removeEventListener(event, callback),
  };
};
