import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ImportIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.713 12.2011C16.1003 11.8073 16.0949 11.1742 15.7011 10.787C15.3073 10.3997 14.6742 10.4051 14.287 10.7989L12.9965 12.1112L13 6.50063C13.0003 5.94834 12.5529 5.50035 12.0006 5.5C11.4483 5.49966 11.0003 5.94709 11 6.49938L10.9965 12.0611L9.70124 10.7871C9.3075 10.3998 8.67436 10.405 8.28707 10.7988C7.89979 11.1925 7.90502 11.8256 8.29876 12.2129L11.1243 14.9921C11.2959 15.2952 11.6212 15.4998 11.9944 15.5C12.0038 15.5 12.0132 15.4999 12.0226 15.4996C12.0345 15.5 12.0464 15.5001 12.0583 15.5C12.3236 15.4978 12.5771 15.3903 12.763 15.2011L15.713 12.2011ZM7 16.5C6.44772 16.5 6 16.9477 6 17.5C6 18.0523 6.44772 18.5 7 18.5H17C17.5523 18.5 18 18.0523 18 17.5C18 16.9477 17.5523 16.5 17 16.5H7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
