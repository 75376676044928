import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const WalletIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="54"
      height="54"
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0H54V54H0z"
                transform="translate(-243 -216) translate(230 158) translate(13 58)"
              />
              <g fill="#000" fillRule="nonzero">
                <path
                  d="M36.229 14.384H7.192C3.22 14.384 0 11.164 0 7.192S3.22 0 7.192 0h29.037c.828 0 1.5.672 1.5 1.5v11.384c0 .828-.672 1.5-1.5 1.5zM7.192 3C4.877 3 3 4.877 3 7.192c0 2.315 1.877 4.192 4.192 4.192h27.537V3H7.192z"
                  transform="translate(-243 -216) translate(230 158) translate(13 58) translate(8 7)"
                />
                <path
                  d="M36.229 40.431H7.192C3.222 40.427.004 37.209 0 33.239V7.192c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v26.047c.004 2.313 1.879 4.188 4.192 4.192h27.537V12.884c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v26.047c0 .828-.672 1.5-1.5 1.5z"
                  transform="translate(-243 -216) translate(230 158) translate(13 58) translate(8 7)"
                />
                <path
                  d="M28.498 30.381c-2.651 0-4.8-2.149-4.8-4.8s2.149-4.8 4.8-4.8 4.8 2.149 4.8 4.8c-.003 2.65-2.15 4.797-4.8 4.8zm0-6.6c-.994 0-1.8.806-1.8 1.8s.806 1.8 1.8 1.8 1.8-.806 1.8-1.8-.806-1.799-1.8-1.799v-.001zM8.149 14.384c-.828 0-1.5-.672-1.5-1.5V7.192c0-.828.672-1.5 1.5-1.5h28.08c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5H9.649v4.192c0 .828-.672 1.5-1.5 1.5z"
                  transform="translate(-243 -216) translate(230 158) translate(13 58) translate(8 7)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
