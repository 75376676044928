import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from '../../../common/api';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';
import { PlatformInput } from '../types';

type InputType = PlatformInput;

export const usePlatformMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    const parsedData = {
      ...body,
      marketplaces: body.marketplaces
        .filter((x) => x.isAvailable)
        .map((x) => {
          const isActiveByCountryCode: boolean =
            body.marketplaces.filter((y) => y.countryCode === x.countryCode)?.[0]?.isActive ||
            false;
          return {
            ...x,
            isActive: isActiveByCountryCode,
          };
        }),
    };

    return await envelope(
      httpClient.put(API.salesIntelligence(currentPartnerId).platforms(), parsedData),
    );
  });
};
