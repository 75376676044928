import { GridLogicOperator, GridSlotsComponentsProps } from '@mui/x-data-grid-pro';
import { nethansaPalette } from '../../../../theme';

export const datagridWrapperComponentsProps: GridSlotsComponentsProps = {
  baseButton: {
    size: 'small',
    color: 'flat',
  },
  columnsPanel: {
    sort: 'asc',
    sx: {
      mt: 2.5,
      mb: 1,
      mx: 1,
    },
  },
  filterPanel: {
    logicOperators: [GridLogicOperator.And, GridLogicOperator.Or],
    columnsSort: 'asc',
    filterFormProps: {
      logicOperatorInputProps: {
        variant: 'outlined',
        size: 'small',
        color: 'flat',
      },
      columnInputProps: {
        variant: 'outlined',
        sx: {
          mt: 'auto',
          '& > .MuiInputLabel-outlined': {
            '&.MuiInputLabel-shrink': {
              transform: 'translate(-12px, -22px) scale(1);',
            },
            fontSize: '12px',
            fontWeight: 700,
            marginLeft: 0,
            lineHeight: '14.52px',
            letterSpacing: '0.22px',
            color: nethansaPalette.main.gray,
            textTransform: 'uppercase',
          },
          '& .MuiOutlinedInput-root': {
            height: '40px',
            '& .MuiInputBase-input': {
              fontSize: '14px',
              fontWeight: 500,
              borderRadius: '6px',
              '&.Mui-disabled': {
                '& + .MuiOutlinedInput-notchedOutline': {
                  borderColor: nethansaPalette.button.grayLight,
                },
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              top: 0,
              legend: {
                display: 'none',
              },
            },
          },
        },
      },
      operatorInputProps: {
        variant: 'outlined',
        sx: {
          mt: 'auto',
          '& .MuiInputLabel-outlined': {
            transform: 'translate(-12px, -22px) scale(1);',
            fontSize: '12px',
            fontWeight: 700,
            marginLeft: 0,
            lineHeight: '14.52px',
            letterSpacing: '0.22px',
            color: nethansaPalette.main.gray,
            textTransform: 'uppercase',
          },
          '& .MuiOutlinedInput-root': {
            height: '40px',
            '& .MuiInputBase-input': {
              fontSize: '14px',
              fontWeight: 500,
              borderRadius: '6px',
              '&.Mui-disabled': {
                '& + .MuiOutlinedInput-notchedOutline': {
                  borderColor: nethansaPalette.button.grayLight,
                },
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              top: 0,
              legend: {
                display: 'none',
              },
            },
          },
        },
      },
      valueInputProps: {
        variant: 'outlined',
        InputComponentProps: {
          variant: 'outlined',
          size: 'small',
        },
        sx: {
          mt: 'auto',
          '& .MuiInputLabel-standard': {
            fontSize: '12px',
            fontWeight: 700,
            marginLeft: 0,
            lineHeight: '14.52px',
            letterSpacing: '0.22px',
            color: nethansaPalette.main.gray,
            textTransform: 'uppercase',
            top: '4px',
            left: 0,
          },
          '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
              top: 0,
              legend: {
                display: 'none',
              },
            },
            '& input[type="date"]': {
              cursor: 'text',
              '&::-webkit-calendar-picker-indicator': {
                display: 'block',
                fontSize: '20px',
                cursor: 'pointer',
              },
            },
          },
        },
      },
      deleteIconProps: {
        sx: {
          '& .MuiSvgIcon-root': { color: 'common.black' },
        },
      },
    },
    sx: {
      py: 1,
      pt: 1,
      // Customize inputs using css selectors
      '& .MuiDataGrid-filterForm': { p: 2, pt: 3, pb: 1, alignItems: 'flex-start' },
      '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
      '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 200, marginTop: 0 },
      '& .MuiDataGrid-filterFormOperatorInput': { mr: 2, width: 200, marginTop: 0 },
      '& .MuiDataGrid-filterFormValueInput': { width: '250px', minWidth: '200px', marginTop: 0 },
      '& .MuiDataGrid-filterFormDeleteIcon': { height: '40px', justifyContent: 'center' },
    },
  },
};
