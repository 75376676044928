import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useActiveLocation } from 'modules/shared';
import { Link, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = React.PropsWithChildren<{
  href: string;
  exact?: boolean;
  hidden?: boolean;
  handleNavigationTabHover?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onReady?: (element: HTMLAnchorElement) => void;
  onExit?: () => void;
}>;

export const NavigationTabsItem: React.FC<Props> = (props) => {
  const self = useRef<HTMLAnchorElement>(null);
  const active = useActiveLocation(props.href, props.exact);

  const sx: SxProps<Theme> = useMemo(
    () => ({
      px: 4,
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      fontWeight: '700',
      color: active ? 'text.primary' : 'nethansa.main.gray',
      '&:hover': {
        color: active ? 'text.primary' : 'nethansa.main.gray',
      },
      '&:after': {
        position: 'absolute',
        content: '""',
        height: '20px',
        width: '1px',
        right: 0,
        backgroundColor: 'nethansa.line.light',
      },
      '&:last-of-type': {
        '&:after': {
          display: 'none',
        },
      },
    }),
    [active],
  );

  useLayoutEffect(() => {
    if (!self.current || !props.onReady || !active) return;
    props.onReady(self.current);
  }, [active]);

  return (
    <Link
      ref={self}
      href={props.href}
      sx={sx}
      className={active ? 'NavigationTabsItemActive' : ''}
      onMouseEnter={props.handleNavigationTabHover || (() => null)}
      onMouseLeave={props.onExit || (() => null)}
    >
      <Typography variant="h2">{props.children}</Typography>
    </Link>
  );
};
