import { useTranslation } from 'react-i18next';
import { Show, TooltipWrapper } from 'components';
import { SharedOfferStateEnum } from 'generated/graphql';
import { DatagridCellFlag } from 'modules/datagrid';
import { CanExecuteAction } from 'modules/offers/actions-validator';
import { getStatusColorGroup } from 'modules/offers/components/table-utils';
import { OfferToPauseOrActivate } from 'modules/offers/hooks/use-get-offers-to-pause-or-activate.query';
import { OfferAction } from 'modules/offers/types/offer-action';
import { nethansaPalette } from 'theme';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';

export interface Props {
  offers: OfferToPauseOrActivate[];
  selectedMarketplaceCode: string;
  selectedSharedOfferState: SharedOfferStateEnum;
  action: OfferAction;
}

export const OffersToPauseOrActivateList: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  const [tPlatform] = useTranslation('platforms');
  const translationModalNode = props.action === OfferAction.Pause ? 'pauseModal' : 'activateModal';

  return (
    <Stack
      divider={<Divider sx={{ width: '100%', backgroundColor: nethansaPalette.line.light }} />}
    >
      {props.offers
        ?.filter((offer) => offer.marketplaceCode !== props.selectedMarketplaceCode)
        .map((offer: OfferToPauseOrActivate) => (
          <Stack
            key={offer.offerId}
            sx={{ flexDirection: 'row', height: '28px', my: 0.5, alignItems: 'center', gap: 2 }}
          >
            <Box sx={{ width: '160px' }}>
              <DatagridCellFlag
                value={offer.marketplaceCode || 'unknown'}
                label={
                  tPlatform(`amazon.markets.website.${offer.marketplaceCode as 'DE'}`) || 'unknown'
                }
                href={(offer?.url ? offer.url : '') || ''}
              />
            </Box>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
              <Show when={!CanExecuteAction(props.action, offer.stateName, offer.subStatesNames)}>
                <TooltipWrapper
                  content={t(
                    `modals.${translationModalNode}.errors.someOffersWithTheSameSkuAreInNotValidState`,
                  )}
                >
                  <Box component="span">
                    <IconButton color="error" sx={{ width: '20px', height: '20px', pl: 0 }}>
                      <ErrorOutlineIcon />
                    </IconButton>
                  </Box>
                </TooltipWrapper>
              </Show>
              <Typography
                variant="h5"
                sx={{
                  textTransform: 'lowercase',
                  color:
                    nethansaPalette.status[`${getStatusColorGroup(offer.stateName) as 'dead'}`]
                      .dark,
                }}
              >
                {t(`offer-status.main.${offer.stateName as 'ACTIVE'}`)}
              </Typography>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};
