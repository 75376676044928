import React from 'react';
import { Stack, Typography } from '@mui/material';
import { CloseButton } from '../close-button';
import { Tooltip } from '../tooltip';
import { Dialog } from './index';

export type BaseModalProps = React.PropsWithChildren<{
  open: boolean;
  onClose: (confirm: boolean) => void;
  header: string;
  minWidth?: string;
  tooltip?: string;
  dangerHeader?: boolean;
  disableCloseOnBackdropClick?: boolean;
}>;

export const DialogBaseWrapper: React.FC<BaseModalProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => (props.disableCloseOnBackdropClick ? null : props.onClose(false))}
    >
      <Stack
        sx={{ minWidth: props.minWidth ?? '500px', p: '32px' }}
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography
          variant="h1"
          sx={{ mr: 4, color: props.dangerHeader ? 'error.main' : 'nethansa.main.dark' }}
        >
          {props.header}
          {props.tooltip && <Tooltip sx={{ ml: '8px' }} content={props.tooltip} />}
        </Typography>
        <CloseButton onClick={() => props.onClose(false)} />
      </Stack>
      {props.children}
    </Dialog>
  );
};
