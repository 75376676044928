import React from 'react';
import { Outlet } from 'react-router-dom';
import { environment } from 'environment';
import { Navbar } from './components/navbar';

export const LayoutRoot: React.FC = () => (
  <React.Fragment>
    {environment.ENV === 'development' && <Navbar />}
    <Outlet />
  </React.Fragment>
);
