import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryUpdateMonitoredOffersTagsArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared';

const amazonBulkAction = gql`
  query updateMonitoredOffersTags(
    $partnerId: String!
    $tagsToAdd: [String!]!
    $tagsToRemove: [String!]!
    $where: MonitoredOfferGridDtoFilterInput
  ) {
    updateMonitoredOffersTags(
      partnerId: $partnerId
      where: $where
      tagsToAdd: $tagsToAdd
      tagsToRemove: $tagsToRemove
    )
  }
`;

type InputType = Omit<QueryUpdateMonitoredOffersTagsArgs, 'partnerId'>;

export const useBulkTagsMutation = (): UseMutationResult<Query, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryUpdateMonitoredOffersTagsArgs, 'partnerId'>;
    return await graphqlClient.request<QueryUpdateMonitoredOffersTagsArgs>(amazonBulkAction, {
      partnerId: currentPartnerId || '0',
      where: data.where,
      tagsToAdd: data.tagsToAdd,
      tagsToRemove: data.tagsToRemove,
    });
  });
};
