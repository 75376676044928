import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import { ErrorMeta } from 'modules/shared/types';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { ProfileDataType } from '../types/profile-data.type';

type InputType = Omit<ProfileDataType, 'email'>;

export const useProfileDataMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    return await envelope(httpClient.put(API.profiles().profile(), body));
  });
};
