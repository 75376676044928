import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldOutlined } from 'components';
import { AddCircleIcon } from 'components/icons';
import {
  DatagridDetailPanelContent,
  DatagridPagination,
  DatagridWrapper,
  TableNoResults,
  ToolbarTable,
} from 'modules/datagrid';
import { Button } from '@mui/material';
import {
  DataGridProProps,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRowsProp,
  UncapitalizedGridSlotsComponent,
} from '@mui/x-data-grid-pro';
import { useMonitoringColumnsDefinition, useMonitoringOffersQuery } from '../hooks';
import { AddOffersModal } from '../modals';
import { MonitoredOfferRow } from '../types';
import { DetailPanelChart } from './detail-panel-chart';
import { TableMonitoringFooterBulkActions } from './table-monitoring-footer-bulk-actions';

export const TableMonitoringOffers: React.FC = () => {
  const [t] = useTranslation('monitoring');

  const columns = useMonitoringColumnsDefinition();

  const { data, isFetching } = useMonitoringOffersQuery(columns);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const rows: GridRowsProp<MonitoredOfferRow> = (data?.monitoredOffers?.nodes ||
    []) as MonitoredOfferRow[];

  // Important: never pass components to Grid without useMemo
  const components: Partial<UncapitalizedGridSlotsComponent> = useMemo(() => {
    return {
      baseTextField: TextFieldOutlined,
      toolbar: () => (
        <ToolbarTable columns={columns}>
          <Button
            size="small"
            color="flat"
            data-testid="add-offers-to-track-button"
            onClick={() => setModalOpen(true)}
            startIcon={<AddCircleIcon sx={{ height: 24, width: 'auto' }} />}
          >
            {t('button.add-offers')}
          </Button>
          <AddOffersModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            trackedOffers={isFetching ? 0 : data?.monitoredOffers?.totalCount}
          />
        </ToolbarTable>
      ),
      footer: () => <TableMonitoringFooterBulkActions />,
      noResultsOverlay: TableNoResults,
      noRowsOverlay: TableNoResults,
      pagination: DatagridPagination,
    };
  }, [columns, modalOpen, data?.monitoredOffers?.totalCount]);

  const getDetailPanelHeight = useCallback(() => 300, []);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => (
      <DatagridDetailPanelContent>
        <DetailPanelChart row={row} />
      </DatagridDetailPanelContent>
    ),
    [],
  );

  return (
    <DatagridWrapper
      enableVirtualization
      loading={isFetching}
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      components={components}
      rowHeight={96}
      checkboxSelection
      disableCenterValign
      forcedPinnedColumns={{
        left: ['__check__', 'state', GRID_DETAIL_PANEL_TOGGLE_FIELD],
      }}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
    />
  );
};
