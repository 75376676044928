import { useMutation } from 'react-query';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { useSnackbar } from '../../snackbar';
import { CurrencyCode, FileType, SchemaName } from '../types';
import { useCommonTranslations } from './use-common-translations';

type InputType = {
  viewQuery: string;
  columns: string[];
  language: string;
  fileType: FileType;
  partnerBaseCurrency: CurrencyCode;
};

export const useExportDatagridData = (schemaName: SchemaName) => {
  const { currentPartnerId } = useAuth();
  const { pushSnackbar } = useSnackbar();
  const { tCommon } = useCommonTranslations();

  return useMutation(
    async (body: InputType) => {
      await httpClient.post(API.partner(currentPartnerId).dataExport(schemaName), body);
      return;
    },
    {
      onSuccess: () => {
        pushSnackbar({
          message: tCommon('datagrid.actions.export-snackbar-success'),
          variant: 'success',
        });
      },
    },
  );
};
