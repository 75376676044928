import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

export const NoPlatformCallToAction: React.FC = () => {
  const [t] = useTranslation('settings');

  return (
    <Box sx={{ my: 2, mx: 4 }}>
      <Typography
        variant="h5"
        sx={{ '& > a': { textDecoration: 'underline', textTransform: 'uppercase' } }}
      >
        <Trans i18nKey="global-settings.no-platform" t={t}>
          text
          <br />
          text<a href="/Wizard">Link to Wizard</a> text.
        </Trans>
      </Typography>
    </Box>
  );
};
