import React, { useState } from 'react';
import {
  AlertLoader,
  Checkbox,
  Dialog,
  DialogHeader,
  SelectField,
  TextFieldNumber,
} from 'components';
import {
  AlertIcon,
  BillIcon,
  CarrierIcon,
  CheckedIcon,
  ChevronIcon,
  CloseIcon,
  DragIndicatorIcon,
  ExpandIcon,
  ExternalLinkIcon,
  EyeIcon,
  ImageMissingIcon,
  LockIcon,
  MoreIcon,
  PaymentsIcon,
  SearchIcon,
  ShieldCheckedIcon,
  ShowViewIcon,
  SpinnerIcon,
  UploadIcon,
  UserAddIcon,
  UserEditIcon,
  WalletIcon,
} from 'components/icons';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { TabsHorizontalDraggable } from '../../components/tabs-horizontal-draggable';
import { Tab } from '../../components/tabs-horizontal-draggable/types';

const longHtmlText = `
  Aliquam <strong>eget finibus</strong> ante, non <em>facilisis lectus</em>. Sed vitae dignissim est, vel aliquam tellus.
  Praesent non <span style='color: red'>nunc mollis</span>, fermentum neque at, semper arcu.
  Nullam eget est sed sem iaculis gravida <a href='https://google.com'>eget</a> vitae justo.
`;

const longHtmlTextWithImage = `
  Lorem ipsum <img src='https://picsum.photos/200/80' />
  Hello! <button>Click</button>
`;

const tabsData: Tab[] = [
  { id: '1', label: 'Grand total' },
  { id: '2', label: 'Long name custom view' },
  { id: '3', label: 'Weekly report' },
  { id: '4', label: 'Some test report' },
  { id: '5', label: 'Four' },
  { id: '6', label: 'Grand total' },
  { id: '7', label: 'Long name custom view' },
  { id: '8', label: 'Weekly report' },
  { id: '9', label: 'Some test report' },
  { id: '10', label: 'Four' },
  { id: '11', label: 'Grand total' },
  { id: '12', label: 'Long name custom view' },
  { id: '13', label: 'Weekly report' },
  { id: '14', label: 'Some test report' },
  { id: '15', label: 'Four' },
];

export const StylebookPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalLight, setOpenModalLight] = useState(false);
  const [age, setAge] = React.useState<string | undefined>('');
  const [activeTab, setActiveTab] = useState<string | number>(1);

  const handleChangeAge = (value?: string) => {
    setAge(value);
  };

  return (
    <Box sx={{ m: 5 }}>
      <Paper>
        <Stack direction="row">
          <Box
            sx={{
              minWidth: '70px',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: '12px',
                color: 'nethansa.button.grayHover',
                textAlign: 'right',
              }}
            >
              Views:
            </Typography>
          </Box>
          <Box sx={{ maxWidth: '100%' }}>
            <TabsHorizontalDraggable
              sx={{ maxWidth: 'calc(100% - 70px)' }}
              tabs={tabsData}
              activeTabId={activeTab}
              onItemClick={(id) => setActiveTab(id)}
              onOrderChange={console.log}
            />
          </Box>
        </Stack>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Typography variant="h1">H1</Typography>
        <Typography variant="h2">H2</Typography>
        <Typography variant="h3">H3</Typography>
        <Typography variant="h4">H4</Typography>
        <Typography variant="h5">H5</Typography>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <Button>Next</Button>

          <Button disabled color="secondary" variant={'contained'}>
            Primary contained
          </Button>
          <Button color="secondary" variant={'contained'}>
            Primary contained
          </Button>
          <Button variant="text">Cancel</Button>
          <Button size="small">Next</Button>
          <Button size="small" disabled>
            Next
          </Button>
          <Button size="small" color="flat" variant="contained">
            Cancel
          </Button>
          <Button disabled size="small" color="flat" variant="contained">
            Cancel
          </Button>
        </Box>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <TextField placeholder="Placeholder" disabled />
          <TextField placeholder="Placeholder" disabled value="A1CBDEFABCDEFG" />
          <TextField placeholder="Placeholder" />
          <TextField placeholder="Placeholder" value="A1CBDEFABCDEFG" />
          <TextField
            placeholder="Placeholder"
            fullWidth
            value="amzn.mws.2cccbb23-4cd5-6e78-f987-ghi6jkl5m432"
          />
        </Box>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <TextFieldNumber placeholder="Amount" sx={{ width: '120px' }} unit={'€'} precision={2} />
          <TextFieldNumber
            placeholder="Amount"
            sx={{ width: '120px' }}
            unit={'$$'}
            precision={2}
            clearable
          />
          <TextFieldNumber
            disabled
            placeholder="Amount"
            sx={{ width: '120px' }}
            unit={'$$'}
            precision={2}
            clearable
          />
        </Box>
      </Paper>

      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <FormControl>
            <Checkbox label="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />
            <Checkbox
              checked
              value
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
            />
            <Checkbox
              indeterminate
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
            />

            <Checkbox disabled label="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />
            <Checkbox
              disabled
              checked
              value
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
            />
            <Checkbox
              disabled
              indeterminate
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
            />
          </FormControl>
        </Box>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Box>
          <SelectField
            value={age}
            tooltipContent={longHtmlText}
            onChange={handleChangeAge}
            placeholder="Select age"
            label="Main Shipping country"
            options={[
              { value: 10, children: 'Ten' },
              { value: 20, children: 'Twenty' },
              { value: 30, children: 'Thirty' },
            ]}
          />
          <SelectField
            value={age}
            onChange={handleChangeAge}
            tooltipContent={longHtmlTextWithImage}
            placeholder={'Select age'}
            helperText="Message"
            label="Main Shipping country"
            options={[
              { value: '', children: <em>None</em> },
              { value: 10, children: 'Ten' },
              { value: 20, children: 'Twenty' },
              { value: 30, children: 'Thirty' },
            ]}
          />
          <SelectField
            value={age}
            onChange={handleChangeAge}
            label="Main Shipping country"
            error
            helperText="Error here!"
            options={[
              { value: '', children: <em>None</em> },
              { value: 10, children: 'Ten' },
              { value: 20, children: 'Twenty' },
              { value: 30, children: 'Thirty' },
            ]}
          />
          <SelectField
            value={age}
            disabled
            onChange={handleChangeAge}
            helperText="Some helper text"
            options={[
              { value: '', children: <em>None</em> },
              { value: 10, children: 'Ten' },
              { value: 20, children: 'Twenty' },
              { value: 30, children: 'Thirty' },
            ]}
          />
        </Box>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Stack direction="row" justifyContent="center">
          <Box sx={{ width: '80%' }}>
            <Stepper activeStep={1} alternativeLabel>
              <Step>
                <StepLabel>Set globals</StepLabel>
              </Step>
              <Step>
                <StepLabel>Connect platforms</StepLabel>
              </Step>
              <Step>
                <StepLabel>Set VAT & shipping</StepLabel>
              </Step>
              <Step>
                <StepLabel>Add products</StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Stack>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Stack spacing={2}>
          <AlertLoader>Empty</AlertLoader>
          <Alert severity="error">This is an error alert — check it out!</Alert>
          <Alert severity="warning">This is a warning alert — check it out!</Alert>
          <Alert severity="info">This is an info alert — check it out!</Alert>
          <Alert severity="success">This is a success alert — check it out!</Alert>
        </Stack>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Button onClick={() => setOpenModal(true)}>Open modal</Button>
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogHeader title="Hello World!" onClose={() => setOpenModal(false)} />
          <DialogContent>
            <p>Lorem ipsum</p>
          </DialogContent>
          <DialogActions>
            <Button size="small">cancel</Button>
            <Button size="small">Next</Button>
          </DialogActions>
        </Dialog>

        <Button onClick={() => setOpenModalLight(true)}>Open light modal</Button>
        <Dialog open={openModalLight} onClose={() => setOpenModalLight(false)}>
          <DialogHeader lightMode onClose={() => setOpenModalLight(false)} spacing="lg" />
          <DialogContent>
            <p>Lorem ipsum</p>
          </DialogContent>
          <DialogActions>
            <Button>Next</Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <Paper sx={{ my: 2, p: 2 }}>
        <Typography variant="h3">Icons</Typography>
        <Stack direction="row" flexWrap="wrap">
          <Stack alignItems="center" sx={{ p: 1 }}>
            <AlertIcon />
            <Typography variant="caption">AlertIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <BillIcon />
            <Typography variant="caption">BillIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <CarrierIcon />
            <Typography variant="caption">CarrierIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <CheckedIcon />
            <Typography variant="caption">CheckedIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <ChevronIcon />
            <Typography variant="caption">ChevronIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <CloseIcon />
            <Typography variant="caption">CloseIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <DragIndicatorIcon />
            <Typography variant="caption">DragIndicatorIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <ExpandIcon />
            <Typography variant="caption">ExpandIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <ExternalLinkIcon />
            <Typography variant="caption">ExternalLinkIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <EyeIcon />
            <Typography variant="caption">EyeIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <ImageMissingIcon />
            <Typography variant="caption">ImageMissingIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <LockIcon />
            <Typography variant="caption">LockIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <MoreIcon />
            <Typography variant="caption">MoreIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <PaymentsIcon />
            <Typography variant="caption">PaymentsIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <SearchIcon />
            <Typography variant="caption">SearchIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <ShieldCheckedIcon />
            <Typography variant="caption">ShieldCheckedIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <ShowViewIcon />
            <Typography variant="caption">ShowViewIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <SpinnerIcon />
            <Typography variant="caption">SpinnerIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <UploadIcon />
            <Typography variant="caption">UploadIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <UserAddIcon />
            <Typography variant="caption">UserAddIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <UserEditIcon />
            <Typography variant="caption">UserEditIcon</Typography>
          </Stack>
          <Stack alignItems="center" sx={{ p: 1 }}>
            <WalletIcon />
            <Typography variant="caption">WalletIcon</Typography>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};
