import { Route, Routes } from 'react-router-dom';
import { DashboardLayout, DashboardPage } from 'modules/dashboard';

export const DashboardRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route index element={<DashboardPage />} />
      </Route>
    </Routes>
  );
};
