import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { useDebounce } from '@react-hook/debounce';
import { TextFieldNumber } from '../../../../../../components';
import { useCommonTranslations } from '../../../../../shared';

type Props = GridFilterInputValueProps;

export const NumberBetweenInputOperator: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const { item, applyValue } = props;

  const [fromValue, setFromValue] = useState<number | undefined>(item.value?.[0]);
  const [toValue, setToValue] = useState<number | undefined>(item.value?.[1]);

  const [debouncedValue, setDebouncedValue] = useDebounce([fromValue, toValue], 400);

  const handleFromFilterChange = (val: number | null) => {
    const parsedValue = val === null ? undefined : val;
    setFromValue(parsedValue);
  };

  const handleToFilterChange = (val: number | null) => {
    const parsedValue = val === null ? undefined : val;
    setToValue(parsedValue);
  };

  const hasError =
    typeof fromValue === 'number' && typeof toValue === 'number' && fromValue > toValue;

  useEffect(() => {
    setDebouncedValue([fromValue, toValue]);
  }, [fromValue, toValue]);

  useEffect(() => {
    applyValue({ ...item, value: debouncedValue });
  }, [debouncedValue]);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TextFieldNumber
        fullWidth
        error={hasError}
        label={tCommon('from')}
        placeholder=""
        name="custom-number-filter-operator-from"
        value={fromValue}
        onChange={handleFromFilterChange}
        precision={2}
        omitZeroDecimal={true}
        InputLabelProps={{
          shrink: true,
          sx: {
            '&.MuiInputLabel-root': {
              transform: 'translate(-12px, -22px) scale(1)',
            },
          },
        }}
      />
      <TextFieldNumber
        fullWidth
        error={hasError}
        label={tCommon('to')}
        placeholder=""
        name="custom-number-filter-operator-to"
        value={toValue}
        onChange={handleToFilterChange}
        precision={2}
        omitZeroDecimal={true}
        InputLabelProps={{
          shrink: true,
          sx: {
            '&.MuiInputLabel-root': {
              transform: 'translate(-12px, -22px) scale(1)',
            },
          },
        }}
      />
    </Stack>
  );
};
