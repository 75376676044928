import {
  GridFilterInputMultipleSingleSelect,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { useCommonTranslations } from '../../shared';
import { DatagridListOperatorEnum } from '../consts';

export const useDatagridListOperators = (): GridFilterOperator[] => {
  const { tCommon } = useCommonTranslations();

  const containsOperator: GridFilterOperator = {
    label: tCommon('datagrid.operators.list.contain'),
    value: DatagridListOperatorEnum.LIST_CONTAINS,
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: { value?: string[] }): boolean => {
        if (!params.value || params.value.length === 0) return false;
        return params.value.length > 0;
      };
    },
    InputComponent: GridFilterInputMultipleSingleSelect,
    InputComponentProps: { size: 'small' },
  };
  const notContainsOperator: GridFilterOperator = {
    label: tCommon('datagrid.operators.list.not-contain'),
    value: DatagridListOperatorEnum.LIST_NOT_CONTAINS,
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: { value?: string[] }): boolean => {
        if (!params.value || params.value.length === 0) return false;
        return params.value.length > 0;
      };
    },
    InputComponent: GridFilterInputMultipleSingleSelect,
    InputComponentProps: { size: 'small' },
  };
  const emptyList: GridFilterOperator = {
    label: tCommon('datagrid.operators.list.empty-list'),
    value: DatagridListOperatorEnum.EMPTY_LIST,
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: { value?: string }): boolean => {
        return !params.value || params.value.length === 0;
      };
    },
  };
  const notEmptyList: GridFilterOperator = {
    label: tCommon('datagrid.operators.list.not-empty-list'),
    value: DatagridListOperatorEnum.NOT_EMPTY_LIST,
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: { value?: string }): boolean => {
        return !params.value || params.value.length === 0;
      };
    },
  };
  return [containsOperator, notContainsOperator, emptyList, notEmptyList];
};
