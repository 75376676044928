import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProductsPage } from 'modules/products';
import { ProductsLayout } from '../../modules/products/products.layout';

export const ProductsRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<ProductsLayout />}>
        <Route index element={<ProductsPage />} />
      </Route>
    </Routes>
  );
};
