import React from 'react';
import { Box, FormControlLabel, Stack } from '@mui/material';
import {
  GridColDef,
  gridColumnDefinitionsSelector,
  GridColumnsPanelProps,
  gridColumnVisibilityModelSelector,
  GridPanelContent,
  GridPanelFooter,
  GridPanelHeader,
  GridPanelWrapper,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Checkbox } from '../../../../components';

const collator = new Intl.Collator();

const defaultSearchPredicate: NonNullable<GridColumnsPanelProps['searchPredicate']> = (
  column,
  searchValue,
) => {
  return (column.headerName || column.field).toLowerCase().indexOf(searchValue) > -1;
};

const hiddenColumnsInColumnPanel: string[] = [
  '__check__',
  'offerKey',
  'offerRepricingDetails',
  'suspiciousProductCostUpdatedAt',
  'suspiciousProductCostSource',
  'aggregationDataMinBuyBoxPriceLast30Days',
  'aggregationDataMaxBuyBoxPriceLast30Days',
  'aggregationDataMaxRankingLast30Days',
  'aggregationDataMinRankingLast30Days',
  'buyBoxInfoSellerId',
  'buyBoxInfoFeedbackCount',
  'buyBoxInfoSellerPositiveFeedbackRating',
  'buyBoxInfoShipsFrom',
];

export const DatagridColumnsPanel: React.FC<GridColumnsPanelProps> = (props) => {
  const apiRef = useGridApiContext();
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const columnVisibilityModel = useGridSelector(apiRef, gridColumnVisibilityModelSelector);
  const rootProps = useGridRootProps();
  const [searchValue, setSearchValue] = React.useState('');
  // const ownerState = { classes: rootProps.classes };
  // const classes = useUtilityClasses(ownerState);

  const {
    sort,
    searchPredicate = defaultSearchPredicate,
    autoFocusSearchField = true,
    ...other
  } = props;

  const sortedColumns = React.useMemo(() => {
    switch (sort) {
      case 'asc':
        return [...columns].sort((a, b) =>
          collator.compare(a.headerName || a.field, b.headerName || b.field),
        );

      case 'desc':
        return [...columns].sort(
          (a, b) => -collator.compare(a.headerName || a.field, b.headerName || b.field),
        );

      default:
        return columns;
    }
  }, [columns, sort]);

  const toggleColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: field } = event.target as HTMLInputElement;
    apiRef.current.setColumnVisibility(field, columnVisibilityModel[field] === false);
  };

  const toggleAllColumns = React.useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        return apiRef.current.setColumnVisibilityModel({});
      }

      return apiRef.current.setColumnVisibilityModel(
        Object.fromEntries(
          columns.filter((col) => col.hideable !== false).map((col) => [col.field, false]),
        ),
      );
    },
    [apiRef, columns],
  );

  const handleSearchValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    },
    [],
  );

  const currentColumns = React.useMemo(() => {
    if (!searchValue) {
      return sortedColumns;
    }
    const searchValueToCheck = searchValue.toLowerCase();
    return sortedColumns.filter((column) => searchPredicate(column, searchValueToCheck));
  }, [sortedColumns, searchValue, searchPredicate]);

  const firstSwitchRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (autoFocusSearchField) {
      searchInputRef.current && searchInputRef.current.focus();
    } else if (firstSwitchRef.current && typeof firstSwitchRef.current.focus === 'function') {
      firstSwitchRef.current.focus();
    }
  }, [autoFocusSearchField]);

  let firstHideableColumnFound = false;

  const isFirstHideableColumn = (column: GridColDef) => {
    if (!firstHideableColumnFound && column.hideable !== false) {
      firstHideableColumnFound = true;
      return true;
    }
    return false;
  };

  return (
    <GridPanelWrapper {...other}>
      <GridPanelHeader>
        <rootProps.slots.baseTextField
          label={apiRef.current.getLocaleText('columnsPanelTextFieldLabel')}
          placeholder={apiRef.current.getLocaleText('columnsPanelTextFieldPlaceholder')}
          inputRef={searchInputRef}
          value={searchValue}
          onChange={handleSearchValueChange}
          variant="standard"
          fullWidth
          {...rootProps.slotProps?.baseTextField}
        />
      </GridPanelHeader>
      <GridPanelContent>
        <Stack gap={0}>
          {currentColumns
            .filter((x) => !hiddenColumnsInColumnPanel.includes(x.field))
            .map((column) => (
              <Box key={column.field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        py: 0,
                        pr: 0,
                        '& + .MuiTypography-root': {
                          paddingTop: 0,
                          '&.MuiFormControlLabel-label': {
                            marginLeft: 1,
                          },
                        },
                      }}
                      size="small"
                      disabled={column.hideable === false}
                      inputRef={isFirstHideableColumn(column) ? firstSwitchRef : undefined}
                      name={column.field}
                      checked={columnVisibilityModel[column.field] !== false}
                      onChange={toggleColumn}
                      label=""
                      {...rootProps.slotProps?.baseSwitch}
                    />
                  }
                  label={column.headerName || column.field}
                />
              </Box>
            ))}
        </Stack>
      </GridPanelContent>
      <GridPanelFooter>
        <rootProps.slots.baseButton
          onClick={() => toggleAllColumns(false)}
          {...rootProps.slotProps?.baseButton}
        >
          {apiRef.current.getLocaleText('columnsPanelHideAllButton')}
        </rootProps.slots.baseButton>
        <rootProps.slots.baseButton
          onClick={() => toggleAllColumns(true)}
          {...rootProps.slotProps?.baseButton}
        >
          {apiRef.current.getLocaleText('columnsPanelShowAllButton')}
        </rootProps.slots.baseButton>
      </GridPanelFooter>
    </GridPanelWrapper>
  );
};
