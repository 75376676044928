import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show, Tooltip } from 'components';
import {
  useCountriesTranslations,
  useCurrenciesTranslations,
  useCommonTranslations,
} from 'modules/shared';
import { Divider, Paper, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useGlobalSettingsQuery } from '../../hooks/use-global-settings.query';

export const SectionGlobalSettings: React.FC = () => {
  const [tCountries] = useCountriesTranslations();
  const [tCurrencies] = useCurrenciesTranslations();
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('settings');

  const { data, isLoading } = useGlobalSettingsQuery();
  const isNoDataCountry: boolean = !isLoading && !data?.mainShippingCountry;
  const isNoDataCurrency: boolean = !isLoading && !data?.baseCurrency;

  const itemStyles: SxProps<Theme> = { '& > *': { flex: 1 } };

  return (
    <Paper sx={{ width: '100%', mt: 3, p: 3 }}>
      <Stack spacing={3} divider={<Divider />}>
        <Stack direction="row" sx={itemStyles}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h5">{t('global-settings.main-shipping-country.label')}</Typography>
            <Tooltip content={t('global-settings.main-shipping-country.tooltip.__html')} />
          </Stack>
          <Typography variant="h5" fontWeight={700}>
            <Show when={isNoDataCountry}>{tCommon('not-yet-set')}</Show>
            <Show when={!isNoDataCountry}>{tCountries(data?.mainShippingCountry as 'PL')}</Show>
          </Typography>
        </Stack>
        <Stack direction="row" sx={itemStyles}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h5">{t('global-settings.base-currency.label')}</Typography>
            <Tooltip content={t('global-settings.base-currency.tooltip.__html')} />
          </Stack>
          <Typography variant="h5" fontWeight={700}>
            <Show when={isNoDataCurrency}>{tCommon('not-yet-set')}</Show>
            <Show when={!isNoDataCurrency}>
              {tCurrencies(`${data?.baseCurrency}.long` as 'PLN.long')}
            </Show>
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
