import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { ButtonSelectMenu } from 'components';
import { useCommonTranslations, useCountriesTranslations } from 'modules/shared';
import { CarrierName, CountryCode } from 'modules/shared/types';
import { Collapse, List, Stack, Typography } from '@mui/material';
import { sortPrimitives } from '../../../../../../../../common/utils';
import { useMappedAvailableCountries } from '../../../../../../hooks';
import { renderCountryItem } from '../../../../common';
import { ShippingCountryFormProps } from '../../../../section-shipping-country';

type Props = {
  name: string;
  addCountry: (countryCode: CountryCode) => void;
  removeCountry: (countryCode: CountryCode) => void;
  countryCodes: CountryCode[];
};

export const OtherCarrierField: React.FC<Props> = (props) => {
  const [t] = useTranslation('settings');
  const [tCountries] = useCountriesTranslations();
  const { tCommon } = useCommonTranslations();
  const { control, setValue } = useFormContext<ShippingCountryFormProps>();
  const mappedAvailableCountries = useMappedAvailableCountries();

  const shippingCountriesValue = useWatch({
    control,
    name: 'shippingCountries',
  });

  const notIntegratedCountriesValue = useWatch({
    control,
    name: 'notIntegratedCountries',
  });

  const handleMoveCountryToCarrier = (
    country: CountryCode,
    carrier: CarrierName | 'notIntegrated',
  ) => {
    const prevShippingCountry = shippingCountriesValue.find((x) => x.carrierName === props.name);
    const prevShippingCountryIndex = shippingCountriesValue.findIndex(
      (x) => x.carrierName === props.name,
    );

    if (prevShippingCountryIndex >= 0 && prevShippingCountry) {
      setValue(`shippingCountries.${prevShippingCountryIndex}`, {
        ...prevShippingCountry,
        countryCodes: prevShippingCountry.countryCodes.filter((y) => y !== country),
      });
    }

    if (carrier !== 'notIntegrated') {
      const nextShippingCountry = shippingCountriesValue.find((x) => x.carrierName === carrier);
      const nextShippingCountryIndex = shippingCountriesValue.findIndex(
        (x) => x.carrierName === carrier,
      );

      if (nextShippingCountryIndex >= 0 && nextShippingCountry) {
        setValue(`shippingCountries.${nextShippingCountryIndex}`, {
          ...nextShippingCountry,
          countryCodes: [...nextShippingCountry.countryCodes, country].sort(sortPrimitives),
        });
      }
      return;
    }

    setValue(
      'notIntegratedCountries',
      [...notIntegratedCountriesValue, country].sort(sortPrimitives),
    );
  };

  const filteredAndParsedCarriers = shippingCountriesValue
    .filter((x) => x.carrierName !== props.name)
    .map((x) => x.carrierName);

  return (
    <Stack sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h2">{props.name || 'Carrier name'}</Typography>
      <List
        sx={{
          '& .MuiCollapse-root': {
            '&:nth-of-type(odd)': {
              backgroundColor: 'nethansa.background.title',
            },
          },
        }}
      >
        <TransitionGroup>
          {props.countryCodes.map((country) => (
            <Collapse key={country}>
              {renderCountryItem({
                code: country,
                name: tCountries(country),
                handleRemove: props.removeCountry,
                moveToCarrier: handleMoveCountryToCarrier,
                carriersToMove: filteredAndParsedCarriers as CarrierName[],
                renderNotIntegrateButton: true,
                labels: {
                  remove: tCommon('remove'),
                  notIntegrated: t('carriers.shipping-country.not-integrated.button'),
                  changeCarrierTo: t('carriers.shipping-country.change-carrier-to'),
                },
              })}
            </Collapse>
          ))}
        </TransitionGroup>
      </List>

      <ButtonSelectMenu
        options={mappedAvailableCountries}
        buttonLabel={t('carriers.shipping-country.add-country')}
        onSelect={(value) => {
          if (!value) return;
          props.addCountry(value as unknown as CountryCode);
        }}
      />
    </Stack>
  );
};
