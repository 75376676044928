import { UseQueryResult, useQuery } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import {
  InputMaybe,
  ProductGridDtoFilterInput,
  ProductsQuery,
  ProductsQueryVariables,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';

export const getShoppingBasketVeryfication = gql`
  query ShoppingBasketValidProductsCount(
    $partnerId: String!
    $first: Int!
    $filter: ProductGridDtoFilterInput
  ) {
    products(partnerId: $partnerId, first: $first, where: $filter) {
      totalCount
    }
  }
`;

const additionalFilters: ProductGridDtoFilterInput[] = [
  {
    productCost: { gt: 0 },
  },
];

export const useShoppingBasketVeryficationQuery = (
  filters: InputMaybe<ProductGridDtoFilterInput> | undefined,
): UseQueryResult<number> => {
  const { currentPartnerId } = useAuth();

  return useQuery<number>(
    ['products', 'total-count', 'products-total-all-count', currentPartnerId, filters],
    async () => {
      let parsedFilter: InputMaybe<ProductGridDtoFilterInput> = {
        ...filters,
      };

      if (parsedFilter.and) parsedFilter.and = [...parsedFilter.and, ...additionalFilters];
      else parsedFilter = { and: additionalFilters };

      const result = (await graphqlClient.request<ProductsQueryVariables>(
        getShoppingBasketVeryfication,
        {
          partnerId: String(currentPartnerId),
          first: 0,
          ...(Object.keys(parsedFilter || {}).length ? { filter: parsedFilter } : {}),
        },
      )) as ProductsQuery;

      return result?.products?.totalCount || 0;
    },
    {
      staleTime: 1,
      keepPreviousData: false,
      retry: true,
    },
  );
};
