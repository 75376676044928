import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { Alert, Box } from '@mui/material';
import { Show } from '../../../../components';
import { hasUserRightClaims } from '../../helpers/claims-checker';
import { useUser } from '../../hooks/use-user';
import { Claim } from '../../types';

type Props = React.PropsWithChildren<{
  allowed: Claim | Claim[];
  fallbackUrl?: string;
}>;

export const ClaimDefenderRoutLayout: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');
  const { claimsFetched, claims } = useUser();

  const hasRightClaims = hasUserRightClaims(props.allowed, claims);

  return (
    <>
      <Show when={!hasRightClaims && claimsFetched && Boolean(props?.fallbackUrl)}>
        <Navigate replace to={props.fallbackUrl || '/'} />
      </Show>
      <Show when={!hasRightClaims && claimsFetched && Boolean(!props?.fallbackUrl)}>
        <Box sx={{ p: 3 }}>
          <Alert severity="warning" sx={{ maxWidth: '448px' }}>
            {t('role.no-access')}
          </Alert>
        </Box>
      </Show>
      {!hasRightClaims && claimsFetched}
      <Show when={claimsFetched && hasRightClaims}>
        <Outlet />
      </Show>
    </>
  );
};
