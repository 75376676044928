import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getLangShort } from 'common/utils';
import { FormAlert, FormButtonsGroup } from 'components';
import { Button, Stack, TextField } from '@mui/material';
import { useProfileDataMutation, useProfileDataQuery } from '../../hooks';
import { ProfileDataType } from '../../types/profile-data.type';

type FormProps = ProfileDataType;

export const SectionProfileData: React.FC = () => {
  const [t, i18n] = useTranslation('settings');
  const profileDataQuery = useProfileDataQuery();
  const profileDataMutation = useProfileDataMutation();
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm<FormProps>({
    mode: 'onBlur',
    defaultValues: {
      firstName: profileDataQuery.data?.firstName,
      lastName: profileDataQuery.data?.lastName,
      email: profileDataQuery.data?.email,
      phone: profileDataQuery.data?.phone,
      lang: profileDataQuery.data?.lang,
    },
  });

  const submit = useCallback(
    (data: FormProps) => {
      return profileDataMutation.mutate(data, {
        onSuccess: () => {
          profileDataQuery.refetch();
          reset({ ...data });
          i18n.changeLanguage(getLangShort(data.lang));
        },
        onError: (error) => {
          Object.entries<string[]>(error.errors).forEach(([key, errors]) => {
            errors.forEach((errorKey) => {
              setError(key as keyof FormProps, {
                type: 'Validation Error',
                message: t(
                  `form.${key}.validation.${errorKey}` as 'form.companyName.validation.required',
                ),
              });
            });
          });
        },
      });
    },
    [profileDataMutation.mutate],
  );

  const disabled = profileDataMutation.isLoading || isSubmitting;

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack gap="30px" sx={{ mt: 2.5 }}>
        <Controller
          control={control}
          name="firstName"
          // rules={{
          //   required: {
          //     value: true,
          //     message: t('form.firstName.validation.required'),
          //   },
          // }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                autoComplete="name"
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.firstName.label')}
                placeholder={t('form.firstName.placeholder')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="lastName"
          // rules={{
          //   required: {
          //     value: true,
          //     message: t('form.lastName.validation.required'),
          //   },
          // }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                autoComplete="surname"
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.lastName.label')}
                placeholder={t('form.lastName.placeholder')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="email"
          render={({ field }) => {
            return (
              <TextField
                disabled
                value={field.value}
                label={t('form.email.label')}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field, fieldState }) => {
            return (
              <TextField
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.phone.label')}
                placeholder={t('form.phone.placeholder')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        {/*<Controller*/}
        {/*  control={control}*/}
        {/*  name="lang"*/}
        {/*  rules={{*/}
        {/*    required: {*/}
        {/*      value: true,*/}
        {/*      message: t('form.interfaceLanguage.validation.required'),*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  render={({ field, fieldState }) => {*/}
        {/*    return (*/}
        {/*      <SelectField*/}
        {/*        disabled={disabled}*/}
        {/*        value={field.value}*/}
        {/*        onChange={field.onChange}*/}
        {/*        onBlur={field.onBlur}*/}
        {/*        label={t('form.interfaceLanguage.label')}*/}
        {/*        placeholder={t('form.interfaceLanguage.placeholder')}*/}
        {/*        error={!!fieldState.error}*/}
        {/*        helperText={fieldState.error?.message}*/}
        {/*        sx={{ mb: 2, flex: 1, m: 0 }}*/}
        {/*        options={[*/}
        {/*          {*/}
        {/*            children: 'English',*/}
        {/*            value: 'en-US',*/}
        {/*          },*/}
        {/*          {*/}
        {/*            children: 'Polish',*/}
        {/*            value: 'pl-PL',*/}
        {/*          },*/}
        {/*        ]}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}
      </Stack>
      <FormAlert
        show={profileDataMutation.isSuccess || profileDataMutation.isError}
        isSuccess={profileDataMutation.isSuccess}
        isError={profileDataMutation.isError}
        successMessage={t('user-profile.success')}
        errorMessage={t('user-profile.errors.general')}
      />
      <FormButtonsGroup sx={{ mt: 1 }} isLoading={disabled}>
        <Button size="small" type="submit" disabled={disabled}>
          {t('form.actions.update')}
        </Button>
      </FormButtonsGroup>
    </form>
  );
};
