import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { useDebounce } from '@react-hook/debounce';
import { TextFieldNumber } from '../../../../../../components';
import { useCommonTranslations } from '../../../../../shared';

type Props = GridFilterInputValueProps;

export const NumberInputOperator: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const { item, applyValue } = props;
  const [value, setValue] = useState<number | undefined>(
    item.value ? Number(item.value) : undefined,
  );
  const [debouncedValue, setDebouncedValue] = useDebounce<number | undefined>(value, 500);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef: React.Ref<any> = React.useRef(null);

  const handleFilterChange = (value: number | null) => {
    setValue(value || undefined);
    setDebouncedValue(value || undefined);
    // applyValue({ ...item, value: value });
  };

  useEffect(() => {
    applyValue({ ...item, value: debouncedValue });
  }, [debouncedValue]);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 45,
        pt: 0.5,
        width: '100%',
      }}
    >
      <TextFieldNumber
        fullWidth
        label={tCommon('value')}
        placeholder=""
        name="custom-number-filter-operator"
        value={item.value ? Number(item.value) : undefined}
        onChange={handleFilterChange}
        precision={0}
        inputRef={inputRef}
        InputLabelProps={{
          shrink: true,
          sx: {
            '&.MuiInputLabel-root': {
              transform: 'translate(-12px, -22px) scale(1)',
            },
          },
        }}
      />
    </Box>
  );
};
