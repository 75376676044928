export const USER_UNAUTHORIZED = 'user_unauthorized';
export const COORDINATOR_READY = 'coordinator_ready';
export const REACT_LINK_SETTINGS_CHANGED = 'reactLink-settingsChanged';

export const EVENT_USER_UNAUTHORIZED = new Event(USER_UNAUTHORIZED);
export const EVENT_COORDINATOR_READY = new Event(COORDINATOR_READY);
export const EVENT_REACT_LINK_SETTINGS_CHANGED = new CustomEvent(REACT_LINK_SETTINGS_CHANGED);

type Events =
  | typeof EVENT_USER_UNAUTHORIZED
  | typeof EVENT_COORDINATOR_READY
  | typeof EVENT_REACT_LINK_SETTINGS_CHANGED;

export const eventDispatcher = (event: Events) => {
  if (typeof window === 'undefined' || typeof window.dispatchEvent === 'undefined') {
    throw new Error('Dispatch event is not possible');
  }
  window.dispatchEvent(event);
  console.log(`* Event dispatched: [ ${event.type} ] *`);
};
