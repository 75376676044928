import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { Collapse, List, Stack, Typography } from '@mui/material';
import { sortPrimitives } from '../../../../../../common/utils';
import { ButtonSelectMenu, Tooltip } from '../../../../../../components';
import { useCommonTranslations, useCountriesTranslations } from '../../../../../shared';
import { CarrierName, CountryCode } from '../../../../../shared/types';
import { useMappedAvailableCountries } from '../../../../hooks';
import { CarriersShippingInputType } from '../../../../types';
import { renderCountryItem } from '../../common';

export const ListCountiresNotIntegrated: React.FC = () => {
  const [t] = useTranslation('settings');
  const [tCountries] = useCountriesTranslations();
  const { tCommon } = useCommonTranslations();
  const { control, setValue } = useFormContext<CarriersShippingInputType>();
  const mappedAvailableCountries = useMappedAvailableCountries();

  const shippingCountriesValue = useWatch({
    control,
    name: 'shippingCountries',
  });

  const filteredAndParsedCarriers = shippingCountriesValue.map((x) => x.carrierName);

  return (
    <Stack sx={{ mt: 2, mb: 2 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h2">{t('carriers.shipping-country.not-integrated.label')}</Typography>
        <Tooltip content={t('carriers.shipping-country.not-integrated.tooltip.__html')} />
      </Stack>
      <Controller
        control={control}
        name={`notIntegratedCountries`}
        render={({ field }) => {
          const handleRemoveCountry = (countryCode: CountryCode) => {
            field.onChange(field.value.filter((x) => x !== countryCode));
          };

          const handleMoveCountryToCarrier = (
            country: CountryCode,
            carrier: CarrierName | 'notIntegrated',
          ) => {
            field.onChange(field.value.filter((x) => x !== country));

            const shippingCountryIndex = shippingCountriesValue.findIndex(
              (x) => x.carrierName === carrier,
            );
            const shippingCountry = shippingCountriesValue.find((x) => x.carrierName === carrier);

            if (!shippingCountry || shippingCountryIndex < 0) return;

            setValue(`shippingCountries.${shippingCountryIndex}`, {
              ...shippingCountry,
              countryCodes: [...shippingCountry.countryCodes, country].sort(sortPrimitives),
            });
          };

          return (
            <>
              <List
                sx={{
                  '& .MuiCollapse-root': {
                    '&:nth-of-type(odd)': {
                      backgroundColor: 'nethansa.background.title',
                    },
                  },
                }}
              >
                <TransitionGroup>
                  {field.value.map((country) => (
                    <Collapse key={country}>
                      {renderCountryItem({
                        code: country,
                        name: tCountries(country),
                        handleRemove: handleRemoveCountry,
                        moveToCarrier: handleMoveCountryToCarrier,
                        carriersToMove: filteredAndParsedCarriers as CarrierName[],
                        labels: {
                          remove: tCommon('remove'),
                          notIntegrated: t('carriers.shipping-country.not-integrated.button'),
                          changeCarrierTo: t('carriers.shipping-country.change-carrier-to'),
                        },
                      })}
                    </Collapse>
                  ))}
                </TransitionGroup>
              </List>
              <ButtonSelectMenu
                options={mappedAvailableCountries}
                buttonLabel={t('carriers.shipping-country.add-country')}
                onSelect={(value) => {
                  if (!value) return;
                  field.onChange([...field.value, value as unknown as CountryCode]);
                }}
              />
            </>
          );
        }}
      />
    </Stack>
  );
};
