import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { useAuth } from 'modules/authorization';
import { ResponseAPIType } from 'modules/shared/types';
import { GlobalsType } from '../types/globals.type';

export const useGlobalSettingsQuery = (): UseQueryResult<GlobalsType> => {
  const { i18n } = useTranslation();
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<GlobalsType>(
    ['settings', 'globals', i18n.language],
    async () => {
      const response = await httpClient.get<ResponseAPIType<GlobalsType>>(
        API.salesIntelligence(currentPartnerId).globals(),
      );

      return response.data.data;
    },
    {
      staleTime: 50000,
      keepPreviousData: true,
      enabled: authorized,
      retry: false,
    },
  );
};
