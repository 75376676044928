export enum OfferAction {
  Unknown = 0,
  Pause = 1,
  Activate = 2,
  Send,
  AsinEdit,
  Analyze,
  EditPrices,
  ShowDetails,
  ChangeSku,
  Delete,
}
