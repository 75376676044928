import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show, Tooltip, TooltipWrapper } from 'components';
import { ChannelCode, OfferGridDtoFilterInput, SharedOfferSubStateEnum } from 'generated/graphql';
import { ClaimDefenderComponent } from 'modules/authorization';
import { CanExecuteAction } from 'modules/offers/actions-validator';
import { OfferAction } from 'modules/offers/types/offer-action';
import { PlatformChannelCode } from 'modules/shared/types';
import { Box, Divider, IconButton, List, ListItemButton, Stack } from '@mui/material';
import { MoreIcon } from '../../../../components/icons';
import { mapSelectedOfferIdsToFilter } from '../../../datagrid';
import { useLabelsSendToUtils, useOfferCoordinatorContext } from '../../hooks';
import { OfferGridRow, PlatformCodeEnum } from '../../types';

type Props = {
  offer: OfferGridRow;
};

export const ButtonMenuOffer: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  const {
    editAsin: [, setEditAsinData],
    priceSettings: [, setPriceSettings],
    sendOffers: [, setSendOffers],
    analyzeOffers: [, setAnalyzeOffers],
    deleteOffers: [, setDeleteOffers],
    rawPriceCalculation: [, setRawPrice],
    pauseOffer: [, setPauseOrActivateOffer],
    changeSku: [, setChangeSku],
    editTags: [, setEditTags],
    offerDetails: [, setOfferDetails],
  } = useOfferCoordinatorContext();

  const channelCode: PlatformChannelCode = (props.offer.channelCode ||
    'AMZFBA') as PlatformChannelCode;
  const platformCode = channelCode === 'DEFAULT' ? PlatformCodeEnum.KAUF : PlatformCodeEnum.AMZEU;

  const sendToLabel = useLabelsSendToUtils(channelCode);

  const canPauseOffer = CanExecuteAction(
    OfferAction.Pause,
    props.offer.sharedOfferState,
    props.offer.sharedOfferSubState as unknown as SharedOfferSubStateEnum[],
  );
  const canActivateOffer = CanExecuteAction(
    OfferAction.Activate,
    props.offer.sharedOfferState,
    props.offer.sharedOfferSubState as unknown as SharedOfferSubStateEnum[],
  );
  const canEditPrices = CanExecuteAction(OfferAction.EditPrices, props.offer.sharedOfferState, []);
  const canSendOffer = CanExecuteAction(OfferAction.Send, props.offer.sharedOfferState, []);
  const canEditAsin = CanExecuteAction(OfferAction.AsinEdit, props.offer.sharedOfferState, []);
  const canAnalyze = CanExecuteAction(OfferAction.Analyze, props.offer.sharedOfferState, []);
  const canChangeSku = CanExecuteAction(OfferAction.ChangeSku, props.offer.sharedOfferState, []);
  const canDelete = CanExecuteAction(OfferAction.Delete, props.offer.sharedOfferState, []);

  const isFbmChannel = props.offer.channelCode === ChannelCode.Amzfbm;
  const isKaufland = props.offer.channelCode === ChannelCode.Default;

  const manageMenu = (
    <List sx={{ px: '0!important', '&>*': { fontSize: '13px' } }}>
      <ListItemButton
        data-testid="offer-details"
        onClick={() => {
          setOfferDetails({
            offerId: props.offer.offerKey || '',
            productName: props.offer.productName || '',
            status: props.offer.sharedOfferState || '',
            detailedStatus: props.offer.sharedOfferSubState || [],
            marketplaceCode: props.offer.marketplaceCode || '',
            channelCode: props.offer.channelCode || '',
            domain: props.offer.domain || '',
            productUrl: props.offer.productUrl || '',
            asin: props.offer.asin,
            calculatedPrice: props.offer.calculatedPrice,
            listingPrice: props.offer.listingPrice,
            currencyCode: props.offer.currencyCode,
          });
        }}
      >
        {t('offer-details.label')}
      </ListItemButton>

      <Show when={!!sendToLabel}>
        <ListItemButton
          data-testid="send-to"
          onClick={() => {
            if (canSendOffer)
              setSendOffers({
                selectedOfferIds: [props.offer.offerKey || ''],
                platformCode: platformCode,
                channelDefined: channelCode,
                row: props.offer,
              });
          }}
        >
          <Stack direction="row" spacing={1}>
            <Box sx={{ opacity: !canSendOffer ? 0.5 : 1 }}>{sendToLabel}</Box>
            <Show when={!canSendOffer}>
              <Tooltip
                sx={{ pr: '10px' }}
                content={t('actions.action-disabled', { action: sendToLabel })}
              ></Tooltip>
            </Show>
          </Stack>
        </ListItemButton>
      </Show>

      <Show when={!canActivateOffer && isFbmChannel}>
        <ListItemButton
          data-testid="pause-offer-id"
          onClick={() => {
            if (canPauseOffer) setPauseOrActivateOffer([props.offer || '', OfferAction.Pause]);
          }}
        >
          <Stack direction="row" spacing={1}>
            <Box sx={{ opacity: !canPauseOffer ? 0.5 : 1 }}>{t('actions.pause')}</Box>
            <Show when={!canPauseOffer}>
              <Tooltip sx={{ pr: '10px' }} content={t('actions.pauseTooltip')}></Tooltip>
            </Show>
          </Stack>
        </ListItemButton>
      </Show>

      <Show when={!canPauseOffer && !!canActivateOffer && isFbmChannel}>
        <ListItemButton
          data-testid="activate-offer-id"
          onClick={() => {
            if (canActivateOffer)
              setPauseOrActivateOffer([props.offer || '', OfferAction.Activate]);
          }}
        >
          <Stack direction="row" spacing={1}>
            <Box sx={{ opacity: !canActivateOffer ? 0.5 : 1 }}>{t('actions.activate')}</Box>
          </Stack>
        </ListItemButton>
      </Show>

      <Show when={!isKaufland}>
        <ListItemButton
          data-testid="edit-asin"
          onClick={() => {
            if (canEditAsin)
              setEditAsinData({
                offerId: props.offer.offerKey || '',
              });
          }}
        >
          <Stack direction="row" spacing={1}>
            <Box sx={{ opacity: !canEditAsin ? 0.5 : 1 }}>{t('modals.edit-asin-modal.header')}</Box>
            <Show when={!canEditAsin}>
              <Tooltip
                sx={{ pr: '10px' }}
                content={t('actions.action-disabled', {
                  action: t('modals.edit-asin-modal.header'),
                })}
              ></Tooltip>
            </Show>
          </Stack>
        </ListItemButton>
      </Show>

      <ListItemButton
        sx={{ cursor: 'default' }}
        data-testid="price-settings"
        onClick={() => {
          if (canEditPrices)
            setPriceSettings({
              offerId: props.offer.offerKey || '',
              domain: props.offer.domain || '',
              productUrl: props.offer.productUrl || '',
              productName: props.offer.productName || '',
            });
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box sx={{ opacity: !canEditPrices ? 0.5 : 1 }}>{t('modals.price-settings.title')}</Box>
          <Show when={!canEditPrices}>
            <Tooltip content={t('actions.price-settings-disabled')} />
          </Show>
        </Stack>
      </ListItemButton>

      <ListItemButton
        data-testid="analyze"
        onClick={() => {
          if (canAnalyze)
            setAnalyzeOffers({
              selectedOfferIds: [props.offer.offerKey || ''],
              platformCode: platformCode,
              channelDefined: channelCode,
              row: props.offer,
            });
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box sx={{ opacity: !canAnalyze ? 0.5 : 1 }}>{t('actions.analyze')}</Box>
          <Show when={!canAnalyze}>
            <Tooltip content={t('actions.action-disabled', { action: t('actions.analyze') })} />
          </Show>
        </Stack>
      </ListItemButton>

      <Show when={!isKaufland && isFbmChannel}>
        <ListItemButton
          data-testid="change-sku"
          onClick={() => {
            if (canChangeSku) setChangeSku(props.offer || '');
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box sx={{ opacity: !canChangeSku ? 0.5 : 1 }}>{t('actions.change-sku')}</Box>
            <Show when={!canChangeSku}>
              <Tooltip content={t('actions.change-sku-disabled')} />
            </Show>
          </Stack>
        </ListItemButton>
      </Show>
      <Show when={!isKaufland}>
        <ListItemButton
          data-testid="change-tags"
          onClick={() => {
            setEditTags({
              row: props.offer,
              filters: mapSelectedOfferIdsToFilter<OfferGridDtoFilterInput>([
                props.offer.offerKey || '',
              ]).filter,
            });
          }}
        >
          {t('actions.edit-tags')}
        </ListItemButton>
      </Show>
      <Divider />

      <ListItemButton
        data-testid="delete"
        sx={{ color: 'nethansa.main.watermelon' }}
        onClick={() => {
          if (canDelete)
            setDeleteOffers({
              selectedOfferIds: [props.offer.offerKey || ''],
              platformCode: platformCode,
              channelDefined: channelCode,
              row: props.offer,
            });
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box sx={{ opacity: !canDelete ? 0.5 : 1 }}>{t('actions.delete')}</Box>
          <Show when={!canDelete}>
            <Tooltip content={t('actions.action-disabled', { action: t('actions.delete') })} />
          </Show>
        </Stack>
      </ListItemButton>

      <ClaimDefenderComponent hidden allowed={'OFFERS_OFFER_MENU_ITEM_RAW_PRICE_CALCULATION'}>
        <>
          <Divider />
          <ListItemButton
            data-testid="raw-price-caculation"
            onClick={() => setRawPrice(props.offer.offerKey || '')}
          >
            {t('actions.show-raw-price-calculation')}
          </ListItemButton>
        </>
      </ClaimDefenderComponent>
    </List>
  );

  return (
    <Stack alignItems="center">
      <TooltipWrapper
        content={manageMenu}
        placement="right-start"
        componentsProps={{
          tooltip: {
            sx: {
              p: 0,
            },
          },
        }}
      >
        <IconButton size="small">
          <MoreIcon sx={{ fontSize: '18px' }} />
        </IconButton>
      </TooltipWrapper>
    </Stack>
  );
};
