import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { Checkbox, DialogConfirmation, Show, TooltipWrapper } from 'components';
import { DatagridCellFlag } from 'modules/datagrid';
import { ActionDetails } from 'modules/offers/components/modal-action-details/action-details';
import { useSnackbar } from 'modules/snackbar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert, Box, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import { SpinnerIcon } from '../../../../components/icons';
import { SharedOfferStateEnum } from '../../../../generated/graphql';
import { NAVIGATION_APP_HEIGHT, nethansaPalette } from '../../../../theme';
import { SelectedOfferDetails } from '../../components/modal-selected-offer-details/selected-offer-details';
import { getStatusColorGroup } from '../../components/table-utils';
import { useOfferCoordinatorContext } from '../../hooks';
import { useChangeSkuMutation } from '../../hooks/use-change-sku.mutation';
import {
  OfferToChangeSku,
  useGetOffersToChangeSkuQuery,
} from '../../hooks/use-get-offers-to-change-sku.query';

export const ChangeSkuModal: React.FC = () => {
  const { pushSnackbar } = useSnackbar();
  const { isFetching: isLoadingOffers, data: offersToSelected } = useGetOffersToChangeSkuQuery();
  const {
    changeSku: [selectedOfferWithAction, setOfferWithAction],
  } = useOfferCoordinatorContext();

  const [t] = useTranslation('offers');
  const [tPlatform] = useTranslation('platforms');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { mutate: changeSku, isLoading: isChangingSku } = useChangeSkuMutation();

  const refreshOffers = () => queryClient.refetchQueries({ active: true, queryKey: 'offers' });
  const closeModal = () => setOfferWithAction(undefined);

  const selectedOffer = selectedOfferWithAction;

  if (!selectedOffer) throw Error('Unexpected exception');

  const otherOffersWithoutSelected =
    offersToSelected?.filter((offer) => offer.offerId !== selectedOffer.offerKey) ?? [];

  const skuInputRef = React.useRef<HTMLInputElement>(null);
  const checkboxOfferRefs = React.useRef<(HTMLInputElement | null)[]>([]);

  const confirm = (confirmed: boolean) => {
    const offerIds = checkboxOfferRefs.current
      .filter((e) => e?.checked === true)
      .map((e) => e?.value ?? '');

    if (!confirmed) {
      closeModal();
      return;
    }

    changeSku(
      {
        offerId: selectedOffer.offerKey ?? '',
        newSku: skuInputRef.current?.value ?? '',
        selectedOfferIds: offerIds ?? [],
      },
      {
        onSuccess: (data) => {
          if (data.isSuccess) {
            refreshOffers();
            closeModal();
            pushSnackbar({
              variant: 'success',
              message: t('modals.change-sku-modal.success'),
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } else
            setErrorMessage(t(`modals.change-sku-modal.errors.${data.errorMessages[0]}` as any));
        },
        onError: () => {
          setErrorMessage(t('modals.change-sku-modal.errors.general'));
        },
      },
    );
  };

  return (
    <DialogConfirmation
      isLoading={isLoadingOffers || isChangingSku}
      confirmLabel={t('actions.change-sku')}
      open
      onClose={confirm}
      header={t('actions.change-sku')}
      description=""
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <SelectedOfferDetails row={selectedOffer} />

        <ActionDetails
          title={t('actions.action-details-title')}
          content={t('modals.change-sku-modal.action-details')}
        />

        <Show when={errorMessage != null}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Show>

        <Stack
          sx={{
            backgroundColor: nethansaPalette.background.title,
            width: '100%',
            gap: 2,
            fontSize: '13px',
            lineHeight: '18px',
            px: '16px',
            pt: '8px',
            overflowY: 'auto',
            maxHeight: `calc(100vh - ${NAVIGATION_APP_HEIGHT} - 364px)`,
          }}
        >
          <Show when={!isLoadingOffers}>
            <Show when={otherOffersWithoutSelected.length > 0}>
              <Typography variant="h5">
                {t('modals.change-sku-modal.label', { sku: selectedOffer.sku })}:
              </Typography>
            </Show>
            <Show when={otherOffersWithoutSelected.length === 0}>
              <Typography variant="h5">
                {t('modals.change-sku-modal.label-no-offers', { sku: selectedOffer.sku })}:
              </Typography>
            </Show>

            <TextField defaultValue={selectedOffer?.sku + '_2'} inputRef={skuInputRef} />
            <Show when={otherOffersWithoutSelected.length > 0}>
              <Typography variant="h5">{t('modals.change-sku-modal.uncheck-info')}</Typography>
            </Show>
            <Stack
              divider={
                <Divider sx={{ width: '100%', backgroundColor: nethansaPalette.line.light }} />
              }
            >
              {otherOffersWithoutSelected.map((e: OfferToChangeSku, index: number) => (
                <Stack direction="row" key={e.offerId} sx={{ alignItems: 'center' }}>
                  <Checkbox
                    label=""
                    sx={{ mr: '16px' }}
                    value={e.offerId}
                    inputRef={(element) => (checkboxOfferRefs.current[index] = element)}
                    defaultChecked={e.stateName !== SharedOfferStateEnum.Processing}
                    disabled={e.stateName === SharedOfferStateEnum.Processing}
                  />
                  <Box sx={{ width: '130px' }}>
                    <DatagridCellFlag
                      value={e?.marketplaceCode || 'unknown'}
                      label={
                        tPlatform(`amazon.markets.website.${e?.marketplaceCode as 'DE'}`) ||
                        'unknown'
                      }
                      href={(e?.url ? e?.url : '') || ''}
                    />
                  </Box>
                  <Show when={e.stateName === SharedOfferStateEnum.Processing}>
                    <TooltipWrapper
                      content={t('modals.change-sku-modal.offer-tooltip-error')}
                      enterDelay={500}
                      enterNextDelay={500}
                    >
                      <Box component="span">
                        <IconButton color="error" sx={{ width: '20px', height: '20px', pl: 0 }}>
                          <ErrorOutlineIcon />
                        </IconButton>
                      </Box>
                    </TooltipWrapper>
                  </Show>
                  <Typography
                    variant="h5"
                    sx={{
                      textTransform: 'lowercase',
                      color:
                        nethansaPalette.status[`${getStatusColorGroup(e.stateName) as 'dead'}`]
                          .dark,
                    }}
                  >
                    {t(`offer-status.main.${e.stateName as 'ACTIVE'}`)}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Show>
          <Show when={isLoadingOffers}>
            <SpinnerIcon sx={{ width: '150px', height: 'auto', margin: 'auto' }} />
          </Show>
        </Stack>
      </Stack>
    </DialogConfirmation>
  );
};
