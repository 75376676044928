import React from 'react';
import { CardForm, Show } from 'components';
import { ClaimDefenderComponent } from 'modules/authorization';
import { SectionBillingData, SectionBillingDataSkeleton } from './components';
import { useBillingDataQuery } from './hooks';

export const BillingDataSettingsPage: React.FC = () => {
  const { isFetched } = useBillingDataQuery();

  return (
    <ClaimDefenderComponent
      showInfoBanner
      allowed={'SETTINGS_PAYMENTS_AND_BILLINGS_BILLING_DATA_EDIT'}
    >
      <CardForm>
        <Show when={isFetched} fallback={<SectionBillingDataSkeleton />}>
          <SectionBillingData />
        </Show>
      </CardForm>
    </ClaimDefenderComponent>
  );
};
