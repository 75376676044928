import { GridCellParams } from '@mui/x-data-grid-pro';

export const currencyCellClassName =
  (key: string, currency?: string) => (params: GridCellParams) => {
    if (typeof params.value !== 'number' && !params.value) return 'dg-empty-line';
    return `dg-currency-cell ${currency || params.row?.[key] || 'EUR'}`;
  };

export const percentageCellClassName = (params: GridCellParams) =>
  typeof params.value !== 'number' && !params.value ? 'dg-empty-line' : 'dg-percentage-cell';
