import { useMutation, UseMutationResult } from 'react-query';
import {
  OffersBulkActionPriceSettingsQueryVariables,
  UpdatePriceSettingsRequestInput,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useDatagridFiltersToGraphqlTranslator } from 'modules/datagrid';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { useAuth } from '../../authorization';
import { ErrorMeta, PlatformChannelCode } from '../../shared/types';
import { useOffersColumnsDefinition } from './use-offers-columns-definition';
import { searchableColumnsOffers } from './use-offers.query';
import { usePriceSettingsQuery } from './use-price-settings.query';

const queryBulkAction = gql`
  query OffersBulkActionPriceSettings(
    $partnerId: Int!
    $priceSettingsRequest: UpdatePriceSettingsRequestInput!
    $filter: OfferGridDtoFilterInput
  ) {
    updatePriceSettingsOnFilteredOffers(
      priceSettingsRequest: $priceSettingsRequest
      partnerId: $partnerId
      where: $filter
    ) {
      offerKey
    }
  }
`;

type InputType = {
  priceSettingsRequest: UpdatePriceSettingsRequestInput;
  filters?: GridFilterModel;
  searchValue?: string;
  channelDefined: PlatformChannelCode;
};

export const useBulkActionOnFilteredPriceSettingsMutation = (): UseMutationResult<
  void,
  ErrorMeta,
  InputType
> => {
  const { currentPartnerId } = useAuth();
  const { refetch } = usePriceSettingsQuery();
  const columns = useOffersColumnsDefinition();

  return useMutation(
    async (body) => {
      const filtersParsed = useDatagridFiltersToGraphqlTranslator(
        body.filters || ({} as GridFilterModel),
        columns,
        searchableColumnsOffers,
        body.channelDefined,
        body.searchValue,
      );

      return await graphqlClient.request<OffersBulkActionPriceSettingsQueryVariables>(
        queryBulkAction,
        {
          partnerId: Number(currentPartnerId || '0'),
          filter: filtersParsed.filter,
          priceSettingsRequest: body.priceSettingsRequest,
        },
      );
    },
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
};
