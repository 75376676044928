import React, { useCallback, useMemo } from 'react';
import { TextFieldOutlined } from 'components';
import { ClaimDefenderComponent } from 'modules/authorization';
import {
  DatagridExportMenu,
  DatagridPagination,
  DatagridWrapper,
  TableNoResults,
  ToolbarTable,
} from 'modules/datagrid';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { useCommonTranslations } from 'modules/shared';
import { GridRowsProp, UncapitalizedGridSlotsComponent } from '@mui/x-data-grid-pro';
import { FileType } from '../../../shared/types';
import { useSaleRevenueProfitReportsQuery } from '../../hooks';
import { useSaleRevenueColumnsDefinition } from '../../hooks/use-sale-revenue-columns-definition';
import { useSaleRevenueReportExport } from '../../hooks/use-sale-revenue-reports-export';
import { SaleRevenueRow } from '../../types';

export const TableSaleRevenue: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const { data: globalSettingsData, isFetching: isGlobalSettingsFetching } =
    useGlobalSettingsQuery();

  const columns = useSaleRevenueColumnsDefinition();

  const { data, isFetching } = useSaleRevenueProfitReportsQuery(columns);

  const rows: GridRowsProp<SaleRevenueRow> = (data?.saleRevenueProfitReport?.nodes ||
    []) as SaleRevenueRow[];

  const saleRevenueLazyExportData = useSaleRevenueReportExport(columns);

  const onLazyDownloadClick = useCallback(
    (fileType: FileType) => {
      saleRevenueLazyExportData.mutate(fileType);
    },
    [saleRevenueLazyExportData],
  );

  // Important: never pass components to Grid without useMemo
  const components: Partial<UncapitalizedGridSlotsComponent> = useMemo(() => {
    const LIMIT_XLSX = 5000,
      LIMIT_CSV = 50000;
    const disabledExportXlsxByLimit = (data?.saleRevenueProfitReport?.totalCount || 0) > LIMIT_XLSX;
    const disabledExportCsvByLimit = (data?.saleRevenueProfitReport?.totalCount || 0) > LIMIT_CSV;

    const options = [
      {
        label: tCommon('datagrid.actions.download-as-csv'),
        onClick: () => onLazyDownloadClick('csv'),
        tooltip: disabledExportCsvByLimit
          ? tCommon('datagrid.actions.export-limit-message.tooltip.__html', { count: LIMIT_CSV })
          : '',
        disabled: saleRevenueLazyExportData.isLoading || disabledExportCsvByLimit,
      },
      {
        label: tCommon('datagrid.actions.download-as-excel'),
        onClick: () => onLazyDownloadClick('xlsx'),
        tooltip: disabledExportXlsxByLimit
          ? tCommon('datagrid.actions.export-limit-message.tooltip.__html', { count: LIMIT_XLSX })
          : '',
        disabled: saleRevenueLazyExportData.isLoading || disabledExportXlsxByLimit,
      },
    ];

    return {
      baseTextField: TextFieldOutlined,
      toolbar: () => (
        <ToolbarTable columns={columns} disabled={!globalSettingsData?.baseCurrency}>
          <ClaimDefenderComponent notFullWidth allowed={'REPORTS_SALES_REVENUE_EXPORT'}>
            <DatagridExportMenu
              loading={saleRevenueLazyExportData.isLoading}
              disabled={saleRevenueLazyExportData.isLoading}
              label={tCommon('export')}
              options={options}
            />
          </ClaimDefenderComponent>
        </ToolbarTable>
      ),
      noResultsOverlay: TableNoResults,
      noRowsOverlay: TableNoResults,
      pagination: DatagridPagination,
    };
  }, [
    columns,
    globalSettingsData?.baseCurrency,
    saleRevenueLazyExportData.mutate,
    saleRevenueLazyExportData.isLoading,
    data?.saleRevenueProfitReport?.totalCount,
  ]);

  return (
    <DatagridWrapper
      enableVirtualization
      loading={isFetching || isGlobalSettingsFetching}
      getRowId={(row) => row.lineItemId}
      rows={rows}
      columns={columns}
      components={components}
    />
  );
};
