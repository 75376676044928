import {
  ErrorGeneral,
  ErrorMeta,
  ErrorResponseAPIType,
  ErrorResponseCodeAndMessageAPIType,
} from '../../modules/shared/types';

export function envelope<T>(promise: Promise<T>) {
  return promise
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const isErrorCodeAndMessagePattern = !!(error as ErrorResponseCodeAndMessageAPIType)?.response
        ?.data?.errorMessage;

      if (isErrorCodeAndMessagePattern) {
        const messageAndCodeError = (error as ErrorResponseCodeAndMessageAPIType)?.response?.data;

        throw {
          title: messageAndCodeError.errorMessage,
          errors: [],
          instance: '',
          status: messageAndCodeError.errorCode || 450,
        };
      }

      const errorParsed = (error as ErrorResponseAPIType)?.response?.data?.error;
      const isResponseApiError = !!errorParsed?.errors;

      const generalError = !isResponseApiError
        ? (errorParsed as unknown as ErrorGeneral)
        : ({} as ErrorGeneral);

      throw isResponseApiError
        ? errorParsed
        : ({
            title: generalError?.title || 'Undefined error',
            errors: [],
            instance: generalError?.instance,
            status: generalError?.status || 400,
          } as unknown as ErrorMeta);
    });
}
