import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { longDateWithMonthDescription } from 'common/format/date';
import { CardForm, Show } from 'components';
import { isAfter, parseISO, sub } from 'date-fns';
import { routes } from 'routes';
import { nethansaPalette } from 'theme';
import { Alert, Box, Skeleton, Stack, Typography } from '@mui/material';
import { PaymentCardPage } from './components/section-payments/payment-card.page';
import { usePaymentInfoQuery } from './hooks';

export const PaymentsSettingsPage: React.FC = () => {
  const [t] = useTranslation('settings');
  const { i18n } = useTranslation();
  const { data, isFetched, isError } = usePaymentInfoQuery();

  const subtitleStyles = {
    color: nethansaPalette.main.gray,
    display: 'inline-block',
    mt: 2,
  };
  const PARAM_WIDTH = '152px';

  const deadlineDateToStartPay = () => sub(parseISO(data?.dateTo || Date()), { days: 3 });
  const showPayAlert = () => isAfter(new Date(), deadlineDateToStartPay());
  const isDedicated = data?.name?.toLowerCase().startsWith('dedicated');

  return (
    <CardForm>
      <Show when={!isFetched || isError || (!data && isFetched)}>
        <Show when={isError || (!data && isFetched)}>
          <Alert severity="error" sx={{ mb: 4 }}>
            {t('payments.error.fetch-problem')}
          </Alert>
        </Show>
        {Array.from(new Array(4)).map((item, index) => (
          <Stack key={index} sx={{ flexDirection: 'row', gap: 2, mb: 2 }}>
            <Skeleton variant="text" width={PARAM_WIDTH} height="1rem" />
            <Stack sx={{ height: '60px', width: `calc(100% - ${PARAM_WIDTH})` }}>
              {Array.from(new Array(3)).map((item, index) => (
                <Skeleton height="1rem" key={index} />
              ))}
            </Stack>
          </Stack>
        ))}
        <Skeleton variant="text" width={PARAM_WIDTH} height="1rem" />
        <Skeleton variant="rectangular" width="100%" height={60} />
      </Show>
      <Show when={!isError && isFetched && !!data}>
        <Stack
          sx={{
            gap: 3,
            fontSize: '13px',
            '& h5': { lineHeight: '18px', '& div': { minHeight: '24px' } },
            '& a': { textDecoration: 'underline' },
          }}
        >
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography variant="h5" sx={{ minWidth: PARAM_WIDTH }}>
              {t('payments.params.subscription.label')}:
            </Typography>
            <Typography variant="h5" sx={{ lineHeight: '24px !important' }}>
              <Trans
                i18nKey="payments.params.subscription.value"
                t={t}
                values={{
                  name: data?.licenseState == 'Trial' ? 'Free Trial' : data?.name || '',
                  dateFrom: longDateWithMonthDescription(
                    data?.dateFrom || new Date(),
                    i18n.language,
                  ),
                  dateTo: longDateWithMonthDescription(data?.dateTo || new Date(), i18n.language),
                }}
              />
            </Typography>
          </Stack>
          <Show when={!isDedicated}>
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography variant="h5" sx={{ minWidth: PARAM_WIDTH }}>
                {t('payments.params.charge.label')}:
              </Typography>
              <Typography variant="h5">
                <Box>
                  <Trans
                    i18nKey="payments.params.charge.value-line1"
                    t={t}
                    values={{
                      currency: data?.price.currencyCode,
                      price: data?.price.value?.toFixed(2),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      showPayAlert() ||
                      (!!data?.payments.length && data?.payments[0].paymentStatus !== 'Paid')
                        ? nethansaPalette.main.watermelon
                        : nethansaPalette.main.dark,
                  }}
                >
                  <Trans
                    i18nKey={
                      data?.licenseState == 'Trial'
                        ? 'payments.params.charge.value-line2a'
                        : 'payments.params.charge.value-line2b'
                    }
                    t={t}
                    values={{
                      dateTo: longDateWithMonthDescription(
                        data?.dateTo || new Date(),
                        i18n.language,
                      ),
                    }}
                  />
                </Box>
                <Show when={data?.licenseState == 'Trial' && !!data.name?.length}>
                  <Box>
                    <Trans
                      i18nKey="payments.params.charge.value-line3"
                      t={t}
                      values={{
                        name: data?.name,
                      }}
                    />
                  </Box>
                </Show>
                <Show when={showPayAlert()}>
                  <Box sx={{ color: nethansaPalette.main.watermelon }}>
                    <Trans
                      i18nKey="payments.pay-alert"
                      t={t}
                      values={{
                        name: data?.name || '',
                      }}
                    />
                  </Box>
                </Show>
                <Show when={!!data?.payments.length && data?.payments[0].paymentStatus !== 'Paid'}>
                  <Box sx={{ color: nethansaPalette.main.watermelon }}>
                    {t('payments.error.last-payment-fail')}
                  </Box>
                </Show>
              </Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography variant="h5" sx={{ minWidth: PARAM_WIDTH }}>
                {t('payments.params.billing.label')}:
              </Typography>
              <Typography variant="h5">
                <Box sx={{ fontWeight: 700 }}>
                  <Trans
                    i18nKey="payments.params.billing.value-line1"
                    t={t}
                    values={{
                      count: data?.period,
                    }}
                  />
                </Box>
                <Box>
                  <Trans i18nKey="payments.params.billing.value-line2" t={t} />
                </Box>
              </Typography>
            </Stack>
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography variant="h5" sx={{ minWidth: PARAM_WIDTH }}>
                {t('payments.params.invoice.label')}:
              </Typography>
              <Typography variant="h5">
                <Trans
                  i18nKey="payments.params.invoice.value"
                  t={t}
                  components={[
                    <span></span>,
                    <a
                      href={routes.Settings.children.PaymentsAndBillings.children.BillingData.link()}
                    ></a>,
                  ]}
                />
              </Typography>
            </Stack>
            <Box>
              <Typography variant="smallUppercase" sx={subtitleStyles}>
                {t('payments.subtitle-method')}
              </Typography>
              <Typography variant="h5">
                <Show when={data?.paymentMethod == 'AdyenSubscription'}>
                  <Trans i18nKey="payments.credit-card.description" t={t}>
                    text
                    <a href="https://www.adyen.com/contact" target="_blank">
                      Link to Adyen
                    </a>
                    text
                  </Trans>
                  <PaymentCardPage subscription={data} />
                </Show>
                <Show when={data?.paymentMethod == 'AccountTransfer'}>
                  <Trans i18nKey="payments.accout-transfer" t={t} />
                </Show>
              </Typography>
            </Box>
          </Show>
          <Box>
            <Typography variant="smallUppercase" sx={subtitleStyles}>
              {t('payments.subtitle-change')}
            </Typography>
            <Typography variant="h5">
              <Trans i18nKey="payments.contact-support" t={t}>
                text<a href="mailto:platform.support@nethansa.com">email</a>text
                {i18n.language === 'pl' ? (
                  <a href="https://nethansa.com/pl/cennik/" target="_blank">
                    here
                  </a>
                ) : (
                  <a href="https://nethansa.com/nethansa-platform-pricing/" target="_blank">
                    here
                  </a>
                )}
                text
              </Trans>
            </Typography>
          </Box>
        </Stack>
      </Show>
    </CardForm>
  );
};
