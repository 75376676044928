import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Show } from '../../../../components';
import { hasUserRightClaims } from '../../helpers/claims-checker';
import { useUser } from '../../hooks/use-user';
import { Claim } from '../../types';

type Props = React.PropsWithChildren<{
  allowed: Claim | Claim[];
  hidden?: boolean;
  notFullWidth?: boolean;
  showInfoBanner?: boolean;
}>;

export const ClaimDefenderComponent: React.FC<Props> = (props) => {
  const { t } = useTranslation('common');
  const container = useRef<HTMLDivElement>(null);
  const { claimsFetched, claims } = useUser();

  const hasRightClaims = hasUserRightClaims(props.allowed, claims);
  const hasRightClaimsToRender = claimsFetched ? hasRightClaims : false;

  if (props.hidden) {
    return <Show when={hasRightClaimsToRender}>{props.children}</Show>;
  }

  useEffect(() => {
    if (!container.current) return;

    const selector = 'input, select, [type="button"], [role="button"]';

    if (!hasRightClaims) {
      container.current
        .querySelectorAll(selector)
        .forEach((element) => element.setAttribute('tabindex', '-1'));
      return;
    }

    container.current
      .querySelectorAll(selector)
      .forEach((element) => element.removeAttribute('tabindex'));
  }, [hasRightClaims]);

  const styles: SxProps<Theme> = {
    position: 'relative',
    width: props.notFullWidth ? 'unset' : '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      width: props.notFullWidth ? 'unset' : '100%',
    },
  };

  const noAccessStyles: SxProps<Theme> = !hasRightClaimsToRender
    ? {
        opacity: 0.7,
        pointerEvents: 'none',
      }
    : {};

  return (
    <>
      <Show when={!hasRightClaimsToRender && !!props.showInfoBanner}>
        <Alert severity="warning" sx={{ maxWidth: '448px', m: 3, mb: 0 }}>
          {t('role.no-edit')}
        </Alert>
      </Show>
      <Box ref={container} sx={{ ...styles, ...noAccessStyles }}>
        {props.children}
      </Box>
    </>
  );
};
