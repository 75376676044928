import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, Show, TooltipWrapper } from 'components';
import { BlockedIcon, HolidaysIcon } from 'components/icons';
import { PlatformCode, SharedOfferStateEnum } from 'generated/graphql';
import { DatagridCellFlag } from 'modules/datagrid';
import { OfferDetailsType } from 'modules/offers/types';
import { usePlatformQuery } from 'modules/settings-sales-platforms/hooks';
import { PlatformType } from 'modules/shared';
import { nethansaPalette } from 'theme';
import { alpha, Box, DialogTitle, Stack, Typography } from '@mui/material';
import { LeftLineParams } from '.';
import { DataGridSharedStateCell } from '../../../components/table-offers/components/shared-state-cell';

type Props = {
  onClose: () => void;
  data?: OfferDetailsType;
};

export const OfferDetailsHeader: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  const [c] = useTranslation('currencies');

  const platformType =
    props.data?.channelCode === 'DEFAULT' ? PlatformCode.Kauf : PlatformCode.Amzeu;
  const { data: platformData } = usePlatformQuery(platformType as PlatformType);
  const marketplaceInfo = platformData?.partnerChannels
    .filter((c) => c.name == props.data?.channelCode)[0]
    .partnerMarketplaces.filter((m) => m.countryCode == props.data?.marketplaceCode)[0];

  return (
    <DialogTitle
      component="header"
      sx={{
        zIndex: 2,
        position: 'relative',
        boxShadow: `0px 0px 10px ${alpha(nethansaPalette.main.accent, 0.3)}`,
        p: 2,
        pr: 6,
        pb: 1.5,
      }}
    >
      <Typography
        variant="h2"
        title={props.data?.productName}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {props.data?.productName}
      </Typography>
      <Stack direction="row" spacing={8} pt={2} minWidth={8}>
        <LeftLineParams>
          <Show when={!!props.data}>
            <DataGridSharedStateCell
              isRowDirection
              sharedState={props.data?.status || SharedOfferStateEnum.Unknown}
              sharedSubStates={props.data?.detailedStatus || []}
            />
          </Show>
        </LeftLineParams>

        <LeftLineParams>
          <Stack direction="row" alignItems="flex-start">
            <Box sx={{ mr: 1 }}>
              <DatagridCellFlag
                value={props.data?.marketplaceCode || 'unknown'}
                label={props.data?.domain}
                href={props.data?.asin ? props.data?.productUrl : ''}
              />
            </Box>

            {marketplaceInfo?.isConfigured === false && (
              <TooltipWrapper
                content={t('offer-details.marketplaceStatus.blocked')}
                disableHoverListener={false}
                placement="right"
              >
                <Box>
                  <BlockedIcon sx={{ color: nethansaPalette.main.gray }} />
                </Box>
              </TooltipWrapper>
            )}
            {marketplaceInfo?.isBreak && (
              <TooltipWrapper
                content={t('offer-details.marketplaceStatus.holidays')}
                disableHoverListener={false}
                placement="right"
              >
                <Box>
                  <HolidaysIcon sx={{ color: nethansaPalette.main.gray }} />
                </Box>
              </TooltipWrapper>
            )}
          </Stack>
          <Box>
            {t('offer-details.channel', {
              context: props.data?.channelCode === 'AMZFBA' ? 'platform' : 'merchant',
            })}
          </Box>
        </LeftLineParams>

        <LeftLineParams>
          <Stack direction="row">
            <Box minWidth={128}>{t('column.calculatedPrice.label') + ':'}</Box>
            <Box minWidth={64} textAlign="right">
              {props.data?.calculatedPrice?.toFixed(2) || '-'}&nbsp;
              {props.data?.currencyCode
                ? c(`${props.data.currencyCode}.short` as 'EUR.short')
                : '-'}
            </Box>
          </Stack>
          <Stack direction="row">
            <Box minWidth={128}>{t('column.listingPrice.label') + ':'}</Box>
            <Box minWidth={64} textAlign="right">
              {props.data?.listingPrice?.toFixed(2) || '-'}&nbsp;
              {props.data?.currencyCode
                ? c(`${props.data.currencyCode}.short` as 'EUR.short')
                : '-'}
            </Box>
          </Stack>
        </LeftLineParams>
      </Stack>
      <CloseButton
        onClick={props.onClose}
        sx={{ position: 'absolute', top: '16px', right: '16px' }}
      />
    </DialogTitle>
  );
};
