import { useTranslation } from 'react-i18next';
import { Show, Tooltip, TooltipWrapper } from 'components';
import { MoreIcon } from 'components/icons';
import { useProductsCoordinatorContext } from 'modules/products/hooks';
import { ProductGridRow } from 'modules/products/types';
import { nethansaPalette } from 'theme';
import { Box, Divider, IconButton, List, ListItemButton, Stack } from '@mui/material';
import { ProductGridDtoFilterInput } from '../../../../generated/graphql';
import { setExternalIdInOfferFilter } from '../../../authorization/helpers';
import { mapSelectedProductIdsToFilter } from '../../../datagrid';

type Props = {
  product: ProductGridRow;
};

export const ButtonMenuProducts: React.FC<Props> = (props) => {
  const {
    editHandlingTime: [, setEditHandlingTime],
    editProductCost: [, setEditProductCost],
    editVat: [, setEditVat],
    editTags: [, setEditTags],
    createOffers: [, setCreateOffers],
    deleteProduct: [, setDeleteProduct],
    acceptSuspectedCost: [, setAcceptSuspectedCost],
  } = useProductsCoordinatorContext();

  const [t] = useTranslation('products');
  const suspectedCost = props.product.suspiciousProductCost !== null;

  const manageMenu = (
    <List sx={{ px: '0!important', '&>*': { fontSize: '13px' } }}>
      <ListItemButton
        data-testid="products-editHandlingTime"
        onClick={() => {
          setEditHandlingTime({
            row: props.product,
            filters: mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>([props.product.id])
              .filter,
          });
        }}
      >
        {t('actions.editHandlingTime')}
      </ListItemButton>
      <ListItemButton
        data-testid="products-editProductCost"
        onClick={() => {
          setEditProductCost({
            row: props.product,
            filters: mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>([props.product.id])
              .filter,
          });
        }}
      >
        {t('actions.editProductCost')}
      </ListItemButton>
      <ListItemButton
        data-testid="products-editVAT"
        onClick={() => {
          setEditVat({
            row: props.product,
            filters: mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>([props.product.id])
              .filter,
          });
        }}
      >
        {t('actions.editVAT')}
      </ListItemButton>
      <ListItemButton
        data-testid="products-editTags"
        onClick={() => {
          setEditTags({
            row: props.product,
            filters: mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>([props.product.id])
              .filter,
          });
        }}
      >
        {t('actions.editTags')}
      </ListItemButton>
      <ListItemButton
        data-testid="products-acceptSuspectedCost"
        onClick={() => {
          if (suspectedCost)
            setAcceptSuspectedCost({
              row: props.product,
              filters: mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>([props.product.id])
                .filter,
            });
        }}
      >
        <Stack direction="row" spacing={1}>
          <Box sx={{ opacity: !suspectedCost ? 0.5 : 1 }}>{t('actions.acceptSuspectedCost')}</Box>
          <Show when={!suspectedCost}>
            <Tooltip
              sx={{ pr: '10px' }}
              content={t('actions.action-disabled', {
                action: t('actions.acceptSuspectedCost'),
              })}
            ></Tooltip>
          </Show>
        </Stack>
      </ListItemButton>

      <Divider />
      <ListItemButton
        data-testid="products-createOffers"
        onClick={() => {
          setCreateOffers({
            row: props.product,
            filters: mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>([props.product.id])
              .filter,
          });
        }}
      >
        {t('actions.createOffers')}
      </ListItemButton>
      <ListItemButton
        data-testid="products-showOffer"
        onClick={() => {
          setExternalIdInOfferFilter(props.product.externalId || '');
          window.location.href = '/Offers';
        }}
      >
        {t('actions.showOffers')}
      </ListItemButton>

      <Divider />
      <ListItemButton
        sx={{ color: nethansaPalette.main.watermelon }}
        data-testid="products-delete"
        onClick={() => {
          setDeleteProduct({
            row: props.product,
            filters: mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>([props.product.id])
              .filter,
          });
        }}
      >
        {t('actions.deleteProduct')}
      </ListItemButton>
    </List>
  );

  return (
    <Stack alignItems="center">
      <TooltipWrapper
        content={manageMenu}
        placement="right-start"
        componentsProps={{
          tooltip: {
            sx: {
              p: 0,
            },
          },
        }}
      >
        <IconButton size="small">
          <MoreIcon sx={{ fontSize: '18px' }} />
        </IconButton>
      </TooltipWrapper>
    </Stack>
  );
};
