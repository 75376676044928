import React, { ReactElement, useMemo } from 'react';
import { CarrierName } from 'modules/shared/types';
import { Divider, Stack, Typography } from '@mui/material';
import { useCarriersListQuery, useIntegrationContext } from '../../hooks';
import { ButtonManageCarrier, DisconnectCarrierModal, ModalCarrierForm } from './components';

export const SectionIntegrations: React.FC = () => {
  const { data } = useCarriersListQuery();

  const {
    connector: [connectorCarrierCode],
    disconnectCarrier: [disconnectCarrierCode, setDisconnectCarrierCode],
  } = useIntegrationContext();

  const modals = useMemo<{ [key in CarrierName]: ReactElement }>(
    () => ({
      DHL: <ModalCarrierForm carrierName="DHL" />,
      DPD: <ModalCarrierForm carrierName="DPD" />,
      GLS: <ModalCarrierForm carrierName="GLS" />,
      UPS: <ModalCarrierForm carrierName="UPS" />,
      'EH DHL': <ModalCarrierForm carrierName="EH DHL" />,
      'EH COLSYG': <ModalCarrierForm carrierName="EH COLSYG" />,
      'EH RML48T': <ModalCarrierForm carrierName="EH RML48T" />,
    }),
    [data],
  );

  return (
    <>
      <Stack
        spacing={2}
        divider={
          <Divider sx={{ width: '100%', backgroundColor: 'nethansa.line.light', opacity: 0.5 }} />
        }
      >
        {(data || [])?.map((x) => (
          <Stack
            key={x.carrierId}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2" color={!x.isActive ? 'nethansa.main.gray' : 'text.main'}>
              {x.name}
            </Typography>
            <ButtonManageCarrier connected={x.isActive} carrierName={x.name} />
          </Stack>
        ))}
      </Stack>
      {connectorCarrierCode && modals[connectorCarrierCode]}
      {disconnectCarrierCode && (
        <DisconnectCarrierModal
          carrierName={disconnectCarrierCode}
          onClose={() => setDisconnectCarrierCode(undefined)}
        />
      )}
    </>
  );
};
