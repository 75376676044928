import { PaperClasses, PaperProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const paperStyles: {
  defaultProps?: Partial<PaperProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof PaperClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      borderRadius: '6px',
    },
    elevation: {
      boxShadow: `0px 3px 5px ${nethansaPalette.line.light}`,
    },
    elevation24: {
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px',
    },
  },
};
