import React from 'react';
import { useTranslation } from 'react-i18next';
import { PriceManagementMode } from 'generated/graphql';
import isEqual from 'lodash.isequal';
import { UseDataGridSharedStatesOperator } from 'modules/datagrid/hooks/use-datagrid-shared-states-operator';
import { PlatformChannelCode } from 'modules/shared/types';
import { MODULE_FILTERS_GROUP_BAR_HEIGHT, nethansaPalette } from 'theme';
import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { GridLogicOperator } from '@mui/x-data-grid';
import { TabWithCount } from '../../../../components';
import { useDatagridContext, useDatagridViewContext } from '../../../datagrid';
import { GridStateType } from '../../../datagrid/types';
import { OfferGridColumn } from '../../types';

type PredefinedView = {
  id: string;
  label: string;
  columns: OfferGridColumn[];
  gridConfiguration: Pick<GridStateType, 'filter' | 'sort'>;
};

type Props = {
  channel: PlatformChannelCode;
};

export const TablePredefinedFiltersTabs: React.FC<Props> = (props) => {
  const { filter, sort } = useDatagridContext();
  const { currentView } = useDatagridViewContext();
  const [t] = useTranslation('offers');
  const sharedStatesOperator = UseDataGridSharedStatesOperator();

  const amazonViews = [
    {
      id: '41235d48-8dc9-4e55-8457-9c39aca3bdb7',
      label: t('filters-group.asin-verification'),
      gridConfiguration: {
        filter: {
          items: [
            {
              field: 'sharedOfferState',
              operator: 'sharedStatus',
              id: 90813,
              value: 'MULTIPLE_LISTINGS_FOUND',
            },
          ],
          linkOperator: GridLogicOperator.And,
        },
      },
      columns: [{ field: 'sharedOfferState', filterOperators: sharedStatesOperator }],
    },
  ];

  const predefinedViews: PredefinedView[] = [
    {
      id: '9f25fea1-3cfc-4f7b-b317-baba699f5326',
      label: t('filters-group.ready-to-send'),
      gridConfiguration: {
        filter: {
          items: [
            {
              field: 'sharedOfferState',
              operator: 'sharedStatus',
              id: 90812,
              value: 'READY_TO_SEND',
            },
          ],
          logicOperator: GridLogicOperator.And,
        },
      },
      columns: [{ field: 'detailedStatus', filterOperators: sharedStatesOperator }],
    },
    ...(props.channel !== 'DEFAULT' ? amazonViews : []),
    {
      id: '7a70f121-dad0-4570-9d24-9c7ca4a8615d',
      label: t('filters-group.almost-best'),
      columns: [
        { field: 'changeToBestMarkup', type: 'number' },
        { field: 'repricingEnabled', type: 'boolean' },
        { field: 'productStock', type: 'number' },
      ],
      gridConfiguration: {
        sort: [
          {
            field: 'changeToBestMarkup',
            sort: 'desc',
          },
        ],
        filter: {
          items: [
            {
              field: 'changeToBestMarkup',
              operator: '>',
              id: 90812,
              value: '-5',
            },
            {
              field: 'changeToBestMarkup',
              operator: '<',
              id: 19318,
              value: '0',
            },
            {
              field: 'repricingEnabled',
              operator: 'is',
              id: 33275,
              value: 'true',
            },
            {
              field: 'productStock',
              operator: '>',
              id: 39024,
              value: '0',
            },
          ],
          logicOperator: GridLogicOperator.And,
        },
      },
    },
    {
      id: '9f25fea1-3cfc-4f7b-b317-baba6456dd26',
      label: t('filters-group.dynamic-pricing'),
      gridConfiguration: {
        filter: {
          items: [
            {
              field: 'priceManagementMode',
              operator: 'is',
              id: 90814,
              value: PriceManagementMode.Dynamic,
            },
          ],
          logicOperator: GridLogicOperator.And,
        },
      },
      columns: [{ field: 'priceManagementMode' }],
    },
  ];

  const rootStyles: SxProps<Theme> = {
    height: MODULE_FILTERS_GROUP_BAR_HEIGHT,
    px: 3,
    backgroundColor: 'nethansa.background.group',
    border: `1px solid ${nethansaPalette.button.grayHover}`,
    borderRight: 0,
    borderLeft: 0,
  };

  const changeFiltersAndSort = (gridConfiguration: Pick<GridStateType, 'filter' | 'sort'>) => {
    filter[1](gridConfiguration.filter || { items: [] });
    sort[1](gridConfiguration.sort || []);
  };

  const resetToViewConfig = () => {
    filter[1](currentView?.gridConfiguration.filter || { items: [] });
    sort[1](currentView?.gridConfiguration.sort || []);
  };

  const checkActiveTab = (gridConfiguration: Pick<GridStateType, 'filter' | 'sort'>): boolean => {
    return isEqual(
      { sort: gridConfiguration.sort || [], filter: gridConfiguration.filter || {} },
      { sort: sort[0] || [], filter: { ...filter[0] } || {} },
    );
  };

  return (
    <Stack direction="row" spacing={2} sx={rootStyles} alignItems="center">
      {predefinedViews.map((x, index) => {
        if (!x.gridConfiguration.filter) return null;

        const isActive = checkActiveTab(x.gridConfiguration);

        return (
          <TabWithCount
            id={x.id}
            key={index}
            active={isActive}
            label={x.label}
            columns={x.columns}
            channel={props.channel}
            filters={x.gridConfiguration.filter}
            onClick={() =>
              isActive ? resetToViewConfig() : changeFiltersAndSort(x.gridConfiguration)
            }
          />
        );
      })}
    </Stack>
  );
};
