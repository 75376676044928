import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { sortBy } from '../../../common/utils';
import { useAuth } from '../../authorization';
import { PlatformType, ResponseAPIType } from '../../shared/types';
import { Platform, PlatformPartnerChannel } from '../types';

export type GeneralPlatformResponseType = Platform & {
  sellerId?: string;
  authToken?: string;
  clientKey?: string;
  secretKey?: string;
  spApiToken?: string;
};

export const usePlatformQuery = (
  type: PlatformType,
): UseQueryResult<GeneralPlatformResponseType> => {
  const { authorized, currentPartnerId } = useAuth();
  return useQuery<GeneralPlatformResponseType, Error, GeneralPlatformResponseType>(
    ['settings', 'platform', type, currentPartnerId],
    async () => {
      const response = await httpClient.get<ResponseAPIType<GeneralPlatformResponseType>>(
        API.salesIntelligence(currentPartnerId).singlePlatform(type),
      );

      const partnerChannels: PlatformPartnerChannel[] = [...response.data.data.partnerChannels]
        .sort((a) => (a.name === 'AMZFBM' ? -1 : 1))
        .sort((a, b) => b.partnerMarketplaces.length - a.partnerMarketplaces.length);

      return {
        ...response.data.data,
        partnerChannels: partnerChannels.map((x) => ({
          ...x,
          partnerMarketplaces: x.partnerMarketplaces.sort(sortBy('countryCode')),
        })),
      };
    },
    {
      staleTime: 5000,
      enabled: authorized,
    },
  );
};
