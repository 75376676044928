import React, { useCallback, useEffect } from 'react';
import { nethansaPalette } from 'theme';
import { Box } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

type Props = React.PropsWithChildren<unknown>;

export const DatagridDetailPanelContent: React.FC<Props> = (props) => {
  const apiRef = useGridApiContext();
  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions?.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions?.viewportInnerSize.width);
  }, [apiRef]);

  useEffect(() => {
    return apiRef.current.subscribeEvent('viewportInnerSizeChange', handleViewportInnerSizeChange);
  }, [apiRef, handleViewportInnerSizeChange]);

  return (
    <Box
      sx={{
        bgcolor: nethansaPalette.background.titleDark,
        height: '100%',
        borderBottom: `1px solid ${nethansaPalette.line.dark}`,
        position: 'sticky',
        left: 0,
        width,
      }}
    >
      {props.children}
    </Box>
  );
};
