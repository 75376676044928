import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, Dialog } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, Stack, Typography } from '@mui/material';

type Props = {
  open: boolean;
  onClose: (confirm: boolean) => void;
};

export const WizardPlatformsDisconnectModal: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('wizard');

  return (
    <Dialog open={props.open}>
      <Stack
        sx={{ minWidth: '500px', p: '32px' }}
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h1" sx={{ color: 'error.main' }}>
          {t('connect-platforms.disconnect-modal.title')}
        </Typography>
        {props.onClose && <CloseButton onClick={() => props.onClose(false)} />}
      </Stack>

      <Box sx={{ px: '32px', pb: '32px', maxWidth: '500px' }}>
        <Typography variant="h5">
          {t('connect-platforms.disconnect-modal.description.top')}
        </Typography>
        <Typography variant="h5">
          {t('connect-platforms.disconnect-modal.description.bottom')}
        </Typography>
      </Box>

      <Stack
        direction="row"
        justifyContent="flex-end"
        gap="10px"
        sx={{ py: 2, px: 4, borderTop: '1px solid #DDE8F1' }}
      >
        <Button variant="text" size="small" onClick={() => props.onClose(false)}>
          {tCommon('cancel')}
        </Button>
        <Button size="small" type="submit" onClick={() => props.onClose(true)}>
          {tCommon('confirm')}
        </Button>
      </Stack>
    </Dialog>
  );
};
