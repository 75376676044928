import React from 'react';
import { AcceptSuspectedCost, EditHandlingTimeModal, EditVatModal } from './modals';
import { CreateOffersModal } from './modals/modal-create-offers/create-offers.modal';
import { DeleteProductModal } from './modals/modal-delete-product/delete-product.modal';
import { EditProductCostModal } from './modals/modal-edit-product-cost/edit-product-cost.modal';
import { EditTagsModal } from './modals/modal-edit-tags/edit-tags.modal';
import { ShoppingBasket } from './modals/modal-shopping-basket/shopping-basket.modal';
import { ProductsModalCoordinatorContext, ProductsModalsCoordinatorProvider } from './providers';

type Props = React.PropsWithChildren;

export const ProductsModalsCoordinator: React.FC<Props> = (props) => {
  return (
    <ProductsModalsCoordinatorProvider>
      {props.children}
      <ProductsModalCoordinatorContext.Consumer>
        {(params) => (
          <>
            {params.editHandlingTime[0] && <EditHandlingTimeModal />}
            {params.editProductCost[0] && <EditProductCostModal />}
            {params.editVat[0] && <EditVatModal />}
            {params.editTags[0] && <EditTagsModal />}
            {params.deleteProduct[0] && <DeleteProductModal />}
            {params.createOffers[0] && <CreateOffersModal />}
            {params.acceptSuspectedCost[0] && <AcceptSuspectedCost />}
            {params.shoppingBasket[0] && <ShoppingBasket />}
          </>
        )}
      </ProductsModalCoordinatorContext.Consumer>
    </ProductsModalsCoordinatorProvider>
  );
};
