import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipWrapper } from 'components';
import { Show } from 'components/show';
import { Tag } from 'components/tags/tag';
import { Box, Chip, List, ListItem, ListItemText, Stack } from '@mui/material';

type Props = {
  tags: string[];
  color: string;
  maxTags?: number;
};

export const DatagridCellTags: React.FC<Props> = (props) => {
  const restCount = props.maxTags ? props.tags.slice(props.maxTags, props.tags.length).length : 0;

  const { t } = useTranslation('offers');

  const reversedTags = useMemo(() => [...props.tags].reverse(), [props.tags.length]);

  const tagsList = (
    <List sx={{ p: '0 !important', mt: '0 !important' }}>
      <ListItemText
        primary={t('tagTooltipText')}
        sx={{ mt: 0, mb: 1.25, '& > span': { fontSize: '14px' } }}
      />
      {reversedTags.map((tag) => (
        <ListItem key={tag} sx={{ py: 0.25, px: 0 }}>
          <Tag title={tag} color={props.color} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Stack
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      flexDirection={'column'}
      gap={0.5}
    >
      <Show when={!props.tags.length}>
        <Box sx={{ color: 'nethansa.button.grayHover' }}>—</Box>
      </Show>
      {reversedTags.slice(0, props.maxTags || props.tags.length).map((x) => (
        <Tag key={x} title={x} color={props.color} />
      ))}
      <Show when={restCount > 0}>
        <TooltipWrapper placement="right-end" content={tagsList}>
          <Chip
            variant="outlined"
            size="small"
            sx={{
              color: 'nethansa.main.gray',
              backgroundColor: 'nethansa.main.light',
              fontSize: '12px',
              borderRadius: '6px',
              borderWidth: '2px',
              borderColor: 'nethansa.line.light',

              '&:hover': {
                borderColor: 'nethansa.button.grayLight',
              },
            }}
            label={`+${restCount}`}
          />
        </TooltipWrapper>
      </Show>
    </Stack>
  );
};
