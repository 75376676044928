import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabsLayout } from 'components/navigation-tabs';
import { AppGlobalParametersContext } from 'modules/shared/contexts/app-global-parameters.provider';

export const DashboardLayout: React.FC = () => {
  const [t] = useTranslation('dashboard');
  const showContext = useContext(AppGlobalParametersContext);

  return (
    <NavigationTabsLayout
      title={t('title')}
      subtitle={t('subtitle')}
      isHidden={!showContext.monitoringModuleWithBars}
      isNotSticky
    >
      {/* <NavigationTabsItem
        href={routes.Dashboard.children.Tutorials.link()}
        children={'Tutorials'}
      /> */}
      {/* <NavigationTabsItem
                href={routes.Dashboard.children.Current.link()}
                children={'Current'}/> */}
    </NavigationTabsLayout>
  );
};
