import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { useAuth } from '../../authorization';
import { CarriersShippingInputType } from '../types';

export const useCarriersShippingCountriesMutation = (): UseMutationResult<
  void,
  Error,
  CarriersShippingInputType
> => {
  const { currentPartnerId } = useAuth();

  return useMutation([...settingsDefaultMutationKeys], async (body: CarriersShippingInputType) => {
    await httpClient.put<void, Error, CarriersShippingInputType>(
      API.partnerCarrier(currentPartnerId).shippingCountries(),
      body,
    );
    return;
  });
};
