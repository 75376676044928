import { ButtonGroupClasses, ButtonGroupProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    flat: true;
  }

  interface ButtonGroupClasses {
    containedFlat: true;
    outlinedFlat: true;
  }
}

export const buttonGroupStyles: {
  defaultProps?: Partial<ButtonGroupProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof ButtonGroupClasses>> | undefined;
} = {
  defaultProps: {
    variant: 'contained',
    color: 'secondary',
  },
  styleOverrides: {
    root: {
      borderRadius: '22px',
      fontWeight: 700,
      fontSize: '12px',
      boxShadow: 'none',
      lineHeight: '15px',
      letterSpacing: '0.22px',
      '&:hover': {
        boxShadow: 'none',
      },
      '& .MuiButtonGroup-grouped': {
        minWidth: '32px',
      },
      '& .MuiButton-containedFlat': {
        color: nethansaPalette.main.dark,
        '&.MuiButtonGroup-grouped': {
          borderRightColor: nethansaPalette.main.light,
        },
        '&:hover': {
          backgroundColor: nethansaPalette.button.grayHover,
        },
        '&.Mui-disabled': {
          backgroundColor: nethansaPalette.button.grayLight,
          color: nethansaPalette.main.gray,
          '&.MuiButtonGroup-grouped': {
            borderRightColor: nethansaPalette.main.light,
          },
        },
      },
    },
  },
};
