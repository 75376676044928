import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseButton, Dialog, FormAlert, FormButtonsGroup, SelectField } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { CarrierName } from 'modules/shared/types';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from '../../../../../snackbar';
import {
  useCarrierData,
  useCarriersListQuery,
  useCarriersShippingCountriesQuery,
  useIntegrationContext,
} from '../../../../hooks';
import { useCarriersDisconnectMutation } from '../../../../hooks/use-carriers-disconnect.mutation';

type Props = {
  carrierName?: CarrierName;
  onClose: (confirm: boolean) => void;
};

type FormProps = {
  carrierToDeleteId?: number;
  newDefaultCarrierId?: number;
};

export const DisconnectCarrierModal: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('settings');
  const { pushSnackbar } = useSnackbar();
  const carriersListQuery = useCarriersListQuery();
  const { disconnectCarrier } = useIntegrationContext();
  const carriersShippingCountriesQuery = useCarriersShippingCountriesQuery();
  const { data } = useCarrierData(disconnectCarrier[0] as CarrierName);
  const carriersDisconnectMutation = useCarriersDisconnectMutation();

  const carriers = useMemo(
    () =>
      carriersListQuery.data
        ?.filter((x) => !x.isDefault && x.isActive)
        .map((x) => ({
          value: x.carrierId,
          children: x.name,
        })) || [],
    [carriersListQuery.data?.length],
  );

  const isDefault = data?.isDefault || false;
  const isMoreThenZero = carriers.length > 0;
  const isDefaultFieldVisible = isDefault && isMoreThenZero;

  const formMethods = useForm<FormProps>({
    defaultValues: {
      carrierToDeleteId: data?.id,
      newDefaultCarrierId: isDefaultFieldVisible ? carriers[0]?.value || undefined : undefined,
    },
  });

  const submit = (body: FormProps) => {
    return carriersDisconnectMutation.mutate(
      {
        carrierToDeleteId: String(body.carrierToDeleteId),
        newDefaultCarrierId: body.newDefaultCarrierId,
      },
      {
        onSuccess: () => {
          props.onClose(true);
          carriersListQuery.refetch();
          carriersShippingCountriesQuery.refetch();
          pushSnackbar({ variant: 'success', message: t('carriers.disconnect-modal.success') });
        },
      },
    );
  };

  return (
    <Dialog open>
      <form onSubmit={formMethods.handleSubmit(submit)}>
        <Stack
          sx={{ minWidth: '496px', p: '32px' }}
          direction="row"
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h1" sx={{ color: 'error.main' }}>
            {t('carriers.disconnect-modal.title')}
          </Typography>
          {props.onClose && <CloseButton onClick={() => props.onClose(false)} />}
        </Stack>

        <Box sx={{ px: 4, pb: 3, maxWidth: '500px' }}>
          <Typography variant="h5">
            {t('carriers.disconnect-modal.description.top', {
              carrier: t(`carriers.code-to-text.${props.carrierName || 'DPD'}`),
            })}
          </Typography>
          <Typography sx={{ mt: 1 }} variant="h5">
            {t('carriers.disconnect-modal.description.bottom')}
          </Typography>
        </Box>

        {isDefaultFieldVisible && (
          <Stack sx={{ mt: 2, px: 4, pb: 1 }}>
            <Controller
              control={formMethods.control}
              name="newDefaultCarrierId"
              rules={{
                required: {
                  value: true,
                  message: t('form.newDefaultCarrierId.validation.required'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <SelectField
                    disabled={carriersDisconnectMutation.isLoading}
                    value={String(field.value)}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    label={t('form.newDefaultCarrierId.label')}
                    placeholder={t('form.newDefaultCarrierId.placeholder')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 2, flex: 1, m: 0 }}
                    options={carriers}
                  />
                );
              }}
            />
          </Stack>
        )}

        <Box sx={{ px: 2, pb: 1 }}>
          <FormAlert
            show={carriersDisconnectMutation.isError}
            isSuccess={false}
            isError={carriersDisconnectMutation.isError}
            errorMessage={t('carriers.disconnect-modal.error.general')}
          />
        </Box>

        <FormButtonsGroup
          sx={{ py: 2, px: 4, borderTop: '1px solid #DDE8F1' }}
          isLoading={carriersDisconnectMutation.isLoading}
        >
          <Button
            variant="text"
            size="small"
            disabled={carriersDisconnectMutation.isLoading}
            onClick={() => props.onClose(false)}
          >
            {tCommon('cancel')}
          </Button>
          <Button size="small" type="submit" disabled={carriersDisconnectMutation.isLoading}>
            {tCommon('confirm')}
          </Button>
        </FormButtonsGroup>
      </form>
    </Dialog>
  );
};
