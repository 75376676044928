import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { API, httpClient } from 'common/api';
import { useAuth } from 'modules/authorization/hooks';
import { ResponseAPIType } from 'modules/shared/types';

type InputType = {
  file?: File;
};

export const useOfferPricesImportMutation = (): UseMutationResult<
  ResponseAPIType,
  AxiosError,
  InputType
> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    if (!body.file) throw new Error('File is missing');
    const form = new FormData();

    form.append('excelFile', body.file, body.file.name);

    return (
      await httpClient.post<ResponseAPIType>(API.offers(currentPartnerId).importPrices(), form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    ).data;
  });
};
