import React from 'react';
import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  justifyContent?: 'center' | 'flex-start' | 'flex-end';
  height?: number;
  width?: number | string;
  sx?: SxProps<Theme>;
};

export const RenderCell: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={props.justifyContent || 'flex-start'}
      spacing={1}
      sx={{
        height: props.height || '60px',
        minWidth: '103px',
        width: props.width ? props.width : '103px',
        '& > *': {
          pr: 1,
        },
        ...(props.sx || {}),
      }}
    >
      {props.children}
    </Stack>
  );
};
