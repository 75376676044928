import React from 'react';
import { FormButtonsGroup } from 'components';
import { Box, Divider, Skeleton, Stack } from '@mui/material';

export const EditASINFormSkeleton: React.FC = () => {
  return (
    <Box>
      <Stack
        direction="row"
        spacing={4}
        sx={{ pb: 4 }}
        divider={<Divider orientation="horizontal" sx={{ width: '1px' }} />}
      >
        <Box sx={{ maxWidth: '350px' }}>
          <Stack sx={{ pl: 4 }}>
            <Box>
              <Skeleton
                width="330px"
                height="200px"
                variant={'rectangular'}
                sx={{ borderRadius: '6px' }}
              />
            </Box>
          </Stack>
        </Box>
        <Stack sx={{ maxWidth: '520px', pr: 4 }} spacing={4}>
          <Skeleton
            variant={'rectangular'}
            height={50}
            width="490px"
            sx={{ borderRadius: '6px' }}
          />
          <Skeleton
            variant={'rectangular'}
            height={300}
            width="490px"
            sx={{ borderRadius: '6px' }}
          />
        </Stack>
      </Stack>
      <Divider />
      <FormButtonsGroup sx={{ py: 2, px: 3, pt: 2 }}>
        <Skeleton width="120px" height="50px" />
        <Skeleton width="120px" height="50px" />
      </FormButtonsGroup>
    </Box>
  );
};
