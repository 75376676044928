import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { Checkbox, DialogConfirmation, SelectField, Show } from 'components';
import { SpinnerIcon } from 'components/icons';
import {
  useProductVatMutation,
  useProductVatQuery,
  useProductsCoordinatorContext,
} from 'modules/products/hooks';
import { useVatRatesQuery } from 'modules/settings-sales-vat-rates/hooks/use-vat-rates.query';
import { TaxRateCountryType } from 'modules/settings-sales-vat-rates/types/vat-rate.type';
import { CountryCode } from 'modules/shared/types';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import {
  Alert,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { SelectedProductDetails } from '../components/selected-product-details';

type DataVatRow = TaxRateCountryType & { currentTaxRate: number | null };
type ProductVatFormProps = {
  vatRates: {
    availableTaxRates: number[];
    currentTaxRate: number | null;
    taxRate: number | null;
    isVatOnMarketplace: boolean;
    countryCode: CountryCode;
  }[];
};

export const EditVatModal: React.FC = () => {
  const {
    editVat: [actionParams, setActionParams],
  } = useProductsCoordinatorContext();

  const [t] = useTranslation('products');
  const { pushSnackbar } = useSnackbar();

  const {
    isLoading: isProductLoading,
    isError: isProductError,
    data: productData,
  } = useProductVatQuery();

  const {
    isLoading: isMarketLoading,
    isError: isMarketError,
    data: marketData,
  } = useVatRatesQuery();

  const productVatMutation = useProductVatMutation();

  const isAllLoaded: boolean = !isProductLoading && !isMarketLoading;
  const isError: boolean = isProductError || isMarketError || productVatMutation.isError;

  const data: DataVatRow[] = (productData?.marketTaxRates || [])
    .map((x) => {
      const vatObj = (marketData?.taxRates || []).filter((y) => y.countryCode === x.countryCode)[0];

      return {
        ...vatObj,
        currentTaxRate: x.currentTaxRate,
      };
    })
    .sort((a, b) => (a.country || '').localeCompare(b.country || ''));

  const [actionFinished, setActionFinished] = useState<boolean>(false);

  const refreshProducts = () => queryClient.refetchQueries({ active: true, queryKey: 'products' });
  const closeModal = () => setActionParams(undefined);

  const { control, reset, getValues } = useForm<ProductVatFormProps>({
    defaultValues: {
      vatRates: [
        {
          availableTaxRates: [0],
          currentTaxRate: null,
          taxRate: null,
          isVatOnMarketplace: true,
          countryCode: undefined,
        },
      ],
    },
  });

  const vatRatesValues = useWatch({
    control: control,
    name: 'vatRates',
  });

  useEffect(() => {
    const defaults: ProductVatFormProps = {
      vatRates: (data || []).map((x) => ({
        availableTaxRates: x.availableTaxRates || [],
        currentTaxRate: x.currentTaxRate,
        isVatOnMarketplace:
          typeof x.currentTaxRate === 'number' && x.currentTaxRate !== null ? false : true,
        taxRate: x.taxRate,
        countryCode: x.countryCode,
      })),
    };
    reset(defaults);
  }, [isAllLoaded]);

  const mutateData = useCallback((data: ProductVatFormProps) => {
    productVatMutation.mutate(
      {
        productVatRates: data.vatRates.map((x) => {
          const currentTaxRate = () => {
            if (x.isVatOnMarketplace) return null;

            return x.currentTaxRate === null
              ? Number(x.availableTaxRates[0])
              : Number(x.currentTaxRate);
          };

          return {
            countryCode: x.countryCode,
            setDefault: x.isVatOnMarketplace,
            taxRate: currentTaxRate(),
          };
        }),
      },
      {
        onSuccess: () => {
          handleResponse();
        },
      },
    );
  }, []);

  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }
    mutateData(getValues());
  };

  const handleResponse = () => {
    setActionFinished(true);
    actionParams?.onSuccess && actionParams?.onSuccess();

    if (!isError) {
      closeModal();
      pushSnackbar({
        variant: 'success',
        message: t('modals.editVat.messages.success'),
      });
      refreshProducts();
    }
  };

  return (
    <DialogConfirmation
      confirmLabel={t('modals.buttons.save')}
      open
      onClose={confirm}
      isConfirmationDisabled={actionFinished}
      header={t('modals.editVat.header')}
      description=""
    >
      <Stack sx={{ width: '576px', mx: 4, mb: 4, gap: 2 }}>
        <Show when={actionParams?.row !== null}>
          <SelectedProductDetails row={actionParams?.row} />
        </Show>
        <Show
          when={isAllLoaded}
          fallback={
            <Stack alignItems="center" justifyContent="center">
              <SpinnerIcon sx={{ fontSize: '100px' }} />
            </Stack>
          }
        >
          <Show
            when={!isError}
            fallback={
              <Alert severity="error" sx={{ width: '100%' }}>
                {t('modals.editVat.messages.error')}
              </Alert>
            }
          >
            <Box
              sx={{ p: 2, pt: 0, overflow: 'hidden', bgcolor: nethansaPalette.background.title }}
            >
              <TableContainer sx={{ maxHeight: '50vh' }}>
                <Table
                  aria-label="Product VAT table"
                  stickyHeader
                  sx={{
                    '& .MuiTableCell-root': {
                      py: 0,
                    },
                    '& .MuiTableCell-root:first-of-type': {
                      pl: '16px !important',
                    },
                    '& .MuiTableRow-root': {
                      height: '56px',
                    },
                    '& .MuiTableHead-root .MuiTableRow-root:first-of-type': {
                      height: '40px',
                    },
                    '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-root': {
                      borderBottom: `1px solid ${nethansaPalette.line.light}`,
                      pb: 0,
                    },
                    '& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd) .MuiTableCell-body': {
                      bgcolor: nethansaPalette.main.light,
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('modals.editVat.table-header.country')}</TableCell>
                      <TableCell>{t('modals.editVat.table-header.vat-marketplace')}</TableCell>
                      <TableCell>{t('modals.editVat.table-header.vat-product')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, i) => {
                      const isSelected: boolean =
                        vatRatesValues.find((y) => y.countryCode === row.countryCode)
                          ?.isVatOnMarketplace || false;
                      return (
                        <TableRow key={row.countryCode}>
                          <TableCell>{row.country}</TableCell>

                          <TableCell>
                            <Controller
                              control={control}
                              name={`vatRates.${i}.isVatOnMarketplace`}
                              render={({ field }) => {
                                return (
                                  <Checkbox
                                    value={field.value}
                                    checked={!!field.value}
                                    label={String(row.taxRate?.toFixed(2) + ' %')}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    sx={{
                                      '& + .MuiFormControlLabel-label': {
                                        ml: '0 !important',
                                        pt: '4px',
                                      },
                                    }}
                                  />
                                );
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            <Controller
                              control={control}
                              name={`vatRates.${i}.currentTaxRate`}
                              render={({ field }) => {
                                const options = row.availableTaxRates
                                  .sort((a, b) => b - a)
                                  .map((x) => ({
                                    value: String(x),
                                    children: `${x.toFixed(2)} %`,
                                  }));

                                const selectValue = () => {
                                  if (field.value === undefined) return '';
                                  if (field.value === null) return options[0].value || '';

                                  return field.value;
                                };

                                return (
                                  <Box sx={{ m: 0 }}>
                                    <SelectField
                                      value={String(selectValue())}
                                      onChange={field.onChange}
                                      onBlur={field.onBlur}
                                      disabled={isSelected || !isAllLoaded}
                                      sx={{ m: 0, maxWidth: '128px' }}
                                      options={options}
                                    />
                                  </Box>
                                );
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Show>
        </Show>
      </Stack>
    </DialogConfirmation>
  );
};
