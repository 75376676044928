import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { DialogBaseWrapper, FileField, Show } from 'components';
import { ExcelIcon, FileUploadIcon, SpinnerIcon } from 'components/icons';
import { ActionDetails } from 'modules/offers/components/modal-action-details/action-details';
import { useOfferPricesImportMutation } from 'modules/offers/hooks';
import { ResponseAPIType, useCommonTranslations } from 'modules/shared';
import { PriceImportResponseType } from 'modules/shared/types';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import { Alert, Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { ImportOffersPriceSummary } from './componenents/import-offers-price-summary';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type ImportProductsFormProps = {
  fileToUpload?: File;
};

export const ImportOffersPriceModal: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  const { tCommon } = useCommonTranslations();
  const { pushSnackbar } = useSnackbar();

  const [responseErrors, setResponseErrors] = useState<PriceImportResponseType | null>(null);
  const [inputError, setInputError] = useState<string | null>(null);
  const MAX_FILESIZE_IN_MB = 10;
  const SUPPORTED_FILES = ['xls', 'xlsx'];

  const pricesImportMutation = useOfferPricesImportMutation();

  const { control, clearErrors, getValues, handleSubmit, reset } = useForm<ImportProductsFormProps>(
    {
      defaultValues: {
        fileToUpload: undefined,
      },
    },
  );

  const submit = () => {
    const fileValue = getValues('fileToUpload');

    if (!fileValue) return;

    pricesImportMutation.mutate(
      { file: fileValue },
      {
        onSuccess: () => {
          queryClient.refetchQueries({ active: true, queryKey: 'offers' });

          pushSnackbar({
            variant: 'success',
            message: t('modals.price-import.success'),
          });
          handleClose();
        },

        onError: (error) => {
          const data = (error.response?.data as ResponseAPIType).data as PriceImportResponseType;

          if (data.errors !== 0) setResponseErrors(data);
        },
      },
    );
  };

  const handleFileValidation = () => {
    const file = getValues('fileToUpload');

    if (!file) return;

    setInputError(null);

    const sizeInMB = Number((file.size / (1024 * 1024)).toFixed(2));
    const fileExtension = file.name.split('.').pop() || 'unknown';

    if (sizeInMB > MAX_FILESIZE_IN_MB) {
      setInputError(t('modals.price-import.validation.too-big-file'));
    }

    if (!SUPPORTED_FILES.includes(fileExtension)) {
      setInputError(t('modals.price-import.validation.incorrect-extension'));
    }
  };

  const handleClose = () => {
    reset();
    pricesImportMutation.reset();

    setInputError(null);
    setResponseErrors(null);
    props.onClose();
  };

  return (
    <DialogBaseWrapper
      header={t('modals.price-import.header')}
      open={props.isOpen}
      onClose={handleClose}
      disableCloseOnBackdropClick
    >
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent
          sx={{
            mx: 4,
            mb: 2,
            minWidth: '578px !important',
            width: '578px',
            maxHeight: 'calc(100vh - 250px)',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <ActionDetails
            title={t('actions.action-details-title')}
            content={
              <>
                <Trans i18nKey="modals.price-import.section1.desc" t={t} />
                <a href={t('modals.price-import.section1.link.address')} target="_blank">
                  {' '}
                  {t('modals.price-import.section1.link.text')}
                </a>
              </>
            }
          />

          <Show when={responseErrors !== null}>
            <ImportOffersPriceSummary data={responseErrors as PriceImportResponseType} />
          </Show>

          <Show when={pricesImportMutation.isSuccess}></Show>

          <Show
            when={
              !pricesImportMutation.isLoading &&
              responseErrors === null &&
              !pricesImportMutation.isSuccess
            }
          >
            <Stack component={'section'} sx={{ bgcolor: nethansaPalette.background.title, p: 2 }}>
              <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                <ExcelIcon />
                <Typography variant="h3" sx={{ fontWeight: 700 }}>
                  {t('modals.price-import.section2.title')}
                </Typography>
              </Stack>
              <Typography
                variant="h5"
                sx={{
                  lineHeight: '18px',
                  color: nethansaPalette.main.gray,
                  mt: 1,
                  width: 0,
                  minWidth: '100%',
                }}
              >
                {t('modals.price-import.section2.desc')}
              </Typography>
            </Stack>

            <Stack component={'section'} sx={{ bgcolor: nethansaPalette.background.title, p: 2 }}>
              <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                <FileUploadIcon />
                <Typography variant="h3" sx={{ fontWeight: 700 }}>
                  {t('modals.price-import.section3.title')}
                </Typography>
              </Stack>
              <Typography
                variant="h5"
                sx={{
                  lineHeight: '18px',
                  whiteSpace: 'pre-line',
                  color: nethansaPalette.main.gray,
                  mt: 1,
                  width: 0,
                  minWidth: '100%',
                }}
              >
                {t('modals.price-import.section3.desc', { maxSize: MAX_FILESIZE_IN_MB })}
              </Typography>
              <Controller
                control={control}
                name="fileToUpload"
                rules={{
                  required: {
                    value: true,
                    message: t('modals.price-import.validation.no-file'),
                  },
                }}
                render={({ field }) => {
                  return (
                    <FileField
                      isFlat
                      onChange={(file) => {
                        field.onChange(file);
                        field.onBlur();

                        if (!file) return;

                        clearErrors();
                        pricesImportMutation.reset();
                        handleFileValidation();
                      }}
                      disabled={pricesImportMutation.isLoading || pricesImportMutation.isSuccess}
                      error={!!inputError}
                      helperText={inputError || ''}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      buttonLabel={tCommon('choose')}
                      value={field.value}
                    />
                  );
                }}
              />
            </Stack>
          </Show>

          <Show when={pricesImportMutation.isLoading}>
            <Alert
              severity="info"
              icon={<SpinnerIcon sx={{ height: 42, width: 'auto' }} />}
              sx={{
                '&.MuiAlert-standardInfo': { bgcolor: nethansaPalette.background.titleDark },
                '& .MuiAlert-icon': { p: 0 },
              }}
            >
              {t('modals.price-import.processing')}
            </Alert>
          </Show>
        </DialogContent>

        <DialogActions sx={{ px: 4, py: 2, borderTop: `1px solid ${nethansaPalette.line.light}` }}>
          <Show
            when={!pricesImportMutation.isSuccess && !pricesImportMutation.isError}
            fallback={
              <Button type="button" size="small" onClick={handleClose}>
                OK
              </Button>
            }
          >
            <Button type="button" size="small" variant="text" onClick={handleClose}>
              {tCommon('cancel')}
            </Button>
            <Button
              type="submit"
              size="small"
              disabled={
                pricesImportMutation.isLoading || !!inputError || pricesImportMutation.isError
              }
            >
              {t('modals.price-import.buttons.send')}
            </Button>
          </Show>
        </DialogActions>
      </form>
    </DialogBaseWrapper>
  );
};
