import React, { useState } from 'react';
import { useInView } from 'react-hook-inview';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = React.PropsWithChildren<{
  sx?: SxProps<Theme>;
  height?: number | string;
  unobserveOnEnter?: boolean;
  rootMargin?: number;
}>;

export const RenderInView: React.FC<Props> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref] = useInView({
    threshold: 0.1,
    unobserveOnEnter: !!props.unobserveOnEnter,
    rootMargin: props.rootMargin ? `${props.rootMargin || 0}px` : '100px',
    onEnter: () => {
      setIsVisible(true);
    },
  });

  return (
    <Box
      sx={{ ...(props.sx || {}), height: props.height }}
      className="observer-container"
      ref={ref}
    >
      {isVisible ? props.children : null}
    </Box>
  );
};
