export const setUrlToHigherResolution = (
  src: string,
  provider: 'amazon' | 'kaufland',
  targetRes?: number,
): string => {
  if (!src) return '';
  if (provider === 'amazon') {
    return src.replace('SL75', `SL${targetRes || 512}`);
  }
  return src;
};
