import React from 'react';
import { EditTagsModal } from './modals/edit-tags.modal';
import { ModalMonitoringDeleteConfirmation } from './modals/modal-monitoring-delete.confirmation';
import {
  MonitoringModalCoordinatorContext,
  MonitoringModalsCoordinatorProvider,
} from './providers';

type Props = React.PropsWithChildren;

export const MonitoringModalsCoordinator: React.FC<Props> = (props) => {
  return (
    <MonitoringModalsCoordinatorProvider>
      {props.children}
      <MonitoringModalCoordinatorContext.Consumer>
        {(params) => (
          <>
            {params.deleteOffers[0] && <ModalMonitoringDeleteConfirmation />}
            {params.editTags[0] && <EditTagsModal />}
          </>
        )}
      </MonitoringModalCoordinatorContext.Consumer>
    </MonitoringModalsCoordinatorProvider>
  );
};
