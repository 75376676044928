import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { nethansaPalette } from '../../theme';

type Props = {
  sx?: SxProps<Theme>;
};

export const SpinnerIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="28px"
      height="28px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="75" cy="50" fill={nethansaPalette.main.gray} r="6.39718">
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.875s"
        />
      </circle>
      <circle cx="67.678" cy="67.678" fill={nethansaPalette.main.gray} r="4.8">
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.75s"
        />
      </circle>
      <circle cx="50" cy="75" fill={nethansaPalette.main.gray} r="4.8">
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.625s"
        />
      </circle>
      <circle cx="32.322" cy="67.678" fill={nethansaPalette.main.gray} r="4.8">
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.5s"
        />
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.5s"
        />
      </circle>
      <circle cx="25" cy="50" fill={nethansaPalette.main.gray} r="4.8">
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.375s"
        />
      </circle>
      <circle cx="32.322" cy="32.322" fill={nethansaPalette.main.gray} r="4.80282">
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.25s"
        />
      </circle>
      <circle cx="50" cy="25" fill={nethansaPalette.main.gray} r="6.40282">
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.125s"
        />
      </circle>
      <circle cx="67.678" cy="32.322" fill={nethansaPalette.main.gray} r="7.99718">
        <animate
          attributeName="r"
          values="4.8;4.8;8;4.8;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="0s"
        />
      </circle>
    </SvgIcon>
  );
};
