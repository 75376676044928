import { UseQueryResult, useQuery } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import { InputMaybe, MonitoredOfferGridDtoFilterInput } from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';
import { useDatagridFiltersToGraphqlTranslator } from 'modules/datagrid';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { MonitoredOfferGridColDefCustom } from '../types';
import { searchableMonitoringColumns } from './use-monitoring-offers.query';

export const getMonitoringTotalCount = gql`
  query MonitoringTotalCount(
    $partnerId: String!
    $first: Int!
    $filter: MonitoredOfferGridDtoFilterInput
  ) {
    monitoredOffers(partnerId: $partnerId, first: $first, where: $filter) {
      totalCount
    }
  }
`;

export const useMonitoringTotalCountQuery = (
  groupName: string,
  columnsUsedInFilters: MonitoredOfferGridColDefCustom[],
  filters: GridFilterModel,
): UseQueryResult<number> => {
  const { currentPartnerId } = useAuth();

  const gridFilterSort = useDatagridFiltersToGraphqlTranslator<
    MonitoredOfferGridColDefCustom,
    MonitoredOfferGridDtoFilterInput
  >(filters, columnsUsedInFilters, searchableMonitoringColumns);

  return useQuery<number>(
    ['monitoring', 'total-count', groupName, currentPartnerId, gridFilterSort.filter],
    async () => {
      const parsedFilter: InputMaybe<MonitoredOfferGridDtoFilterInput> = {
        ...gridFilterSort.filter,
      };

      const result = await graphqlClient.request(getMonitoringTotalCount, {
        partnerId: String(currentPartnerId),
        first: 0,
        ...(Object.keys(parsedFilter || {}).length ? { filter: parsedFilter } : {}),
      });

      return result?.monitoredOffers?.totalCount || 0;
    },
    {
      staleTime: 5000,
      keepPreviousData: false,
      retry: false,
    },
  );
};
