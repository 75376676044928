import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ArrowUpIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71 9.7C6.1 10.09 6.73 10.09 7.12 9.7L11 5.83V21C11 21.55 11.45 22 12 22C12.55 22 13 21.55 13 21V5.83L16.88 9.71C17.27 10.1 17.9 10.1 18.29 9.71C18.68 9.32 18.68 8.69 18.29 8.3L12.7 2.7C12.31 2.31 11.68 2.31 11.29 2.7L5.71 8.29C5.32 8.68 5.32 9.32 5.71 9.7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
