import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SearchTextInput } from 'components/search-text-input';
import { useDatagridContext } from 'modules/datagrid';
import { searchableMonitoringColumns } from 'modules/monitoring/hooks';
import { searchableColumnsProducts } from 'modules/products/hooks';
import { useCommonTranslations } from 'modules/shared';
import { AppGlobalParametersContext } from 'modules/shared/contexts/app-global-parameters.provider';
import { AppModules, PlatformChannelCode } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import { Box, Stack } from '@mui/material';
import {
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { HideToolbarsButton, MenuButtonSaveView } from './components';

type Props = React.PropsWithChildren<{
  columns: GridColDef[];
  disabled?: boolean;
  isToolbarsButtonHidden?: boolean;
}>;

export const ToolbarTable: React.FC<Props> = (props) => {
  const gridContext = useDatagridContext();
  const { tCommon } = useCommonTranslations();
  const [tReports] = useTranslation('reports');
  const [tOffers] = useTranslation('offers');
  const [tProducts] = useTranslation('products');
  const [tMonitoring] = useTranslation('monitoring');
  const showContext = useContext(AppGlobalParametersContext);
  const currentModule: AppModules = useLocation().pathname.split('/')[1] as AppModules;

  const toolbarExpanded = () => {
    switch (currentModule) {
      case 'Offers':
        return showContext.offersModuleWithBars;
      case 'Reports':
        return showContext.reportsModuleWithBars;
      case 'Products':
        return showContext.productsModuleWithBars;
      case 'Monitoring':
        return showContext.monitoringModuleWithBars;
    }

    return false;
  };

  const offersSearchableColumnsByChannel: { [key in PlatformChannelCode]: string[] } = {
    AMZFBA: ['productName', 'title', 'externalId', 'asin', 'ean', 'sku'],
    AMZFBM: ['productName', 'title', 'externalId', 'asin', 'ean', 'sku'],
    DEFAULT: ['productName', 'title', 'externalId', 'itemId', 'ean', 'sku'],
  };

  const channel: () => PlatformChannelCode = () => {
    const subpath = useLocation().pathname.split('/')[2];
    return Object.keys(offersSearchableColumnsByChannel).includes(subpath)
      ? (subpath as 'AMZFBM')
      : 'AMZFBM';
  };

  const searchableFiledByAppModule: { [key in AppModules]: string[] } = {
    Offers: offersSearchableColumnsByChannel[channel()].map((x) =>
      tOffers(`column.${x}.label` as 'column.asin.label'),
    ),
    Orders: [],
    Products: searchableColumnsProducts.map((x) =>
      tProducts(`column.${x}.label` as 'column.name.label'),
    ),
    Reports: ['productName', 'eAN', 'category', 'orderId'].map((x) => {
      return tReports(`sales-revenue-profit.column.${x}` as 'sales-revenue-profit.column.category');
    }),
    Monitoring: searchableMonitoringColumns.map((x) => {
      return tMonitoring(`column.${x}.label` as 'column.state.label');
    }),
    Dashboard: [],
  };

  const tooltipKeysByModuleApp = searchableFiledByAppModule[currentModule];

  const tooltipContent =
    tooltipKeysByModuleApp.length > 0
      ? `
      <p style="margin-top: 0;">${tCommon('datagrid.searchbar.tooltip-content')}</p>
    <ul>
      ${tooltipKeysByModuleApp.map((x) => `<li>${x}</li>`).join('')}
    </ul>
  `
      : undefined;

  return (
    <Box
      sx={{
        '& .MuiDataGrid-toolbarContainer': {
          gap: '12px',
          p: 1.5,
          px: 1,
          borderBottom: `1px solid ${nethansaPalette.line.dark}`,
        },
        position: 'relative',
      }}
    >
      <GridToolbarContainer>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-start">
            <SearchTextInput
              disabled={props.disabled}
              sx={{ ml: 1, mr: 2, minWidth: '244px' }}
              placeholder={tCommon('search')}
              initialValue={gridContext.search[0]}
              onDebounceChange={gridContext.search[1]}
              tooltip={tooltipContent}
            />
            <GridToolbarFilterButton />
            <GridToolbarColumnsButton />
            {props.children}
          </Stack>
          {toolbarExpanded() === true && (
            <Stack direction="row" sx={{ pr: 2, alignItems: 'center' }}>
              <MenuButtonSaveView />
            </Stack>
          )}
        </Stack>
        <HideToolbarsButton
          onClick={() => showContext.toggleBarsVisibility(currentModule)}
          isHidden={props.isToolbarsButtonHidden}
          isExpanded={toolbarExpanded()}
        />
      </GridToolbarContainer>
    </Box>
  );
};
