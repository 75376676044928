import { GridFilterModel } from '@mui/x-data-grid-pro';
import { PlatformChannelCode } from '../../shared/types';
import { Money } from './offer-details.type';

export type SinglePriceSettingsParamsType = {
  offerId: string;
  domain: string;
  productUrl: string;
  productName: string;
  onSuccess?: () => void;
};

export type MultiplePriceSettingsParamsType = {
  selectedOfferIds: string[];
  channelDefined: PlatformChannelCode;
  selectedCount: number;
  filters?: GridFilterModel;
  searchValue?: string;
  onSuccess?: () => void;
};

export type PriceSettingsTab = 'PriceManagement' | 'Competition' | 'PriceRange';

export type RepricingStatus = 'LeaveAsItIs' | 'On' | 'Off';

export enum PriceManagementMode {
  'Unknown' = 0,
  'Off' = 1,
  'Fixed' = 2,
  'Dynamic' = 3,
}

export type PriceSettingsDataType = {
  fixedPricesSettings: {
    fixedMinimumPrice: number;
    fixedMaximumPrice: number;
    fixedPrice: number;
  };
  markupSettings: {
    minimalMarkup: number;
    standardMarkup: number;
    maximumMarkup: number;
  };
  parametersSettings: {
    returnReserve: number;
    shippingCost: number;
    additionalCost: number;
    productCostInMarketplaceCurrency: Money;
  };
  repricerSettings: {
    percentageDifferenceFromCompetition: number;
    repreicingEnabled: RepricingStatus;
    valueDifferenceFromCompetition: number;
    priceManagementMode: PriceManagementMode;
  };
};

export type PriceSettingsInputType = {
  selectedOfferIds: string[];
  filters: unknown;
  markupSettings: {
    minimalMarkup: number;
    standardMarkup: number;
    maximumMarkup: number;
    useDefaultMinimalMarkup: boolean;
    useDefaultStandardMarkup: boolean;
    useDefaultMaximumMarkup: boolean;
  };
  repricerSettings: {
    percentageDifferenceFromCompetition: number;
    valueDifferenceFromCompetition: number;
    repricingStatus: RepricingStatus;
    priceManagementMode: PriceManagementMode;
    useDefaultPercentageDifferenceFromCompetition: boolean;
    useDefaultValueDifferenceFromCompetition: boolean;
  };
  fixedPricesSettings: {
    fixedMinimumPrice: number;
    fixedMaximumPrice: number;
    fixedPrice: number;
    removeFixedMinimumPrice: boolean;
    removeFixedMaximumPrice: boolean;
    removeFixedPrice: boolean;
  };
  parametersSettings: {
    returnReserve: number;
    shippingCost: number;
    additionalCost: number;
    useDefaultReturnReserve: boolean;
    useDefaultShippingCost: boolean;
    useDefaultAdditionalCost: boolean;
    productCostInMarketplaceCurrency: Money;
  };
};

export type OfferPriceSettingsParams = {
  channelCode: string;
  currencyCode: string;
  marketplaceCode: string;
  platformCode: string;
  platformName: string;
};
