import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from '../../../../common/api';
import { ErrorMeta, ResponseAPIDataStatusType } from '../../../shared/types';
import { useDatagridViewContext } from '../use-datagrid-view-context';
import { useViewsQuery } from './use-views.query';

export const useViewShareMutation = (): UseMutationResult<
  ResponseAPIDataStatusType,
  ErrorMeta,
  { id: string; share: boolean }
> => {
  const { tableId } = useDatagridViewContext();
  const { refetch } = useViewsQuery(tableId);

  return useMutation(
    async ({ id, share }) => {
      if (share) {
        const response = await httpClient.patch(API.views().viewShare(id));
        return response.data;
      }
      const response = await httpClient.patch(API.views().viewUnshare(id));
      return response.data;
    },
    {
      onSettled: () => {
        refetch();
      },
    },
  );
};
