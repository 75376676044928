import { useEffect } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import {
  InputMaybe,
  OfferGridDtoFilterInput,
  OfferGridDtoSortInput,
  OffersQuery,
  OffersQueryVariables,
  SortEnumTypeAnimusOffers,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { PlatformChannelCode } from 'modules/shared/types';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { useAuth } from '../../authorization';
import { useDatagridContext, useDatagridContextGraphqlTranslator } from '../../datagrid';
import { OfferGridColumn, OfferGridRowKey } from '../types';

export const getOffers = gql`
  query Offers(
    $partnerId: Int!
    $first: Int!
    $after: String
    $sort: [OfferGridDtoSortInput!]
    $filter: OfferGridDtoFilterInput
  ) {
    offers(partnerId: $partnerId, first: $first, after: $after, where: $filter, order: $sort) {
      totalCount
      nodes {
        ...offerNode
      }
      pageInfo {
        ...paginationMetaAnimus
      }
      edges {
        cursor
      }
    }
  }

  fragment offerNode on OfferGridDto {
    actualMarkup
    additionalCostPercent
    additionalCostValue
    additionalProfit
    asin
    bestSellers
    brand
    calculatedPrice
    changeToBestMarkup
    channelCode
    channelCommission
    color
    competitivePower
    competitorsPrice
    currencyCode
    currentProfit
    differenceFromCompetitionPercent
    differenceFromCompetitionValue
    domain
    dynamicPricingModel
    ean
    externalId
    fixedMaximumPrice
    fixedMinimumPrice
    fixedPrice
    fullfilmentAdditionalFee
    height
    imageUrl
    isbn
    last30DaysSoldOrders
    last30DaysSoldQuantity
    lastOrderDate
    length
    listingPrice
    manufacturer
    marketplaceCode
    marketSizeQuantity
    marketSizeValue
    maximumMarkup
    maximumPrice
    minimumMarkup
    minimumPrice
    netCarrierCost
    netCarrierCostInBaseCurrency
    nethansaCommission
    nethansaPlatformRanking
    netProductCost
    netProductCostInBaseCurrency
    numberOfCompetitors
    offerAddDate
    offerKey
    offerModifiedDate
    offerRepricingDetails
    offerStock
    packageQuantity
    parentAsin
    platformCategory
    platformCategoryRanking
    potentialRangeKey
    potentialRangeName
    prediction30DaysProfit
    prediction30DaysQuantity
    prediction30DaysTurnover
    priceManagementMode
    productName
    productStock
    productTags
    offerTags
    productUrl
    repricingEnabled
    returnFee
    reviewCount
    sendingResultInformation
    sharedOfferState
    sharedOfferSubState
    size
    sku
    sourceGrossPrice
    standardMarkup
    standardPrice
    starCount
    title
    totalProfitInLast30Days
    upc
    vatRate
    weight
    width
  }

  fragment paginationMetaAnimus on PageInfoAnimusOffers {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const searchableColumnsOffers: OfferGridRowKey[] = [
  'productName',
  'asin',
  'ean',
  'sku',
  'title',
  'externalId',
  'offerKey',
];

export const useOffersQuery = (
  columns: OfferGridColumn[],
  channel?: PlatformChannelCode,
): UseQueryResult<OffersQuery> => {
  const { currentPartnerId } = useAuth();
  const gridContext = useDatagridContext();

  //TODO: define which columns should be searchable
  const gridFilterSort = useDatagridContextGraphqlTranslator<
    OfferGridColumn,
    OfferGridRowKey,
    OfferGridDtoFilterInput,
    OfferGridDtoSortInput
  >(columns, searchableColumnsOffers);

  const result = useQuery<OffersQuery>(
    [
      'offers',
      channel,
      currentPartnerId,
      gridFilterSort.first,
      gridFilterSort.after,
      gridFilterSort.sort,
      gridFilterSort.filter,
    ],
    async () => {
      const channelDefined = channel
        ? {
            or: [
              {
                channelCode: {
                  eq: channel,
                },
              },
            ],
          }
        : {};

      const parsedFilter: InputMaybe<OfferGridDtoFilterInput> = {
        ...gridFilterSort.filter,
        ...channelDefined,
      };

      const sortParsed: InputMaybe<OfferGridDtoSortInput> = gridFilterSort.sort
        ? gridFilterSort.sort
        : {
            externalId: SortEnumTypeAnimusOffers.Desc,
          };

      return (await graphqlClient.request<OffersQueryVariables>(getOffers, {
        partnerId: Number(currentPartnerId),
        first: gridFilterSort.first,
        after: gridFilterSort.after,
        sort: sortParsed,
        ...(Object.keys(parsedFilter || {}).length ? { filter: parsedFilter } : {}),
      })) as OffersQuery;
    },
    {
      staleTime: 5000,
      keepPreviousData: false,
      retry: false,
    },
  );

  useEffect(() => {
    gridContext.rowCount[1](result.data?.offers?.totalCount || 0);
  }, [result.data?.offers?.totalCount]);

  return result;
};
