import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { ChevronIcon } from 'components/icons';
import { BigFailIcon } from 'components/icons/big-fail.icon';
import { FileValidationError, PriceImportResponseType } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';

export interface Props {
  data: PriceImportResponseType;
}

export const ImportOffersPriceSummary: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');

  const initialTabsState = props.data.errorInfoImportPrices.map((position) => ({
    error: position.error,
    open: false,
  }));

  const [openTab, setTabOpen] = useState(initialTabsState);

  function handleClick(error: FileValidationError) {
    const newState = openTab.map((tab) => {
      if (tab.error === error) {
        tab.open = !tab.open;
      }
      return tab;
    });
    setTabOpen(newState);
  }

  return (
    <Stack
      sx={{
        gap: '8px',
        backgroundColor: nethansaPalette.background.error,
        padding: '16px',
        width: '100%',
      }}
    >
      <Stack sx={{ gap: '10px', flexDirection: 'row' }}>
        <BigFailIcon />
        <Typography variant="h5" sx={{ lineHeight: '24px' }}>
          {t('modals.price-import.validation.summary-problems-title')}
        </Typography>
      </Stack>

      {props.data?.errorInfoImportPrices.map((error) => {
        return (
          <Stack>
            <Stack sx={{ gap: '10px', flexDirection: 'row', ml: '20px' }}>
              <ButtonBase
                disableRipple
                onClick={() => handleClick(error.error)}
                sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
              >
                <Typography sx={{ color: nethansaPalette.button.blueMain }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      pl: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        backgroundColor: nethansaPalette.main.watermelon,
                      }}
                    />

                    <Typography
                      variant="h5Text"
                      sx={{ color: nethansaPalette.main.dark, mx: '6px' }}
                    >
                      {t(
                        `modals.price-import.validation.${error.error}` as 'modals.price-import.validation.incorrect-data',
                      )}
                    </Typography>

                    {error.errorsInfo && (
                      <>
                        <Typography variant="h5Text" sx={{ ml: '-6px' }}>
                          {': '}
                        </Typography>
                        <Typography variant="h5Bold" sx={{ mx: '6px' }}>
                          {error.errorsInfo.length <= 10 ? error.errorsInfo.length : '10+'}
                        </Typography>

                        <ChevronIcon
                          sx={{
                            width: '10px',
                            height: '10px',
                            transform: openTab.filter((t) => t.error === error.error)[0].open
                              ? 'rotate(0.75turn)'
                              : 'rotate(0.5turn)',
                            color: nethansaPalette.button.blueMain,
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </Typography>
              </ButtonBase>
            </Stack>

            <Show
              when={
                error.errorsInfo != null && openTab.filter((t) => t.error === error.error)[0].open
              }
            >
              <Stack sx={{ gap: '0px', flexDirection: 'col', ml: '60px' }}>
                {error.errorsInfo &&
                  error.errorsInfo.slice(0, 10).map((info) => (
                    <Typography variant="h5">
                      {t('modals.price-import.validation.position-info', {
                        position: info.position,
                        sku: info.platformProductId,
                      })}
                    </Typography>
                  ))}
              </Stack>
            </Show>
          </Stack>
        );
      })}
    </Stack>
  );
};
