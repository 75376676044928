import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { DialogConfirmation, Show } from 'components';
import { useProductsCoordinatorContext } from 'modules/products/hooks';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import { Alert, Stack, Typography, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { DetailedActionResponseOfProductErrorCode, Query } from '../../../../generated/graphql';
import { BulkActionSummary } from '../../components/bulk-action-summary';
import { useBulkDeleteProductMutation } from '../../hooks';
import { SelectedProductDetails } from '../components/selected-product-details';
import { SelectedProducts } from '../components/selected-products';

export const DeleteProductModal: React.FC = () => {
  const {
    deleteProduct: [actionParams, setActionParams],
  } = useProductsCoordinatorContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [actionFinished, setActionFinished] = useState<boolean>(false);
  const { pushSnackbar } = useSnackbar();
  const useAmazonBulkAction = useBulkDeleteProductMutation();
  const [t] = useTranslation('products');
  const [bulkSummary, setBulkSummary] = useState<DetailedActionResponseOfProductErrorCode | null>(
    null,
  );
  let selectedMode = 'first';
  const refreshProducts = () => queryClient.refetchQueries({ active: true, queryKey: 'products' });
  const closeModal = () => setActionParams(undefined);
  const radioRef = React.useRef<HTMLInputElement>(null);

  const handleResponse = (data: Query) => {
    setActionFinished(true);
    refreshProducts();
    actionParams?.onSuccess && actionParams?.onSuccess();

    if (actionParams?.row == null) {
      setBulkSummary(data.deleteProduct);
      return;
    }

    const errors = data.deleteProduct?.errors;

    if (!errors || errors.length == 0) {
      closeModal();
      pushSnackbar({
        variant: 'success',
        message: t('modals.deleteProduct.messages.success'),
      });
    } else setErrorMessage(t(`errors.${errors[0].key}` as any));
  };

  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }

    let force = selectedMode != 'first';
    if (actionParams?.row != null) force = true;

    useAmazonBulkAction.mutate(
      {
        where: actionParams?.filters,
        force: force,
      },
      {
        onSuccess: (data) => handleResponse(data),
        onError: () => setErrorMessage(t('modals.deleteProduct.messages.error')),
      },
    );
  };

  const radioDescriptionStyles = {
    pr: 2,
    pl: 0,
    pt: '1px',
    pb: '1px',
    mb: 0,
    mt: 1,
  };

  const secondLabelDescriptionStyles = {
    color: nethansaPalette.main.gray,
    pl: '42px',
    lineHeight: '18px',
  };

  const firstLabelDescriptionStyles = {
    ...secondLabelDescriptionStyles,
    mt: '-10px',
  };

  return (
    <DialogConfirmation
      isLoading={useAmazonBulkAction.isLoading}
      confirmLabel={t('modals.buttons.delete')}
      open
      dangerHeader
      onClose={confirm}
      isConfirmationDisabled={actionFinished}
      header={t('modals.deleteProduct.header')}
      tooltip={t('modals.deleteProduct.tooltip')}
      description=""
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <Show when={actionParams?.row != null}>
          <SelectedProductDetails row={actionParams?.row} />
        </Show>
        <Show when={actionParams?.row == null}>
          <SelectedProducts productsNumber={actionParams?.selectedCount} />
        </Show>
        <Show when={errorMessage != null}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Show>
        <Show when={bulkSummary != null}>
          <BulkActionSummary data={bulkSummary as DetailedActionResponseOfProductErrorCode} />
        </Show>

        <Show when={!actionFinished}>
          <Stack
            sx={{
              flexDirection: 'column',
              backgroundColor: nethansaPalette.background.title,
              width: '100%',
              pl: 2,
              pb: 1,
              fontSize: '13px',
              lineHeight: '18px',
            }}
          >
            <Show when={actionParams?.row != null}>
              <Typography
                variant="h5"
                sx={{ lineHeight: '18px', whiteSpace: 'pre-line', pt: 1, fontWeight: 'bold' }}
              >
                {t('modals.deleteProduct.description')}
              </Typography>
            </Show>
            <Show when={actionParams?.row == null}>
              <RadioGroup
                defaultValue={selectedMode}
                name="use-radio-group"
                ref={radioRef}
                onChange={(e) => {
                  selectedMode = e.target.value;
                }}
              >
                <FormControlLabel
                  value="first"
                  sx={radioDescriptionStyles}
                  control={<Radio />}
                  label={
                    <Typography variant="h2" sx={{ fontWeight: 'normal' }}>
                      {t('modals.deleteProduct.options.first.header')}
                    </Typography>
                  }
                />
                <Typography variant="h5" sx={firstLabelDescriptionStyles}>
                  {t('modals.deleteProduct.options.first.description')}
                </Typography>
                <FormControlLabel
                  value="second"
                  sx={radioDescriptionStyles}
                  control={<Radio />}
                  label={
                    <Typography variant="h2" sx={{ fontWeight: 'normal' }}>
                      {t('modals.deleteProduct.options.second.header')}
                    </Typography>
                  }
                />
                <Typography variant="h5" sx={secondLabelDescriptionStyles}>
                  {t('modals.deleteProduct.options.second.description')}
                </Typography>
              </RadioGroup>
            </Show>
          </Stack>
        </Show>
      </Stack>
    </DialogConfirmation>
  );
};
