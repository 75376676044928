import React, { useState } from 'react';
import { useUserQuery } from '../hooks/use-user.query';
import { Claim } from '../types';

export type State = {
  claimsFetched: boolean;
  claims: Claim[];
};

export const UserContext = React.createContext<State>({
  claimsFetched: false,
  claims: [],
});

type Props = {
  partnerId?: string;
};

export const UserProvider: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const claimsFetched = useState<boolean>(false);
  const claims = useState<Claim[]>([]);

  useUserQuery({
    onSuccess: (data) => {
      claimsFetched[1](true);
      claims[1](data?.claims || []);
    },
  });

  const value: State = {
    claimsFetched: claimsFetched[0],
    claims: claims[0],
  };

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};
