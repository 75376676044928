import React from 'react';
import { useTranslation } from 'react-i18next';
import { nethansaPalette } from 'theme';
import { Box, Stack, Typography } from '@mui/material';
import { marketplaceList } from '../../../common/utils';
import { SharedMonitoredOfferState } from '../../../generated/graphql';
import { DatagridCellFlag } from '../../datagrid';
import { StatusColorGroup } from '../../offers';
import { MonitoredOfferRow } from '../types';
import { getMonitoringStatusColorGroup } from '../utils';

export interface Props {
  row?: MonitoredOfferRow;
}

export const SelectedMonitoringDetails: React.FC<Props> = (props) => {
  const [t] = useTranslation('monitoring');
  const marketplace = marketplaceList.filter((el) => el.value === props.row?.marketplaceCode);
  return (
    <Stack
      sx={{
        gap: '40px',
        flexDirection: 'row',
        backgroundColor: nethansaPalette.background.title,
        width: '100%',
        pl: 2,
        py: 1,
        fontSize: '13px',
        lineHeight: '18px',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '170px' }}>
        <DatagridCellFlag
          value={props.row?.marketplaceCode || 'UNKNOWN'}
          label={marketplace[0].label}
          href={
            props.row?.state === SharedMonitoredOfferState.Active
              ? `https://${marketplace[0].label}/dp/${props.row.asin}`
              : undefined
          }
        />
      </Box>
      <Box
        sx={{ width: '130px' }}
        color={
          nethansaPalette.status[
            `${
              getMonitoringStatusColorGroup(
                props.row?.state || SharedMonitoredOfferState.Unknown,
              ) as StatusColorGroup
            }`
          ].dark
        }
      >
        {t(`column.state.${props.row?.state as 'ACTIVE'}`)}
      </Box>
      <Box sx={{ lineHeight: '24px' }}>
        <Typography variant="h5" sx={{ '& > span': { color: nethansaPalette.main.gray } }}>
          <span>ASIN: </span>
          {props.row?.asin}
        </Typography>
      </Box>
    </Stack>
  );
};
