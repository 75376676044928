import React from 'react';
import { useTranslation } from 'react-i18next';
import { nethansaPalette } from 'theme';
import { Box, Typography } from '@mui/material';

export interface Props {
  productsNumber: number | undefined;
}

export const SelectedProducts: React.FC<Props> = (props) => {
  const [t] = useTranslation('products');
  return (
    <Box
      sx={{
        gap: '40px',
        flexDirection: 'row',
        backgroundColor: nethansaPalette.background.title,
        width: '100%',
        pl: 2,
        py: 1,
        fontSize: '13px',
        lineHeight: '18px',
      }}
    >
      <Typography variant="h5">
        <span>{t('actions.generic-labels.for.prefix')} </span>
        <strong>
          {t('actions.generic-labels.for.products', {
            count: props.productsNumber,
          })}
        </strong>
      </Typography>
      <Typography variant="h5">{t('actions.generic-labels.warning')}</Typography>
    </Box>
  );
};
