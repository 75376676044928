import { useMemo } from 'react';
import { InputMaybe, SortEnumType } from 'generated/graphql';
import { GridLogicOperator } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getColumnType, parseFilterValue, reduceArrayOfFilters } from '../utils';
import { useDatagridContext } from './use-datagrid-context';

type ResultType<TFilter = Record<string, unknown>, TSort = Record<string, unknown>> = {
  first: number;
  after?: string;
  filter?: InputMaybe<TFilter>;
  sort?: InputMaybe<TSort>;
};

// Translate data grid filters and sorting context to graphql request
export function useDatagridContextGraphqlTranslator<
  TColumn extends GridColDef,
  TColumnKey = string[],
  TFilter = Record<string, unknown>,
  TSort = Record<string, unknown>,
>(columns: TColumn[], searchableColumns: TColumnKey[]): ResultType<TFilter, TSort> {
  const gridContext = useDatagridContext();

  const first = useMemo(() => gridContext.itemsPerPage[0], [gridContext.itemsPerPage[0]]);

  const after = useMemo(
    () => gridContext.page[0] * gridContext.itemsPerPage[0],
    [gridContext.page[0], gridContext.itemsPerPage[0]],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sortReduced: InputMaybe<any> = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return gridContext.sort[0].reduce<InputMaybe<any>>((acc, v) => {
      if (!acc) acc = {};
      // eslint-disable-next-line
      // @ts-ignore
      acc[v.field] = v.sort === 'asc' ? SortEnumType.Asc : SortEnumType.Desc;
      return acc;
    }, {});
  }, [gridContext.sort[0]]);

  const sort = gridContext.sort[0]?.length > 0 ? sortReduced : undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const search: InputMaybe<any> | undefined =
    gridContext.search[0].length > 0
      ? {
          or: searchableColumns?.map((x) => {
            const column = columns.find((y) => y.field === x);
            const value = gridContext.search[0];
            if (!column) return {};
            const operator = column.type === 'number' ? 'eq' : 'contains';
            return {
              [x as string]: {
                [operator]: parseFilterValue(
                  value,
                  column.field,
                  getColumnType(column.field, columns),
                ),
              },
            };
          }),
        }
      : undefined;

  const reducedFilters = reduceArrayOfFilters(
    gridContext.filter[0].items,
    columns,
    gridContext.filter[0].logicOperator || GridLogicOperator.And,
  );

  const filter =
    !!search || !!reducedFilters
      ? {
          and: [
            ...(search ? [{ ...search }] : []),
            ...(reducedFilters ? [{ ...reducedFilters }] : []),
          ],
        }
      : undefined;

  return {
    first: first,
    after: after > 0 ? window.btoa(String(after - 1)) : undefined,
    sort: sort as InputMaybe<TSort>,
    filter: filter as InputMaybe<TFilter>,
  };
}
