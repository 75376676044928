import React from 'react';
import { Show } from 'components/show';
import { Alert, Box, Button, DialogActions, Typography } from '@mui/material';
import { useCommonTranslations } from '../../modules/shared';
import { FormButtonsGroup } from '../form-buttons-group';
import { BaseModalProps, DialogBaseWrapper } from './index';

type Props = BaseModalProps & {
  description?: string | JSX.Element;
  confirmLabel?: string;
  isLoading?: boolean;
  isError?: boolean;
  isConfirmationDisabled?: boolean;
  errorMessage?: string;
  showOnlyClose?: boolean;
};

export const DialogConfirmation: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();

  return (
    <DialogBaseWrapper
      header={props.header}
      tooltip={props.tooltip}
      open={props.open}
      onClose={() => props.onClose(false)}
      dangerHeader={props.dangerHeader}
      disableCloseOnBackdropClick
    >
      <Show when={props.description !== undefined && props.description !== ''}>
        <Box sx={{ px: '32px', pb: '32px', maxWidth: '500px' }}>
          {typeof props.description === 'string' ? (
            <Typography variant="h5" sx={{ whiteSpace: 'pre-line' }}>
              {props.description}
            </Typography>
          ) : (
            props.description
          )}
        </Box>
      </Show>

      {props.isError && (
        <Box sx={{ px: 3.5, pb: 2 }}>
          <Alert color="error">{props.errorMessage}</Alert>
        </Box>
      )}

      {props.children}

      <DialogActions
        sx={{
          px: 3,
          py: 2,
          borderTop: (theme) => `1px solid ${theme.palette.nethansa.line.light}`,
        }}
      >
        <FormButtonsGroup isLoading={props.isLoading}>
          <Show when={!props.showOnlyClose}>
            <Button
              type="button"
              size="small"
              variant="text"
              disabled={props.isLoading}
              onClick={() => props.onClose(false)}
            >
              {tCommon('cancel')}
            </Button>
            <Button
              type="button"
              size="small"
              disabled={props.isLoading || props.isError || props.isConfirmationDisabled}
              onClick={() => props.onClose(true)}
            >
              {props.confirmLabel ? props.confirmLabel : tCommon('confirm')}
            </Button>
          </Show>
          <Show when={props.showOnlyClose === true}>
            <Button
              type="button"
              size="small"
              disabled={props.isLoading}
              onClick={() => props.onClose(false)}
            >
              {tCommon('close')}
            </Button>
          </Show>
        </FormButtonsGroup>
      </DialogActions>
    </DialogBaseWrapper>
  );
};
