import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getGridNumericOperators, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';
import { useCommonTranslations } from '../../shared';
import { NumberBetweenInputOperator } from '../components';

export const getNumericBetweenOperator = (t: TFunction) => {
  return {
    label: t('datagrid.operators.date.between'),
    value: 'between',
    InputComponentProps: {
      sx: {
        width: '320px',
      },
    },
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: { value?: string }): boolean => {
        return Boolean(params.value);
      };
    },
    InputComponent: NumberBetweenInputOperator,
  };
};

export const useDatagridNumericOperators = (): GridFilterOperator[] => {
  const { tCommon } = useCommonTranslations();
  const { i18n } = useTranslation();

  return useMemo(
    () => [...getGridNumericOperators(), getNumericBetweenOperator(tCommon)],
    [i18n.language],
  );
};
