import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { longDateWithMonthDescription } from 'common/format/date';
import { queryClient } from 'common/query-client';
import { DialogConfirmation, Show } from 'components';
import { ActionDetails } from 'modules/offers/components/modal-action-details/action-details';
import { BulkActionSummary } from 'modules/products/components/bulk-action-summary';
import { useProductsCoordinatorContext } from 'modules/products/hooks';
import { useBulkApproveSuspicousProductCostMutation } from 'modules/products/hooks/use-bulk-approve-suspicious-product-cost.mutation';
import { useCommonTranslations } from 'modules/shared';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import { Alert, Typography } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import { DetailedActionResponseOfProductErrorCode, Query } from '../../../../generated/graphql';
import { SelectedProductDetails } from '../components/selected-product-details';
import { SelectedProducts } from '../components/selected-products';

export const AcceptSuspectedCost: React.FC = () => {
  const {
    acceptSuspectedCost: [actionParams, setActionParams],
  } = useProductsCoordinatorContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { pushSnackbar } = useSnackbar();

  const [t, i18n] = useTranslation('products');
  const { tCommon } = useCommonTranslations();

  const [summary, setSummary] = useState<DetailedActionResponseOfProductErrorCode>({
    numberOfSucceededOperations: 0,
    errors: null,
  });
  const [showSummarize, setShowSummarize] = useState(false);
  const isSingleElement = actionParams?.row != null;

  const useApproveSuspicousProductCost = useBulkApproveSuspicousProductCostMutation();

  const refreshProducts = () => queryClient.refetchQueries({ active: true, queryKey: 'products' });
  const closeModal = () => setActionParams(undefined);
  const handleResponse = (data: Query) => {
    const errors = data.approveSuspicousProductCost?.errors;

    if (isSingleElement) {
      if (!errors || errors.length == 0) {
        refreshProducts();
        actionParams?.onSuccess && actionParams?.onSuccess();
        closeModal();
        pushSnackbar({
          variant: 'success',
          message: t('modals.acceptSuspectedCost.messages.success'),
        });
      } else {
        setErrorMessage(t(`errors.${errors[0].key}` as 'errors.Unknown'));
        return;
      }
    }

    setSummary(data.approveSuspicousProductCost);
    setShowSummarize(true);
    refreshProducts();
  };
  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }

    useApproveSuspicousProductCost.mutate(
      {
        where: actionParams?.filters,
      },
      {
        onSuccess: (data) => handleResponse(data),
        onError: () => setErrorMessage(t('modals.acceptSuspectedCost.messages.error')),
      },
    );
  };
  const suspiciousPercent =
    actionParams &&
    actionParams.row &&
    actionParams.row.productCost &&
    actionParams.row.suspiciousProductCost
      ? (
          100 -
          (actionParams.row.suspiciousProductCost * 100) / actionParams.row.productCost
        ).toFixed(2)
      : 0;

  return (
    <DialogConfirmation
      isLoading={useApproveSuspicousProductCost.isLoading}
      confirmLabel={t('modals.buttons.confirm')}
      open
      onClose={confirm}
      header={t('modals.acceptSuspectedCost.header')}
      description=""
      showOnlyClose={showSummarize}
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <Show when={actionParams?.row != null}>
          <SelectedProductDetails row={actionParams?.row} />
        </Show>
        <Show when={actionParams?.row == null}>
          <SelectedProducts productsNumber={actionParams?.selectedCount} />
        </Show>

        <ActionDetails
          title={t('actions.action-details-title')}
          content={t('modals.acceptSuspectedCost.action-details')}
        />

        <Show when={actionParams?.row != null}>
          <Stack
            sx={{ backgroundColor: nethansaPalette.background.title, px: 2, py: 1, width: '100%' }}
          >
            <Typography variant="h5Text" sx={{ mb: 2 }}>
              {t('modals.acceptSuspectedCost.section1.desc', { percent: suspiciousPercent })}
            </Typography>
            <Stack direction="row" gap="10px">
              <Typography variant="h5Text" sx={{ color: nethansaPalette.main.gray }}>
                {t('modals.acceptSuspectedCost.section1.product-cost-to-date')}:
              </Typography>
              <Typography variant="h5Text">
                {tCommon(
                  `currencies.${actionParams?.row?.baseCurrencyCode || 'EUR'}` as 'currencies.EUR',
                )}{' '}
                {actionParams?.row?.productCost?.toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction="row" gap="10px">
              <Typography variant="h5Text" sx={{ color: nethansaPalette.main.gray }}>
                {t('modals.acceptSuspectedCost.section1.suspicious-product-cost')}:
              </Typography>
              <Typography variant="h5Text">
                {tCommon(
                  `currencies.${actionParams?.row?.baseCurrencyCode || 'EUR'}` as 'currencies.EUR',
                )}{' '}
                {actionParams?.row?.suspiciousProductCost?.toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction="row" gap="10px">
              <Typography variant="h5Text" sx={{ color: nethansaPalette.main.gray }}>
                {t('modals.acceptSuspectedCost.section1.suspicious-product-cost-date')}:
              </Typography>
              <Typography variant="h5Text">
                {longDateWithMonthDescription(
                  actionParams?.row?.suspiciousProductCostUpdatedAt as string,
                  i18n.language,
                )}
              </Typography>
            </Stack>
            <Stack direction="row" gap="10px">
              <Typography variant="h5Text" sx={{ color: nethansaPalette.main.gray }}>
                {t('modals.acceptSuspectedCost.section1.suspicious-product-cost-source')}:
              </Typography>
              <Typography variant="h5Text">
                {actionParams?.row?.suspiciousProductCostSource}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{ backgroundColor: nethansaPalette.background.title, px: 2, py: 1, width: '100%' }}
          >
            <Typography variant="h5Bold">
              {t('modals.acceptSuspectedCost.section2.title', {
                value: actionParams?.row?.suspiciousProductCost,
                currency: actionParams?.row?.baseCurrencyCode,
              })}
            </Typography>
            <Typography variant="h5Text">
              {t('modals.acceptSuspectedCost.section2.desc')}
            </Typography>
          </Stack>
        </Show>

        <Show when={actionParams?.row == null && !showSummarize}>
          <Stack
            sx={{ backgroundColor: nethansaPalette.background.title, px: 2, py: 1, width: '100%' }}
          >
            <Typography variant="h5Bold">
              {t('modals.acceptSuspectedCost.section2.title-mass')}
            </Typography>
          </Stack>
        </Show>

        <Show when={errorMessage != null}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Show>

        <Show when={showSummarize}>
          <BulkActionSummary data={summary} />
        </Show>
      </Stack>
    </DialogConfirmation>
  );
};
