import React, { useCallback, useState } from 'react';
import { AppModules } from '../types';

type AppGlobalParametersContext = {
  offersModuleWithBars: boolean;
  reportsModuleWithBars: boolean;
  productsModuleWithBars: boolean;
  monitoringModuleWithBars: boolean;
  dashboardModuleWithBars: boolean;
  toggleBarsVisibility: (currentModule: AppModules) => void;
};

const defaultValue: AppGlobalParametersContext = {
  offersModuleWithBars: true,
  reportsModuleWithBars: true,
  productsModuleWithBars: true,
  monitoringModuleWithBars: true,
  dashboardModuleWithBars: true,
  toggleBarsVisibility: () => null,
};

export const AppGlobalParametersContext =
  React.createContext<AppGlobalParametersContext>(defaultValue);

type Props = React.PropsWithChildren<unknown>;

export const AppGlobalParametersProvider: React.FC<Props> = (props) => {
  const [showOffersBars, setShowOffersBars] = useState(defaultValue.offersModuleWithBars);
  const [showReportsBars, setShowReportsBars] = useState(defaultValue.reportsModuleWithBars);
  const [showProductsBars, setShowProductsBars] = useState(defaultValue.productsModuleWithBars);
  const [showMonitoringBars, setShowMonitoringBars] = useState(
    defaultValue.monitoringModuleWithBars,
  );
  const [showDashboardBars, setshowDashboardBars] = useState(defaultValue.dashboardModuleWithBars);

  const toggleBarsVisibility = useCallback(
    (currentModule: AppModules) => {
      if (currentModule === 'Offers') setShowOffersBars(!showOffersBars);
      if (currentModule === 'Reports') setShowReportsBars(!showReportsBars);
      if (currentModule === 'Products') setShowProductsBars(!showProductsBars);
      if (currentModule === 'Monitoring') setShowMonitoringBars(!showMonitoringBars);
      if (currentModule === 'Dashboard') setshowDashboardBars(!showDashboardBars);
    },
    [showOffersBars, showReportsBars, showProductsBars, showMonitoringBars, showDashboardBars],
  );

  return (
    <AppGlobalParametersContext.Provider
      value={{
        offersModuleWithBars: showOffersBars,
        reportsModuleWithBars: showReportsBars,
        productsModuleWithBars: showProductsBars,
        monitoringModuleWithBars: showMonitoringBars,
        dashboardModuleWithBars: showDashboardBars,
        toggleBarsVisibility,
      }}
    >
      {props.children}
    </AppGlobalParametersContext.Provider>
  );
};
