import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const AllViewsIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 21H21V3H3L3 21ZM8.25375 4.8V7.05H5.25V4.8H8.25375ZM10.5037 7.05V4.8H13.5075V7.05H10.5037ZM5.25 19.2V8.85L18.75 8.85V19.2H5.25ZM18.75 7.05L15.7463 7.05V4.8H18.75V7.05Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
