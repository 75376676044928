import React from 'react';
import { ExportIcon } from 'components/icons';
import { Box, Button, CircularProgress, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Show, Tooltip } from '../../../../components';

type Props = {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  options: {
    label: string;
    tooltip?: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
};

export const DatagridExportMenu: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        color="flat"
        size="small"
        id="export-button"
        aria-controls={open ? 'export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={
          props.loading ? (
            <CircularProgress size="16px" color="inherit" />
          ) : (
            <ExportIcon sx={{ height: 24, width: 'auto' }} />
          )
        }
        disabled={props.disabled}
      >
        {props.label || 'Export'}
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
      >
        {props.options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (option.disabled) return;
              option.onClick();
              handleClose();
            }}
            disableRipple={props.disabled}
            disableTouchRipple={props.disabled}
            disableGutters={props.disabled}
            sx={{ '&:hover': { backgroundColor: 'nethansa.other.pale' } }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                sx={{
                  fontSize: '13px',
                  color: option.disabled ? 'nethansa.button.grayLight' : undefined,
                }}
              >
                {option.label}
              </Typography>
              <Show when={Boolean(option.tooltip)}>
                <Box>
                  <Tooltip content={option.tooltip} />
                </Box>
              </Show>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
