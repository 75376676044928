import { TooltipClasses, TooltipProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const tooltipStyles: {
  defaultProps?: Partial<TooltipProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TooltipClasses>> | undefined;
} = {
  styleOverrides: {
    popper: {
      zIndex: 5,
      marginBottom: '0px',
      '&[data-popper-placement="top-start"]': {
        '& .MuiTooltip-tooltip': {
          marginLeft: '-21px',
          '& .Tooltip-Arrow': {
            left: '20px',
          },
        },
      },
      '&[data-popper-placement="top-end"]': {
        '& .MuiTooltip-tooltip': {
          marginRight: '-19px',
          '& .Tooltip-Arrow': {
            right: '20px',
          },
        },
      },
      '&[data-popper-placement="top"]': {
        '& .MuiTooltip-tooltip': {
          mb: -2,
          '& .Tooltip-Arrow': {
            left: 'calc(50% - 8px)',
          },
        },
      },
      '&[data-popper-placement="bottom"]': {
        '& .MuiTooltip-tooltip': {
          '& .Tooltip-Arrow': {
            left: 'calc(50% - 8px)',
            top: '-9px',
            transform: 'rotate(180deg)',
          },
        },
      },
      '&[data-popper-placement="bottom-start"]': {
        '& .MuiTooltip-tooltip': {
          marginLeft: '-20px',
          '& .Tooltip-Arrow': {
            left: '30px',
            top: '-9px',
            transform: 'rotate(180deg)',
          },
        },
      },
      '&[data-popper-placement="bottom-end"]': {
        '& .MuiTooltip-tooltip': {
          marginRight: '-20px',
          '& .Tooltip-Arrow': {
            right: '28px',
            top: '-9px',
            transform: 'rotate(180deg)',
          },
        },
      },
      '&[data-popper-placement="right-start"]': {
        '& .MuiTooltip-tooltip': {
          marginTop: '-20px',
          '& .Tooltip-Arrow': {
            left: '-12px',
            top: '20px',
            transform: 'rotate(90deg)',
          },
        },
      },
      '&[data-popper-placement="right-end"]': {
        '& .MuiTooltip-tooltip': {
          marginBottom: '-20px',
          '& .Tooltip-Arrow': {
            top: 'calc(100% - 42px)',
            left: '-12px',
            transform: 'rotate(90deg)',
          },
        },
      },
      '&[data-popper-placement="right"]': {
        '& .MuiTooltip-tooltip': {
          '& .Tooltip-Arrow': {
            left: '-12px',
            top: 'calc(50% - 5px)',
            transform: 'rotate(90deg)',
          },
        },
      },
      '&[data-popper-placement="left-start"]': {
        '& .MuiTooltip-tooltip': {
          marginTop: '-20px',
          '& .Tooltip-Arrow': {
            right: '-13px',
            top: '25px',
            transform: 'rotate(-90deg)',
          },
        },
      },
      '&[data-popper-placement="left-end"]': {
        '& .MuiTooltip-tooltip': {
          marginBottom: '-20px',
          '& .Tooltip-Arrow': {
            right: '-13px',
            top: 'calc(100% - 38px)',
            transform: 'rotate(-90deg)',
          },
        },
      },
      '&[data-popper-placement="left"]': {
        '& .MuiTooltip-tooltip': {
          '& .Tooltip-Arrow': {
            right: '-13px',
            top: 'calc(50% - 5px)',
            transform: 'rotate(-90deg)',
          },
        },
      },
    },
    tooltip: {
      background: nethansaPalette.main.light,
      color: nethansaPalette.main.dark,
      padding: 16,
      boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.3)',
      borderRadius: '6px',
      maxWidth: '30rem',
      fontSize: '13px',
      lineHeight: '18px',
      '& ul': {
        listStyleType: 'square',
        paddingLeft: 32,
        margin: '8px 0 0 0',
      },
    },
  },
};
