import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { Tooltip, Checkbox } from '../../../../../../components';
import { useOfferASINQuery } from '../../../../hooks';
import { OfferItem } from './components/offer-item';

export const OffersList: React.FC = () => {
  const { t } = useTranslation('offers');
  const form = useFormContext();
  const { data } = useOfferASINQuery();

  const hasAtLeastOneOffer = (data?.offers || []).length > 0;

  return (
    <Stack spacing={3}>
      {hasAtLeastOneOffer && (
        <>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5Bold" sx={{ lineHeight: '18px' }}>
              {t('modals.edit-asin-modal.offer.click-to-select-multiple.label', {
                count: data?.offers.length,
              })}
            </Typography>
            <Tooltip
              content={t('modals.edit-asin-modal.offer.click-to-select-multiple.tooltip.__html')}
            />
          </Stack>

          <Stack
            sx={{
              pl: 0,
              pr: 4,
              mt: '8px !important',
              minHeight: '300px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            spacing={2}
          >
            {(data?.offers || []).map((x) => (
              <OfferItem key={x.asin} {...x} />
            ))}
          </Stack>
        </>
      )}

      <Box sx={{ pr: 4 }}>
        <Controller
          control={form.control}
          name="targetAsin"
          rules={{
            required: {
              value: true,
              message: t('form.targetAsin.validation.required'),
            },
            pattern: {
              value: /^[A-Z\d]{10}$/,
              message: t('form.targetAsin.validation.pattern'),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <Stack direction="row" alignItems="center" spacing={1.5}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="h5Bold">{t('form.targetAsin.label')}</Typography>
                  <Tooltip content={t('form.targetAsin.tooltip.__html')} />
                </Stack>
                <TextField
                  autoComplete="target-asin"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 2, maxWidth: '128px', flex: 1 }}
                />
              </Stack>
            );
          }}
        />
        <Stack direction="row" alignItems="center" spacing={1}>
          <Controller
            control={form.control}
            name="sendToAmazon"
            render={({ field }) => (
              <Checkbox
                label={t('modals.edit-asin-modal.send-to-amazon.label')}
                value={field.value}
                onChange={field.onChange}
                sx={{
                  ml: '-10px',
                  '& + .MuiFormControlLabel-label': {
                    ml: '0 !important',
                    mr: '-16px',
                    pt: '4px',
                  },
                }}
              />
            )}
          />
          <Tooltip content={t('modals.edit-asin-modal.send-to-amazon.tooltip')} />
        </Stack>
      </Box>
    </Stack>
  );
};
