import React, { useState } from 'react';
import { StateSetterValue } from '../../shared/types';
import {
  EditAsinType,
  MultiplePriceSettingsParamsType,
  SelectedOffersWithChannelCode,
  SinglePriceSettingsParamsType,
  OfferDetailsType,
  OfferGridRow,
  SelectedOffers,
} from '../types';
import { OfferAction } from '../types/offer-action';

type OfferModalCoordinatorContextType = {
  sendOffers: StateSetterValue<SelectedOffersWithChannelCode | undefined>;
  editAsin: StateSetterValue<EditAsinType | undefined>;
  priceSettings: StateSetterValue<
    SinglePriceSettingsParamsType | MultiplePriceSettingsParamsType | undefined
  >;
  analyzeOffers: StateSetterValue<SelectedOffersWithChannelCode | undefined>;
  deleteOffers: StateSetterValue<SelectedOffersWithChannelCode | undefined>;
  offerDetails: StateSetterValue<OfferDetailsType | undefined>;
  rawPriceCalculation: StateSetterValue<string | undefined>;
  pauseOffer: StateSetterValue<[OfferGridRow, OfferAction] | undefined>;
  changeSku: StateSetterValue<OfferGridRow | undefined>;
  editTags: StateSetterValue<SelectedOffers | undefined>;
};

export const OfferModalCoordinatorContext = React.createContext<OfferModalCoordinatorContextType>({
  sendOffers: [undefined, () => null],
  editAsin: [undefined, () => null],
  priceSettings: [undefined, () => null],
  analyzeOffers: [undefined, () => null],
  deleteOffers: [undefined, () => null],
  offerDetails: [undefined, () => null],
  rawPriceCalculation: [undefined, () => null],
  pauseOffer: [undefined, () => null],
  changeSku: [undefined, () => null],
  editTags: [undefined, () => null],
});

type Props = React.PropsWithChildren<unknown>;

export const OfferModalsCoordinatorProvider: React.FC<Props> = (props) => {
  const sendOffers = useState<SelectedOffersWithChannelCode | undefined>(undefined);
  const editAsin = useState<EditAsinType | undefined>(undefined);
  const priceSettings = useState<
    SinglePriceSettingsParamsType | MultiplePriceSettingsParamsType | undefined
  >(undefined);
  const analyzeOffers = useState<SelectedOffersWithChannelCode | undefined>(undefined);
  const deleteOffers = useState<SelectedOffersWithChannelCode | undefined>(undefined);
  const offerDetails = useState<OfferDetailsType | undefined>(undefined);
  const rawPriceCalculation = useState<string | undefined>(undefined);
  const pauseOffer = useState<[OfferGridRow, OfferAction] | undefined>(undefined);
  const changeSku = useState<OfferGridRow | undefined>(undefined);
  const editTags = useState<SelectedOffers | undefined>(undefined);

  return (
    <OfferModalCoordinatorContext.Provider
      value={{
        sendOffers,
        editAsin,
        priceSettings,
        analyzeOffers,
        deleteOffers,
        offerDetails,
        rawPriceCalculation,
        pauseOffer,
        changeSku,
        editTags,
      }}
    >
      {props.children}
    </OfferModalCoordinatorContext.Provider>
  );
};
