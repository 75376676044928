import * as React from 'react';
import { ReactElement } from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { TooltipArrow } from './components/tooltip-arrow';

type Props = {
  children: ReactElement;
  content: string | React.ReactElement;
} & Omit<TooltipProps, 'components' | 'arrow' | 'title'>;

export const TooltipWrapper: React.FC<Props> = (props) => {
  const { children, content, ...tooltipProps } = props;

  return (
    <MuiTooltip
      disableFocusListener
      disableTouchListener
      placement="top-start"
      arrow
      components={{
        Arrow: TooltipArrow,
      }}
      PopperProps={{}}
      title={content}
      {...tooltipProps}
    >
      {children}
    </MuiTooltip>
  );
};
