import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQuery } from 'react-query';
import { API, httpClient } from 'common/api';
import { useAuth } from 'modules/authorization';
import { ResponseAPIType } from 'modules/shared';

export type Tutorial = {
  id: number;
  name: string;
  order: number;
  chapters: TutorialChapter[];
};

export type TutorialChapter = {
  id: number;
  title: string;
  content: string;
  order: number;
};

export const useTutorialDataQuery = (): UseQueryResult<Tutorial[]> => {
  const { authorized } = useAuth();
  const { i18n } = useTranslation('dashboard');

  return useQuery<Tutorial[]>(
    ['cms', 'tutorials'],
    async () => {
      const response = await httpClient.get<ResponseAPIType<Tutorial[]>>(
        API.cms(i18n.language).tutorials(),
      );

      return [...response.data.data];
    },

    {
      staleTime: 20000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
