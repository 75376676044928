import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BillIcon,
  CarrierIcon,
  /* PaymentsIcon,
ShieldCheckedIcon,
UserAddIcon, */
  UserEditIcon,
  WalletIcon,
} from 'components/icons';
import { ClaimDefenderComponent } from 'modules/authorization';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { routes } from 'routes';
import { Stack } from '@mui/material';
import { SectionCard } from './components';

export const SettingsConfigurationPage: React.FC = () => {
  const [t] = useTranslation('settings');
  const globalSettingsQuery = useGlobalSettingsQuery();
  const SalesIntelligenceRoutes = routes.Settings.children.SalesIntelligence;
  const ProfileRoutes = routes.Settings.children.UserProfile;
  const CarriersRoutes = routes.Settings.children.Carriers;
  const PaymentsAndBillingsRoutes = routes.Settings.children.PaymentsAndBillings;

  const isFetched = globalSettingsQuery.isFetched;
  const isWizardConfigured = globalSettingsQuery.data?.isConfigured || false;

  return (
    <Stack sx={{ p: 4 }} direction="row" flexWrap="wrap" gap="22px">
      <ClaimDefenderComponent notFullWidth allowed={'SETTINGS_SALES_INTELLIGENCE'} hidden={true}>
        <SectionCard
          disabled={isFetched && !isWizardConfigured}
          icon={<WalletIcon sx={{ fontSize: '52px' }} />}
          title={t('configuration.sales-intelligence')}
          links={[
            {
              label: t('configuration.platforms'),
              href: SalesIntelligenceRoutes.children.Platforms.link(),
            },
            {
              label: t('configuration.vat-rates'),
              href: SalesIntelligenceRoutes.children.VatRates.link(),
            },
            {
              label: t('configuration.global-settings'),
              href: SalesIntelligenceRoutes.children.GlobalSettings.link(),
            },
          ]}
        />
      </ClaimDefenderComponent>
      <ClaimDefenderComponent notFullWidth allowed={'SETTINGS_PAYMENTS_AND_BILLINGS'} hidden={true}>
        <SectionCard
          icon={<BillIcon sx={{ fontSize: '52px' }} />}
          title={t('configuration.payments-management')}
          links={[
            {
              label: t('configuration.payments'),
              href: PaymentsAndBillingsRoutes.children.Payments.link(),
            },
            {
              label: t('configuration.billing-data'),
              href: PaymentsAndBillingsRoutes.children.BillingData.link(),
            },
          ]}
        />
      </ClaimDefenderComponent>
      <ClaimDefenderComponent notFullWidth allowed={'SETTINGS_CARRIERS'} hidden={true}>
        <SectionCard
          icon={<CarrierIcon sx={{ fontSize: '52px' }} />}
          title={t('configuration.carriers')}
          links={[
            { label: t('configuration.integrations'), href: CarriersRoutes.link() },
            {
              label: t('configuration.shipping-countries'),
              href: CarriersRoutes.children.ShippingCountry.link(),
            },
            {
              label: t('configuration.ship-from-address'),
              href: CarriersRoutes.children.ShipFromAddress.link(),
            },
          ]}
        />
      </ClaimDefenderComponent>
      <ClaimDefenderComponent notFullWidth allowed={'SETTINGS_YOUR_PROFILE'} hidden={true}>
        <SectionCard
          icon={<UserEditIcon sx={{ fontSize: '52px' }} />}
          title={t('configuration.your-profile')}
          links={[
            { label: t('configuration.personal-data'), href: ProfileRoutes.link() },
            {
              label: t('configuration.change-password'),
              href: ProfileRoutes.children.ChangePassword.link(),
            },
            { label: t('configuration.security'), href: ProfileRoutes.children.Security.link() },
          ]}
        />
      </ClaimDefenderComponent>
      {/* Hidden, beacuse no content in sections and opacity style is reserved for readonly role view.
        <SectionCard
          disabled
          icon={<UserAddIcon sx={{ fontSize: '52px' }} />}
          title={t('configuration.users')}
          links={[
            { label: t('configuration.users'), href: SalesIntelligenceRoutes.link() },
            {
              label: t('configuration.add-new-user'),
              href: SalesIntelligenceRoutes.children.Platforms.link(),
            },
          ]}
        />
        <SectionCard
          disabled
          icon={<ShieldCheckedIcon sx={{ fontSize: '48px' }} />}
          title={t('configuration.products-updates')}
          links={[
            { label: t('configuration.update-settings'), href: SalesIntelligenceRoutes.link() },
          ]}
        />
        <SectionCard
          disabled
          icon={<PaymentsIcon sx={{ fontSize: '40px' }} />}
          title={t('configuration.usage-and-subscription')}
          links={[
            { label: t('configuration.subscription'), href: SalesIntelligenceRoutes.link() },
            {
              label: t('configuration.usage'),
              href: SalesIntelligenceRoutes.children.Platforms.link(),
            },
          ]}
        />
      */}
    </Stack>
  );
};
