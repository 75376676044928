import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AmazonLogo, FlagIcon, Tooltip } from 'components';
import { useCommonTranslations } from 'modules/shared';
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PlatformsConnectorContext } from '../../contexts/platfroms-modal.provider';
import { usePlatformMarketsMutation } from '../../hooks/use-platform-markets.mutation';
import { usePlatformMarketsQuery } from '../../hooks/use-platform-markets.query';
import { ConnectorHeader } from '../connector-header';

type FormProps = {
  markets: string[];
};

export const MarketsSettings: React.FC = () => {
  const [t] = useTranslation('platforms');
  const { tCommon } = useCommonTranslations();

  const context = useContext(PlatformsConnectorContext);
  const marketsQuery = usePlatformMarketsQuery('amzeu');
  const marketsMutation = usePlatformMarketsMutation('amzeu');

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful },
  } = useForm<FormProps>({
    defaultValues: {
      markets: [],
    },
  });

  const submit = async (values: FormProps) => {
    const data: { code: string; isManagedByClipperon: boolean }[] =
      marketsQuery.data?.map((x) => ({
        code: x.code,
        isManagedByClipperon: values.markets.some((y) => y === x.code) && x.isEnabled,
      })) || [];
    await marketsMutation.mutate(data, {
      onSuccess: () => {
        context.close();
      },
    });
  };

  useEffect(() => {
    if (!marketsQuery.data || marketsQuery.isLoading) return;
    setValue(
      'markets',
      marketsQuery.data
        .filter((x) => x.isManagedByClipperon || x.isDefaultMarketplace)
        .map((x) => x.code),
    );
  }, [marketsQuery.data, marketsQuery.isLoading]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box sx={{ p: 4, pb: 2 }}>
        <ConnectorHeader logo={<AmazonLogo />} stepsCount={2} />
        <Box sx={{ mt: 4 }}>
          <Alert severity="success" sx={{ mb: 4 }}>
            <span>{t('amazon.markets.connect-success')}</span>
          </Alert>

          <Typography sx={{ mb: 2 }} variant="h2">
            {t('amazon.markets.title')}
          </Typography>

          <Stack direction="row" gap="16px" sx={{ mx: 4, maxHeight: '290px', overflow: 'auto' }}>
            <Controller
              control={control}
              name="markets"
              render={({ field }) => {
                return (
                  <>
                    <RadioGroup
                      defaultValue={
                        marketsQuery.data?.filter((m) => m.isManagedByClipperon)[0]?.code || 'DE'
                      }
                      value={field.value}
                      sx={{ width: '100%' }}
                    >
                      <Table sx={{ width: '100%' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('amazon.markets.table.marketplace.header')}</TableCell>
                            <TableCell>
                              <Stack direction="row" justifyContent={'center'}>
                                <Box sx={{ mr: 1 }}>
                                  {t('amazon.markets.table.on-holiday.header')}
                                </Box>
                                <Tooltip
                                  content={t('amazon.markets.table.on-holiday.tooltip.__html')}
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {marketsQuery.data?.map((x) => (
                            <TableRow key={x.code}>
                              <TableCell sx={{ p: 0 }}>
                                <Stack direction="row" alignItems="center" gap="10px">
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        disabled={marketsQuery.isLoading || !x.isEnabled}
                                        value={x.code}
                                        onBlur={() => field.onBlur()}
                                        onChange={(val) => {
                                          field.onChange((field.value = [val.target?.value]));
                                        }}
                                      />
                                    }
                                    label={
                                      <Stack direction={'row'} gap="6px" alignItems={'center'}>
                                        {x.code}: {t(`amazon.markets.website.${x.code}`)}
                                        <FlagIcon country={x.code} />
                                      </Stack>
                                    }
                                  />
                                </Stack>
                              </TableCell>
                              <TableCell sx={{ p: 0, textAlign: 'center !important' }}>
                                {x.isEnabled
                                  ? x.isOnHoliday
                                    ? t('amazon.markets.table.on-holiday.yes')
                                    : t('amazon.markets.table.on-holiday.no')
                                  : t('amazon.markets.table.n-a')}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </>
                );
              }}
            />
          </Stack>
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        gap="10px"
        sx={{ py: 2, px: 4, borderTop: '1px solid #DDE8F1' }}
      >
        {!isSubmitSuccessful && (
          <Button size="small" type="submit" disabled={marketsQuery.isLoading}>
            {tCommon('save')}
          </Button>
        )}
      </Stack>
    </form>
  );
};
