import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormAlert, FormButtonsGroup } from 'components';
import { useCommonTranslations } from 'modules/shared/hooks';
import { Box, Button, Divider, Stack } from '@mui/material';
import { queryClient } from '../../../../../../common/query-client';
import { useOfferASINQuery, useOfferCoordinatorContext } from '../../../../hooks';
import { useOfferEditAsinMutation } from '../../../../hooks/use-offer-edit-asin.mutation';
import { OffersList } from '../offers-list';
import { ProductData } from '../product-data';

export type FormProps = {
  targetAsin: string;
  sendToAmazon: boolean;
};

export const EditASINForm: React.FC = () => {
  const { t } = useTranslation('offers');
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { tCommon } = useCommonTranslations();

  const { data } = useOfferASINQuery();
  const editASINMutation = useOfferEditAsinMutation();

  const {
    editAsin: [actionParams, setEditAsinData],
  } = useOfferCoordinatorContext();

  const form = useForm<FormProps>({
    defaultValues: {
      targetAsin: data?.targetAsin || '',
      sendToAmazon: false,
    },
    mode: 'onBlur',
  });

  const handleSuccess = () => {
    queryClient.refetchQueries({ active: true, queryKey: 'offers' });
    actionParams?.onSuccess && actionParams?.onSuccess();
    setEditAsinData(undefined);
  };

  const submit = (body: FormProps) => {
    if (body.targetAsin === data?.targetAsin && body.sendToAmazon === false) {
      handleSuccess();
      return;
    }

    editASINMutation.mutate(body, {
      onSuccess: handleSuccess,
    });
  };

  const disabled = editASINMutation.isLoading;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <Stack
          direction="row"
          spacing={4}
          sx={{ pb: 2 }}
          divider={<Divider orientation="horizontal" sx={{ width: '1px' }} />}
        >
          <Box sx={{ maxWidth: '350px' }}>
            <ProductData />
          </Box>
          <Box sx={{ maxWidth: '520px' }}>
            <OffersList />
          </Box>
        </Stack>
        <Divider />
        <Box sx={{ p: 1 }}>
          <FormAlert
            show={editASINMutation.isSuccess || editASINMutation.isError}
            isSuccess={editASINMutation.isSuccess}
            isError={editASINMutation.isError}
            errorMessage={t('modals.edit-asin-modal.error-update')}
            successMessage={t('modals.edit-asin-modal.success-update')}
          />
        </Box>
        <FormButtonsGroup sx={{ py: 2, px: 3, pt: 0 }} isLoading={disabled}>
          <Button
            type="button"
            size="small"
            variant="text"
            disabled={disabled}
            onClick={() => setEditAsinData(undefined)}
          >
            {tCommon('cancel')}
          </Button>
          <Button ref={submitButtonRef} disabled={disabled} type="submit" size="small">
            {tCommon('change')}
          </Button>
        </FormButtonsGroup>
      </form>
    </FormProvider>
  );
};
