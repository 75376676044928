import { Maybe, SharedOfferStateEnum, SharedOfferSubStateEnum } from 'generated/graphql';
import { CurrencyCode } from 'modules/shared/types';

export type OfferDetailsType = {
  offerId: string;
  productName: string;
  status: SharedOfferStateEnum;
  detailedStatus: Maybe<SharedOfferSubStateEnum>[];
  marketplaceCode: string; //MarketCodeType
  channelCode: string; // PlatformChannelCode
  domain: string;
  productUrl: string;
  asin: string | null;
  calculatedPrice: number | null;
  listingPrice: number | null;
  currencyCode: string | null; // CurrencyCode
};

export type Money = {
  value: number | null;
  currencyCode: CurrencyCode;
};

export type Percent = {
  value: number | null;
};

export enum PricingModel {
  'unknown' = 0,
  'off' = 1,
  'full' = 2, // Full profit maximization,
  'limited' = 3, // Limited profit maximization
  'fixed' = 4, // Fixed price sale
}

export type PriceComponentsBreakdownType = {
  productCost: Money;
  productCostInMarketplaceCurrency: Money;
  shippingCost: Money;
  shippingCostInMarketplaceCurrency: Money;
  additionalCost: Money;
  taxRate: Percent;
  taxValue: Money;
  returnsReserve: Percent;
  returnsReserveValue: Money;
  marketplaceCommission: Percent;
  marketplaceCommissionValue: Money;
  nethansaCommission: Percent;
  nethansaCommissionValue: Money;
  marketplaceCommissionFixedValue: Money;
  calculatedPrice: Money;
  actualMarkup: Percent;
  profit: Money;
  additionalProfit: Money;
  otherCostsTotal: Money;
  costsTotal: Money;
  finalPrice: Money;
};

export type OfferDetailsPriceRangeType = {
  pricingModel: PricingModel;
  marketplacePriceManagementEnabled: boolean;
  offerPriceManagementEnabled: boolean;
  fullProfitMaximizationModel: ModelFullProfitMaximizationType;
  limitedProfitMaximizationModel: ModelLimitedProfitMaximizationType;
  fixedPriceTypeModel: ModelFixedPriceType;
};

export type ModelFullProfitMaximizationType = {
  productCost: Money;
  productCostInMarketplaceCurrency: Money;
  shippingCost: Money;
  shippingCostInMarketplaceCurrency: Money;
  taxRate: Percent;
  taxValue: Money;
  returnsReserve: Percent;
  returnsReserveValue: Money;
  additionalCost: Money;
  marketplaceCommission: Percent;
  marketplaceCommissionValue: Money;
  nethansaCommission: Percent;
  nethansaCommissionValue: Money;
  minimumMarkup: Percent;
  standardMarkup: Percent;
  maximumMarkup: Percent;
  minimumPrice: Money;
  standardPrice: Money;
  maximumPrice: Money;
};

export type ModelLimitedProfitMaximizationType = {
  minimumFixedPrice: Money;
  maximumFixedPrice: Money;
};

export type ModelFixedPriceType = {
  fixedPrice: Money;
};
