import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ProfileDataType } from '../types/profile-data.type';

type ProfileResponse = {
  email: string;
  firstName: string;
  lang: 'en-US' | 'pl-PL';
  lastName: string;
  phone: string;
  userId: number;
};

export const useProfileDataQuery = (): UseQueryResult<ProfileDataType> => {
  const { authorized } = useAuth();

  return useQuery<ProfileDataType>(
    ['settings', 'profile-data'],
    async () => {
      const response = await httpClient.get<ResponseAPIType<ProfileResponse>>(
        API.profiles().profile(),
      );

      return {
        ...response.data.data,
      };
    },

    {
      staleTime: 20000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
