import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { TabPanel } from 'components/tab-panel';
import { StepCircleWizard } from 'modules/wizard/components/stepper/components/stepper-circle-wizard';
import { nethansaPalette } from 'theme';
import { Box, Button, ButtonBase, Stack, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { useTutorialDataQuery } from './hooks/use-tutorials-data.query';

export const DashboardPage: React.FC = () => {
  const [t] = useTranslation('dashboard');
  const { data: tutorials, isFetched: tutorialsFetched } = useTutorialDataQuery();
  const [tutorialVisible, setTutorialVisible] = useState(true);
  const [activeTutorial, setActiveTutorial] = React.useState(1);
  const [activeChapter, setActiveChapter] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTutorial(newValue);
    setActiveChapter(0);
  };
  const handleChapterChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveChapter(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const tabStyle: SxProps = {
    color: nethansaPalette.main.gray,
    fontWeight: 700,
    px: 3,
    '&.Mui-selected': {
      border: `1px solid ${nethansaPalette.line.light}`,
      borderRadius: '6px',
      borderBottom: 'none',
      bgcolor: nethansaPalette.main.light,
      px: 3,
    },
  };

  const chapterTabStyle: SxProps = {
    px: 2,
    py: '12px',
    textAlign: 'left',
    borderBottom: `1px solid ${nethansaPalette.line.light}`,
    svg: {
      width: '36px',
      flexShrink: 0,
    },
    '&.Mui-selected': {
      bgcolor: nethansaPalette.main.light,
      px: 2,
      span: {
        fontWeight: '700 !important',
      },
    },
    '&:first-of-type': {
      px: 2,
    },
  };

  const boxStyle: SxProps = {
    bgcolor: nethansaPalette.main.light,
    border: `1px solid ${nethansaPalette.line.light}`,
    borderRadius: '6px',
    boxShadow: `0 3px 5px 0 ${nethansaPalette.line.dark}`,
    width: '228px',
    p: 2,
    '*:not(.MuiTypography-h5Text)': {
      textAlign: 'center',
    },
    '.MuiTypography-h5Text': {
      color: nethansaPalette.main.gray,
    },
  };

  const chapterTab = (index: number, name: string) => (
    <Stack direction={'row'} alignItems={'center'} width={'100%'} spacing={1}>
      {StepCircleWizard({
        active: index == activeChapter,
        completed: false,
        error: false,
        icon: index + 1,
      })}
      <Typography variant="h5Text">{name}</Typography>
    </Stack>
  );

  return (
    <Stack direction={'column'} spacing={4} sx={{ my: 4, px: 4, mx: 'auto', maxWidth: '1184px' }}>
      <Stack direction={'column'} spacing={2}>
        <Stack direction={'row'}>
          <Typography variant="h2">{t('hello.head')}</Typography>

          <ButtonBase
            disableRipple
            onClick={() => setTutorialVisible(!tutorialVisible)}
            sx={{ flexGrow: 1, justifyContent: 'flex-end' }}
            key="tutorialsVisibilityBtn"
          >
            <Typography
              variant="smallUppercase"
              sx={{ color: nethansaPalette.button.blueMain, fontWeight: '700' }}
            >
              {tutorialVisible ? t('tutorials.hideTutorials') : t('tutorials.showTutorials')}
            </Typography>
          </ButtonBase>
        </Stack>
        <Typography variant="h5Text">{t('hello.description')}</Typography>
      </Stack>

      <Show when={tutorialVisible && tutorialsFetched && tutorials !== undefined}>
        <Stack direction={'row'} width={'100%'} spacing={2}>
          <Box>
            <Tabs
              value={activeTutorial}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                '& .MuiTabs-flexContainer': {
                  alignItems: 'center',
                },
              }}
            >
              <Typography variant="smallUppercase" sx={tabStyle}>
                {t('tutorials.tabsHead')}:
              </Typography>

              {tutorials?.map((tutorial, ti) => {
                return <Tab label={tutorial.name} disableRipple sx={tabStyle} {...a11yProps(ti)} />;
              })}
            </Tabs>
            <Box
              sx={{
                bgcolor: nethansaPalette.main.light,
                border: `1px solid ${nethansaPalette.line.light}`,
                borderRadius: '6px',
                p: 3,
                boxShadow: `0 3px 5px 0 ${nethansaPalette.line.dark}`,
              }}
            >
              {tutorials?.map((tutorial, index) => {
                return (
                  <TabPanel value={activeTutorial} index={index + 1}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: '446px',
                        overflowY: 'auto',
                      }}
                    >
                      <Tabs
                        value={activeChapter}
                        onChange={handleChapterChange}
                        orientation="vertical"
                        sx={{
                          minWidth: '320px',
                          bgcolor: nethansaPalette.background.title,
                          position: 'sticky',
                          top: 0,
                          left: 0,
                          zIndex: 1,
                        }}
                      >
                        {tutorial.chapters?.map((chapter, chapterIndex) => {
                          return (
                            <Tab
                              label={chapterTab(chapterIndex, chapter.title)}
                              disableRipple
                              sx={chapterTabStyle}
                            />
                          );
                        })}
                      </Tabs>

                      {tutorial.chapters?.map((chapter, chapterIndex) => {
                        return (
                          <TabPanel value={activeChapter} index={chapterIndex}>
                            <Box sx={{ px: 3, py: 0, fontSize: '13px', lineHeight: '18px' }}>
                              <div dangerouslySetInnerHTML={{ __html: chapter.content }} />
                            </Box>
                          </TabPanel>
                        );
                      })}
                    </Box>
                  </TabPanel>
                );
              })}
            </Box>
          </Box>

          <Stack direction={'column'} spacing={2} sx={{ pt: 6 }}>
            {/* <Stack direction={'column'} spacing={2} sx={boxStyle}>
              <Typography variant="h3Bold">{t('tutorials.boxes.introduction.title')}</Typography>
              <Typography variant="h5Text">
                {t('tutorials.boxes.introduction.description')}
              </Typography>
              <Box>
                <Button size="small" color="flat">
                  {t('tutorials.boxes.introduction.button.text')}
                </Button>
              </Box>
            </Stack> */}

            <Stack direction={'column'} spacing={2} sx={boxStyle}>
              <Typography variant="h3Bold">{t('tutorials.boxes.knowlegeBase.title')}</Typography>
              <Typography variant="h5Text">
                {t('tutorials.boxes.knowlegeBase.description')}
              </Typography>
              <Box>
                <Button
                  size="small"
                  color="flat"
                  onClick={() =>
                    window.open(t('tutorials.boxes.knowlegeBase.button.href'), '_blank')
                  }
                >
                  {t('tutorials.boxes.knowlegeBase.button.text')}
                </Button>
              </Box>
            </Stack>

            <Stack direction={'column'} spacing={2} sx={boxStyle}>
              <Typography variant="h3Bold">{t('tutorials.boxes.demoSession.title')}</Typography>
              <Typography variant="h5Text">
                {t('tutorials.boxes.demoSession.description')}
              </Typography>
              <Box>
                <Button
                  size="small"
                  color="flat"
                  onClick={() =>
                    window.open(t('tutorials.boxes.demoSession.button.href'), '_blank')
                  }
                >
                  {t('tutorials.boxes.demoSession.button.text')}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Show>
    </Stack>
  );
};
