import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { CarriersShippingCountriesType } from '../types';

export const useCarriersShippingCountriesQuery =
  (): UseQueryResult<CarriersShippingCountriesType> => {
    const { authorized, currentPartnerId } = useAuth();

    return useQuery<CarriersShippingCountriesType>(
      ['settings', 'carriers', 'shipping-countries'],
      async () => {
        const response = await httpClient.get<ResponseAPIType<CarriersShippingCountriesType>>(
          API.partnerCarrier(currentPartnerId).shippingCountries(),
        );
        return response.data.data;
      },
      {
        staleTime: 10000,
        keepPreviousData: false,
        enabled: authorized,
      },
    );
  };
