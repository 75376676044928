import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'common/utils';
import { SelectField } from 'components';
import { SelectMultiCheckboxField } from 'components/input-fields/select-multi-checkbox-field';
import { SharedOfferStateEnum, SharedOfferSubStateEnum } from 'generated/graphql';
import { operators } from 'modules/datagrid/consts';
import { TableOffersContext } from 'modules/offers/components/table-offers/contexts';
import { useCommonTranslations } from 'modules/shared';
import { getPossibleSharedStates, getPossibleSubStates } from 'modules/shared/states';
import { Box, Stack } from '@mui/material';
import { GridFilterInputValueProps, GridFilterItem } from '@mui/x-data-grid-pro';

export declare type SharedStateGridFilterItemValue = {
  subStateGridFilterItem: GridFilterItem;
} & GridFilterItem;

export const SharedStatesInputComponent: React.FC<GridFilterInputValueProps> = (props) => {
  const { tCommon } = useCommonTranslations();
  const channel = useContext(TableOffersContext);

  if (channel === null) throw new Error('Invalid channel!');

  const initialState = (props.item.value as SharedOfferStateEnum) ?? null;
  const initialSubStates =
    (
      (props.item as SharedStateGridFilterItemValue).subStateGridFilterItem as GridFilterItem
    )?.value.map((state: SharedOfferStateEnum) => state as SharedOfferStateEnum) ?? [];
  const [t] = useTranslation('offers');
  const { item, applyValue } = props;
  const [state, setState] = React.useState<SharedOfferStateEnum | null>(initialState);
  const [subStates, setSubStates] = React.useState<SharedOfferSubStateEnum[]>(initialSubStates);
  const statuses = getPossibleSharedStates(channel)
    .map((state) => ({
      children: t(
        `offer-status.main.${
          SharedOfferStateEnum[state.toString() as keyof typeof SharedOfferStateEnum]
        }`,
      ),
      value: SharedOfferStateEnum[state.toString() as keyof typeof SharedOfferStateEnum],
    }))
    .sort(sortBy('children'));

  const selectFieldstyles = {
    '& .MuiInputBase-root': {
      height: '40px',
      width: '121px',
    },
  };

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        height: 40,
        width: '100%',
        gap: 0.5,
        '& > div': { margin: 0 },
      }}
    >
      <SelectField
        options={statuses}
        label={tCommon('value')}
        value={item.value || ''}
        onChange={(value?: string | null) => {
          applyValue({
            ...item,
            value: value || '',
            subStateGridFilterItem: {
              field: 'sharedOfferSubState',
              operator: operators.isAnyOf,
              value: [],
            },
          } as SharedStateGridFilterItemValue);

          setState((value as SharedOfferStateEnum) || null);
          setSubStates([]);
        }}
        sx={selectFieldstyles}
      />
      {state !== null && getPossibleSubStates(channel, state).length > 0 && (
        <SelectMultiCheckboxField
          label={tCommon('sub-value')}
          value={subStates}
          options={getPossibleSubStates(channel, state)
            .map((subState: SharedOfferSubStateEnum) => ({
              value: subState,
              children: t(`offer-status.substatus.${subState as 'ACTIVATING'}`),
            }))
            .sort(sortBy('children'))}
          onChange={(value?: string[] | null) => {
            const subStateGridFilterItem: GridFilterItem = {
              field: 'sharedOfferSubState',
              operator: operators.isAnyOf,
              value: value,
            };

            const gridFilterItem: SharedStateGridFilterItemValue = {
              id: item.id,
              field: item.field,
              operator: item.operator,
              value: item.value,
              subStateGridFilterItem: subStateGridFilterItem,
            };
            applyValue(gridFilterItem);
            setSubStates(value as SharedOfferSubStateEnum[]);
          }}
          sx={selectFieldstyles}
          labelSx={{
            top: '-18px',
          }}
          renderValue={(subStates) => <Box>{tCommon('selected', { count: subStates.length })}</Box>}
        />
      )}
    </Stack>
  );
};
