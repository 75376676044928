import axios from 'axios';
import { environment } from 'environment';
import { getCredentials } from 'modules/authorization';
import { EVENT_USER_UNAUTHORIZED, eventDispatcher } from '../events';

const httpClient = axios.create({
  baseURL: environment.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: environment.ENV !== 'development',
});

if (environment.ENV === 'development') {
  httpClient.interceptors.request.use(async (config) => {
    const credentials = getCredentials();

    config.headers = {
      ...(config.headers || {}),
      Authorization: `Bearer ${credentials.token}`,
    };

    return config;
  });
}

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      eventDispatcher(EVENT_USER_UNAUTHORIZED);
    }
    throw error;
  },
);

export { httpClient };
