import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { ArrowDownIcon, ArrowUpIcon, ArrowsNochangeIcon } from 'components/icons';
import { CurrencyCode } from 'modules/shared';
import { nethansaPalette } from 'theme';
import { Box, Stack } from '@mui/material';

type Props = {
  value: number | null;
  trendValue: number | null;
  currency?: CurrencyCode;
  transKeyPath?: string;
};

const trendPecent = (value: number | null, trendValue: number | null) => {
  if (typeof value !== 'number' || typeof trendValue !== 'number') return null;

  return ((trendValue / value - 1) * 100).toFixed(2);
};

const trendColor = (num: number | null) => {
  if (!num) return nethansaPalette.status.dead.dark;
  if (num > 0) return nethansaPalette.status.active.dark;
  if (num < 0) return nethansaPalette.main.watermelon;
};

export const DatagridCellTrend: React.FC<Props> = (props) => {
  const trend = trendPecent(props.value, props.trendValue);
  const color = trendColor(Number(trend));
  const [t] = useTranslation('monitoring');
  const [tCurrency] = useTranslation('currencies');

  const properTitle = () => {
    const keyStart = 'trend-title-';
    if (Number(trend) > 0) return keyStart + 'lower';
    if (Number(trend) < 0) return keyStart + 'higher';
    else return keyStart + 'same';
  };

  return (
    <Stack sx={{ alignItems: 'flex-end' }}>
      <Box>
        <Show when={!!props.currency}>
          <Box component={'span'} sx={{ color: nethansaPalette.button.grayHover }}>
            {tCurrency(`${props.currency as 'EUR'}.short`)}&nbsp;
          </Box>
        </Show>
        {props.value}
      </Box>
      <Show when={!!trend}>
        <Stack
          sx={{ flexDirection: 'row', alignItems: 'self-end', color: color }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title={t(`${props.transKeyPath}.${properTitle()}` as any, {
            valuePercent: Math.abs(Number(trend)),
            average: props.trendValue?.toFixed(2),
          })}
        >
          <Show when={Number(trend) < 0}>
            <ArrowUpIcon />
            {Math.abs(Number(trend))}
          </Show>
          <Show when={Number(trend) > 0}>
            <ArrowDownIcon />
            {trend}
          </Show>
          <Show when={Number(trend) === 0}>
            <ArrowsNochangeIcon />
            &nbsp;{trend}
          </Show>
          {' %'}
        </Stack>
      </Show>
    </Stack>
  );
};
