import React from 'react';
import { MODULE_VIEWS_BAR_HEIGHT } from 'theme';
import { horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { DragIndicatorIcon } from '../../../icons';
import { Tab } from '../../types';

type Props = Tab & {
  isActive?: boolean;
  dragOverlay?: boolean;
  onClick?: () => void;
};

export const TabItem: React.FC<Props> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: props.id,
    strategy: horizontalListSortingStrategy,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const overlayStyle: SxProps<Theme> = {
    boxShadow: 5,
    opacity: 0.8,
  };

  const backdropStyle: SxProps<Theme> = {
    opacity: 0.2,
  };

  const dragActiveById = active?.id === props.id;

  return (
    <Stack
      onClick={() => {
        props.onClick && props.onClick();
      }}
      direction="row"
      justifyContent="center"
      sx={{
        py: 2.5,
        pl: 3,
        pr: 2.5,
        height: MODULE_VIEWS_BAR_HEIGHT,
        position: 'relative',
        cursor: 'pointer',
        maxWidth: '220px',
        minWidth: '80px',
        backgroundColor: props.isActive ? 'nethansa.background.title' : 'white',
        ...(dragActiveById && props.dragOverlay ? overlayStyle : {}),
        ...(dragActiveById && !props.dragOverlay ? backdropStyle : {}),
        '& button': {
          opacity: 0,
          transition: '0.2s opacity',
        },
        '&:hover': {
          '& button': {
            opacity: 1,
          },
        },
      }}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Button
        variant="text"
        sx={{
          width: '24px',
          height: '24px',
          minWidth: 0,
          cursor: 'move',
          position: 'absolute',
          left: 0,
          top: 18,
          backgroundColor: 'none',
          border: 'none',
          p: 0,
        }}
        {...listeners}
      >
        <DragIndicatorIcon />
      </Button>
      <Typography
        title={props.label}
        variant="h2"
        sx={{
          color: props.isActive ? 'nethansa.main.dark' : 'nethansa.main.gray',
          maxWidth: '162px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.label}
      </Typography>
    </Stack>
  );
};
