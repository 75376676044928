import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Show } from 'components';
import { OffersFBAPage, OffersFBMPage, OffersKauflandPage, OffersLayout } from 'modules/offers';
import { usePlatformsSettingsQuery } from 'modules/settings-sales-platforms/hooks';
import { Box } from '@mui/material';
import { routes } from '../routes';

export const OffersRouter: React.FC = () => {
  const offersRoutes = routes.Offers;

  const platformsQuery = usePlatformsSettingsQuery();
  const activePlatforms = (platformsQuery.data || []).filter((x) => x.isActive);
  const defaultChannelLink =
    activePlatforms.filter((p) => p.platformCode === 'AMZEU').length > 0
      ? offersRoutes.children.OffersFBM.link()
      : activePlatforms.filter((p) => p.platformCode === 'KAUF').length > 0
      ? offersRoutes.children.OffersKaufland.link()
      : '';

  return (
    <Show when={platformsQuery.isFetched} fallback={<Box></Box>}>
      <Routes>
        <Route element={<OffersLayout />}>
          <Route index element={<Navigate to={defaultChannelLink} />} />
          <Route path={offersRoutes.children.OffersFBM.name} element={<OffersFBMPage />} />
          <Route path={offersRoutes.children.OffersFBA.name} element={<OffersFBAPage />} />
          <Route
            path={offersRoutes.children.OffersKaufland.name}
            element={<OffersKauflandPage />}
          />
        </Route>
      </Routes>
    </Show>
  );
};
