import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { FormGroupedViews, MenuListPopup } from './components';

export const MenuListViews: React.FC = () => {
  const [t] = useTranslation('common');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <MenuListPopup
      buttonLabel={t('views.button.all-views-label')}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      onClick={(event) => setAnchorEl(event.currentTarget)}
    >
      <Show when={Boolean(anchorEl)}>
        <FormGroupedViews onClose={() => setAnchorEl(null)} />
      </Show>
    </MenuListPopup>
  );
};
