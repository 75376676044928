import React from 'react';
import { TooltipWrapper } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { CarrierName } from 'modules/shared/types';
import { Box, Button, List, ListItemButton } from '@mui/material';
import { useIntegrationContext } from '../../../../hooks';

type Props = {
  connected?: boolean;
  carrierName: CarrierName;
};

export const ButtonManageCarrier: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const {
    connector: [, setConnector],
    disconnectCarrier: [, setDisconnectCarrier],
  } = useIntegrationContext();

  if (!props.connected) {
    return (
      <Button
        data-testid="connect-button"
        color="flat"
        size="small"
        onClick={() => setConnector(props.carrierName)}
      >
        {tCommon('connect')}
      </Button>
    );
  }

  const manageMenu = (
    <List sx={{ '&>*': { fontSize: '13px', p: 0 } }}>
      <ListItemButton data-testid="edit-button" onClick={() => setConnector(props.carrierName)}>
        {tCommon('edit')}
      </ListItemButton>
      <ListItemButton
        data-testid="disconnect-button"
        onClick={() => setDisconnectCarrier(props.carrierName)}
      >
        {tCommon('disconnect')}
      </ListItemButton>
    </List>
  );

  return (
    <TooltipWrapper
      content={manageMenu}
      placement="right-start"
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
            '& > ul': {
              paddingLeft: 0,
            },
          },
        },
      }}
    >
      <Button data-testid="dots-button" color="flat" size="small" sx={{ minWidth: '47px' }}>
        <Box component="span">...</Box>
      </Button>
    </TooltipWrapper>
  );
};
