import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppGlobalParametersContext } from 'modules/shared/contexts/app-global-parameters.provider';
import { NavigationTabsItem, NavigationTabsLayout } from '../../components/navigation-tabs';
import { routes } from '../../routes';

export const ReportsLayout: React.FC = () => {
  const [t] = useTranslation('reports');
  const showContext = useContext(AppGlobalParametersContext);

  return (
    <NavigationTabsLayout
      title={t('title')}
      isHidden={!showContext.reportsModuleWithBars}
      isNotSticky
    >
      <NavigationTabsItem
        href={routes.Reports.children.SalesRevenue.link()}
        exact
        children={t('tab.sales-revenue')}
      />
      <NavigationTabsItem
        hidden
        href={routes.Reports.children.OrderReturns.link()}
        children={t('tab.order-returns')}
      />
    </NavigationTabsLayout>
  );
};
