import { MarketplaceCode } from 'generated/graphql';

export const marketplaceList = [
  { value: MarketplaceCode.Gb, label: 'amazon.co.uk' },
  { value: MarketplaceCode.De, label: 'amazon.de' },
  { value: MarketplaceCode.Fr, label: 'amazon.fr' },
  { value: MarketplaceCode.It, label: 'amazon.it' },
  { value: MarketplaceCode.Es, label: 'amazon.es' },
  { value: MarketplaceCode.Nl, label: 'amazon.nl' },
  { value: MarketplaceCode.Se, label: 'amazon.se' },
  { value: MarketplaceCode.Pl, label: 'amazon.pl' },
  { value: MarketplaceCode.Be, label: 'amazon.com.be' },
];
