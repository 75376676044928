import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryUpdateProductCostArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

const amazonBulkAction = gql`
  query updateProductCost(
    $partnerId: String!
    $newProductCost: Decimal
    $where: ProductGridDtoFilterInput
  ) {
    updateProductCost(newProductCost: $newProductCost, partnerId: $partnerId, where: $where) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

type InputType = Omit<QueryUpdateProductCostArgs, 'partnerId'>;

export const useBulkProductCostMutation = (): UseMutationResult<Query, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryUpdateProductCostArgs, 'partnerId'>;
    return await graphqlClient.request<QueryUpdateProductCostArgs>(amazonBulkAction, {
      partnerId: currentPartnerId || '0',
      where: data.where,
      newProductCost: data.newProductCost,
    });
  });
};
