import React from 'react';
import { Button, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { GridLogicOperator } from '@mui/x-data-grid';
import {
  gridFilterableColumnDefinitionsSelector,
  GridFilterForm,
  GridFilterItem,
  gridFilterModelSelector,
  GridPanelContent,
  GridPanelFooter,
  GridPanelWrapper,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
  GridColDef,
  GridFilterFormProps,
  GetColumnForNewFilterArgs,
} from '@mui/x-data-grid-pro';
import { Show } from '../../../../components';
import { useCommonTranslations } from '../../../shared';
import { EVENT_DATAGRID_RESET_FILTERS } from '../../events';
import { dispatchDatagridEvent } from '../../events/dispatcher';
import { OFFERS_FILTER_STORAGE_KEY, useFiltersFromStorageUtils } from '../../hooks';

interface GridFilterPanelProps extends Pick<GridFilterFormProps, 'logicOperators' | 'columnsSort'> {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx?: SxProps<Theme>;
  /**
   * Function that returns the next filter item to be picked as default filter.
   * @param {GetColumnForNewFilterArgs} args Currently configured filters and columns.
   * @returns {GridColDef['field']} The field to be used for the next filter or `null` to prevent adding a filter.
   */
  getColumnForNewFilter?: (args: GetColumnForNewFilterArgs) => GridColDef['field'] | null;
  /**
   * Props passed to each filter form.
   */
  filterFormProps?: Pick<
    GridFilterFormProps,
    | 'columnsSort'
    | 'deleteIconProps'
    | 'logicOperatorInputProps'
    | 'operatorInputProps'
    | 'columnInputProps'
    | 'valueInputProps'
    | 'filterColumns'
  >;

  /**
   * If `true`, the `Add filter` button will not be displayed.
   * @default false
   */
  disableAddFilterButton?: boolean;
  /**
   * If `true`, the `Remove all` button will be disabled
   * @default false
   */
  disableRemoveAllButton?: boolean;

  /**
   * @ignore - do not document.
   */
  children?: React.ReactNode;
}

type GridFilterPanelPropsExtended = GridFilterPanelProps & {
  getColumnForNewFilter: (params: {
    currentFilters: GridFilterItem[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: GridColDef<any, any, any>[];
  }) => string;
};

const getGridFilter = (col: GridColDef): GridFilterItem => ({
  field: col.field,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  operator: col.filterOperators![0].value,
  id: Math.round(Math.random() * 1e5),
});

export const DatagridFilterPanel = React.forwardRef<HTMLDivElement, GridFilterPanelPropsExtended>(
  function GridFilterPanel(props, ref) {
    const { tCommon } = useCommonTranslations();

    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
    const filterableColumns = useGridSelector(apiRef, gridFilterableColumnDefinitionsSelector);
    const lastFilterRef = React.useRef<any>(null);
    const clearSelectedItems = () => apiRef.current.setRowSelectionModel([]);
    const {
      logicOperators = [GridLogicOperator.And, GridLogicOperator.Or],
      columnsSort,
      filterFormProps,
      getColumnForNewFilter,
      children,
      sx,
      ...other
    } = props;

    const applyFilter = React.useCallback(
      (item: GridFilterItem) => {
        clearSelectedItems();
        apiRef.current.upsertFilterItem(item);
      },
      [apiRef],
    );

    const applyFilterLinkOperator = React.useCallback(
      (operator: GridLogicOperator) => {
        clearSelectedItems();
        apiRef.current.setFilterLogicOperator(operator);
      },
      [apiRef],
    );

    const getDefaultFilter = React.useCallback((): GridFilterItem | null => {
      let nextColumnWithOperator;

      if (getColumnForNewFilter && typeof getColumnForNewFilter === 'function') {
        // To allow override the column for default (first) filter
        const nextColumnFieldName = getColumnForNewFilter({
          currentFilters: filterModel?.items || [],
          columns: filterableColumns,
        });

        nextColumnWithOperator = filterableColumns.find(
          ({ field }) => field === nextColumnFieldName,
        );
      } else {
        nextColumnWithOperator = filterableColumns.find((colDef) => colDef.filterOperators?.length);
      }

      if (!nextColumnWithOperator) {
        return null;
      }

      return getGridFilter(nextColumnWithOperator);
    }, [filterModel?.items, filterableColumns, getColumnForNewFilter]);

    const getNewFilter = React.useCallback((): GridFilterItem | null => {
      if (getColumnForNewFilter === undefined || typeof getColumnForNewFilter !== 'function') {
        return getDefaultFilter();
      }

      const currentFilters = filterModel.items.length
        ? filterModel.items
        : [getDefaultFilter()].filter(Boolean);

      // If no items are there in filterModel, we have to pass defaultFilter
      const nextColumnFieldName = getColumnForNewFilter({
        currentFilters: currentFilters as GridFilterItem[],
        columns: filterableColumns,
      });

      const nextColumnWithOperator = filterableColumns.find(
        ({ field }) => field === nextColumnFieldName,
      );

      if (!nextColumnWithOperator) {
        return null;
      }

      return getGridFilter(nextColumnWithOperator);
    }, [filterModel.items, filterableColumns, getColumnForNewFilter, getDefaultFilter]);

    const items = React.useMemo<GridFilterItem[]>(() => {
      if (filterModel.items.length) {
        return filterModel.items;
      }

      const defaultFilter = getDefaultFilter();

      return defaultFilter ? [defaultFilter] : [];
    }, [filterModel.items, getDefaultFilter]);

    const hasMultipleFilters = items.length > 1;

    const addNewFilter = () => {
      clearSelectedItems();
      const newFilter = getNewFilter();

      if (!newFilter) {
        return;
      }
      apiRef.current.upsertFilterItems([...items, newFilter]);
    };

    const { getOffersFilter, cleanSessionStorageValue } = useFiltersFromStorageUtils();

    const deleteFilter = React.useCallback(
      (item: GridFilterItem) => {
        clearSelectedItems();

        const shouldCloseFilterPanel = items.length === 1;
        const offersFiltersFromStorage = getOffersFilter();
        const isCurrentItemInStorage: boolean =
          (offersFiltersFromStorage || []).filter((x) => {
            return x.value === item.value && x.field === item.field;
          }).length > 0;

        if (isCurrentItemInStorage) {
          if (offersFiltersFromStorage?.length === 1) {
            cleanSessionStorageValue();
          } else {
            window.sessionStorage.setItem(
              OFFERS_FILTER_STORAGE_KEY,
              JSON.stringify(
                (offersFiltersFromStorage || []).filter((x) => {
                  return !(x.value === item.value && x.field === item.field);
                }),
              ),
            );
          }
        }

        apiRef.current.deleteFilterItem(item);

        if (shouldCloseFilterPanel) {
          apiRef.current.hideFilterPanel();
        }
      },
      [apiRef, items.length],
    );

    const resetFilters = React.useCallback(() => {
      clearSelectedItems();

      if (items.length === 0) return;

      dispatchDatagridEvent(EVENT_DATAGRID_RESET_FILTERS);
      apiRef.current.setFilterModel({ items: [] });
      apiRef.current.hideFilterPanel();
    }, [items.length]);

    React.useEffect(() => {
      if (
        logicOperators.length > 0 &&
        filterModel.logicOperator &&
        !logicOperators.includes(filterModel.logicOperator)
      ) {
        applyFilterLinkOperator(logicOperators[0]);
      }
    }, [logicOperators, applyFilterLinkOperator, filterModel.logicOperator]);

    React.useEffect(() => {
      if (items.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lastFilterRef.current!.focus();
      }
    }, [items.length]);

    return (
      <GridPanelWrapper ref={ref} {...other} sx={sx as SxProps<Theme>}>
        <GridPanelContent>
          {items.map((item, index) => (
            <GridFilterForm
              key={item.id == null ? index : item.id}
              item={item}
              applyFilterChanges={applyFilter}
              deleteFilter={deleteFilter}
              hasMultipleFilters={hasMultipleFilters}
              showMultiFilterOperators={index > 0}
              multiFilterOperator={filterModel.logicOperator}
              disableMultiFilterOperator={index !== 1}
              applyMultiFilterOperatorChanges={applyFilterLinkOperator}
              focusElementRef={index === items.length - 1 ? lastFilterRef : null}
              logicOperators={logicOperators}
              columnsSort={columnsSort}
              {...filterFormProps}
            />
          ))}
        </GridPanelContent>
        <Show when={!rootProps.disableMultipleColumnsFiltering}>
          <GridPanelFooter sx={{ ml: 5.25, justifyContent: 'flex-start', gap: 1 }}>
            <Button onClick={addNewFilter} size="small" color="secondary">
              + {apiRef.current.getLocaleText('filterPanelAddFilter')}
            </Button>
            <Show when={items.length > 0}>
              <Button onClick={resetFilters} size="small" variant="text">
                {tCommon('datagrid.actions.clear-all-filters')}
              </Button>
            </Show>
          </GridPanelFooter>
        </Show>
      </GridPanelWrapper>
    );
  },
);
