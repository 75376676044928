import { TFunction } from 'i18next';
import { PlatformFieldType } from '../types';

export const generalPlatformFields = (t: TFunction): PlatformFieldType[] => {
  return [
    {
      name: 'isAutoPrice',
      type: 'checkbox',
      title: t('form.priceManagement.label'),
      tooltip: t('form.priceManagement.tooltip.__html'),
    },
    {
      name: 'isAutoOffersImport',
      type: 'checkbox',
      title: t('form.automaticDownloadOffers.label'),
      tooltip: t('form.automaticDownloadOffers.tooltip.__html'),
    },
    {
      name: 'isAutomaticCreateAndSearchOffersEnabled',
      type: 'checkbox',
      title: t('form.automaticOffersCreation.label'),
      tooltip: t('form.automaticOffersCreation.tooltip.__html'),
    },
    {
      name: 'wbzFeeProc',
      type: 'number',
      title: t('form.nethansaCommission.label'),
      tooltip: t('form.nethansaCommission.tooltip.__html'),
      disabled: true,
      unit: '%',
      precision: 2,
    },
    {
      name: 'defReturnMarkupProc',
      type: 'number',
      title: t('form.returnsReserve.label'),
      tooltip: t('form.returnsReserve.tooltip.__html'),
      unit: '%',
      precision: 2,
      rules: {
        required: {
          value: true,
          message: 'Field is required',
        },
      },
    },
    {
      name: 'shipCostStore',
      type: 'number',
      title: t('form.shippingCost.label'),
      tooltip: t('form.shippingCost.tooltip.__html'),
      precision: 2,
      baseCurrency: true,
      rules: {
        required: {
          value: true,
          message: 'Field is required',
        },
      },
    },
    // {
    //   name: 'handlingTimeInDays',
    //   type: 'number',
    //   title: t('form.handlingTimeInDays.label'),
    //   tooltip: t('form.handlingTimeInDays.tooltip.__html'),
    //   unit: '',
    //   rules: {
    //     required: {
    //       value: true,
    //       message: 'Field is required',
    //     },
    //   },
    // },
    {
      name: 'shipVariableCost',
      type: 'number',
      title: t('form.additionalCost.label'),
      tooltip: t('form.additionalCost.tooltip.__html'),
      countryCurrency: true,
      precision: 2,
    },
    {
      name: 'minPriceProc',
      type: 'number',
      title: t('form.minimumMarkup.label'),
      tooltip: t('form.minimumMarkup.tooltip.__html'),
      unit: '%',
      precision: 2,
      rules: {
        required: {
          value: true,
          message: 'Field is required',
        },
      },
    },
    {
      name: 'stdPriceProc',
      type: 'number',
      title: t('form.standardMarkup.label'),
      tooltip: t('form.standardMarkup.tooltip.__html'),
      unit: '%',
      precision: 2,
      rules: {
        required: {
          value: true,
          message: 'Field is required',
        },
      },
    },
    {
      name: 'maxPriceProc',
      type: 'number',
      title: t('form.maximumMarkup.label'),
      tooltip: t('form.maximumMarkup.tooltip.__html'),
      unit: '%',
      precision: 2,
    },
    {
      name: 'percentBelowLowestPrice',
      type: 'number',
      title: t('form.differenceFromCompetitionPercentage.label'),
      tooltip: t('form.differenceFromCompetitionPercentage.tooltip.__html'),
      unit: '%',
      precision: 2,
      rules: {
        required: {
          value: true,
          message: 'Field is required',
        },
      },
    },
    {
      name: 'amountBelowLowestPrice',
      type: 'number',
      countryCurrency: true,
      title: t('form.differenceFromCompetition.label'),
      tooltip: t('form.differenceFromCompetition.tooltip.__html'),
      precision: 2,
    },
  ];
};
