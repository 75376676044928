import { StepperClasses, StepperProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const stepperStyles: {
  defaultProps?: Partial<StepperProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof StepperClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      color: nethansaPalette.main.dark,
      '.MuiStepLabel-iconContainer': {
        '.MuiSvgIcon-root': {
          width: '36px',
          height: '36px',
          borderRadius: '50%',
          borderWidth: '3px',
          border: `3px solid ${nethansaPalette.line.light}`,

          circle: {
            boxSizing: 'border-box',
            fill: nethansaPalette.main.light,
          },

          '&.Mui-active': {
            borderColor: nethansaPalette.main.active,
          },

          '&.Mui-completed': {
            border: 'none',
          },

          text: {
            fontSize: '11px',
            fontWeight: 700,
          },
        },
      },
      '.MuiStepLabel-labelContainer': {
        '.MuiStepLabel-label': {
          fontSize: '13px',
          lineHeight: '24px',
          marginTop: '5px',
          '&.Mui-active, &.Mui-completed, &': {
            color: nethansaPalette.main.gray,
          },
        },
      },
      '.MuiStepConnector-root': {
        top: '16px',
        left: 'calc(-50% + 18px)',
        right: 'calc(50% + 18px)',
        '.MuiStepConnector-line': {
          borderColor: nethansaPalette.line.light,
          borderTopWidth: '3px',
        },
        '&.Mui-active, &.Mui-completed': {
          '.MuiStepConnector-line': {
            borderColor: nethansaPalette.main.active,
          },
        },
      },
    },
  },
};
