import { UseQueryResult, useQuery } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import {
  Query,
  OfferGridDtoFilterInput,
  QueryOfferTagsCountsArgs,
  TagCountAnimusOffers,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';

export const getOffersTagCount = gql`
  query offerTagsCounts($partnerId: Int!, $first: Int!, $where: OfferGridDtoFilterInput) {
    offerTagsCounts(partnerId: $partnerId, first: $first, where: $where) {
      totalCount
      nodes {
        tag
        count
      }
    }
  }
`;

export const useOffersTagCounts = (
  filters?: OfferGridDtoFilterInput,
): UseQueryResult<Array<TagCountAnimusOffers>> => {
  const { currentPartnerId } = useAuth();

  return useQuery<Array<TagCountAnimusOffers>>(
    ['products', 'tags', 'count', currentPartnerId],
    async () => {
      const result = (await graphqlClient.request<QueryOfferTagsCountsArgs>(getOffersTagCount, {
        partnerId: Number(currentPartnerId),
        first: 10000,
        where: filters,
      })) as Query;

      return result.offerTagsCounts?.nodes || [];
    },
    {
      staleTime: 0,
      refetchInterval: 0,
      keepPreviousData: false,
      retry: false,
    },
  );
};
