import React from 'react';
import { GlobalStyles as MuiGlobalStyles, useTheme } from '@mui/material';

export const GlobalStyles: React.FC = () => {
  const { palette } = useTheme();

  return (
    <MuiGlobalStyles
      styles={{
        html: {
          overflowY: 'auto',
        },
        a: {
          color: palette.secondary.main,
          textDecoration: 'none',
        },
        // '::-webkit-scrollbar': {
        //   width: '8px',
        //   height: '8px',
        // },
        // '::-webkit-scrollbar-track': {
        //   background: '#fff',
        //   border: '1px solid #dde8f1',
        // },
        // '::-webkit-scrollbar-thumb': {
        //   background: 'nethansa.button.grayLight',
        //   borderRadius: '6px',
        // },
        // '::-webkit-scrollbar-thumb:hover': {
        //   background: '#b2b3c6',
        // },
      }}
    />
  );
};
