import { UseQueryResult } from 'react-query';
import { CarrierName } from 'modules/shared/types';
import { CarrierType } from '../types';
import { useCarriersListQuery } from './use-carriers-list.query';

export const useCarrierData = (
  carrierName: CarrierName,
): UseQueryResult<CarrierType | undefined> => {
  const carriersListQuery = useCarriersListQuery();

  return {
    ...carriersListQuery,
    data: carriersListQuery.data?.find((x) => x.name === carrierName),
  } as UseQueryResult<CarrierType | undefined>;
};
