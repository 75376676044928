import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, FormButtonsGroup, NethansaLogo, Show, TextFieldPassword } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { Alert, Box, Button, Stack, TextField } from '@mui/material';
import { useAuth } from './hooks';

type FormProps = {
  email: string;
  password: string;
};

export const LoginModal: React.FC = () => {
  const [error, setError] = useState('');
  const { tCommon } = useCommonTranslations();
  const { login } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormProps>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const submit = async (data: FormProps) => {
    return login(data, {
      onError: (error) => {
        if (!error) return setError('User is not authorize');
        setError(error.title);
      },
    });
  };

  return (
    <Dialog open={true}>
      <form onSubmit={handleSubmit(submit)}>
        <Box sx={{ p: 4, minWidth: '400px' }}>
          <Stack alignItems="center">
            <NethansaLogo key="splash" sx={{ mb: 5, display: 'flex' }} />
          </Stack>
          <Stack spacing="20px" sx={{ mb: 1 }}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    autoComplete="current-email"
                    disabled={isSubmitting}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={'Login'}
                    placeholder={'Login'}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 2, flex: 1 }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="password"
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextFieldPassword
                    autoComplete="current-password"
                    disabled={isSubmitting}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label={'password'}
                    placeholder={'Password'}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{ mb: 2, flex: 1 }}
                  />
                );
              }}
            />
          </Stack>
          <Show when={!!error}>
            <Alert color="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          </Show>
          <FormButtonsGroup isLoading={isSubmitting}>
            <Button type="submit" size="small" disabled={isSubmitting}>
              {tCommon('confirm')}
            </Button>
          </FormButtonsGroup>
        </Box>
      </form>
    </Dialog>
  );
};
