import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import omitBy from 'lodash.omitby';
import { ErrorMeta, PlatformCode } from 'modules/shared/types';

type InputType = {
  sellerId?: string;
  mwsAuthToken?: string;
  clientKey?: string;
  secretKey?: string;
};

type FieldErrorType = 'required' | 'incorrectFormat';
type TitleErrorType = 'validationErrors';

export const usePlatformConnectMutation = (
  platformCode: PlatformCode,
): UseMutationResult<
  void,
  ErrorMeta<{ sellerId: FieldErrorType[]; mwsAuthToken: FieldErrorType[] }, TitleErrorType>,
  InputType
> => {
  return useMutation(async (body) => {
    const requestTestConnectionBody: Partial<InputType> = omitBy(
      {
        sellerId: body.sellerId,
        mwsAuthToken: body.mwsAuthToken,
        clientKey: body.clientKey,
        secretKey: body.secretKey,
      },
      (val) => !val,
    );

    return await envelope(
      httpClient.post(
        API.wizard().singlePlatformTestConnection(platformCode),
        requestTestConnectionBody,
      ),
    );
  });
};
