import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'theme/components/text-field-phone.styles.css';
import { FormControl, FormHelperText, InputLabel, TextFieldProps } from '@mui/material';
import { Show } from '../show';

type Props = TextFieldProps & {
  onChange: (value: string) => void;
  country?: string | number | undefined;
  regions?: string | string[] | undefined;
  localization?: object;
};

export const TextFieldPhone: React.FC<Props> = (props) => {
  const [t, { language, getDataByLanguage }] = useTranslation('settings');
  const allTranslationsObj = (lang: string) =>
    getDataByLanguage(lang) as unknown as { countries: { [key: string]: string } };

  const transformKeys = (obj: object) => {
    const arrayFromObj = Object.entries(obj);

    return Object.fromEntries(
      arrayFromObj.map(([k, v]) => {
        return [k.toLowerCase(), v];
      }),
    );
  };

  const countriesLang = transformKeys(allTranslationsObj(language).countries);

  const countriesOrder = () => {
    const entries = Object.entries(countriesLang);
    return entries.sort((a, b) => a[1].localeCompare(b[1])).map(([a]) => a);
  };

  return (
    <FormControl>
      <InputLabel
        sx={{
          top: '-22px',
          left: 0,
          fontSize: '12px',
          fontWeight: 700,
          textTransform: 'uppercase',
          letterSpacing: '0.22px',
        }}
      >
        {t('form.phone.labelRequired')}
      </InputLabel>
      <PhoneInput
        key={language}
        country={props.country}
        regions={props.regions}
        localization={countriesLang}
        preserveOrder={countriesOrder()}
        onlyCountries={countriesOrder()}
        countryCodeEditable={false}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value as string}
        onChange={(value) => {
          props.onChange(value || '');
        }}
        onBlur={props.onBlur}
        containerStyle={{
          marginBottom: '16px',
          fontFamily: 'Inter,-apple-system,Roboto,Arial,sans-serif',
          fontWeight: '500',
        }}
        inputStyle={{
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          width: '100%',
          height: '40px',
          font: 'inherit',
          fontSize: '14px',
          borderRadius: '4px',
          padding: '5px 10px 5px 48px',
        }}
        dropdownStyle={{
          fontSize: '13px',
        }}
        containerClass={props.error ? 'phone-error' : ''}
        inputClass={'input--states'}
        buttonClass={'flag-button--states'}
      />
      <Show when={!!props.helperText}>
        <FormHelperText
          error={props.error}
          sx={{
            m: 0,
            position: 'absolute',
            right: 0,
            top: '40px',
            mt: '3px',
          }}
        >
          {props.helperText}
        </FormHelperText>
      </Show>
    </FormControl>
  );
};
