import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { nethansaPalette } from '../../theme';

type Props = {
  sx?: SxProps<Theme>;
};

export const ExternalLinkIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.001 20H6.001C5.47057 20 4.96186 19.7893 4.58679 19.4142C4.21171 19.0391 4.001 18.5304 4.001 18V7C4.001 6.46957 4.21171 5.96086 4.58679 5.58579C4.96186 5.21071 5.47057 5 6.001 5H10.001V7H6.001V18H17.001V14H19.001V18C19.001 18.5304 18.7903 19.0391 18.4152 19.4142C18.0401 19.7893 17.5314 20 17.001 20ZM11.701 13.707L10.291 12.293L16.584 6H13.001V4H20.001V11H18.001V7.415L11.701 13.707Z"
        fill={nethansaPalette.button.blueMain}
      />
    </SvgIcon>
  );
};
