import React from 'react';
import { useTranslation } from 'react-i18next';
import { nethansaPalette } from 'theme';
import { Box, Typography } from '@mui/material';
import { Show } from '../../../../components';

export interface Props {
  offersNumber: number | undefined;
  showWarning: boolean;
}

export const SelectedOffers: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  return (
    <Box
      sx={{
        gap: '40px',
        flexDirection: 'row',
        backgroundColor: nethansaPalette.background.title,
        width: '100%',
        pl: 2,
        py: 1,
        fontSize: '13px',
        lineHeight: '18px',
      }}
    >
      <Typography variant="h5">
        <span>{t('actions.generic-labels.for.prefix')} </span>
        <strong>
          {t('actions.generic-labels.for.offers', {
            count: props.offersNumber ?? 0,
          })}
        </strong>
      </Typography>
      <Show when={props.showWarning}>
        <Typography variant="h5">{t('actions.generic-labels.warning')}</Typography>
      </Show>
    </Box>
  );
};
