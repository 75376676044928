import React from 'react';
import { CardForm, Show } from 'components';
import { Box } from '@mui/material';
import { ClaimDefenderComponent } from '../authorization';
import { SectionShippingCountry, SectionShippingCountrySkeleton } from './components';
import { useCarriersListQuery, useCarriersShippingCountriesQuery } from './hooks';

export const ShippingCountryPage: React.FC = () => {
  const carriersListQuery = useCarriersListQuery();
  const CarriersShippingCountriesQuery = useCarriersShippingCountriesQuery();

  const hasAllData = carriersListQuery.isFetched && CarriersShippingCountriesQuery.isFetched;

  return (
    <ClaimDefenderComponent showInfoBanner allowed={'SETTINGS_CARRIERS_SHIPPING_COUNTRIES_EDIT'}>
      <Box>
        <CardForm>
          <Show when={hasAllData} fallback={<SectionShippingCountrySkeleton />}>
            <SectionShippingCountry />
          </Show>
        </CardForm>
      </Box>
    </ClaimDefenderComponent>
  );
};
