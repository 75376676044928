import { addMinutes, format } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';
import { DateIOFormats } from '@date-io/core/IUtils';

export const dateFormats: DateIOFormats<string> = {
  dayOfMonth: 'd',
  fullDate: 'dd-MM-yyyy',
  fullDateWithWeekday: 'PPPP',
  fullDateTime: 'PPP p',
  fullDateTime12h: 'PPP hh:mm aaa',
  fullDateTime24h: 'PPP HH:mm',
  fullTime: 'p',
  fullTime12h: 'hh:mm aaa',
  fullTime24h: 'HH:mm',
  hours12h: 'hh',
  hours24h: 'HH',
  keyboardDate: 'P',
  keyboardDateTime: 'P p',
  keyboardDateTime12h: 'P hh:mm aaa',
  keyboardDateTime24h: 'P HH:mm',
  minutes: 'mm',
  month: 'LLLL',
  monthAndDate: 'd MMMM',
  monthAndYear: 'LLLL yyyy',
  monthShort: 'MMM',
  weekday: 'EEEE',
  weekdayShort: 'EEE',
  normalDate: 'd MMMM',
  normalDateWithWeekday: 'EEE, d MMMM',
  seconds: 'ss',
  shortDate: 'd MMM',
  year: 'yyyy',
};

export const longDate = (date: string | Date) =>
  date ? format(new Date(date), dateFormats.fullDate) : '';

export const longDateWithMonthDescription = (date: string | Date, language: string) => {
  return date
    ? format(new Date(date), 'dd-MMM-yyyy', { locale: language === 'en' ? enUS : pl })
    : '';
};

export const longTime = (date: string | Date) => (date ? format(new Date(date), 'HH:mm:ss') : '');

export const longDateWithMonthDescriptionAndTime = (date: string | Date, language: string) => {
  return date
    ? format(new Date(date), 'dd-MMM-yyyy HH:mm:ss', { locale: language === 'en' ? enUS : pl })
    : '';
};

export const parseDate = (date: string) => new Date(date);

export const parseDateAndFixIso = (date: Date) => addMinutes(date, date.getTimezoneOffset() * -1);
