import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Stack, TextField } from '@mui/material';
import { DialogBaseWrapper, FormButtonsGroup } from '../../../../components';
import { useCommonTranslations } from '../../../shared';
import { useSnackbar } from '../../../snackbar';
import { useDatagridViewContext } from '../../hooks';
import { useViewRenameMutation } from '../../hooks/api';

type FormProps = {
  newViewName: string;
};

export const ModalRenameView: React.FC = () => {
  const [t] = useTranslation('common');
  const { tCommon } = useCommonTranslations();
  const { renameViewModal } = useDatagridViewContext();
  const viewRenameMutation = useViewRenameMutation();
  const { pushSnackbar } = useSnackbar();

  const { control, handleSubmit, setError } = useForm<FormProps>({
    defaultValues: {
      newViewName: renameViewModal.view?.name || '',
    },
  });

  const submit = (data: FormProps) => {
    viewRenameMutation.mutate(
      {
        viewId: renameViewModal.view?.id || '',
        newName: data.newViewName,
      },
      {
        onSuccess: () => {
          pushSnackbar({
            variant: 'success',
            message: tCommon('views.modal.rename-view.snackbar.rename-success'),
          });
          renameViewModal.change(undefined);
        },
        onError: (error) => {
          setError('newViewName', {
            type: 'server',
            message: t(`views.api-responses.${error.response.data.messageCode as 'ok'}`),
          });
        },
      },
    );
  };

  return (
    <DialogBaseWrapper
      header={tCommon('views.modal.rename-view.title')}
      onClose={() => renameViewModal.change(undefined)}
      open={Boolean(renameViewModal.view)}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Stack sx={{ px: 4, py: 3 }} spacing={2}>
          <Controller
            rules={{
              required: {
                value: true,
                message: tCommon('views.modal.rename-view.validation.blank'),
              },
            }}
            control={control}
            name="newViewName"
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  autoFocus
                  variant="outlined"
                  label={tCommon('views.modal.rename-view.text-input-label')}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </Stack>
        <Divider />
        <FormButtonsGroup sx={{ py: 2, px: 4 }} isLoading={viewRenameMutation.isLoading}>
          <Button
            type="button"
            size="small"
            variant="text"
            disabled={viewRenameMutation.isLoading}
            onClick={() => renameViewModal.change(undefined)}
          >
            {tCommon('cancel')}
          </Button>
          <Button type="submit" size="small" disabled={viewRenameMutation.isLoading}>
            {tCommon('confirm')}
          </Button>
        </FormButtonsGroup>
      </form>
    </DialogBaseWrapper>
  );
};
