import { TabClasses, TabProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const tabStyles: {
  defaultProps?: Partial<TabProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TabClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      padding: '9px 29px',
      '&:first-of-type': {
        paddingLeft: 32,
      },
      '&.Mui-selected': {
        color: '#282828',
        fontWeight: 700,
      },
    },
  },
};
