import React from 'react';
import { Dialog as MuiDialog, DialogProps } from '@mui/material';

type Props = Omit<DialogProps, 'maxWidth' | 'hideBackdrop' | 'transitionDuration'>;

export const Dialog: React.FC<Props> = (props) => {
  return (
    <MuiDialog {...props} maxWidth={false} hideBackdrop={false} transitionDuration={0}>
      {props.children}
    </MuiDialog>
  );
};
