import React, { useState } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CloseButton } from '../close-button';
import { Dialog } from '../dialog';
import { ExpandIcon } from '../icons';

type Props = React.PropsWithChildren<{
  imageSrc: string;
  borderRadius?: number;
}>;

export const ImagePreview: React.FC<Props> = (props) => {
  if (!props.imageSrc) return <>{props.children}</>;

  const [preview, setPreview] = useState(false);

  const borderRadius = props.borderRadius ? `${props.borderRadius}px` : '6px';

  const hoverContentStyles: SxProps<Theme> = {
    backgroundColor: 'rgba(40, 40, 40, 0)',
    borderRadius: borderRadius,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.3s, background-color 0.3s',
    '& > svg': {
      opacity: 0,
    },
    '&:hover': {
      backgroundColor: 'rgba(40, 40, 40, 0.4)',
      '& > svg': {
        opacity: 1,
      },
    },
  };

  return (
    <Box
      data-testid="image-preview-root"
      sx={{
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: borderRadius,
      }}
    >
      <Box
        data-testid="image-preview-container"
        sx={{
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        {props.children}
        <Box
          onClick={(event) => {
            setPreview(true);
            event.stopPropagation();
          }}
          sx={hoverContentStyles}
          data-testid="image-preview-hover-box"
        >
          <ExpandIcon sx={{ fontSize: '40px' }} />
        </Box>
        <Dialog data-testid="image-preview-dialog" open={preview} onClose={() => setPreview(false)}>
          <Stack
            sx={{ minWidth: '500px', p: 2 }}
            direction="row"
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <CloseButton onClick={() => setPreview(false)} />
          </Stack>
          <Box sx={{ p: 4 }} data-testid="image-preview-dialog-content">
            <img alt="image-preview" loading="lazy" src={props.imageSrc} width="100%" />
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};
