import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { PlatformCode } from 'modules/shared/types';
import { PlatformDataWizardType } from '../../types/platform-wizard.type';

type ResponseType = {
  platformCode: 'amzeu' | 'kauf';
  connectionStatus: 'notAttempted' | 'connected' | 'failed';
  sellerId?: string;
  mwsAuthToken?: string;
  clientKey?: string;
  secretKey?: string;
  connectionTimestamp: string | null;
  connectionError: string | null;
}[];

export const useWizardPlatformsQuery = (): UseQueryResult<PlatformDataWizardType[]> => {
  return useQuery<PlatformDataWizardType[]>(
    ['wizard', 'platforms'],
    async () => {
      const response = await httpClient.get<ResponseType>(API.wizard().platforms());
      return response.data.map((x) => ({
        ...x,
        platformCode: String(x.platformCode).toLowerCase() as PlatformCode,
      }));
    },
    {
      staleTime: 5000,
    },
  );
};
