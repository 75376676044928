import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { CarrierType } from '../types';

export const useCarriersListQuery = (): UseQueryResult<CarrierType[]> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<CarrierType[]>(
    ['settings', 'carriers'],
    async () => {
      const response = await httpClient.get<ResponseAPIType<CarrierType[]>>(
        API.partnerCarrier(currentPartnerId).carriers(),
      );
      return response.data.data;
    },
    {
      staleTime: 5000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
