import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AmazonLogo, FormButtonsGroup } from 'components';
import { addMinutes } from 'date-fns';
import { useCommonTranslations } from 'modules/shared';
import { Alert, Box, Button, Typography } from '@mui/material';
import { PlatformsConnectorContext } from '../../contexts/platfroms-modal.provider';
import { useAmazonPlatformCheckQuery } from '../../hooks/use-amazon-platform-check.query';
import { usePlatformQuery } from '../../hooks/use-platform.query';
import { ConnectorHeader } from '../connector-header';

const AMAZON_AUTH_ENDPOINT = '/SpApiAuthorization/AuthorizeFromApplication';

export const AccountExternalAuth: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const platformData = usePlatformQuery('amzeu');
  const context = useContext(PlatformsConnectorContext);
  const [outsideAuthorizing, setOutsideAuthorizing] = useState(false);
  const platformCheckQuery = useAmazonPlatformCheckQuery();

  const onAuthorizeClick = async () => {
    if (!(window && window.open)) return;
    window.open(AMAZON_AUTH_ENDPOINT, '__blank');
    setOutsideAuthorizing(true);

    const endTime = addMinutes(new Date(), 5).getTime();

    checkIfAmazonIsAuthorized(
      endTime,
      () => {
        // Wait until done
        setOutsideAuthorizing(false);
        context.setStep(1);
      },
      () => {
        console.log('ERROR');
      },
    );
  };

  const checkIfAmazonIsAuthorized = async (
    endTime: number,
    done?: () => void,
    timeout?: () => void,
  ) => {
    const response = await platformCheckQuery.refetch();
    if (response.data?.hasConnectedAmazonPlatform) {
      done && done();
      return;
    }

    if (endTime < Date.now()) {
      timeout && timeout();
      return;
    }

    setTimeout(() => {
      checkIfAmazonIsAuthorized(endTime, done, timeout);
    }, 3000);
  };

  return (
    <>
      <Box sx={{ p: 4, pb: 2 }}>
        <ConnectorHeader logo={<AmazonLogo />} stepsCount={2} />
        <Box sx={{ mt: 4 }}>
          <Typography sx={{ mb: 3 }} variant="h2">
            {t('amazon.integrate.title')}
          </Typography>
          <Typography sx={{ mb: 3 }} variant="h5">
            {t('amazon.integrate.description')}
          </Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <span>{t('amazon.subtitle')}</span>
          </Alert>
        </Box>
      </Box>

      <FormButtonsGroup
        isLoading={outsideAuthorizing || platformData.isLoading}
        sx={{ py: 2, px: 4, borderTop: '1px solid #DDE8F1' }}
      >
        <Button
          variant="text"
          size="small"
          onClick={context.close}
          disabled={platformData.isLoading || platformCheckQuery.isLoading || outsideAuthorizing}
        >
          {tCommon('cancel')}
        </Button>
        <Button
          size="small"
          onClick={onAuthorizeClick}
          disabled={platformData.isLoading || platformCheckQuery.isLoading || outsideAuthorizing}
        >
          {t('amazon.authorize')}
        </Button>
      </FormButtonsGroup>
    </>
  );
};
