import packageJson from '../package.json';

type Environment = {
  API_URL: string;
  GRAPHQL_URL: string;
  MUI_LICENSE_KEY: string;
  ADYEN_CLIENT_KEY: string;
  ADYEN_ENV: 'test' | 'live';
  ENV: 'development' | 'production' | 'test';
  VERSION: string;
};

export const environment: Environment = {
  API_URL: window.REACT_APP_API_URL || process.env.REACT_APP_API_URL || '',
  GRAPHQL_URL: window.REACT_APP_GRAPHQL_URL || process.env.REACT_APP_GRAPHQL_URL || '',
  MUI_LICENSE_KEY: process.env.REACT_APP_MUI_LICENSE_KEY || '',
  ADYEN_CLIENT_KEY:
    window.REACT_APP_ADYEN_CLIENT_KEY || process.env.REACT_APP_ADYEN_CLIENT_KEY || '',
  ADYEN_ENV: window.REACT_APP_ENV || process.env.REACT_APP_ENV || '',
  ENV: process.env.NODE_ENV,
  VERSION: packageJson.version,
};
