import { GridLogicOperator } from '@mui/x-data-grid';
import { DatagridContextType } from '../types';

export const gridInitialState: DatagridContextType = {
  rowCount: [0, () => null],
  page: [0, () => null],
  itemsPerPage: [20, () => null],
  sort: [[], () => null],
  filter: [
    {
      items: [],
      logicOperator: GridLogicOperator.And,
    },
    () => null,
  ],
  search: ['', () => null],
  pinnedColumns: [{}, () => null],
  visibleColumns: [{}, () => null],
  visibleOrderedColumns: [[], () => []],
  onGridStateChange: () => null,
  prepareColumns: () => [],
  selectedRows: [[], () => null],
  bulkActionType: ['manually', () => null],
};
