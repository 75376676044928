import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Show } from 'components';
import { AppGlobalParametersContext } from 'modules/shared/contexts/app-global-parameters.provider';
import { Stack } from '@mui/material';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import {
  DatagridContextProvider,
  DatagridViewsContext,
  DatagridViewsContextProvider,
} from '../../contexts';
import { GridTableIds } from '../../enum';
import {
  DATAGRID_RESET_FILTERS,
  datagridEventHandler,
  DatagridEventHandlerResult,
} from '../../events';
import { ToolbarViews } from '../datagrid-views';

type Props = React.PropsWithChildren<{
  tableId: GridTableIds;
  columns: string[];
  topSection?: React.ReactNode;
  initialData?: {
    itemsPerPage?: number;
    forceFiltersValue?: GridFilterItem[];
  };
  events?: {
    resetFilters?: () => void;
  };
}>;

export const DatagridWrapperPage: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const showContext = useContext(AppGlobalParametersContext);
  const currentModule = useLocation().pathname.split('/')[1];
  const showOffersBars = currentModule === 'Offers' && showContext.offersModuleWithBars;
  const showReportsBars = currentModule === 'Reports' && showContext.reportsModuleWithBars;
  const showProductsBars = currentModule === 'Products' && showContext.productsModuleWithBars;
  const showMonitoringBars = currentModule === 'Monitoring' && showContext.monitoringModuleWithBars;

  // Register datagrid filters
  useEffect(() => {
    const eventsHandlers: DatagridEventHandlerResult[] = [
      datagridEventHandler(DATAGRID_RESET_FILTERS, () => {
        if (props.events?.resetFilters) props.events?.resetFilters();
      }),
    ];
    return () => {
      eventsHandlers.forEach((x) => x.clear());
    };
  }, []);

  return (
    <Stack key={i18n.language}>
      <DatagridViewsContextProvider tableId={props.tableId}>
        <DatagridViewsContext.Consumer>
          {(params) => {
            return (
              <DatagridContextProvider
                columns={props.columns}
                key={params.view?.id || '0'}
                itemsPerPage={props.initialData?.itemsPerPage}
                initialForcedFilters={props.initialData?.forceFiltersValue}
              >
                <>
                  <Show
                    when={
                      showOffersBars || showReportsBars || showProductsBars || showMonitoringBars
                    }
                  >
                    {props.topSection}
                    <ToolbarViews />
                  </Show>
                  {props.children}
                </>
              </DatagridContextProvider>
            );
          }}
        </DatagridViewsContext.Consumer>
      </DatagridViewsContextProvider>
    </Stack>
  );
};
