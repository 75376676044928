import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryApproveSuspicousProductCostArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

const amazonBulkAction = gql`
  query approveSuspicousProductCost($partnerId: String!, $where: ProductGridDtoFilterInput) {
    approveSuspicousProductCost(partnerId: $partnerId, where: $where) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

type InputType = Omit<QueryApproveSuspicousProductCostArgs, 'partnerId'>;

export const useBulkApproveSuspicousProductCostMutation = (): UseMutationResult<
  Query,
  ErrorMeta,
  InputType
> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryApproveSuspicousProductCostArgs, 'partnerId'>;
    return await graphqlClient.request<QueryApproveSuspicousProductCostArgs>(amazonBulkAction, {
      partnerId: currentPartnerId || '0',
      where: data.where,
    });
  });
};
