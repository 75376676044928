import React from 'react';
import { nethansaPalette } from 'theme';
import { Stack } from '@mui/material';

type Props = React.PropsWithChildren<unknown>;

export const LeftLineParams: React.FC<Props> = (props) => {
  return (
    <Stack
      spacing={0.5}
      sx={{
        pl: 2,
        borderLeft: `2px solid ${nethansaPalette.line.light}`,
        fontSize: '14px',
        fontWeight: 500,
        '& > *': {
          height: '20px',
          lineHeight: '20px',
        },
      }}
    >
      {props.children}
    </Stack>
  );
};
