import React from 'react';
import { Show, Tooltip } from 'components';
import { Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { CellWithTooltipType } from '../../../../../../types';

type PropsType = {
  cellContent: CellWithTooltipType;
  textWrapperSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
};

export const FirstColumnCell: React.FC<PropsType> = (props) => {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 0.5, ...props.sx }}>
      <Typography
        variant="h5"
        sx={{
          lineHeight: '18px',
          overflowWrap: 'break-word',
          ...(props.textWrapperSx || {}),
        }}
      >
        {props.cellContent.text}
      </Typography>
      <Show when={!!props.cellContent.tooltip}>
        <Tooltip content={props.cellContent.tooltip} />
      </Show>
    </Stack>
  );
};
