import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';
import { UpdateProductVatRatesType } from '../types';
import { useProductVatQuery } from './use-product-vat.query';
import { useProductsCoordinatorContext } from './use-products-coordinator-context';

type InputType = UpdateProductVatRatesType;

export const useProductVatMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();
  const { refetch } = useProductVatQuery();
  const {
    editVat: [data],
  } = useProductsCoordinatorContext();

  return useMutation(
    async (body) => {
      return await envelope(
        httpClient.put(API.products(currentPartnerId).vat(data?.row?.id), body),
      );
    },
    {
      onSuccess: () => {
        refetch();
      },
    },
  );
};
