import React, { useCallback } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormAlert, FormButtonsGroup, TextFieldPassword } from 'components';
import { Button, Stack, TextField } from '@mui/material';
import { useChangePasswordMutation } from '../../hooks';

type FormProps = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const SectionChangePassword: React.FC = () => {
  const [t] = useTranslation('settings');
  const changePasswordMutation = useChangePasswordMutation();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm<FormProps>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const newPasswordValue = useWatch({
    control,
    name: 'newPassword',
  });

  const submit = useCallback((data: FormProps) => {
    changePasswordMutation.mutate(data, {
      onSuccess: () => {
        reset({
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      },
      onError: (error) => {
        Object.entries<string[]>(error.errors).forEach(([key, errors]) => {
          errors.forEach((errorKey) => {
            setError(key as keyof FormProps, {
              type: 'validate',
              message: t(
                `form.${key}.validation.${errorKey}` as 'form.oldPassword.validation.passwordMismatch',
              ),
            });
          });
        });
      },
    });
  }, []);

  const passwordValidator = useCallback((value?: string) => {
    if (!value) return t('form.newPassword.validation.required');
    if (value.length < 8 || value.length >= 30)
      return t('form.newPassword.validation.minMaxLength');
    if (!/(?=.*[0-9])/.test(value)) return t('form.newPassword.validation.digit');
    if (!/(?=.*[a-z])/.test(value)) return t('form.newPassword.validation.lowerCase');
    if (!/(?=.*[A-Z])/.test(value)) return t('form.newPassword.validation.upperCase');
    if (!/(?=.*[!#$%&*+\-.<=>?@_])/.test(value))
      return t('form.newPassword.validation.specialCharacter');
    return;
  }, []);

  const repeatedPasswordValidator = useCallback(
    (value?: string) => {
      if (!value) return t('form.repeatedNewPassword.validation.required');
      if (value !== newPasswordValue)
        return t('form.repeatedNewPassword.validation.retypedPassword');
      return;
    },
    [newPasswordValue],
  );

  const disable = isSubmitting || changePasswordMutation.isLoading;

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack gap="30px" sx={{ mt: 2.5 }}>
        {/* --- Empty value for username and autosuggestions --- */}
        <TextField sx={{ display: 'none' }} autoComplete="username" name="username" value="" />
        {/* --- */}

        <Controller
          control={control}
          name="oldPassword"
          rules={{
            required: {
              value: true,
              message: t('form.oldPassword.validation.required'),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextFieldPassword
                autoComplete="current-pas-change"
                disabled={disable}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.oldPassword.label')}
                placeholder={t('form.oldPassword.placeholder')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="newPassword"
          rules={{
            validate: passwordValidator,
          }}
          render={({ field, fieldState }) => {
            return (
              <TextFieldPassword
                autoComplete="new-password"
                disabled={disable}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.newPassword.label')}
                tooltipContent={t('form.newPassword.tooltip.__html')}
                placeholder={t('form.newPassword.placeholder')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="confirmPassword"
          rules={{ validate: repeatedPasswordValidator }}
          render={({ field, fieldState }) => {
            return (
              <TextFieldPassword
                autoComplete="new-password"
                disabled={disable}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.repeatedNewPassword.label')}
                placeholder={t('form.repeatedNewPassword.placeholder')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
      </Stack>
      <FormAlert
        show={changePasswordMutation.isSuccess}
        isSuccess={changePasswordMutation.isSuccess}
        isError={false}
        successMessage={t('change-password.success')}
      />
      <FormButtonsGroup sx={{ mt: 1 }} isLoading={disable}>
        <Button size="small" type="submit" disabled={disable}>
          {t('form.actions.change')}
        </Button>
      </FormButtonsGroup>
    </form>
  );
};
