import React, { useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  GridState,
  selectedGridRowsCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { SelectField, SplitButton, SplitButtonOption } from '../../../../../../components';
import { useCommonTranslations } from '../../../../../shared';
import { useDatagridContext } from '../../../../hooks';
import { BulkActionsProps, BulkActionType } from '../../../../types';

type Props<T extends string = string> = BulkActionsProps<T> & {
  entitiesType?: 'offers' | 'products';
};

export const DatagridFooterBulkActions: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const { bulkActionType } = useDatagridContext();
  const [actionType, setActionType] = useState<string | undefined>();

  const {
    rowCount: [datagridRowCount],
  } = useDatagridContext();
  const apiRef = useGridApiContext();
  const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector);
  const selectedRowIds = useGridSelector(apiRef, (state: GridState) => state.rowSelection);

  const actions = useMemo(
    () =>
      (props.bulkActions || [])
        .filter((a) => a.visible !== false)
        .map((x) => ({
          value: x.value,
          children: x.label,
        })),
    [props.bulkActions],
  );

  const selectType = useMemo(
    () => [
      { value: 'manually', children: tCommon('datagrid.footer.manually-selected') },
      { value: 'all', children: tCommon('datagrid.footer.on-all-pages') },
    ],
    [],
  );

  const selectedCount = useMemo(
    () => (bulkActionType[0] === 'all' ? datagridRowCount : selectedRowCount),
    [bulkActionType[0], datagridRowCount, selectedRowCount],
  );

  const onApplyClick = () => {
    if (!actionType || !props.handleBulkAction) return;
    props?.handleBulkAction(
      actionType,
      bulkActionType[0] === 'all' ? undefined : [...selectedRowIds],
    );
  };

  const onClearClick = () => {
    apiRef.current.setRowSelectionModel([]);
    bulkActionType[1]('manually');
  };

  const onPreviewClick = () => {
    console.log('PREVIEW');
  };

  const hasPreviewOption = false; //bulkActionType[0] !== 'all'; // tymczasowe rozwiązanie do czasu naprawienia managera dla zadania #12831

  const splitButtonOptions: SplitButtonOption[] = [
    { label: tCommon('datagrid.footer.apply'), onClick: onApplyClick, disabled: !actionType },
    { label: tCommon('datagrid.footer.clear'), onClick: onClearClick },
    ...(hasPreviewOption
      ? [{ label: tCommon('datagrid.footer.preview'), onClick: onPreviewClick, disabled: true }]
      : []),
  ];

  return (
    <Stack ml={3} mr={1} my={1} gap={1} direction="row" alignItems="center" flexWrap="wrap">
      <Typography variant="h3">{tCommon('datagrid.footer.bulk-actions')}</Typography>
      <SelectField
        darkMode
        value={actionType || ''}
        onChange={(value) => setActionType(value)}
        onBlur={(value) => setActionType(value)}
        placeholder={tCommon('datagrid.footer.select-action')}
        sx={{ flex: 1, width: '192px', m: 0, p: 0 }}
        options={actions}
      />
      <Typography variant="h3">
        {tCommon('datagrid.footer.for')}{' '}
        {props.entitiesType
          ? tCommon(`datagrid.footer.entities.${props.entitiesType as 'offers'}`, {
              count: selectedCount,
            })
          : tCommon('datagrid.footer.entities.generals', { count: selectedCount })}
      </Typography>
      <SelectField
        darkMode
        value={bulkActionType[0] || ''}
        onChange={(value) => bulkActionType[1](value as BulkActionType)}
        onBlur={(value) => bulkActionType[1](value as BulkActionType)}
        sx={{ flex: 1, width: '192px', m: 0, p: 0 }}
        options={selectType}
      />
      <Typography variant="h3">
        ({selectedCount}{' '}
        {props.entitiesType
          ? tCommon(`datagrid.footer.entities.${(props.entitiesType || '') as 'offers'}`, {
              count: selectedCount,
            })
          : tCommon('datagrid.footer.entities.generals', { count: selectedCount })}
        )
      </Typography>
      <SplitButton color="flat" options={splitButtonOptions} />
    </Stack>
  );
};
