import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, Dialog, Show } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useAdyenPaymentMethodsQuery } from 'modules/settings-billing-data/hooks';
import { SubscriptionType } from 'modules/settings-billing-data/types';
import { useProfileDataQuery } from 'modules/settings-user-profile/hooks';
import { Box, DialogContent, Stack, Typography } from '@mui/material';
import { AdyenCheckoutContainer } from './adyen-checkout-container';

type Props = React.PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  title?: string;
  loading?: boolean;
  subscription?: SubscriptionType;
}>;

export const PaymentCardModal: React.FC<Props> = (props) => {
  const { data, isFetched } = useAdyenPaymentMethodsQuery();
  const userIdQuery = useProfileDataQuery();
  const [t] = useTranslation('settings');

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      sx={{ '&  .MuiDialogContent-root': { minWidth: 0, maxWidth: '496px' } }}
    >
      <Stack
        sx={{ p: 4, pr: 3 }}
        direction="row"
        justifyContent={'space-between'}
        alignItems={'flex-start'}
      >
        <Typography variant="h1">{props.title}</Typography>
        {props.onClose && <CloseButton onClick={() => props.onClose()} />}
      </Stack>
      <DialogContent>
        <Box
          sx={{
            p: 4,
            pt: 0,
            minWidth: '300px',
            minHeight: '300px',
            width: 'fit-content',
            margin: 'auto',
            position: 'relative',
          }}
        >
          <Typography variant="h5" sx={{ lineHeight: '18px', mb: 2 }}>
            {t('payments.credit-card.description-modal')}
          </Typography>
          <Show
            when={isFetched && userIdQuery.isFetched}
            fallback={
              <SpinnerIcon
                sx={{
                  width: '100px',
                  height: 'auto',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%)',
                }}
              />
            }
          >
            <AdyenCheckoutContainer
              paymentMethods={data}
              subscriptionName={props.subscription?.name}
              subscriptionPrice={props.subscription?.price}
              userId={userIdQuery.data?.userId}
            />
          </Show>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
