import React, { useState } from 'react';
import { CarrierName } from 'modules/shared/types';
import { IntegrationContextType } from '../types';

export const IntegrationContext = React.createContext<IntegrationContextType>({});

type Props = React.PropsWithChildren<unknown>;

export const IntegrationContextProvider: React.FC<Props> = (props) => {
  const connector = useState<CarrierName | undefined>();
  const disconnectCarrier = useState<CarrierName | undefined>();

  const value: IntegrationContextType = {
    connector,
    disconnectCarrier,
  };

  return <IntegrationContext.Provider value={value}>{props.children}</IntegrationContext.Provider>;
};
