import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlatformChannelCode } from 'modules/shared/types';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { OfferGridDtoFilterInput } from '../../../../generated/graphql';
import {
  DatagridFooter,
  mapSelectedOfferIdsToFilter,
  useDatagridContext,
  useDatagridFiltersToGraphqlTranslator,
} from '../../../datagrid';
import { BulkAction, BulkActionsProps } from '../../../datagrid/types';
import {
  useLabelsSendToUtils,
  useOfferCoordinatorContext,
  useOffersColumnsDefinition,
  useOffersTotalCountQuery,
} from '../../hooks';
import { searchableColumnsOffers } from '../../hooks/use-offers.query';
import { OfferGridColumn, PlatformCodeEnum, SelectedOffers } from '../../types';

type Action = 'sendTo' | 'priceSettings' | 'analyze' | 'delete' | 'editTags';

type Props = {
  channelCode: PlatformChannelCode;
};

export const TableOffersFooterBulkActions: React.FC<Props> = (props) => {
  const { t } = useTranslation('offers');
  const apiRef = useGridApiContext();
  const { bulkActionType, filter, search, rowCount } = useDatagridContext();
  const columns = useOffersColumnsDefinition();
  const sendToLabel = useLabelsSendToUtils(props.channelCode);

  const offersTotalCountQuery = useOffersTotalCountQuery(
    'offers-total-all-count',
    columns,
    { items: [] },
    props.channelCode || 'AMZFBA',
  );

  const platformCode =
    props.channelCode === 'DEFAULT' ? PlatformCodeEnum.KAUF : PlatformCodeEnum.AMZEU;

  const {
    priceSettings: [, setPriceSettings],
    sendOffers: [, setSendOffers],
    analyzeOffers: [, setAnalyzeOffers],
    deleteOffers: [, setDeleteOffers],
    editTags: [, setEditTags],
  } = useOfferCoordinatorContext();

  const handleActionSuccess = () => {
    apiRef.current.setRowSelectionModel([]);
    bulkActionType[1]('manually');
  };

  const getBulkActions = (): BulkAction<Action>[] => {
    const actions: BulkAction<Action>[] = [
      { label: sendToLabel, value: 'sendTo' },
      { label: t('actions.price-settings'), value: 'priceSettings' },
      { label: t('actions.analyze'), value: 'analyze' },
      { label: t('actions.delete'), value: 'delete' },
    ];

    if (platformCode == PlatformCodeEnum.AMZEU)
      actions.push({ label: t('actions.edit-tags'), value: 'editTags' });

    return actions;
  };

  const bulkActionProps: BulkActionsProps<Action> = useMemo(
    () => ({
      bulkActions: getBulkActions(),
      handleBulkAction: (actionType, selectedIds) => {
        const offerIds = (selectedIds || []) as string[];

        let selectedCount = offerIds.length;
        if (bulkActionType[0] === 'all') selectedCount = rowCount[0];

        const gridFilters =
          bulkActionType[0] !== 'all'
            ? mapSelectedOfferIdsToFilter<OfferGridDtoFilterInput>(offerIds)
            : useDatagridFiltersToGraphqlTranslator<OfferGridColumn, OfferGridDtoFilterInput>(
                filter[0],
                columns,
                searchableColumnsOffers,
                props.channelCode,
                search[0],
              );

        const selectedOffers: SelectedOffers = {
          filters: gridFilters.filter,
          selectedCount: selectedCount,
          onSuccess: handleActionSuccess,
        };

        const obj: Record<Action, () => void> = {
          priceSettings: () => {
            setPriceSettings({
              selectedOfferIds: offerIds,
              channelDefined: props.channelCode,
              filters: filter[0],
              searchValue: search[0],
              onSuccess: handleActionSuccess,
              selectedCount: selectedCount,
            });
          },
          analyze: () => {
            setAnalyzeOffers({
              selectedOfferIds: offerIds,
              platformCode: platformCode,
              channelDefined: props.channelCode,
              filters: filter[0],
              searchValue: search[0],
              onSuccess: handleActionSuccess,
              selectedCount: selectedCount,
            });
          },
          delete: () => {
            setDeleteOffers({
              selectedOfferIds: offerIds,
              platformCode: platformCode,
              channelDefined: props.channelCode,
              filters: filter[0],
              searchValue: search[0],
              onSuccess: handleActionSuccess,
              selectedCount: selectedCount,
            });
          },
          sendTo: () => {
            setSendOffers({
              selectedOfferIds: offerIds,
              platformCode: platformCode,
              channelDefined: props.channelCode,
              filters: filter[0],
              searchValue: search[0],
              selectedCount: selectedCount,
              onSuccess: handleActionSuccess,
            });
          },
          editTags: () => {
            setEditTags(selectedOffers);
          },
        };
        obj[actionType]();
      },
    }),
    [handleActionSuccess],
  );
  return (
    <DatagridFooter
      entitiesType="offers"
      totalAllCount={offersTotalCountQuery.data}
      {...(bulkActionProps as BulkActionsProps)}
    />
  );
};
