import { useTranslation } from 'react-i18next';
import {
  InputMaybe,
  ProductGridDtoFilterInput,
  ProductGridDtoSortInput,
  SortEnumType,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useDatagridContext, useDatagridContextGraphqlTranslator } from 'modules/datagrid';
import { useExportDatagridData } from 'modules/shared';
import { FileType } from 'modules/shared/types';
import { useAuth } from '../../authorization';
import { useGlobalSettingsQuery } from '../../settings-sales-global';
import { ProductGridRowKey, ProductsGridColumn } from '../types';
import { searchableColumnsProducts } from './use-products.query';

export const getProductsToExport = (columns: string[]) => {
  const parsedColumns = columns.map((x) => `${x}\n`);
  return gql`
    query Products(
      $partnerId: String!
      $first: Int
      $after: String
      $sort: [ProductGridDtoSortInput!]
      $filter: ProductGridDtoFilterInput
    ) {
      products(partnerId: $partnerId, first: $first, after: $after, where: $filter, order: $sort) {
        totalCount
        nodes {
          ...nodes
        }
        pageInfo {
          ...paginationMetaAnimus
        }
        edges {
          cursor
        }
      }
    }

    fragment nodes on ProductGridDto {
      ${parsedColumns}
    }

    fragment paginationMetaAnimus on PageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  `;
};

type Result = {
  mutate: (fileType: FileType, columnsForImport: boolean) => Promise<void>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

export const useProductsExport = (columns: ProductsGridColumn[]): Result => {
  const { currentPartnerId } = useAuth();
  const { visibleOrderedColumns } = useDatagridContext();
  const { data } = useGlobalSettingsQuery();
  const [, i18n] = useTranslation();

  const columnsNotToExport: string[] = ['__check__', 'id'];

  const productColumnsToExport = (arr: string[]) => {
    return arr.filter((x) => !columnsNotToExport.includes(x));
  };

  const visibleColumns = productColumnsToExport([...visibleOrderedColumns[0]]);
  const columnsForImport = [
    'externalId',
    'ean',
    'name',
    'category',
    'stock',
    'taxRate',
    'productCost',
    'handlingTime',
  ];
  const gridFilterSort = useDatagridContextGraphqlTranslator<
    ProductsGridColumn,
    ProductGridRowKey,
    ProductGridDtoFilterInput,
    ProductGridDtoSortInput
  >(columns, searchableColumnsProducts);

  const exportDatagridData = useExportDatagridData('products');

  return {
    ...exportDatagridData,
    mutate: async (fileType, isImport) => {
      const parsedSort: InputMaybe<ProductGridDtoSortInput> = gridFilterSort.sort ?? {
        name: SortEnumType.Desc,
      };

      const viewQuery = {
        query: getProductsToExport(visibleColumns),
        variables: {
          partnerId: currentPartnerId,
          first: gridFilterSort.first,
          after: gridFilterSort.after,
          sort: parsedSort,
          filter: gridFilterSort.filter,
        },
      };

      return exportDatagridData.mutate({
        columns: isImport ? columnsForImport : visibleColumns,
        language: i18n.language,
        fileType: fileType,
        viewQuery: JSON.stringify(viewQuery),
        partnerBaseCurrency: data?.baseCurrency || 'EUR',
      });
    },
  };
};
