import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabsHorizontalDraggable } from 'components';
import { MODULE_VIEWS_BAR_HEIGHT } from 'theme';
import { Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useDatagridViewsManager } from '../../hooks';
import { MenuListViews } from './components';

export const ToolbarViews: React.FC = () => {
  const [t] = useTranslation('common');
  const { visibleViews, currentView, changeView, reorderViews } = useDatagridViewsManager();

  const rootStyle: SxProps<Theme> = {
    height: MODULE_VIEWS_BAR_HEIGHT,
    backgroundColor: 'common.white',
  };

  const tabsParsed: Tab[] = useMemo(
    () =>
      visibleViews.map((x) => ({
        id: String(x.id),
        label: x.name,
      })),
    [visibleViews],
  );

  const onOrderChange = useCallback(
    (reorderedTabs: Tab[]) => {
      reorderViews(reorderedTabs);
    },
    [reorderViews],
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={rootStyle}>
      <Stack direction="row" alignItems="center" sx={{ maxWidth: 'calc(100% - 210px)' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ width: '70px' }}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 700,
              fontSize: '12px',
              color: 'nethansa.button.grayHover',
            }}
          >
            {t('views.list.header')}
          </Typography>
        </Stack>
        <TabsHorizontalDraggable
          sx={{ maxWidth: 'calc(100% - 70px)' }}
          tabs={tabsParsed}
          activeTabId={currentView?.id || 0}
          onItemClick={(id) => {
            changeView({ id: String(id) });
          }}
          onOrderChange={onOrderChange}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ width: '210px', pr: 3 }}
      >
        <MenuListViews />
      </Stack>
    </Stack>
  );
};
