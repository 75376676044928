import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const WarningIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      // width="24"
      // height="17"
      viewBox="0 0 24 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 17H21.6842L11.8421 0L2 17ZM5.10474 15.2105L11.8421 3.57L18.5795 15.2105H5.10474ZM10.9474 12.5263H12.7368V14.3158H10.9474V12.5263ZM10.9474 7.15789H12.7368V10.7368H10.9474V7.15789Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
