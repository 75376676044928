import React from 'react';
import { Stack } from '@mui/material';
import { FlagIcon, Show } from '../../../../components';

type Props = {
  value: string;
  label?: string;
  href?: string;
};

export const DatagridCellFlag: React.FC<Props> = (props) => {
  return (
    <Stack
      direction="row"
      gap={0.75}
      alignItems="center"
      sx={{ '& span': { textTransform: 'lowercase' } }}
    >
      <FlagIcon country={props.value ? props.value.slice(-2).toUpperCase() : ''} />
      <Show when={!!props.href} fallback={<span>{props.label || props.value}</span>}>
        <a href={props.href} target="_blank">
          {props.label || props.value}
        </a>
      </Show>
    </Stack>
  );
};
