import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ExportIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28698 8.29886C7.89975 8.69265 7.90507 9.3258 8.29886 9.71302C8.69265 10.1003 9.32579 10.0949 9.71302 9.70114L11.0035 8.38878L11 13.9994C10.9997 14.5517 11.4471 14.9997 11.9994 15C12.5517 15.0003 12.9997 14.5529 13 14.0006L13.0035 8.43888L14.2988 9.71293C14.6925 10.1002 15.3256 10.095 15.7129 9.70124C16.1002 9.3075 16.095 8.67436 15.7012 8.28707L12.8757 5.50789C12.7041 5.20483 12.3788 5.00023 12.0056 5C11.9962 4.99999 11.9868 5.00012 11.9774 5.00037C11.9655 5.00005 11.9536 4.99994 11.9417 5.00003C11.6764 5.00225 11.4229 5.10974 11.237 5.29886L8.28698 8.29886ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16H7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
