import { UseQueryResult, useQuery } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import {
  Query,
  MonitoredOfferGridDtoFilterInput,
  TagCount,
  QueryMonitoredOfferTagsCountsArgs,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';

export const getMonitoredOffersTagCount = gql`
  query monitoredOfferTagsCounts(
    $partnerId: String!
    $first: Int!
    $where: MonitoredOfferGridDtoFilterInput
  ) {
    monitoredOfferTagsCounts(partnerId: $partnerId, first: $first, where: $where) {
      totalCount
      nodes {
        tag
        count
      }
    }
  }
`;

export const useMonitoredOffersTagCounts = (
  filters?: MonitoredOfferGridDtoFilterInput,
): UseQueryResult<Array<TagCount>> => {
  const { currentPartnerId } = useAuth();

  return useQuery<Array<TagCount>>(
    ['monitored-offer', 'tags', 'count', currentPartnerId],
    async () => {
      const result = (await graphqlClient.request<QueryMonitoredOfferTagsCountsArgs>(
        getMonitoredOffersTagCount,
        {
          partnerId: currentPartnerId || '0',
          first: 10000,
          where: filters,
        },
      )) as Query;

      return result.monitoredOfferTagsCounts?.nodes || [];
    },
    {
      staleTime: 0,
      refetchInterval: 0,
      keepPreviousData: false,
      retry: false,
    },
  );
};
