import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogBaseWrapper, Show } from 'components';
import { Stack } from '@mui/material';
import { queryClient } from '../../../../common/query-client';
import { SpinnerIcon } from '../../../../components/icons';
import { useOfferCoordinatorContext, usePriceSettingsQuery } from '../../hooks';
import { PriceSettingsForm } from './components';

export const PriceSettingsModal: React.FC = () => {
  const [t] = useTranslation('offers');
  const { isFetched, isLoading, isError } = usePriceSettingsQuery();

  const {
    priceSettings: [actionParams, setPriceSettings],
  } = useOfferCoordinatorContext();

  const handleClose = () => {
    queryClient.refetchQueries({ active: true, queryKey: 'offers' });
    actionParams?.onSuccess && actionParams.onSuccess();
    setPriceSettings(undefined);
  };

  const renderForm = isFetched && !isError;

  return (
    <DialogBaseWrapper
      open
      onClose={handleClose}
      disableCloseOnBackdropClick
      header={t('modals.price-settings.title')}
    >
      <Show when={renderForm}>
        <PriceSettingsForm />
      </Show>
      <Show when={isLoading}>
        <Stack sx={{ height: '100px' }} alignItems="center" justifyContent="center">
          <SpinnerIcon sx={{ fontSize: '48px' }} />
        </Stack>
      </Show>
      <Show when={isError}>
        <Stack sx={{ height: '100px' }} alignItems="center" justifyContent="center">
          <h5>{t('modals.price-settings.error.general')}</h5>
        </Stack>
      </Show>
    </DialogBaseWrapper>
  );
};
