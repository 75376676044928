import { useQuery, UseQueryResult } from 'react-query';
import {
  ChannelCode,
  OfferFilterValuesQueryVariables,
  OfferFilterValuesResponse,
  PlatformCode,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';
import { graphqlClient } from '../../../common/graphql/graphql-client';

type Props = {
  platformCode: PlatformCode;
  channelCode: ChannelCode;
};

const fallbackData: OfferFilterValuesResponse = {
  competitivePowerValues: [],
  marketplaceCodes: [],
};

const query = gql`
  query OfferFilterValues(
    $partnerId: Int!
    $channelCode: ChannelCode!
    $platformCode: PlatformCode!
  ) {
    offerFilterValues(
      partnerId: $partnerId
      channelCode: $channelCode
      platformCode: $platformCode
    ) {
      competitivePowerValues
      marketplaceCodes
    }
  }
`;

export const useOffersFiltersValuesByPlatformQuery = (
  props: Props,
): UseQueryResult<OfferFilterValuesResponse> => {
  const { authorized, currentPartnerId } = useAuth();
  return useQuery(
    ['sales', 'filters-values', props.platformCode, props.channelCode],
    async () => {
      const response = await graphqlClient.request<OfferFilterValuesQueryVariables>(query, {
        partnerId: Number(currentPartnerId || '0'),
        platformCode: props.platformCode,
        channelCode: props.channelCode,
      });

      return response?.offerFilterValues || fallbackData;
    },
    {
      staleTime: 600000,
      enabled: Boolean(authorized && props.channelCode),
      placeholderData: fallbackData,
    },
  );
};
