import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

type InputType = { carrierToDeleteId: string; newDefaultCarrierId?: number };

export const useCarriersDisconnectMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation([...settingsDefaultMutationKeys], async (data) => {
    return await envelope(
      httpClient.put(
        API.partnerCarrier(currentPartnerId).singleCarrierDeactivate(data.carrierToDeleteId),
        {
          newDefaultCarrierId: data.newDefaultCarrierId,
        },
      ),
    );
  });
};
