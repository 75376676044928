import React, { useState } from 'react';
import { StateSetterValue } from '../../shared/types';
import { SelectedProducts } from '../types';

type ProductsModalCoordinatorContextType = {
  editHandlingTime: StateSetterValue<SelectedProducts | undefined>;
  editProductCost: StateSetterValue<SelectedProducts | undefined>;
  editVat: StateSetterValue<SelectedProducts | undefined>;
  editTags: StateSetterValue<SelectedProducts | undefined>;
  createOffers: StateSetterValue<SelectedProducts | undefined>;
  deleteProduct: StateSetterValue<SelectedProducts | undefined>;
  acceptSuspectedCost: StateSetterValue<SelectedProducts | undefined>;
  shoppingBasket: StateSetterValue<SelectedProducts | undefined>;
};

export const ProductsModalCoordinatorContext =
  React.createContext<ProductsModalCoordinatorContextType>({
    editHandlingTime: [undefined, () => null],
    editProductCost: [undefined, () => null],
    editVat: [undefined, () => null],
    editTags: [undefined, () => null],
    createOffers: [undefined, () => null],
    deleteProduct: [undefined, () => null],
    acceptSuspectedCost: [undefined, () => null],
    shoppingBasket: [undefined, () => null],
  });

type Props = React.PropsWithChildren;

export const ProductsModalsCoordinatorProvider: React.FC<Props> = (props) => {
  const editHandlingTime = useState<SelectedProducts | undefined>(undefined);
  const editProductCost = useState<SelectedProducts | undefined>(undefined);
  const editVat = useState<SelectedProducts | undefined>(undefined);
  const editTags = useState<SelectedProducts | undefined>(undefined);
  const createOffers = useState<SelectedProducts | undefined>(undefined);
  const deleteProduct = useState<SelectedProducts | undefined>(undefined);
  const acceptSuspectedCost = useState<SelectedProducts | undefined>(undefined);
  const shoppingBasket = useState<SelectedProducts | undefined>(undefined);

  return (
    <ProductsModalCoordinatorContext.Provider
      value={{
        editHandlingTime,
        editProductCost,
        editVat,
        editTags,
        createOffers,
        deleteProduct,
        acceptSuspectedCost,
        shoppingBasket,
      }}
    >
      {props.children}
    </ProductsModalCoordinatorContext.Provider>
  );
};
