import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ImageMissingIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      data-testid="image-missing-icon"
      sx={props.sx}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2450_6171)">
        <path
          d="M16.5001 31.5C24.7843 31.5 31.5001 24.7843 31.5001 16.5C31.5001 8.21573 24.7843 1.5 16.5001 1.5C8.21579 1.5 1.50006 8.21573 1.50006 16.5C1.50006 24.7843 8.21579 31.5 16.5001 31.5Z"
          stroke="#DDE8F1"
          strokeWidth="2"
          fill="none"
        />
        <path d="M5.50006 5.5L27.5001 27.5" stroke="#DDE8F1" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_2450_6171">
          <rect width="32" height="32" fill="white" transform="translate(0.500061 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
