import { SharedMonitoredOfferState } from 'generated/graphql';
import { StatusColorGroup } from 'modules/offers/types';

export const getMonitoringStatusColorGroup = (
  status: SharedMonitoredOfferState,
): StatusColorGroup => {
  const s = SharedMonitoredOfferState;

  if (status === s.Active) return 'active';
  if (status === s.Searching) return 'temp';
  return 'dead';
};
