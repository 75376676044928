import React, { ReactElement, useState } from 'react';
import { Show } from 'components';
import { ChevronIcon } from 'components/icons';
import { nethansaPalette } from 'theme';
import { ButtonBase, Stack, Typography } from '@mui/material';

export interface Props {
  title: string;
  content: string | ReactElement;
}

export const ActionDetails: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack>
      <ButtonBase
        disableRipple
        onClick={() => setOpen(!open)}
        sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
      >
        <Typography variant="smallUppercase" sx={{ color: nethansaPalette.button.blueMain }}>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: '6px',
              pl: 2,
            }}
          >
            {props.title}

            <ChevronIcon
              sx={{
                width: '10px',
                height: '10px',
                transform: open ? 'rotate(0.75turn)' : 'rotate(0.5turn)',
                color: nethansaPalette.button.blueMain,
              }}
            />
          </Stack>
        </Typography>
      </ButtonBase>

      <Show when={open}>
        <Typography
          variant="body2"
          sx={{
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '18px',
            paddingInline: 2,
            paddingBlock: 1,
            background: nethansaPalette.background.title,
          }}
        >
          {props.content}
        </Typography>
      </Show>
    </Stack>
  );
};
