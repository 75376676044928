import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { useCommonTranslations } from '../../../../../../../../shared';

type Props = {
  name: string;
};

export const BooleanReadonlyTextCell: React.FC<Props> = (props) => {
  const { control } = useFormContext();
  const { tCommon } = useCommonTranslations();

  const booleanValue: boolean | null = useWatch({
    name: props.name,
    control,
  });

  const renderText = useMemo(() => (booleanValue ? tCommon('yes') : tCommon('no')), [booleanValue]);

  return (
    <Box sx={{ textAlign: 'center', width: '100%' }}>
      <Typography variant="h5">{renderText}</Typography>
    </Box>
  );
};
