import React from 'react';
import { FieldNamesMarkedBoolean, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Fade, Stack, Typography } from '@mui/material';
import { FormButtonsGroup } from '../../../../../../components';
import { useCommonTranslations } from '../../../../../shared';
import { PlatformInput } from '../../../../types';

type Props = {
  isLoading: boolean;
  onReset: () => void;
};

const countDirtyChanges = (values: FieldNamesMarkedBoolean<PlatformInput>): number => {
  const stockDifferencesCount = values.isAutoStock ? 1 : 0;
  const marketplacesDifferencesCount = (values.marketplaces || [])
    .filter((x) => !!x)
    .map((x) => Object.keys(x).length)
    .reduce((acc, v) => acc + v, 0);
  return stockDifferencesCount + marketplacesDifferencesCount;
};

export const SectionActionButtons: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const { formState } = useFormContext<PlatformInput>();

  const unsavedChangesCount = countDirtyChanges(formState.dirtyFields);

  return (
    <>
      <Box sx={{ mt: 2, pl: 2 }}>
        <Typography variant="h5">{t('form.field-is-required')}</Typography>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          py: 2,
          pr: 3,
          position: 'sticky',
          bottom: 0,
          left: '3rem',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          zIndex: 3,
          width: 'min(100%, 100vw - 205px - 4rem)',
        }}
      >
        <Fade in={formState.isDirty && !props.isLoading}>
          <Alert severity="warning" sx={{ position: 'absolute', top: '-50px' }}>
            {t('unsaved-changes', { count: unsavedChangesCount })}
          </Alert>
        </Fade>
        <FormButtonsGroup isLoading={props.isLoading}>
          <Button size="small" variant="text" disabled={props.isLoading} onClick={props.onReset}>
            {tCommon('cancel')}
          </Button>
          <Button type="submit" size="small" disabled={props.isLoading}>
            {tCommon('save')}
          </Button>
        </FormButtonsGroup>
      </Stack>
    </>
  );
};
