import React from 'react';
import { Divider, Paper, Skeleton, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const SectionGlobalSettingsSkeleton: React.FC = () => {
  const itemStyles: SxProps<Theme> = { '& > *': { flex: 1 } };

  return (
    <Paper sx={{ width: '100%', maxWidth: '448px', mt: 3, p: 3 }}>
      <Stack spacing={3} divider={<Divider />}>
        <Stack direction="row" sx={itemStyles}>
          <Skeleton height="24px" />
        </Stack>
        <Stack direction="row" sx={itemStyles}>
          <Skeleton height="24px" />
        </Stack>
      </Stack>
    </Paper>
  );
};
