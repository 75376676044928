import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { StoredSnapshot } from '../types';
import { useOfferCoordinatorContext } from './use-offer-coordinator-context';

export const usePriceHistoryQuery = (): UseQueryResult<StoredSnapshot[]> => {
  const { authorized, currentPartnerId } = useAuth();
  const {
    offerDetails: [data],
  } = useOfferCoordinatorContext();

  return useQuery<StoredSnapshot[]>(
    [],
    async () => {
      if (!data?.offerId) return [];

      const response = await httpClient.get<ResponseAPIType<StoredSnapshot[]>>(
        API.offers(currentPartnerId).priceHistory(encodeURIComponent(data?.offerId)),
      );
      return response.data.data.map((x) => {
        x.createdAt = x.createdAt + 'Z';
        return x;
      });
    },
    {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: false,
      enabled: authorized,
      retry: 0,
    },
  );
};
