import { useQuery, UseQueryResult } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import {
  InputMaybe,
  OfferGridDtoFilterInput,
  OffersQuery,
  OffersQueryVariables,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';
import { useDatagridFiltersToGraphqlTranslator } from 'modules/datagrid';
import { PlatformChannelCode } from 'modules/shared/types';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { OfferGridColumn } from '../types';
import { searchableColumnsOffers } from './use-offers.query';

export const getOffersTotalCount = gql`
  query OffersTotalCount($partnerId: Int!, $first: Int!, $filter: OfferGridDtoFilterInput) {
    offers(partnerId: $partnerId, first: $first, where: $filter) {
      totalCount
    }
  }
`;

export const useOffersTotalCountQuery = (
  groupName: string,
  columnsUsedInFilters: OfferGridColumn[],
  filters: GridFilterModel,
  channel?: PlatformChannelCode,
): UseQueryResult<number> => {
  const { currentPartnerId } = useAuth();

  const gridFilterSort = useDatagridFiltersToGraphqlTranslator<
    OfferGridColumn,
    OfferGridDtoFilterInput
  >(filters, columnsUsedInFilters, searchableColumnsOffers, channel);

  return useQuery<number>(
    ['offers', 'total-count', groupName, channel, currentPartnerId, gridFilterSort.filter],
    async () => {
      const channelDefined = channel
        ? {
            or: [
              {
                channelCode: {
                  eq: channel,
                },
              },
            ],
          }
        : null;

      const parsedFilter: InputMaybe<OfferGridDtoFilterInput> = {
        ...gridFilterSort.filter,
        ...channelDefined,
      };

      const result = (await graphqlClient.request<OffersQueryVariables>(getOffersTotalCount, {
        partnerId: Number(currentPartnerId),
        first: 0,
        ...(Object.keys(parsedFilter || {}).length ? { filter: parsedFilter } : {}),
      })) as OffersQuery;

      return result?.offers?.totalCount || 0;
    },
    {
      staleTime: 60000,
      refetchInterval: 180000,
      keepPreviousData: false,
      retry: false,
    },
  );
};
