import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogBaseWrapper, FileField, Show } from 'components';
import { ExcelIcon, FileUploadIcon, SpinnerIcon } from 'components/icons';
import { ImportFileError, useProductsImportMutation } from 'modules/products/hooks';
import { useCommonTranslations } from 'modules/shared/hooks';
import { ResponseAPIType } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Link,
  Stack,
  Typography,
} from '@mui/material';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type ImportProductsFormProps = {
  fileToUpload?: File;
};

export const ImportProductsModal: React.FC<Props> = (props) => {
  const [t] = useTranslation('products');
  const { tCommon } = useCommonTranslations();
  const productImportMutation = useProductsImportMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [inputError, setInputError] = useState<string | null>(null);
  const MAX_FILESIZE_IN_MB = 10;
  const SUPPORTED_FILES = ['xls', 'xlsx'];

  const { control, clearErrors, getValues, handleSubmit, reset } = useForm<ImportProductsFormProps>(
    {
      defaultValues: {
        fileToUpload: undefined,
      },
    },
  );

  const submit = () => {
    const fileValue = getValues('fileToUpload');

    if (!fileValue) return;

    productImportMutation.mutate(
      { file: fileValue },
      {
        onSuccess: () => {
          setErrorMessage(null);
        },

        onError: (error) => {
          if (error.response) {
            const data = error.response.data as ResponseAPIType;
            const errorCode = data.error?.errors.code[0] as ImportFileError;
            setErrorMessage(t(`modals.import.validation.${errorCode}`));
          }
        },
      },
    );
  };

  const handleFileValidation = () => {
    const file = getValues('fileToUpload');

    if (!file) return;

    setInputError(null);

    const sizeInMB = Number((file.size / (1024 * 1024)).toFixed(2));
    const fileExtension = file.name.split('.').pop() || 'unknown';

    if (sizeInMB > MAX_FILESIZE_IN_MB) {
      setInputError(t('modals.import.validation.too-big-file'));
    }

    if (!SUPPORTED_FILES.includes(fileExtension)) {
      setInputError(t('modals.import.validation.IncorrectExtension'));
    }
    setErrorMessage(null);
  };

  const handleClose = () => {
    reset();
    productImportMutation.reset();
    setErrorMessage(null);
    setInputError(null);
    props.onClose();
  };

  return (
    <DialogBaseWrapper
      header={t('modals.import.header')}
      open={props.isOpen}
      onClose={handleClose}
      disableCloseOnBackdropClick
    >
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent
          sx={{
            mx: 4,
            mb: 2,
            minWidth: '576px !important',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Stack
            component={'section'}
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: nethansaPalette.background.title,
              p: 2,
            }}
          >
            <Box sx={{ width: '75%' }}>
              <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                <ExcelIcon />
                <Typography variant="h3" sx={{ fontWeight: 700 }}>
                  {t('modals.import.section1.title')}
                </Typography>
              </Stack>
              <Typography
                variant="h5"
                sx={{
                  lineHeight: '18px',
                  color: nethansaPalette.main.gray,
                  mt: 1,
                  width: 0,
                  minWidth: '100%',
                }}
              >
                {t('modals.import.section1.desc')}
              </Typography>
            </Box>
            <Link href={t('modals.import.template-url')} target="_blank" rel="noreferrer">
              <Button size="small" color="flat" data-testid="button-download-import-template">
                {t('modals.buttons.download')}
              </Button>
            </Link>
          </Stack>

          <Stack component={'section'} sx={{ bgcolor: nethansaPalette.background.title, p: 2 }}>
            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <FileUploadIcon />
              <Typography variant="h3" sx={{ fontWeight: 700 }}>
                {t('modals.import.section2.title')}
              </Typography>
            </Stack>
            <Typography
              variant="h5"
              sx={{
                lineHeight: '18px',
                whiteSpace: 'pre-line',
                color: nethansaPalette.main.gray,
                mt: 1,
                width: 0,
                minWidth: '100%',
              }}
            >
              {t('modals.import.section2.desc', { maxSize: MAX_FILESIZE_IN_MB })}
            </Typography>
            <Controller
              control={control}
              name="fileToUpload"
              rules={{
                required: {
                  value: true,
                  message: t('modals.import.validation.no-file'),
                },
              }}
              render={({ field }) => {
                return (
                  <FileField
                    isFlat
                    onChange={(file) => {
                      field.onChange(file);
                      field.onBlur();

                      if (!file) return;

                      clearErrors();
                      productImportMutation.reset();
                      handleFileValidation();
                    }}
                    disabled={productImportMutation.isLoading || productImportMutation.isSuccess}
                    error={!!inputError}
                    helperText={inputError || ''}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    buttonLabel={tCommon('choose')}
                    value={field.value}
                  />
                );
              }}
            />
          </Stack>
          <Show when={productImportMutation.isLoading}>
            <Alert
              severity="info"
              icon={<SpinnerIcon sx={{ height: 42, width: 'auto' }} />}
              sx={{
                '&.MuiAlert-standardInfo': { bgcolor: nethansaPalette.background.titleDark },
                '& .MuiAlert-icon': { p: 0 },
              }}
            >
              {t('modals.import.processing')}
            </Alert>
          </Show>
          <Show when={!!errorMessage}>
            <Alert severity="error">{errorMessage || t(`modals.import.error-general`)}</Alert>
          </Show>
          <Show when={productImportMutation.isSuccess}>
            <Alert severity="success" sx={{ width: 0, minWidth: '100%' }}>
              {t('modals.import.success')}
            </Alert>
          </Show>
        </DialogContent>

        <DialogActions sx={{ px: 4, py: 2, borderTop: `1px solid ${nethansaPalette.line.light}` }}>
          <Show
            when={!productImportMutation.isSuccess}
            fallback={
              <Button type="button" size="small" onClick={handleClose}>
                OK
              </Button>
            }
          >
            <Button type="button" size="small" variant="text" onClick={handleClose}>
              {tCommon('cancel')}
            </Button>
            <Button
              type="submit"
              size="small"
              disabled={
                productImportMutation.isLoading || !!inputError || productImportMutation.isError
              }
            >
              {t('modals.buttons.import')}
            </Button>
          </Show>
        </DialogActions>
      </form>
    </DialogBaseWrapper>
  );
};
