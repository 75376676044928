import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppGlobalParametersContext } from 'modules/shared/contexts/app-global-parameters.provider';
import { NavigationTabsLayout } from '../../components/navigation-tabs';

export const ProductsLayout: React.FC = () => {
  const [t] = useTranslation('products');

  const showContext = useContext(AppGlobalParametersContext);

  return (
    <NavigationTabsLayout
      title={t('title')}
      isHidden={!showContext.productsModuleWithBars}
      isNotSticky
    ></NavigationTabsLayout>
  );
};
