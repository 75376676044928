import { endOfDay, isValid, startOfDay } from 'date-fns';
import { InputMaybe } from 'generated/graphql';
import { SharedStateGridFilterItemValue } from 'modules/datagrid/components/datagrid-operators/shared-states-input-component';
import { GridLogicOperator } from '@mui/x-data-grid';
import { GridColDef, GridFilterItem } from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { parseDate, parseDateAndFixIso } from '../../../../common/format/date';
import { DatagridListOperatorEnum, listOfListOperators, operators } from '../../consts';
import { DateEnumOperatorType, getDateRanges } from '../get-date-ranges';
import { getColumnType } from './get-column-type';
import { parseFilterValue } from './parse-filter-value';

export function reduceArrayOfFilters<TColumn extends GridColDef>(
  items: GridFilterItem[],
  columns: TColumn[],
  linkOperator: GridLogicOperator,
): InputMaybe<any> | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterArrayValue = items.reduce<Array<any>>((acc, x) => {
    const type = getColumnType(x.field, columns);

    if (x.operator == operators.sharedStatuses) {
      if (x.value == null) return acc;

      const subStates = (x as SharedStateGridFilterItemValue)?.subStateGridFilterItem?.value as
        | string[]
        | null;

      if (subStates != null && subStates.length)
        acc.push({
          ['and']: [
            { [x.field]: { [operators.equals]: x.value } },
            {
              [(x as SharedStateGridFilterItemValue).subStateGridFilterItem.field]: {
                [operators.isAnyOf]: subStates,
              },
            },
          ],
        });
      else
        acc.push({
          [x.field]: {
            [operators.equals]: x.value,
          },
        });

      return acc;
    }

    // Handle enum value
    if (type === 'singleSelect' && !x.value && x.value !== 0) {
      if (x.operator === 'isEmpty' || x.operator === 'isNotEmpty') {
        const baseOperator = x.operator === 'isEmpty' ? 'or' : 'and';
        const operator = x.operator === 'isEmpty' ? 'eq' : 'neq';
        acc.push({
          [baseOperator]: [{ [x.field]: { [operator]: '' } }, { [x.field]: { [operator]: null } }],
        });
        return acc;
      }

      if (x.operator === 'isNull' || x.operator === 'isNotNull') {
        const baseOperator = x.operator === 'isNull' ? 'or' : 'and';
        const operator = x.operator === 'isNull' ? 'eq' : 'neq';
        acc.push({
          [baseOperator]: [{ [x.field]: { [operator]: null } }],
        });
        return acc;
      }

      if (x.operator === 'is' && x.value === undefined) {
        acc.push({ ['and']: [{ [x.field]: { ['eq']: null } }] });
        return acc;
      }

      if (
        x.operator !== DatagridListOperatorEnum.EMPTY_LIST &&
        x.operator !== DatagridListOperatorEnum.NOT_EMPTY_LIST
      ) {
        return acc;
      }
    }

    // Handle string nullable or empty string value
    if (type === 'boolean' && x.value !== 'true' && x.value !== 'false') {
      return acc;
    }

    //Handle multi select list operators
    if (
      (type === 'string' || type === 'singleSelect') &&
      listOfListOperators.some((y) => y === x.operator)
    ) {
      const operator = x.operator as DatagridListOperatorEnum;

      if (operator === 'emptyList') {
        acc.push({
          and: [{ [x.field]: { any: false } }],
        });
      }
      if (operator === 'notEmptyList') {
        acc.push({
          and: [{ [x.field]: { any: true } }],
        });
      }
      if (operator === 'listContains' && !!x.value?.length) {
        acc.push({
          and: [{ [x.field]: { some: { in: x.value } } }],
        });
      }
      if (operator === 'listNotContains' && !!x.value?.length) {
        acc.push({
          or: [{ [x.field]: { none: { in: x.value } } }, { [x.field]: { any: false } }],
        });
      }
      return acc;
    }

    if (type === 'string' && (x.operator === 'isEmpty' || x.operator === 'isNotEmpty')) {
      const baseOperator = x.operator === 'isEmpty' ? 'or' : 'and';
      const operator = x.operator === 'isEmpty' ? 'eq' : 'neq';
      acc.push({
        [baseOperator]: [{ [x.field]: { [operator]: '' } }, { [x.field]: { [operator]: null } }],
      });
      return acc;
    }

    // Handle single day date value
    if (type === 'date' && (x.operator === 'is' || x.operator === 'not')) {
      if (!x.value) return acc;

      const parsedDate = parseDate(x.value);

      const parsedDates = [
        isValid(parsedDate) ? parseDateAndFixIso(startOfDay(parsedDate)) : undefined,
        isValid(parsedDate) ? parseDateAndFixIso(endOfDay(parsedDate)) : undefined,
      ];

      if (x.operator === 'not') {
        acc.push({
          or: [
            {
              [x.field]: { [operators['<']]: parsedDates[0] },
            },
            {
              [x.field]: { [operators['>']]: parsedDates[1] },
            },
          ],
        });
        return acc;
      }

      acc.push({
        [x.field]: {
          [operators['>=']]: parsedDates[0],
          [operators['<=']]: parsedDates[1],
        },
      });
      return acc;
    }

    // Handle single date enum value
    if (type === 'date' && x.operator === 'period') {
      if (!x.value) return acc;

      const typedValue: DateEnumOperatorType = x.value;

      if (typedValue.includes('last')) {
        acc.push({
          [x.field]: {
            [operators['>=']]: getDateRanges()[typedValue][0],
            [operators['<=']]: getDateRanges()[typedValue][1],
          },
        });
        return acc;
      }
      acc.push({
        [x.field]: {
          [operators['>=']]: getDateRanges()[typedValue][0],
        },
      });
      return acc;
    }

    // Handle between two dates
    if (type === 'date' && x.operator === 'between') {
      if (!x.value) return acc;
      const typedValue: DateRange<Date> = x.value;
      acc.push({
        [x.field]: {
          [operators['>=']]: parseDateAndFixIso(startOfDay(typedValue[0] || new Date())),
          [operators['<=']]: parseDateAndFixIso(endOfDay(typedValue[1] || new Date())),
        },
      });
      return acc;
    }

    if (type === 'date' && (x.operator === 'after' || x.operator === 'onOrAfter')) {
      if (!x.value) return acc;

      const date = parseDate(x.value);

      if (x.operator === 'after') {
        acc.push({
          [x.field]: {
            [operators['>']]: parseDateAndFixIso(endOfDay(date || new Date())),
          },
        });
        return acc;
      }

      if (x.operator === 'onOrAfter') {
        acc.push({
          [x.field]: {
            [operators['>=']]: parseDateAndFixIso(startOfDay(date || new Date())),
          },
        });
        return acc;
      }
    }

    if (type === 'date' && (x.operator === 'before' || x.operator === 'onOrBefore')) {
      if (!x.value) return acc;

      const date = parseDate(x.value);

      if (x.operator === 'before') {
        acc.push({
          [x.field]: {
            [operators['<']]: parseDateAndFixIso(startOfDay(date || new Date())),
          },
        });
        return acc;
      }
      if (x.operator === 'onOrBefore') {
        acc.push({
          [x.field]: {
            [operators['<=']]: parseDateAndFixIso(endOfDay(date || new Date())),
          },
        });
        return acc;
      }
    }

    if (type === 'date' && x.operator === 'last') {
      if (!x.value) return acc;
    }

    if (type === 'number') {
      if (x.operator === 'isEmpty' || x.operator === 'isNotEmpty') {
        const operator = x.operator === 'isEmpty' ? 'eq' : 'neq';
        acc.push({ [x.field]: { [operator]: null } });
        return acc;
      }

      if (!x.value) return acc;

      if (x.operator === 'between') {
        const typedValue: [number | undefined, number | undefined] = x.value;

        acc.push({
          [x.field]: {
            [operators['>=']]: typedValue[0],
            [operators['<=']]: typedValue[1],
          },
        });
        return acc;
      }
    }

    // Handle rest values
    acc.push({
      [x.field]: {
        [operators[x.operator as keyof typeof operators]]: parseFilterValue(
          x.value,
          x.field,
          type,
          x.operator as keyof typeof operators,
        ),
      },
    });

    return acc;
  }, []);

  return (items || []).length > 0 && filterArrayValue.length > 0
    ? {
        [linkOperator]: filterArrayValue,
      }
    : undefined;
}
