import { UseQueryResult, useQuery } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import {
  InputMaybe,
  ProductGridDtoFilterInput,
  ProductsQuery,
  ProductsQueryVariables,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';
import { useDatagridFiltersToGraphqlTranslator } from 'modules/datagrid';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { ProductsGridColumn } from '../types';
import { searchableColumnsProducts } from './use-products.query';

export const getProductsTotalCount = gql`
  query ProductsTotalCount($partnerId: String!, $first: Int!, $filter: ProductGridDtoFilterInput) {
    products(partnerId: $partnerId, first: $first, where: $filter) {
      totalCount
    }
  }
`;

export const useProductsTotalCountQuery = (
  groupName: string,
  columnsUsedInFilters: ProductsGridColumn[],
  filters: GridFilterModel,
): UseQueryResult<number> => {
  const { currentPartnerId } = useAuth();

  const gridFilterSort = useDatagridFiltersToGraphqlTranslator<
    ProductsGridColumn,
    ProductGridDtoFilterInput
  >(filters, columnsUsedInFilters, searchableColumnsProducts);

  return useQuery<number>(
    ['products', 'total-count', groupName, currentPartnerId, gridFilterSort.filter],
    async () => {
      const parsedFilter: InputMaybe<ProductGridDtoFilterInput> = {
        ...gridFilterSort.filter,
      };

      const result = (await graphqlClient.request<ProductsQueryVariables>(getProductsTotalCount, {
        partnerId: String(currentPartnerId),
        first: 0,
        ...(Object.keys(parsedFilter || {}).length ? { filter: parsedFilter } : {}),
      })) as ProductsQuery;

      return result?.products?.totalCount || 0;
    },
    {
      staleTime: 60000,
      refetchInterval: 180000,
      keepPreviousData: false,
      retry: false,
    },
  );
};
