import { useMutation, UseMutationResult } from 'react-query';
import { ErrorMeta } from 'modules/shared/types';
import { AnimusActionResponse } from 'modules/shared/types/animus-action-response';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { OfferAction } from '../types/offer-action';

export const usePauseOrActivateOfferMutation = (): UseMutationResult<
  AnimusActionResponse,
  ErrorMeta,
  { offerId: string; action: OfferAction }
> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (data) => {
    return (
      await httpClient.post<AnimusActionResponse>(
        API.offers(currentPartnerId).pauseOrActivateOffer(data.offerId, data.action),
      )
    ).data;
  });
};
