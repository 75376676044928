import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from 'common/api';

type InputType = {
  platformCode: 'amzeu' | 'kauf';
};

export const useWizardPlatformDeleteMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.delete(API.wizard().singlePlatform(body.platformCode));
    return;
  });
};
