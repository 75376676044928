import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardForm, Checkbox, FormAlert, FormButtonsGroup } from 'components';
import { ExternalLinkIcon } from 'components/icons';
import { ClaimDefenderComponent } from 'modules/authorization';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, Divider } from '@mui/material';
import { useVatRatesMutation } from '../../hooks/use-vat-rates.mutation';
import { useVatRatesQuery } from '../../hooks/use-vat-rates.query';
import { TaxRateInputType } from '../../types/vat-rate.type';
import { TableVatRates } from './components';

export type VATRatesFormProps = TaxRateInputType;

export const SectionVatRates: React.FC = () => {
  const [t] = useTranslation('settings');
  const { tCommon } = useCommonTranslations();

  const vatRatesQuery = useVatRatesQuery();
  const vatRatesMutation = useVatRatesMutation();

  const { control, handleSubmit, reset } = useForm<VATRatesFormProps>({
    defaultValues: {
      isVatOss: vatRatesQuery.data?.isVatOss || false,
      taxRates: vatRatesQuery.data?.taxRates.map((x) => ({
        countryCode: x.countryCode,
        id: x.id,
        isActive: x.isActive,
        taxRate: x.taxRate,
      })),
    },
  });

  const submit = useCallback((data: VATRatesFormProps) => {
    vatRatesMutation.mutate(data, {
      onSuccess: () => {
        vatRatesQuery.refetch();
        reset({ ...data });
      },
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box sx={{ pl: 2, pb: 0, pt: 2 }}>
        <Controller
          control={control}
          name="isVatOss"
          render={({ field }) => {
            return (
              <Checkbox
                value={field.value}
                checked={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={
                  <Box component="span">
                    {t('form.haveOptedUnionOSS.label')}
                    <Box component="span" sx={{ '& > *': { textDecoration: 'underline' } }}>
                      <a href={t('form.haveOptedUnionOSS.url')} target="_blank" rel="noreferrer">
                        {t('form.haveOptedUnionOSS.labelCTA')}{' '}
                        <ExternalLinkIcon
                          sx={{
                            fontSize: '22px',
                            marginBottom: '-6px',
                          }}
                        />
                      </a>
                    </Box>
                  </Box>
                }
              />
            );
          }}
        />
      </Box>
      <CardForm fullWidth>
        <TableVatRates control={control} />
        <ClaimDefenderComponent hidden allowed={'SETTINGS_SALES_INTELLIGENCE_VAT_RATES_EDIT'}>
          <Divider sx={{ backgroundColor: 'nethansa.line.light', mt: 2, mb: 1 }} />
          <FormAlert
            show={vatRatesMutation.isSuccess || vatRatesMutation.isError}
            isSuccess={vatRatesMutation.isSuccess}
            isError={vatRatesMutation.isError}
            successMessage={t('vat-rates.success')}
            errorMessage={t('vat-rates.errors.general')}
          />
          <FormButtonsGroup sx={{ mt: 1 }} isLoading={vatRatesMutation.isLoading}>
            <Button size="small" type="submit" disabled={vatRatesMutation.isLoading}>
              {tCommon('save')}
            </Button>
          </FormButtonsGroup>
        </ClaimDefenderComponent>
      </CardForm>
    </form>
  );
};
