import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { DialogBaseWrapper, FormButtonsGroup } from '../../../../components';
import { useCommonTranslations } from '../../../shared';
import { useSnackbar } from '../../../snackbar';
import { useDatagridViewContext } from '../../hooks';
import { useViewShareMutation } from '../../hooks/api';

type FormProps = {
  viewId: string;
  isShared: boolean;
};

const radioButtonLabelStyle: SxProps<Theme> = {
  height: '30px',
  '&.MuiFormControlLabel-root .MuiTypography-root': {
    ml: 1,
  },
};

export const ModalManageSharingView: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const { manageSharingViewModal } = useDatagridViewContext();
  const viewShareMutation = useViewShareMutation();
  const { pushSnackbar } = useSnackbar();

  const { control, handleSubmit } = useForm<FormProps>({
    defaultValues: {
      viewId: String(manageSharingViewModal.view?.id) || '',
      isShared: manageSharingViewModal.view?.isShared || false,
    },
  });

  const submit = (data: FormProps) => {
    viewShareMutation.mutate(
      {
        id: data.viewId || '',
        share: data.isShared,
      },
      {
        onSuccess: () => {
          pushSnackbar({
            variant: 'success',
            message: tCommon('views.modal.share-view.snackbar.share-success'),
          });
          manageSharingViewModal.change(undefined);
        },
      },
    );
  };

  return (
    <DialogBaseWrapper
      header={tCommon('views.modal.share-view.title')}
      onClose={() => manageSharingViewModal.change(undefined)}
      open={Boolean(manageSharingViewModal.view)}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Stack sx={{ px: 4, pb: 3 }} spacing={1}>
          <Typography variant="h5Bold">{manageSharingViewModal.view?.name || ''}</Typography>
          <Controller
            control={control}
            name="isShared"
            render={({ field }) => {
              return (
                <RadioGroup
                  onChange={(event, value) => {
                    field.onChange(value === 'true');
                  }}
                  aria-labelledby="private-radio-buttons-group-label"
                  defaultValue={String(field.value)}
                  value={String(field.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio sx={{ p: 0 }} />}
                    label={
                      <Typography variant="h5">
                        {tCommon('views.modal.share-view.radio-option-private')}
                      </Typography>
                    }
                    sx={radioButtonLabelStyle}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio sx={{ p: 0 }} />}
                    label={
                      <Typography variant="h5">
                        {tCommon('views.modal.share-view.radio-option-public')}
                      </Typography>
                    }
                    sx={radioButtonLabelStyle}
                  />
                </RadioGroup>
              );
            }}
          />
        </Stack>
        <Divider />
        <FormButtonsGroup sx={{ py: 2, px: 4 }} isLoading={viewShareMutation.isLoading}>
          <Button type="button" size="small" variant="text" disabled={viewShareMutation.isLoading}>
            {tCommon('cancel')}
          </Button>
          <Button type="submit" size="small" disabled={viewShareMutation.isLoading}>
            {tCommon('confirm')}
          </Button>
        </FormButtonsGroup>
      </form>
    </DialogBaseWrapper>
  );
};
