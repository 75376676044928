import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  longDateWithMonthDescriptionAndTime,
  parseDate,
  parseDateAndFixIso,
} from 'common/format/date';
import { marketplaceList } from 'common/utils';
import { Show } from 'components';
import { StatusColorGroup } from 'modules/offers/types';
import { nethansaPalette } from 'theme';
import { Box, Stack } from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColumnHeaderParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { ChannelCode, SharedMonitoredOfferState } from '../../../generated/graphql';
import {
  DatagridCellFlag,
  DatagridCellImage,
  DatagridCellTrend,
  DatagridHeaderTooltip,
  currencyCellClassName,
  currencyCellValueFormatter,
  nullableValueCellValueGetter,
  useDatagridCountryListOperators,
  useDatagridDateOperators,
  DatagridCellTags,
  useDatagridListOperators,
} from '../../datagrid';
import { useOffersTags } from '../../offers/hooks/use-offers-tags.query';
import { MonitoredOfferGridColDefCustom } from '../types';
import { getMonitoringStatusColorGroup } from '../utils';
import { ButtonMenuMonitoring } from '../utils/menu-button-monitoring';

export const useMonitoringColumnsDefinition = (): MonitoredOfferGridColDefCustom[] => {
  const { t, i18n } = useTranslation('monitoring');
  const [tCurrency] = useTranslation('currencies');
  const dateOperators = useDatagridDateOperators();
  const countryListOperators = useDatagridCountryListOperators();
  const listOperators = useDatagridListOperators();
  const { data: dataOfferTags } = useOffersTags();

  const monitoredOfferStateList = [
    {
      value: SharedMonitoredOfferState.Active,
      label: t(`column.state.${SharedMonitoredOfferState.Active}`),
    },
    {
      value: SharedMonitoredOfferState.Deleted,
      label: t(`column.state.${SharedMonitoredOfferState.Deleted}`),
    },
    {
      value: SharedMonitoredOfferState.NotFound,
      label: t(`column.state.${SharedMonitoredOfferState.NotFound}`),
    },
    {
      value: SharedMonitoredOfferState.Searching,
      label: t(`column.state.${SharedMonitoredOfferState.Searching}`),
    },
    {
      value: SharedMonitoredOfferState.Unknown,
      label: t(`column.state.${SharedMonitoredOfferState.Unknown}`),
    },
  ];

  const channelList = [
    {
      value: ChannelCode.Amzfba,
      label: 'Amazon FBA',
    },
    {
      value: ChannelCode.Amzfbm,
      label: 'Amazon FBM',
    },
    {
      value: null,
      label: '-',
    },
  ];

  const renderHeader = useCallback(
    (translationKey?: string) => (params: GridColumnHeaderParams<GridValidRowModel>) =>
      (
        <DatagridHeaderTooltip
          header={params.colDef.headerName}
          tooltipContent={t(
            `column.${
              translationKey || params.colDef.field
            }.tooltip.__html` as `column.state.tooltip.__html`,
          )}
        />
      ),
    [t, i18n.language],
  );

  return useMemo<MonitoredOfferGridColDefCustom[]>(() => {
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        type: undefined,
        width: 60,
        pinnable: false,
        sortable: false,
        editable: false,
        resizable: false,
        hideable: false,
        renderCell: (params) => {
          return (
            <Stack sx={{ position: 'relative', height: '100%' }}>
              <Box
                className="offersDataGridRowState"
                sx={{
                  borderColor:
                    nethansaPalette.status[
                      `${getMonitoringStatusColorGroup(params.row.state) as StatusColorGroup}`
                    ].light,
                }}
              ></Box>
              <Box>
                {GRID_CHECKBOX_SELECTION_COL_DEF?.renderCell &&
                  GRID_CHECKBOX_SELECTION_COL_DEF?.renderCell(params)}
              </Box>
              <Box>{<ButtonMenuMonitoring offer={params.row} />}</Box>
            </Stack>
          );
        },
      },
      {
        field: 'state',
        headerName: t('column.state.label'),
        width: 120,
        pinnable: false,
        editable: false,
        resizable: false,
        hideable: false,
        type: 'singleSelect',
        valueOptions: monitoredOfferStateList,
        renderCell: (param) => (
          <Box
            color={
              nethansaPalette.status[
                `${getMonitoringStatusColorGroup(param.value) as StatusColorGroup}`
              ].dark
            }
          >
            {param.formattedValue}
          </Box>
        ),
      },
      {
        field: 'asin',
        headerName: t('column.asin.label'),
        width: 120,
        type: 'string',
      },
      {
        field: 'marketplaceCode',
        headerName: t('column.marketplaceCode.label'),
        width: 146,
        type: 'singleSelect',
        valueOptions: marketplaceList.map((el) => el.value),
        filterOperators: countryListOperators,
        renderCell: (params) => {
          const marketplace = marketplaceList.filter((el) => el.value === params.value);
          return (
            <DatagridCellFlag
              value={params.value}
              label={marketplace[0].label}
              href={
                params.row.state === SharedMonitoredOfferState.Active
                  ? `https://${marketplace[0].label}/dp/${params.row.asin}`
                  : undefined
              }
            />
          );
        },
      },
      {
        field: 'catalogImageUrl',
        headerName: t('column.catalogImageUrl.label'),
        sortable: false,
        filterable: false,
        width: 100,
        type: 'string',
        renderCell: (params) => <DatagridCellImage src={params.value} provider="amazon" />,
      },
      {
        field: 'catalogTitle',
        headerName: t('column.catalogTitle.label'),
        width: 250,
        cellClassName: 'dg-text-wrapper-cell',
        type: 'string',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'catalogParentAsin',
        headerName: t('column.catalogParentAsin.label'),
        width: 120,
        type: 'string',
      },
      {
        field: 'catalogBrand',
        headerName: t('column.catalogBrand.label'),
        width: 120,
        type: 'sting',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'rankingName',
        headerName: t('column.rankingName.label'),
        width: 140,
        type: 'string',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'rankingRank',
        headerName: t('column.rankingRank.label'),
        width: 100,
        type: 'number',
        valueGetter: nullableValueCellValueGetter,
        renderHeader: renderHeader(),
        renderCell: (param) => {
          return (
            <DatagridCellTrend
              value={param.value ? param.value.toLocaleString(i18n.language) : null}
              trendValue={param.row.aggregationDataRankingAvg7Days}
              transKeyPath="column.rankingRank"
            />
          );
        },
      },
      {
        field: 'aggregationDataMaxRankingLast30Days-group',
        headerName: t('column.aggregationDataMaxRankingLast30Days-group.label'),
        width: 120,
        type: 'number',
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return param.row.aggregationDataMinRankingLast30Days ? (
            <Stack sx={{ alignItems: 'flex-end' }}>
              <Box>
                {t('column.aggregationDataMaxRankingLast30Days.unit')}:&nbsp;
                {param.row.aggregationDataMaxRankingLast30Days.toLocaleString(i18n.language)}
              </Box>
              <Box>
                {t('column.aggregationDataMinRankingLast30Days.unit')}:&nbsp;
                {param.row.aggregationDataMinRankingLast30Days.toLocaleString(i18n.language)}
              </Box>
            </Stack>
          ) : (
            <Box>—</Box>
          );
        },
        renderHeader: renderHeader(),
      },
      {
        field: 'aggregationDataMaxRankingLast30Days',
        headerName: t('column.aggregationDataMaxRankingLast30Days.label'),
        width: 80,
        type: 'number',
      },
      {
        field: 'aggregationDataMinRankingLast30Days',
        headerName: t('column.aggregationDataMinRankingLast30Days.label'),
        width: 80,
        type: 'number',
      },
      {
        field: 'numberOfOffers',
        headerName: t('column.numberOfOffers.label'),
        width: 120,
        type: 'number',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'buyBoxInfoChannelCode',
        headerName: t('column.buyBoxInfoChannelCode.label'),
        width: 120,
        type: 'singleSelect',
        valueOptions: channelList,
      },
      {
        field: 'buyBoxInfoPrice',
        headerName: t('column.buyBoxInfoPrice.label'),
        width: 120,
        type: 'number',
        valueGetter: nullableValueCellValueGetter,
        renderCell: (param) => {
          return (
            <DatagridCellTrend
              value={param.value}
              trendValue={param.row.aggregationDataBuyBuxPriceAvgLast7Days}
              currency={param.row.currencyCode}
              transKeyPath="column.buyBoxInfoPrice"
            />
          );
        },
        renderHeader: renderHeader(),
      },
      {
        field: 'aggregationDataMinBuyBoxPriceLast30Days-group',
        headerName: t('column.aggregationDataMinBuyBoxPriceLast30Days-group.label'),
        width: 160,
        sortable: false,
        filterable: false,
        type: 'number',
        renderCell: (param) => {
          return param.row.aggregationDataMinBuyBoxPriceLast30Days ? (
            <Stack
              sx={{ alignItems: 'flex-end', '& span': { color: nethansaPalette.button.grayHover } }}
            >
              <Box>
                {t('column.aggregationDataMinBuyBoxPriceLast30Days.unit')}:&nbsp;
                {param.row.currencyCode ? (
                  <span>{tCurrency(`${param.row.currencyCode as 'EUR'}.short`)} </span>
                ) : (
                  ''
                )}
                {param.row.aggregationDataMinBuyBoxPriceLast30Days.toFixed(2)}
              </Box>
              <Box>
                {t('column.aggregationDataMaxBuyBoxPriceLast30Days.unit')}:&nbsp;
                {param.row.currencyCode ? (
                  <span>{tCurrency(`${param.row.currencyCode as 'EUR'}.short`)} </span>
                ) : (
                  ''
                )}
                {param.row.aggregationDataMaxBuyBoxPriceLast30Days.toFixed(2)}
              </Box>
            </Stack>
          ) : (
            <Box>—</Box>
          );
        },
        renderHeader: renderHeader(),
      },
      {
        field: 'monitoredOfferTags',
        headerName: t('column.monitoredOfferTags.label'),
        width: 180,
        sortable: false,
        type: 'singleSelect',
        filterOperators: listOperators,
        valueOptions: (dataOfferTags || [])?.map((x) => ({ value: x.name, label: x.name })),
        renderCell: (params) => (
          <DatagridCellTags
            maxTags={2}
            tags={params.value || []}
            color={nethansaPalette.other.tagOffer}
          />
        ),
      },
      {
        field: 'aggregationDataMinBuyBoxPriceLast30Days',
        headerName: t('column.aggregationDataMinBuyBoxPriceLast30Days.label'),
        width: 80,
        type: 'number',
      },
      {
        field: 'aggregationDataMaxBuyBoxPriceLast30Days',
        headerName: t('column.aggregationDataMaxBuyBoxPriceLast30Days.label'),
        width: 80,
        type: 'number',
      },
      {
        field: 'buyBoxInfoSellerId-group',
        headerName: t('column.buyBoxInfoSellerId-group.label'),
        width: 160,
        type: 'string',
        filterable: false,
        renderCell: (param) => {
          const marketplaceUrl = marketplaceList.filter(
            (el) => el.value === param.row.marketplaceCode,
          )[0].label;
          const sellerUrl = `https://www.${marketplaceUrl}/sp?seller=${param.row.buyBoxInfoSellerId}`;
          const {
            buyBoxInfoFeedbackCount,
            buyBoxInfoSellerPositiveFeedbackRating,
            buyBoxInfoShipsFrom,
          } = param.row;
          return param.row.isBuyBox ? (
            <Stack
              sx={{
                flexDirection: 'column',
                '& span': { color: nethansaPalette.button.grayHover },
              }}
            >
              <Show when={param.row.buyBoxInfoSellerId !== 'Amazon'} fallback={<Box>Amazon</Box>}>
                <Box>
                  <a href={sellerUrl} target="_blank">
                    {param.row.buyBoxInfoSellerId}
                  </a>
                </Box>
                <Box>
                  <Trans
                    i18nKey="column.buyBoxInfoFeedbackCount.unit"
                    t={t}
                    components={{ gray: <span /> }}
                    values={{ count: buyBoxInfoFeedbackCount }}
                  />
                </Box>
                <Box>
                  <Trans
                    i18nKey="column.buyBoxInfoSellerPositiveFeedbackRating.unit"
                    t={t}
                    components={{ gray: <span /> }}
                    values={{ count: buyBoxInfoSellerPositiveFeedbackRating }}
                  />
                </Box>
                <Box>
                  <span>{t('column.buyBoxInfoShipsFrom.unit')} </span>
                  {param.row.buyBoxInfoChannelCode === 'AMZFBA'
                    ? 'Amazon'
                    : buyBoxInfoShipsFrom || '-'}
                </Box>
              </Show>
            </Stack>
          ) : (
            <Box>{param.row.isBuyBox === null ? '—' : t('column.buyBoxInfoSellerId.no-data')}</Box>
          );
        },
      },
      {
        field: 'buyBoxInfoSellerId',
        headerName: t('column.buyBoxInfoSellerId.label'),
        width: 80,
        type: 'string',
      },
      {
        field: 'buyBoxInfoFeedbackCount',
        headerName: t('column.buyBoxInfoFeedbackCount.label'),
        width: 80,
        type: 'number',
      },
      {
        field: 'buyBoxInfoSellerPositiveFeedbackRating',
        headerName: t('column.buyBoxInfoSellerPositiveFeedbackRating.label'),
        width: 80,
        type: 'number',
      },
      {
        field: 'buyBoxInfoShipsFrom',
        headerName: t('column.buyBoxInfoShipsFrom.label'),
        width: 80,
        type: 'string',
      },
      {
        field: 'buyBoxInfoIsPrime',
        headerName: t('column.buyBoxInfoIsPrime.label'),
        width: 80,
        type: 'boolean',
      },
      {
        field: 'lowestPrice',
        headerName: t('column.lowestPrice.label'),
        width: 128,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'createdAt',
        headerName: t('column.createdAt.label'),
        width: 120,
        type: 'date',
        filterOperators: dateOperators,
        cellClassName: 'dg-text-wrapper-cell',
        valueGetter: (params) => parseDateAndFixIso(parseDate(params.value)),
        valueFormatter: (params) =>
          params.value ? longDateWithMonthDescriptionAndTime(params.value, i18n.language) : '—',
      },
      {
        field: 'updatePricingData',
        headerName: t('column.updatePricingData.label'),
        width: 128,
        type: 'date',
        filterOperators: dateOperators,
        cellClassName: 'dg-text-wrapper-cell',
        valueGetter: (params) => parseDateAndFixIso(parseDate(params.value)),
        valueFormatter: (params) =>
          params.value ? longDateWithMonthDescriptionAndTime(params.value, i18n.language) : '—',
      },
    ];
  }, []);
};
