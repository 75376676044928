import React, { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Collapse, MenuItem, MenuItemProps, Stack } from '@mui/material';

type Props = React.PropsWithChildren<{ MenuItemProps?: MenuItemProps; menu: JSX.Element }>;

export const MenuItemExpander: React.FC<Props> = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <MenuItem {...(props.MenuItemProps || {})} onClick={() => setExpanded(!expanded)}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          {props.children}
          <ExpandMore
            sx={{
              fontSize: '22px',
              transition: 'transform 0.2s',
              transform: expanded ? `rotate(180deg)` : 'none',
            }}
          />
        </Stack>
      </MenuItem>
      <Collapse in={expanded}>{props.menu}</Collapse>
    </>
  );
};
