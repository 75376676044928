import React from 'react';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const AmazonLogo: React.FC<Props> = (props) => {
  return (
    <Box sx={props.sx}>
      <svg
        width="93"
        height="28"
        viewBox="0 0 93 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_834:2391)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.6421 21.8969C52.2427 25.8767 44.4166 28 37.6785 28C28.2305 28 19.725 24.5056 13.2902 18.6936C12.7846 18.2366 13.2376 17.6138 13.8443 17.9697C20.7887 22.0101 29.3751 24.4409 38.2447 24.4409C44.2265 24.4409 50.8069 23.2032 56.8574 20.635C57.7715 20.2467 58.5359 21.2336 57.6421 21.8969Z"
            fill="#FF9900"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.8866 19.3286C59.1991 18.4469 55.3245 18.9121 53.5854 19.1183C53.0555 19.183 52.9747 18.722 53.4519 18.3903C56.5378 16.2184 61.6015 16.8453 62.192 17.5733C62.7825 18.3054 62.0383 23.3812 59.1384 25.8039C58.6935 26.176 58.2689 25.9778 58.467 25.4844C59.1182 23.8585 60.5783 20.2144 59.8866 19.3286Z"
            fill="#FF9900"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.7067 3.05763V0.946409C53.7067 0.626894 53.9494 0.412537 54.2406 0.412537H63.6926C63.9959 0.412537 64.2386 0.630939 64.2386 0.946409V2.75429C64.2346 3.05763 63.9798 3.45399 63.5268 4.08089L58.6289 11.0738C60.4489 11.0293 62.37 11.3003 64.0202 12.2305C64.3923 12.4408 64.4934 12.7482 64.5217 13.0516V15.3043C64.5217 15.6117 64.182 15.9717 63.8261 15.7856C60.9181 14.2609 57.0556 14.095 53.8402 15.8018C53.5126 15.9798 53.1688 15.6239 53.1688 15.3165V13.1769C53.1688 12.8332 53.1729 12.2467 53.5166 11.725L59.1911 3.58746H54.2527C53.9494 3.58746 53.7067 3.3731 53.7067 3.05763Z"
            fill="#282828"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2274 16.2305H16.3518C16.0768 16.2103 15.8584 16.004 15.8381 15.7411V0.982796C15.8381 0.687549 16.0849 0.452967 16.3922 0.452967H19.0737C19.3528 0.465092 19.5752 0.679457 19.5955 0.946393V2.87562H19.648C20.3477 1.01111 21.6622 0.141541 23.4337 0.141541C25.2335 0.141541 26.3578 1.01111 27.1667 2.87562C27.8624 1.01111 29.4438 0.141541 31.1384 0.141541C32.3437 0.141541 33.6622 0.639012 34.467 1.75529C35.3771 2.99695 35.191 4.80079 35.191 6.38218L35.187 15.6966C35.187 15.9919 34.9403 16.2305 34.6329 16.2305H31.7613C31.4741 16.2103 31.2436 15.9797 31.2436 15.6966V7.8746C31.2436 7.25175 31.3002 5.69867 31.1627 5.10817C30.9483 4.11727 30.3053 3.8382 29.4721 3.8382C28.7765 3.8382 28.0484 4.30332 27.7532 5.0475C27.4579 5.79169 27.4863 7.03739 27.4863 7.8746V15.6966C27.4863 15.9919 27.2395 16.2305 26.9322 16.2305H24.0606C23.7694 16.2103 23.5429 15.9797 23.5429 15.6966L23.5388 7.8746C23.5388 6.22849 23.8098 3.80585 21.7674 3.80585C19.7006 3.80585 19.7815 6.16782 19.7815 7.8746V15.6966C19.7815 15.9919 19.5348 16.2305 19.2274 16.2305"
            fill="#282828"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.3761 0.141541C76.643 0.141541 78.9524 3.80585 78.9524 8.4651C78.9524 12.9666 76.4003 16.5379 72.3761 16.5379C68.186 16.5379 65.9049 12.8736 65.9049 8.30737C65.9049 3.71282 68.2143 0.141541 72.3761 0.141541ZM72.4004 3.15469C70.281 3.15469 70.1476 6.04245 70.1476 7.84225C70.1476 9.64609 70.1193 13.4964 72.3761 13.4964C74.6046 13.4964 74.7098 10.3903 74.7098 8.49745C74.7098 7.25175 74.6572 5.76338 74.281 4.58239C73.9575 3.55509 73.3144 3.15469 72.4004 3.15469Z"
            fill="#282828"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84.4853 16.2305H81.6218C81.3347 16.2103 81.1041 15.9797 81.1041 15.6966L81.1001 0.93426C81.1244 0.663279 81.363 0.452967 81.6542 0.452967H84.3195C84.5703 0.465092 84.7765 0.634969 84.8332 0.865505V3.12233H84.8857C85.6906 1.10413 86.819 0.141541 88.8048 0.141541C90.095 0.141541 91.3529 0.606657 92.1618 1.88067C92.914 3.06166 92.914 5.0475 92.914 6.4752V15.7654C92.8817 16.0242 92.6431 16.2305 92.36 16.2305H89.4762C89.2133 16.2103 88.9949 16.0162 88.9666 15.7654V7.74922C88.9666 6.13547 89.1527 3.77349 87.1668 3.77349C86.4671 3.77349 85.8241 4.24265 85.5045 4.95448C85.1001 5.8564 85.0475 6.75428 85.0475 7.74922V15.6966C85.0435 15.9919 84.7927 16.2305 84.4853 16.2305"
            fill="#282828"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.1719 9.18099C46.1719 10.3013 46.2003 11.2356 45.634 12.2305C45.177 13.0394 44.449 13.5369 43.6441 13.5369C42.54 13.5369 41.8929 12.6956 41.8929 11.454C41.8929 9.00303 44.089 8.55814 46.1719 8.55814V9.18099ZM49.0719 16.1901C48.8818 16.3599 48.6067 16.3721 48.3924 16.2588C47.4379 15.4661 47.264 15.0981 46.7422 14.3418C45.1649 15.9515 44.0445 16.4327 42.0021 16.4327C39.5794 16.4327 37.6987 14.9403 37.6987 11.9515C37.6987 9.61779 38.9606 8.02831 40.7645 7.25177C42.3256 6.5642 44.5056 6.44287 46.1719 6.25278V5.88068C46.1719 5.19716 46.2245 4.38827 45.8201 3.79778C45.4723 3.26795 44.8009 3.04954 44.2063 3.04954C43.1103 3.04954 42.1355 3.61173 41.8969 4.77654C41.8484 5.03539 41.6583 5.29019 41.3954 5.30232L38.6087 5.00303C38.3742 4.95045 38.1113 4.76036 38.18 4.4004C38.8191 1.01921 41.8767 0 44.6108 0C46.0102 0 47.8383 0.372093 48.9424 1.43175C50.3418 2.73812 50.2084 4.48129 50.2084 6.37816V10.8594C50.2084 12.2063 50.7665 12.7968 51.2923 13.5248C51.4743 13.7836 51.5147 14.095 51.2801 14.2892C50.6937 14.7786 49.6502 15.6886 49.0759 16.1982L49.0718 16.1901"
            fill="#282828"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.4732 9.18123C8.4732 10.3016 8.50151 11.2358 7.93528 12.2308C7.47825 13.0397 6.75429 13.5371 5.94539 13.5371C4.84125 13.5371 4.19818 12.6959 4.19818 11.4542C4.19818 9.00328 6.39433 8.55838 8.4732 8.55838V9.18123ZM11.3731 16.1903C11.183 16.3602 10.908 16.3723 10.6936 16.2591C9.73912 15.4664 9.56925 15.0983 9.04347 14.342C7.46612 15.9517 6.34985 16.433 4.30333 16.433C1.88473 16.433 0 14.9406 0 11.9517C0 9.61804 1.26592 8.02855 3.06572 7.25201C4.62689 6.56445 6.80687 6.44312 8.4732 6.25303V5.88093C8.4732 5.19741 8.52578 4.38851 8.12537 3.79802C7.7735 3.26819 7.10212 3.04979 6.51162 3.04979C5.41557 3.04979 4.4368 3.61197 4.19818 4.77678C4.14964 5.03563 3.95955 5.29044 3.70071 5.30257L0.91001 5.00328C0.675429 4.9507 0.416581 4.76061 0.481293 4.40065C1.12437 1.01946 4.17795 0.000244141 6.91203 0.000244141C8.31142 0.000244141 10.1395 0.372338 11.2437 1.43199C12.6431 2.73836 12.5096 4.48154 12.5096 6.3784V10.8597C12.5096 12.2065 13.0677 12.797 13.5935 13.525C13.7796 13.7839 13.82 14.0953 13.5854 14.2894C12.999 14.7788 11.9555 15.6888 11.3812 16.1984L11.3731 16.1903"
            fill="#282828"
          />
        </g>
        <defs>
          <clipPath id="clip0_834:2391">
            <rect width="92.914" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
