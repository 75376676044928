import { isValid, startOfDay, subDays } from 'date-fns';
import { parseDate, parseDateAndFixIso } from '../../../../common/format/date';
import { operators } from '../../consts';

export const parseFilterValue = (
  value: string | string[],
  columnField,
  columnType: string,
  columnOperator?: keyof typeof operators,
) => {
  if (columnOperator && ['isEmpty', 'isNotEmpty'].some((x) => x === columnOperator)) {
    return null;
  }
  if (columnType === 'number' && Array.isArray(value)) {
    return (value || []).map((val) => {
      const numberValue = parseFloat(val as string);
      return !isNaN(numberValue) ? numberValue : val;
    });
  }
  if (columnType === 'number') {
    const numberValue = parseFloat(value as string);
    return !isNaN(numberValue) ? numberValue : value;
  }
  if (columnType === 'date') {
    if (columnOperator === 'last') {
      return parseDateAndFixIso(startOfDay(subDays(new Date(), Number(value || 0))));
    }
    const dateValue = parseDate(value as string);
    return isValid(dateValue) ? dateValue : value;
  }
  if (columnType === 'boolean') {
    return value === 'true';
  }
  if (columnType === 'singleSelect') {
    return value;
  }
  return value ? value : '';
};
