import { useEffect } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import {
  InputMaybe,
  MonitoredOfferGridDtoFilterInput,
  MonitoredOfferGridDtoSortInput,
  Query,
  SortEnumType,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';
import { useDatagridContext, useDatagridContextGraphqlTranslator } from 'modules/datagrid';
import { MonitoredOfferGridColDefCustom, MonitoredOfferRowKey } from '../types';

export const getMonitoringOffers = gql`
  query monitoredOffers(
    $partnerId: String!
    $first: Int
    $after: String
    $sort: [MonitoredOfferGridDtoSortInput!]
    $filter: MonitoredOfferGridDtoFilterInput
  ) {
    monitoredOffers(
      partnerId: $partnerId
      first: $first
      after: $after
      where: $filter
      order: $sort
    ) {
      totalCount
      nodes {
        ...monitoredOffersNode
      }
      pageInfo {
        ...paginationMeta
      }
      edges {
        cursor
      }
    }
  }

  fragment monitoredOffersNode on MonitoredOfferGridDto {
    id
    asin
    marketplaceCode
    partnerId
    state
    catalogTitle
    catalogParentAsin
    catalogBrand
    catalogImageUrl
    rankingRank
    rankingName
    numberOfOffers
    isBuyBox
    buyBoxInfoPrice
    buyBoxInfoSellerId
    buyBoxInfoIsPrime
    buyBoxInfoChannelCode
    buyBoxInfoFeedbackCount
    buyBoxInfoSellerPositiveFeedbackRating
    buyBoxInfoShipsFrom
    lowestPrice
    aggregationDataMinBuyBoxPriceLast30Days
    aggregationDataMaxBuyBoxPriceLast30Days
    aggregationDataMinRankingLast30Days
    aggregationDataMaxRankingLast30Days
    currencyCode
    updatePricingData
    createdAt
    aggregationDataRankingAvg7Days
    monitoredOfferTags
    aggregationDataBuyBuxPriceAvgLast7Days
  }

  fragment paginationMeta on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const searchableMonitoringColumns: MonitoredOfferRowKey[] = [
  'catalogTitle',
  'asin',
  'catalogParentAsin',
  'catalogBrand',
  'rankingName',
];

export const useMonitoringOffersQuery = (
  columns: MonitoredOfferGridColDefCustom[],
): UseQueryResult<Query> => {
  const { currentPartnerId } = useAuth();
  const gridContext = useDatagridContext();
  const gridFilterSort = useDatagridContextGraphqlTranslator(columns, searchableMonitoringColumns);

  const queryResult = useQuery(
    [
      'monitoredOffers',
      currentPartnerId,
      gridFilterSort.first,
      gridFilterSort.after,
      gridFilterSort.sort,
      gridFilterSort.filter,
    ],
    async () => {
      const parsedFilter: InputMaybe<MonitoredOfferGridDtoFilterInput> = {
        ...gridFilterSort.filter,
      };

      const parsedSort: InputMaybe<MonitoredOfferGridDtoSortInput> = gridFilterSort.sort ?? {
        createdAt: SortEnumType.Desc,
      };

      const data = await graphqlClient.request(getMonitoringOffers, {
        partnerId: String(currentPartnerId),
        first: gridFilterSort.first,
        after: gridFilterSort.after,
        sort: parsedSort,
        ...(Object.keys(parsedFilter || {}).length ? { filter: parsedFilter } : {}),
      });

      return {
        ...data,
      };
    },
    {
      staleTime: 5000,
      keepPreviousData: false,
      retry: false,
    },
  );

  useEffect(() => {
    // Update rowCount to calculate current page
    gridContext.rowCount[1](queryResult.data?.monitoredOffers?.totalCount || 0);
  }, [queryResult.data?.monitoredOffers?.totalCount]);

  return queryResult;
};
