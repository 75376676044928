import React, { useEffect, useState } from 'react';
import { nethansaPalette } from 'theme';
import { IconButton, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useDebounce } from '@react-hook/debounce';
import { CloseIcon, SearchIcon } from '../icons';
import { TextFieldOutlined } from '../input-fields';
import { Show } from '../show';
import { Tooltip } from '../tooltip';

type Props = {
  placeholder?: string;
  initialValue?: string;
  onDebounceChange: (value: string) => void;
  debounceTime?: number;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  tooltip?: string;
};

export const SearchTextInput: React.FC<Props> = React.memo((props) => {
  const [value, setValue] = useState(props.initialValue || '');
  const [debouncedValue, setDebouncedValue] = useDebounce(value, props.debounceTime || 500);

  useEffect(() => {
    props.onDebounceChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <TextFieldOutlined
      disabled={props.disabled}
      sx={props.sx}
      placeholder={props.placeholder}
      onChange={(event) => {
        setValue(event.target.value || '');
        setDebouncedValue(event.target.value || '');
      }}
      value={value}
      InputProps={{
        endAdornment: (
          <Stack direction="row" spacing={0.5} alignItems="center">
            {value.length === 0 ? (
              <SearchIcon
                sx={{ color: nethansaPalette.button.grayLight, fontSize: '16px', m: '5px' }}
              />
            ) : (
              <IconButton
                size="small"
                sx={{
                  '& path': {
                    stroke: nethansaPalette.button.grayLight,
                  },
                  '&:hover': {
                    '& path': {
                      stroke: nethansaPalette.button.grayHover,
                    },
                  },
                }}
                onClick={() => {
                  setValue('');
                  setDebouncedValue('');
                }}
              >
                <CloseIcon sx={{ fontSize: '16px' }} />
              </IconButton>
            )}
            <Show when={!!props.tooltip}>
              <Tooltip content={props.tooltip} />
            </Show>
          </Stack>
        ),
        sx: { borderRadius: '6px' },
      }}
    />
  );
});
