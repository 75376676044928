import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogHeader } from 'components';
import { SpinnerIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import { Box, Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { OnboardingWizardContext, WizardFormStepType } from './contexts/onboarding-wizard.provider';
import { useWizardStatusQuery } from './hooks/default/use-wizard-status.query';

export const WelcomeModal: React.FC = () => {
  const status = useWizardStatusQuery();
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('wizard');
  const context = useContext(OnboardingWizardContext);

  const handleClick = () => {
    context.goToFormStep(WizardFormStepType.globals);
  };

  return (
    <Dialog open>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '350px',
          backgroundImage: 'url("/images/wizard-hello-background.png")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <DialogHeader lightMode onClose={context.closeOnboarding} spacing="lg" />
        <DialogContent>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              pt: 10,
              pb: '102px',
            }}
          >
            <Typography variant="h1" sx={{ mb: 4 }}>
              {t('welcome-modal.header')}
            </Typography>

            <Typography
              variant="h2"
              sx={{
                textAlign: 'center',
                lineHeight: '19.36px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box component="span" sx={{ display: 'block' }}>
                {t('welcome-modal.description.normalLine')}
              </Box>
              <Box
                component="span"
                sx={{ display: 'block', backgroundColor: 'primary.main', px: 1 }}
              >
                {t('welcome-modal.description.highlightedLine')}
              </Box>
            </Typography>
          </Stack>
        </DialogContent>
      </Box>

      {status.isLoading && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: 'background.paper', py: 4, width: '100%', height: '100%' }}
        >
          <SpinnerIcon sx={{ fontSize: '120px' }} />
        </Stack>
      )}
      {!status.isLoading && (
        <DialogActions
          sx={{
            p: '55px',
            justifyContent: 'center',
            '&..MuiDialogActions-root': {
              p: '55px',
            },
          }}
        >
          <Button sx={{ minWidth: '203px' }} onClick={handleClick}>
            {tCommon('get-started')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
