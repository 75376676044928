import React, { useState } from 'react';
import { StateSetterValue } from '../../shared';
import { SelectedMonitoring } from '../types';

type MonitoringModalCoordinatorContextType = {
  deleteOffers: StateSetterValue<SelectedMonitoring | undefined>;
  editTags: StateSetterValue<SelectedMonitoring | undefined>;
};

export const MonitoringModalCoordinatorContext =
  React.createContext<MonitoringModalCoordinatorContextType>({
    deleteOffers: [undefined, () => null],
    editTags: [undefined, () => null],
  });

type Props = React.PropsWithChildren<unknown>;

export const MonitoringModalsCoordinatorProvider: React.FC<Props> = (props) => {
  const deleteOffers = useState<SelectedMonitoring | undefined>(undefined);
  const editTags = useState<SelectedMonitoring | undefined>(undefined);

  return (
    <MonitoringModalCoordinatorContext.Provider
      value={{
        deleteOffers,
        editTags,
      }}
    >
      {props.children}
    </MonitoringModalCoordinatorContext.Provider>
  );
};
