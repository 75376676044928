import { useQuery } from 'react-query';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';

type Response = {
  hasConnectedAmazonPlatform: boolean;
};

type Params = {
  onSuccess?: (data: Response) => void;
  onError?: () => void;
};

export const useAmazonPlatformCheckQuery = (params?: Params) => {
  const { authorized } = useAuth();

  return useQuery<Response, Error, Response>(
    ['wizard', 'platform', 'markets', 'AMZEU'],
    async () => {
      const response = await httpClient.post(API.wizard().singlePlatformTestConnection('amzeu'), {
        partnerId: httpClient.defaults.params['partnerId'],
      });
      return {
        hasConnectedAmazonPlatform: Boolean(response.status == 204),
      };
    },
    {
      staleTime: 0,
      retry: 0,
      enabled: authorized,
      onSuccess: params?.onSuccess,
      onError: params?.onError,
    },
  );
};
