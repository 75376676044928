import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from '../../../../common/api';
import { useAuth } from '../../../authorization';
import { ResponseAPIDataStatusType } from '../../../shared/types';
import { DatagridViewsGroupType } from '../../types';

type ResponseData = DatagridViewsGroupType<string>[];

type CallbackType<TData> = { onSuccess: (data: TData) => void };

export const useViewsQuery = (
  tableId: string,
  callbacks?: CallbackType<DatagridViewsGroupType[]>,
): UseQueryResult<DatagridViewsGroupType[]> => {
  const { currentPartnerId } = useAuth();

  return useQuery<DatagridViewsGroupType[]>(
    ['views', tableId],
    async () => {
      const response = await httpClient.get<ResponseAPIDataStatusType<ResponseData>>(
        API.views().viewsList(currentPartnerId, tableId),
      );

      const parsedData: DatagridViewsGroupType[] = response.data.data.map((x) => ({
        ...x,
        tableViews: x.tableViews.map((y) => {
          const mapObj = {
            columnField: 'field',
            operatorValue: 'operator',
            linkOperator: 'logicOperator',
          };
          const filtersMui6Compatible = y.gridConfiguration.replace(
            /columnField|operatorValue|linkOperator/g,
            (matched) => mapObj[matched],
          );

          try {
            return {
              ...y,
              gridConfiguration: JSON.parse(filtersMui6Compatible),
            };
          } catch (err: unknown) {
            // pushSnackbar({
            //   message: `Problem occurred during transform a saved view "${y.name}" into grid`,
            //   variant: 'error',
            // });
            return {
              ...y,
              gridConfiguration: {},
            };
          }
        }),
      }));

      return parsedData;
    },
    {
      staleTime: 2000,
      onSuccess: (data) => {
        callbacks?.onSuccess && callbacks.onSuccess(data);
      },
    },
  );
};
