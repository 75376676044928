import { SharedOfferStateEnum } from 'generated/graphql';
import { StatusColorGroup } from 'modules/offers/types';

export const getStatusColorGroup = (status: SharedOfferStateEnum): StatusColorGroup => {
  const s = SharedOfferStateEnum;
  const dead = [s.NotFound, s.Deleted, s.Unknown, s.New];
  const opBefore = [s.Incomplete, s.MultipleListingsFound, s.ReadyToSend, s.SendingError];
  const opAfter = [s.ListingError, s.OutOfStock, s.OnHold];

  if (dead.includes(status)) return 'dead';
  if (status === s.Processing) return 'temp';
  if (opBefore.includes(status)) return 'opBefore';
  if (opAfter.includes(status)) return 'opAfter';
  if (status === s.Active) return 'active';
  return 'dead';
};
