import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { SideMenuSettings } from './components';

export const SettingsConfigurationLayout: React.FC = () => {
  return (
    <Stack direction="row" sx={{ width: '100%' }}>
      <SideMenuSettings />
      <Box
        sx={{
          backgroundColor: 'nethansa.background.title',
          flexBasis: '100%',
          width: 'calc(100% - 205px)',
        }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
};
