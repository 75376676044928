import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ExpandIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon sx={props.sx} width="32" height="32" viewBox="0 0 32 32">
      <g clipPath="url(#clip0_2994_8145)">
        <g filter="url(#filter0_d_2994_8145)">
          <path
            d="M17.9038 4.59619V6.21688L24.3215 6.22253L17.3296 13.2144L18.4787 14.3635L25.4706 7.37158L25.4755 13.7886H27.0962V4.59619H17.9038Z"
            fill="black"
          />
        </g>
        <path
          d="M17.9038 4.59619V6.21688L24.3215 6.22253L17.3296 13.2144L18.4787 14.3635L25.4706 7.37158L25.4755 13.7886H27.0962V4.59619H17.9038Z"
          fill="white"
        />
        <g filter="url(#filter1_d_2994_8145)">
          <path
            d="M14.0962 26.7886V25.1679L7.67849 25.1622L14.6704 18.1704L13.5213 17.0213L6.52944 24.0132L6.52449 17.5962H4.9038V26.7886H14.0962Z"
            fill="black"
          />
        </g>
        <path
          d="M14.0962 26.7886V25.1679L7.67849 25.1622L14.6704 18.1704L13.5213 17.0213L6.52944 24.0132L6.52449 17.5962H4.9038V26.7886H14.0962Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2994_8145"
          x="13.3297"
          y="1.59619"
          width="17.7665"
          height="17.7673"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2994_8145" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2994_8145"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2994_8145"
          x="0.903809"
          y="12.0213"
          width="17.7665"
          height="17.7673"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2994_8145" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2994_8145"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2994_8145">
          <rect width="32" height="31.3848" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
