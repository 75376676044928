import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show, TooltipWrapper } from 'components';
import { Maybe, SharedOfferStateEnum, SharedOfferSubStateEnum } from 'generated/graphql';
import { nethansaPalette } from 'theme';
import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import { getStatusColorGroup } from '../../../table-utils';
import { ChipStatus } from './chip-status';

type Props = {
  sharedState: SharedOfferStateEnum;
  sharedSubStates: Maybe<SharedOfferSubStateEnum>[];
  isRowDirection?: boolean;
  isNormalJustifyContent?: boolean;
};

export const DataGridSharedStateCell: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  const colorGroup = getStatusColorGroup(props.sharedState);

  const subStatusList = (
    <List sx={{ p: '0 !important', mt: '0 !important' }}>
      {props.sharedSubStates.map((subStatus) => (
        <ListItem key={subStatus} sx={{ py: 0.25, px: 0 }}>
          <ChipStatus
            colorGroup={colorGroup}
            label={t(`offer-status.substatus.${subStatus as 'ACTIVATING'}`)}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Stack
      className={`c-${colorGroup as 'dead'}`}
      sx={{
        flexDirection: props.isRowDirection ? 'row' : 'column',
        justifyContent: props.isNormalJustifyContent ? 'normal' : 'space-between',
        alignItems: 'flex-start',
        height: props.isRowDirection ? 'auto' : '72px',
        gap: props.isRowDirection ? 1 : 'unset',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          textTransform: 'lowercase',
          lineHeight: '18px',
          color: nethansaPalette.status[`${colorGroup as 'dead'}`].dark,
        }}
      >
        {t(`offer-status.main.${props.sharedState as 'ACTIVE'}`)}
      </Typography>
      <Show when={props.sharedSubStates.length > 0}>
        <Stack
          sx={{
            flexDirection: 'column',
            gap: 0.25,
            alignItems: props.sharedSubStates.length > 2 ? 'stretch' : 'flex-start',
          }}
        >
          <ChipStatus
            colorGroup={colorGroup}
            label={t(`offer-status.substatus.${props.sharedSubStates[0] as 'ACTIVATING'}`)}
          />

          <Show when={props.sharedSubStates.length == 2}>
            <ChipStatus
              colorGroup={colorGroup}
              label={t(`offer-status.substatus.${props.sharedSubStates[1] as 'ACTIVATING'}`)}
            />
          </Show>

          <Show when={props.sharedSubStates.length > 2}>
            <TooltipWrapper placement="top" content={subStatusList}>
              <Box>
                <ChipStatus
                  sx={{ width: '100%' }}
                  colorGroup={colorGroup}
                  label={`+ ${props.sharedSubStates.length - 1}`}
                />
              </Box>
            </TooltipWrapper>
          </Show>
        </Stack>
      </Show>
    </Stack>
  );
};
