import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    smallUppercase: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h5Bold?: React.CSSProperties;
    h5Text: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    smallUppercase?: React.CSSProperties;
    h3Bold?: React.CSSProperties;
    h5Bold?: React.CSSProperties;
    h5Text?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    smallUppercase: true;
    h3Bold: true;
    h5Bold: true;
    h5Text: true;
  }
}

export const typographyOptions: TypographyOptions = {
  fontFamily: ['Inter', '-apple-system', 'Roboto', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '33.89px',
  },
  h2: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19.36px',
  },
  h3: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.94px',
  },
  h3Bold: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16.94px',
  },
  h4: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '15.73px',
    letterSpacing: '0.5px',
  },
  h5: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '24px',
    '& .ulink': {
      color: '#4543a6',
      textDecoration: 'underline',
      '&:hover': {
        color: '#373584',
      },
    },
  },
  h5Bold: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  h5Text: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  smallUppercase: {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
  },
};
