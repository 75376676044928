export type PaginationMetaType = {
  total: number;
  offset: number;
  limit: number;
};

export type ErrorGeneral = {
  title: string;
  status: number;
  detail: string;
  instance: string;
};

export type ErrorMeta<TError = { [key: string]: string[] }, TErrorTile = string> = {
  status: number;
  instance: string;
  title: TErrorTile;
  errors: TError;
};

export type ResponseAPIType<T = object> = {
  data: T;
  error?: ErrorMeta;
  pagination?: PaginationMetaType;
};

export enum FileValidationError {
  IncorrectExtension,
  IncorrectColumns,
  IncorrectDataInFirst10Rows,
  IncorrectWorksheetTabName,
  IncorrectFileContent,
  IncorrectLanguage,
  FileSaveError,
  TooManyOffers,
  NoOffersInTheSystem,
  IncorrectPrice,
  ErrorUpdate,
  PriceManagementMode,
  IncorrectData,
  DuplicateOfferKey,
}

export type PriceImportResponseType = {
  errorInfoImportPrices: [
    {
      error: FileValidationError;
      errorsInfo: [{ position: number; platformProductId: string }] | null;
    },
  ];
  errors: number;
};

export type ResponseAPIDataStatusType<T = object> = {
  data: T;
  messageCode?: string;
  description?: string;
  statusCode: number;
  isSuccessful: boolean;
};

export type ResponseAPIDataStatusErrorType = {
  response: {
    data: ResponseAPIDataStatusType<void>;
  };
};

export type ErrorResponseAPIType<T = object> = {
  response: {
    data: ResponseAPIType<T>;
  };
};

export type ErrorResponseCodeAndMessageAPIType = {
  response: {
    data: {
      errorMessage: string;
      errorCode: string;
    };
  };
};
