import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { Tooltip } from '../../../../../../../../components';
import { PlatformFieldType } from '../../../../../../types';
import { RenderCell } from '../render-cell';

type RenderLabelsProps = { fields: PlatformFieldType[] };

export const RenderLabels: React.FC<RenderLabelsProps> = React.memo((props) => (
  <Stack divider={<Divider />}>
    {props.fields.map((field) => (
      <RenderCell key={field.name} width="100%">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ width: '208px', whiteSpace: 'pre-line' }}
        >
          <Typography fontSize="13px" lineHeight="14px">
            {field.title}
          </Typography>
          <Tooltip content={field.tooltip} />
        </Stack>
      </RenderCell>
    ))}
  </Stack>
));
