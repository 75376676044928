import React from 'react';
import { StatusColorGroup } from 'modules/offers/types';
import { nethansaPalette } from 'theme';
import { Chip, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  colorGroup: StatusColorGroup;
  label: string;
  sx?: SxProps<Theme>;
};

export const ChipStatus: React.FC<Props> = (props) => {
  return (
    <Chip
      size="small"
      label={props.label}
      sx={{
        backgroundColor: nethansaPalette.status[`${props.colorGroup as 'dead'}`].bg,
        color: nethansaPalette.status[`${props.colorGroup as 'dead'}`].dark,
        textTransform: 'lowercase',
        borderRadius: '6px',
        justifyContent: 'flex-start',
        fontWeight: 500,
        minWidth: 0,
        height: '20px',
        ...(props.sx || {}),
      }}
    />
  );
};
