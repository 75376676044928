import React from 'react';
import { Box, TextField } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { useCommonTranslations } from '../../../../../shared';
import { DateEnumOperatorType } from '../../../../utils';

type Props = GridFilterInputValueProps;

export const SelectDateEnumOperator: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const { item, applyValue } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef: React.Ref<any> = React.useRef(null);

  const handleFilterChange = (value?: string | null) => {
    applyValue({ ...item, value: value });
  };

  const options: { value: DateEnumOperatorType | null; children: string }[] = [
    {
      value: null,
      children: '',
    },
    {
      value: 'lastWeek',
      children: tCommon('lastWeek'),
    },
    {
      value: 'lastMonth',
      children: tCommon('lastMonth'),
    },
    {
      value: 'lastYear',
      children: tCommon('lastYear'),
    },
    {
      value: 'currentWeek',
      children: tCommon('currentWeek'),
    },
    {
      value: 'currentMonth',
      children: tCommon('currentMonth'),
    },
    {
      value: 'currentYear',
      children: tCommon('currentYear'),
    },
  ];

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 45,
        pt: 0.5,
        width: '100%',
      }}
    >
      <TextField
        id="standard-select-currency-native"
        select
        fullWidth
        label={tCommon('datagrid.operators.date.period-label')}
        placeholder=""
        value={item.value ? String(item.value) : undefined}
        onChange={(event) => handleFilterChange(event.target.value)}
        inputRef={inputRef}
        SelectProps={{
          native: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
          sx: {
            '&.MuiInputLabel-root': {
              transform: 'translate(-12px, -22px) scale(1)',
            },
          },
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value || ''}>
            {option.children}
          </option>
        ))}
      </TextField>
    </Box>
  );
};
