import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertLoader, AmazonLogo, Dialog, FormButtonsGroup, Show } from 'components';
import { addMinutes } from 'date-fns';
import { useCommonTranslations } from 'modules/shared';
import { PlatformsConnectorContext } from 'modules/wizard-platforms-connector/contexts/platfroms-modal.provider';
import { useAmazonPlatformCheckQuery } from 'modules/wizard-platforms-connector/hooks/use-amazon-platform-check.query';
import { nethansaPalette } from 'theme';
import { Alert, Box, Button, DialogContent, Typography } from '@mui/material';
import { PlatformConnectorHeader } from '../components';
import {
  // usePlatformConnectionMutation,
  usePlatformQuery,
  usePlatformsSettingsQuery,
  usePlatformIsActiveMutation,
} from '../hooks';

type Props = {
  onClose: () => void;
};

const AMAZON_AUTH_ENDPOINT = '/SpApiAuthorization/AuthorizeFromApplication';

export const AmazonConnectorModal: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const platformData = usePlatformsSettingsQuery();
  const platformQuery = usePlatformQuery('AMZEU');
  const platformAsActiveMutation = usePlatformIsActiveMutation();
  const platformCheckQuery = useAmazonPlatformCheckQuery();

  const [outsideAuthorizing, setOutsideAuthorizing] = useState(false);
  const context = useContext(PlatformsConnectorContext);

  const onAuthorizeClick = async () => {
    if (!(window && window.open)) return;
    window.open(AMAZON_AUTH_ENDPOINT, '__blank');
    setOutsideAuthorizing(true);

    const endTime = addMinutes(new Date(), 5).getTime();

    checkIfAmazonIsAuthorized(
      endTime,
      () => {
        // Wait until done
        setOutsideAuthorizing(false);
        context.setStep(1);
      },
      () => {
        console.log('ERROR');
      },
    );
  };

  const checkIfAmazonIsAuthorized = async (
    endTime: number,
    done?: () => void,
    timeout?: () => void,
  ) => {
    const response = await platformCheckQuery.refetch();

    if (response.data?.hasConnectedAmazonPlatform) {
      done && done();

      if (platformQuery.data?.platformId) {
        platformAsActiveMutation.mutate({
          platformId: platformQuery.data?.platformId,
          isActive: true,
        });

        platformQuery.refetch();
        platformData.refetch();
      }

      return;
    }

    if (endTime < Date.now()) {
      timeout && timeout();
      return;
    }

    setTimeout(() => {
      checkIfAmazonIsAuthorized(endTime, done, timeout);
    }, 3000);
  };

  const handleClose = () => {
    props.onClose();
    platformQuery.refetch();
    platformData.refetch();
  };

  const areCredentialsValid: boolean =
    (platformCheckQuery.isFetched && platformCheckQuery.data?.hasConnectedAmazonPlatform) || false;

  return (
    <Dialog
      open
      onClose={props.onClose}
      sx={{
        '& .MuiDialogContent-root': {
          maxWidth: 'unset',
          minWidth: 'unset',
          width: '750px',
        },
      }}
    >
      <DialogContent>
        <Box sx={{ p: 4 }}>
          <PlatformConnectorHeader logo={<AmazonLogo />} close={props.onClose} />
          <Box sx={{ mt: 4 }}>
            {!platformQuery.data?.sellerId && (
              <>
                <Typography sx={{ mb: 3 }} variant="h2">
                  {t('amazon.integrate.title')}
                </Typography>
                <Typography sx={{ mb: 3 }} variant="h5">
                  {t('amazon.integrate.description')}
                </Typography>
                <Alert severity="info" sx={{ mb: 3 }}>
                  <span>{t('amazon.subtitle')}</span>
                </Alert>
              </>
            )}
            {platformQuery.data?.sellerId && (
              <>
                <Typography sx={{ mb: 3 }} variant="h2">
                  {t('amazon.reconnect.title')}
                </Typography>

                <Show when={platformCheckQuery.isLoading}>
                  <AlertLoader>{t('amazon.reconnect.pending')}</AlertLoader>
                </Show>
                <Show when={platformAsActiveMutation.isSuccess}>
                  <Alert severity="success">{t('amazon.integrate.success')}</Alert>
                </Show>
                <Show when={platformAsActiveMutation.isLoading}>
                  <AlertLoader>{t('amazon.integrate.loading')}</AlertLoader>
                </Show>

                <Show when={areCredentialsValid}>
                  <Alert severity="success">{t('amazon.reconnect.valid-credentials')}</Alert>
                </Show>

                <Show
                  when={
                    (platformCheckQuery.isError || platformAsActiveMutation.isError) &&
                    !areCredentialsValid
                  }
                >
                  <Alert severity="info">{t('amazon.subtitle')}</Alert>
                </Show>
                <Show
                  when={
                    (platformCheckQuery.isError || platformAsActiveMutation.isError) &&
                    areCredentialsValid
                  }
                >
                  <Alert severity="error">{t('amazon.integrate.error.general')}</Alert>
                </Show>
              </>
            )}
          </Box>
        </Box>
        <FormButtonsGroup
          isLoading={outsideAuthorizing || platformCheckQuery.isLoading || platformData.isLoading}
          sx={{ py: 2, px: 4, borderTop: `1px solid ${nethansaPalette.line.light}` }}
        >
          {!platformAsActiveMutation.isSuccess && (
            <>
              <Button
                variant="text"
                size="small"
                onClick={props.onClose}
                disabled={
                  platformData.isLoading || platformCheckQuery.isLoading || outsideAuthorizing
                }
              >
                {tCommon('cancel')}
              </Button>
              <Button
                size="small"
                type="submit"
                onClick={onAuthorizeClick}
                disabled={
                  platformData.isLoading ||
                  platformCheckQuery.isLoading ||
                  outsideAuthorizing ||
                  areCredentialsValid
                }
              >
                {t('amazon.connect')}
              </Button>
            </>
          )}
          {platformAsActiveMutation.isSuccess && (
            <Button
              size="small"
              type="button"
              onClick={handleClose}
              disabled={
                platformData.isLoading || platformCheckQuery.isLoading || outsideAuthorizing
              }
            >
              {tCommon('ok')}
            </Button>
          )}
        </FormButtonsGroup>
      </DialogContent>
    </Dialog>
  );
};
