import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseDate } from 'common/format/date';
import { SelectField, Show } from 'components';
import { SpinnerIcon } from 'components/icons';
import { intervalToDuration, startOfDay, subDays } from 'date-fns';
import * as echarts from 'echarts/core';
import { SharedMonitoredOfferState } from 'generated/graphql';
import { nethansaPalette } from 'theme';
import { Alert, Box, MenuItem, MenuList, Stack } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { useMonitoringOfferHistoryQuery } from '../hooks';
import { initMonitoringChart, setMonitoringChartOption } from '../utils';

type Props = {
  row?: GridRowModel;
};

export const DetailPanelChart: React.FC<Props> = (props) => {
  const [t, i18n] = useTranslation('monitoring');

  const lastDaysPeriod = [0, 7, 14, 30];
  const [menuOption, setMenuOption] = useState(0);
  const [lastDaysPeriodOption, setlastDaysPeriodOption] = useState<number>(lastDaysPeriod[0]);
  const [refWarning, setRefWarning] = useState<string>('');
  const [chartWarning, setChartWarning] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);

  const { data, isFetched } = useMonitoringOfferHistoryQuery((props.row?.id as string) || '');

  const days = intervalToDuration({
    start: new Date((data && data.length && data[0].updatePricingData) || 0),
    end: new Date((data && data.length && data.slice(-1)[0].updatePricingData) || 0),
  });

  const isRank: boolean = !!data && data.filter((el) => el.ranking).length > 0;
  const isPrice: boolean = !!data && data.filter((el) => el.lowestPrice).length > 0;

  const getPeriodData = (period: number) => {
    if (period === lastDaysPeriod[0]) return data;

    const subDate = startOfDay(subDays(new Date(), period));
    const periodData = (data || []).filter(
      (el) => parseDate(el.updatePricingData || String(Date.now())) >= subDate,
    );

    return periodData;
  };

  useEffect(() => {
    if (!ref.current) return;

    setMenuOption(1);

    if ((days.days || 0) > 0) {
      initMonitoringChart(ref.current, i18n.language.toUpperCase());
    } else {
      setRefWarning(t('detail-panel.not-enough-data'));
    }
  }, [isFetched]);

  useEffect(() => {
    if (!data || !ref.current) return;

    if (data.length === 0 && props.row?.state !== SharedMonitoredOfferState.Active) {
      const status = t(`column.state.${props.row?.state as 'ACTIVE'}`);
      setRefWarning(t('detail-panel.no-data', { status: status || 'unknown' }));
      return;
    }

    echarts.getInstanceByDom(ref.current)?.clear();

    if (menuOption === 1 && !isRank) {
      setChartWarning(t('detail-panel.no-ranking'));
      return;
    }

    if (menuOption === 2 && !isPrice) {
      setChartWarning(t('detail-panel.no-price'));
      return;
    }

    setChartWarning('');
    setMonitoringChartOption(
      ref.current,
      getPeriodData(lastDaysPeriodOption) || [],
      t,
      menuOption,
      props.row?.currencyCode,
    );
  }, [menuOption]);

  return (
    <Stack sx={{ flexDirection: 'row', height: '100%' }}>
      <Stack
        component={'aside'}
        sx={{
          bgcolor: nethansaPalette.main.light,
          minWidth: '222px',
          py: 2,
          ml: 1,
          justifyContent: 'space-between',
        }}
      >
        <MenuList
          sx={{
            width: '100%',
            '& .MuiMenuItem-root': {
              fontSize: '14px',
              lineHeight: '28px',
              paddingLeft: '16px',
            },
            '& .Mui-selected': {
              bgcolor: `${nethansaPalette.background.titleDark} !important`,
              fontWeight: 700,
              borderLeft: `4px solid ${nethansaPalette.main.active}`,
              paddingLeft: '12px',
              '&:hover': {
                bgcolor: `${nethansaPalette.background.group} !important`,
              },
            },
          }}
        >
          {[1, 2, 3].map((num) => {
            return (
              <MenuItem
                disabled={!isFetched || !!refWarning}
                key={num}
                onClick={() => {
                  setMenuOption(num);
                }}
                selected={menuOption === num}
                children={t(`detail-panel.menu.option-${String(num) as '1'}`)}
              />
            );
          })}
        </MenuList>
        <SelectField
          disabled={!isFetched || !!refWarning || !!chartWarning}
          value={String(lastDaysPeriodOption)}
          onChange={(value) => {
            setlastDaysPeriodOption(Number(value));

            if (!data || !ref.current) return;

            setMonitoringChartOption(
              ref.current,
              getPeriodData(Number(value)) || [],
              t,
              menuOption,
              props.row?.currencyCode,
            );
          }}
          onBlur={(value) => setlastDaysPeriodOption(Number(value))}
          sx={{ px: 1 }}
          options={lastDaysPeriod.map((num) => ({
            value: String(num),
            disabled: (days.days || 0) < num,
            children:
              num === 0
                ? t('detail-panel.period-all-days')
                : t('detail-panel.period-last-days', { num: num }),
          }))}
        />
      </Stack>
      <Show
        when={isFetched}
        fallback={
          <Stack
            sx={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}
          >
            <SpinnerIcon sx={{ fontSize: '100px' }} />
          </Stack>
        }
      >
        <Box ref={ref} sx={{ p: 2, width: '100%', position: 'relative' }}>
          <Show when={!!chartWarning || !!refWarning}>
            <Alert
              severity="warning"
              sx={{ position: 'absolute', top: '32px', left: '32px', width: 'calc(100% - 64px)' }}
            >
              {chartWarning || refWarning}
            </Alert>
          </Show>
        </Box>
      </Show>
    </Stack>
  );
};
