import React from 'react';
import { DatagridWrapperPage, GridTableIds } from 'modules/datagrid';
import { ProductsModalsCoordinator } from '.';
import { TableProducts } from './components/table-products';
import { useProductsColumnsDefinition } from './hooks';

export const ProductsPage: React.FC = () => {
  const columns = useProductsColumnsDefinition();
  const parsedColumns = columns.map((x) => x.field);

  return (
    <ProductsModalsCoordinator>
      <DatagridWrapperPage
        columns={parsedColumns}
        tableId={GridTableIds.Products}
        initialData={{ itemsPerPage: 50 }}
      >
        <TableProducts />
      </DatagridWrapperPage>
    </ProductsModalsCoordinator>
  );
};
