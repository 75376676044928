import React, { useMemo } from 'react';
import { setUrlToHigherResolution } from 'common/utils';
import { ImagePreview, ProductImage } from 'components';

type Props = {
  src: string;
  provider: 'amazon' | 'kaufland';
};

export const DatagridCellImage: React.FC<Props> = (props) => {
  const urlImage = useMemo(
    () => [
      setUrlToHigherResolution(props.src, props.provider),
      setUrlToHigherResolution(props.src, props.provider, 75),
    ],
    [props.src, props.provider],
  );

  return (
    <ImagePreview imageSrc={urlImage[0]}>
      <ProductImage src={urlImage[1]} alt="offer-image" width={75} />
    </ImagePreview>
  );
};
