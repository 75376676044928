import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import { settingsDefaultMutationKeys } from 'common/query-client';
import { useAuth } from 'modules/authorization';
import { ErrorMeta } from 'modules/shared/types';
import { PlatformIsActiveInput } from '../types';

type InputType = PlatformIsActiveInput;

export const usePlatformIsActiveMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    const parsedData = {
      ...body,
    };

    return await envelope(
      httpClient.put(API.salesIntelligence(currentPartnerId).platformIsActive(), parsedData),
    );
  });
};
