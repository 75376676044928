import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NAVIGATION_APP_HEIGHT, MODULE_NAVIGATION_BAR_HEIGHT } from 'theme';
import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import { BlockMainRenderWithDelay, CardForm } from '../../../../components';
import { SpinnerIcon } from '../../../../components/icons';
import { ClaimDefenderComponent } from '../../../authorization';
import { PlatformType } from '../../../shared/types';
import { useSnackbar } from '../../../snackbar';
import { usePlatformMutation, usePlatformQuery } from '../../hooks';
import { PlatformInput, PlatformPartnerChannelMarketplace } from '../../types';
import { SectionActionButtons } from './components/section-action-buttons';
import { SectionMainSettings } from './components/section-main-settings';
import { SectionMarketplaces } from './components/section-marketplaces';

type Props = {
  platformType: PlatformType;
};

export const Platform: React.FC<Props> = (props) => {
  const [t] = useTranslation('platforms');
  const { data: platformData } = usePlatformQuery(props.platformType);
  const platformMutation = usePlatformMutation();
  const { pushSnackbar } = useSnackbar();

  const form = useForm<PlatformInput>({
    mode: 'onBlur',
    defaultValues: {
      isAutoStock: platformData?.isAutoStock,
      marketplaces: (platformData?.partnerChannels || []).reduce<
        Omit<PlatformPartnerChannelMarketplace, 'countryCode' | 'isAutoStock'>[]
      >((acc, v) => {
        acc = [...acc, ...v.partnerMarketplaces];
        return acc;
      }, []),
    },
  });

  const submit = (data: PlatformInput) => {
    return platformMutation.mutate(data, {
      onSuccess: () => {
        pushSnackbar({ message: t('success.successfulUpdate'), variant: 'success' });
        form.reset(data);
      },
      onError: (error) => {
        const errorMessage =
          error.title === 'unexpectedError'
            ? t('errors.unexpectedError')
            : t('errors.validationError');

        pushSnackbar({ message: errorMessage, variant: 'error' });

        Object.entries<string[]>(error.errors).forEach(([key, errors]) => {
          const splitKey = key.split('.');
          const parsedKey = `${splitKey[0]}.${splitKey[1][0].toLowerCase()}${splitKey[1].substring(
            1,
          )}`;

          errors.forEach((e) => {
            const errorKey = e as 'updatePartnerMarketplaceError';
            form.setError(parsedKey as keyof PlatformInput, {
              type: t('errors.validationError'),
              message: t(`errors.${errorKey}`),
            });
          });
        });
      },
    });
  };

  const clientId = useMemo(
    () => (props.platformType === 'AMZEU' ? platformData?.sellerId : platformData?.clientKey),
    [props.platformType],
  );

  const clientSecretKey = useMemo(
    () => (props.platformType === 'AMZEU' ? platformData?.authToken : platformData?.secretKey),
    [props.platformType],
  );

  const typographyLabelProps: TypographyProps = {
    variant: 'h4',
    textTransform: 'uppercase',
    fontSize: '12px',
    sx: { color: 'nethansa.main.gray', pb: 4 },
  };

  const formStyle: React.CSSProperties = {
    display: 'inline',
  };

  return (
    <FormProvider {...form}>
      <Box
        sx={{
          height: `calc(100vh - ${NAVIGATION_APP_HEIGHT} - ${MODULE_NAVIGATION_BAR_HEIGHT} - 60px)`,
          overflow: 'auto',
        }}
      >
        <ClaimDefenderComponent
          showInfoBanner
          allowed={'SETTINGS_SALES_INTELLIGENCE_PLATFORMS_EDIT'}
        >
          <form onSubmit={form.handleSubmit(submit)} style={formStyle}>
            <CardForm fullWidth sx={{ maxWidth: '1160px', position: 'sticky', left: 0 }}>
              <Typography {...typographyLabelProps}>{t('page.section.main-settings')}</Typography>
              <Box sx={{ px: 2 }}>
                <SectionMainSettings client={clientId} secret={clientSecretKey} />
              </Box>
            </CardForm>
            <CardForm fullWidth sx={{ pt: 0, display: 'inline-block', minWidth: '1160px' }}>
              <Typography {...typographyLabelProps}>
                {t('page.section.marketplace-settings')}
              </Typography>
              <BlockMainRenderWithDelay
                fallback={
                  <Stack sx={{ width: '100%', alignItems: 'center' }}>
                    <SpinnerIcon sx={{ fontSize: '50px' }} />
                  </Stack>
                }
              >
                <Box sx={{ display: 'flex', position: 'relative' }}>
                  <Box sx={{ position: 'relative' }}>
                    <SectionMarketplaces />
                    <ClaimDefenderComponent
                      hidden
                      allowed={'SETTINGS_SALES_INTELLIGENCE_PLATFORMS_EDIT'}
                    >
                      <SectionActionButtons
                        isLoading={platformMutation.isLoading}
                        onReset={() => form.reset()}
                      />
                    </ClaimDefenderComponent>
                  </Box>
                </Box>
              </BlockMainRenderWithDelay>
            </CardForm>
          </form>
        </ClaimDefenderComponent>
      </Box>
    </FormProvider>
  );
};
