import React, { useRef } from 'react';
import { nethansaPalette } from 'theme';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { UploadIcon } from '../icons';

type Props = {
  value?: File;
  onChange?: (file?: File) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  label?: string;
  accept?: string | undefined;
  buttonLabel?: string;
  isFlat?: boolean;
};

export const FileField: React.FC<Props> = (props) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length || !props.onChange) return;
    props.onChange(event.target.files[0]);
  };

  const bgColor = props.isFlat ? nethansaPalette.button.grayLight : nethansaPalette.main.light;

  return (
    <Box sx={{ mt: 1 }}>
      <Box
        sx={{
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '14.52px',
          letterSpacing: '0.22px',
          textTransform: 'uppercase',
          color: 'nethansa.main.gray',
          mb: 1,
        }}
      >
        {props.label}
      </Box>
      <Stack
        direction="row"
        onClick={() => ref.current?.click()}
        sx={{ cursor: props.disabled ? 'default' : 'pointer' }}
      >
        <TextField
          disabled={props.disabled}
          sx={{ minWidth: '510px', width: '100%' }}
          value={props.value?.name || ''}
          error={props.error}
          helperText={props.helperText}
          InputProps={{
            sx: {
              pointerEvents: 'none',
              '&.MuiInputBase-adornedEnd': {
                paddingRight: 0,
              },
              '& + .MuiFormHelperText-root': {
                position: props.isFlat ? 'static' : 'absolute',
                textAlign: props.isFlat ? 'left' : 'right',
              },
              justifyContent: 'space-between',
            },
            endAdornment: (
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  px: 2,
                  height: '40px',
                  borderLeftWidth: '1px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: props.error ? 'error.main' : 'flat.main',
                  borderRadius: '4px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  backgroundColor: props.disabled ? 'background.default' : bgColor,
                }}
              >
                <Typography
                  variant="smallUppercase"
                  sx={{ color: props.disabled ? nethansaPalette.main.gray : 'common.black', pr: 1 }}
                >
                  {props.buttonLabel}
                </Typography>
                <UploadIcon
                  sx={{
                    fontSize: '12px',
                    color: props.disabled ? nethansaPalette.main.gray : 'common.black',
                  }}
                />
              </Stack>
            ),
          }}
        />
      </Stack>
      <Box sx={{ opacity: 0, position: 'absolute' }}>
        <input
          ref={ref}
          disabled={props.disabled}
          type="file"
          onChange={handleChange}
          accept={props.accept}
        />
      </Box>
    </Box>
  );
};
