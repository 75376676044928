import React from 'react';
import { UnitOfSizeType, UnitOfWeightType } from 'modules/shared/types';
import { Box, Stack } from '@mui/material';
import {
  inchesToUnit,
  parseLengthUnit,
  parseWeightUnit,
  poundsToUnit,
} from '../../../../common/utils/converters';

type Props = {
  value: number | string | undefined | (number | string | undefined)[];
  type: 'weight' | 'dimensions';
  unit?: UnitOfSizeType | UnitOfWeightType;
  reverse?: boolean;
};

export const DatagridCellValueConverter: React.FC<Props> = (props) => {
  if (typeof props.value === 'undefined') {
    return <Box sx={{ color: 'nethansa.button.grayHover' }}>—</Box>;
  }

  const parseValue = (val: number | string | undefined): string => {
    if (props.type === 'weight')
      return val ? poundsToUnit(Number(val), props.unit as UnitOfWeightType) : '-';
    if (props.type === 'dimensions')
      return val ? inchesToUnit(Number(val), props.unit as UnitOfSizeType) : '-';
    return val ? Number(val).toFixed(2) : '-';
  };

  const rawValue =
    typeof props.value === 'object'
      ? props.value.map((x) => parseValue(x))
      : parseValue(props.value);

  const value =
    typeof rawValue === 'object' ? (
      <>
        {(rawValue || []).map((x, index) => (
          <Box key={index}>{x}</Box>
        ))}
      </>
    ) : (
      rawValue
    );

  const unit =
    props.type === 'weight'
      ? parseWeightUnit(props.unit as UnitOfWeightType)
      : parseLengthUnit(props.unit as UnitOfSizeType);

  return (
    <Stack direction={props.reverse ? 'row-reverse' : 'row'} spacing={0.5}>
      <Stack
        direction="column"
        spacing={0.5}
        alignItems={props.reverse ? 'flex-end' : 'flex-start'}
      >
        {value}
      </Stack>
      <Box sx={{ color: 'nethansa.button.grayHover' }}>{unit}</Box>
    </Stack>
  );
};
