import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DatagridFooter,
  mapSelectedProductIdsToFilter,
  useDatagridContext,
  useDatagridFiltersToGraphqlTranslator,
} from 'modules/datagrid';
import { BulkActionsProps } from 'modules/datagrid/types';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { MonitoredOfferGridDtoFilterInput } from '../../../generated/graphql';
import { searchableMonitoringColumns, useMonitoringColumnsDefinition } from '../hooks';
import { useMonitoringCoordinatorContext } from '../hooks/use-monitoring-coordinator-context';
import { useMonitoringTotalCountQuery } from '../hooks/use-monitoring-count.query';
import { MonitoredOfferGridColDefCustom, SelectedMonitoring } from '../types';

type Action = 'deleteOffers' | 'editTags';

export const TableMonitoringFooterBulkActions: React.FC = () => {
  const [t] = useTranslation('monitoring');
  const apiRef = useGridApiContext();
  const { bulkActionType, filter, search, rowCount } = useDatagridContext();
  const columns = useMonitoringColumnsDefinition();

  const offersTotalCountQuery = useMonitoringTotalCountQuery(
    'monitoring-total-all-count',
    columns,
    {
      items: [],
    },
  );

  const {
    deleteOffers: [, setDeleteOffers],
    editTags: [, setEditTags],
  } = useMonitoringCoordinatorContext();

  const handleActionSuccess = () => {
    apiRef.current.setRowSelectionModel([]);
    bulkActionType[1]('manually');
  };

  const bulkActionProps: BulkActionsProps<Action> = useMemo(
    () => ({
      bulkActions: [
        { label: t('actions.delete'), value: 'deleteOffers' },
        { label: t('actions.edit-tags'), value: 'editTags' },
      ],
      handleBulkAction: (actionType, selectedIds) => {
        const ids = (selectedIds || []) as string[];

        let selectedCount = ids.length;
        if (bulkActionType[0] === 'all') selectedCount = rowCount[0];

        const gridFilters =
          bulkActionType[0] !== 'all'
            ? mapSelectedProductIdsToFilter<MonitoredOfferGridDtoFilterInput>(ids)
            : useDatagridFiltersToGraphqlTranslator<
                MonitoredOfferGridColDefCustom,
                MonitoredOfferGridDtoFilterInput
              >(filter[0], columns, searchableMonitoringColumns, undefined, search[0]);

        const selectedMonitoring: SelectedMonitoring = {
          filters: gridFilters.filter,
          selectedCount: selectedCount,
          onSuccess: handleActionSuccess,
        };

        const obj: Record<Action, () => void> = {
          deleteOffers(): void {
            setDeleteOffers(selectedMonitoring);
          },
          editTags(): void {
            setEditTags(selectedMonitoring);
          },
        };
        obj[actionType]();
      },
    }),
    [handleActionSuccess],
  );

  return (
    <DatagridFooter
      entitiesType="offers"
      totalAllCount={offersTotalCountQuery.data}
      {...(bulkActionProps as BulkActionsProps)}
    />
  );
};
