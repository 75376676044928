import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Show } from '../../components';
import { ClaimDefenderComponent } from '../authorization';
import { SectionVatRates, SectionVatRatesSkeleton } from './components';
import { useVatRatesQuery } from './hooks/use-vat-rates.query';

export const SettingsSalesVatRatesPage: React.FC = () => {
  const [t] = useTranslation('wizard');
  const { isFetched } = useVatRatesQuery();

  return (
    <ClaimDefenderComponent showInfoBanner allowed={'SETTINGS_SALES_INTELLIGENCE_VAT_RATES_EDIT'}>
      <Box sx={{ maxWidth: '720px', overflow: 'auto' }}>
        <Box sx={{ p: 3, pb: 0 }}>
          <Typography variant="h5" sx={{ mb: 0 }}>
            {t('set-vat-and-shipping-step.description')}
          </Typography>
        </Box>
        <Show when={isFetched} fallback={<SectionVatRatesSkeleton />}>
          <SectionVatRates />
        </Show>
      </Box>
    </ClaimDefenderComponent>
  );
};
