import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { PlatformType } from 'modules/shared/types';
import { WizardPlatformsDisconnectModal } from '../../wizard-platforms-connector';
import { usePlatformDisconnectMutation, usePlatformsSettingsQuery } from '../hooks';
import { AmazonConnectorModal, KauflandConnectorModal, GenericModal } from '../modals';
import { PlatformsContextType } from '../types';

export const PlatformsContext = React.createContext<PlatformsContextType>({});

type Props = React.PropsWithChildren<unknown>;

export const PlatformsContextProvider: React.FC<Props> = (props) => {
  const { isFetched, data, refetch } = usePlatformsSettingsQuery();
  const platformDisconnectMutation = usePlatformDisconnectMutation();

  const tab = useState<PlatformType | undefined>(undefined);
  const connector = useState<PlatformType | undefined>();
  const modal = useState<ReactNode | undefined>();
  const disconnectPlatform = useState<PlatformType | undefined>();

  const handleCloseDisconnectModal = useCallback(
    (confirm: boolean) => {
      disconnectPlatform[1](undefined);
      if (!disconnectPlatform[0] || !confirm) return;
      platformDisconnectMutation.mutate(
        { platformCode: disconnectPlatform[0] },
        {
          onSettled: () => {
            refetch();
          },
        },
      );
    },
    [disconnectPlatform[0], disconnectPlatform[1]],
  );

  useEffect(() => {
    if (tab[0] || !data) return;
    const activePlatform = data.filter((x) => x.isActive)[0];
    if (activePlatform) {
      tab[1](activePlatform.platformCode);
    }
  }, [isFetched, data, tab[0]]);

  const value: PlatformsContextType = useMemo(
    () => ({
      tab,
      connector,
      modal,
    }),
    [tab[0]],
  );

  return (
    <PlatformsContext.Provider value={value}>
      {props.children}
      {connector?.[0] === 'AMZEU' && (
        <AmazonConnectorModal onClose={() => connector[1](undefined)} />
      )}
      {connector?.[0] === 'KAUF' && (
        <KauflandConnectorModal onClose={() => connector[1](undefined)} />
      )}
      {modal?.[0] && <GenericModal>{modal?.[0]}</GenericModal>}
      <WizardPlatformsDisconnectModal
        open={!!disconnectPlatform[0]}
        onClose={handleCloseDisconnectModal}
      />
    </PlatformsContext.Provider>
  );
};
