import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from '../../../../common/api';
import { ErrorMeta, ResponseAPIDataStatusType } from '../../../shared/types';

type InputType = {
  viewId: string;
  gridConfiguration: string;
};

export const useViewEditGridConfigurationMutation = (): UseMutationResult<
  void,
  ErrorMeta,
  InputType
> => {
  return useMutation(async (body: InputType) => {
    await httpClient.patch<ResponseAPIDataStatusType>(
      API.views().viewEditGridConfiguration(body.viewId),
      { gridConfiguration: body.gridConfiguration },
    );
    return;
  });
};
