import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { CloseIcon } from 'components/icons';
import { useRawPriceCalculationQuery } from 'modules/offers/hooks/use-raw-price-calculation.query';
import BarChartIcon from '@mui/icons-material/BarChart';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyIcon from '@mui/icons-material/Key';
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useOfferCoordinatorContext } from '../../hooks';

export const RawPriceCalculationModal: React.FC = () => {
  const [t] = useTranslation('offers');
  const { isFetched, data, isLoading, isError, isSuccess } = useRawPriceCalculationQuery();

  const {
    rawPriceCalculation: [id, setOfferId],
  } = useOfferCoordinatorContext();

  const json = JSON.parse(data || '{}');

  const idRef = useRef<TextFieldProps>();
  const onClick = async () => {
    if (idRef.current != null) await navigator.clipboard.writeText(idRef.current?.value as string);
  };

  const width = 640;
  const height = 400;

  function tableData(key, value, path = '') {
    let result = Array<JSX.Element>();

    if (typeof value !== 'object' || value === null) {
      const line = (
        <TableRow key={key}>
          <TableCell padding="none" sx={{ pl: 2 }} align="left">
            {path + key}
          </TableCell>
          <TableCell padding="none" align="left">
            {value === null ? 'null' : value.toString()}
          </TableCell>
        </TableRow>
      );
      result.push(line);
    } else {
      Object.keys(value)
        .sort()
        .map((skey) => {
          const line = tableData(skey, value[skey], `${path + key} > `);
          result = [...result, ...line];
        });
    }

    return result;
  }

  return (
    <Dialog open onClose={() => setOfferId(undefined)}>
      <Show when={isLoading}>
        <LinearProgress />
      </Show>
      <DialogTitle variant="h1" sx={{ pl: 0, ml: 4, pb: 0 }}>
        <Stack flexDirection="row" justifyContent="space-between">
          {t('modals.raw-price-calculation-modal.header')}
          <div>
            <IconButton
              href={`http://172.30.12.12:42061/Home/Index/${id}`}
              target="_blank"
              aria-label="delete"
            >
              <BarChartIcon />
            </IconButton>
            <IconButton onClick={() => setOfferId(undefined)}>
              <CloseIcon />
            </IconButton>
          </div>
        </Stack>
      </DialogTitle>
      <Stack sx={{ mt: 0, mb: 2, ml: 4, width }}>
        <Stack alignItems="center" justifyContent="flex-start" flexDirection="row">
          <Typography variant="h2">
            <KeyIcon />
          </Typography>
          <TextField
            inputRef={idRef}
            variant="standard"
            value={id}
            id="outlined-start-adornment"
            sx={{ m: 1, width: '52ch' }}
            aria-readonly={true}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onClick} aria-label="copy-button">
                    <ContentCopyIcon></ContentCopyIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Stack>
      </Stack>
      <Divider />
      <Show when={isError}>
        <Stack flexDirection="row" alignItems="flex-start" sx={{ ml: 4, pt: 4, height, width }}>
          <Typography variant="h3">{t('modals.raw-price-calculation-modal.error')}</Typography>
        </Stack>
      </Show>
      <Show when={isLoading}>
        <Stack flexDirection="row" alignItems="flex-start" sx={{ ml: 4, height, width }}>
          <Typography variant="h3">{t('modals.raw-price-calculation-modal.pending')}</Typography>
        </Stack>
      </Show>
      <Show when={isFetched && isSuccess}>
        <TableContainer sx={{ height, mb: 4 }}>
          <Table sx={{}} aria-label="a dense table">
            <TableBody>
              {Object.keys(json)
                .sort()
                .map((key) => tableData(key, json[key]))}
            </TableBody>
          </Table>
        </TableContainer>
      </Show>
    </Dialog>
  );
};
