import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { useAuth } from 'modules/authorization';
import { ResponseAPIType } from 'modules/shared/types';
import { ShipFromAddressType } from '../types';

type ShipFromAddressResponse = Omit<ShipFromAddressType, 'vatId'>;

export const useShipFromAddressQuery = (): UseQueryResult<ShipFromAddressResponse> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<ShipFromAddressResponse>(
    ['settings', 'carriers', 'ship-from-address'],
    async () => {
      const response = await httpClient.get<ResponseAPIType<ShipFromAddressResponse>>(
        API.partnerCarrier(currentPartnerId).address(),
      );

      return {
        ...response.data.data,
      };
    },

    {
      staleTime: 20000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
