import { useMutation, UseMutationResult } from 'react-query';
import {
  OffersBulkActionOnAmazonQuery,
  OffersBulkActionOnAmazonQueryVariables,
  OffersBulkActionOnKauflandQuery,
  OffersBulkActionOnKauflandQueryVariables,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';
import { PlatformCodeEnum } from '../types';

const amazonBulkAction = gql`
  query OffersBulkActionOnAmazon(
    $partnerId: Int!
    $amazonOperation: AmazonOperation!
    $filter: OfferGridDtoFilterInput
  ) {
    executeAmazonMassActionOnFilteredOffers(
      amazonOperation: $amazonOperation
      partnerId: $partnerId
      where: $filter
    ) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

const kauflandBulkAction = gql`
  query OffersBulkActionOnKaufland(
    $partnerId: Int!
    $kauflandOperation: KauflandOperation!
    $filter: OfferGridDtoFilterInput
  ) {
    executeKauflandMassActionOnFilteredOffers(
      kauflandOperation: $kauflandOperation
      partnerId: $partnerId
      where: $filter
    ) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

type InputType =
  | Omit<OffersBulkActionOnAmazonQueryVariables, 'partnerId'>
  | Omit<OffersBulkActionOnKauflandQueryVariables, 'partnerId'>;

export const useBulkActionOnFilteredRowsMutation = (
  platformCode?: PlatformCodeEnum,
): UseMutationResult<OffersBulkActionOnAmazonQuery, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    if (platformCode === PlatformCodeEnum.AMZEU) {
      const data = body as Omit<OffersBulkActionOnAmazonQueryVariables, 'partnerId'>;
      return await graphqlClient.request<OffersBulkActionOnAmazonQueryVariables>(amazonBulkAction, {
        partnerId: Number(currentPartnerId || '0'),
        filter: data.filter,
        amazonOperation: data.amazonOperation,
      });
    }

    const data = body as Omit<OffersBulkActionOnKauflandQueryVariables, 'partnerId'>;
    return await graphqlClient.request<OffersBulkActionOnKauflandQueryVariables>(
      kauflandBulkAction,
      {
        partnerId: Number(currentPartnerId || '0'),
        filter: data.filter,
        kauflandOperation: data.kauflandOperation,
      },
    );
  });
};

export const useAmazonBulkActionOnFilteredRowsMutation = (): UseMutationResult<
  OffersBulkActionOnAmazonQuery,
  ErrorMeta,
  InputType
> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<OffersBulkActionOnAmazonQueryVariables, 'partnerId'>;
    return await graphqlClient.request<OffersBulkActionOnAmazonQueryVariables>(amazonBulkAction, {
      partnerId: Number(currentPartnerId || '0'),
      filter: data.filter,
      amazonOperation: data.amazonOperation,
    });
  });
};

export const useKauflandBulkActionOnFilteredRowsMutation = (): UseMutationResult<
  OffersBulkActionOnKauflandQuery,
  ErrorMeta,
  InputType
> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<OffersBulkActionOnKauflandQueryVariables, 'partnerId'>;
    return await graphqlClient.request<OffersBulkActionOnKauflandQueryVariables>(
      kauflandBulkAction,
      {
        partnerId: Number(currentPartnerId || '0'),
        filter: data.filter,
        kauflandOperation: data.kauflandOperation,
      },
    );
  });
};
