import React from 'react';
import { Stack, Typography } from '@mui/material';

type Props = {
  keyLabel: string;
  value?: string | number | JSX.Element;
};

export const CardKeyValue: React.FC<Props> = (props) => {
  return (
    <Stack direction="row" spacing={1}>
      <Typography sx={{ whiteSpace: 'nowrap' }} variant="h5" color="nethansa.main.gray">
        {props.keyLabel}
      </Typography>
      <Typography variant="h5">
        {typeof props.value === 'number' ? props.value : props.value || '-'}
      </Typography>
    </Stack>
  );
};
