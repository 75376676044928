import { PlatformCodeEnum } from 'modules/offers';
import { OfferAction } from 'modules/offers/types/offer-action';
import { PlatformType, SchemaName } from 'modules/shared/types';

const VER = 'v1';

const authentication = () => {
  const root = `/${VER}/authentications`;
  return {
    tokens: () => `${root}/tokens`,
  };
};

const billings = () => {
  const root = `/${VER}/settings`;
  return {
    billingData: (partnerId: string | null) =>
      `${root}/partners/${partnerId}/billings/billing-data`,
  };
};

const offers = (partnerId: string | null) => {
  const prefix = `/${VER}/partners/${partnerId}`;
  return {
    offerAsin: (offerId: string) => `${prefix}/offers/${offerId}/asin`,
    offerPriceSettings: (offerId: string) => `${prefix}/offers/${offerId}/price-settings`,
    priceSettings: () => `${prefix}/offers/price-settings`,
    tags: () => `${prefix}/offers/tags`,
    platformOffers: (platformCode: PlatformCodeEnum) =>
      `${prefix}/platforms/${platformCode}/offers`,
    platformOffersAnalyze: (platformCode: PlatformCodeEnum) =>
      `${prefix}/platforms/${platformCode}/offers/analyze`,
    platformOffersSend: (platformCode: PlatformCodeEnum) =>
      `${prefix}/platforms/${platformCode}/offers/send`,
    offersDetailsPriceRange: (offerId: string) =>
      `${prefix}/offers/${offerId}/GetPriceAndProfitComponents`,
    offersDetailsPriceComponentsBreakdown: (offerId: string) =>
      `${prefix}/offers/${offerId}/GetPriceComponentsBreakdown`,
    rawPriceCalculation: (offerId: string) => `${prefix}/offers/${offerId}/GetRawPriceCalculation`,
    offersBySku: (sku: string) => `${prefix}/offers/${sku}/GetOffersBySku`,
    changeSku: (offerId: string) => `${prefix}/offers/${offerId}/ChangeSku`,
    pauseOrActivateOffer: (offerId: string, action: OfferAction) => {
      switch (action) {
        case OfferAction.Pause:
          return `${prefix}/offers/${offerId}/Pause`;
        case OfferAction.Activate:
          return `${prefix}/offers/${offerId}/Activate`;
        default:
          throw new Error('Unsupported operation');
      }
    },
    priceHistory: (offerId: string) => `${prefix}/offers/${offerId}/GetPriceHistory`,
    importPrices: () => `${prefix}/offers/import-prices`,
    offersPotential: () => `${prefix}/offers/potentialRanges`,
  };
};

const partnerCarrier = (partnerId: string | null) => {
  const prefix = `/${VER}/settings/partners/${partnerId}`;
  return {
    carriers: () => `${prefix}/carriers`,
    singleCarrier: (partnerCarrierId: string) => `${prefix}/carriers/${partnerCarrierId}`,
    singleCarrierDeactivate: (partnerCarrierId: string) =>
      `${prefix}/carriers/${partnerCarrierId}/deactivate`,
    address: () => `${prefix}/carriers/address`,
    shippingCountries: () => `${prefix}/carriers/shipping-countries`,
  };
};

const payments = () => {
  const root = `/${VER}/payments`;
  return {
    paymentInfo: () => `${root}/get-payment-informations`,
    adyenPaymentMethods: () => `${root}/get-payment-methods`,
    adyenSendPayment: () => `${root}/send-payment`,
  };
};

const products = (partnerId: string | null) => {
  const root = `/${VER}/partners/${partnerId}/products`;
  return {
    products: () => `${root}`,
    createProductOffers: (productId: string) => `${root}/${productId}/create-offers`,
    delete: (productId: string) => `${root}/${productId}/delete`,
    handlingTime: (productId: string) => `${root}/${productId}/handling-time`,
    purchasePrice: (productId: string) => `${root}/${productId}/purchase-price`,
    productTags: (productId: string) => `${root}/${productId}/tags`,
    vat: (productId: string) => `${root}/${productId}/vat`,
    createOffers: () => `${root}/create-offers`,
    export: () => `${root}/export`,
    tags: () => `${root}/tags`,
    import: () => `${root}/import`,
  };
};

const profiles = () => {
  const root = `/${VER}/settings/profiles`;
  return {
    profile: () => `${root}`,
    password: () => `${root}/password`,
    twoFA: () => `${root}/two-fa`,
    twoFAQRCode: () => `${root}/two-fa-qr-code`,
    twoFAStatus: () => `${root}/two-fa-status`,
  };
};

const salesIntelligence = (partnerId: string | null) => {
  const root = `/${VER}/settings/partners/${partnerId}`;
  return {
    filtersValues: () => root,
    globals: () => `${root}/globals`,
    platforms: () => `${root}/platforms`,
    platformIsActive: () => `${root}/platforms/is-active`,
    singlePlatform: (platformType: PlatformType) => `${root}/platforms/${platformType}`,
    platformManageConnection: (platform: 'amz' | 'kauf') =>
      `${root}/platforms/${platform}/manage-connection`,
    taxRates: () => `${root}/tax-rates`,
  };
};

const views = () => {
  const root = `/${VER}/reports/views`;
  return {
    viewsList: (partnerId: string | null, tableId: string) =>
      `${root}/${partnerId}?tableId=${tableId}`,
    viewAdd: (partnerId: string | null, tableId: string) =>
      `${root}/${partnerId}?tableId=${tableId}`,
    viewDelete: (viewId: string) => `${root}/${viewId}`,
    viewEditGridConfiguration: (viewId: string) => `${root}/${viewId}/editGrid`,
    viewRename: (viewId: string, tableId: string) => `${root}/${viewId}/rename?tableId=${tableId}`,
    viewShare: (viewId: string) => `${root}/${viewId}/share`,
    viewUnshare: (viewId: string) => `${root}/${viewId}/unshare`,
    viewsReorder: () => `${root}/reorder`,
  };
};

const wizard = () => {
  const root = `/${VER}/wizard`;
  const rootSteps = `${root}/steps`;
  return {
    commitChanges: () => `${root}/commit-changes`,
    status: () => `${root}/status`,
    globals: () => `${rootSteps}/globals`,
    platforms: () => `${rootSteps}/platforms`,
    singlePlatform: (platform: 'amzeu' | 'kauf') => `${rootSteps}/platforms/${platform}`,
    singlePlatformMarketplaces: (platform: 'amzeu' | 'kauf') =>
      `${rootSteps}/platforms/${platform}/marketplaces`,
    singlePlatformTestConnection: (platform: 'amzeu' | 'kauf') =>
      `${rootSteps}/platforms/${platform}/test-connection`,
    products: () => `${rootSteps}/products`,
    productsUploadFile: () => `${rootSteps}/products/upload-file`,
    vatAndShipping: () => `${rootSteps}/vat-and-shipping`,
  };
};

const partner = (partnerId: string | null) => {
  const root = `/${VER}/partner/${partnerId}`;
  return {
    dataExport: (schemaName: SchemaName) => `${root}/data-export/${schemaName}`,
  };
};

const monitoring = (partnerId: string | null) => {
  const root = `/${VER}/partners/${partnerId}/monitored-offers`;
  return {
    create: () => `${root}/create`,
    history: (offerId: string) => `/${VER}/monitored-offers/${offerId}/GetOfferHistory`,
  };
};

const cms = (language: string | null) => {
  const root = `/${VER}/cms/${language}`;
  return {
    tutorials: () => `${root}/tutorials`,
  };
};

export const API = {
  authentication,
  billings,
  offers,
  partnerCarrier,
  payments,
  products,
  profiles,
  salesIntelligence,
  views,
  wizard,
  partner,
  monitoring,
  cms,
};
