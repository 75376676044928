import { useQuery, UseQueryResult } from 'react-query';
import { CurrencyCode, ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import {
  OfferPriceSettingsParams,
  PriceSettingsDataType,
  SinglePriceSettingsParamsType,
} from '../types';
import { useOfferCoordinatorContext } from './use-offer-coordinator-context';

type PriceSettingsDataResponse = {
  isSingleOffer: boolean;
  defaultPriceSettings: PriceSettingsDataType;
  offerPriceSettings: PriceSettingsDataType;
  offerPriceSettingsParams: OfferPriceSettingsParams;
  partnerBaseCurrencyCode: CurrencyCode;
};

export const usePriceSettingsQuery = (): UseQueryResult<PriceSettingsDataResponse> => {
  const { authorized, currentPartnerId } = useAuth();
  const {
    priceSettings: [data],
  } = useOfferCoordinatorContext();

  const offerId = Object.hasOwn(data || {}, 'offerId')
    ? (data as SinglePriceSettingsParamsType).offerId
    : [];

  return useQuery<PriceSettingsDataResponse>(
    ['offers', 'price-settings', currentPartnerId, offerId],
    async () => {
      if (typeof offerId === 'string') {
        const response = await httpClient.get<ResponseAPIType<PriceSettingsDataResponse>>(
          API.offers(currentPartnerId).offerPriceSettings(encodeURIComponent(offerId)),
        );
        return { ...response.data.data, isSingleOffer: true };
      } else {
        const response = await httpClient.get<ResponseAPIType<PriceSettingsDataResponse>>(
          API.offers(currentPartnerId).priceSettings(),
        );
        return { ...response.data.data, isSingleOffer: false };
      }
    },

    {
      staleTime: 5000,
      keepPreviousData: false,
      enabled: authorized,
    },
  );
};
