import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from 'components';
import { Stack, Typography } from '@mui/material';
import { useOfferASINQuery } from '../../../../hooks';
import { CardKeyValue } from '../card-key-value';
import { CardWithLabel } from '../card-with-label';

export const ProductData: React.FC = () => {
  const { t } = useTranslation('offers');
  const { t: tPlatform } = useTranslation('platforms');
  const { data } = useOfferASINQuery();

  return (
    <Stack spacing={3} sx={{ pl: 4 }}>
      <Typography variant="h5" sx={{ lineHeight: '18px' }}>
        {t('modals.edit-asin-modal.product.description')}
      </Typography>

      {data && (
        <Stack direction="row" spacing={1}>
          <FlagIcon country={data.marketplaceCode} />
          <Typography variant="h5">
            <Stack>
              {data.platformCode && (
                <>
                  {data.platformCode === 'KAUF'
                    ? tPlatform('kaufland.website')
                    : tPlatform(
                        `amazon.markets.website.${data.marketplaceCode}` as 'amazon.markets.website.DE',
                      )}
                </>
              )}
              {data.channelCode && (
                <>
                  -{' '}
                  {t(
                    `modals.edit-asin-modal.product.fulfilled-by.${data.channelCode}` as 'modals.edit-asin-modal.product.fulfilled-by.AMZFBM',
                  )}
                </>
              )}
            </Stack>
          </Typography>
        </Stack>
      )}

      <CardWithLabel roundedCardTop label={t('modals.edit-asin-modal.product.data-label')}>
        <CardKeyValue keyLabel={t('label.ean')} value={data?.product.ean} />
        <CardKeyValue keyLabel={t('label.external-id')} value={data?.product.partnerProductId} />
        <CardKeyValue
          keyLabel={t('label.purchase-price')}
          value={`${data?.product.purchasePrice || '-'} ${
            data?.product.purchasePriceCurrency || ''
          }`}
        />
        <CardKeyValue keyLabel={t('label.product-name')} value={data?.product.name} />
      </CardWithLabel>
    </Stack>
  );
};
