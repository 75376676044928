import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryCreateOffersArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

const amazonBulkAction = gql`
  query createOffers(
    $partnerId: String!
    $amzFba: [String!]!
    $amzFbm: [String!]!
    $commonSku: Boolean!
    $kaufland: Boolean!
    $where: ProductGridDtoFilterInput
  ) {
    createOffers(
      partnerId: $partnerId
      amzFba: $amzFba
      amzFbm: $amzFbm
      commonSku: $commonSku
      kaufland: $kaufland
      where: $where
    ) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

type InputType = Omit<QueryCreateOffersArgs, 'partnerId'>;

export const useBulkCreateOffersMutation = (): UseMutationResult<Query, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryCreateOffersArgs, 'partnerId'>;
    return await graphqlClient.request<QueryCreateOffersArgs>(amazonBulkAction, {
      partnerId: currentPartnerId || '0',
      where: data.where,
      amzFba: data.amzFba,
      amzFbm: data.amzFbm,
      commonSku: data.commonSku,
      kaufland: data.kaufland,
    });
  });
};
