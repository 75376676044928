import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from 'common/api';

type InputType = {
  mainShippingCountry: string;
  baseCurrency: string;
};

export const useWizardGlobalsMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.put<null, null, InputType>(API.wizard().globals(), {
      baseCurrency: body.baseCurrency,
      mainShippingCountry: body.mainShippingCountry,
    });
    return;
  });
};
