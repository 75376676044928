import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { API, httpClient } from 'common/api';
import { Show } from 'components';
import { SpinnerIcon } from 'components/icons';
import { environment } from 'environment';
import { Money } from 'modules/offers/types';
import { usePaymentInfoQuery } from 'modules/settings-billing-data/hooks';
import { routes } from 'routes';
import { nethansaPalette } from 'theme';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { PaymentMethodsResponseInterface } from '@adyen/adyen-web/dist/types/types';
import { Alert, Box } from '@mui/material';

type formState = 'init' | 'pending' | 'fail-internal' | 'fail-adyen' | 'success';

type sendPaymentResponseType = {
  message?: string | null;
  status?: number;
  refusalReasonCode?: string | null;
};

type Props = {
  paymentMethods?: PaymentMethodsResponseInterface;
  subscriptionName?: string;
  subscriptionPrice?: Money;
  userId?: number;
};

export const AdyenCheckoutContainer: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const [t] = useTranslation('settings');
  const paymentContainerRef = useRef<HTMLDivElement>(null);
  const [formState, setFormstate] = useState<formState>('init');
  const [externalError, setExternalError] = useState<null | string>(null);
  const { refetch } = usePaymentInfoQuery();

  const returnUrl = `${window.location.origin}${routes.Settings.children.PaymentsAndBillings.children.Payments.path}`;

  const configuration: CoreOptions = {
    paymentMethodsResponse: props.paymentMethods,
    environment: environment.ADYEN_ENV,
    clientKey: environment.ADYEN_CLIENT_KEY,
    analytics: { enabled: false },
    locale: i18n.language,
    showPayButton: true,
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        enableStoreDetails: false,
        hideCVC: false,
        name: t('payments.subtitle-adyen-form', { name: props.subscriptionName }),
        amount: props.subscriptionPrice?.value
          ? {
              value: Number(props.subscriptionPrice?.value) * 100,
              currency: props.subscriptionPrice?.currencyCode || 'EUR',
            }
          : undefined,
      },
    },
  };

  useEffect(() => {
    if (!paymentContainerRef.current) return;

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        ...configuration,
      });

      if (paymentContainerRef.current) {
        checkout
          .create('dropin', {
            onSubmit: (state, _dropin) => {
              setFormstate('pending');
              httpClient
                .post(API.payments().adyenSendPayment(), {
                  userId: props.userId || 0,
                  ...state.data.paymentMethod,
                  // testing card errors:
                  // https://docs.adyen.com/development-resources/testing/result-code-testing/adyen-response-codes
                  //
                  // additionalData: {
                  //   "RequestedTestAcquirerResponseCode": 13
                  // },
                  returnUrl: returnUrl,
                })
                .then((response: AxiosResponse) => {
                  if (response.status < 300) {
                    setFormstate('success');
                    setTimeout(() => {
                      refetch();
                    }, 10000);
                  }
                })
                .catch((error: AxiosError) => {
                  if (error.response) {
                    setTimeout(() => {
                      refetch();
                    }, 10000);
                    if (error.response.status >= 500) {
                      setFormstate('fail-internal');
                    } else {
                      const data = error.response.data as unknown as sendPaymentResponseType;
                      setExternalError(data?.message || 'UNKNOWN');
                      setFormstate('fail-adyen');
                    }
                  }
                });
            },
          })
          .mount(paymentContainerRef.current);
      }
    };
    createCheckout();
  }, [configuration.locale]);

  const spinnerBox = (
    <Box sx={{ height: '100%' }}>
      <SpinnerIcon
        sx={{
          width: '100px',
          height: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Box>
  );

  return (
    <>
      <Show when={formState === 'init'}>
        <Box
          ref={paymentContainerRef}
          sx={{
            '& .adyen-checkout__button--pay': {
              backgroundColor: nethansaPalette.button.blueMain,
              borderRadius: '48px',
              '&:hover': {
                backgroundColor: nethansaPalette.button.blueHover,
              },
            },
            '& .adyen-checkout__payment-method--selected': {
              backgroundColor: nethansaPalette.background.title,
            },
          }}
        />
      </Show>
      <Show when={formState === 'pending'}>{spinnerBox}</Show>
      <Show when={formState === 'fail-adyen'}>
        <Alert severity="error" sx={{ mt: 2 }}>
          <Trans t={t} i18nKey="payments.error.payment-adyen" values={{ error: externalError }} />
        </Alert>
      </Show>
      <Show when={formState === 'fail-internal'}>
        <Alert severity="error" sx={{ mt: 2 }}>
          {t('payments.error.internal')}
        </Alert>
      </Show>
      <Show when={formState === 'success'}>
        <Alert severity="success" sx={{ mt: 2 }}>
          {t('payments.success')}
        </Alert>
      </Show>
    </>
  );
};
