import React from 'react';
import { DatagridWrapperPage, GridTableIds } from 'modules/datagrid';
import { useFiltersFromStorageUtils } from '../datagrid/hooks';
import { PlatformChannelCode } from '../shared/types';
import { TableOffers } from './components/table-offers';
import { TablePredefinedFiltersTabs } from './components/table-predefined-filters-tabs';
import { useOffersColumnsDefinition } from './hooks';
import { OfferModalsCoordinator } from './offer-modals-coordinator';

const CHANNEL_CODE: PlatformChannelCode = 'DEFAULT';

export const OffersKauflandPage: React.FC = () => {
  const columns = useOffersColumnsDefinition(CHANNEL_CODE);

  const parsedColumns = columns.map((x) => x.field);

  const { getOffersFilter, cleanSessionStorageValue } = useFiltersFromStorageUtils();

  return (
    <OfferModalsCoordinator>
      <DatagridWrapperPage
        columns={parsedColumns}
        tableId={GridTableIds.OffersKAUFLAND}
        topSection={<TablePredefinedFiltersTabs channel={CHANNEL_CODE} />}
        initialData={{ itemsPerPage: 50, forceFiltersValue: getOffersFilter() }}
        events={{
          resetFilters: cleanSessionStorageValue,
        }}
      >
        <TableOffers channel={CHANNEL_CODE} />
      </DatagridWrapperPage>
    </OfferModalsCoordinator>
  );
};
