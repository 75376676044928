import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { PlatformCode } from 'modules/shared/types';

type FieldTypeInput = {
  code: string;
  isManagedByClipperon: boolean;
};

type InputType = FieldTypeInput[];

export const usePlatformMarketsMutation = (
  platformCode: PlatformCode,
): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.put<void, Error, InputType>(
      API.wizard().singlePlatformMarketplaces(platformCode),
      body,
    );
    return;
  });
};
