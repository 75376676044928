import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
import { applyExternalAccess } from 'common/utils';
import { Show, SplashScreen } from 'components';
import enLocale from 'date-fns/locale/en-GB';
import plLocale from 'date-fns/locale/pl';
import { useAuth } from 'modules/authorization';
import { AppGlobalParametersProvider } from 'modules/shared/contexts/app-global-parameters.provider';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { USER_UNAUTHORIZED } from './common/events';
import { CoordinatorExternalAccessPage } from './modules/coordinator-external-access';
import { RootRouter } from './routes';

export const App: React.FC = () => {
  const { i18n, t } = useTranslation('common');
  const { initialized, authorized, currentPartnerId, logout } = useAuth();

  useEffect(() => {
    applyExternalAccess({ changeLanguage: (lang: string) => i18n.changeLanguage(lang).then() });

    window.addEventListener(USER_UNAUTHORIZED, () => {
      logout();
    });
  }, []);

  const isAppReadyToRender = initialized && authorized && !!currentPartnerId;

  const localeMap = {
    en: enLocale,
    pl: plLocale,
  };

  return (
    <Box>
      <LocalizationProvider
        adapterLocale={localeMap[i18n.language as 'en']}
        dateAdapter={AdapterDateFns}
        localeText={{
          start: t('from'),
          end: t('to'),
        }}
      >
        <Show when={isAppReadyToRender}>
          <AppGlobalParametersProvider>
            <RootRouter />
            <CoordinatorExternalAccessPage />
            <ReactQueryDevtools position="top-right" initialIsOpen={false} />
          </AppGlobalParametersProvider>
        </Show>
        <SplashScreen out={initialized} />
      </LocalizationProvider>
    </Box>
  );
};
