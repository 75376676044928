import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MonitoringLayout, MonitoringPage } from 'modules/monitoring';

export const MonitoringRouter: React.FC = () => {
  return (
    <Routes>
      <Route element={<MonitoringLayout />}>
        <Route index element={<MonitoringPage />} />
      </Route>
    </Routes>
  );
};
