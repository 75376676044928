import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { COORDINATOR_READY } from 'common/events';
import { bootstrapI18Next } from 'common/i18next';
import { queryClient } from 'common/query-client';
import { applyExternalAccess } from 'common/utils';
import i18next from 'i18next';
import { AuthorizationProvider, UserProvider } from 'modules/authorization';
import { getTempPartnerId } from 'modules/authorization/helpers';
import { GlobalStyles } from 'modules/shared';
import { ExternalAccessType } from 'modules/shared/types';
import { SnackbarContextProvider } from 'modules/snackbar';
import { theme } from 'theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { App } from './app';
import { httpClient } from './common/api';
import { dateFormats } from './common/format/date';
import { environment } from './environment';

declare global {
  interface Window {
    __REACT_APP_ACCESS__: ExternalAccessType;
    REACT_APP_API_URL: string;
    REACT_APP_GRAPHQL_URL: string;
    REACT_APP_ADYEN_CLIENT_KEY: string;
    REACT_APP_ENV: 'test' | 'live';
    location: Location;
  }
}

const bootstrap = async (
  element?: HTMLElement | null,
  config?: { partnerId?: string; lang?: string; callback?: () => void },
) => {
  if (config?.lang) await i18next.changeLanguage(config.lang);

  if (!httpClient.defaults.params) httpClient.defaults.params = {};
  if (config?.partnerId) httpClient.defaults.params['partnerId'] = config.partnerId;
  if (!config?.partnerId && !!getTempPartnerId())
    httpClient.defaults.params['partnerId'] = getTempPartnerId();

  window.addEventListener(COORDINATOR_READY, () => {
    console.log(`React App: v${environment.VERSION}`);
    config?.callback ? config?.callback() : console.log('APP bootstrapped!');
  });

  const root = ReactDOM.createRoot(
    element || document.getElementById('root') || document.createElement('div'),
  );

  root.render(
    <Suspense fallback={null}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthorizationProvider partnerId={config?.partnerId}>
            <QueryClientProvider client={queryClient}>
              <UserProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={dateFormats}>
                  <SnackbarContextProvider>
                    <App />
                    <CssBaseline />
                    <GlobalStyles />
                  </SnackbarContextProvider>
                </LocalizationProvider>
              </UserProvider>
            </QueryClientProvider>
          </AuthorizationProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>,
  );
};

bootstrapI18Next();

LicenseInfo.setLicenseKey(environment.MUI_LICENSE_KEY);

applyExternalAccess({
  bootstrap,
});

if (process.env.NODE_ENV === 'development') {
  bootstrap(document.getElementById('root'), {
    lang: 'en',
  });
}
