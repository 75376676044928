import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { DialogConfirmation, Show, TextFieldNumber } from 'components';
import { useProductsCoordinatorContext } from 'modules/products/hooks';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import { Alert, Stack, Typography } from '@mui/material';
import { Query } from '../../../../generated/graphql';
import { useBulkHandlingTimeProductMutation } from '../../hooks';
import { SelectedProductDetails } from '../components/selected-product-details';
import { SelectedProducts } from '../components/selected-products';

export const EditHandlingTimeModal: React.FC = () => {
  const {
    editHandlingTime: [actionParams, setActionParams],
  } = useProductsCoordinatorContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);
  const { pushSnackbar } = useSnackbar();
  const useAmazonBulkAction = useBulkHandlingTimeProductMutation();
  const [t] = useTranslation('products');
  const handlingTimeInputRef = React.useRef<HTMLInputElement>(null);
  const refreshProducts = () => queryClient.refetchQueries({ active: true, queryKey: 'products' });
  const closeModal = () => setActionParams(undefined);

  const minHandlingTime = 1;
  const maxHandlingTime = 28;

  const validationError = t('modals.editHandlingTime.validation.minmax', {
    min: minHandlingTime,
    max: maxHandlingTime,
  });

  const handleResponse = (data: Query) => {
    const errors = data.updateProductHandlingTime?.errors;
    if (!errors || errors.length == 0) {
      refreshProducts();
      actionParams?.onSuccess && actionParams?.onSuccess();
      closeModal();
      pushSnackbar({
        variant: 'success',
        message: t('modals.editHandlingTime.messages.success'),
      });
    } else setErrorMessage(t('modals.editHandlingTime.messages.error'));
  };

  const verifyValue = (value: number | null): boolean => {
    if (!value) {
      setValidationMessage(validationError);
      return false;
    }

    if (isNaN(value) || value < minHandlingTime || value > maxHandlingTime) {
      setValidationMessage(validationError);
      return false;
    }

    setValidationMessage(null);
    return true;
  };

  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }

    if (!handlingTimeInputRef.current?.value) {
      setValidationMessage(validationError);
      return;
    }

    const newHandlingTime = Number(handlingTimeInputRef.current?.value);
    if (!verifyValue(newHandlingTime)) return;

    useAmazonBulkAction.mutate(
      {
        newHandlingTime: newHandlingTime,
        where: actionParams?.filters,
      },
      {
        onSuccess: (data) => handleResponse(data),
        onError: () => setErrorMessage(t('modals.editHandlingTime.messages.error')),
      },
    );
  };

  return (
    <DialogConfirmation
      isLoading={useAmazonBulkAction.isLoading}
      confirmLabel={t('modals.buttons.change')}
      open
      onClose={confirm}
      header={t('modals.editHandlingTime.header')}
      tooltip={t('modals.editHandlingTime.tooltip')}
      description=""
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <Show when={actionParams?.row != null}>
          <SelectedProductDetails row={actionParams?.row} />
        </Show>
        <Show when={actionParams?.row == null}>
          <SelectedProducts productsNumber={actionParams?.selectedCount} />
        </Show>
        <Show when={errorMessage != null}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Show>

        <Stack
          sx={{
            gap: '16px',
            flexDirection: 'column',
            backgroundColor: nethansaPalette.background.title,
            width: '100%',
            pl: 2,
            py: 1,
            fontSize: '13px',
            lineHeight: '18px',
          }}
        >
          <Typography variant="h5" sx={{ lineHeight: '18px', whiteSpace: 'pre-line' }}>
            {t('modals.editHandlingTime.description')}
          </Typography>

          <Stack
            sx={{
              gap: '1rem',
              flexDirection: 'row',
              alignItems: 'center',
              mb: '1rem',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: '700' }}>
              {t('modals.editHandlingTime.handlingTime-label')}
            </Typography>
            <TextFieldNumber
              value={actionParams?.row?.handlingTime}
              inputRef={handlingTimeInputRef}
              onBlur={verifyValue}
              helperText={validationMessage}
              error={validationMessage != null}
              sx={{
                width: '96px',
                '& > p': { marginLeft: 0, width: '250px', right: 'auto !important' },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </DialogConfirmation>
  );
};
