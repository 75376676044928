import React from 'react';
import { CardForm, Show } from 'components';
import { ClaimDefenderComponent } from '../authorization';
import { ShipFromAddressForm, ShipFromAddressFormSkeleton } from './components';
import { useShipFromAddressQuery } from './components/section-ship-from-address/hooks';

export const ShipFromAddressPage: React.FC = () => {
  const { isFetched } = useShipFromAddressQuery();

  return (
    <ClaimDefenderComponent showInfoBanner allowed={'SETTINGS_CARRIERS_SHIP_FORM_ADDRESS_EDIT'}>
      <CardForm>
        <Show when={isFetched} fallback={<ShipFromAddressFormSkeleton />}>
          <ShipFromAddressForm />
        </Show>
      </CardForm>
    </ClaimDefenderComponent>
  );
};
