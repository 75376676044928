import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation, Show } from 'components';
import { Stack, Typography } from '@mui/material';
import { queryClient } from '../../../common/query-client';
import { Query } from '../../../generated/graphql';
import { nethansaPalette } from '../../../theme';
import { SelectedOffers } from '../../offers/components/modal-selected-offer-details/selected-offers';
import { useSnackbar } from '../../snackbar';
import { SelectedMonitoringDetails } from '../components';
import { useBulkDeleteMonitoringMutation } from '../hooks/use-bulk-delete-monitoring.mutation';
import { useMonitoringCoordinatorContext } from '../hooks/use-monitoring-coordinator-context';

export const ModalMonitoringDeleteConfirmation: React.FC = () => {
  const {
    deleteOffers: [actionParams, setActionParams],
  } = useMonitoringCoordinatorContext();
  const { t } = useTranslation('monitoring');
  const refreshGrid = () =>
    queryClient.refetchQueries({ active: true, queryKey: 'monitoredOffers' });
  const closeModal = () => setActionParams(undefined);
  const isSingleElement = actionParams?.row != null;
  const selectedElement = actionParams?.row;

  const useAmazonBulkAction = useBulkDeleteMonitoringMutation();

  const { pushSnackbar } = useSnackbar();

  const showError = () => {
    pushSnackbar({
      variant: 'error',
      message: t('modal.delete.messages.error'),
    });
  };

  const handleResponse = (data: Query) => {
    actionParams?.onSuccess && actionParams?.onSuccess();
    const errors = data.deleteMonitoredOffer?.errors;

    if (!errors || errors.length == 0) {
      const message = isSingleElement
        ? t('modal.delete.messages.success')
        : t('modal.delete.messages.success-multi');
      pushSnackbar({
        variant: 'success',
        message: message,
      });
      refreshGrid();
    } else showError();

    closeModal();
  };

  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }

    useAmazonBulkAction.mutate(
      {
        where: actionParams?.filters,
      },
      {
        onSuccess: (data) => handleResponse(data),
        onError: () => showError(),
      },
    );
  };

  return (
    <DialogConfirmation
      open
      dangerHeader
      onClose={confirm}
      header={t('modal.delete.header')}
      description=""
      isLoading={useAmazonBulkAction.isLoading}
      confirmLabel={t('actions.delete')}
    >
      <Stack sx={{ width: '540px', ml: 4, mr: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <Show when={isSingleElement}>
          <SelectedMonitoringDetails row={selectedElement} />
        </Show>
        <Show when={!isSingleElement}>
          <SelectedOffers offersNumber={actionParams?.selectedCount} showWarning={true} />
        </Show>
        <Show when={isSingleElement}>
          <Stack
            sx={{
              flexDirection: 'column',
              backgroundColor: nethansaPalette.background.title,
              width: '100%',
              pl: 2,
              pb: 1,
              fontSize: '13px',
              lineHeight: '18px',
            }}
          >
            <Typography
              variant="h5"
              sx={{ lineHeight: '18px', whiteSpace: 'pre-line', pt: 1, fontWeight: 'bold' }}
            >
              {t('modal.delete.description')}
            </Typography>
          </Stack>
        </Show>
      </Stack>
    </DialogConfirmation>
  );
};
