import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import {
  RepricingStatus as RepricingStatusGraphQL,
  PriceManagementMode as PriceManagementModeGraphQL,
} from 'generated/graphql';
import { DatagridCellFlag } from 'modules/datagrid/components';
import { currency } from 'modules/offers/modals/offer-details/components/utils/float-with-symbol';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FormButtonsGroup, Show } from '../../../../../../components';
import { useCommonTranslations } from '../../../../../shared';
import { useSnackbar } from '../../../../../snackbar';
import {
  useBulkActionOnFilteredPriceSettingsMutation,
  useOfferCoordinatorContext,
  usePriceSettingsQuery,
} from '../../../../hooks';
import { usePriceSettingsMutation } from '../../../../hooks/use-price-setings.mutation';
import {
  MultiplePriceSettingsParamsType,
  PriceManagementMode,
  PriceSettingsInputType,
  PriceSettingsTab,
  RepricingStatus,
  SinglePriceSettingsParamsType,
} from '../../../../types';
import { Tabs } from './components';
import { PriceManagementTab, CompetitionTab, PriceRangeTab } from './views';

type FormProps = PriceSettingsInputType;

export const PriceSettingsForm: React.FC = () => {
  const [t] = useTranslation('offers');
  const { tCommon } = useCommonTranslations();
  const [tabName, setTabName] = useState<PriceSettingsTab>('PriceManagement');

  const { data } = usePriceSettingsQuery();
  const priceSettingsMutation = usePriceSettingsMutation();
  const priceSettingsBulkActionMutation = useBulkActionOnFilteredPriceSettingsMutation();
  const { pushSnackbar } = useSnackbar();

  const {
    priceSettings: [priceSettingsParams, setPriceSettings],
  } = useOfferCoordinatorContext();

  const handleTabChange = (event: React.SyntheticEvent, newValue: PriceSettingsTab) => {
    setTabName(newValue);
  };

  const refreshAndClearSelection = () => {
    queryClient.refetchQueries({ active: true, queryKey: 'offers' });
    priceSettingsParams?.onSuccess && priceSettingsParams?.onSuccess();
  };

  const handleClose = () => setPriceSettings(undefined);

  const isForSinglePriceSettings = Object.hasOwn(priceSettingsParams || {}, 'offerId');

  const getDefaultValues = () => {
    const markupSettings = data?.offerPriceSettings?.markupSettings;

    const repricingSettings = data?.offerPriceSettings?.repricerSettings;
    const defaultRepricingSettings = data?.defaultPriceSettings?.repricerSettings;

    const fixedPricesSettings = data?.offerPriceSettings?.fixedPricesSettings;
    const defaultFixedPricesSettings = data?.defaultPriceSettings?.fixedPricesSettings;

    const parametersSettings = data?.offerPriceSettings?.parametersSettings;

    const getRepricingStatus = (repreicingEnabled?: boolean | RepricingStatus) => {
      if (typeof repreicingEnabled !== 'boolean' && !repreicingEnabled) {
        return 'LeaveAsItIs';
      }
      return typeof repricingSettings?.repreicingEnabled === 'boolean'
        ? repricingSettings?.repreicingEnabled
          ? 'On'
          : 'Off'
        : repricingSettings?.repreicingEnabled;
    };

    const selectedOfferIdsValue = isForSinglePriceSettings
      ? [(priceSettingsParams as SinglePriceSettingsParamsType).offerId]
      : (priceSettingsParams as MultiplePriceSettingsParamsType).selectedOfferIds;

    return {
      selectedOfferIds: selectedOfferIdsValue,
      filters: [],
      markupSettings: {
        minimalMarkup: markupSettings?.minimalMarkup,
        standardMarkup: markupSettings?.standardMarkup,
        maximumMarkup: markupSettings?.maximumMarkup,
        useDefaultMinimalMarkup: isForSinglePriceSettings ? !markupSettings?.minimalMarkup : false,
        useDefaultStandardMarkup: isForSinglePriceSettings
          ? !markupSettings?.standardMarkup
          : false,
        useDefaultMaximumMarkup: isForSinglePriceSettings ? !markupSettings?.maximumMarkup : false,
      },
      repricerSettings: {
        repricingStatus:
          getRepricingStatus(repricingSettings?.repreicingEnabled) ||
          getRepricingStatus(defaultRepricingSettings?.repreicingEnabled),
        percentageDifferenceFromCompetition: repricingSettings?.percentageDifferenceFromCompetition,
        valueDifferenceFromCompetition:
          repricingSettings?.valueDifferenceFromCompetition ||
          defaultRepricingSettings?.valueDifferenceFromCompetition,
        priceManagementMode: isForSinglePriceSettings ? repricingSettings?.priceManagementMode : 0,
        useDefaultValueDifferenceFromCompetition: isForSinglePriceSettings
          ? !repricingSettings?.valueDifferenceFromCompetition
          : false,
        useDefaultPercentageDifferenceFromCompetition: isForSinglePriceSettings
          ? !repricingSettings?.percentageDifferenceFromCompetition
          : false,
      },
      fixedPricesSettings: {
        fixedMinimumPrice:
          fixedPricesSettings?.fixedMinimumPrice || defaultFixedPricesSettings?.fixedMinimumPrice,
        fixedMaximumPrice:
          fixedPricesSettings?.fixedMaximumPrice || defaultFixedPricesSettings?.fixedMaximumPrice,
        fixedPrice: fixedPricesSettings?.fixedPrice || defaultFixedPricesSettings?.fixedPrice,
        removeFixedPrice: false,
        removeFixedMinimumPrice: false,
        removeFixedMaximumPrice: false,
      },
      parametersSettings: {
        additionalCost: parametersSettings?.additionalCost,
        returnReserve: parametersSettings?.returnReserve,
        shippingCost: parametersSettings?.shippingCost,
        useDefaultAdditionalCost: isForSinglePriceSettings
          ? !parametersSettings?.additionalCost
          : false,
        useDefaultReturnReserve: isForSinglePriceSettings
          ? !parametersSettings?.returnReserve
          : false,
        useDefaultShippingCost: isForSinglePriceSettings
          ? !parametersSettings?.shippingCost
          : false,
      },
    };
  };

  const formMethods = useForm<FormProps>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
  });

  const tabsNames: { [key in PriceSettingsTab]: PriceSettingsTab } = {
    PriceManagement: 'PriceManagement',
    Competition: 'Competition',
    PriceRange: 'PriceRange',
  };

  const tabsListWithParams: { label: PriceSettingsTab; hasError: boolean }[] = [
    {
      label: tabsNames.PriceManagement,
      hasError: !!formMethods.formState.errors.fixedPricesSettings?.fixedPrice,
    },
    { label: tabsNames.Competition, hasError: !!formMethods.formState.errors.repricerSettings },
    {
      label: tabsNames.PriceRange,
      hasError:
        !!formMethods.formState.errors.markupSettings ||
        !!formMethods.formState.errors.parametersSettings ||
        !!formMethods.formState.errors.fixedPricesSettings?.fixedMinimumPrice ||
        !!formMethods.formState.errors.fixedPricesSettings?.fixedMaximumPrice,
    },
  ];

  const handleSuccessResponse = (data: FormProps) => {
    formMethods.reset(data);
    pushSnackbar({
      variant: 'success',
      message: t('modals.price-settings.success'),
    });
    refreshAndClearSelection();
    handleClose();
  };

  const handleErrorResponse = () => {
    pushSnackbar({
      variant: 'error',
      message: t('modals.price-settings.error.general'),
    });
  };

  const submit = (data: FormProps) => {
    const isBySelectedOffers = data.selectedOfferIds.length > 0;

    if (isForSinglePriceSettings) {
      data.fixedPricesSettings.removeFixedPrice = data.fixedPricesSettings.fixedPrice === null;
      data.fixedPricesSettings.removeFixedMinimumPrice =
        data.fixedPricesSettings.fixedMinimumPrice === null;
      data.fixedPricesSettings.removeFixedMaximumPrice =
        data.fixedPricesSettings.fixedMaximumPrice === null;
    }

    if (isBySelectedOffers) {
      return priceSettingsMutation.mutate(
        {
          ...data,
          repricerSettings: {
            ...data.repricerSettings,
            priceManagementMode: Number(data.repricerSettings.priceManagementMode),
          },
        },
        {
          onSuccess: () => handleSuccessResponse(data),
          onError: handleErrorResponse,
        },
      );
    }

    const repriceStatusDictionary: Record<RepricingStatus, RepricingStatusGraphQL> = {
      On: RepricingStatusGraphQL.On,
      Off: RepricingStatusGraphQL.Off,
      LeaveAsItIs: RepricingStatusGraphQL.LeaveAsItIs,
    };

    const priceManagementModeDictionary: Record<PriceManagementMode, PriceManagementModeGraphQL> = {
      0: PriceManagementModeGraphQL.Unknown,
      1: PriceManagementModeGraphQL.Off,
      2: PriceManagementModeGraphQL.Fixed,
      3: PriceManagementModeGraphQL.Dynamic,
    };

    const priceSettingsParamsForMultiple = priceSettingsParams as MultiplePriceSettingsParamsType;

    return priceSettingsBulkActionMutation.mutate(
      {
        filters: priceSettingsParamsForMultiple.filters,
        channelDefined: priceSettingsParamsForMultiple.channelDefined,
        searchValue: priceSettingsParamsForMultiple.searchValue,
        priceSettingsRequest: {
          fixedPricesSettings: data.fixedPricesSettings,
          markupSettings: data.markupSettings,
          parametersSettings: data.parametersSettings,
          repricerSettings: {
            ...data.repricerSettings,
            repricingStatus: repriceStatusDictionary[data.repricerSettings.repricingStatus],
            priceManagementMode:
              priceManagementModeDictionary[data.repricerSettings.priceManagementMode],
          },
        },
      },
      {
        onSuccess: () => handleSuccessResponse(data),
        onError: handleErrorResponse,
      },
    );
  };

  const disabled = priceSettingsMutation.isLoading || priceSettingsBulkActionMutation.isLoading;

  return (
    <>
      <Stack
        sx={{
          flexDirection: data?.isSingleOffer ? 'row' : 'column',
          justifyContent: 'space-between',
          gap: data?.isSingleOffer ? 4 : 0,
          px: 4,
          mb: 1,
          fontSize: '13px',
          lineHeight: '18px',
          fontWeight: 500,
          '& > h5': {
            lineHeight: '18px',
          },
        }}
      >
        <Show when={!isForSinglePriceSettings}>
          <Typography variant="h5">
            <span>{t('modals.price-settings.for.prefix')} </span>
            <strong>
              {t('modals.price-settings.for.offers', {
                count: (priceSettingsParams as MultiplePriceSettingsParamsType)?.selectedCount,
              })}
            </strong>
          </Typography>
          <Typography variant="h5" sx={{ whiteSpace: 'pre-line', width: 0, minWidth: '100%' }}>
            {t('modals.price-settings.on.multi')}
          </Typography>
        </Show>

        <Show when={isForSinglePriceSettings}>
          <Box>
            <Typography variant="h5" sx={{ lineHeight: '18px' }}>
              {t('offer-details.channel', {
                context:
                  data?.offerPriceSettingsParams?.channelCode === 'AMZFBA'
                    ? 'platform'
                    : 'merchant',
              })}
            </Typography>
            <DatagridCellFlag
              value={data?.offerPriceSettingsParams?.marketplaceCode || 'unknown'}
              label={(priceSettingsParams as SinglePriceSettingsParamsType)?.domain}
              href={(priceSettingsParams as SinglePriceSettingsParamsType)?.productUrl}
            />
          </Box>
          <Stack direction="row" gap={0.5}>
            <Box>{t('column.netProductCost.label') + ':'}</Box>
            <Box>
              {currency(
                data?.offerPriceSettings?.parametersSettings?.productCostInMarketplaceCurrency
                  ?.value || 0,
                data?.offerPriceSettings?.parametersSettings?.productCostInMarketplaceCurrency
                  ?.currencyCode || 'EUR',
              )}
            </Box>
          </Stack>
          <Typography variant="h5" sx={{ maxWidth: '370px', flexGrow: 1 }}>
            {(priceSettingsParams as SinglePriceSettingsParamsType)?.productName || 'unknown'}
          </Typography>
        </Show>
      </Stack>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submit)}>
          <TabContext value={tabName}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs tabs={tabsListWithParams} handleTabChange={handleTabChange} />
            </Box>
            <Box
              sx={{
                height: isForSinglePriceSettings ? '360px' : '420px',
                minWidth: isForSinglePriceSettings ? '752px' : '832px',
                overflowY: 'auto',
              }}
            >
              <TabPanel value={tabsNames.PriceManagement}>
                <PriceManagementTab disabled={disabled} />
              </TabPanel>
              <TabPanel value={tabsNames.Competition}>
                <CompetitionTab disabled={disabled} />
              </TabPanel>
              <TabPanel value={tabsNames.PriceRange}>
                <PriceRangeTab disabled={disabled} />
              </TabPanel>
            </Box>
          </TabContext>

          <FormButtonsGroup
            sx={{ py: 2, pr: 4, borderTop: 1, borderColor: 'divider' }}
            isLoading={priceSettingsMutation.isLoading || priceSettingsBulkActionMutation.isLoading}
          >
            <Button
              onClick={handleClose}
              type="button"
              size="small"
              variant="text"
              disabled={disabled}
            >
              {tCommon('cancel')}
            </Button>
            <Button
              size="small"
              type="submit"
              disabled={disabled || Object.keys(formMethods.formState.errors).length > 0}
            >
              {tCommon('save')}
            </Button>
          </FormButtonsGroup>
        </form>
      </FormProvider>
    </>
  );
};
