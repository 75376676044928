import React, { useEffect, useRef, useState } from 'react';
import { Alert, Collapse } from '@mui/material';

type Props = {
  show: boolean;
  isSuccess: boolean;
  isError: boolean;
  successMessage?: string | JSX.Element;
  errorMessage?: string | JSX.Element;
  duration?: number;
};

export const FormAlert: React.FC<Props> = (props) => {
  const ref = useRef(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.show) setShow(true);

    timeout.current = setTimeout(() => {
      if (!ref.current) return;
      setShow(false);
    }, props.duration || 20000);
  }, [props.show, props.duration]);

  useEffect(() => {
    return () => {
      if (!timeout.current) return;
      clearTimeout(timeout.current);
    };
  }, []);

  const color = props.isSuccess ? 'success' : props.isError ? 'error' : undefined;

  const message = props.isSuccess
    ? props.successMessage
    : props.isError
    ? props.errorMessage
    : undefined;

  return (
    <Collapse in={show}>
      <Alert ref={ref} color={color} sx={{ mb: 1 }}>
        {message}
      </Alert>
    </Collapse>
  );
};
