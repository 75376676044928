import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { dimensionSizeParser, dimensionWeightParser, setUrlToHigherResolution } from 'common/utils';
import { ImagePreview, ProductImage } from 'components';
import { Box, Link, Stack, Typography } from '@mui/material';
import { OfferType } from '../../../../../../types';
import { CardKeyValue } from '../../../card-key-value';
import { CardWithLabel } from '../../../card-with-label';

type Props = OfferType;

export const OfferItem: React.FC<Props> = (props) => {
  const { t } = useTranslation('offers');
  const { control, setValue, clearErrors } = useFormContext();
  const currentTargetAsin = useWatch({ control, name: 'targetAsin' });

  const isActive = props.asin === currentTargetAsin;

  const handleBoxClick = () => {
    clearErrors();
    setValue('targetAsin', props.asin);
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <Box
      onClick={handleBoxClick}
      sx={{
        cursor: 'pointer',
        width: '464px',
        borderRadius: '6px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: isActive ? 'nethansa.main.active' : 'nethansa.button.grayLight',
        '&:hover': {
          borderColor: isActive ? 'nethansa.main.active' : 'nethansa.button.grayHover',
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ py: 1.5, px: 2, position: 'relative' }}
      >
        <Box>
          <Typography variant="h5Bold" color="nethansa.main.gray">
            {t('label.asin')}
          </Typography>
          <Typography variant="h2">{props.asin}</Typography>
        </Box>
        <Box sx={{ mb: '-28px' }}>
          <ImagePreview imageSrc={setUrlToHigherResolution(props.imageUrl, 'amazon')}>
            <ProductImage
              src={setUrlToHigherResolution(props.imageUrl, 'amazon', 75)}
              alt="offer-image"
              width={75}
            />
          </ImagePreview>
        </Box>
      </Stack>
      <CardWithLabel label={t('modals.edit-asin-modal.offer.marketplace-listing-data')}>
        <CardKeyValue
          keyLabel={t('label.title')}
          value={
            <Link
              sx={{ color: 'nethansa.button.blueMain' }}
              component="a"
              href={props.productUrl}
              target="_blank"
              referrerPolicy="no-referrer"
              onClick={handleLinkClick}
            >
              {props.name}
            </Link>
          }
        />
        <CardKeyValue keyLabel={t('label.product-type')} value={props.productType} />
        <CardKeyValue keyLabel={t('label.main-category')} value={props.mainCategory} />
        <Stack direction="row" spacing={2}>
          <Box>
            <CardKeyValue keyLabel={t('label.rank')} value={props.rank} />
            <CardKeyValue
              keyLabel={t('label.item-weight')}
              value={dimensionWeightParser(props.dimensions)}
            />
            <CardKeyValue keyLabel={t('label.size')} value={props.size} />
          </Box>
          <Box>
            <CardKeyValue keyLabel={t('label.best-sellers-rank')} value={props.bestSellersRank} />
            <CardKeyValue
              keyLabel={t('label.item-dimensions')}
              value={dimensionSizeParser(props.dimensions)}
            />
            <CardKeyValue keyLabel={t('label.package-quantity')} value={props.packageQuantity} />
          </Box>
        </Stack>
      </CardWithLabel>
    </Box>
  );
};
