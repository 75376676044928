import { MenuClasses, MenuProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const menuStyles: {
  defaultProps?: Partial<MenuProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof MenuClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      zIndex: 5,
    },
  },
};
