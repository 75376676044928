import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { WizardFormStepType } from '../../contexts/onboarding-wizard.provider';

type ResponseType = {
  currentStep: 'globals' | 'platforms' | 'vat-and-shipping' | 'products';
};

export const useWizardStatusQuery = (): UseQueryResult<WizardFormStepType> => {
  return useQuery<WizardFormStepType>(
    ['wizard', 'status'],
    async () => {
      const response = await httpClient.get<ResponseType>(API.wizard().status());
      return WizardFormStepType[response.data.currentStep];
    },
    {
      staleTime: 500,
    },
  );
};
