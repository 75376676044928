import { InputMaybe } from 'generated/graphql';
import { PlatformChannelCode } from 'modules/shared/types';
import { GridLogicOperator } from '@mui/x-data-grid';
import { GridColDef, GridFilterModel } from '@mui/x-data-grid-pro';
import { getColumnType, parseFilterValue, reduceArrayOfFilters } from '../utils';

type ResultType<TFilter = Record<string, unknown>> = {
  filter?: InputMaybe<TFilter>;
};

// Translate data grid filters and sorting context to graphql request
export function useDatagridFiltersToGraphqlTranslator<
  TColumn extends GridColDef,
  TFilter = Record<string, unknown>,
>(
  filters: GridFilterModel,
  columns: TColumn[],
  searchableColumns: string[],
  channel?: PlatformChannelCode,
  searchValue?: string,
): ResultType<TFilter> {
  const reducedFilters = reduceArrayOfFilters(
    filters.items,
    columns,
    filters?.logicOperator || GridLogicOperator.And,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const search: InputMaybe<any> | undefined =
    (searchValue || '').length > 0
      ? {
          or: searchableColumns?.map((x) => {
            const column = columns.find((y) => y.field === x);
            if (!column) return {};
            const operator = column.type === 'number' ? 'eq' : 'contains';
            return {
              [x as string]: {
                [operator]: parseFilterValue(
                  searchValue || '',
                  column.field,
                  getColumnType(column.field, columns),
                ),
              },
            };
          }),
        }
      : undefined;

  const channelDefined = channel
    ? {
        or: [
          {
            channelCode: {
              eq: channel,
            },
          },
        ],
      }
    : null;

  const filter =
    !!reducedFilters || !!search || !!channelDefined
      ? {
          and: [
            ...(search ? [{ ...search }] : []),
            ...(reducedFilters ? [{ ...reducedFilters }] : []),
            ...(channelDefined ? [{ ...channelDefined }] : []),
          ],
        }
      : undefined;

  return {
    filter: filter as InputMaybe<TFilter>,
  };
}

export function mapSelectedOfferIdsToFilter<TFilter = Record<string, unknown>>(
  ids: string[],
): ResultType<TFilter> {
  const filter = { and: [{ and: [{ offerKey: { in: ids } }] }] };

  return {
    filter: filter as InputMaybe<TFilter>,
  };
}

export function mapSelectedProductIdsToFilter<TFilter = Record<string, unknown>>(
  ids: string[],
): ResultType<TFilter> {
  const filter = { and: [{ and: [{ id: { in: ids } }] }] };

  return {
    filter: filter as InputMaybe<TFilter>,
  };
}

export function mapSelectedMonitoringIdsToFilter<TFilter = Record<string, unknown>>(
  ids: string[],
): ResultType<TFilter> {
  const filter = { and: [{ and: [{ id: { in: ids } }] }] };

  return {
    filter: filter as InputMaybe<TFilter>,
  };
}
