import { SelectClasses, SelectProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const selectStyles: {
  defaultProps?: Partial<SelectProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof SelectClasses>> | undefined;
} = {
  styleOverrides: {
    select: {
      borderRadius: '6px',
      padding: '6px 13px',
      fontSize: '14px',
      fontWeight: 500,
      backgroundColor: nethansaPalette.main.light,

      '&.Mui-disabled': {
        backgroundColor: nethansaPalette.background.title,
        borderColor: nethansaPalette.button.grayLight,
      },
    },
    icon: {
      color: nethansaPalette.main.dark,
      '&.Mui-disabled': {
        color: nethansaPalette.button.grayLight,

        '& + .MuiOutlinedInput-notchedOutline': {
          borderColor: nethansaPalette.button.grayLight,
        },
      },
    },
  },
};
