import React from 'react';
import { DatagridWrapperPage, GridTableIds } from 'modules/datagrid';
import { TableSaleRevenue } from './components/table-sale-revenue';
import { useSaleRevenueColumnsDefinition } from './hooks/use-sale-revenue-columns-definition';

export const SalesRevenueReportsPage: React.FC = () => {
  const columns = useSaleRevenueColumnsDefinition();

  const parsedColumns = columns.map((x) => x.field);

  return (
    <DatagridWrapperPage
      tableId={GridTableIds.SalesRevenueProfit}
      columns={parsedColumns}
      initialData={{ itemsPerPage: 50 }}
    >
      <TableSaleRevenue />
    </DatagridWrapperPage>
  );
};
