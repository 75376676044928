import { RadioClasses, RadioProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const radioStyles: {
  defaultProps?: Partial<RadioProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof RadioClasses>> | undefined;
} = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: nethansaPalette.button.grayHover,
    },
  },
};
