import * as React from 'react';
import { ReactNode } from 'react';
import { Box, Button, IconButton, PopoverOrigin, Theme } from '@mui/material';
import Menu from '@mui/material/Menu';
import { SxProps } from '@mui/system';
import { Show } from '../show';

type Props = {
  buttonContent?: string | JSX.Element;
  iconButton?: boolean;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  buttonColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'flat';
  maxMenuHeight?: string;
  minMenuWidth?: string;
  anchorOrigin?: PopoverOrigin;
  edgeIcon?: false | 'start' | 'end' | undefined;
  sx?: SxProps<Theme>;
  children?:
    | ((params: { handleClose: () => void }) => JSX.Element | string | JSX.Element[])
    | ReactNode
    | undefined;
};

export const ButtonMenu: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ...(props.sx || {}) }}>
      <Show when={!!props.iconButton}>
        <IconButton edge={props.edgeIcon} onClick={handleClick}>
          {props.buttonContent || 'Options'}
        </IconButton>
      </Show>
      <Show when={!props.iconButton}>
        <Button
          size="small"
          color={props.buttonColor || 'primary'}
          variant={props.buttonVariant || 'text'}
          onClick={handleClick}
        >
          {props.buttonContent || 'Options'}
        </Button>
      </Show>
      <Menu
        anchorOrigin={
          props.anchorOrigin || {
            vertical: 'top',
            horizontal: 'right',
          }
        }
        id="long-button-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: props.maxMenuHeight || '184px',
            minWidth: props.minMenuWidth || '170px',
            boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.3)',
          },
        }}
      >
        {typeof props.children === 'function' ? props.children({ handleClose }) : props.children}
      </Menu>
    </Box>
  );
};
