export enum PlatformCodeEnum {
  AMZEU,
  AMZUS,
  KAUF,
}

export enum ChannelCodeEnum {
  DEFAULT,
  AMZFBA,
  AMZFBM,
}
