import { getRouteObject } from './helpers';

const settingsPathBase = '/Settings';
const offersPathBase = '/Offers';
const productsPathBase = '/Products';
const reportsPathBase = '/Reports';
const monitoringPathBase = '/Monitoring';
const dashboardPathBase = '/Home';

export const routes = {
  Stylebook: getRouteObject('/Stylebook'),
  Dashboard: {
    ...getRouteObject(`${dashboardPathBase}`),
    children: {
      Tutorials: {
        ...getRouteObject(`${dashboardPathBase}`),
      },
      Current: {
        ...getRouteObject(`${dashboardPathBase}/Current`),
      },
    },
  },
  Offers: {
    ...getRouteObject(`${offersPathBase}`),
    children: {
      OffersFBA: {
        ...getRouteObject(`${offersPathBase}/AMZFBA`),
      },
      OffersFBM: {
        ...getRouteObject(`${offersPathBase}/AMZFBM`),
      },
      OffersKaufland: {
        ...getRouteObject(`${offersPathBase}/DEFAULT`),
      },
    },
  },
  Products: {
    ...getRouteObject(`${productsPathBase}`),
  },
  Reports: {
    ...getRouteObject(`${reportsPathBase}`),
    children: {
      SalesRevenue: getRouteObject(`${reportsPathBase}/SalesRevenue`),
      SalesRevenueProfit: getRouteObject(`${reportsPathBase}/SalesRevenueProfit`),
      OrderReturns: getRouteObject(`${reportsPathBase}/OrderReturns`),
      Taxes: getRouteObject(`${reportsPathBase}/Taxes`),
    },
  },
  Monitoring: {
    ...getRouteObject(`${monitoringPathBase}`),
  },
  Settings: {
    ...getRouteObject(`${settingsPathBase}`),
    children: {
      SalesIntelligence: {
        ...getRouteObject(`${settingsPathBase}/SalesIntelligence`),
        children: {
          GlobalSettings: getRouteObject(`${settingsPathBase}/SalesIntelligence/GlobalSettings`),
          Platforms: getRouteObject(`${settingsPathBase}/SalesIntelligence`),
          VatRates: getRouteObject(`${settingsPathBase}/SalesIntelligence/VatRates`),
        },
      },
      UserProfile: {
        ...getRouteObject(`${settingsPathBase}/Profile`),
        children: {
          ChangePassword: getRouteObject(`${settingsPathBase}/Profile/ChangePassword`),
          Security: getRouteObject(`${settingsPathBase}/Profile/Security`),
        },
      },
      PaymentsAndBillings: {
        ...getRouteObject(`${settingsPathBase}/PaymentAndBillings`),
        children: {
          BillingData: getRouteObject(`${settingsPathBase}/PaymentAndBillings/BillingData`),
          Payments: getRouteObject(`${settingsPathBase}/PaymentAndBillings/Payments`),
        },
      },
      Carriers: {
        ...getRouteObject(`${settingsPathBase}/Carriers`),
        children: {
          ShippingCountry: getRouteObject(`${settingsPathBase}/Carriers/ShippingCountry`),
          ShipFromAddress: getRouteObject(`${settingsPathBase}/Carriers/ShipFromAddress`),
        },
      },
    },
  },
};
