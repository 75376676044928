import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { longDateWithMonthDescription, parseDate, parseDateAndFixIso } from 'common/format/date';
import {
  DatagridCellTags,
  DatagridHeaderTooltip,
  currencyCellClassName,
  currencyCellValueFormatter,
  nullableValueCellValueFormatter,
  percentageCellClassName,
  useDatagridListOperators,
  useDatagridNonNullableDateOperators,
  useDatagridNonNullableNumberOperators,
} from 'modules/datagrid';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { Box, Stack } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { nethansaPalette } from '../../../theme';
import { ButtonMenuProducts } from '../components/table-products';
import { DataGridProductCostCell } from '../components/table-products/components/data-grid-product-cost-cell';
import { DataGridSuspiciousCostCell } from '../components/table-products/components/data-grid-suspicious-cost-cell';
import { ProductsGridColumn } from '../types';
import { useProductsTags } from './use-products-tags.query';

export const useProductsColumnsDefinition = (): ProductsGridColumn[] => {
  const { t, i18n } = useTranslation('products');

  const { data: globalSettingsData } = useGlobalSettingsQuery();
  const { data: dataTags } = useProductsTags();

  const nonNullableDateOperators = useDatagridNonNullableDateOperators();
  const listOperators = useDatagridListOperators();
  const nonNullableNumberOperators = useDatagridNonNullableNumberOperators();

  return useMemo<ProductsGridColumn[]>(() => {
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        type: undefined,
        width: 60,
        pinnable: false,
        sortable: false,
        editable: false,
        resizable: false,
        hideable: false,
        renderCell: (params) => {
          return (
            <Stack sx={{ position: 'relative' }}>
              <Box>
                {GRID_CHECKBOX_SELECTION_COL_DEF?.renderCell &&
                  GRID_CHECKBOX_SELECTION_COL_DEF?.renderCell(params)}
              </Box>
              <Box>
                <ButtonMenuProducts product={params.row} />
              </Box>
            </Stack>
          );
        },
      },
      {
        field: 'name',
        headerName: t('column.name.label'),
        width: 250,
        type: 'string',
        cellClassName: 'dg-text-wrapper-cell',
      },
      {
        field: 'externalId',
        headerName: t('column.externalId.label'),
        type: 'string',
      },
      {
        field: 'stock',
        headerName: t('column.stock.label'),
        type: 'number',
        filterOperators: nonNullableNumberOperators,
      },
      {
        field: 'createdAt',
        headerName: t('column.createdAt.label'),
        width: 120,
        type: 'date',
        filterOperators: nonNullableDateOperators,
        cellClassName: 'dg-text-wrapper-cell',
        valueGetter: (params) => parseDateAndFixIso(parseDate(params.value)),
        valueFormatter: (params) =>
          params.value ? longDateWithMonthDescription(params.value, i18n.language) : undefined,
      },
      {
        field: 'updatedAt',
        headerName: t('column.updatedAt.label'),
        width: 120,
        type: 'date',
        filterOperators: nonNullableDateOperators,
        cellClassName: 'dg-text-wrapper-cell',
        valueGetter: (params) => parseDateAndFixIso(parseDate(params.value)),
        valueFormatter: (params) =>
          params.value ? longDateWithMonthDescription(params.value, i18n.language) : undefined,
      },
      {
        field: 'handlingTime',
        headerName: t('column.handlingTime.label'),
        type: 'number',
        filterOperators: nonNullableNumberOperators,
      },
      {
        field: 'tags',
        headerName: t('column.tags.label'),
        width: 180,
        sortable: false,
        type: 'singleSelect',
        filterOperators: listOperators,
        valueOptions: (dataTags || [])?.map((x) => ({ value: x.name, label: x.name })),
        renderCell: (params) => (
          <DatagridCellTags
            maxTags={2}
            tags={params.value || []}
            color={nethansaPalette.other.lightBlue}
          />
        ),
      },
      {
        field: 'category',
        headerName: t('column.category.label'),
        type: 'string',
      },
      {
        field: 'productCost',
        headerName: t('column.productCost.label'),
        type: 'number',
        // valueFormatter: currencyCellValueFormatter,
        // cellClassName: currencyCellClassName('baseCurrencyCode'),
        renderCell: (params) => <DataGridProductCostCell product={params.row} />,
      },
      {
        field: 'suspiciousProductCost',
        headerName: t('column.suspiciousProductCost.label'),
        type: 'number',
        renderCell: (params) => (
          <DataGridSuspiciousCostCell
            value={params.row.suspiciousProductCost}
            currency={params.row.baseCurrencyCode}
            date={params.row.suspiciousProductCostUpdatedAt}
            source={params.row.suspiciousProductCostSource}
          />
        ),
        renderHeader: (params) => (
          <DatagridHeaderTooltip
            header={params.colDef.headerName}
            tooltipContent={t(`column.suspiciousProductCost.tooltip.__html`)}
          />
        ),
      },
      {
        field: 'suspiciousProductCostUpdatedAt',
        headerName: t('column.suspiciousProductCostUpdatedAt.label'),
        type: 'date',
      },
      {
        field: 'suspiciousProductCostSource',
        headerName: t('column.suspiciousProductCostSource.label'),
        type: 'string',
      },
      {
        field: 'taxRate',
        headerName: t('column.taxRate.label'),
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
        filterOperators: nonNullableNumberOperators,
      },
      {
        field: 'ean',
        headerName: t('column.ean.label'),
        type: 'string',
      },
      {
        field: 'prediction30DaysQuantity',
        headerName: t('column.prediction30DaysQuantity.label'),
        type: 'number',
      },
      {
        field: 'predcition30DaysProfit',
        headerName: t('column.predcition30DaysProfit.label'),
        type: 'number',
        valueFormatter: currencyCellValueFormatter,
        cellClassName: currencyCellClassName('baseCurrencyCode'),
      },
      {
        field: 'prediction30DaysTurnover',
        headerName: t('column.prediction30DaysTurnover.label'),
        type: 'number',
        valueFormatter: currencyCellValueFormatter,
        cellClassName: currencyCellClassName('baseCurrencyCode'),
      },
      {
        field: 'last30DaysSoldQuantity',
        headerName: t('column.last30DaysSoldQuantity.label'),
        type: 'number',
      },
    ];
  }, [globalSettingsData?.baseCurrency, dataTags?.length]);
};
