import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
  crossed?: boolean;
};

export const SearchIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3734_11133)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.94116 7.5294C1.94116 4.4431 4.4431 1.94116 7.5294 1.94116C10.6157 1.94116 13.1176 4.4431 13.1176 7.5294C13.1176 10.6157 10.6157 13.1176 7.5294 13.1176C4.4431 13.1176 1.94116 10.6157 1.94116 7.5294ZM7.5294 -0.0588379C3.33853 -0.0588379 -0.0588379 3.33853 -0.0588379 7.5294C-0.0588379 11.7203 3.33853 15.1176 7.5294 15.1176C9.26432 15.1176 10.8633 14.5354 12.1415 13.5557L14.3517 15.7659C14.7422 16.1565 15.3754 16.1565 15.7659 15.7659C16.1565 15.3754 16.1565 14.7422 15.7659 14.3517L13.5557 12.1415C14.5354 10.8633 15.1176 9.26432 15.1176 7.5294C15.1176 3.33853 11.7203 -0.0588379 7.5294 -0.0588379Z"
          fill="#CFD0DC"
        />
      </g>
      <defs>
        <clipPath id="clip0_3734_11133">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
