import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from 'common/api';

type InputType = {
  hasVatOss: boolean;
  countries: {
    countryCode: string;
    isVatRegistered: boolean;
    selectedVatRate: string | null;
    defaultShippingCost: number | null;
  }[];
};

export const useWizardVatAndShippingMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.put(API.wizard().vatAndShipping(), body);
    return;
  });
};
