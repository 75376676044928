import React from 'react';
import { emptyArray } from 'common/utils';
import { Divider, Skeleton, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  numberOfFields: number;
  spacing?: number;
  itemHeight?: number;
  sx?: SxProps<Theme>;
  widthDivider?: boolean;
  itemVariant?: 'text' | 'rectangular' | 'circular';
  itemSx?: SxProps<Theme>;
};

export const SkeletonForm: React.FC<Props> = (props) => {
  return (
    <Stack
      spacing={props.spacing || 4}
      sx={{ ...(props.sx || {}) }}
      divider={
        props.widthDivider ? (
          <Divider sx={{ width: '100%', backgroundColor: 'nethansa.line.light', opacity: 0.5 }} />
        ) : null
      }
    >
      {emptyArray(props.numberOfFields).map((value, index) => (
        <Skeleton
          key={index}
          height={`${props.itemHeight || 50}px`}
          variant={props.itemVariant}
          sx={{ ...(props.itemSx || {}) }}
        />
      ))}
    </Stack>
  );
};
