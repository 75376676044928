import { useQuery, UseQueryResult } from 'react-query';
import { SharedOfferStateEnum, SharedOfferSubStateEnum } from 'generated/graphql';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ChannelCodeEnum, PlatformCodeEnum } from '../types';
import { useOfferCoordinatorContext } from './use-offer-coordinator-context';

export type OfferToChangeSku = {
  offerId: string;
  platformCode: PlatformCodeEnum;
  channelCode: ChannelCodeEnum;
  marketplaceCode: string;
  ean: string;
  sku: string;
  platformListingId: string;
  marketplacePrice: number | undefined;
  productQuantity: number | undefined;
  platformQuantity: number | undefined;
  state: number;
  stateName: SharedOfferStateEnum;
  subStates: number[];
  subStatesNames: SharedOfferSubStateEnum[];
  title: string;
  url: string;
  mainImage: string;
};

export const useGetOffersToChangeSkuQuery = (): UseQueryResult<OfferToChangeSku[]> => {
  const { authorized, currentPartnerId } = useAuth();
  const {
    changeSku: [offer],
  } = useOfferCoordinatorContext();

  return useQuery<OfferToChangeSku[]>(
    [],
    async () => {
      const response = await httpClient.get<ResponseAPIType<OfferToChangeSku[]>>(
        API.offers(currentPartnerId).offersBySku(encodeURIComponent(offer?.sku || '')),
      );
      return response.data.data;
    },
    {
      staleTime: 0,
      keepPreviousData: false,
      enabled: authorized,
      retry: 0,
    },
  );
};
