import { State } from '../contexts/user.context';
import { Claim } from '../types';

export const hasUserRightClaims = (allowedClaims: Claim | Claim[], useClaims: Claim[]) => {
  const isArrayValue = Array.isArray(allowedClaims);

  return isArrayValue
    ? useClaims.some((x) => (allowedClaims as Claim[])?.some((y) => x === y))
    : useClaims.some((x) => x === allowedClaims);
};

export const HasClaims = (ctx: State, allowed: Claim | Claim[]) => {
  const hasRightClaims = hasUserRightClaims(allowed, ctx.claims);

  return ctx.claimsFetched ? hasRightClaims : false;
};
