import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation, Show } from 'components';
import {
  AmazonOperation,
  DetailedActionResponseOfOfferErrorCode,
  KauflandOperation,
  OfferGridDtoFilterInput,
  OffersBulkActionOnAmazonQuery,
  OffersBulkActionOnKauflandQuery,
} from 'generated/graphql';
import {
  mapSelectedOfferIdsToFilter,
  useDatagridFiltersToGraphqlTranslator,
} from 'modules/datagrid';
import { ActionDetails } from 'modules/offers/components/modal-action-details/action-details';
import { useSnackbar } from 'modules/snackbar';
import { Stack } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { queryClient } from '../../../../common/query-client';
import { SelectedOfferDetails } from '../../components';
import { BulkActionSummary } from '../../components/bulk-action-summary';
import { SelectedOffers } from '../../components/modal-selected-offer-details/selected-offers';
import {
  useAmazonBulkActionOnFilteredRowsMutation,
  useKauflandBulkActionOnFilteredRowsMutation,
  useOfferCoordinatorContext,
  useOffersColumnsDefinition,
} from '../../hooks';
import { searchableColumnsOffers } from '../../hooks/use-offers.query';
import { OfferGridColumn, PlatformCodeEnum } from '../../types';

export const ModalOfferDeleteConfirmation: React.FC = () => {
  const { t } = useTranslation('offers');
  const { pushSnackbar } = useSnackbar();
  const columns = useOffersColumnsDefinition();

  const {
    deleteOffers: [actionParams, setDeleteOffers],
  } = useOfferCoordinatorContext();

  const isSingleElement = actionParams?.row != null;
  const [summary, setSummary] = useState<DetailedActionResponseOfOfferErrorCode>({
    numberOfSucceededOperations: 0,
    errors: null,
  });

  const [showSummarize, setShowSummarize] = useState(false);
  const [isConfirmationDisabled, setIsConfirmationDisabled] = useState(false);
  const [isError, setIsError] = useState(!String(actionParams?.platformCode));
  const useAmazonBulkAction = useAmazonBulkActionOnFilteredRowsMutation();
  const useKauflandBulkAction = useKauflandBulkActionOnFilteredRowsMutation();
  const initialError = !String(actionParams?.platformCode)
    ? t('modals.delete-offers.error.missing-platform-code')
    : '';

  const [errorMessage, setErrorMessage] = useState<string | undefined>(initialError);
  const selectedOffer = actionParams?.row;

  const refreshAndClearSelection = () => {
    queryClient.refetchQueries({ active: true, queryKey: 'offers' });
    actionParams?.onSuccess && actionParams?.onSuccess();
  };

  const showGenericError = () => {
    setIsError(true);
    setErrorMessage(t('modals.delete-offers.error.general'));
  };

  const handleResponseKaufland = (response: OffersBulkActionOnKauflandQuery) => {
    handleResponse(response.executeKauflandMassActionOnFilteredOffers);
  };

  const handleResponseAmazon = (response: OffersBulkActionOnAmazonQuery) => {
    handleResponse(response.executeAmazonMassActionOnFilteredOffers);
  };

  const handleResponse = (data: DetailedActionResponseOfOfferErrorCode | undefined | null) => {
    if (!data) {
      showGenericError();
      return;
    }

    const errors = data?.errors;

    if (isSingleElement) {
      if (!errors || errors.length == 0) {
        refreshAndClearSelection();
        showSnackBarAndCloseWindow();
        return;
      }

      setIsError(true);
      setErrorMessage(t(`errors.${errors[0].key}` as any));
      return;
    }

    setIsConfirmationDisabled(true);
    setSummary(data);
    setShowSummarize(true);
    refreshAndClearSelection();
  };

  const showSnackBarAndCloseWindow = () => {
    pushSnackbar({
      variant: 'success',
      message: t('modals.delete-offers.success.single'),
    });

    setDeleteOffers(undefined);
  };

  const handleResponseError = () => {
    showGenericError();
  };

  const confirm = (confirm: boolean) => {
    if (!confirm) {
      setDeleteOffers(undefined);
      return;
    }

    const hasSelectedOffers = (actionParams?.selectedOfferIds || []).length > 0;

    const gridFilters = hasSelectedOffers
      ? mapSelectedOfferIdsToFilter<OfferGridDtoFilterInput>(
          actionParams?.selectedOfferIds || ([] as string[]),
        )
      : useDatagridFiltersToGraphqlTranslator<OfferGridColumn, OfferGridDtoFilterInput>(
          actionParams?.filters || ({} as GridFilterModel),
          columns,
          searchableColumnsOffers,
          actionParams?.channelDefined || 'AMZFBM',
          actionParams?.searchValue,
        );

    if (PlatformCodeEnum.AMZEU === actionParams?.platformCode) {
      useAmazonBulkAction.mutate(
        {
          amazonOperation: AmazonOperation.Delete,
          filter: gridFilters.filter,
        },
        {
          onError: handleResponseError,
          onSuccess: handleResponseAmazon,
        },
      );
      return;
    }

    useKauflandBulkAction.mutate(
      {
        kauflandOperation: KauflandOperation.Delete,
        filter: gridFilters.filter,
      },
      {
        onError: handleResponseError,
        onSuccess: handleResponseKaufland,
      },
    );
  };

  return (
    <DialogConfirmation
      open
      dangerHeader
      onClose={confirm}
      header={t('actions.delete')}
      description=""
      confirmLabel={t('actions.delete')}
      isLoading={useAmazonBulkAction.isLoading || useKauflandBulkAction.isLoading}
      isConfirmationDisabled={isConfirmationDisabled}
      isError={isError}
      errorMessage={errorMessage}
      showOnlyClose={showSummarize}
    >
      <Stack sx={{ width: '540px', ml: 4, mr: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <Show when={isSingleElement}>
          <SelectedOfferDetails row={selectedOffer} />
        </Show>
        <Show when={!isSingleElement}>
          <SelectedOffers offersNumber={actionParams?.selectedCount} showWarning={!showSummarize} />
        </Show>

        <ActionDetails
          title={t('actions.action-details-title')}
          content={t('modals.delete-offers.action-details')}
        />

        <Show when={showSummarize}>
          <BulkActionSummary data={summary} />
        </Show>
      </Stack>
    </DialogConfirmation>
  );
};
