import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import flatten from 'lodash.flatten';
import { sortBy } from '../../../common/utils';
import { useCountriesTranslations } from '../../shared';
import { CarriersShippingInputType } from '../types';
import { useCarriersShippingCountriesQuery } from './use-carriers-shipping-countries.query';

export const useMappedAvailableCountries = () => {
  const [tCountries] = useCountriesTranslations();
  const { data } = useCarriersShippingCountriesQuery();
  const { control } = useFormContext<CarriersShippingInputType>();

  const shippingCountriesValue = useWatch({
    control,
    name: 'shippingCountries',
  });

  const notIntegratedCountriesValue = useWatch({
    control,
    name: 'notIntegratedCountries',
  });

  const shippingCountriesValueInUseFlatten = [
    ...flatten(shippingCountriesValue.map((x) => x.countryCodes)),
    ...notIntegratedCountriesValue,
  ];

  return useMemo(
    () =>
      data?.supportedCountryCodes
        .map((x) => ({
          label: `${tCountries(x)} (${x})`,
          value: x,
          disabled: shippingCountriesValueInUseFlatten.some((y) => y === x),
        }))
        .sort(sortBy('label')) || [],
    [data?.supportedCountryCodes?.length, shippingCountriesValueInUseFlatten],
  );
};
