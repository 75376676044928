import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { DialogConfirmation, Show } from 'components';
import { SharedOfferStateEnum } from 'generated/graphql';
import { CanExecuteAction } from 'modules/offers/actions-validator';
import { ActionDetails } from 'modules/offers/components/modal-action-details/action-details';
import { useGetOffersToPauseOrActivateQuery } from 'modules/offers/hooks/use-get-offers-to-pause-or-activate.query';
import { usePauseOrActivateOfferMutation } from 'modules/offers/hooks/use-pause-or-activate-offer.mutation';
import { OfferAction } from 'modules/offers/types/offer-action';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import { NAVIGATION_APP_HEIGHT } from 'theme/variables';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { SelectedOfferDetails } from '../../components';
import { useOfferCoordinatorContext } from '../../hooks';
import { OffersToPauseOrActivateList } from './offers-to-pause-or-activate-list';

export const PauseOrActivateOfferModal: React.FC = () => {
  const { pushSnackbar } = useSnackbar();

  const { isLoading: isLoadingOffersToPauseOrActivate, data: offersToPauseOrActivateData } =
    useGetOffersToPauseOrActivateQuery();
  const {
    mutate: pauseOrActivate,
    data: pauseOrActivationData,
    isLoading: isPausingOrActivating,
  } = usePauseOrActivateOfferMutation();
  const {
    pauseOffer: [selectedOfferWithAction, setOfferWithAction],
  } = useOfferCoordinatorContext();

  const [t] = useTranslation('offers');
  const [tCommon] = useTranslation('common');

  const refreshOffers = () => queryClient.refetchQueries({ active: true, queryKey: 'offers' });
  const closeModal = () => setOfferWithAction(undefined);

  const selectedOffer = selectedOfferWithAction?.[0];
  const action = selectedOfferWithAction?.[1];
  const translationModalNode = action === OfferAction.Pause ? 'pauseModal' : 'activateModal';

  if (!selectedOffer || !action) throw Error('Unexpected exception');

  const otherOffersHasInvalidState = offersToPauseOrActivateData?.length
    ? offersToPauseOrActivateData.some(
        (offer) => !CanExecuteAction(action, offer.stateName, offer.subStatesNames),
      )
    : false;

  const otherOffersWithoutSelected =
    offersToPauseOrActivateData?.filter((offer) => offer.offerId !== selectedOffer.offerKey) ?? [];

  return (
    <DialogConfirmation
      isConfirmationDisabled={otherOffersHasInvalidState}
      isLoading={isPausingOrActivating || isLoadingOffersToPauseOrActivate}
      confirmLabel={t(`modals.${translationModalNode}.buttonLabel`)}
      open
      onClose={async (confirmed) => {
        const selectedOfferId = selectedOffer.offerKey ?? '';

        if (!confirmed || !selectedOfferId.length) {
          closeModal();
          return;
        }

        pauseOrActivate(
          { offerId: selectedOfferId, action: action },
          {
            onSuccess: (data) => {
              if (data.isSuccess) {
                refreshOffers();
                closeModal();
                pushSnackbar({
                  variant: 'success',
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  message: t(`${translationModalNode}.success.message` as any),
                });
              }
            },
          },
        );
      }}
      header={t(`modals.${translationModalNode}.header`)}
      dangerHeader={false}
      description=""
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <SelectedOfferDetails row={selectedOffer} />

        <ActionDetails
          title={t('actions.action-details-title')}
          content={t(`modals.${translationModalNode}.action-details`)}
        />

        <Stack
          sx={{
            backgroundColor: otherOffersHasInvalidState
              ? nethansaPalette.background.error
              : nethansaPalette.background.title,
            width: '100%',
            gap: 2,
            fontSize: '13px',
            lineHeight: '18px',
            px: '16px',
            pt: '8px',
            overflowY: 'auto',
            maxHeight: `calc(100vh - ${NAVIGATION_APP_HEIGHT} - 364px)`,
          }}
        >
          <Show when={otherOffersWithoutSelected.length > 0}>
            <Typography
              variant="h5"
              sx={{
                lineHeight: '18px',
                color: otherOffersHasInvalidState
                  ? nethansaPalette.main.watermelon
                  : nethansaPalette.main.dark,
              }}
            >
              <Show
                when={otherOffersHasInvalidState}
                fallback={t(`modals.${translationModalNode}.skuWarning`, {
                  sku: selectedOffer.sku || 'unknown',
                })}
              >
                {t(`modals.${translationModalNode}.actionImpossible`)}
              </Show>
            </Typography>
            <OffersToPauseOrActivateList
              action={action}
              offers={offersToPauseOrActivateData ?? []}
              selectedMarketplaceCode={selectedOffer.marketplaceCode ?? ''}
              selectedSharedOfferState={
                selectedOffer.sharedOfferState ?? SharedOfferStateEnum.Unknown
              }
            />
          </Show>
          <Show when={pauseOrActivationData?.isSuccess === false}>
            <Alert severity="error" sx={{ width: '100%', mt: '20px' }}>
              <AlertTitle>{tCommon('error')}:</AlertTitle>
              {(pauseOrActivationData?.errorMessages ?? []).map((error) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                t(`modals.${translationModalNode}.errors.${error}` as any),
              )}
            </Alert>
          </Show>
        </Stack>
      </Stack>
    </DialogConfirmation>
  );
};
