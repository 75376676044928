import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabsItem, NavigationTabsLayout } from 'components/navigation-tabs';
import { routes } from 'routes';

export const CarriersLayout: React.FC = () => {
  const [t] = useTranslation('settings');
  return (
    <NavigationTabsLayout>
      <NavigationTabsItem
        href={routes.Settings.children.Carriers.link()}
        exact
        children={t('configuration.integrations')}
      />
      <NavigationTabsItem
        href={routes.Settings.children.Carriers.children.ShippingCountry.link()}
        children={t('configuration.shipping-countries')}
      />
      <NavigationTabsItem
        href={routes.Settings.children.Carriers.children.ShipFromAddress.link()}
        children={t('configuration.ship-from-address')}
      />
    </NavigationTabsLayout>
  );
};
