import React from 'react';
import { Box, Stack, TextField, TextFieldProps } from '@mui/material';
import { AlertIcon } from '../icons';
import { Tooltip, TooltipWrapper } from '../tooltip';

type Props = TextFieldProps & { tooltipContent?: string; tooltipError?: string };

export const TextFieldWithTooltip: React.FC<Props> = (props) => {
  const { tooltipContent, tooltipError, label, ...textFieldProps } = props;

  const tooltipErrorComponent = props.error && tooltipError && (
    <TooltipWrapper
      content={
        <Box component="span" sx={{ color: 'error.main' }}>
          {tooltipError}
        </Box>
      }
      disableHoverListener={false}
      placement="right"
    >
      <Box component="span" sx={{ position: 'absolute', right: '-7px', top: '10px' }}>
        <AlertIcon sx={{ fontSize: '16px', cursor: 'pointer' }} />
      </Box>
    </TooltipWrapper>
  );

  const labelComponent = !tooltipContent ? (
    label
  ) : (
    <Stack direction="row" spacing="10px" alignItems="center">
      <span>{label}</span>{' '}
      <Box>
        <Tooltip content={tooltipContent} />
      </Box>
    </Stack>
  );

  return (
    <Box sx={{ position: 'relative', width: props.fullWidth ? '100%' : 'auto' }}>
      <TextField {...textFieldProps} label={labelComponent} />
      <Box sx={{ position: 'absolute', display: 'inline-flex', alignItems: 'center' }}>
        {tooltipErrorComponent}
      </Box>
    </Box>
  );
};
