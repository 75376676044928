import React, { useCallback } from 'react';
import { DatagridExportMenu } from '../../../../../datagrid';
import { useCommonTranslations } from '../../../../../shared';
import { FileType, PlatformChannelCode } from '../../../../../shared/types';
import { useOffersColumnsDefinition } from '../../../../hooks';
import { useOffersExport } from '../../../../hooks/use-offers-export';
import { useOffersQuery } from '../../../../hooks/use-offers.query';

type Props = {
  channel?: PlatformChannelCode;
};

export const TableOffersExport: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();

  const columns = useOffersColumnsDefinition(props.channel);

  const { data } = useOffersQuery(columns, props.channel);

  const offersLazyExportData = useOffersExport(columns, props.channel);

  const onLazyDownloadClick = useCallback(
    (fileType: FileType) => {
      offersLazyExportData.mutate(fileType);
    },
    [offersLazyExportData],
  );

  const offersLazyExportForImportData = useOffersExport(columns, props.channel, true);

  const onLazyDownloadForImportClick = useCallback(
    (fileType: FileType) => {
      offersLazyExportForImportData.mutate(fileType);
    },
    [offersLazyExportForImportData],
  );

  const LIMIT_XLSX = 1000000,
    LIMIT_CSV = 1000000,
    LIMIT_IMPORT = 1000000;
  const disabledExportXlsxByLimit = (data?.offers?.totalCount || 0) > LIMIT_XLSX;
  const disabledExportCsvByLimit = (data?.offers?.totalCount || 0) > LIMIT_CSV;
  const disabledExportImportByLimit = (data?.offers?.totalCount || 0) > LIMIT_IMPORT;

  const options = [
    {
      label: tCommon('datagrid.actions.download-as-csv'),
      onClick: () => onLazyDownloadClick('csv'),
      tooltip: disabledExportCsvByLimit
        ? tCommon('datagrid.actions.export-limit-message.tooltip.__html', { count: LIMIT_CSV })
        : '',
      disabled: offersLazyExportData.isLoading || disabledExportCsvByLimit,
    },
    {
      label: tCommon('datagrid.actions.download-as-excel'),
      onClick: () => onLazyDownloadClick('xlsx'),
      tooltip: disabledExportXlsxByLimit
        ? tCommon('datagrid.actions.export-limit-message.tooltip.__html', { count: LIMIT_XLSX })
        : '',
      disabled: offersLazyExportData.isLoading || disabledExportXlsxByLimit,
    },
    {
      label: tCommon('datagrid.actions.download-as-import-compatible'),
      onClick: () => onLazyDownloadForImportClick('xlsx'),
      tooltip: disabledExportImportByLimit
        ? tCommon('datagrid.actions.export-limit-message.tooltip.__html', { count: LIMIT_IMPORT })
        : '',
      disabled: offersLazyExportForImportData.isLoading || disabledExportImportByLimit,
    },
  ];

  return (
    <DatagridExportMenu
      loading={offersLazyExportData.isLoading}
      disabled={offersLazyExportData.isLoading}
      label={tCommon('export')}
      options={options}
    />
  );
};
