import React from 'react';
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';
import { Divider, Stack } from '@mui/material';
import { RenderInView } from '../../../../../../../../components';
import { useCurrenciesTranslations } from '../../../../../../../shared';
import { CurrencyCode } from '../../../../../../../shared/types';
import {
  PlatformFieldType,
  PlatformPartnerChannelMarketplace,
  PlatformPresentationFieldType,
} from '../../../../../../types';
import { RenderCell } from '../render-cell';
import { BooleanReadonlyTextCell, CheckboxCell, NumberInputCell } from './components';

type RenderFieldsProps = {
  baseCurrency: CurrencyCode;
  fields: PlatformFieldType[];
  marketplace: PlatformPartnerChannelMarketplace;
  index: number;
  disabled?: boolean;
};

const renderComponentByType = (
  type: PlatformPresentationFieldType,
  form: UseFormReturn<FieldValues, unknown>,
  index: number,
  field: PlatformFieldType,
  disabled?: boolean,
) => {
  if (type === 'checkbox') {
    return (
      <CheckboxCell
        key={`${field.name}`}
        control={form.control}
        name={`marketplaces[${index}].${String(field.name)}`}
        disabled={disabled || field.disabled}
        onChange={field.onChange}
      />
    );
  }

  if (type === 'text') {
    return (
      <BooleanReadonlyTextCell
        key={`${String(field.name)}-${index}`}
        name={`marketplaces[${index}].${String(field.name)}`}
      />
    );
  }

  if (type === 'number') {
    return (
      <NumberInputCell
        key={field.name}
        disabled={field.disabled || disabled}
        unit={field.unit}
        precision={field.precision}
        rules={field.rules}
        control={form.control}
        name={`marketplaces[${index}].${String(field.name)}`}
      />
    );
  }
};

export const RenderFields: React.FC<RenderFieldsProps> = (props) => {
  const form = useFormContext();
  const [tCurrency] = useCurrenciesTranslations();

  const baseCurrency = tCurrency(`${props.baseCurrency || 'EUR'}.short`);

  return (
    <Stack divider={<Divider />}>
      {props.fields.map((field) => {
        const unit = field.baseCurrency
          ? baseCurrency
          : field.countryCurrency
          ? tCurrency(`${props.marketplace.currencyCode || 'EUR'}.short`)
          : field.unit;

        return (
          <RenderInView key={field.name} height="60px">
            <RenderCell justifyContent="center">
              {renderComponentByType(
                field.type,
                form,
                props.index,
                { ...field, unit: unit },
                props.disabled,
              )}
            </RenderCell>
          </RenderInView>
        );
      })}
    </Stack>
  );
};
