import React from 'react';
import { useTranslation } from 'react-i18next';
import { SharedOfferStateEnum } from 'generated/graphql';
import { nethansaPalette } from 'theme';
import { Box, Stack, Typography } from '@mui/material';
import { DatagridCellFlag } from '../../../datagrid';
import { OfferGridRow } from '../../types';
import { DataGridSharedStateCell } from '../table-offers/components/shared-state-cell';

export interface Props {
  row?: OfferGridRow;
}

export const SelectedOfferDetails: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  return (
    <Stack
      sx={{
        gap: '40px',
        flexDirection: 'row',
        backgroundColor: nethansaPalette.background.title,
        width: '100%',
        pl: 2,
        py: 1,
        fontSize: '13px',
        lineHeight: '18px',
      }}
    >
      <Box>
        <Typography variant="h5" sx={{ lineHeight: '18px' }}>
          {t('offer-details.channel', {
            context: props.row?.channelCode === 'AMZFBA' ? 'platform' : 'merchant',
          })}
        </Typography>

        <DatagridCellFlag
          value={props.row?.marketplaceCode || 'unknown'}
          label={props.row?.domain || ''}
          href={(props.row?.asin ? props.row?.productUrl : '') || ''}
        />
      </Box>

      <Box>
        <DataGridSharedStateCell
          isRowDirection={false}
          sharedState={props.row?.sharedOfferState || SharedOfferStateEnum.Unknown}
          sharedSubStates={props.row?.sharedOfferSubState || []}
          isNormalJustifyContent={true}
        />
      </Box>
      <Box sx={{ lineHeight: '24px' }}>
        <Typography variant="h5" sx={{ '& > span': { color: nethansaPalette.main.gray } }}>
          <span>SKU: </span>
          {props.row?.sku}
        </Typography>
        <Typography variant="h5" sx={{ '& > span': { color: nethansaPalette.main.gray } }}>
          <span>EAN: </span> {props.row?.ean}
        </Typography>
        <Typography variant="h5" sx={{ '& > span': { color: nethansaPalette.main.gray } }}>
          <span>{t('column.offerStock.label')}: </span>
          {props.row?.offerStock}
        </Typography>
      </Box>
    </Stack>
  );
};
