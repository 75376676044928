import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { PlatformCode } from 'modules/shared/types';
import { useAuth } from '../../authorization';
import { PlatformUnionType } from '../types/platform-union.type';

type ResponseType = {
  platformCode: 'amzeu' | 'kauf';
  connectionStatus: 'notAttempted' | 'connected' | 'failed';
  sellerId?: string;
  mwsAuthToken?: string;
  clientKey?: string;
  secretKey?: string;
  connectionTimestamp: string | null;
  connectionError: string | null;
}[];

export const usePlatformQuery = (
  platformCode: PlatformCode,
): UseQueryResult<PlatformUnionType | null> => {
  const { authorized } = useAuth();

  return useQuery<PlatformUnionType | null>(
    ['platforms', platformCode],
    async () => {
      const response = await httpClient.get<ResponseType>(API.wizard().platforms());
      return (
        response.data.find(
          (x) => (String(x.platformCode).toLowerCase() as PlatformCode) === platformCode,
        ) || null
      );
    },
    {
      staleTime: 0,
      enabled: authorized,
    },
  );
};
