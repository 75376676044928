import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockedIcon, HolidaysIcon } from 'components/icons';
import {
  ChannelCode,
  OfferGridDto,
  PlatformCode,
  PriceManagementMode,
  PriceValidationStatus,
} from 'generated/graphql';
import { useDataGridSimpleStringsOperators } from 'modules/datagrid/hooks';
import { useDatagridMultiListOperators } from 'modules/datagrid/hooks/use-datagrid-multilist-operators';
import { UseDataGridSharedStatesOperator } from 'modules/datagrid/hooks/use-datagrid-shared-states-operator';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { usePlatformQuery } from 'modules/settings-sales-platforms/hooks';
import { PlatformChannelCode, PlatformType, UnitOfSizeType } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import { Box, Stack } from '@mui/material';
import '@mui/x-data-grid';
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellParams,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { useOffersFiltersValuesByPlatformQuery } from '.';
import {
  longDateWithMonthDescription,
  longDateWithMonthDescriptionAndTime,
  parseDate,
  parseDateAndFixIso,
} from '../../../common/format/date';
import {
  currencyCellClassName,
  currencyCellValueFormatter,
  DatagridCellFlag,
  DatagridCellImage,
  DatagridCellPreWrapText,
  DatagridCellTags,
  DatagridCellValueConverter,
  DatagridHeaderTooltip,
  nullableValueCellValueFormatter,
  percentageCellClassName,
  useDatagridDateOperators,
  useDatagridListOperators,
} from '../../datagrid';
import { useDatagridCountryListOperators } from '../../datagrid/hooks/use-datagrid-country-list-operators';
import { useDatagridNonNullableNumberOperators } from '../../datagrid/hooks/use-datagrid-non-nullable-number-operators';
import { DataGridRepricingInfoCell } from '../components/table-offers/components/repricing-info-cell';
import { DataGridSharedStateCell } from '../components/table-offers/components/shared-state-cell';
import { ButtonMenuOffer, getStatusColorGroup } from '../components/table-utils';
import { OfferGridColumn } from '../types';
import { useOffersPotential } from './use-offers-potential.query';
import { useOffersTags } from './use-offers-tags.query';
import { useProductTags } from './use-product-tags.query';

const platformChannelCodeToChannelCodeEnum: Record<PlatformChannelCode, ChannelCode> = {
  AMZFBA: ChannelCode.Amzfba,
  AMZFBM: ChannelCode.Amzfbm,
  DEFAULT: ChannelCode.Default,
};

export const useOffersColumnsDefinition = (channel?: PlatformChannelCode): OfferGridColumn[] => {
  const { t, i18n } = useTranslation('offers');

  const platformType = channel === 'DEFAULT' ? PlatformCode.Kauf : PlatformCode.Amzeu;

  const { data: filtersValues } = useOffersFiltersValuesByPlatformQuery({
    channelCode: platformChannelCodeToChannelCodeEnum[channel || 'AMZFBA'],
    platformCode: platformType,
  });

  const { data: globalSettingsData } = useGlobalSettingsQuery();

  const { data: dataProductTags } = useProductTags();

  const { data: dataOfferTags } = useOffersTags();

  const { data: offersPotentials } = useOffersPotential();

  const { data: platformData, isFetched: isPlatformDataFetched } = usePlatformQuery(
    platformType as PlatformType,
  );

  const dateOperators = useDatagridDateOperators();

  const sharedStatesOperator = UseDataGridSharedStatesOperator();

  const listOperators = useDatagridListOperators();

  const multiListOperators = useDatagridMultiListOperators();

  const simpleStringOperators = useDataGridSimpleStringsOperators();

  const countryListOperators = useDatagridCountryListOperators();

  const nonNullableNumberOperators = useDatagridNonNullableNumberOperators();

  const renderHeader = useCallback(
    (translationKey?: string) =>
      (params: GridColumnHeaderParams<GridValidRowModel, OfferGridDto>) =>
        (
          <DatagridHeaderTooltip
            header={params.colDef.headerName}
            tooltipContent={t(
              `column.${
                translationKey || params.colDef.field
              }.tooltip.__html` as `column.sharedOfferState.tooltip.__html`,
            )}
          />
        ),
    [t, i18n.language],
  );

  const isKaufland = channel === 'DEFAULT';
  const isAmazonFBA = channel === 'AMZFBA';

  return useMemo<OfferGridColumn[]>(() => {
    const competitivePowerCellClassName = (params: GridCellParams) => {
      type DicKeys = 'Best' | 'Poor' | 'Only' | 'None';

      type DicType = {
        [key in DicKeys]: string;
      };

      const dictionary: DicType = {
        Best: `dg-chip success`, //{ color: 'success', label: 'BEST' },
        Poor: `dg-chip error`, //{ color: 'error', label: 'POOR' },
        Only: `dg-chip warning`, //{ color: 'warning', label: 'ONLY', sx: { borderColor: 'nethansa.main.active' } },
        None: `dg-chip default`, //{ color: 'default', label: 'NONE' },
      };
      return dictionary[params.value as 'Best'] || dictionary.None;
    };

    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        type: undefined,
        width: 60,
        pinnable: false,
        sortable: false,
        editable: false,
        resizable: false,
        hideable: false,
        renderCell: (params) => {
          return (
            <Stack sx={{ position: 'relative' }}>
              <Box
                className="offersDataGridRowState"
                sx={{
                  borderColor:
                    nethansaPalette.status[
                      `${getStatusColorGroup(params.row.sharedOfferState) as 'dead'}`
                    ].light,
                }}
              ></Box>
              <Box>
                {GRID_CHECKBOX_SELECTION_COL_DEF?.renderCell &&
                  GRID_CHECKBOX_SELECTION_COL_DEF?.renderCell(params)}
              </Box>
              <Box>
                <ButtonMenuOffer offer={params.row} />
              </Box>
            </Stack>
          );
        },
      },
      {
        field: 'sharedOfferState',
        headerName: t('column.sharedOfferState.label'),
        width: 200,
        filterOperators: sharedStatesOperator,
        pinnable: false,
        editable: false,
        resizable: false,
        hideable: false,
        renderCell: (params) => (
          <DataGridSharedStateCell
            sharedState={params.value}
            sharedSubStates={params.row['sharedOfferSubState']}
          />
        ),
      },
      {
        field: 'priceManagementMode',
        headerName: t('column.priceManagementMode.label'),
        width: 200,
        type: 'singleSelect',
        valueOptions: Object.values(PriceManagementMode)
          .filter((mode) => mode !== PriceManagementMode.Unknown)
          .map((mode) => ({
            value: mode,
            label: t(`offer-priceManagment.priceManagementMode.${mode}`),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        renderCell: (params) => (
          <DataGridRepricingInfoCell
            mode={params.row['priceManagementMode']}
            pricingModel={params.row['dynamicPricingModel']}
            repricingDetails={params.row['offerRepricingDetails']}
          />
        ),
        renderHeader: renderHeader(),
      },
      {
        field: 'offerRepricingDetails',
        headerName: t('column.offerRepricingDetails.label'),
        type: 'singleSelect',
        filterOperators: multiListOperators,
        valueOptions: Object.values(PriceValidationStatus)
          .filter((mode) => mode !== PriceValidationStatus.Valid)
          .map((x) => ({
            value: x,
            label: t(`offer-priceManagment.offerRepricingDetails.${x}`),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
      {
        field: 'sendingResultInformation',
        headerName: t('column.sendingResultInformation.label'),
        width: 250,
        type: 'string',
        filterOperators: simpleStringOperators,
        renderCell: (params) => <DatagridCellPreWrapText text={params.value} />,
      },
      {
        field: 'marketplaceCode',
        headerName: t('column.marketplaceCode.label'),
        width: 146,
        type: 'singleSelect',
        valueOptions: filtersValues?.marketplaceCodes,
        filterOperators: countryListOperators,
        renderCell: (params) => {
          const channelInfo = platformData?.partnerChannels.filter((c) => c.name == channel)[0];
          const marketplaceInfo = channelInfo?.partnerMarketplaces.filter(
            (m) => m.countryCode === params.value,
          )[0];

          return (
            <Stack direction="column" gap="8px">
              <DatagridCellFlag
                value={params.value}
                label={params.row.domain}
                href={params.row.asin ? params.row.productUrl : undefined}
              />
              <Stack
                sx={{
                  color: nethansaPalette.button.grayHover,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                {marketplaceInfo?.isConfigured === false && (
                  <Box title={t('offer-details.marketplaceStatus.blocked')}>
                    <BlockedIcon />
                  </Box>
                )}
                {marketplaceInfo?.isBreak && (
                  <Box title={t('offer-details.marketplaceStatus.holidays')}>
                    <HolidaysIcon />
                  </Box>
                )}
              </Stack>
            </Stack>
          );
        },
        renderHeader: renderHeader(),
      },
      {
        field: 'imageUrl',
        headerName: t('column.imageUrl.label'),
        sortable: false,
        filterable: false,
        width: 100,
        type: 'string',
        renderCell: (params) => <DatagridCellImage src={params.value} provider="amazon" />,
      },
      {
        field: 'productName',
        headerName: t('column.productName.label'),
        width: 250,
        type: 'string',
        cellClassName: 'dg-text-wrapper-cell',
        renderHeader: renderHeader(),
      },
      {
        field: 'title',
        headerName: t('column.title.label'),
        width: 250,
        type: 'string',
        cellClassName: 'dg-text-wrapper-cell',
        renderHeader: renderHeader(),
      },
      {
        field: 'competitivePower',
        headerName: t('column.competitivePower.label'),
        width: 90,
        type: 'singleSelect',
        valueOptions: filtersValues?.competitivePowerValues.map((x) => ({ value: x, label: x })),
        filterOperators: [...getGridSingleSelectOperators(), getGridStringOperators()[4]],
        cellClassName: competitivePowerCellClassName,
        renderHeader: renderHeader(),
      },
      {
        field: 'sku',
        headerName: t('column.sku.label'),
        width: 120,
        type: 'string',
        cellClassName: 'dg-text-wrapper-cell',
        renderHeader: renderHeader(),
      },
      {
        field: 'externalId',
        headerName: t('column.externalId.label'),
        width: 120,
        type: 'string',
        cellClassName: 'dg-text-wrapper-cell',
        renderHeader: renderHeader(),
      },
      {
        field: 'asin',
        headerName: isKaufland ? t('column.itemId.label') : t('column.asin.label'),
        width: 120,
        type: 'string',
        cellClassName: 'dg-text-wrapper-cell',
        renderHeader: renderHeader(),
      },
      {
        field: 'ean',
        headerName: t('column.ean.label'),
        width: 120,
        type: 'string',
        cellClassName: 'dg-text-wrapper-cell',
        renderHeader: renderHeader(),
      },
      {
        field: 'offerTags',
        headerName: t('column.offerTags.label'),
        width: 180,
        sortable: false,
        type: 'singleSelect',
        filterOperators: listOperators,
        valueOptions: (dataOfferTags || [])?.map((x) => ({ value: x.name, label: x.name })),
        renderCell: (params) => (
          <DatagridCellTags
            maxTags={2}
            tags={params.value || []}
            color={nethansaPalette.other.tagOffer}
          />
        ),
      },
      {
        field: 'productTags',
        headerName: t('column.productTags.label'),
        width: 180,
        sortable: false,
        type: 'singleSelect',
        filterOperators: listOperators,
        valueOptions: (dataProductTags || [])?.map((x) => ({ value: x.name, label: x.name })),
        renderCell: (params) => (
          <DatagridCellTags
            maxTags={2}
            tags={params.value || []}
            color={nethansaPalette.other.lightBlue}
          />
        ),
        renderHeader: renderHeader(),
      },
      ...(isKaufland
        ? []
        : [
            {
              field: 'parentAsin',
              headerName: t('column.parentAsin.label'),
              width: 120,
              type: 'string',
              cellClassName: 'dg-text-wrapper-cell',
            },
            {
              field: 'platformCategory',
              headerName: t('column.platformCategory.label'),
              width: 120,
              type: 'string',
              cellClassName: 'dg-text-wrapper-cell',
              renderHeader: renderHeader(),
            },
            {
              field: 'dimensions',
              headerName: t('column.dimensions.label'),
              width: 120,
              filterable: false,
              sortable: false,
              type: 'number',
              renderCell: (params: GridRenderCellParams) => {
                const { width, height, length } = params.row;
                return (
                  <DatagridCellValueConverter
                    reverse
                    type="dimensions"
                    value={[width, height, length]}
                    unit={UnitOfSizeType.Centimeters}
                  />
                );
              },
              renderHeader: renderHeader(),
            },
            {
              field: 'platformCategoryRanking',
              headerName: t('column.platformCategoryRanking.label'),
              width: 120,
              type: 'number',
              renderHeader: renderHeader(),
              filterOperators: nonNullableNumberOperators,
            },
            {
              field: 'brand',
              headerName: t('column.brand.label'),
              width: 120,
              type: 'string',
              renderHeader: renderHeader(),
            },
            {
              field: 'size',
              headerName: t('column.size.label'),
              width: 120,
              type: 'string',
              renderHeader: renderHeader(),
            },
            {
              field: 'color',
              headerName: t('column.color.label'),
              width: 120,
              type: 'string',
              renderHeader: renderHeader(),
            },
            {
              field: 'weight',
              headerName: t('column.weight.label'),
              width: 120,
              type: 'number',
              filterable: false,
              renderHeader: renderHeader(),
            },
            {
              field: 'packageQuantity',
              headerName: t('column.packageQuantity.label'),
              width: 120,
              type: 'number',
              renderHeader: renderHeader(),
            },
          ]),
      ...(isAmazonFBA
        ? [
            {
              field: 'fullfilmentAdditionalFee',
              headerName: t('column.fullfilmentAdditionalFee.label'),
              width: 120,
              type: 'number',
              cellClassName: currencyCellClassName('currencyCode'),
              valueFormatter: currencyCellValueFormatter,
              renderHeader: renderHeader(),
            },
          ]
        : []),
      {
        field: 'offerAddDate',
        headerName: t('column.offerAddDate.label'),
        width: 120,
        type: 'date',
        filterOperators: dateOperators,
        cellClassName: 'dg-text-wrapper-cell',
        valueGetter: (params) => parseDateAndFixIso(parseDate(params.value)),
        valueFormatter: (params) =>
          params.value
            ? longDateWithMonthDescriptionAndTime(params.value, i18n.language)
            : undefined,
      },
      {
        field: 'additionalProfit',
        headerName: t('column.additionalProfit.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'last30DaysSoldOrders',
        headerName: t('column.last30DaysSoldOrders.label'),
        width: 120,
        type: 'number',
      },
      {
        field: 'last30DaysSoldQuantity',
        headerName: t('column.last30DaysSoldQuantity.label'),
        width: 120,
        type: 'number',
      },
      {
        field: 'lastOrderDate',
        headerName: t('column.lastOrderDate.label'),
        width: 120,
        type: 'date',
        filterOperators: dateOperators,
        cellClassName: 'dg-text-wrapper-cell',
        valueGetter: (params) => parseDateAndFixIso(parseDate(params.value)),
        valueFormatter: (params) =>
          params.value ? longDateWithMonthDescription(params.value, i18n.language) : undefined,
      },
      {
        field: 'offerStock',
        headerName: t('column.offerStock.label'),
        width: 120,
        type: 'number',
        renderHeader: renderHeader(),
        filterOperators: nonNullableNumberOperators,
      },
      {
        field: 'productStock',
        headerName: t('column.productStock.label'),
        width: 120,
        type: 'number',
        renderHeader: renderHeader(),
      },
      {
        field: 'numberOfCompetitors',
        headerName: t('column.numberOfCompetitors.label'),
        width: 120,
        type: 'number',
        renderHeader: renderHeader(),
      },
      {
        field: 'competitorsPrice',
        headerName: t('column.competitorsPrice.label'),
        width: 90,
        type: 'number',
        valueFormatter: currencyCellValueFormatter,
        cellClassName: currencyCellClassName('currencyCode'),
      },
      {
        field: 'listingPrice',
        headerName: t('column.listingPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'calculatedPrice',
        headerName: t('column.calculatedPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'sourceGrossPrice',
        headerName: t('column.sourceGrossPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'fixedPrice',
        headerName: t('column.fixedPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'fixedMinimumPrice',
        headerName: t('column.fixedMinimumPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'fixedMaximumPrice',
        headerName: t('column.fixedMaximumPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'minimumPrice',
        headerName: t('column.minimumPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'standardPrice',
        headerName: t('column.standardPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'maximumPrice',
        headerName: t('column.maximumPrice.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'differenceFromCompetitionPercent',
        headerName: t('column.differenceFromCompetitionPercentage.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
      },
      {
        field: 'differenceFromCompetitionValue',
        headerName: t('column.differenceFromCompetitionValue.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'minimumMarkup',
        headerName: t('column.minimumMarkup.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'standardMarkup',
        headerName: t('column.standardMarkup.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
      },
      {
        field: 'maximumMarkup',
        headerName: t('column.maximumMarkup.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'actualMarkup',
        headerName: t('column.actualMarkup.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'changeToBestMarkup',
        headerName: t('column.changeToBestMarkup.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
        renderHeader: renderHeader(),
      },
      {
        field: 'netProductCostInBaseCurrency',
        headerName: t('column.netProductCost.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode', globalSettingsData?.baseCurrency),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'vatRate',
        headerName: t('column.vatRate.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
      },
      {
        field: 'netCarrierCostInBaseCurrency',
        headerName: t('column.netCarrierCost.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode', globalSettingsData?.baseCurrency),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'returnFee',
        headerName: t('column.returnFee.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
      },
      {
        field: 'channelCommission',
        headerName: t('column.channelCommissionAmazon.label'),
        width: 120,
        type: 'number',
        cellClassName: percentageCellClassName,
        valueFormatter: nullableValueCellValueFormatter,
      },
      {
        field: 'additionalCostValue',
        headerName: t('column.additionalCostValue.label'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('currencyCode'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'potentialRangeKey',
        headerName: t('column.potentialRange.label'),
        width: 100,
        type: 'singleSelect',
        valueOptions: (offersPotentials || [])?.map((x) => ({ value: x.key, label: x.name })),
        cellClassName: (params) => `potentialBar potential-${params.row.potentialRangeKey}`,
      },
      {
        field: 'offerKey',
        type: 'string',
        filterable: false,
      },
    ];
  }, [
    globalSettingsData?.baseCurrency,
    dataProductTags?.length,
    JSON.stringify(filtersValues),
    isKaufland,
    isPlatformDataFetched,
  ]);
};
