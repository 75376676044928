import { TabClasses, TableProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const tableStyles: {
  defaultProps?: Partial<TableProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TabClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      '& .MuiTableHead-root': {
        '& .MuiTableRow-head': {
          '& .MuiTableCell-root': {
            paddingBottom: '12px',
            fontSize: '13px',
            fontWeight: 700,
            lineHeight: '15.73px',
            letterSpacing: '0.5px',
            border: 'none',
            '&:first-of-type': {
              paddingLeft: 0,
            },
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-body': {
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '24px',
            border: 'none',
          },
          '&:nth-of-type(odd)': {
            '& .MuiTableCell-body': {
              backgroundColor: nethansaPalette.background.title,
            },
          },
        },
      },
    },
  },
};
