import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import { gql } from 'graphql-request';
import { Claim } from '../types';
import { useAuth } from './use-auth';

type UserQueryResponse = {
  claims: Claim[];
};

const getUserClaims = gql`
  query GetUserClaims($partnerId: Int!) {
    claimsForUser(partnerId: $partnerId, first: 1000) {
      nodes
    }
  }
`;

export const useUserQuery = (
  options?: UseQueryOptions<UserQueryResponse>,
): UseQueryResult<UserQueryResponse> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<UserQueryResponse>(
    [['auth', 'user', currentPartnerId]],
    async () => {
      const data = await graphqlClient.request(getUserClaims, {
        partnerId: Number(currentPartnerId),
      });

      return {
        claims: [...data.claimsForUser.nodes],

        // zostawiam na potrzeby szybkich testów dla przyszłych claimsów
        /*
        claims: [
          'SETTINGS',
          'SETTINGS_SALES_INTELLIGENCE',
          'SETTINGS_SALES_INTELLIGENCE_GLOBAL_SETTINGS',
          'SETTINGS_SALES_INTELLIGENCE_PLATFORMS',
          'SETTINGS_SALES_INTELLIGENCE_PLATFORMS_EDIT',
          'SETTINGS_SALES_INTELLIGENCE_VAT_RATES',
          'SETTINGS_SALES_INTELLIGENCE_VAT_RATES_EDIT',
          'SETTINGS_PAYMENTS_AND_BILLINGS',
          'SETTINGS_PAYMENTS_AND_BILLINGS_BILLING_DATA',
          'SETTINGS_PAYMENTS_AND_BILLINGS_BILLING_DATA_EDIT',
          'SETTINGS_CARRIERS',
          'SETTINGS_CARRIERS_INTEGRATIONS',
          'SETTINGS_CARRIERS_INTEGRATIONS_EDIT',
          'SETTINGS_CARRIERS_SHIPPING_COUNTRIES',
          'SETTINGS_CARRIERS_SHIPPING_COUNTRIES_EDIT',
          'SETTINGS_CARRIERS_SHIP_FORM_ADDRESS',
          'SETTINGS_CARRIERS_SHIP_FORM_ADDRESS_EDIT',
          'SETTINGS_YOUR_PROFILE',
          'SETTINGS_YOUR_PROFILE_PERSONAL_DATA',
          'SETTINGS_YOUR_PROFILE_CHANGE_PASSWORD',
          'SETTINGS_YOUR_PROFILE_SECURITY',
          'REPORTS',
          'REPORTS_SALES_REVENUE',
          'REPORTS_SALES_REVENUE_EXPORT',
          'REPORTS_SALES_REVENUE_V2',
          'REPORTS_SALES_REVENUE_V2_EXPORT',
        ],
        */
      };
    },
    {
      staleTime: 60000,
      keepPreviousData: false,
      enabled: authorized,
      ...(options || {}),
    },
  );
};
