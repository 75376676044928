import React from 'react';
import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { nethansaPalette } from '../../theme';
import { CheckedIcon } from '../icons';

export type CheckboxCustomProps = Omit<
  CheckboxProps,
  'icon' | 'checkedIcon' | 'indeterminateIcon'
> & {
  label: string | JSX.Element;
};

type CheckboxIconProps = {
  disabled?: boolean;
};

const checkboxStyles: SxProps<Theme> = {
  height: '18px',
  width: '18px',
  borderRadius: '4px',
};

const CheckboxDefaultIcon: React.FC<CheckboxIconProps> = (props) => {
  return (
    <Box
      component="span"
      sx={{
        ...checkboxStyles,
        backgroundColor: props.disabled ? 'background.default' : 'common.white',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'flat.main',
      }}
    />
  );
};

const CheckboxCheckedIcon: React.FC<CheckboxIconProps> = (props) => {
  return (
    <Box
      component="span"
      sx={{
        ...checkboxStyles,
        backgroundColor: props.disabled ? 'common.white' : 'primary.main',
        borderWidth: props.disabled ? '2px' : 'none',
        borderStyle: props.disabled ? 'solid' : 'none',
        borderColor: props.disabled ? 'flat.main' : 'none',
      }}
    >
      <CheckedIcon
        sx={{
          fontSize: '10px',
          path: { stroke: props.disabled ? nethansaPalette.button.grayLight : 'common.black' },
        }}
      />
    </Box>
  );
};

const CheckboxIndeterminateIcon: React.FC<CheckboxIconProps> = (props) => {
  return (
    <Box
      component="span"
      sx={{
        ...checkboxStyles,
        backgroundColor: 'common.white',
        borderWidth: `2px`,
        borderStyle: 'solid',
        borderColor: props.disabled ? 'flat.main' : 'secondary.main',
      }}
    >
      <Box
        sx={{
          height: '2px',
          width: '10px',
          borderRadius: '4px',
          backgroundColor: props.disabled ? 'flat.main' : 'secondary.main',
        }}
      />
    </Box>
  );
};

export const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxCustomProps>((props, ref) => {
  const { label, ...inputProps } = props;

  if (!label)
    return (
      <MuiCheckbox
        {...inputProps}
        ref={ref}
        icon={<CheckboxDefaultIcon disabled={props.disabled} />}
        checkedIcon={<CheckboxCheckedIcon disabled={props.disabled} />}
        indeterminateIcon={<CheckboxIndeterminateIcon disabled={props.disabled} />}
      />
    );

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          {...inputProps}
          ref={ref}
          icon={<CheckboxDefaultIcon disabled={props.disabled} />}
          checkedIcon={<CheckboxCheckedIcon disabled={props.disabled} />}
          indeterminateIcon={<CheckboxIndeterminateIcon disabled={props.disabled} />}
        />
      }
      label={label}
    />
  );
});
