import React from 'react';
import { SkeletonForm } from '../../../../components';

export const SectionIntegrationsSkeleton: React.VFC = () => {
  return (
    <SkeletonForm
      widthDivider
      numberOfFields={5}
      spacing={2}
      itemVariant="rectangular"
      itemSx={{ borderRadius: 1 }}
      itemHeight={30}
    />
  );
};
