import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import omitBy from 'lodash.omitby';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { useAuth } from '../../authorization';
import { PlatformType } from '../../shared/types';

type InputType = {
  sellerId?: string;
  authToken?: string;
  clientKey?: string;
  secretKey?: string;
  platformCode?: PlatformType;
};

type PlatformError = { response: { data: { errorCode: string; errorMessage: string } } };

export const usePlatformConnectionMutation = (
  platform: 'amz' | 'kauf',
): UseMutationResult<void, PlatformError, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    const requestTestConnectionBody: Partial<InputType> = omitBy(
      {
        sellerId: body.sellerId,
        authToken: body.authToken,
        clientKey: body.clientKey,
        secretKey: body.secretKey,
        platformCode: body.platformCode,
      },
      (val) => !val,
    );

    await envelope(
      httpClient.put<null, { errorCode: string; errorMessage: string }, InputType>(
        API.salesIntelligence(currentPartnerId).platformManageConnection(platform),
        requestTestConnectionBody,
      ),
    );

    return;
  });
};
