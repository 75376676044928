import React from 'react';
import { CardForm, Show } from 'components';
import { SectionProfileData, SectionProfileDataSkeleton } from './components';
import { useProfileDataQuery } from './hooks';

export const UserDataPage: React.FC = () => {
  const profileDataQuery = useProfileDataQuery();

  return (
    <CardForm>
      <Show when={profileDataQuery.isFetched} fallback={<SectionProfileDataSkeleton />}>
        <SectionProfileData />
      </Show>
    </CardForm>
  );
};
