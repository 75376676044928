import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImportIcon } from 'components/icons';
import { PlatformCode } from 'generated/graphql';
import { DatagridWrapper, ToolbarTable } from 'modules/datagrid';
import { ImportOffersPriceModal } from 'modules/offers/modals/modal-import-offers-price/import-offers-price.modal';
import { usePlatformQuery } from 'modules/settings-sales-platforms/hooks';
import { PlatformChannelCode } from 'modules/shared/types';
import { Button } from '@mui/material';
import { GridRowsProp, UncapitalizedGridSlotsComponent } from '@mui/x-data-grid-pro';
import { useOffersColumnsDefinition } from '../../hooks';
import { useOffersQuery } from '../../hooks/use-offers.query';
import { OfferGridRow } from '../../types';
import { TableOffersFooterBulkActions } from '../table-offers-footer-bulk-actions';
import { TableOffersExport } from './components/table-offers-export';
import { TableOffersContext } from './contexts';

type Props = {
  channel: PlatformChannelCode;
};

export const TableOffers: React.FC<Props> = (props) => {
  const columns = useOffersColumnsDefinition(props.channel);

  const { data, isFetching } = useOffersQuery(columns, props.channel);
  const { isFetched: platformDataIsFetched } = usePlatformQuery(
    props.channel === 'DEFAULT' ? PlatformCode.Kauf : PlatformCode.Amzeu,
  );

  const [tOffers] = useTranslation('offers');
  const [open, setImportOpen] = useState<boolean>(false);

  const rows: GridRowsProp<OfferGridRow> = (data?.offers?.nodes || []) as OfferGridRow[];

  // Important: never pass components to Grid without useMemo
  const components: Partial<UncapitalizedGridSlotsComponent> = useMemo(() => {
    return {
      toolbar: () => (
        <ToolbarTable columns={columns}>
          <Button
            color="flat"
            size="small"
            data-testid="import-button"
            onClick={() => setImportOpen(true)}
            startIcon={<ImportIcon sx={{ height: 24, width: 'auto' }} />}
          >
            {tOffers('actions.import')}
          </Button>
          <ImportOffersPriceModal isOpen={open} onClose={() => setImportOpen(false)} />

          <TableOffersExport channel={props.channel} />
        </ToolbarTable>
      ),
      footer: () => <TableOffersFooterBulkActions channelCode={props.channel} />,
    };
  }, [columns, props.channel, open]);

  return (
    <TableOffersContext.Provider value={props.channel ?? null}>
      <DatagridWrapper
        enableVirtualization
        // columnBuffer={25}
        // columnThreshold={30}
        // rowBuffer={20}
        rowHeight={96}
        checkboxSelection
        loading={isFetching && platformDataIsFetched}
        getRowId={(row) => row.offerKey}
        rows={rows}
        columns={columns}
        components={components}
        disableCenterValign
        forcedPinnedColumns={{
          left: ['__check__'],
        }}
      />
    </TableOffersContext.Provider>
  );
};
