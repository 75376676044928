import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { SpinnerIcon } from 'components/icons';
import { nethansaPalette } from 'theme';
import { Box, Stack, Typography } from '@mui/material';
import { MONITORING_LIMIT } from '../consts';
import { useMonitoringOffersQuery } from '../hooks';

export const LimitBarElement: React.FC = () => {
  const [t] = useTranslation('monitoring');
  const { data, isFetching } = useMonitoringOffersQuery([{ field: 'asin' }]);

  return (
    <Stack
      sx={{
        bgcolor: nethansaPalette.main.light,
        minWidth: '200px',
        justifyContent: 'center',
        alignItems: 'flex-end',
        pr: 3,
      }}
    >
      <Typography variant="h5Text" component="div" color={nethansaPalette.main.gray}>
        {t('limit-info-label')}
      </Typography>
      <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
        <Box>
          <Show when={!isFetching} fallback={<SpinnerIcon />}>
            {data?.monitoredOffers?.totalCount}
          </Show>
        </Box>
        <Box>&nbsp;{`/ ${MONITORING_LIMIT}`}</Box>
      </Typography>
    </Stack>
  );
};
