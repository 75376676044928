import React from 'react';
import { CardForm } from 'components';
import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { emptyArray } from '../../../../common/utils';

export const SectionVatRatesSkeleton: React.FC = () => {
  return (
    <form>
      <Box sx={{ px: 3, pb: 0, pt: 2 }}>
        <Skeleton height="50px" />
      </Box>
      <CardForm fullWidth>
        <Table>
          <TableHead>
            <TableRow>
              {emptyArray(3).map((v, i) => (
                <TableCell key={i}>
                  <Skeleton height="32px" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {emptyArray(6).map((value, index) => (
              <TableRow key={index}>
                {emptyArray(3).map((v, i) => (
                  <TableCell key={i}>
                    <Skeleton height="34px" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardForm>
    </form>
  );
};
