import React, { useEffect } from 'react';
// import { dateFormats } from 'common/format/date';
import { Box, Stack, TextField } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

type Props = GridFilterInputValueProps;

export const DateRangePickerOperator: React.FC<Props> = (props) => {
  const { item, applyValue } = props;
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

  const handleFilterChange = (value?: DateRange<Date>) => {
    if (value?.[0] && value?.[1]) {
      applyValue({ ...item, value: value });
    }
  };

  useEffect(() => {
    if (value?.[0] && value?.[1]) {
      handleFilterChange(value);
    }
  }, [value]);

  const inputLabelProps = {
    shrink: true,
    sx: {
      '&.MuiInputLabel-root': {
        transform: 'translate(-12px, -22px) scale(1)',
      },
    },
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 45,
        pt: 0.5,
        width: '100%',
      }}
    >
      <DateRangePicker
        //format={dateFormats.fullDate}
        value={item.value || [null, null]}
        onChange={(newValue) => {
          setValue(newValue as DateRange<Date>);
        }}
        renderInput={(startProps, endProps) => (
          <Stack direction="row" spacing={1}>
            <TextField {...startProps} InputLabelProps={inputLabelProps} />
            <TextField {...endProps} InputLabelProps={inputLabelProps} />
          </Stack>
        )}
      />
    </Box>
  );
};
