import { useTranslation } from 'react-i18next';
import {
  InputMaybe,
  OrderStatus,
  SaleRevenueProfitReportFilterInput,
  SaleRevenueProfitReportSortInput,
  SortEnumType,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useDatagridContext, useDatagridContextGraphqlTranslator } from 'modules/datagrid';
import { useExportDatagridData } from 'modules/shared';
import { FileType } from 'modules/shared/types';
import { useAuth } from '../../authorization';
import { useGlobalSettingsQuery } from '../../settings-sales-global';
import { SaleRevenueProfitGridColDefCustom } from '../types';

export const getSaleRevenueProfitQueryToExport = (columns: string[]) => {
  const parsedColumns = columns.map((x) => `${x}\n`);
  return gql`
    query SaleRevenueProfitReport(
      $partnerId: String!
      $first: Int!
      $after: String
      $sort: [SaleRevenueProfitReportSortInput!]
      $filter: SaleRevenueProfitReportFilterInput
    ) {
      saleRevenueProfitReport(
        partnerId: $partnerId
        first: $first
        after: $after
        where: $filter
        order: $sort
      ) {
        totalCount
        nodes {
          ...saleRevenueProfitReportNode
        }
        pageInfo {
          ...paginationMeta
        }
        edges {
          cursor
        }
      }
    }

    fragment saleRevenueProfitReportNode on SaleRevenueProfitReport {
      ${parsedColumns}
    }

    fragment paginationMeta on PageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  `;
};

type Result = {
  mutate: (fileType: FileType) => Promise<void>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

export const useSaleRevenueReportExport = (
  columns: SaleRevenueProfitGridColDefCustom[],
): Result => {
  const { currentPartnerId } = useAuth();
  const { visibleOrderedColumns } = useDatagridContext();
  const { data } = useGlobalSettingsQuery();
  const [, i18n] = useTranslation();

  const visibleColumns = [...visibleOrderedColumns[0]];

  const gridFilterSort = useDatagridContextGraphqlTranslator(columns, [
    'productName',
    'eAN',
    'category',
    'orderId',
  ]);

  const exportDatagridData = useExportDatagridData('saleRevenueProfitReport');

  return {
    ...exportDatagridData,
    mutate: async (fileType) => {
      const definedStatus = [OrderStatus.Unshipped, OrderStatus.Shipped, OrderStatus.Closed];
      const parsedFilter: InputMaybe<SaleRevenueProfitReportFilterInput> = {
        ...gridFilterSort.filter,
        or: definedStatus.map((x) => ({
          status: {
            eq: x,
          },
        })),
      };

      const parsedSort: InputMaybe<SaleRevenueProfitReportSortInput> = gridFilterSort.sort ?? {
        orderDate: SortEnumType.Desc,
      };

      const viewQuery = {
        query: getSaleRevenueProfitQueryToExport(visibleColumns),
        variables: {
          partnerId: String(currentPartnerId),
          first: gridFilterSort.first,
          after: gridFilterSort.after,
          sort: parsedSort,
          filter: parsedFilter,
        },
      };

      return exportDatagridData.mutate({
        columns: visibleColumns,
        language: i18n.language,
        fileType: fileType,
        viewQuery: JSON.stringify(viewQuery),
        partnerBaseCurrency: data?.baseCurrency || 'EUR',
      });
    },
  };
};
