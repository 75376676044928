import { PlatformChannelCode } from 'modules/shared/types';
import { useCommonTranslations } from '../../shared';

export const useLabelsSendToUtils = (channelCode: string | undefined): string => {
  const { tCommon } = useCommonTranslations();
  const channelCodeParsed: PlatformChannelCode = (channelCode || 'AMZFBA') as PlatformChannelCode;

  const dictionary: { [key in PlatformChannelCode]: string } = {
    AMZFBA: tCommon('send-to', { name: 'Amazon FBA' }),
    AMZFBM: tCommon('send-to', { name: 'Amazon FBM' }),
    DEFAULT: tCommon('send-to', { name: 'Kaufland' }),
  };

  return dictionary[channelCodeParsed] || dictionary['AMZFBA'];
};
