import React, { useCallback } from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { Checkbox } from 'components';
import { Box } from '@mui/material';

type Props = {
  name: string;
  control: Control;
  disabled?: boolean;
  onChange?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    field: ControllerRenderProps<any, string>,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
};

export const CheckboxCell: React.FC<Props> = (props) => {
  const handleOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (field: ControllerRenderProps<any, string>) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) props.onChange(field, event);
      else field.onChange(event);
    },
    [props.onChange],
  );

  return (
    <Box sx={{ textAlign: 'center', width: '100%' }}>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
          <Checkbox
            label={''}
            name={props.name}
            checked={field.value}
            onChange={handleOnChange(field)}
            onBlur={field.onBlur}
            disabled={props.disabled}
          />
        )}
      />
    </Box>
  );
};
