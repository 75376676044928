import React from 'react';
import { PlatformChannelCode } from 'modules/shared/types';
import { ButtonBase, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { OfferGridColumn } from '../../modules/offers';
import { useOffersTotalCountQuery } from '../../modules/offers/hooks/use-offers-count.query';
import { SpinnerIcon } from '../icons';
import { Show } from '../show';

type Props = {
  id: string;
  count?: number;
  label: string;
  onClick: () => void;
  channel: string;
  active?: boolean;
  filters: GridFilterModel;
  columns?: OfferGridColumn[];
};

export const TabWithCount: React.FC<Props> = (props) => {
  const { data, isLoading } = useOffersTotalCountQuery(
    props.id,
    props.columns || [],
    props.filters,
    props.channel as PlatformChannelCode,
  );

  const rootStyle: SxProps<Theme> = {
    display: 'flex',
    direction: 'row',
    border: 'none',
    borderColor: 'nethansa.button.grayHover',
    borderWidth: '1px',
    borderRadius: '6px',
    padding: 0,
    margin: 0,
    height: '32px',
  };

  const stackStyle: SxProps<Theme> = {
    height: '100%',
  };

  const activeStyles: SxProps<Theme> = {
    borderColor: 'nethansa.button.blueMain',
    backgroundColor: 'nethansa.button.blueMain',
    color: 'white',
  };

  const countStyle: SxProps<Theme> = {
    px: 1.5,
    height: '100%',
    borderColor: 'nethansa.button.grayHover',
    borderStyle: 'solid',
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderLeftWidth: '1px',
    borderTopWidth: '1px',
    borderBottomWidth: '2px',
    borderRightWidth: '0',
    backgroundColor: 'nethansa.line.light',
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: '700',
    ...(props.active ? { ...activeStyles } : {}),
  };

  const labelStyle: SxProps<Theme> = {
    pl: 1,
    pr: 1.5,
    height: '100%',
    borderColor: 'nethansa.button.grayHover',
    borderStyle: 'solid',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
    borderRightWidth: '1px',
    borderTopWidth: '1px',
    borderBottomWidth: '2px',
    borderLeftWidth: '0',
    backgroundColor: 'white',
    lineHeight: '24px',
    fontSize: '13px',
    ...(props.active ? { borderColor: 'nethansa.button.blueMain' } : {}),
  };

  return (
    <ButtonBase onClick={props.onClick} sx={rootStyle}>
      <Stack sx={stackStyle} direction="row" spacing={0} alignItems="flex-start">
        <Stack sx={countStyle} justifyContent="center">
          <Show when={data !== undefined && !isLoading}>{data || 0}</Show>
          <Show when={isLoading}>
            <SpinnerIcon sx={{ fontSize: '26px', px: 0, mx: 0 }} />
          </Show>
        </Stack>
        <Stack sx={labelStyle} justifyContent="center">
          {props.label}
        </Stack>
      </Stack>
    </ButtonBase>
  );
};
