export enum GridTableIds {
  Unassigned,
  SalesRevenue,
  OrderReturns,
  Taxes,
  SalesRevenueProfit,
  OffersAMZFBM,
  OffersAMZFBA,
  OffersKAUFLAND,
  Products,
  Monitoring,
}
