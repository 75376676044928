import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import { useAuth } from 'modules/authorization';
import { ErrorMeta } from 'modules/shared/types';
import { settingsDefaultMutationKeys } from '../../../../../common/query-client';
import { ShipFromAddressType } from '../types';

type InputType = Omit<ShipFromAddressType, 'countries' | 'vatId'>;

export const useShipFromAddressMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();
  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    return await envelope(httpClient.put(API.partnerCarrier(currentPartnerId).address(), body));
  });
};
