import { WarningIcon } from 'components/icons';
import { ProductGridDtoFilterInput } from 'generated/graphql';
import { mapSelectedProductIdsToFilter } from 'modules/datagrid';
import { useProductsCoordinatorContext } from 'modules/products/hooks';
import { ProductGridRow } from 'modules/products/types';
import { useCommonTranslations } from 'modules/shared';
import { nethansaPalette } from 'theme';
import { IconButton, Stack, Typography } from '@mui/material';

type Props = {
  product: ProductGridRow;
};

export const DataGridProductCostCell: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();

  const {
    acceptSuspectedCost: [, setAcceptSuspectedCost],
  } = useProductsCoordinatorContext();

  return (
    <Stack direction="row">
      {props.product.suspiciousProductCost !== null && (
        <IconButton
          onClick={() => {
            setAcceptSuspectedCost({
              row: props.product,
              filters: mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>([props.product.id])
                .filter,
            });
          }}
          sx={{ p: 0 }}
        >
          <WarningIcon
            sx={{
              width: '18px',
              height: '18px',
              color: nethansaPalette.status.opBefore.dark,
            }}
          />
        </IconButton>
      )}

      <Typography variant="h5Text" sx={{ color: '#B0B1BB', mr: '4px' }}>
        {tCommon(`currencies.${props.product.baseCurrencyCode || 'EUR'}` as 'currencies.EUR')}
      </Typography>
      <Typography variant="h5Text">{props.product.productCost}</Typography>
    </Stack>
  );
};
