import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const AddMarketplaceIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6H16C16.55 6 17 5.55 17 5C17 4.45 16.55 4 16 4H3C2.45 4 2 4.45 2 5C2 5.55 2.45 6 3 6Z"
        fill="currentColor"
      />

      <path
        d="M15 17H17V14H17.18C17.81 14 18.28 13.42 18.16 12.8L17.16 7.8C17.07 7.34 16.66 7 16.18 7H2.82001C2.34001 7 1.93001 7.34 1.84001 7.8L0.840013 12.8C0.720013 13.42 1.19001 14 1.82001 14H2.00001V19C2.00001 19.55 2.45001 20 3.00001 20H10C10.55 20 11 19.55 11 19V14H15V17ZM9.00001 18H4.00001V14H9.00001V18Z"
        fill="currentColor"
      />

      <path
        d="M22 18H20V16C20 15.45 19.55 15 19 15C18.45 15 18 15.45 18 16V18H16C15.45 18 15 18.45 15 19C15 19.55 15.45 20 16 20H18V22C18 22.55 18.45 23 19 23C19.55 23 20 22.55 20 22V20H22C22.55 20 23 19.55 23 19C23 18.45 22.55 18 22 18Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
