import React, { useCallback, useEffect, useReducer } from 'react';
import { API, httpClient } from 'common/api';
import { Show } from 'components';
import { environment } from 'environment';
import { ErrorMeta, ErrorResponseAPIType } from '../../shared/types';
import {
  clearCredentials,
  clearTempPartnerId,
  decodeToken,
  getCredentials,
  getTempPartnerId,
  hasValidCredentials,
  setCredentials,
  setTempPartnerId,
} from '../helpers';
import { LoginModal } from '../login.modal';
import { PartnerSelectorModal } from '../partner-selector.modal';
import { AuthenticationInputType, AuthenticationResponseType, AuthenticationType } from '../types';

type State = {
  initialized: boolean;
  authorized: boolean;
  withCredentials: boolean;
  partnerIds: string[];
  currentPartnerId: string | null;
};

export type AuthorizationContextType = State & {
  login: (
    body: { email: string; password: string },
    callbacks?: {
      onSuccess?: (data: AuthenticationType) => void;
      onError?: (error?: ErrorMeta) => void;
    },
  ) => void;
  logout: () => void;
  setPartnerId: (partnerId: string) => void;
};

type ActionType = 'UPDATE';

const reducer = (state: State, action: { type: ActionType; payload: Partial<State> }): State => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const initialState: State = {
  initialized: environment.ENV !== 'development' || hasValidCredentials(),
  authorized: environment.ENV !== 'development' || hasValidCredentials(),
  withCredentials: environment.ENV !== 'development',
  currentPartnerId: getTempPartnerId(),
  partnerIds: [],
};

export const AuthorizationContext = React.createContext<AuthorizationContextType>({
  ...initialState,
  logout: () => null,
  login: () => null,
  setPartnerId: () => null,
});

type Props = React.PropsWithChildren<{
  partnerId?: string;
}>;

export const AuthorizationProvider: React.FC<Props> = (props) => {
  const [state, dispatch] = useReducer(
    reducer,
    props.partnerId
      ? {
          ...initialState,
          currentPartnerId: props.partnerId || getTempPartnerId(),
        }
      : initialState,
  );

  const login = useCallback(
    async (
      body: { email: string; password: string },
      callbacks?: {
        onSuccess?: (data: AuthenticationType) => void;
        onError?: (error?: ErrorMeta) => void;
      },
    ) => {
      try {
        const result = await httpClient.post<
          void,
          { data: AuthenticationResponseType },
          AuthenticationInputType
        >(API.authentication().tokens(), body);

        const data = result.data.data;

        setCredentials(data.token, data.refreshToken);

        const decodedToken = decodeToken(data.token || '');

        dispatch({
          type: 'UPDATE',
          payload: {
            authorized: true,
            partnerIds:
              typeof decodedToken.partnerIds === 'string'
                ? decodedToken.partnerIds.split(';')
                : decodedToken.partnerIds,
          },
        });
        callbacks && callbacks?.onSuccess && callbacks?.onSuccess(data);

        return;
      } catch (err) {
        const error = err as ErrorResponseAPIType<void>;

        dispatch({
          type: 'UPDATE',
          payload: {
            authorized: false,
          },
        });

        callbacks && callbacks?.onError && callbacks?.onError(error?.response?.data?.error);
        return;
      }
    },
    [dispatch],
  );

  const setPartnerId = useCallback(
    (partnerId: string) => {
      if (!state.partnerIds.some((x) => x === partnerId)) return;

      setTempPartnerId(partnerId);
      if (!httpClient.defaults.params) httpClient.defaults.params = {};
      if (partnerId) httpClient.defaults.params['partnerId'] = partnerId;

      dispatch({
        type: 'UPDATE',
        payload: {
          currentPartnerId: partnerId,
        },
      });
    },
    [state.partnerIds, dispatch],
  );

  const logout = useCallback(() => {
    clearCredentials();
    clearTempPartnerId();
    dispatch({
      type: 'UPDATE',
      payload: {
        partnerIds: [],
        authorized: false,
      },
    });
  }, []);

  useEffect(() => {
    if (state.withCredentials) return;

    const credentials = getCredentials();

    if (!credentials || credentials.refreshTokenExpired < Date.now()) {
      return dispatch({
        type: 'UPDATE',
        payload: {
          initialized: true,
        },
      });
    }

    if (!credentials.token) return;

    const decodedToken = decodeToken(credentials.token || '');

    return dispatch({
      type: 'UPDATE',
      payload: {
        authorized: true,
        initialized: true,
        partnerIds:
          typeof decodedToken.partnerIds === 'string'
            ? decodedToken.partnerIds.split(';')
            : decodedToken.partnerIds,
      },
    });
  }, [dispatch]);

  const showLoginModal = state.initialized && !state.authorized;
  const showPartnerSelectionModal =
    state.initialized && state.authorized && !state.currentPartnerId;

  const value: AuthorizationContextType = {
    ...state,
    login,
    logout,
    setPartnerId,
  };

  return (
    <AuthorizationContext.Provider value={value}>
      {props.children}
      <Show when={showLoginModal}>
        <LoginModal />
      </Show>
      <Show when={showPartnerSelectionModal}>
        <PartnerSelectorModal />
      </Show>
    </AuthorizationContext.Provider>
  );
};
