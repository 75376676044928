import React from 'react';
import { SaveIcon } from 'components/icons';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';

type Props = React.PropsWithChildren<{
  anchorEl: null | HTMLElement;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  buttonLabel?: string;
}>;

export const PopupViewSaveMenu: React.FC<Props> = (props) => (
  <>
    <Button
      size="small"
      color="flat"
      onClick={props.onClick}
      startIcon={<SaveIcon sx={{ height: 24, width: 'auto' }} />}
    >
      {props.buttonLabel}
    </Button>

    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id="menu-list-views"
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={() => props.onClose()}
      MenuListProps={{
        sx: {
          py: 1.5,
          px: 2,
        },
      }}
      PaperProps={{
        style: {
          maxHeight: '480px',
          maxWidth: '232px',
          boxShadow: '0px 0px 10px 0px #4040404D',
        },
      }}
    >
      {props.children}
    </Menu>
  </>
);
