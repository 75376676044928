interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  styles?: React.CSSProperties;
}

export const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: 0, width: '100%', height: '100%', ...other.styles }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
