import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, SelectField, Tooltip } from 'components';
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useVatRatesQuery } from '../../../hooks/use-vat-rates.query';
import { VATRatesFormProps } from '../section-vat-rates';

type Props = {
  control: Control<VATRatesFormProps>;
  isLoading?: boolean;
};

export const TableVatRates: React.FC<Props> = (props) => {
  const [t] = useTranslation('settings');

  const vatRatesQuery = useVatRatesQuery();

  const isVatOssValue = useWatch({
    control: props.control,
    name: 'isVatOss',
  });

  const taxRatesValue = useWatch({
    control: props.control,
    name: 'taxRates',
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('vat-rates.table.country.header')}</TableCell>
          <TableCell>
            <Stack direction="row">
              <Box sx={{ mr: 1 }}>{t('vat-rates.table.vatRegistration.header')}</Box>
              <Tooltip content={t('vat-rates.table.vatRegistration.tooltip.__html')} />
            </Stack>
          </TableCell>
          <TableCell>
            <Stack direction="row">
              <Box sx={{ mr: 1 }}>{t('vat-rates.table.defaultVATRate.header')}</Box>
              <Tooltip content={t('vat-rates.table.defaultVATRate.tooltip.__html')} />
            </Stack>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {vatRatesQuery.data?.taxRates.map((x, i) => {
          const isSelected: boolean = taxRatesValue.find((y) => y.id === x.id)?.isActive || false;
          return (
            <TableRow key={x.countryCode}>
              <TableCell>{x.country}</TableCell>
              <TableCell align="center" sx={{ textAlign: 'center' }}>
                <Box sx={{ mr: 3, maxWidth: '128px', textAlign: 'center' }}>
                  {x.isMainShippingCountry && <Checkbox label="" value checked disabled />}
                  {!x.isMainShippingCountry && (
                    <Controller
                      control={props.control}
                      name={`taxRates.${i}.isActive`}
                      render={({ field }) => {
                        return (
                          <Checkbox
                            value={field.value}
                            checked={!!field.value}
                            label=""
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                          />
                        );
                      }}
                    />
                  )}
                </Box>
              </TableCell>
              <TableCell sx={{ width: '180px' }}>
                <Controller
                  control={props.control}
                  name={`taxRates.${i}.taxRate`}
                  rules={{
                    required: isSelected && x.countryCode !== 'GB',
                  }}
                  render={({ field, fieldState }) => {
                    const disabled = !isSelected && !isVatOssValue;

                    return (
                      <Box sx={{ m: 0 }}>
                        <SelectField
                          value={field.value !== null ? String(field.value) : ''}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          placeholder={t('form.defaultVATRate.placeholder')}
                          disabled={x.countryCode === 'GB' ? false : disabled || props.isLoading}
                          sx={{ m: 0, maxWidth: '128px' }}
                          options={x.availableTaxRates
                            .sort((a, b) => b - a)
                            .map((x) => ({
                              value: String(x),
                              children: `${x} %`,
                            }))}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      </Box>
                    );
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
