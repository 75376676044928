import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ClaimDefenderRoutLayout } from 'modules/authorization';
import {
  BillingDataSettingsLayout,
  BillingDataSettingsPage,
  PaymentsSettingsPage,
} from 'modules/settings-billing-data';
import {
  CarriersLayout,
  IntegrationsPage,
  ShipFromAddressPage,
  ShippingCountryPage,
} from 'modules/settings-carriers';
import {
  SettingsConfigurationLayout,
  SettingsConfigurationPage,
  SettingsPageLayout,
} from 'modules/settings-configuration';
import { SettingsSalesLayout } from 'modules/settings-sales';
import { SettingsSalesGlobalPage } from 'modules/settings-sales-global';
import { SettingsSalesPlatformsPage } from 'modules/settings-sales-platforms';
import { SettingsSalesVatRatesPage } from 'modules/settings-sales-vat-rates';
import {
  ChangePasswordPage,
  Security2faPage,
  UserDataPage,
  UserProfileLayout,
} from 'modules/settings-user-profile';
import { routes } from '../routes';

export const SettingsRouter: React.FC = () => {
  const salesIntelligenceRoutes = routes.Settings.children.SalesIntelligence;
  const userProfileRoutes = routes.Settings.children.UserProfile;
  const carriersRoutes = routes.Settings.children.Carriers;
  const paymentAndBillingsRoutes = routes.Settings.children.PaymentsAndBillings;

  return (
    <Routes>
      <Route element={<ClaimDefenderRoutLayout allowed={'SETTINGS'} />}>
        <Route element={<SettingsConfigurationLayout />}>
          <Route element={<SettingsPageLayout />}>
            <Route index element={<SettingsConfigurationPage />} />
          </Route>

          <Route element={<ClaimDefenderRoutLayout allowed={'SETTINGS_SALES_INTELLIGENCE'} />}>
            <Route path={salesIntelligenceRoutes.name} element={<SettingsSalesLayout />}>
              <Route
                element={
                  <ClaimDefenderRoutLayout allowed={'SETTINGS_SALES_INTELLIGENCE_PLATFORMS'} />
                }
              >
                <Route index element={<SettingsSalesPlatformsPage />} />
              </Route>
              <Route
                element={
                  <ClaimDefenderRoutLayout allowed={'SETTINGS_SALES_INTELLIGENCE_VAT_RATES'} />
                }
              >
                <Route
                  path={salesIntelligenceRoutes.children.VatRates.name}
                  element={<SettingsSalesVatRatesPage />}
                />
              </Route>
              <Route
                element={
                  <ClaimDefenderRoutLayout
                    allowed={'SETTINGS_SALES_INTELLIGENCE_GLOBAL_SETTINGS'}
                  />
                }
              >
                <Route
                  path={salesIntelligenceRoutes.children.GlobalSettings.name}
                  element={<SettingsSalesGlobalPage />}
                />
              </Route>
            </Route>
          </Route>

          <Route element={<ClaimDefenderRoutLayout allowed={'SETTINGS_YOUR_PROFILE'} />}>
            <Route path={userProfileRoutes.name} element={<UserProfileLayout />}>
              <Route
                element={
                  <ClaimDefenderRoutLayout allowed={'SETTINGS_YOUR_PROFILE_PERSONAL_DATA'} />
                }
              >
                <Route index element={<UserDataPage />} />
              </Route>
              <Route
                element={
                  <ClaimDefenderRoutLayout allowed={'SETTINGS_YOUR_PROFILE_CHANGE_PASSWORD'} />
                }
              >
                <Route
                  path={userProfileRoutes.children.ChangePassword.name}
                  element={<ChangePasswordPage />}
                />
              </Route>
              <Route
                element={<ClaimDefenderRoutLayout allowed={'SETTINGS_YOUR_PROFILE_SECURITY'} />}
              >
                <Route
                  path={userProfileRoutes.children.Security.name}
                  element={<Security2faPage />}
                />
              </Route>
            </Route>
          </Route>

          <Route element={<ClaimDefenderRoutLayout allowed={'SETTINGS_CARRIERS'} />}>
            <Route path={carriersRoutes.name} element={<CarriersLayout />}>
              <Route
                element={<ClaimDefenderRoutLayout allowed={'SETTINGS_CARRIERS_INTEGRATIONS'} />}
              >
                <Route index element={<IntegrationsPage />} />
              </Route>
              <Route
                element={
                  <ClaimDefenderRoutLayout allowed={'SETTINGS_CARRIERS_SHIPPING_COUNTRIES'} />
                }
              >
                <Route
                  path={carriersRoutes.children.ShippingCountry.name}
                  element={<ShippingCountryPage />}
                />
              </Route>
              <Route
                element={
                  <ClaimDefenderRoutLayout allowed={'SETTINGS_CARRIERS_SHIP_FORM_ADDRESS'} />
                }
              >
                <Route
                  path={carriersRoutes.children.ShipFromAddress.name}
                  element={<ShipFromAddressPage />}
                />
              </Route>
            </Route>
          </Route>

          <Route element={<ClaimDefenderRoutLayout allowed={'SETTINGS_PAYMENTS_AND_BILLINGS'} />}>
            <Route path={paymentAndBillingsRoutes.name} element={<BillingDataSettingsLayout />}>
              <Route
                index
                element={<Navigate to={paymentAndBillingsRoutes.children.Payments.link()} />}
              />
              <Route
                path={paymentAndBillingsRoutes.children.Payments.name}
                element={<PaymentsSettingsPage />}
              />
              <Route
                element={
                  <ClaimDefenderRoutLayout
                    allowed={'SETTINGS_PAYMENTS_AND_BILLINGS_BILLING_DATA'}
                  />
                }
              >
                <Route
                  path={paymentAndBillingsRoutes.children.BillingData.name}
                  element={<BillingDataSettingsPage />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
