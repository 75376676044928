import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { useOfferCoordinatorContext } from './use-offer-coordinator-context';

export const useRawPriceCalculationQuery = (): UseQueryResult<string> => {
  const { authorized, currentPartnerId } = useAuth();
  const {
    rawPriceCalculation: [offerId],
  } = useOfferCoordinatorContext();

  return useQuery<string>(
    [],
    async () => {
      const response = await httpClient.get<ResponseAPIType<string>>(
        API.offers(currentPartnerId).rawPriceCalculation(encodeURIComponent(offerId || '')),
      );
      return response.data.data;
    },
    {
      staleTime: 0,
      keepPreviousData: false,
      enabled: authorized,
      retry: 0,
    },
  );
};
