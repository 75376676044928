import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectMultiCheckboxField } from 'components/input-fields/select-multi-checkbox-field';
import { Box, Divider, Stack } from '@mui/material';
import { ValueOptions } from '@mui/x-data-grid-pro';
import { DatagridCellFlag } from '../../modules/datagrid';
import { PlatformPartnerChannelMarketplace } from '../../modules/settings-sales-platforms/types';

type Props = {
  marketplaces: PlatformPartnerChannelMarketplace[] | ValueOptions[];
  onChange: (value?: string[] | null) => void;
  items: string[];
  placeholder?: string;
  error?: boolean;
};

export const SelectMultiCheckboxMarketplace: React.FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef: React.Ref<any> = React.useRef(null);
  const [tPlatform] = useTranslation('platforms');

  const options = props.marketplaces.map((x) => ({
    value: x.countryCode || x.value,
    sx: {
      padding: 0,
    },
    children: (
      <DatagridCellFlag
        value={x.countryCode || x.value}
        label={x.label || tPlatform(`amazon.markets.website.${x.countryCode as 'DE'}`) || 'unknown'}
      />
    ),
  }));

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 40,
        width: '100%',
      }}
    >
      <SelectMultiCheckboxField
        value={props.items}
        inputRef={inputRef}
        placeholder={props.placeholder}
        options={options}
        onChange={props.onChange}
        error={props.error}
        sx={{
          width: '100%',
          margin: 0,
        }}
        selectSx={{
          minHeight: 28,
        }}
        labelSx={{
          top: '-16px',
        }}
        renderValue={(value) => (
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            divider={<Divider orientation="vertical" color="black" sx={{ height: '20px' }} />}
            sx={{
              '>:not(style)+:not(style)': { marginInline: 0, mb: '4px' },
              '>:not(style)+hr': { marginInline: '8px' },
            }}
          >
            {value.map((x) => (
              <Box sx={{ width: '130px' }} key={x}>
                <DatagridCellFlag
                  value={x}
                  label={tPlatform(`amazon.markets.website.${x as 'DE'}`) || 'unknown'}
                />
              </Box>
            ))}
          </Stack>
        )}
      />
    </Box>
  );
};
