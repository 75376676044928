import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const BigSuccessIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8724_23671)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2C7.8203 2 2 7.8203 2 15C2 22.1797 7.8203 28 15 28C22.1797 28 28 22.1797 28 15C28 7.8203 22.1797 2 15 2ZM0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z"
          fill="#00D791"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7008 11.2866C21.0947 11.6736 21.1004 12.3068 20.7134 12.7008L13.8374 19.7008C13.6403 19.9014 13.3679 20.0098 13.0868 19.9993C12.8058 19.9889 12.5421 19.8606 12.3605 19.6458L9.23652 15.9528C8.87983 15.5312 8.9325 14.9002 9.35415 14.5435C9.7758 14.1868 10.4068 14.2395 10.7635 14.6612L13.1791 17.5168L19.2866 11.2992C19.6736 10.9052 20.3068 10.8996 20.7008 11.2866Z"
          fill="#00D791"
        />
      </g>
      <defs>
        <clipPath id="clip0_8724_23671">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
