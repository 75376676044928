import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { MonitoredOfferHistory } from '../types';

export const useMonitoringOfferHistoryQuery = (
  offerId: string,
): UseQueryResult<MonitoredOfferHistory[]> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<MonitoredOfferHistory[]>(
    ['monitoring', 'offer-history', currentPartnerId, offerId],
    async () => {
      const response = await httpClient.get<ResponseAPIType<MonitoredOfferHistory[]>>(
        API.monitoring(currentPartnerId).history(offerId),
      );
      return [...response.data.data];
    },
    {
      staleTime: 1000 * 60 * 10,
      keepPreviousData: false,
      enabled: authorized,
      retry: false,
    },
  );
};
