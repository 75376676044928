import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import { useAuth } from 'modules/authorization/hooks';
import { ErrorMeta } from 'modules/shared/types';
import { AddOffersToTrackInput } from '../types';

export const useAddOffersToTrackMutation = (): UseMutationResult<
  void,
  ErrorMeta,
  AddOffersToTrackInput
> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    return await envelope(httpClient.put(API.monitoring(currentPartnerId).create(), body));
  });
};
