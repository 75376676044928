import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { parseFloatValueWithUnit } from 'common/utils';
import { Show, TextFieldNumber } from 'components';
import { usePriceSettingsQuery } from 'modules/offers/hooks';
import { useCurrenciesTranslations } from 'modules/shared';
import { CurrencyCode } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import { Box, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CellCheckbox, ColumnHeader, FirstColumnCell } from '../../components';

type Props = {
  disabled: boolean;
};

export const PriceRangeTab: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  const [tCurrency] = useCurrenciesTranslations();

  const { control, setValue, resetField } = useFormContext();
  const { data } = usePriceSettingsQuery();

  const validationRangeRules = {
    markupMin: {
      min: -25,
      max: 99,
    },
    markupStd: {
      min: 0,
      max: 999,
    },
    markupMax: {
      min: 0,
      max: 999,
    },
    returnsReserve: {
      min: 0,
      max: 25,
    },
    shipping: {
      min: 0,
      max: 999,
    },
    addCost: {
      min: 0,
      max: 999,
    },
    priceFixedMin: {
      min: 0.1,
      max: 999999,
    },
    priceFixedMax: {
      min: 0.1,
      max: 999999,
    },
  };

  const isSingleOfferAction = data?.isSingleOffer || false;

  const useDefaultReturnReserveValue = useWatch({
    control,
    name: 'parametersSettings.useDefaultReturnReserve',
  });

  const useOfferReturnReserveValue = useWatch({
    control,
    name: 'parametersSettings.returnReserve',
  });

  const useDefaultMinimalMarkupValue = useWatch({
    control,
    name: 'markupSettings.useDefaultMinimalMarkup',
  });

  const useOfferMinimalMarkupValue = useWatch({
    control,
    name: 'markupSettings.minimalMarkup',
  });

  const useDefaultStandardMarkupValue = useWatch({
    control,
    name: 'markupSettings.useDefaultStandardMarkup',
  });

  const useOfferStandardMarkupValue = useWatch({
    control,
    name: 'markupSettings.standardMarkup',
  });

  const useOfferMaximumMarkupValue = useWatch({
    control,
    name: 'markupSettings.maximumMarkup',
  });

  const useDefaultMaximumMarkupValue = useWatch({
    control,
    name: 'markupSettings.useDefaultMaximumMarkup',
  });

  const useDefaultShippingCostValue = useWatch({
    control,
    name: 'parametersSettings.useDefaultShippingCost',
  });

  const useOfferShippingCostValue = useWatch({
    control,
    name: 'parametersSettings.shippingCost',
  });

  const useDefaultAdditionalCostValue = useWatch({
    control,
    name: 'parametersSettings.useDefaultAdditionalCost',
  });

  const useOfferAdditionalCostValue = useWatch({
    control,
    name: 'parametersSettings.additionalCost',
  });

  const useOfferFixedMinimumPriceValue = useWatch({
    control,
    name: 'fixedPricesSettings.fixedMinimumPrice',
  });

  const usePriceManagementMode = useWatch({
    control,
    name: 'repricerSettings.priceManagementMode',
  });

  const finalMinMarkup = isSingleOfferAction
    ? useDefaultMinimalMarkupValue
      ? data?.defaultPriceSettings.markupSettings.minimalMarkup
      : useOfferMinimalMarkupValue
    : useOfferMinimalMarkupValue;

  const finalStdMarkup = isSingleOfferAction
    ? useDefaultStandardMarkupValue
      ? data?.defaultPriceSettings.markupSettings.standardMarkup
      : useOfferStandardMarkupValue
    : useOfferStandardMarkupValue;

  const finalMaxMarkup = isSingleOfferAction
    ? useDefaultMaximumMarkupValue && data?.defaultPriceSettings.markupSettings.maximumMarkup
      ? data?.defaultPriceSettings.markupSettings.maximumMarkup
      : useOfferMaximumMarkupValue || validationRangeRules.markupMax.max
    : useOfferMaximumMarkupValue;

  const handleOfferValue = (value: boolean | null, checkboxName: string) => {
    isSingleOfferAction ? setValue(checkboxName, value) : setValue(checkboxName, false);
  };

  const isProductCostMissing =
    isSingleOfferAction &&
    (data?.offerPriceSettings.parametersSettings.productCostInMarketplaceCurrency.value === 0 ||
      data?.offerPriceSettings.parametersSettings.productCostInMarketplaceCurrency.value === null);

  const baseCurrency = tCurrency(`${data?.partnerBaseCurrencyCode || 'EUR'}.short`);

  const marketCurrency = isSingleOfferAction
    ? tCurrency(`${(data?.offerPriceSettingsParams?.currencyCode as CurrencyCode) || 'EUR'}.short`)
    : '$$';

  const getValue = (val?: number, unit?: string) => {
    if (!isSingleOfferAction) return undefined;
    return !val || val === 0
      ? t('modals.price-settings.no-set')
      : parseFloatValueWithUnit(val, 2, unit || '%');
  };

  const rowStyles: SxProps<Theme> = {
    height: '56px',
    flexDirection: 'row',
    alignItems: 'center',
    '& > div:not(:first-of-type)': {
      backgroundColor: nethansaPalette.background.title,
      height: '100%',
      width: '112px',
    },
    '& > div:first-of-type': {
      width: '160px',
    },
    '& > div:nth-of-type(2)': {
      width: isSingleOfferAction ? '112px' : '96px',
    },
  };

  return (
    <>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: isSingleOfferAction ? 0 : 2,
        }}
      >
        <Box>
          <ColumnHeader
            label={t('modals.price-settings.columnHeader.price-range-cost.label')}
            tooltip={t('modals.price-settings.columnHeader.price-range-cost.__html')}
          />

          <Stack sx={{ ...rowStyles, height: isSingleOfferAction ? '36px' : '52px', mt: 2 }}>
            <Box></Box>
            <ColumnHeader
              isSmall
              sx={{ p: '10px' }}
              label={t('modals.price-settings.columnHeader.default.label')}
            />
            <ColumnHeader
              isSmall
              sx={{ p: '10px' }}
              label={t('modals.price-settings.columnHeader.individual.label')}
            />
          </Stack>

          <Controller
            control={control}
            name={'markupSettings.minimalMarkup'}
            rules={{
              required: {
                value:
                  isSingleOfferAction &&
                  !useDefaultMinimalMarkupValue &&
                  useOfferMinimalMarkupValue === null,
                message: t('form.minimalMarkup.validation.required'),
              },
              min: {
                value: validationRangeRules.markupMin.min,
                message: t('form.validation-generic.min', {
                  min: validationRangeRules.markupMin.min,
                }),
              },
              max: {
                value: validationRangeRules.markupMin.max,
                message: t('form.validation-generic.max', {
                  max: validationRangeRules.markupMin.max,
                }),
              },
              validate: () => {
                if (!isSingleOfferAction) return;

                return (
                  Number(finalMinMarkup) <= Number(finalStdMarkup) ||
                  t('modals.price-settings.parameters.markup-min.warning-too-high-value')
                );
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Stack sx={rowStyles}>
                  <FirstColumnCell
                    cellContent={{
                      text: t('form.minimalMarkup.label'),
                      tooltip: t('form.minimalMarkup.tooltip.__html'),
                    }}
                  />

                  <CellCheckbox
                    control={control}
                    disabled={
                      props.disabled || (useOfferMinimalMarkupValue === null && isSingleOfferAction)
                    }
                    name={'markupSettings.useDefaultMinimalMarkup'}
                    label={getValue(data?.defaultPriceSettings?.markupSettings.minimalMarkup)}
                  />
                  <Stack sx={{ justifyContent: 'center', p: 1 }}>
                    <TextFieldNumber
                      disabled={
                        props.disabled || (field.value !== null && useDefaultMinimalMarkupValue)
                      }
                      onChange={(value) => {
                        if (value === null) resetField('markupSettings.minimalMarkup');
                        handleOfferValue(value === null, 'markupSettings.useDefaultMinimalMarkup');
                        field.onChange(value);
                      }}
                      onBlur={field.onBlur}
                      value={field.value}
                      unit={'%'}
                      precision={2}
                      error={!!fieldState.error}
                      tooltipError={fieldState.error?.message}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          />

          <Controller
            control={control}
            name={'markupSettings.standardMarkup'}
            rules={{
              required: {
                value:
                  isSingleOfferAction &&
                  !useDefaultStandardMarkupValue &&
                  useOfferStandardMarkupValue === null,
                message: t('form.standardMarkup.validation.required'),
              },
              min: {
                value: validationRangeRules.markupStd.min,
                message: t('form.validation-generic.min', {
                  min: validationRangeRules.markupStd.min,
                }),
              },
              max: {
                value: validationRangeRules.markupStd.max,
                message: t('form.validation-generic.max', {
                  max: validationRangeRules.markupStd.max,
                }),
              },
              validate: () => {
                if (!isSingleOfferAction) return;

                return (
                  Number(finalStdMarkup) >= Number(finalMinMarkup) ||
                  t('modals.price-settings.parameters.markup-std.warning-too-low-value')
                );
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Stack sx={rowStyles}>
                  <FirstColumnCell
                    cellContent={{
                      text: t('form.standardMarkup.label'),
                      tooltip: t('form.standardMarkup.tooltip.__html'),
                    }}
                  />

                  <CellCheckbox
                    control={control}
                    disabled={
                      props.disabled ||
                      (useOfferStandardMarkupValue === null && isSingleOfferAction)
                    }
                    name={'markupSettings.useDefaultStandardMarkup'}
                    label={getValue(data?.defaultPriceSettings?.markupSettings.standardMarkup)}
                  />
                  <Stack sx={{ justifyContent: 'center', p: 1 }}>
                    <TextFieldNumber
                      disabled={
                        props.disabled || (field.value !== null && useDefaultStandardMarkupValue)
                      }
                      onChange={(value) => {
                        if (value === null) resetField('markupSettings.standardMarkup');
                        handleOfferValue(value === null, 'markupSettings.useDefaultStandardMarkup');
                        field.onChange(value);
                      }}
                      onBlur={field.onBlur}
                      value={field.value}
                      unit={'%'}
                      precision={2}
                      error={!!fieldState.error}
                      tooltipError={fieldState.error?.message}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          />

          <Controller
            control={control}
            name={'markupSettings.maximumMarkup'}
            rules={{
              min: {
                value: validationRangeRules.markupMax.min,
                message: t('form.validation-generic.min', {
                  min: validationRangeRules.markupMax.min,
                }),
              },
              max: {
                value: validationRangeRules.markupMax.max,
                message: t('form.validation-generic.max', {
                  max: validationRangeRules.markupMax.max,
                }),
              },
              validate: () => {
                if (!isSingleOfferAction) return;

                return (
                  Number(finalMaxMarkup) >= Number(finalStdMarkup) ||
                  t('modals.price-settings.parameters.markup-max.warning-too-low-value')
                );
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Stack sx={rowStyles}>
                  <FirstColumnCell
                    cellContent={{
                      text: t('modals.price-settings.parameters.markup-max.label'),
                      tooltip: t('form.maximumMarkup.tooltip.__html'),
                    }}
                  />

                  <CellCheckbox
                    control={control}
                    disabled={
                      props.disabled || (useOfferMaximumMarkupValue === null && isSingleOfferAction)
                    }
                    name={'markupSettings.useDefaultMaximumMarkup'}
                    label={getValue(data?.defaultPriceSettings?.markupSettings.maximumMarkup)}
                  />
                  <Stack sx={{ justifyContent: 'center', p: 1 }}>
                    <TextFieldNumber
                      disabled={
                        props.disabled || (field.value !== null && useDefaultMaximumMarkupValue)
                      }
                      onChange={(value) => {
                        if (value === null) resetField('markupSettings.maximumMarkup');
                        handleOfferValue(value === null, 'markupSettings.useDefaultMaximumMarkup');
                        field.onChange(value);
                      }}
                      onBlur={field.onBlur}
                      value={field.value}
                      unit={'%'}
                      precision={2}
                      error={!!fieldState.error}
                      tooltipError={fieldState.error?.message}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          />

          <Controller
            control={control}
            name={'parametersSettings.returnReserve'}
            rules={{
              required: {
                value:
                  isSingleOfferAction &&
                  !useDefaultReturnReserveValue &&
                  useOfferReturnReserveValue === null,
                message: t('form.returnReserve.validation.required'),
              },
              min: {
                value: validationRangeRules.returnsReserve.min,
                message: t('form.validation-generic.min', {
                  min: validationRangeRules.returnsReserve.min,
                }),
              },
              max: {
                value: validationRangeRules.returnsReserve.max,
                message: t('form.validation-generic.max', {
                  max: validationRangeRules.returnsReserve.max,
                }),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Stack sx={rowStyles}>
                  <FirstColumnCell
                    cellContent={{
                      text: t('form.returnReserve.label'),
                      tooltip: t('form.returnReserve.tooltip.__html'),
                    }}
                  />

                  <CellCheckbox
                    control={control}
                    disabled={
                      props.disabled || (useOfferReturnReserveValue === null && isSingleOfferAction)
                    }
                    name={'parametersSettings.useDefaultReturnReserve'}
                    label={getValue(data?.defaultPriceSettings?.parametersSettings.returnReserve)}
                  />
                  <Stack sx={{ justifyContent: 'center', p: 1 }}>
                    <TextFieldNumber
                      disabled={
                        props.disabled || (field.value !== null && useDefaultReturnReserveValue)
                      }
                      onChange={(value) => {
                        handleOfferValue(
                          value === null,
                          'parametersSettings.useDefaultReturnReserve',
                        );
                        field.onChange(value);
                      }}
                      onBlur={field.onBlur}
                      value={field.value}
                      unit={'%'}
                      precision={2}
                      error={!!fieldState.error}
                      tooltipError={fieldState.error?.message}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          />

          <Controller
            control={control}
            name={'parametersSettings.shippingCost'}
            rules={{
              required: {
                value:
                  isSingleOfferAction &&
                  !useDefaultShippingCostValue &&
                  useOfferShippingCostValue === null,
                message: t('form.shippingCost.validation.required'),
              },
              min: {
                value: validationRangeRules.shipping.min,
                message: t('form.validation-generic.min', {
                  min: validationRangeRules.shipping.min,
                }),
              },
              max: {
                value: validationRangeRules.shipping.max,
                message: t('form.validation-generic.max', {
                  max: validationRangeRules.shipping.max,
                }),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Stack sx={rowStyles}>
                  <FirstColumnCell
                    cellContent={{
                      text: t('form.shippingCost.label'),
                      tooltip: t('form.shippingCost.tooltip.__html'),
                    }}
                  />

                  <CellCheckbox
                    control={control}
                    disabled={
                      props.disabled || (useOfferShippingCostValue === null && isSingleOfferAction)
                    }
                    name={'parametersSettings.useDefaultShippingCost'}
                    label={getValue(
                      data?.defaultPriceSettings?.parametersSettings.shippingCost,
                      baseCurrency,
                    )}
                  />
                  <Stack sx={{ justifyContent: 'center', p: 1 }}>
                    <TextFieldNumber
                      disabled={
                        props.disabled || (field.value !== null && useDefaultShippingCostValue)
                      }
                      onChange={(value) => {
                        handleOfferValue(
                          value === null,
                          'parametersSettings.useDefaultShippingCost',
                        );
                        field.onChange(value);
                      }}
                      onBlur={field.onBlur}
                      value={field.value}
                      unit={baseCurrency}
                      precision={2}
                      error={!!fieldState.error}
                      tooltipError={fieldState.error?.message}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          />

          <Controller
            control={control}
            name={'parametersSettings.additionalCost'}
            rules={{
              min: {
                value: validationRangeRules.addCost.min,
                message: t('form.validation-generic.min', {
                  min: validationRangeRules.addCost.min,
                }),
              },
              max: {
                value: validationRangeRules.addCost.max,
                message: t('form.validation-generic.max', {
                  max: validationRangeRules.addCost.max,
                }),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Stack sx={rowStyles}>
                  <FirstColumnCell
                    cellContent={{
                      text: t('modals.price-settings.parameters.additional-cost.label'),
                      tooltip: t('form.additionalCost.tooltip.__html'),
                    }}
                  />

                  <CellCheckbox
                    control={control}
                    disabled={
                      props.disabled ||
                      (useOfferAdditionalCostValue === null && isSingleOfferAction)
                    }
                    name={'parametersSettings.useDefaultAdditionalCost'}
                    label={getValue(
                      data?.defaultPriceSettings?.parametersSettings.additionalCost,
                      marketCurrency,
                    )}
                  />
                  <Stack sx={{ justifyContent: 'center', p: 1 }}>
                    <TextFieldNumber
                      disabled={
                        props.disabled || (field.value !== null && useDefaultAdditionalCostValue)
                      }
                      onChange={(value) => {
                        handleOfferValue(
                          value === null,
                          'parametersSettings.useDefaultAdditionalCost',
                        );
                        field.onChange(value);
                      }}
                      onBlur={field.onBlur}
                      value={field.value}
                      unit={marketCurrency}
                      precision={2}
                      error={!!fieldState.error}
                      tooltipError={fieldState.error?.message}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          />
        </Box>
        <Box>
          <ColumnHeader
            label={t('modals.price-settings.columnHeader.price-range-fixed.label')}
            tooltip={t('modals.price-settings.columnHeader.price-range-fixed.__html')}
          />

          <Stack sx={{ ...rowStyles, height: isSingleOfferAction ? '36px' : '52px', mt: 2 }}>
            <Box></Box>
            <Show when={!isSingleOfferAction}>
              <ColumnHeader
                isSmall
                sx={{ p: '10px' }}
                label={t('modals.price-settings.columnHeader.remove-price.label')}
              />
            </Show>
            <ColumnHeader
              isSmall
              sx={{ p: '10px' }}
              label={t('modals.price-settings.columnHeader.individual.label')}
            />
          </Stack>

          <Controller
            control={control}
            name={'fixedPricesSettings.fixedMinimumPrice'}
            rules={{
              required: {
                value:
                  isProductCostMissing &&
                  Number(usePriceManagementMode) === 3 &&
                  !useOfferFixedMinimumPriceValue,
                message: t('modals.price-settings.parameters.fixed-min-price.validation-reqired'),
              },
              min: {
                value: validationRangeRules.priceFixedMin.min,
                message: t('form.validation-generic.min', {
                  min: validationRangeRules.priceFixedMin.min,
                }),
              },
              max: {
                value: validationRangeRules.priceFixedMin.max,
                message: t('form.validation-generic.max', {
                  max: validationRangeRules.priceFixedMin.max,
                }),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Stack sx={rowStyles}>
                  <FirstColumnCell
                    cellContent={{
                      text: t('modals.price-settings.parameters.fixed-min-price.label'),
                      tooltip: t('form.fixedMinimumPrice.tooltip.__html'),
                    }}
                  />

                  <Show when={!isSingleOfferAction}>
                    <CellCheckbox
                      control={control}
                      disabled={props.disabled}
                      name={'fixedPricesSettings.removeFixedMinimumPrice'}
                    />
                  </Show>

                  <Stack sx={{ justifyContent: 'center', p: 1 }}>
                    <TextFieldNumber
                      disabled={props.disabled}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      unit={marketCurrency}
                      precision={2}
                      error={!!fieldState.error}
                      tooltipError={fieldState.error?.message}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          />

          <Controller
            control={control}
            name={'fixedPricesSettings.fixedMaximumPrice'}
            rules={{
              min: {
                value: validationRangeRules.priceFixedMax.min,
                message: t('form.validation-generic.min', {
                  min: validationRangeRules.priceFixedMax.min,
                }),
              },
              max: {
                value: validationRangeRules.priceFixedMax.max,
                message: t('form.validation-generic.max', {
                  max: validationRangeRules.priceFixedMax.max,
                }),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Stack sx={rowStyles}>
                  <FirstColumnCell
                    cellContent={{
                      text: t('modals.price-settings.parameters.fixed-max-price.label'),
                      tooltip: t('form.fixedMaximumPrice.tooltip.__html'),
                    }}
                  />

                  <Show when={!isSingleOfferAction}>
                    <CellCheckbox
                      control={control}
                      disabled={props.disabled}
                      name={'fixedPricesSettings.removeFixedMaximumPrice'}
                    />
                  </Show>

                  <Stack sx={{ justifyContent: 'center', p: 1 }}>
                    <TextFieldNumber
                      disabled={props.disabled}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      unit={marketCurrency}
                      precision={2}
                      error={!!fieldState.error}
                      tooltipError={fieldState.error?.message}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          />
        </Box>
      </Stack>
    </>
  );
};
