export const parseFloatValue = (value?: string | number, precision?: number) => {
  const newValue =
    value === undefined || value === null ? NaN : parseFloat(String(value).replaceAll(',', '.'));
  if (isNaN(newValue)) {
    return '';
  }

  return precision || precision === 0 ? newValue.toFixed(precision) : newValue.toString();
};

export const parseFloatValueWithUnit = (
  value?: string | number,
  precision?: number,
  unit?: string,
) => {
  return `${parseFloatValue(value, precision)} ${unit}`;
};
