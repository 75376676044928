import React from 'react';
import { Box } from '@mui/material';

type Props = {
  text: string;
};

export const DatagridCellPreWrapText: React.FC<Props> = (props) => {
  const text = React.useMemo(() => props.text || '', [props.text]);

  return (
    <Box
      title={props.text}
      sx={{
        whiteSpace: 'pre-wrap',
        fontSize: '13px',
        maxHeight: '72px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical',
      }}
    >
      {text}
    </Box>
  );
};
