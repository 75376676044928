import React, { useCallback, useMemo, useState } from 'react';
import { useCommonTranslations } from 'modules/shared';
import {
  getGridNumericOperators,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridPinnedColumns,
  GridSortModel,
  GridState,
} from '@mui/x-data-grid-pro';
import { gridInitialState } from '../consts';
import { useDatagridViewContext } from '../hooks';
import { getNumericBetweenOperator } from '../hooks/use-datagrid-numeric-operators';
import { DatagridContextType } from '../types';
import { parseVisibleColumns } from '../utils';

export const DatagridContext = React.createContext<DatagridContextType>(gridInitialState);

type Props = React.PropsWithChildren<{
  columns: string[];
  itemsPerPage?: number;
  initialForcedFilters?: GridFilterItem[];
}>;

export const DatagridContextProvider: React.FC<Props> = React.memo((props) => {
  const { currentView } = useDatagridViewContext();
  const { tCommon } = useCommonTranslations();

  const forcedFilters: GridFilterModel | undefined = props.initialForcedFilters
    ? {
        items: props.initialForcedFilters || [],
      }
    : undefined;

  const rowCount = useState(gridInitialState.rowCount[0]);
  const page = useState(gridInitialState.page[0]);
  const itemsPerPage = useState(props.itemsPerPage || gridInitialState.itemsPerPage[0]);
  const search = useState(gridInitialState.search[0]);
  const visibleColumns = useState(
    parseVisibleColumns(
      currentView?.gridConfiguration.visibleColumns || gridInitialState.visibleColumns[0],
      props.columns,
    ),
  );
  const visibleOrderedColumns = useState(
    currentView?.gridConfiguration.visibleOrderedColumns ||
      gridInitialState.visibleOrderedColumns[0],
  );
  const sort = useState<GridSortModel>(
    currentView?.gridConfiguration.sort || gridInitialState.sort[0],
  );
  const filter = useState<GridFilterModel>(
    forcedFilters || currentView?.gridConfiguration.filter || gridInitialState.filter[0],
  );
  const pinnedColumns = useState<GridPinnedColumns>(
    currentView?.gridConfiguration.pinnedColumns || gridInitialState.pinnedColumns[0],
  );
  const selectedRows = useState(gridInitialState.selectedRows[0]);
  const bulkActionType = useState(gridInitialState.bulkActionType[0]);

  const onGridStateChange = useCallback(
    (state: GridState) => {
      if (Object.keys(visibleColumns[0]).length === 0) {
        const stateVisibleColumnsParsed = (
          state.columns?.orderedFields as string[]
        ).reduce<GridColumnVisibilityModel>((acc, v) => {
          acc[v] = true;
          return acc;
        }, {});
        if (JSON.stringify(visibleColumns[0]) !== JSON.stringify(stateVisibleColumnsParsed)) {
          visibleColumns[1](stateVisibleColumnsParsed);
        }
      }
      const visibleOrderedColumnsParsed = ((state.columns?.orderedFields as string[]) || []).reduce<
        string[]
      >((acc, column) => {
        if (state.columns.columnVisibilityModel[column]) {
          acc.push(column);
        }
        return acc;
      }, []);
      if (
        JSON.stringify(visibleOrderedColumns[0]) !== JSON.stringify(visibleOrderedColumnsParsed)
      ) {
        visibleOrderedColumns[1]([...visibleOrderedColumnsParsed]);
      }
    },
    [JSON.stringify(visibleColumns[0])],
  );

  const onFilterModelChange = useCallback(
    (state: GridFilterModel) => {
      filter[1]({
        items: state.items,
        logicOperator: state.logicOperator || GridLogicOperator.And,
      });
    },
    [filter[1]],
  );

  const prepareColumns = useCallback(
    function <T extends GridColDef>(columns: T[]): T[] {
      const numColumnsCustomLabels = columns.map((col) => {
        if (col.type !== 'number') return col;
        return {
          ...col,
          filterOperators: [
            ...(col.filterOperators || getGridNumericOperators()),
            getNumericBetweenOperator(tCommon),
          ].map((operator) => ({
            ...operator,
            label: tCommon(`datagrid.operators.numeric.${operator.value as '='}`),
          })),
          headerAlign: col.headerAlign || 'left',
        };
      });
      const notOrderedColumns = numColumnsCustomLabels.filter(
        (x) => !visibleOrderedColumns[0].some((y) => y === x.field),
      );
      const orderedColumns = visibleOrderedColumns[0]
        .map((x) => {
          return numColumnsCustomLabels.find((y) => y.field === x);
        })
        .filter((x) => !!x) as T[];

      return [...orderedColumns, ...(notOrderedColumns || [])];
    },
    [visibleOrderedColumns[0]],
  );

  const value: DatagridContextType = useMemo(
    () => ({
      rowCount,
      page,
      itemsPerPage,
      sort,
      filter: [filter[0], onFilterModelChange],
      search,
      pinnedColumns,
      visibleColumns,
      visibleOrderedColumns,
      onGridStateChange,
      prepareColumns,
      selectedRows,
      bulkActionType,
    }),
    [
      page[0],
      itemsPerPage[0],
      JSON.stringify(sort[0]),
      JSON.stringify(filter[0]),
      search[0],
      rowCount[0],
      JSON.stringify(pinnedColumns[0]),
      JSON.stringify(visibleOrderedColumns[0]),
      JSON.stringify(visibleColumns[0]),
      JSON.stringify(selectedRows[0]),
      bulkActionType[0],
    ],
  );

  return <DatagridContext.Provider value={value}>{props.children}</DatagridContext.Provider>;
});
