import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import flatten from 'lodash.flatten';
import { Stack } from '@mui/material';
import { sortBy } from '../../../common/utils';
import { Show } from '../../../components';
import { SpinnerIcon } from '../../../components/icons';
import {
  ModalCreateView,
  ModalDeleteView,
  ModalManageSharingView,
  ModalRenameView,
} from '../components';
import { GridTableIds } from '../enum';
import { useViewsQuery } from '../hooks/api';
import {
  DatagridViewContextType,
  DatagridViewsGroupType,
  DatagridViewType,
  GridStateTypeWithId,
  GridViewData,
} from '../types';

type Props = React.PropsWithChildren<{
  tableId: GridTableIds;
}>;

const initialData: DatagridViewContextType = {
  tableId: '',
  view: null,
  changeView: () => null,
  forceChangeView: () => null,
  resetView: () => null,
  createViewModal: {
    view: undefined,
    change: () => null,
  },
  renameViewModal: {
    view: undefined,
    change: () => null,
  },
  deleteViewModal: {
    view: undefined,
    change: () => null,
  },
  manageSharingViewModal: {
    view: undefined,
    change: () => null,
  },
};

export const DatagridViewsContext = React.createContext<DatagridViewContextType>(initialData);

export const DatagridViewsContextProvider: React.FC<Props> = (props) => {
  const prevViewsLength = useRef(0);
  const [currentView, setCurrentView] = useState<DatagridViewType | null>(null);
  const [showCreateViewModal, setShowCreateViewModal] = useState<GridViewData | undefined>(
    undefined,
  );
  const [showRenameViewModal, setShowRenameViewModal] = useState<GridViewData | undefined>(
    undefined,
  );
  const [showDeleteViewModal, setShowDeleteViewModal] = useState<GridViewData | undefined>(
    undefined,
  );
  const [showManageSharingViewModal, setShowManageSharingViewModal] = useState<
    GridViewData | undefined
  >(undefined);

  const flattVisibleTabs = (views: DatagridViewsGroupType[]) => {
    return flatten<DatagridViewType>([...views].map((x) => x.tableViews))
      .filter((x) => x.visible)
      .sort(sortBy('position'));
  };

  const { data, isFetched, refetch } = useViewsQuery(String(props.tableId), {
    onSuccess: (successData) => {
      const flattedTab = flattVisibleTabs(successData || []);
      if (flattedTab.length === prevViewsLength.current) return;
      if (prevViewsLength.current === 0) {
        setCurrentView(flattedTab[0]);
      }
      prevViewsLength.current = flattedTab.length;
    },
  });

  const changeCurrentView = (tab: string | { config: GridStateTypeWithId }) => {
    if (typeof tab === 'string') {
      const flattedTab = flattVisibleTabs(data || []).find((x) => x.id === tab);
      if (flattedTab) {
        setCurrentView({
          ...flattedTab,
        });
      }
      return;
    }
    //Force view config update
    setCurrentView({
      gridConfiguration: { ...tab.config, name: tab.config.name, id: tab.config.id },
      id: tab.config.id,
      name: tab.config.name,
      predefined: true,
      public: true,
      visible: true,
      ownedByRequestingUser: false,
      ownerId: '',
      position: 1,
      tableId: '',
      partnerId: '',
    });
  };

  const resetCurrentTab = useCallback(() => {
    const flattedTab = flattVisibleTabs(data || []).find((x) => x.id === currentView?.id);

    if (flattedTab) {
      setCurrentView(null);
      setTimeout(() => {
        setCurrentView(flattedTab);
      }, 0);
    }
  }, [data, currentView?.id]);

  useEffect(() => {
    // Is required to force refetch tabs after changing views
    refetch();
  }, []);

  const value: DatagridViewContextType = useMemo(
    () => ({
      tableId: String(props.tableId),
      view: currentView,
      changeView: changeCurrentView,
      forceChangeView: changeCurrentView,
      resetView: resetCurrentTab,
      createViewModal: {
        view: showCreateViewModal,
        change: setShowCreateViewModal,
      },
      renameViewModal: {
        view: showRenameViewModal,
        change: setShowRenameViewModal,
      },
      deleteViewModal: {
        view: showDeleteViewModal,
        change: setShowDeleteViewModal,
      },
      manageSharingViewModal: {
        view: showManageSharingViewModal,
        change: setShowManageSharingViewModal,
      },
    }),
    [
      props.tableId,
      currentView,
      changeCurrentView,
      showCreateViewModal,
      showRenameViewModal,
      showDeleteViewModal,
      showManageSharingViewModal,
    ],
  );

  return (
    <DatagridViewsContext.Provider value={value}>
      <Show
        when={isFetched}
        fallback={
          <Stack sx={{ width: '100%', alignItems: 'center', mt: 4 }}>
            <SpinnerIcon sx={{ fontSize: '50px' }} />
          </Stack>
        }
      >
        <>{props.children}</>
        <Show when={Boolean(showCreateViewModal)}>
          <ModalCreateView />
        </Show>
        <Show when={Boolean(showRenameViewModal)}>
          <ModalRenameView />
        </Show>
        <Show when={Boolean(showDeleteViewModal)}>
          <ModalDeleteView />
        </Show>
        <Show when={Boolean(showManageSharingViewModal)}>
          <ModalManageSharingView />
        </Show>
      </Show>
    </DatagridViewsContext.Provider>
  );
};
