import * as React from 'react';
import { IconButton, SvgIcon, Tooltip as MuiTooltip, SxProps } from '@mui/material';
import { nethansaPalette } from '../../theme';
import { TooltipArrow } from './components/tooltip-arrow';

type Props = {
  content?: string;
  sx?: SxProps;
};

export const Tooltip: React.FC<Props> = (props) => {
  return (
    <MuiTooltip
      disableFocusListener
      disableTouchListener
      title={<span dangerouslySetInnerHTML={{ __html: props.content || '' }} />}
      placement="top-start"
      arrow
      components={{
        Arrow: TooltipArrow,
      }}
    >
      <IconButton sx={{ width: '16px', height: '16px', p: 0, ...props.sx }}>
        <SvgIcon viewBox="0 0 16 16" width="16" height="16" fill="none" sx={{ fontSize: '16px' }}>
          <path
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
            fill="#CFD0DC"
          />
          <path
            d="M8.6 10V9.837C8.602 9.524 8.64 9.261 8.713 9.047C8.785 8.833 8.897 8.645 9.047 8.482C9.197 8.319 9.389 8.163 9.624 8.013C9.903 7.834 10.144 7.633 10.35 7.413C10.555 7.19 10.714 6.935 10.829 6.646C10.943 6.357 11 6.026 11 5.653C11 5.093 10.87 4.617 10.612 4.221C10.354 3.825 9.998 3.523 9.543 3.314C9.09 3.104 8.566 3 7.976 3C7.438 3 6.946 3.103 6.501 3.309C6.056 3.515 5.698 3.826 5.428 4.242C5.158 4.659 5.015 5.187 5 5.825H6.817C6.827 5.563 6.886 5.344 6.996 5.167C7.106 4.989 7.246 4.855 7.419 4.764C7.591 4.674 7.774 4.628 7.967 4.628C8.166 4.628 8.347 4.673 8.512 4.762C8.677 4.852 8.808 4.979 8.906 5.145C9.004 5.311 9.053 5.505 9.053 5.725C9.053 5.932 9.01 6.119 8.924 6.287C8.838 6.455 8.722 6.607 8.577 6.744C8.431 6.881 8.267 7.014 8.082 7.142L7.904 7.27C7.733 7.401 7.581 7.544 7.45 7.697C7.275 7.901 7.142 8.169 7.051 8.501C6.961 8.833 6.914 9.279 6.911 9.837V10H8.599H8.6ZM8 13C8.178 13 8.342 12.955 8.492 12.864C8.642 12.774 8.763 12.652 8.856 12.5C8.949 12.348 8.997 12.18 9 11.996C8.997 11.722 8.896 11.488 8.696 11.293C8.496 11.098 8.264 11 8 11C7.722 11 7.485 11.098 7.29 11.293C7.094 11.488 6.997 11.723 7 11.996C6.997 12.272 7.094 12.508 7.29 12.705C7.485 12.902 7.722 13 8 13Z"
            fill={nethansaPalette.main.dark}
          />
        </SvgIcon>
      </IconButton>
    </MuiTooltip>
  );
};
