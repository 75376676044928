import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  longDateWithMonthDescriptionAndTime,
  parseDate,
  parseDateAndFixIso,
} from 'common/format/date';
import { OrderStatus, PlatformFeeSource, PriceSettingsType } from '../../../generated/graphql';
import {
  currencyCellClassName,
  currencyCellValueFormatter,
  DatagridCellFlag,
  nullableValueCellValueFormatter,
  nullableValueCellValueGetter,
  percentageCellClassName,
  useDatagridDateOperators,
} from '../../datagrid';
import { useGlobalSettingsQuery } from '../../settings-sales-global';
import { useCommonTranslations } from '../../shared';
import { SaleRevenueProfitGridColDefCustom } from '../types';

export const useSaleRevenueColumnsDefinition = (): SaleRevenueProfitGridColDefCustom[] => {
  const { tCommon } = useCommonTranslations();
  const { t, i18n } = useTranslation('reports');
  const { data: globalSettingsData } = useGlobalSettingsQuery();
  const dateOperators = useDatagridDateOperators();

  const OrderStatusList = [
    { value: OrderStatus.Unshipped, label: tCommon(`orderStatus.${OrderStatus.Unshipped}`) },
    { value: OrderStatus.Shipped, label: tCommon(`orderStatus.${OrderStatus.Shipped}`) },
    { value: OrderStatus.Closed, label: tCommon(`orderStatus.${OrderStatus.Closed}`) },
  ];

  const PlatformFeeSourceList = [
    { value: PlatformFeeSource.Estimate, label: 'Estimate' },
    { value: PlatformFeeSource.Marketplace, label: 'Marketplace' },
    { value: PlatformFeeSource.Unknown, label: 'Unknown' },
  ];

  const PriceSettingsTypeList = [
    { value: PriceSettingsType.CalculatedPrice, label: 'Calculated Price' },
    { value: PriceSettingsType.FixedPrice, label: 'Fixed Price' },
    { value: PriceSettingsType.Unknown, label: 'Unknown' },
  ];

  return useMemo<SaleRevenueProfitGridColDefCustom[]>(() => {
    return [
      {
        field: 'orderId',
        headerName: t('sales-revenue-profit.column.orderId'),
        width: 200,
        type: 'string',
      },
      {
        field: 'partnerOrderNumber',
        headerName: t('sales-revenue-profit.column.partnerOrderNumber'),
        width: 150,
        type: 'string',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'productName',
        headerName: t('sales-revenue-profit.column.productName'),
        width: 400,
        type: 'string',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'marketplace',
        headerName: t('sales-revenue-profit.column.marketplace'),
        width: 150,
        type: 'string',
        renderCell: (params) => <DatagridCellFlag value={params.value} />,
      },
      {
        field: 'fulfillment',
        headerName: t('sales-revenue-profit.column.fulfillment'),
        width: 100,
        type: 'string',
      },
      {
        field: 'orderDate',
        headerName: t('sales-revenue-profit.column.orderDate'),
        width: 120,
        type: 'date',
        filterOperators: dateOperators,
        valueGetter: (params) => parseDateAndFixIso(parseDate(params.value)),
        valueFormatter: (params) =>
          params.value ? longDateWithMonthDescriptionAndTime(params.value, i18n.language) : '—',
      },
      {
        field: 'status',
        headerName: t('sales-revenue-profit.column.status'),
        width: 120,
        type: 'singleSelect',
        valueOptions: OrderStatusList,
        valueFormatter: (params) =>
          tCommon(`orderStatus.${params.value.toUpperCase() as 'CANCELED'}`),
      },
      {
        field: 'shippedDate',
        headerName: t('sales-revenue-profit.column.shippedDate'),
        width: 120,
        type: 'date',
        filterOperators: dateOperators,
        valueGetter: (params) => parseDateAndFixIso(parseDate(params.value)),
        valueFormatter: (params) =>
          params.value ? longDateWithMonthDescriptionAndTime(params.value, i18n.language) : '—',
      },
      {
        field: 'shipCountry',
        headerName: t('sales-revenue-profit.column.shipCountry'),
        width: 120,
        type: 'string',
      },
      {
        field: 'lineItemId',
        headerName: t('sales-revenue-profit.column.lineItemId'),
        width: 150,
        type: 'string',
      },
      {
        field: 'productExternalId',
        headerName: t('sales-revenue-profit.column.productExternalId'),
        width: 200,
        type: 'string',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'eAN',
        headerName: t('sales-revenue-profit.column.eAN'),
        width: 150,
        type: 'string',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'category',
        headerName: t('sales-revenue-profit.column.category'),
        width: 150,
        type: 'string',
        valueGetter: nullableValueCellValueGetter,
      },
      {
        field: 'quantity',
        headerName: t('sales-revenue-profit.column.quantity'),
        width: 100,
        type: 'number',
      },
      {
        field: 'grandTotal',
        headerName: t('sales-revenue-profit.column.grandTotal'),
        width: 150,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'grandTotalEUR',
        headerName: t('sales-revenue-profit.column.grandTotalEUR'),
        width: 150,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency', 'EUR'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'grandTotalBase',
        headerName: t('sales-revenue-profit.column.grandTotalBase', {
          currency: globalSettingsData?.baseCurrency,
        }),
        width: 150,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency', globalSettingsData?.baseCurrency),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'grandTotalTax',
        headerName: t('sales-revenue-profit.column.grandTotalTax'),
        width: 150,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'orderCurrency',
        headerName: t('sales-revenue-profit.column.orderCurrency'),
        width: 100,
        type: 'string',
      },
      {
        field: 'productTotal',
        headerName: t('sales-revenue-profit.column.productTotal'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'shippingTotal',
        headerName: t('sales-revenue-profit.column.shippingTotal'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'otherTotal',
        headerName: t('sales-revenue-profit.column.otherTotal'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'baseCurrency',
        headerName: t('sales-revenue-profit.column.baseCurrency'),
        width: 100,
        type: 'string',
        filterable: false,
      },
      {
        field: 'orderHasDiscount',
        headerName: t('sales-revenue-profit.column.orderHasDiscount'),
        width: 100,
        type: 'boolean',
      },
      {
        field: 'productBaseCostNet',
        headerName: t('sales-revenue-profit.column.productBaseCostNet'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'shipHandleCalcCostNet',
        headerName: t('sales-revenue-profit.column.shipHandleCalcCostNet'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'platformCommissionPercent',
        headerName: t('sales-revenue-profit.column.platformCommissionPercent'),
        width: 120,
        type: 'number',
        valueFormatter: nullableValueCellValueFormatter,
        cellClassName: percentageCellClassName,
      },
      {
        field: 'platformCommission',
        headerName: t('sales-revenue-profit.column.platformCommission'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'platformClosingFeeNet',
        headerName: t('sales-revenue-profit.column.platformClosingFeeNet'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'platformChargebackFeeNet',
        headerName: t('sales-revenue-profit.column.platformChargebackFeeNet'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'platformFeeSource',
        headerName: t('sales-revenue-profit.column.platformFeeSource'),
        width: 120,
        type: 'singleSelect',
        valueOptions: PlatformFeeSourceList,
        valueGetter: (params) => (params.value ? params.value : 'Unknown'),
      },
      {
        field: 'returnsReservePercent',
        headerName: t('sales-revenue-profit.column.returnsReservePercent'),
        width: 120,
        type: 'number',
        valueFormatter: nullableValueCellValueFormatter,
        cellClassName: percentageCellClassName,
      },
      {
        field: 'returnsReserve',
        headerName: t('sales-revenue-profit.column.returnsReserve'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'nethansaFeePercent',
        headerName: t('sales-revenue-profit.column.nethansaFeePercent'),
        width: 120,
        type: 'number',
        valueFormatter: nullableValueCellValueFormatter,
        cellClassName: percentageCellClassName,
      },
      {
        field: 'nethansaFee',
        headerName: t('sales-revenue-profit.column.nethansaFee'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'itemProfitNet',
        headerName: t('sales-revenue-profit.column.itemProfitNet'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'itemAddProfitNet',
        headerName: t('sales-revenue-profit.column.itemAddProfitNet'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'itemProfitNetEUR',
        headerName: t('sales-revenue-profit.column.itemProfitNetEUR'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency', 'EUR'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'itemProfitNetBase',
        headerName: t('sales-revenue-profit.column.itemProfitNetBase', {
          currency: globalSettingsData?.baseCurrency,
        }),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency', globalSettingsData?.baseCurrency),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'itemAddProfitNetEUR',
        headerName: t('sales-revenue-profit.column.itemAddProfitNetEUR'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency', 'EUR'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'itemAddProfitNetBase',
        headerName: t('sales-revenue-profit.column.itemAddProfitNetBase', {
          currency: globalSettingsData?.baseCurrency,
        }),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency', globalSettingsData?.baseCurrency),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'minMarkupPercent',
        headerName: t('sales-revenue-profit.column.minMarkupPercent'),
        width: 120,
        type: 'number',
        valueFormatter: nullableValueCellValueFormatter,
        cellClassName: percentageCellClassName,
      },
      {
        field: 'fixedMinPrice',
        headerName: t('sales-revenue-profit.column.fixedMinPrice'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'minPrice',
        headerName: t('sales-revenue-profit.column.minPrice'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'fixedPrice',
        headerName: t('sales-revenue-profit.column.fixedPrice'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'effectiveMarkupProc',
        headerName: t('sales-revenue-profit.column.effectiveMarkupProc'),
        width: 120,
        type: 'number',
        valueFormatter: nullableValueCellValueFormatter,
        cellClassName: percentageCellClassName,
      },
      {
        field: 'priceSettingsType',
        headerName: t('sales-revenue-profit.column.priceSettingsType'),
        width: 150,
        type: 'singleSelect',
        valueOptions: PriceSettingsTypeList,
        valueFormatter: (params) => params.value.toLowerCase(),
      },
      {
        field: 'vcsActive',
        headerName: t('sales-revenue-profit.column.vcsActive'),
        width: 100,
        type: 'boolean',
      },
      {
        field: 'platformFeeFulfillmentNetValue',
        headerName: t('sales-revenue-profit.column.platformFeeFulfillmentNetValue'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'shipCostNet',
        headerName: t('sales-revenue-profit.column.shipCostNet'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
      {
        field: 'addCostNet',
        headerName: t('sales-revenue-profit.column.addCostNet'),
        width: 120,
        type: 'number',
        cellClassName: currencyCellClassName('orderCurrency'),
        valueFormatter: currencyCellValueFormatter,
      },
    ];
  }, [globalSettingsData?.baseCurrency]);
};
