import React, { useCallback } from 'react';
import { DatagridExportMenu } from '../../../datagrid';
import { useCommonTranslations } from '../../../shared';
import { FileType } from '../../../shared/types';
import { useProductsColumnsDefinition, useProductsQuery } from '../../hooks';
import { useProductsExport } from '../../hooks/use-products-export';

export const TableProductsExport: React.FC = () => {
  const { tCommon } = useCommonTranslations();

  const columns = useProductsColumnsDefinition();

  const { data } = useProductsQuery(columns);

  const productsLazyExportData = useProductsExport(columns);

  const onLazyDownloadClick = useCallback(
    (fileType: FileType, isImport: boolean) => {
      productsLazyExportData.mutate(fileType, isImport);
    },
    [productsLazyExportData],
  );

  const LIMIT_XLSX = 1000000,
    LIMIT_CSV = 1000000;
  const disabledExportXlsxByLimit = (data?.products?.totalCount || 0) > LIMIT_XLSX;
  const disabledExportCsvByLimit = (data?.products?.totalCount || 0) > LIMIT_CSV;

  const options = [
    {
      label: tCommon('datagrid.actions.download-as-csv'),
      onClick: () => onLazyDownloadClick('csv', false),
      tooltip: disabledExportCsvByLimit
        ? tCommon('datagrid.actions.export-limit-message.tooltip.__html', { count: LIMIT_CSV })
        : '',
      disabled: productsLazyExportData.isLoading || disabledExportCsvByLimit,
    },
    {
      label: tCommon('datagrid.actions.download-as-excel'),
      onClick: () => onLazyDownloadClick('xlsx', false),
      tooltip: disabledExportXlsxByLimit
        ? tCommon('datagrid.actions.export-limit-message.tooltip.__html', { count: LIMIT_XLSX })
        : '',
      disabled: productsLazyExportData.isLoading || disabledExportXlsxByLimit,
    },
    {
      label: tCommon('datagrid.actions.download-as-import-compatible'),
      onClick: () => onLazyDownloadClick('xlsx', true),
      tooltip: disabledExportXlsxByLimit
        ? tCommon('datagrid.actions.export-limit-message.tooltip.__html', { count: LIMIT_XLSX })
        : '',
      disabled: productsLazyExportData.isLoading || disabledExportXlsxByLimit,
    },
  ];

  return (
    <DatagridExportMenu
      loading={productsLazyExportData.isLoading}
      disabled={productsLazyExportData.isLoading}
      label={tCommon('export')}
      options={options}
    />
  );
};
