import React, { useCallback, useContext, useState } from 'react';
import { CoordinatorContext } from '../../coordinator-external-access';

export enum WizardStepType {
  'welcome' = 0,
  'form' = 1,
  'congratulation' = 2,
}

export enum WizardFormStepType {
  'globals',
  'platforms',
  'vat-and-shipping',
  'products',
  'summary',
}

type OnboardingContextWizardType = {
  step: WizardStepType;
  formStep: WizardFormStepType;
  goToWelcomeStep: () => void;
  goToCongratulationStep: () => void;
  goToFormStep: (formStep: WizardFormStepType) => void;
  goToSummaryStep: () => void;
  closeOnboarding: () => void;
};

const defaultValue: OnboardingContextWizardType = {
  step: WizardStepType.welcome,
  formStep: WizardFormStepType.globals,
  goToWelcomeStep: () => null,
  goToCongratulationStep: () => null,
  goToFormStep: () => null,
  goToSummaryStep: () => null,
  closeOnboarding: () => null,
};

export const OnboardingWizardContext =
  React.createContext<OnboardingContextWizardType>(defaultValue);

type Props = React.PropsWithChildren<unknown>;

export const OnboardingWizardProvider: React.FC<Props> = (props) => {
  const coordinatorContext = useContext(CoordinatorContext);
  const [step, setStep] = useState<WizardStepType>(defaultValue.step);
  const [formStep, setFormStep] = useState<WizardFormStepType>(defaultValue.formStep);

  const goToWelcomeStep = useCallback(() => {
    setStep(WizardStepType.welcome);
    setFormStep(WizardFormStepType.globals);
  }, [setStep, setFormStep]);

  const goToCongratulationStep = useCallback(() => {
    setStep(WizardStepType.congratulation);
  }, [setStep, setFormStep]);

  const goToFormStep = useCallback(
    (formStep: WizardFormStepType) => {
      setStep(WizardStepType.form);
      setFormStep(formStep);
    },
    [setStep, setFormStep],
  );

  const goToSummaryStep = useCallback(() => {
    setStep(WizardStepType.congratulation);
    setFormStep(WizardFormStepType.globals);
  }, [setStep, setFormStep]);

  const closeOnboarding = useCallback(() => {
    coordinatorContext.close();
  }, [setStep]);

  return (
    <OnboardingWizardContext.Provider
      value={{
        step: step,
        formStep: formStep,
        goToWelcomeStep,
        goToCongratulationStep,
        goToFormStep,
        goToSummaryStep,
        closeOnboarding,
      }}
    >
      {props.children}
    </OnboardingWizardContext.Provider>
  );
};
