import React from 'react';
import { TooltipArrow } from 'components/tooltip/components/tooltip-arrow';
import { Typography, Tooltip as MuiTooltip } from '@mui/material';

type Props = {
  tooltipContent?: string;
  header?: string;
};

export const DatagridHeaderTooltip: React.FC<Props> = (props) => {
  return (
    <MuiTooltip
      title={<span dangerouslySetInnerHTML={{ __html: props.tooltipContent || '' }} />}
      placement={'bottom-start'}
      arrow
      components={{ Arrow: TooltipArrow }}
    >
      <Typography variant="h4" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', py: 1 }}>
        {props.header || ''}
      </Typography>
    </MuiTooltip>
  );
};
