import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const UserEditIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="54"
      height="54"
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0H54V54H0z"
                transform="translate(-744 -604) translate(731 546) translate(13 58)"
              />
              <g>
                <g stroke="#000" strokeLinejoin="round" strokeWidth="2.8">
                  <g transform="translate(-744 -604) translate(731 546) translate(13 58) translate(10 7) translate(.864 .273)">
                    <circle cx="14.434" cy="9.185" r="9.185" />
                    <path d="M24.341 22.307c-4.19-3.955-10.332-5.038-15.623-2.757C3.427 21.832 0 27.043 0 32.805v6.56h17.058" />
                  </g>
                  <path
                    d="M31.11 25.65L22.8 33.945 22.8 38.1 26.955 38.1 35.25 29.79z"
                    transform="translate(-744 -604) translate(731 546) translate(13 58) translate(10 7)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
