import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from 'common/api';

type InputType = {
  importMethod: string;
};

export const useProductsWizardMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation(async (body) => {
    await httpClient.post(API.wizard().products(), body);
    return;
  });
};
