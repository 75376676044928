import { SwitchClasses, SwitchProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const switchStyles: {
  defaultProps?: Partial<SwitchProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof SwitchClasses>> | undefined;
} = {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: 38,
      height: 24,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(14px)',
          color: '#FFFFFF',
          '& + .MuiSwitch-track': {
            backgroundColor: nethansaPalette.main.active,
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: nethansaPalette.main.active,
          border: '6px solid #FFFFFF',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: nethansaPalette.main.gray,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
      },
      '& .MuiSwitch-track': {
        borderRadius: 24 / 2,
        backgroundColor: nethansaPalette.background.group,
        opacity: 1,
        transition: 'background-color 0.3s',
      },
    },
  },
};
