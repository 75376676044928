import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { sortBy } from 'common/utils';
import { useCountriesTranslations } from 'modules/shared';
import { CountryCode } from 'modules/shared/types';
import { VatAndShippingWizardType } from '../../types/vat-and-shipping-wizard.type';

type ResponseType = {
  hasVatOss: boolean;
  countries: {
    countryCode: CountryCode;
    isVatRegistered: boolean;
    vatRates: {
      [key: string]: number | null;
      standard: number | null;
      reduced1: number | null;
      reduced2: number | null;
      superReduced: number | null;
      zero: number | null;
    };
    selectedVatRate: string;
    defaultShippingCost: number;
  }[];
};

export const useWizardVatAndShippingQuery = (): UseQueryResult<VatAndShippingWizardType> => {
  const { i18n } = useTranslation();
  const [tCountries] = useCountriesTranslations();

  return useQuery<VatAndShippingWizardType>(
    ['wizard', 'vat-and-shipping', i18n.language],
    async () => {
      const response = await httpClient.get<ResponseType>(API.wizard().vatAndShipping());

      return {
        hasVatOss: response.data.hasVatOss,
        countries: response.data.countries
          .map((x) => ({
            countryCode: x.countryCode,
            countryName: tCountries(x.countryCode),
            defaultShippingCost: x.defaultShippingCost,
            isVatRegistered: x.isVatRegistered,
            selectedVatRate: x.selectedVatRate,
            vatRates: Object.keys(x.vatRates)
              .filter((y) => {
                const value = x.vatRates?.[y];
                return typeof value === 'number' && value >= 0;
              })
              .map((y) => ({
                label: `${(typeof x.vatRates?.[y] === 'number'
                  ? x.vatRates?.[y] || 0
                  : ''
                ).toString()}%`,
                value: y,
              })),
          }))
          .sort(sortBy('countryName')),
      };
    },
    {
      staleTime: 5000,
      keepPreviousData: true,
    },
  );
};
