import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ChartFilled, ChartOutlined } from 'components/icons';
import { AppGlobalParametersContext } from 'modules/shared/contexts/app-global-parameters.provider';
import {
  MODULE_FILTERS_GROUP_BAR_HEIGHT,
  MODULE_NAVIGATION_BAR_HEIGHT,
  MODULE_VIEWS_BAR_HEIGHT,
  NAVIGATION_APP_HEIGHT,
} from 'theme';
import { Box } from '@mui/material';
import {
  DataGridPro,
  enUS,
  GridLocaleText,
  GridPinnedColumns,
  GridRowParams,
  plPL,
  UncapitalizedGridSlotsComponent,
} from '@mui/x-data-grid-pro';
import { Checkbox, CheckboxCustomProps, TextFieldOutlined } from '../../../../components';
import { useDatagridContext } from '../../hooks';
import '../../styles/datagrid-cell.styles.css';
import { DatagridColumnsPanel } from '../datagrid-columns-panel';
import { DatagridFilterPanel } from '../datagrid-filters-panel';
import { DatagridFooter } from '../datagrid-footer';
import { DatagridPagination } from '../datagrid-pagination';
import { TableNoResults } from '../datagrid-table-empty';
import { datagridWrapperComponentsProps, datagridWrapperStyles } from '../datagrid-wrapper-config';

type Props<TRow, TColumn> = React.PropsWithChildren<{
  rows: readonly TRow[];
  getRowId: (row: TRow) => TRow;
  columns: TColumn[];
  components: Partial<UncapitalizedGridSlotsComponent>;
  loading?: boolean;
  checkboxSelection?: boolean;
  rowHeight?: number;
  enableVirtualization?: boolean;
  rowBuffer?: number;
  columnBuffer?: number;
  columnThreshold?: number;
  disableCenterValign?: boolean;
  forcedPinnedColumns?: GridPinnedColumns;
  getDetailPanelContent?: (params: GridRowParams) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams) => number | 'auto';
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DatagridWrapper: React.FC<Props<any, any>> = (props) => {
  const gridContext = useDatagridContext();
  const { i18n } = useTranslation();
  const currentModule = useLocation().pathname.split('/')[1];
  const showContext = useContext(AppGlobalParametersContext);
  const showOffersBars = currentModule === 'Offers' && showContext.offersModuleWithBars;
  const showReportsBars = currentModule === 'Reports' && showContext.reportsModuleWithBars;
  const showProductsBars = currentModule === 'Products' && showContext.productsModuleWithBars;
  const showMonitoringBars = currentModule === 'Monitoring' && showContext.monitoringModuleWithBars;

  const supportedLocales: { [key: string]: Partial<GridLocaleText> } = {
    pl: plPL.components.MuiDataGrid.defaultProps.localeText,
    en: enUS.components.MuiDataGrid.defaultProps.localeText,
  };

  const datagridHeight = () => {
    if (showOffersBars) {
      return `calc(100vh - ${NAVIGATION_APP_HEIGHT} - ${MODULE_FILTERS_GROUP_BAR_HEIGHT} - ${MODULE_NAVIGATION_BAR_HEIGHT} - ${MODULE_VIEWS_BAR_HEIGHT})`;
    }
    if (showReportsBars || showProductsBars || showMonitoringBars) {
      return `calc(100vh - ${NAVIGATION_APP_HEIGHT} - ${MODULE_NAVIGATION_BAR_HEIGHT} - ${MODULE_VIEWS_BAR_HEIGHT})`;
    }
    return `calc(100vh - ${NAVIGATION_APP_HEIGHT})`;
  };

  const dataGridExtraProps = {
    ...(Object.keys(props).some((x) => x === 'rowHeight') ? { rowHeight: props.rowHeight } : {}),
  };

  const components: Partial<UncapitalizedGridSlotsComponent> = useMemo(
    () => ({
      baseCheckbox: React.forwardRef<HTMLButtonElement, CheckboxCustomProps>((props, ref) => (
        <Checkbox
          {...props}
          ref={ref}
          disabled={gridContext.bulkActionType[0] === 'all'}
          checked={props.checked || (!props.disabled && gridContext.bulkActionType[0] === 'all')}
        />
      )),
      baseTextField: TextFieldOutlined,
      noResultsOverlay: TableNoResults,
      noRowsOverlay: TableNoResults,
      pagination: DatagridPagination,
      filterPanel: DatagridFilterPanel,
      columnsPanel: DatagridColumnsPanel,
      footer: DatagridFooter,
      detailPanelExpandIcon: ChartOutlined,
      detailPanelCollapseIcon: ChartFilled,
      ...props.components,
    }),
    [props.components, gridContext.bulkActionType[0]],
  );

  const pinnedColumns = useMemo(
    () => ({
      left: [
        ...(props.forcedPinnedColumns?.left || []),
        ...(props.forcedPinnedColumns
          ? (gridContext.pinnedColumns[0]?.left || []).filter((x) =>
              (props.forcedPinnedColumns?.left || []).some((y) => y !== x),
            )
          : gridContext.pinnedColumns[0]?.left || []),
      ],
      right: [
        ...(props.forcedPinnedColumns
          ? (gridContext.pinnedColumns[0]?.right || []).filter((x) =>
              (props.forcedPinnedColumns?.right || []).some((y) => y !== x),
            )
          : gridContext.pinnedColumns[0]?.right || []),
        ...(gridContext.pinnedColumns[0]?.right || []),
      ],
    }),
    [JSON.stringify(props.forcedPinnedColumns), JSON.stringify(gridContext.pinnedColumns)],
  );

  const gridStyles = datagridWrapperStyles({ disableCenterValign: props.disableCenterValign });

  const columns = useMemo(() => gridContext.prepareColumns(props.columns), [props.columns]);

  return (
    <Box
      key={i18n.language}
      sx={{
        mt: 0,
        height: datagridHeight,
        transition:
          !showContext.offersModuleWithBars ||
          !showContext.reportsModuleWithBars ||
          !showContext.productsModuleWithBars
            ? 'height 0.6s'
            : 'none',
      }}
    >
      <DataGridPro
        {...dataGridExtraProps}
        keepNonExistentRowsSelected
        checkboxSelection={props.checkboxSelection}
        disableRowSelectionOnClick
        disableVirtualization={!props.enableVirtualization}
        localeText={supportedLocales[i18n.language]}
        loading={props.loading}
        getRowId={props.getRowId}
        rows={props.rows}
        columns={columns}
        slots={components}
        pagination
        paginationMode="server"
        paginationModel={{
          page: gridContext.page[0],
          pageSize: gridContext.itemsPerPage[0],
        }}
        onPaginationModelChange={(model) => {
          gridContext.page[1](model.page), gridContext.itemsPerPage[1](model.pageSize);
        }}
        rowCount={gridContext.rowCount[0]}
        pageSizeOptions={[10, 20, 50, 100]}
        sortingMode="server"
        sortModel={gridContext.sort[0]}
        onSortModelChange={gridContext.sort[1]}
        rowSelectionModel={gridContext.selectedRows[0]}
        onRowSelectionModelChange={gridContext.selectedRows[1]}
        filterMode="server"
        filterModel={gridContext.filter[0]}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={gridContext.pinnedColumns[1]}
        onFilterModelChange={gridContext.filter[1]}
        columnVisibilityModel={gridContext.visibleColumns[0]}
        onColumnVisibilityModelChange={gridContext.visibleColumns[1]}
        onStateChange={gridContext.onGridStateChange}
        slotProps={datagridWrapperComponentsProps}
        sx={gridStyles}
        getDetailPanelHeight={props.getDetailPanelHeight}
        getDetailPanelContent={props.getDetailPanelContent}
        // experimentalFeatures={{ lazyLoading: true }}
      />
    </Box>
  );
};
