import React, { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'components';
import { Stack, Typography } from '@mui/material';
import { useCarriersListQuery } from '../../../../hooks';
import { ShippingCountryType } from '../../../../types';
import { ShippingCountryFormProps } from '../../section-shipping-country';

export const FieldCarrierDefault: React.FC = () => {
  const [t] = useTranslation('settings');
  const carriersListQuery = useCarriersListQuery();

  const {
    control,
    setValue,
    formState: { isSubmitting },
  } = useFormContext<ShippingCountryFormProps>();

  const shippingCountriesValue = useWatch({
    control,
    name: 'shippingCountries',
  });

  const defaultCarrierIdValue = useWatch({
    control,
    name: 'defaultCarrierId',
  });

  const carriers = useMemo(
    () =>
      carriersListQuery.data
        ?.filter((x) => x.isActive)
        .map((x) => ({
          value: x.carrierId,
          children: x.name,
        })) || [],
    [carriersListQuery.data?.length],
  );

  const handleDefaultChangeCarrier = (carrierId?: string) => {
    const carrierToReplace = carriersListQuery.data?.find(
      (x) => x.carrierId === defaultCarrierIdValue,
    );

    const updatedData: Omit<ShippingCountryType, 'partnerCarriedId'>[] = [
      ...(shippingCountriesValue.filter((x) => x.carrierId !== Number(carrierId)) || []),
      ...(carrierToReplace
        ? [
            {
              carrierId: Number(carrierToReplace?.carrierId || 0),
              carrierName: carrierToReplace?.name || '',
              countryCodes: [],
            },
          ]
        : []),
    ];
    setValue('shippingCountries', updatedData);
  };

  return (
    <Stack sx={{ mt: 2 }}>
      <Controller
        control={control}
        name="defaultCarrierId"
        rules={{
          required: {
            value: true,
            message: t('form.defaultCarrierId.validation.required'),
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <SelectField
              disabled={isSubmitting || carriers.length < 2}
              value={String(field.value)}
              onChange={(value) => {
                handleDefaultChangeCarrier(value);
                field.onChange(value);
              }}
              onBlur={field.onBlur}
              label={t('form.defaultCarrierId.label')}
              placeholder={t('form.defaultCarrierId.placeholder')}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              sx={{ mb: 2, flex: 1, m: 0 }}
              options={carriers}
              tooltipContent={t('form.defaultCarrierId.tooltip.__html')}
            />
          );
        }}
      />
      <Typography variant="h5">
        {t('carriers.shipping-country.default-carrier.explanation')}
      </Typography>
    </Stack>
  );
};
