import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { PlatformType, ResponseAPIType } from '../../shared/types';
import { PlatformStatus } from '../types';

type ResponseType = {
  platformCode: PlatformType;
  isActive: boolean;
  sellerId?: string;
  clientKey?: string;
}[];

export const usePlatformsSettingsQuery = (): UseQueryResult<PlatformStatus[]> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<PlatformStatus[]>(
    ['settings', 'platforms'],
    async () => {
      const response = await httpClient.get<ResponseAPIType<ResponseType>>(
        API.salesIntelligence(currentPartnerId).platforms(),
      );

      return response.data.data;
    },
    {
      staleTime: 3600000,
      enabled: authorized,
    },
  );
};
