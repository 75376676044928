import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
  direction?: 'prev' | 'next';
};

export const ChevronIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      data-testid="chevron-icon"
      sx={props.sx}
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4692_17238)" fill="none">
        {(!props.direction || props.direction === 'prev') && (
          <path
            d="M6.5 1L1.5 6L6.5 11"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}

        {props.direction === 'next' && (
          <path
            d="M1.5 1L6.5 6L1.5 11"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </g>
      <defs>
        <clipPath id="clip0_4692_17238">
          <rect width="7" height="12" fill="none" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
