import React, { useEffect, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { LeftArrow, RightArrow, TabItem } from './components';
import { Tab } from './types';

type Props = {
  sx?: SxProps<Theme>;
  tabs: Tab[];
  onItemClick: (itemId: number | string) => void;
  onOrderChange: (tabs: Tab[]) => void;
  activeTabId?: number | string;
  disableScrollToActiveOnInit?: boolean;
};

type ScrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export const TabsHorizontalDraggable: React.FC<Props> = (props) => {
  const apiRef = React.useRef({} as ScrollVisibilityApiType);
  const [tabs, setTabs] = useState<Tab[]>(props.tabs);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setTabs((items) => {
        const oldIndex = items.findIndex((x) => x.id === active?.id);
        const newIndex = items.findIndex((x) => x.id === over?.id);
        const result = arrayMove(items, oldIndex, newIndex);
        setActiveId(null);
        props.onOrderChange(result);
        return result;
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const scrollToItemById = (
    itemId: string | number,
    behavior?: ScrollBehavior,
    inline?: ScrollLogicalPosition,
  ) => {
    apiRef.current.scrollToItem(
      apiRef.current.getItemById(String(itemId)),
      // OR if you not sure about id for first item
      // apiRef.current.getItemById(apiRef.current.items.toItems()[0]),
      behavior || 'auto',
      inline || 'center',
    );
  };

  const onInitScrollView = () => {
    if (!props.activeTabId || props.disableScrollToActiveOnInit) return;
    scrollToItemById(props.activeTabId);
  };

  const onItemClick = (tabId: string | number) => () => {
    props.onItemClick(tabId);
  };

  const activeDraggingItem = tabs.find((x) => x.id === activeId);

  useEffect(() => {
    setTabs([...props.tabs]);
  }, [props.tabs]);

  useEffect(() => {
    if (!props.activeTabId) return;
    scrollToItemById(props.activeTabId, 'smooth', 'nearest');
  }, [props.activeTabId]);

  const rootStyles: SxProps<Theme> = {
    position: 'relative',
    '&  .react-horizontal-scrolling-menu--scroll-container': {
      overflowX: 'hidden',
    },
    '& .react-horizontal-scrolling-menu--item': {
      borderLeft: '1px solid',
      borderColor: 'nethansa.line.light',
      '&:last-of-type': {
        borderRight: '1px solid',
        borderColor: 'nethansa.line.light',
      },
    },
    ...(props.sx || {}),
  };

  return (
    <Box sx={rootStyles}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <SortableContext items={tabs} strategy={horizontalListSortingStrategy}>
          <Box>
            <ScrollMenu
              apiRef={apiRef}
              onInit={onInitScrollView}
              RightArrow={RightArrow}
              LeftArrow={LeftArrow}
            >
              {tabs.map((tab) => {
                return (
                  <TabItem
                    key={tab.id}
                    onClick={onItemClick(tab.id)}
                    isActive={Boolean(props.activeTabId === tab.id)}
                    {...tab}
                  />
                );
              })}
            </ScrollMenu>
          </Box>
        </SortableContext>

        <DragOverlay>
          {activeId && activeDraggingItem ? <TabItem {...activeDraggingItem} dragOverlay /> : null}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};
