import React from 'react';
import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { NethansaLogo } from '../logos';
import { Show } from '../show';

type Props = {
  out: boolean;
};

export const SplashScreen: React.FC<Props> = (props) => {
  const root: SxProps<Theme> = {
    position: 'fixed',
    height: '100vh',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 100,
    backgroundColor: 'common.white',
  };

  return (
    <Show when={!props.out}>
      <Stack sx={root} alignItems="center" justifyContent="center">
        <NethansaLogo />
      </Stack>
    </Show>
  );
};
