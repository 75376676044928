import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

export const OtherOption: React.FC = () => {
  const [t] = useTranslation('wizard');

  return (
    <Box>
      <Typography variant="h5" sx={{ '&>a': { textDecoration: 'underline' } }}>
        {t('add-products.other-option.description.text')}{' '}
        <a href={t('add-products.other-option.description.link')}>
          {t('add-products.other-option.description.link-label')}
        </a>
      </Typography>
    </Box>
  );
};
