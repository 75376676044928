import { UnitOfSizeType, UnitOfWeightType } from '../../modules/shared/types';

export const poundsToUnit = (val: number, unit: UnitOfWeightType) => {
  const parseUnit: { [key in UnitOfWeightType]: number } = {
    [UnitOfWeightType.Unknown]: 1,
    [UnitOfWeightType.Kilograms]: 2.2046,
    [UnitOfWeightType.Pounds]: 1,
  };
  return (val / parseUnit[unit]).toFixed(3);
};

export const parseWeightUnit = (unit: UnitOfWeightType): string => {
  const weightUnitDictionary: { [key in UnitOfWeightType]: string } = {
    [UnitOfWeightType.Unknown]: '-',
    [UnitOfWeightType.Kilograms]: 'kg',
    [UnitOfWeightType.Pounds]: 'lb',
  };

  return weightUnitDictionary[unit] || '-';
};

export const inchesToUnit = (val: number, unit: UnitOfSizeType) => {
  const parseUnit: { [key in UnitOfSizeType]: number } = {
    [UnitOfSizeType.Centimeters]: 0.3937,
    [UnitOfSizeType.Inches]: 1,
    [UnitOfSizeType.Unknown]: 1,
  };
  return (val / parseUnit[unit]).toFixed(3);
};

export const parseLengthUnit = (unit: UnitOfSizeType): string => {
  const lengthUnitDictionary: { [key in UnitOfSizeType]: string } = {
    [UnitOfSizeType.Centimeters]: 'cm',
    [UnitOfSizeType.Inches]: 'in',
    [UnitOfSizeType.Unknown]: '-',
  };

  return lengthUnitDictionary[unit] || '-';
};
