import * as React from 'react';
import { Box, Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type Option = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

type Props = {
  options: Option[];
  onSelect: (value: string | number) => void;
  buttonLabel?: string;
};

export const ButtonSelectMenu: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: string | number) => {
    setAnchorEl(null);
    props.onSelect(value);
  };

  return (
    <Box>
      <Button size="small" variant="text" onClick={handleClick}>
        {props.buttonLabel || 'Options'}
      </Button>
      <Menu
        id="long-button-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '184px',
            width: '256px',
            boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.3)',
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option.value}
            disabled={option.disabled}
            onClick={() => handleSelect(option.value)}
            sx={{ '&:hover': { backgroundColor: 'nethansa.other.pale' } }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
