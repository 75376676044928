import React from 'react';
import { TooltipWrapper } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { PlatformType } from 'modules/shared/types';
import { Button, Stack, Typography } from '@mui/material';
import { usePlatformsContext, usePlatformsSettingsQuery } from '../../../../hooks';

export const ManageButtonMenu: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const {
    connector: [, setProvider],
    // disconnectPlatform: [, setDisconnectProvider],
  } = usePlatformsContext();

  const platformsQuery = usePlatformsSettingsQuery();

  const handleConnectEditPlatform = (platform: PlatformType) => () => {
    setProvider(platform);
  };

  // const handleDisconnectPlatform = (platform: PlatformCode) => () => {
  //   setDisconnectProvider(platform);
  // };

  const Menu: React.FC = () => (
    <Stack gap={2} sx={{ p: 0 }}>
      {platformsQuery.data?.map((x) => (
        <Stack key={x.platformCode} direction="row" alignItems="center" gap={2}>
          <Typography variant="h3" sx={{ minWidth: '120px' }}>
            {tCommon(`platform-code-to-name.${x.platformCode}`)}
          </Typography>
          {x.isActive && (
            <Stack direction="row" gap={1}>
              <Button size="small" color="flat" onClick={handleConnectEditPlatform(x.platformCode)}>
                {tCommon('edit')}
              </Button>
              {/*<Button size="small" color="flat" onClick={handleDisconnectPlatform(x.platformCode)}>*/}
              {/*  {tCommon('disconnect')}*/}
              {/*</Button>*/}
            </Stack>
          )}
          {!x.isActive && (
            <Stack direction="row" gap={1}>
              <Button size="small" color="flat" onClick={handleConnectEditPlatform(x.platformCode)}>
                {tCommon('connect')}
              </Button>
            </Stack>
          )}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <React.Fragment>
      <TooltipWrapper content={<Menu />} placement="bottom-end" sx={{ zIndex: 1 }}>
        <Button color="flat" size="small">
          {tCommon('manage')}
        </Button>
      </TooltipWrapper>
    </React.Fragment>
  );
};
