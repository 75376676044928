import { createTheme } from '@mui/material';
import { alertStyles } from './components/alert.styles';
import { autocompleteStyles } from './components/autocomplete.styles';
import { buttonGroupStyles } from './components/button-group.styles';
import { buttonsStyles } from './components/buttons.styles';
import { checkboxStyles } from './components/checkbox.styles';
import { dialogStyles } from './components/dialog.styles';
import { dividerStyles } from './components/divider.styles';
import { formControlLabelStyles } from './components/form-control-label.styles';
import { inputLabelStyles } from './components/input-label.styles';
import { linkStyles } from './components/link.styles';
import { listItemButtonStyles } from './components/list-item-button.styles';
import { menuItemStyles } from './components/menu-item.styles';
import { menuStyles } from './components/menu.styles';
import { nativeSelectStyles } from './components/native-select.styles';
import { paperStyles } from './components/paper.styles';
import { radioStyles } from './components/radio.styles';
import { selectStyles } from './components/select.styles';
import { skeletonStyles } from './components/skeleton.styles';
import { stepperStyles } from './components/stepper.styles';
import { switchStyles } from './components/switch.styles';
import { tabStyles } from './components/tab.styles';
import { tableStyles } from './components/table.styles';
import { tabsStyles } from './components/tabs.styles';
import { textFieldStyles } from './components/text-field.styles';
import { tooltipStyles } from './components/tooltip.styles';
import { paletteOptions } from './pallete';
import { typographyOptions } from './typography';

export const theme = createTheme({
  typography: typographyOptions,
  palette: paletteOptions,
  components: {
    MuiAutocomplete: autocompleteStyles,
    MuiButton: buttonsStyles,
    MuiButtonGroup: buttonGroupStyles,
    MuiPaper: paperStyles,
    MuiTextField: textFieldStyles,
    MuiSelect: selectStyles,
    MuiMenu: menuStyles,
    MuiMenuItem: menuItemStyles,
    MuiInputLabel: inputLabelStyles,
    MuiTooltip: tooltipStyles,
    MuiStepper: stepperStyles,
    MuiAlert: alertStyles,
    MuiDialog: dialogStyles,
    MuiDivider: dividerStyles,
    MuiCheckbox: checkboxStyles,
    MuiTable: tableStyles,
    MuiSkeleton: skeletonStyles,
    MuiLink: linkStyles,
    MuiSwitch: switchStyles,
    MuiFormControlLabel: formControlLabelStyles,
    MuiListItemButton: listItemButtonStyles,
    MuiNativeSelect: nativeSelectStyles,
    MuiTab: tabStyles,
    MuiTabs: tabsStyles,
    MuiRadio: radioStyles,
  },
  zIndex: {
    modal: 5,
  },
});
