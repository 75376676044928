import React from 'react';
import { DatagridWrapperPage, GridTableIds } from 'modules/datagrid';
import { TableMonitoringOffers } from './components';
import { useMonitoringColumnsDefinition } from './hooks';
import { MonitoringModalsCoordinator } from './monitoring-modals-coordinator';

export const MonitoringPage: React.FC = () => {
  const columns = useMonitoringColumnsDefinition();
  const parsedColumns = columns.map((x) => x.field);

  return (
    <MonitoringModalsCoordinator>
      <DatagridWrapperPage
        tableId={GridTableIds.Monitoring}
        columns={parsedColumns}
        initialData={{ itemsPerPage: 50 }}
      >
        <TableMonitoringOffers />
      </DatagridWrapperPage>
    </MonitoringModalsCoordinator>
  );
};
