import React from 'react';
import { useTranslation } from 'react-i18next';
import { Step, StepLabel, Stepper } from '@mui/material';
import { StepCircleWizard } from './components/stepper-circle-wizard';

type Props = {
  activeStep: number;
};

export const StepperWizard: React.FC<Props> = (props) => {
  const [t] = useTranslation('wizard');

  return (
    <Stepper activeStep={props.activeStep || 0} alternativeLabel>
      <Step>
        <StepLabel StepIconComponent={StepCircleWizard}>{t('stepper.set-globals')}</StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={StepCircleWizard}>{t('stepper.connect-platforms')}</StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={StepCircleWizard}>
          {t('stepper.set-vat-and-shipping')}
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={StepCircleWizard}>{t('stepper.add-products')}</StepLabel>
      </Step>
    </Stepper>
  );
};
