import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from '../../../../common/api';
import { ResponseAPIDataStatusErrorType, ResponseAPIDataStatusType } from '../../../shared/types';
import { useDatagridViewContext } from '../use-datagrid-view-context';
import { useViewsQuery } from './use-views.query';

type InputType = {
  viewId: string;
  newName: string;
};

export const useViewRenameMutation = (): UseMutationResult<
  ResponseAPIDataStatusType,
  ResponseAPIDataStatusErrorType,
  InputType
> => {
  const { tableId } = useDatagridViewContext();
  const { refetch } = useViewsQuery(tableId);

  return useMutation(
    async (body: InputType) => {
      const response = await httpClient.patch<ResponseAPIDataStatusType>(
        API.views().viewRename(body.viewId, tableId),
        {
          newName: body.newName,
        },
      );
      return response.data;
    },
    {
      onSettled: () => {
        refetch();
      },
    },
  );
};
