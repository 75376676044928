type GenericVisibleColumnType = { [key: string]: boolean };

export const parseVisibleColumns = (
  visibleColumnsObject: GenericVisibleColumnType,
  allColumns: string[],
): GenericVisibleColumnType => {
  if (Object.keys(visibleColumnsObject).length === 0) return {};

  return allColumns
    .filter((x) => x !== '__check__')
    .reduce((acc, v) => {
      if (acc[v]) return acc;
      acc[v] = false;
      return acc;
    }, visibleColumnsObject);
};
