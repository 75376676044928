import React, { useCallback } from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { DialogConfirmation, FlagIcon, Show } from '../../../../../../components';
import { useGlobalSettingsQuery } from '../../../../../settings-sales-global';
import { PlatformType } from '../../../../../shared/types';
import { generalPlatformFields } from '../../../../helpers';
import { usePlatformQuery, usePlatformsContext } from '../../../../hooks';
import { PlatformFieldType, PlatformInput } from '../../../../types';
import { RenderCell, RenderFields, RenderLabels } from './components';

export const SectionMarketplaces: React.FC = () => {
  const [t] = useTranslation('platforms');
  const {
    tab: [platformType],
    modal: [, setModalProvider],
  } = usePlatformsContext();
  const { data: platformData } = usePlatformQuery(platformType as PlatformType);
  const globalSettingsQuery = useGlobalSettingsQuery();
  const { getValues, setValue } = useFormContext<PlatformInput>();

  const managedByNethansaField: PlatformFieldType[] = [
    {
      type: 'checkbox',
      name: 'isActive',
      title: t('form.managedByNethansa.label'),
      tooltip: t('form.managedByNethansa.tooltip.__html'),
      onChange: handleManagedByNethansaChange,
    },
  ];
  const isConfiguredField: PlatformFieldType[] = [
    {
      name: 'isConfigured',
      type: 'text',
      title: t('form.isConfigured.label'),
      tooltip: t('form.isConfigured.tooltip.__html'),
    },
  ];

  function handleManagedByNethansaChange(
    field: ControllerRenderProps<any, string>,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    if (field.value === true) {
      const columnNumber = Number((field.name.match(/\d+/g) || [0])[0]);
      const countryCode = getValues(`marketplaces.${columnNumber}.countryCode`);
      const marketplace =
        platformType === 'KAUF'
          ? 'kaufland.de'
          : t(`amazon.markets.website.${countryCode as 'DE'}`);

      setModalProvider(
        <DialogConfirmation
          confirmLabel={t('modals.buttons.confirm')}
          open
          onClose={(confirmed) => {
            if (confirmed) {
              setValue(field.name as `marketplaces.${number}.isActive`, false, {
                shouldDirty: true,
              });
            }
            setModalProvider(undefined);
          }}
          header={t('modals.disableManagedBy.header')}
          description=""
        >
          <Stack sx={{ width: '576px', mx: 4, mb: 2, alignItems: 'flex-start' }}>
            <Typography
              variant="h5"
              sx={{
                lineHeight: '18px',
                '& p': {
                  m: 0,
                  mb: 1.5,
                },
              }}
            >
              <Trans
                i18nKey="modals.disableManagedBy.description"
                t={t}
                values={{
                  marketplace: marketplace,
                }}
              />
            </Typography>
          </Stack>
        </DialogConfirmation>,
      );
    } else {
      field.onChange(event);
    }
  }

  const getPlatformFields = useCallback(
    (isFirstPlatform?: boolean): PlatformFieldType[] => {
      if (platformType === 'AMZEU') {
        if (!isFirstPlatform)
          return generalPlatformFields(t).filter(
            (line) => line.name !== 'isAutomaticCreateAndSearchOffersEnabled',
          );

        return [
          {
            type: 'text',
            name: 'isBreak',
            title: t('form.holidaysEnabled.label'),
            tooltip: t('form.holidaysEnabled.tooltip.__html'),
          },
          ...generalPlatformFields(t),
        ];
      }

      if (platformType === 'KAUF') {
        return [...managedByNethansaField, ...generalPlatformFields(t)];
      }

      return generalPlatformFields(t);
    },
    [platformType, globalSettingsQuery.data?.baseCurrency],
  );

  const columnsPerMarket = platformData?.partnerChannels?.[0]?.partnerMarketplaces?.length || 1;

  return (
    <Box>
      {platformData?.partnerChannels.map((platform, platformIndex) => {
        return (
          <React.Fragment key={platform.id}>
            <Box
              sx={{
                height: '60px',
                position: platformIndex !== 0 ? 'static' : 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'nethansa.main.light',
              }}
            >
              <Show when={platformIndex === 0}>
                <Stack direction="row">
                  <Box sx={{ visibility: 'hidden' }}>
                    <RenderLabels fields={managedByNethansaField} />
                  </Box>
                  {platform.partnerMarketplaces.map((marketplace) => (
                    <RenderCell key={marketplace.id} justifyContent="center" sx={{ pl: 3 }}>
                      <FlagIcon country={marketplace.countryCode} showCountryCode />
                    </RenderCell>
                  ))}
                </Stack>
              </Show>
            </Box>

            <Show when={platformType === 'AMZEU' && platform.name === 'AMZFBM'}>
              <Box sx={{ px: 2 }}>
                <Stack direction="row">
                  <RenderLabels fields={isConfiguredField} />
                  {platform.partnerMarketplaces.map((marketplace, marketplaceIndex) => (
                    <RenderFields
                      baseCurrency={platformData?.partnerBaseCurrencyCode}
                      key={marketplace.id}
                      index={marketplaceIndex + platformIndex * columnsPerMarket}
                      marketplace={marketplace}
                      fields={isConfiguredField}
                      disabled={true}
                    />
                  ))}
                </Stack>
              </Box>

              <Box sx={{ px: 2 }}>
                <Stack direction="row">
                  <RenderLabels fields={managedByNethansaField} />
                  {platform.partnerMarketplaces.map((marketplace, marketplaceIndex) => (
                    <RenderFields
                      baseCurrency={platformData?.partnerBaseCurrencyCode}
                      key={marketplace.id}
                      index={marketplaceIndex + platformIndex * columnsPerMarket}
                      marketplace={marketplace}
                      fields={managedByNethansaField}
                      disabled={!marketplace.isAvailable}
                    />
                  ))}
                </Stack>
              </Box>
            </Show>

            <Show when={platformType === 'AMZEU'}>
              <Stack
                sx={{
                  backgroundColor: 'nethansa.background.group',
                  height: '40px',
                  position: 'sticky',
                  top: '60px',
                  zIndex: 1,
                }}
                justifyContent="center"
              >
                <Typography variant="h4" sx={{ px: 2 }}>
                  {t(`amazon.fulfilled-by.${platform.name as 'AMZFBA'}`)}
                </Typography>
              </Stack>
            </Show>

            <Box sx={{ px: 2 }}>
              <Stack direction="row">
                <RenderLabels fields={getPlatformFields(platformIndex === 0)} />
                {platform.partnerMarketplaces.map((marketplace, marketplaceIndex) => {
                  return (
                    <RenderFields
                      baseCurrency={platformData?.partnerBaseCurrencyCode}
                      key={marketplace.id}
                      index={marketplaceIndex + platformIndex * columnsPerMarket}
                      marketplace={marketplace}
                      fields={getPlatformFields(platformIndex === 0)}
                      disabled={!marketplace.isAvailable}
                    />
                  );
                })}
              </Stack>
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
};
