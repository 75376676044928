import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonTranslations } from 'modules/shared';
import { ConnectionStatus } from 'modules/shared/enums';
import { PlatformCode } from 'modules/shared/types';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import {
  WizardPlatformsConnectorModal,
  WizardPlatformsDisconnectModal,
} from '../../wizard-platforms-connector';
import {
  OnboardingWizardContext,
  WizardFormStepType,
} from '../contexts/onboarding-wizard.provider';
import { useWizardPlatformDeleteMutation, useWizardPlatformsQuery } from '../hooks/platforms';

// Translations:
// t('connect-platforms.platform-code-to-name.amzeu')
// t('connect-platforms.platform-code-to-name.kauf')

export const PlatformsWizardStep: React.FC = () => {
  const [t] = useTranslation('wizard');
  const { tCommon } = useCommonTranslations();
  const context = useContext(OnboardingWizardContext);

  const [showError, setShowError] = useState(false);
  const [connectModal, setConnectModal] = useState<PlatformCode | undefined>();
  const [disconnectPlatformModal, setDisconnectPlatformModal] = useState<
    PlatformCode | undefined
  >();

  const platforms = useWizardPlatformsQuery();
  const platformDeleteMutation = useWizardPlatformDeleteMutation();

  const handleBackClick = useCallback(() => {
    context.goToFormStep(WizardFormStepType.globals);
  }, []);

  const handleNextClick = useCallback(() => {
    if (
      !platforms.data?.some(
        (s) => s.connectionStatus === ConnectionStatus[ConnectionStatus.connected],
      )
    ) {
      setShowError(true);
      return;
    }
    context.goToFormStep(WizardFormStepType['vat-and-shipping']);
  }, [platforms.data]);

  const onConnectClick = useCallback(
    (provider: PlatformCode) => {
      setConnectModal(String(provider).toLowerCase() as PlatformCode);
    },
    [setConnectModal],
  );

  const handleCloseConnector = useCallback(async () => {
    setConnectModal(undefined);
    await platforms.refetch();
    setShowError(false);
  }, [setConnectModal, platforms.refetch]);

  const handleCloseDisconnectModal = useCallback(
    (confirm: boolean) => {
      setDisconnectPlatformModal(undefined);
      if (!disconnectPlatformModal || !confirm) return;
      platformDeleteMutation.mutate(
        { platformCode: disconnectPlatformModal },
        {
          onSettled: () => {
            platforms.refetch();
          },
        },
      );
    },
    [disconnectPlatformModal, setDisconnectPlatformModal],
  );

  const disabled = platformDeleteMutation.isLoading || platforms.isLoading || platforms.isFetching;

  return (
    <>
      <WizardPlatformsConnectorModal
        keepCloseButton
        open={!!connectModal}
        provider={connectModal}
        onClose={handleCloseConnector}
      />
      <WizardPlatformsDisconnectModal
        onClose={handleCloseDisconnectModal}
        open={!!disconnectPlatformModal}
      />
      <DialogContent>
        <Box sx={{ py: 5, backgroundColor: 'background.paper', px: 5 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            {t('connect-platforms.header')}
          </Typography>
          <Stack gap={3}>
            {platforms.isLoading && (
              <>
                <Skeleton variant={'rectangular'} height="83px" sx={{ py: '28px', px: '32px' }} />
                <Skeleton variant={'rectangular'} height="83px" sx={{ py: '28px', px: '32px' }} />
              </>
            )}
            {platforms.data?.map((x) => (
              <Stack
                key={x.platformCode}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  py: '28px',
                  px: '32px',
                  backgroundColor: 'background.default',
                  opacity: disabled ? 0.5 : 1,
                  pointerEvents: disabled ? 'none' : 'all',
                }}
              >
                <Typography variant="h2">
                  {tCommon(`platform-code-to-name.${x.platformCode}`)}
                </Typography>
                <Stack direction="row" gap={2}>
                  {x.connectionStatus === ConnectionStatus[ConnectionStatus.connected] && (
                    <>
                      <Button
                        color="flat"
                        variant="contained"
                        size="small"
                        onClick={() => onConnectClick(x.platformCode)}
                      >
                        {tCommon('edit')}
                      </Button>
                      <Button
                        color="flat"
                        variant="contained"
                        size="small"
                        onClick={() => setDisconnectPlatformModal(x.platformCode)}
                      >
                        {tCommon('disconnect')}
                      </Button>
                    </>
                  )}
                  {x.connectionStatus !== ConnectionStatus[ConnectionStatus.connected] && (
                    <Button
                      color="flat"
                      variant="contained"
                      size="small"
                      onClick={() => onConnectClick(x.platformCode)}
                      disabled={
                        platforms.data?.filter(
                          (p) => p.connectionStatus == ConnectionStatus[ConnectionStatus.connected],
                        ).length > 0
                      }
                    >
                      {tCommon('connect')}
                    </Button>
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
          {showError && (
            <Box sx={{ mt: 3 }}>
              <Alert severity="error">{t('connect-platforms.missing-connected-platform')}</Alert>
            </Box>
          )}
        </Box>
        <DialogActions sx={{ p: 2, borderTop: '1px solid #DDE8F1' }}>
          <Button variant="text" onClick={handleBackClick} disabled={disabled}>
            {tCommon('back')}
          </Button>
          <Button onClick={handleNextClick} disabled={disabled}>
            {tCommon('next')}
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};
