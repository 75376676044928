import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { format } from 'date-fns';
import * as echarts from 'echarts';
import _ from 'lodash';
import { usePriceHistoryQuery } from 'modules/offers/hooks/use-price-history.query';
import { StoredSnapshot } from 'modules/offers/types';
import { nethansaPalette } from 'theme';
import { Alert, Box, LinearProgress } from '@mui/material';

type ActiveRange = { start: number | null; end: number | null };

export const PriceHistory: React.FC = () => {
  const { data, isLoading, isError, isSuccess } = usePriceHistoryQuery();
  const { t } = useTranslation('common');
  const { t: tOffers } = useTranslation('offers');

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;

    if (!isSuccess) {
      echarts.getInstanceByDom(ref.current)?.clear();
    }
    InitChart(ref.current, data ?? [], t);
  }, [data, isSuccess]);

  return (
    <>
      <Show when={isLoading}>
        <LinearProgress />
      </Show>
      <Show when={isError}>
        <Alert severity="error">{tOffers('offer-details.error.general')}</Alert>
      </Show>
      <Box ref={ref} sx={{ minHeight: '600px', p: '32px' }}></Box>
    </>
  );
};

function GetActiveRanges(data: StoredSnapshot[]): ActiveRange[] {
  const sortedData = _.sortBy(data, (d) => new Date(d.createdAt).getTime());
  let start: number | null = null;
  const activeData: ActiveRange[] = [];

  for (const sample of sortedData) {
    if (start === null) {
      if (!sample.activeOnMarketplace) continue;
      else {
        start = new Date(sample.createdAt).getTime();
        continue;
      }
    }

    if (start !== null && !sample.activeOnMarketplace) {
      activeData.push({ start: start, end: new Date(sample.createdAt).getTime() });
      start = null;
    }
  }

  if (start !== null) {
    activeData.push({ start: start, end: new Date(sortedData.at(-1)!.createdAt).getTime() });
  }

  return activeData;
}

function InitChart(
  node: HTMLDivElement,
  data: StoredSnapshot[],
  t: (label: string) => string,
): echarts.EChartsType {
  const chart = echarts.init(node);

  const activeRanges = GetActiveRanges(data);

  chart.setOption({
    height: 300,
    title: { text: '' },
    legend: {
      selected: { [t('product-cost')]: false, [t('marketplace-price')]: false },
    },
    tooltip: { trigger: 'axis' },
    toolbox: {
      feature: {},
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
    xAxis: {
      type: 'time',
      axisLabel: {
        rotate: -70,
        formatter: (val: string | Date) => {
          return format(new Date(val), 'dd MMM HH:mm');
        },
      },
    },
    yAxis: { type: 'value' },
    series: [
      {
        markArea: {
          itemStyle: {
            color: 'rgba(57, 132, 95, 0.10)',
          },
          data: activeRanges.map((d) => [{ name: '', xAxis: d.start }, { xAxis: d.end }]),
        },
        name: t('product-cost'),
        type: 'line',
        data: data.map((x) => [
          new Date(x.createdAt),
          x.purchasePrice === 0 ? null : x.purchasePrice,
        ]),
        itemStyle: {
          color: nethansaPalette.other.blueDeep,
        },
        label: {
          show: false,
        },
      },
      {
        markArea: {
          itemStyle: {
            color: 'rgba(57, 132, 95, 0.10)',
          },
          data: activeRanges.map((d) => [{ name: '', xAxis: d.start }, { xAxis: d.end }]),
        },
        name: t('result-price'),
        type: 'line',
        data: data.map((x) => [new Date(x.createdAt), x.finalPrice]),
        itemStyle: {
          color: nethansaPalette.main.active,
        },
      },
      {
        markArea: {
          itemStyle: {
            color: 'rgba(57, 132, 95, 0.10)',
          },
          data: activeRanges.map((d) => [{ name: '', xAxis: d.start }, { xAxis: d.end }]),
        },
        name: t('marketplace-price'),
        type: 'line',
        data: data.map((x) => [
          new Date(x.createdAt),
          x.lastMarketplacePrice === 0 ? null : x.lastMarketplacePrice,
        ]),
        itemStyle: {
          color: nethansaPalette.button.blueMain,
        },
      },
      {
        markArea: {
          itemStyle: {
            color: 'rgba(57, 132, 95, 0.10)',
          },
          data: activeRanges.map((d) => [{ name: '', xAxis: d.start }, { xAxis: d.end }]),
        },
        name: t('competitor-price'),
        type: 'line',
        data: data.map((x) => [
          new Date(x.createdAt),
          x.competitorPrice === 0 ? null : x.competitorPrice,
        ]),
        itemStyle: {
          color: nethansaPalette.main.watermelon,
        },
      },
    ],
  } as echarts.EChartOption);

  return chart;
}
