import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import { ErrorMeta } from 'modules/shared/types';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { useAuth } from '../../authorization';
import { BillingDataType } from '../types';

type InputType = Omit<BillingDataType, 'countries'>;

export const useBillingDataMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();
  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    return await envelope(httpClient.put(API.billings().billingData(currentPartnerId), body));
  });
};
