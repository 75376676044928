import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { PriceComponentsBreakdownType } from '../types';
import { useOfferCoordinatorContext } from './use-offer-coordinator-context';

export const usePriceComponentsBreakdownQuery =
  (): UseQueryResult<PriceComponentsBreakdownType> => {
    const { authorized, currentPartnerId } = useAuth();
    const {
      offerDetails: [data],
    } = useOfferCoordinatorContext();

    return useQuery<PriceComponentsBreakdownType>(
      ['offers', 'price-components', data?.offerId],
      async () => {
        const response = await httpClient.post<ResponseAPIType<PriceComponentsBreakdownType>>(
          API.offers(currentPartnerId).offersDetailsPriceComponentsBreakdown(
            encodeURIComponent(data?.offerId || ''),
          ),
        );
        return response.data.data;
      },
      {
        staleTime: 1000,
        keepPreviousData: false,
        enabled: authorized,
        retry: false,
      },
    );
  };
