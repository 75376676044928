import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { DialogBaseWrapper, FormButtonsGroup } from '../../../../components';
import { useCommonTranslations } from '../../../shared';
import { useSnackbar } from '../../../snackbar';
import { useDatagridViewContext, useDatagridViewsManager } from '../../hooks';
import { useViewAddMutation, useViewsQuery } from '../../hooks/api';

type FormProps = {
  viewName: string;
  isShared: boolean;
  gridConfig: string;
};

const radioButtonLabelStyle: SxProps<Theme> = {
  height: '30px',
  '&.MuiFormControlLabel-root .MuiTypography-root': {
    ml: 1,
  },
};

export const ModalCreateView: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('common');
  const { createViewModal, tableId } = useDatagridViewContext();
  const { getNextPositionNumber, createAndActivateTab } = useDatagridViewsManager();
  const { refetch } = useViewsQuery(tableId);
  const { mutate, isLoading } = useViewAddMutation();
  const { pushSnackbar } = useSnackbar();

  const { control, handleSubmit, setError } = useForm<FormProps>({
    defaultValues: {
      viewName: createViewModal.view?.name || '',
      isShared: false,
      gridConfig: createViewModal.view?.gridConfiguration || '{}',
    },
  });

  const submit = async (data: FormProps) => {
    await mutate(
      {
        name: data.viewName,
        public: data.isShared,
        position: getNextPositionNumber(),
        gridConfiguration: data.gridConfig,
      },
      {
        onError: (error) => {
          setError('viewName', {
            type: 'server',
            message: t(`views.api-responses.${error.response.data.messageCode as 'ok'}`),
          });
        },
        onSuccess: async (response) => {
          pushSnackbar({
            variant: 'success',
            message: t('views.api-responses.created'),
          });
          // TODO: pass response model
          createAndActivateTab({
            ownedByRequestingUser: true,
            predefined: false,
            visible: true,
            id: response.data.id,
            name: response.data.name,
            gridConfiguration: data.gridConfig,
            ownerId: response.data.ownerId,
            public: response.data.public,
            position: response.data.position,
            tableId: response.data.tableId,
            partnerId: response.data.partnerId,
          });
          await refetch();
          createViewModal.change(undefined);
          return;
        },
      },
    );
  };

  return (
    <DialogBaseWrapper
      header={tCommon('views.modal.create-view.title')}
      onClose={() => createViewModal.change(undefined)}
      open={Boolean(createViewModal.view)}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Stack sx={{ px: 4, py: 3 }} spacing={2}>
          <Controller
            rules={{
              required: {
                value: true,
                message: tCommon('views.modal.rename-view.validation.blank'),
              },
            }}
            control={control}
            name="viewName"
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  autoFocus
                  variant="outlined"
                  label={tCommon('views.modal.rename-view.text-input-label')}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="isShared"
            render={({ field }) => {
              return (
                <RadioGroup
                  onChange={(event, value) => {
                    field.onChange(value === 'true');
                  }}
                  aria-labelledby="private-radio-buttons-group-label"
                  defaultValue={String(field.value)}
                  value={String(field.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio sx={{ p: 0 }} />}
                    label={
                      <Typography variant="h5">
                        {tCommon('views.modal.share-view.radio-option-private')}
                      </Typography>
                    }
                    sx={radioButtonLabelStyle}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio sx={{ p: 0 }} />}
                    label={
                      <Typography variant="h5">
                        {tCommon('views.modal.share-view.radio-option-public')}
                      </Typography>
                    }
                    sx={radioButtonLabelStyle}
                  />
                </RadioGroup>
              );
            }}
          />
        </Stack>
        <Divider />
        <FormButtonsGroup sx={{ py: 2, px: 4 }} isLoading={isLoading}>
          <Button
            type="button"
            size="small"
            variant="text"
            disabled={isLoading}
            onClick={() => {
              createViewModal.change(undefined);
            }}
          >
            {tCommon('cancel')}
          </Button>
          <Button type="submit" size="small" disabled={isLoading}>
            {tCommon('confirm')}
          </Button>
        </FormButtonsGroup>
      </form>
    </DialogBaseWrapper>
  );
};
