import React from 'react';
import { routes } from 'routes';
import { NAVIGATION_APP_HEIGHT, nethansaPalette } from 'theme';
import { Box, Stack } from '@mui/material';

export const Navbar: React.FC = () => {
  return (
    <Box
      sx={{
        zIndex: 1,
        position: 'sticky',
        top: 0,
        height: NAVIGATION_APP_HEIGHT,
        backgroundColor: nethansaPalette.main.dark,
        px: 1,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          height: '100%',
          gap: 1,
          color: nethansaPalette.main.gray,
          '& a': {
            color: nethansaPalette.main.active,
          },
        }}
      >
        <Box>
          <a href={routes.Stylebook.link()}>{routes.Stylebook.name}</a>
        </Box>
        <Box>|</Box>
        <Box>
          <a href={routes.Dashboard.link()}>{routes.Dashboard.name || 'Dashboard'}</a>
        </Box>
        <Box>|</Box>
        <Box>
          <a href={routes.Products.link()}>Products</a>
        </Box>
        <Box>|</Box>
        <Box>
          <a href={routes.Offers.link()}>{routes.Offers.name}</a>
        </Box>
        <Box>|</Box>
        <Box>
          <a href={routes.Reports.link()}>{routes.Reports.name}</a>
        </Box>
        <Box>|</Box>
        <Box>
          <a href={routes.Monitoring.link()}>{routes.Monitoring.name}</a>
        </Box>
        <Box>|</Box>
        <Box>
          <a href={routes.Settings.link()}>{routes.Settings.name}</a>
        </Box>
      </Stack>
    </Box>
  );
};
