import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Show, TooltipWrapper } from 'components';
import { AlertIcon, SpinnerIcon } from 'components/icons';
import { PriceManagementMode } from 'generated/graphql';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { usePlatformsSettingsQuery } from 'modules/settings-sales-platforms/hooks';
import { AppGlobalParametersContext } from 'modules/shared/contexts/app-global-parameters.provider';
import { MODULE_NAVIGATION_BAR_HEIGHT, nethansaPalette } from 'theme';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import { GridLogicOperator } from '@mui/x-data-grid-pro';
import { NavigationTabsItem, NavigationTabsLayout } from '../../components/navigation-tabs';
import { routes } from '../../routes';
import { useOffersTotalCountQuery } from './hooks';

export const OffersLayout: React.FC = () => {
  const [t] = useTranslation('offers');
  const showContext = useContext(AppGlobalParametersContext);

  const platformsQuery = usePlatformsSettingsQuery();
  const activePlatforms = (platformsQuery.data || []).filter((x) => x.isActive);

  const { data: globalSettings, isFetching: isGlobalSettingsLoading } = useGlobalSettingsQuery();
  const { data: DPoffersCount, isLoading } = useOffersTotalCountQuery(
    'dynamicPricingCounter',
    [
      {
        field: 'priceManagementMode',
      },
    ],
    {
      items: [
        {
          field: 'priceManagementMode',
          operator: 'is',
          id: 908244,
          value: PriceManagementMode.Dynamic,
        },
      ],
      logicOperator: GridLogicOperator.And,
    },
  );

  const dynamicPricingStyles: SxProps<Theme> = useMemo(
    () => ({
      height: MODULE_NAVIGATION_BAR_HEIGHT,
      backgroundColor: `${nethansaPalette.main.light}`,
      justifyContent: 'center',
      alignItems: 'end',
      paddingRight: '1rem',
      fontSize: '0.875rem',
      fontWeight: 500,
      h5: {
        fontSize: '0.8125rem',
        fontStyle: 'normal',
        fontWeight: 'inherit',
        lineHeight: '1.125rem',
        margin: 0,
        whiteSpace: 'nowrap',
        color: `${nethansaPalette.main.gray}`,
      },
      '.counter': {
        '&.warning .count': {
          color: `${nethansaPalette.main.watermelon}`,
        },
        '&.error .limit': {
          color: `${nethansaPalette.main.watermelon}`,
        },
      },
    }),
    [],
  );

  const nearLimit = useMemo(() => {
    return globalSettings?.limits.dynamicPricingLimit &&
      DPoffersCount !== undefined &&
      (globalSettings?.limits.dynamicPricingLimit / 100) * 95 <= DPoffersCount
      ? true
      : false;
  }, [globalSettings?.limits.dynamicPricingLimit, DPoffersCount]);

  const overLimit = useMemo(() => {
    return globalSettings?.limits.dynamicPricingLimit &&
      DPoffersCount !== undefined &&
      globalSettings?.limits.dynamicPricingLimit < DPoffersCount
      ? true
      : false;
  }, [globalSettings?.limits.dynamicPricingLimit, DPoffersCount]);

  return (
    <NavigationTabsLayout
      title={t('title')}
      isHidden={!showContext.offersModuleWithBars}
      rightSideElement={
        <Stack direction="column" sx={dynamicPricingStyles}>
          <h5>{t('dynamicPricingCounter.title')}</h5>

          <Show
            when={
              DPoffersCount !== undefined &&
              !isLoading &&
              !isGlobalSettingsLoading &&
              globalSettings !== undefined
            }
          >
            <Stack
              direction={'row'}
              className={`counter ${nearLimit ? 'warning' : ''} ${overLimit ? 'error' : ''}`}
            >
              <Show when={overLimit}>
                <TooltipWrapper
                  content={
                    <Box component="span" sx={{ color: 'error.main' }}>
                      {t('dynamicPricingCounter.overLimitTooltip')}
                    </Box>
                  }
                  disableHoverListener={false}
                  placement="right"
                >
                  <Box component="span" sx={{ mr: '4px' }}>
                    <AlertIcon
                      sx={{ fontSize: '16px', cursor: 'pointer', verticalAlign: 'text-bottom' }}
                    />
                  </Box>
                </TooltipWrapper>
              </Show>
              <Box className="count">{DPoffersCount || 0}</Box>
              <Show
                when={
                  globalSettings?.limits.dynamicPricingLimit != null &&
                  globalSettings?.limits.dynamicPricingLimit > 0
                }
              >
                <Box className="limit">
                  &nbsp;/&nbsp;{globalSettings?.limits.dynamicPricingLimit}
                </Box>
              </Show>
            </Stack>
          </Show>
          <Show when={isLoading || isGlobalSettingsLoading}>
            <SpinnerIcon sx={{ fontSize: '26px', px: 0, mx: 0 }} />
          </Show>
        </Stack>
      }
      isNotSticky
    >
      <NavigationTabsItem
        href={routes.Offers.children.OffersFBM.link()}
        children={'Amazon Europe FBM'}
        hidden={activePlatforms.filter((p) => p.platformCode === 'AMZEU').length == 0}
      />
      <NavigationTabsItem
        href={routes.Offers.children.OffersFBA.link()}
        children={'Amazon Europe FBA'}
        hidden={activePlatforms.filter((p) => p.platformCode === 'AMZEU').length == 0}
      />
      <NavigationTabsItem
        href={routes.Offers.children.OffersKaufland.link()}
        children={'Kaufland'}
        hidden={activePlatforms.filter((p) => p.platformCode === 'KAUF').length == 0}
      />
    </NavigationTabsLayout>
  );
};
