import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Checkbox } from 'components';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type PropsType = {
  control: Control;
  name: string;
  label?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export const CellCheckbox: React.FC<PropsType> = (props) => {
  return (
    <Box
      sx={{
        height: '40px',
        display: 'flex',
        justifyContent: props.label ? 'flex-start' : 'center',
        pt: props.label ? 0 : '10px',
        alignItems: 'center',
        ...(props.sx || {}),
      }}
    >
      <Controller
        control={props.control}
        defaultValue={false}
        name={props.name}
        render={({ field }) => {
          return (
            <Checkbox
              disabled={props.disabled}
              value={field.value}
              checked={!!field.value}
              label={props.label || ''}
              onChange={field.onChange}
              onBlur={field.onBlur}
              sx={{
                '& + .MuiFormControlLabel-label': {
                  ml: '0 !important',
                  p: 0,
                },
              }}
            />
          );
        }}
      />
    </Box>
  );
};
