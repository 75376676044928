import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { marketplaceList } from 'common/utils';
import { DialogBaseWrapper, Show } from 'components';
import { AddMarketplaceIcon, ListAltIcon, WarningIcon } from 'components/icons';
import { SelectMultiCheckboxMarketplace } from 'components/selects';
import { useCommonTranslations } from 'modules/shared/hooks';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { MONITORING_LIMIT } from '../consts';
import { useAddOffersToTrackMutation } from '../hooks';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  trackedOffers?: number;
};

export const AddOffersModal: React.FC<Props> = (props) => {
  const [t] = useTranslation('monitoring');
  const { tCommon } = useCommonTranslations();
  const addOffersToTrackMutation = useAddOffersToTrackMutation();

  const [selectedMarketplaces, setSelectedMarketplaces] = useState<string[]>([]);
  const [selectedError, setSelectedError] = useState<string | undefined>(undefined);
  const [pastedAsins, setPastedAsins] = useState<string | undefined>(undefined);
  const [pastedError, setPastedError] = useState<string | undefined>(undefined);

  const { pushSnackbar } = useSnackbar();

  const isLimitReach: boolean = (props.trackedOffers || 0) >= MONITORING_LIMIT;

  const trimmedMultilineText = (text: string): string[] => {
    return text
      .split('\n')
      .map((el) => el.trim())
      .filter((el) => el !== '');
  };

  const getWrongAsins = (asins: string): string[] => {
    return trimmedMultilineText(asins).filter((el) => el.length !== 10 || !/^[A-Z0-9]*$/.test(el));
  };

  const handleSubmit = () => {
    if (!selectedMarketplaces.length) {
      setSelectedError(t('modal.add-offers.error.no-marketplace'));
      return;
    }

    if (!pastedAsins) {
      setPastedError(t('modal.add-offers.error.no-asin'));
      return;
    }

    if (pastedAsins && getWrongAsins(pastedAsins).length > 0) {
      setPastedError(
        t('modal.add-offers.error.wrong-asin-format', {
          asins:
            getWrongAsins(pastedAsins).length > 10
              ? `${getWrongAsins(pastedAsins).join(', ')} ${t(
                  'modal.add-offers.error.wrong-asin-format-more',
                )}`
              : getWrongAsins(pastedAsins).join(', '),
        }),
      );
      return;
    }

    if (
      pastedAsins &&
      getWrongAsins(pastedAsins).length === 0 &&
      potentialNumberOfOffers() > MONITORING_LIMIT
    ) {
      setPastedError(
        t('modal.add-offers.error.limit-exceeded', {
          count: potentialNumberOfOffers(),
          limit: MONITORING_LIMIT,
        }),
      );
      return;
    }

    addOffersToTrackMutation.mutate(
      {
        monitoredOffers: [
          {
            asin: pastedAsins ? trimmedMultilineText(pastedAsins) : [],
            marketplaceCode: selectedMarketplaces,
          },
        ],
      },
      {
        onSuccess: () => {
          handleClose();
          pushSnackbar({
            variant: 'success',
            message: t('modal.add-offers.success'),
          });
          queryClient.refetchQueries({ active: true, queryKey: 'monitoredOffers' });
        },
      },
    );
  };

  const handleClose = () => {
    setSelectedError(undefined);
    setPastedError(undefined);
    setSelectedMarketplaces([]);
    props.onClose();
  };

  const potentialNumberOfOffers = (): number => {
    return (
      (props.trackedOffers || 0) +
      ((pastedAsins && trimmedMultilineText(pastedAsins).length) || 0) * selectedMarketplaces.length
    );
  };

  return (
    <DialogBaseWrapper
      header={t('modal.add-offers.title')}
      open={props.isOpen}
      onClose={handleClose}
      disableCloseOnBackdropClick
    >
      <DialogContent
        sx={{
          mx: 4,
          mb: 2,
          minWidth: '576px !important',
          maxWidth: '576px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Show
          when={!isLimitReach}
          fallback={
            <Stack sx={{ bgcolor: nethansaPalette.background.error, p: 2 }}>
              <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', mb: 0.5 }}>
                <WarningIcon />
                <Typography variant="h3Bold">
                  {t('modal.add-offers.out-of-limit.header', { number: MONITORING_LIMIT })}
                </Typography>
              </Stack>
              <Typography
                variant="h5Text"
                sx={{
                  color: nethansaPalette.main.gray,
                  mt: 1,
                  width: 0,
                  minWidth: '100%',
                }}
              >
                {t('modal.add-offers.out-of-limit.desc')}
              </Typography>
            </Stack>
          }
        >
          <Stack component={'section'} sx={{ bgcolor: nethansaPalette.background.title, p: 2 }}>
            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', mb: 0.5 }}>
              <AddMarketplaceIcon />
              <Typography variant="h3Bold">
                {t('modal.add-offers.section-select.header')}
              </Typography>
            </Stack>
            <SelectMultiCheckboxMarketplace
              items={selectedMarketplaces}
              marketplaces={marketplaceList}
              placeholder={t('modal.add-offers.section-select.placeholder')}
              error={!!selectedError}
              onChange={(value?: string[] | null) => {
                setSelectedError(undefined);
                setPastedError(undefined);
                setSelectedMarketplaces(value ?? []);
              }}
            />
            <Show when={!!selectedError}>
              <Typography variant="h5Text" color={nethansaPalette.main.watermelon}>
                {selectedError}
              </Typography>
            </Show>
          </Stack>

          <Stack component={'section'} sx={{ bgcolor: nethansaPalette.background.title, p: 2 }}>
            <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <ListAltIcon />
              <Typography variant="h3Bold">{t('modal.add-offers.section-paste.header')}</Typography>
            </Stack>
            <Typography
              variant="h5Text"
              sx={{
                color: nethansaPalette.main.gray,
                mt: 1,
                width: 0,
                minWidth: '100%',
              }}
            >
              {t('modal.add-offers.section-paste.desc')}
            </Typography>
            <TextField
              multiline
              rows={3}
              sx={{ '& .MuiInputBase-root': { p: 0, my: 0.5 } }}
              error={!!pastedError}
              onChange={(e) => {
                setPastedAsins(e.target.value);
                if (e.target.value) setPastedError(undefined);
              }}
            />
            <Show when={!!pastedError}>
              <Typography variant="h5Text" color={nethansaPalette.main.watermelon}>
                {pastedError}
              </Typography>
            </Show>
          </Stack>
        </Show>

        <Show when={addOffersToTrackMutation.isError}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {t('modal.add-offers.error.general')}
          </Alert>
        </Show>
      </DialogContent>

      <DialogActions sx={{ px: 4, py: 2, borderTop: `1px solid ${nethansaPalette.line.light}` }}>
        <Show
          when={!isLimitReach}
          fallback={
            <Button type="button" size="small" onClick={handleClose}>
              OK
            </Button>
          }
        >
          <Button
            type="button"
            size="small"
            variant="text"
            data-testid="cancel-offers-to-track-modal-button"
            onClick={handleClose}
          >
            {tCommon('cancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            data-testid="add-offers-to-track-modal-button"
            disabled={!!selectedError || !!pastedError}
            onClick={handleSubmit}
          >
            {t('button.add')}
          </Button>
        </Show>
      </DialogActions>
    </DialogBaseWrapper>
  );
};
