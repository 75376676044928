import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ResponseAPIType } from '../../shared/types';
import { ProductVatResponseType } from '../types';
import { useProductsCoordinatorContext } from './use-products-coordinator-context';

export const useProductVatQuery = (): UseQueryResult<ProductVatResponseType> => {
  const { authorized, currentPartnerId } = useAuth();

  const {
    editVat: [data],
  } = useProductsCoordinatorContext();

  return useQuery<ProductVatResponseType>(
    ['products', 'vat', currentPartnerId, data?.row?.id],
    async () => {
      const response = await httpClient.get<ResponseAPIType<ProductVatResponseType>>(
        API.products(currentPartnerId).vat(data?.row?.id),
      );

      return { ...response.data?.data };
    },
    {
      staleTime: 5000,
      keepPreviousData: false,
      enabled: authorized,
    },
  );
};
