import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { SubscriptionType } from '../types';

type PaymentInfoResponse = SubscriptionType;

export const usePaymentInfoQuery = (): UseQueryResult<PaymentInfoResponse> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<PaymentInfoResponse>(
    ['settings', 'payment-info', currentPartnerId],
    async () => {
      const response = await httpClient.post<PaymentInfoResponse>(API.payments().paymentInfo());
      return response.data;
    },

    {
      staleTime: 55 * (60 * 1000),
      cacheTime: 60 * (60 * 1000),
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
