import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { Checkbox, DialogConfirmation, Show } from 'components';
import { useProductsCoordinatorContext } from 'modules/products/hooks';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import { Alert, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { SpinnerIcon } from '../../../../components/icons';
import { SelectMultiCheckboxMarketplace } from '../../../../components/selects';
import { ChannelCode, Query } from '../../../../generated/graphql';
import { DatagridCellFlag } from '../../../datagrid';
import { usePlatformQuery } from '../../../settings-sales-platforms/hooks';
import { PlatformPartnerChannelMarketplace } from '../../../settings-sales-platforms/types';
import { useBulkCreateOffersMutation } from '../../hooks';
import { SelectedProductDetails } from '../components/selected-product-details';
import { SelectedProducts } from '../components/selected-products';

export const CreateOffersModal: React.FC = () => {
  const {
    createOffers: [actionParams, setActionParams],
  } = useProductsCoordinatorContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [amazonFbmItems, setAmazonFbmItems] = useState<string[]>([]);
  const [amazonFbaItems, setAmazonFbaItems] = useState<string[]>([]);
  const { pushSnackbar } = useSnackbar();
  const useAmazonBulkAction = useBulkCreateOffersMutation();
  const [t] = useTranslation('products');
  const { data: platformDataAmazon, isFetching: isLoadingAmazon } = usePlatformQuery('AMZEU');
  const { data: platformDataKaufland, isFetching: isLoadingKaufland } = usePlatformQuery('KAUF');

  const refreshProducts = () => queryClient.refetchQueries({ active: true, queryKey: 'products' });

  const [amazonFbmMarketplaces, setAmazonFbmMarketplaces] = useState<
    PlatformPartnerChannelMarketplace[]
  >([]);
  const [amazonFbaMarketplaces, setAmazonFbaMarketplaces] = useState<
    PlatformPartnerChannelMarketplace[]
  >([]);
  const [showKaufland, setShowKaufland] = useState<boolean>(false);
  const [commonSku, setCommonSku] = useState<boolean>(false);
  const [selectedKaufland, setSelectedKaufland] = useState<boolean>(false);
  const closeModal = () => setActionParams(undefined);

  useEffect(() => {
    if (!platformDataAmazon?.isActive) return;

    setAmazonFbmMarketplaces(
      platformDataAmazon?.partnerChannels
        .find((ch) => ch.name == ChannelCode.Amzfbm && ch.isActive)
        ?.partnerMarketplaces.filter((m) => m.isActive) ?? [],
    );
    setAmazonFbaMarketplaces(
      platformDataAmazon?.partnerChannels
        .find((ch) => ch.name == ChannelCode.Amzfba && ch.isActive)
        ?.partnerMarketplaces.filter((m) => m.isActive) ?? [],
    );
  }, [platformDataAmazon]);

  useEffect(() => {
    if (!platformDataKaufland?.isActive) return;

    const marketplaces = platformDataKaufland?.partnerChannels
      .find((ch) => ch.name == ChannelCode.Default && ch.isActive)
      ?.partnerMarketplaces.filter((m) => m.isActive);

    if (marketplaces && marketplaces.length > 0) setShowKaufland(true);
  }, [platformDataKaufland]);

  const handleResponse = (data: Query) => {
    const errors = data.createOffers?.errors;
    if (!errors || errors.length == 0) {
      refreshProducts();
      actionParams?.onSuccess && actionParams?.onSuccess();
      closeModal();
      pushSnackbar({
        variant: 'success',
        message: t('modals.createOffers.messages.success'),
      });
    } else setErrorMessage(t('modals.createOffers.messages.error'));
  };

  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }

    useAmazonBulkAction.mutate(
      {
        amzFba: amazonFbaItems,
        amzFbm: amazonFbmItems,
        kaufland: selectedKaufland,
        commonSku: commonSku,
        where: actionParams?.filters,
      },
      {
        onSuccess: (data) => handleResponse(data),
        onError: () => setErrorMessage(t('modals.createOffers.messages.error')),
      },
    );
  };

  return (
    <DialogConfirmation
      isLoading={useAmazonBulkAction.isLoading}
      confirmLabel={t('modals.buttons.create')}
      open
      onClose={confirm}
      header={t('modals.createOffers.header')}
      tooltip={t('modals.createOffers.tooltip')}
      description=""
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <Show when={actionParams?.row != null}>
          <SelectedProductDetails row={actionParams?.row} />
        </Show>
        <Show when={actionParams?.row == null}>
          <SelectedProducts productsNumber={actionParams?.selectedCount} />
        </Show>
        <Show when={errorMessage != null}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Show>

        <Show when={!isLoadingAmazon && !isLoadingKaufland}>
          <Stack
            sx={{
              gap: 1,
              flexDirection: 'column',
              backgroundColor: nethansaPalette.background.title,
              width: '100%',
              pl: 2,
              pr: 2,
              py: 1,
              fontSize: '13px',
              lineHeight: '18px',
            }}
          >
            <Typography variant="h5" sx={{ lineHeight: '18px', whiteSpace: 'pre-line', mb: 1 }}>
              {t('modals.createOffers.description')}
            </Typography>

            <Show when={amazonFbmMarketplaces.length > 0}>
              <Typography variant="h3Bold">{t('modals.createOffers.label-fbm')}</Typography>
              <SelectMultiCheckboxMarketplace
                items={amazonFbmItems}
                marketplaces={amazonFbmMarketplaces}
                placeholder={t('modals.createOffers.placeholder')}
                onChange={(value?: string[] | null) => {
                  setAmazonFbmItems(value ?? []);
                }}
              />
            </Show>
            <Show when={amazonFbaMarketplaces.length > 0}>
              <Typography variant="h3Bold">{t('modals.createOffers.label-fba')}</Typography>
              <SelectMultiCheckboxMarketplace
                items={amazonFbaItems}
                marketplaces={amazonFbaMarketplaces}
                placeholder={t('modals.createOffers.placeholder')}
                onChange={(value?: string[] | null) => {
                  setAmazonFbaItems(value ?? []);
                }}
              />
              <FormControlLabel
                label={t('modals.createOffers.label-common-sku')}
                sx={{
                  ml: 2,
                }}
                control={
                  <Switch
                    value={commonSku}
                    checked={commonSku}
                    onChange={(event, checked) => setCommonSku(checked)}
                  />
                }
              />
              <Typography
                variant="h5"
                sx={{ lineHeight: '18px', ml: 9, color: nethansaPalette.main.gray }}
              >
                {t('modals.createOffers.description-common-sku')}
              </Typography>
            </Show>
            <Show when={showKaufland}>
              <Typography variant="h3Bold">{t('modals.createOffers.label-kfl')}</Typography>
              <Stack direction="row">
                <Checkbox
                  label=""
                  sx={{ ml: 1 }}
                  value={selectedKaufland}
                  onChange={(event, checked) => setSelectedKaufland(checked)}
                />
                <DatagridCellFlag value={'DE'} label="kaufland.de" />
              </Stack>
            </Show>
          </Stack>
        </Show>
        <Show when={isLoadingAmazon || isLoadingKaufland}>
          <SpinnerIcon sx={{ width: '150px', height: 'auto', margin: 'auto' }} />
        </Show>
      </Stack>
    </DialogConfirmation>
  );
};
