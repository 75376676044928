import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryDeleteProductArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

const amazonBulkAction = gql`
  query deleteProduct($partnerId: String!, $force: Boolean!, $where: ProductGridDtoFilterInput) {
    deleteProduct(partnerId: $partnerId, force: $force, where: $where) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

type InputType = Omit<QueryDeleteProductArgs, 'partnerId'>;

export const useBulkDeleteProductMutation = (): UseMutationResult<Query, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryDeleteProductArgs, 'partnerId'>;
    return await graphqlClient.request<QueryDeleteProductArgs>(amazonBulkAction, {
      partnerId: currentPartnerId || '0',
      force: data.force,
      where: data.where,
    });
  });
};
