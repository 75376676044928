import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { Box, Typography } from '@mui/material';
import { Show } from '../../components';
import { SectionGlobalSettings, SectionGlobalSettingsSkeleton } from './components';
import { NoPlatformCallToAction } from './components/section-global-settings/no-platform-call-to-action';
import { SuspectedProductCostForm } from './components/suspected-product-cost-form/suspected-product-cost-form';

export const SettingsSalesGlobalPage: React.FC = () => {
  const [t] = useTranslation('settings');
  const globalSettingsQuery = useGlobalSettingsQuery();
  const isWizardConfigured = globalSettingsQuery.data?.isConfigured || false;

  return (
    <React.Fragment>
      <Show when={isWizardConfigured} fallback={<NoPlatformCallToAction />}>
        <Box sx={{ m: 3, maxWidth: '592px' }}>
          <Typography variant="h5" whiteSpace="pre-line">
            {t('global-settings.description')}
          </Typography>
          <Show when={globalSettingsQuery.isFetched} fallback={<SectionGlobalSettingsSkeleton />}>
            <SectionGlobalSettings />
            <SuspectedProductCostForm />
          </Show>
        </Box>
      </Show>
    </React.Fragment>
  );
};
