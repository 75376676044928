import React from 'react';
import { IconButton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CloseIcon } from '../icons/close.icon';

type Props = {
  onClick: () => void;
  whiteIcon?: boolean;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
};

export const CloseButton: React.FC<Props> = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      disableRipple
      sx={{
        p: 0,
        '&:hover': {
          backgroundColor: 'transparent',
          '& .MuiSvgIcon-root': {
            path: {
              transition: 'stroke 0.2s',
              stroke: (theme) => theme.palette.primary.main,
            },
          },
        },
        ...(props.sx || {}),
      }}
    >
      <CloseIcon
        sx={{
          fontSize: '17px',
          path: {
            transition: 'stroke 0.2s',
            stroke: (theme) =>
              props.whiteIcon ? theme.palette.common.white : theme.palette.grey[800],
          },
          ...(props.iconSx || {}),
        }}
      />
    </IconButton>
  );
};
