import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { hasUserRightClaims } from 'modules/authorization/helpers/claims-checker';
import { useUser } from 'modules/authorization/hooks/use-user';
import {
  DatagridFooter,
  mapSelectedProductIdsToFilter,
  useDatagridContext,
  useDatagridFiltersToGraphqlTranslator,
} from 'modules/datagrid';
import { BulkActionsProps } from 'modules/datagrid/types';
import {
  searchableColumnsProducts,
  useProductsColumnsDefinition,
  useProductsCoordinatorContext,
  useProductsTotalCountQuery,
} from 'modules/products/hooks';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { ProductGridDtoFilterInput } from '../../../../generated/graphql';
import { ProductsGridColumn, SelectedProducts } from '../../types';

type Action =
  | 'editHandlingTime'
  | 'editTags'
  | 'deleteProduct'
  | 'createOffers'
  | 'acceptSuspectedCost'
  | 'shoppingBasket';

export const TableProductsFooterBulkActions: React.FC = () => {
  const [t] = useTranslation('products');
  const apiRef = useGridApiContext();
  const { bulkActionType, filter, search, rowCount } = useDatagridContext();
  const columns = useProductsColumnsDefinition();

  const offersTotalCountQuery = useProductsTotalCountQuery('products-total-all-count', columns, {
    items: [],
  });

  const { claimsFetched, claims } = useUser();
  const hasRightClaims = hasUserRightClaims('OFFERS_OFFER_MENU_ITEM_RAW_PRICE_CALCULATION', claims);
  const hasRightClaimsToRender = claimsFetched ? hasRightClaims : false;

  const {
    editHandlingTime: [, setEditHandlingTime],
    editTags: [, setEditTags],
    createOffers: [, setCreateOffers],
    deleteProduct: [, setDeleteProduct],
    acceptSuspectedCost: [, setAcceptSuspectedCost],
    shoppingBasket: [, setShoppingBasket],
  } = useProductsCoordinatorContext();

  const handleActionSuccess = () => {
    apiRef.current.setRowSelectionModel([]);
    bulkActionType[1]('manually');
  };

  const bulkActionProps: BulkActionsProps<Action> = useMemo(
    () => ({
      bulkActions: [
        { label: t('actions.editHandlingTime'), value: 'editHandlingTime' },
        { label: t('actions.editTags'), value: 'editTags' },
        { label: t('actions.deleteProduct'), value: 'deleteProduct' },
        { label: t('actions.createOffers'), value: 'createOffers' },
        { label: t('actions.acceptSuspectedCost'), value: 'acceptSuspectedCost' },
        {
          label: t('actions.shoppingBasket'),
          value: 'shoppingBasket',
          visible: hasRightClaimsToRender,
        },
      ],
      handleBulkAction: (actionType, selectedIds) => {
        const productsIds = (selectedIds || []) as string[];

        let selectedCount = productsIds.length;
        if (bulkActionType[0] === 'all') selectedCount = rowCount[0];

        const gridFilters =
          bulkActionType[0] !== 'all'
            ? mapSelectedProductIdsToFilter<ProductGridDtoFilterInput>(productsIds)
            : useDatagridFiltersToGraphqlTranslator<ProductsGridColumn, ProductGridDtoFilterInput>(
                filter[0],
                columns,
                searchableColumnsProducts,
                undefined,
                search[0],
              );

        const selectedProducts: SelectedProducts = {
          filters: gridFilters.filter,
          selectedCount: selectedCount,
          onSuccess: handleActionSuccess,
        };

        const obj: Record<Action, () => void> = {
          createOffers(): void {
            setCreateOffers(selectedProducts);
          },
          deleteProduct(): void {
            setDeleteProduct(selectedProducts);
          },
          editTags(): void {
            setEditTags(selectedProducts);
          },
          editHandlingTime: () => {
            setEditHandlingTime(selectedProducts);
          },
          acceptSuspectedCost: () => {
            setAcceptSuspectedCost(selectedProducts);
          },
          shoppingBasket: () => {
            setShoppingBasket(selectedProducts);
          },
        };

        obj[actionType]();
      },
    }),
    [handleActionSuccess],
  );

  return (
    <DatagridFooter
      entitiesType="products"
      totalAllCount={offersTotalCountQuery.data}
      {...(bulkActionProps as BulkActionsProps)}
    />
  );
};
