import React from 'react';
import { Box, Stack } from '@mui/material';
import { CloseButton } from '../../../../components';

type Props = {
  logo: JSX.Element;
  close: () => void;
};

export const PlatformConnectorHeader: React.FC<Props> = (props) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box>{props.logo}</Box>
      <Box>
        <Stack direction="row" alignItems="center" gap="24px">
          <CloseButton onClick={props.close} />
        </Stack>
      </Box>
    </Stack>
  );
};
