import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { TabPanel } from 'components/tab-panel/tab-panel';
import { SharedOfferStateEnum } from 'generated/graphql';
import { useOfferCoordinatorContext } from 'modules/offers/hooks';
import { NAVIGATION_APP_HEIGHT, nethansaPalette } from 'theme';
import { Alert, Dialog, DialogContent, Tab, Tabs } from '@mui/material';
import { CardPriceComponentsBreakdown, OfferDetailsHeader } from './components';
import { PriceHistory } from './components/price-history';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const OfferDetailsModal: React.FC = () => {
  const [t] = useTranslation('offers');
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    offerDetails: [offerDetailsHeaderParams, setOfferDetails],
  } = useOfferCoordinatorContext();

  const handleClose = () => {
    setOfferDetails(undefined);
  };

  const tabStyles = {
    whiteSpace: 'nowrap',
    alignItems: 'flex-start',
    p: '0 0 0 12px !important',
    color: nethansaPalette.main.dark,
    minHeight: '40px',
    borderLeft: '5px solid transparent !important',
    '&.Mui-selected': {
      borderLeft: `5px solid ${nethansaPalette.main.active} !important`,
      bgcolor: nethansaPalette.background.title,
    },
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      fullScreen
      sx={{
        height: `calc(100% - ${NAVIGATION_APP_HEIGHT})`,
        width: 'min(75vw, 1200px)',
        left: 'auto',
        top: 'auto',
        right: 0,
        bottom: 0,
        '& .MuiDialog-paperScrollPaper': {
          overflowY: 'clip',
        },
      }}
    >
      <OfferDetailsHeader data={offerDetailsHeaderParams} onClose={handleClose} />
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          bgcolor: nethansaPalette.background.title,
        }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="vertical offer details menu"
          sx={{
            minWidth: '160px',
            bgcolor: nethansaPalette.main.light,
            position: 'sticky',
            top: 0,
            left: 0,
            pt: 2,
            zIndex: 1,
          }}
        >
          <Tab
            label={t('offer-details.tabs.price-breakdown')}
            disableRipple
            {...a11yProps(0)}
            sx={tabStyles}
          />
          <Tab
            label={t('offer-details.tabs.price-history')}
            disableRipple
            {...a11yProps(1)}
            sx={{
              ...tabStyles,
              position: 'relative',
              '&::after': {
                content: '"BETA"',
                position: 'absolute',
                right: '4px',
                fontSize: '12px',
                color: nethansaPalette.main.active,
                letterSpacing: '0.22px',
                fontWeight: 500,
              },
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Show
            when={
              offerDetailsHeaderParams?.status == SharedOfferStateEnum.NotFound ||
              offerDetailsHeaderParams?.status == SharedOfferStateEnum.MultipleListingsFound
            }
          >
            <Alert severity="warning">
              {t('offer-details.informations.no-price-breakdown', {
                status: t(
                  `offer-status.main.${offerDetailsHeaderParams?.status}` as 'offer-status.main.NOT_FOUND',
                ),
              })}
            </Alert>
          </Show>

          <CardPriceComponentsBreakdown />
        </TabPanel>
        <TabPanel value={value} index={1} styles={{ textAlign: 'center' }}>
          <PriceHistory />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};
