import { AlertClasses, AlertProps } from '@mui/material/Alert';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette, paletteOptions } from '../pallete';

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    loading: true;
  }
}

export const alertStyles: {
  defaultProps?: Partial<AlertProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof AlertClasses>> | undefined;
} = {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: '15.73px',
      letterSpacing: '0.5px',
      borderRadius: '6px',
      alignItems: 'center',

      '&.MuiAlert-standardError': {
        backgroundColor: (paletteOptions.error as SimplePaletteColorOptions)?.light,
        '.MuiAlert-icon': {
          color: (paletteOptions.error as SimplePaletteColorOptions)?.main,
        },
      },
      '&.MuiAlert-standardSuccess': {
        backgroundColor: (paletteOptions.success as SimplePaletteColorOptions)?.light,
        '.MuiAlert-icon': {
          color: (paletteOptions.success as SimplePaletteColorOptions)?.main,
        },
      },
      '&.MuiAlert-standardInfo': {
        backgroundColor: (paletteOptions.info as SimplePaletteColorOptions)?.light,
        '.MuiAlert-icon': {
          color: (paletteOptions.info as SimplePaletteColorOptions)?.main,
        },
      },
      '&.MuiAlert-standardWarning': {
        backgroundColor: (paletteOptions.warning as SimplePaletteColorOptions)?.light,
        '.MuiAlert-icon': {
          color: (paletteOptions.primary as SimplePaletteColorOptions)?.main,
        },
      },

      '.MuiAlert-icon': {
        fontSize: '28px',
      },

      '.MuiAlert-message': {
        display: 'inline-block',
        color: nethansaPalette.main.dark,
      },
    },
  },
};
