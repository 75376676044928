import { DimensionType, UnitOfSizeType, UnitOfWeightType } from '../../modules/shared/types';
import { inchesToUnit, parseLengthUnit, parseWeightUnit, poundsToUnit } from './converters';

export const dimensionWeightParser = (data?: DimensionType): string => {
  if (!data || data.weightUnit === UnitOfWeightType.Unknown) return '-';

  return `${poundsToUnit(data.weight, data.weightUnit)} ${parseWeightUnit(data.weightUnit)}`;
};

export const dimensionSizeParser = (data?: DimensionType): string => {
  if (!data || data.sizeUnit === UnitOfSizeType.Unknown) return '-';

  const { sizeUnit, length, width, height } = data;

  return `${inchesToUnit(width, sizeUnit)} x ${inchesToUnit(height, sizeUnit)} x ${inchesToUnit(
    length,
    sizeUnit,
  )} ${parseLengthUnit(data.sizeUnit)}`;
};
