import React from 'react';
import { marketplaceList } from 'common/utils';
import { Box, Divider, Stack } from '@mui/material';
import {
  GridFilterInputMultipleSingleSelectProps,
  GridSingleSelectColDef,
} from '@mui/x-data-grid-pro';
import { FlagIcon } from '../../../../../../components';
import { SelectMultiCheckboxField } from '../../../../../../components/input-fields/select-multi-checkbox-field';

type Props = GridFilterInputMultipleSingleSelectProps;

export const CountryCheckboxListOperator: React.FC<Props> = (props) => {
  const { item, applyValue } = props;

  const resolvedColumn = props.item.field ? props.apiRef.current.getColumn(props.item.field) : null;

  const resolvedValueOptions = React.useMemo<string[]>(() => {
    const singleSelectResolvedColumn = resolvedColumn as GridSingleSelectColDef;

    if (!singleSelectResolvedColumn?.valueOptions) {
      return [];
    }
    return singleSelectResolvedColumn.valueOptions as string[];
  }, [resolvedColumn]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef: React.Ref<any> = React.useRef(null);

  const handleFilterChange = (value?: string[] | null) => {
    applyValue({ ...item, value: value || [] });
  };

  const options = resolvedValueOptions.map((x) => ({
    value: x,
    children: (
      <FlagIcon country={x} label={marketplaceList.filter((el) => el.value === x)[0].label} />
    ),
  }));

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 40,
        width: '100%',
      }}
    >
      <SelectMultiCheckboxField
        label="Marketplace"
        value={item.value}
        inputRef={inputRef}
        options={options}
        onChange={handleFilterChange}
        sx={{
          width: '250px',
          '&.MuiInputLabel-root': {},
          margin: 0,
        }}
        selectSx={{
          minHeight: 40,
        }}
        labelSx={{
          top: '-16px',
        }}
        renderValue={(value) => (
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            divider={<Divider orientation="vertical" color="black" sx={{ height: '25px' }} />}
            sx={{
              '>:not(style)+:not(style)': { marginInline: 0 },
              '>:not(style)+hr': { marginInline: '8px' },
            }}
          >
            {value.map((x) => (
              <FlagIcon key={x} country={x} showCountryCode />
            ))}
          </Stack>
        )}
      />
    </Box>
  );
};
