import { TabsClasses, TabsProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const tabsStyles: {
  defaultProps?: Partial<TabsProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TabsClasses>> | undefined;
} = {
  styleOverrides: {
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& .MuiTabs-indicatorSpan': {
        maxWidth: 30,
        width: '100%',
        backgroundColor: '#282828',
      },
    },
  },
};
