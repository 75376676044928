import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryUpdateOffersTagsArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

const amazonBulkAction = gql`
  query updateOffersTags(
    $partnerId: Int!
    $tagsToAdd: [String!]!
    $tagsToRemove: [String!]!
    $where: OfferGridDtoFilterInput
  ) {
    updateOffersTags(
      partnerId: $partnerId
      where: $where
      tagsToAdd: $tagsToAdd
      tagsToRemove: $tagsToRemove
    )
  }
`;

type InputType = Omit<QueryUpdateOffersTagsArgs, 'partnerId'>;

export const useBulkTagsMutation = (): UseMutationResult<Query, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryUpdateOffersTagsArgs, 'partnerId'>;
    return await graphqlClient.request<QueryUpdateOffersTagsArgs>(amazonBulkAction, {
      partnerId: Number(currentPartnerId),
      where: data.where,
      tagsToAdd: data.tagsToAdd,
      tagsToRemove: data.tagsToRemove,
    });
  });
};
