import React, { useContext } from 'react';
import { PlatformsConnectorContext } from '../contexts/platfroms-modal.provider';
import { useAmazonPlatformCheckQuery } from '../hooks/use-amazon-platform-check.query';
import { AccountExternalAuth } from './amazon/account-external-auth';
import { MarketsSettings } from './amazon/markets-settings';

export const AmazonConnector: React.FC = () => {
  const context = useContext(PlatformsConnectorContext);

  useAmazonPlatformCheckQuery({
    onSuccess: (data) => {
      // If account is already connected then go to step 2
      if (data.hasConnectedAmazonPlatform) {
        context.setStep(1);
      }
    },
  });

  const steps: { [key: string]: JSX.Element } = {
    0: <AccountExternalAuth />,
    1: <MarketsSettings />,
  };

  return <>{steps[context.step] || null}</>;
};
