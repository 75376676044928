import React, { useCallback, useRef, useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

type SnackbarType = {
  key: number;
  message: string;
  variant: 'success' | 'error' | 'warning' | 'info';
};

type SnackbarContextType = {
  push: (snackbar: Omit<SnackbarType, 'key'>) => void;
};

export const SnackbarContext = React.createContext<SnackbarContextType>({
  push: () => null,
});

type Props = React.PropsWithChildren<unknown>;

export const SnackbarContextProvider: React.FC<Props> = (props) => {
  const [snackbars, setSnackbars] = useState<SnackbarType[]>([]);
  const autoHideDuration = useRef(6000);

  const push = useCallback(
    (snackbar: Omit<SnackbarType, 'key'>) => {
      const key = Math.floor(Math.ceil(Math.random() * 999999));
      const newSnackbar = { ...snackbar, key };
      setSnackbars([...snackbars, newSnackbar]);
    },
    [snackbars],
  );

  const handleClose = useCallback(
    (key: number) => () => {
      setSnackbars(snackbars.filter((x) => x.key !== key));
    },
    [snackbars],
  );

  return (
    <SnackbarContext.Provider
      value={{
        push: push,
      }}
    >
      {props.children}
      {snackbars.map((x) => (
        <Snackbar
          open
          message={x.message}
          key={x.key}
          autoHideDuration={autoHideDuration.current}
          onClose={handleClose(x.key)}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <MuiAlert onClose={handleClose(x.key)} severity={x.variant} sx={{ width: '100%' }}>
            {x.message}
          </MuiAlert>
        </Snackbar>
      ))}
    </SnackbarContext.Provider>
  );
};
