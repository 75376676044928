import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ClaimDefenderRoutLayout } from '../../modules/authorization';
import { SalesRevenueReportsPage } from '../../modules/reports';
import { ReportsLayout } from '../../modules/reports/reports.layout';
import { routes } from '../routes';

export const ReportsRouter: React.FC = () => {
  const reportsRoutes = routes.Reports;

  return (
    <Routes>
      <Route element={<ClaimDefenderRoutLayout allowed="REPORTS" />}>
        <Route element={<ReportsLayout />}>
          <Route element={<ClaimDefenderRoutLayout allowed="REPORTS_SALES_REVENUE" />}>
            <Route
              path={reportsRoutes.children.SalesRevenue.name}
              element={<SalesRevenueReportsPage />}
            />
          </Route>
          <Route index element={<Navigate to={reportsRoutes.children.SalesRevenue.name} />} />
        </Route>
      </Route>
    </Routes>
  );
};
