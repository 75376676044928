import { useEffect } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import {
  InputMaybe,
  ProductGridDtoFilterInput,
  ProductGridDtoSortInput,
  ProductsQuery,
  ProductsQueryVariables,
  SortEnumType,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';
import { useDatagridContext, useDatagridContextGraphqlTranslator } from 'modules/datagrid';
import { ProductGridRowKey, ProductsGridColumn } from '../types';

export const getProducts = gql`
  query Products(
    $partnerId: String!
    $first: Int!
    $after: String
    $sort: [ProductGridDtoSortInput!]
    $filter: ProductGridDtoFilterInput
  ) {
    products(partnerId: $partnerId, first: $first, after: $after, where: $filter, order: $sort) {
      totalCount
      nodes {
        ...productNode
      }
      pageInfo {
        ...paginationInfo
      }
      edges {
        cursor
      }
    }
  }

  fragment paginationInfo on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }

  fragment productNode on ProductGridDto {
    category
    createdAt
    ean
    externalId
    handlingTime
    id
    last30DaysSoldQuantity
    name
    predcition30DaysProfit
    prediction30DaysQuantity
    prediction30DaysTurnover
    productCost
    suspiciousProductCost
    suspiciousProductCostSource
    suspiciousProductCostUpdatedAt
    baseCurrencyCode
    stock
    tags
    taxRate
    updatedAt
  }
`;

export const searchableColumnsProducts: ProductGridRowKey[] = ['name', 'ean', 'externalId'];

export const useProductsQuery = (columns: ProductsGridColumn[]): UseQueryResult<ProductsQuery> => {
  const { currentPartnerId } = useAuth();
  const gridContext = useDatagridContext();

  const gridFilterSort = useDatagridContextGraphqlTranslator<
    ProductsGridColumn,
    ProductGridRowKey,
    ProductGridDtoFilterInput,
    ProductGridDtoSortInput
  >(columns, searchableColumnsProducts);

  const result = useQuery<ProductsQuery>(
    [
      'products',
      currentPartnerId,
      gridFilterSort.first,
      gridFilterSort.after,
      gridFilterSort.sort,
      gridFilterSort.filter,
    ],
    async () => {
      const parsedFilter: InputMaybe<ProductGridDtoFilterInput> = {
        ...gridFilterSort.filter,
      };

      const sortParsed: InputMaybe<ProductGridDtoSortInput> = gridFilterSort.sort
        ? gridFilterSort.sort
        : {
            name: SortEnumType.Desc,
          };

      return (await graphqlClient.request<ProductsQueryVariables>(getProducts, {
        partnerId: String(currentPartnerId),
        first: gridFilterSort.first,
        after: gridFilterSort.after,
        sort: sortParsed,
        ...(Object.keys(parsedFilter || {}).length ? { filter: parsedFilter } : {}),
      })) as ProductsQuery;
    },
    {
      staleTime: 5000,
      keepPreviousData: false,
      retry: false,
    },
  );

  useEffect(() => {
    gridContext.rowCount[1](result.data?.products?.totalCount || 0);
  }, [result.data?.products?.totalCount]);

  return result;
};
