import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import { ErrorMeta } from 'modules/shared/types';
import { settingsDefaultMutationKeys } from '../../../common/query-client';

type InputType = {
  isTwoFactorEnabled: boolean;
  code: string | null;
};

type CodeError = 'wrongTwoFaCode';

export const useOPTCodeVerification = (): UseMutationResult<
  void,
  ErrorMeta<{ code: [CodeError] }>,
  InputType
> => {
  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    await envelope(httpClient.put(API.profiles().twoFA(), body));
  });
};
