import React from 'react';
import { useCommonTranslations } from 'modules/shared';
import { Stack, Typography } from '@mui/material';

export const TableNoResults: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  return (
    <Stack sx={{ width: '100%', height: '100%' }} alignItems="center" justifyContent="center">
      <Typography>{tCommon('grid.no-result')}</Typography>
    </Stack>
  );
};
