import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormButtonsGroup, TextFieldNumber, Checkbox, FormAlert, Show } from 'components';
import { useGlobalsMutation } from 'modules/settings-sales-global/hooks/use-global-settings.mutation';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global/hooks/use-global-settings.query';
import { GlobalsType } from 'modules/settings-sales-global/types/globals.type';
import { useCommonTranslations } from 'modules/shared';
import { nethansaPalette } from 'theme';
import { Paper, Stack, Typography, Box, Button } from '@mui/material';

export const SuspectedProductCostForm: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('settings');

  const { data, isLoading, refetch } = useGlobalSettingsQuery();
  const globalsMutation = useGlobalsMutation();

  const validationRangeRules = {
    suspectedProductCostProc: {
      min: 0,
      max: 100,
    },
  };

  type formObj = GlobalsType & {
    disabled: boolean;
  };
  const form = useForm<formObj>({
    defaultValues: {
      suspectedProductCostProc: data?.suspectedProductCostProc,
      disabled: data?.suspectedProductCostProc == null,
    },
  });

  const disableSuspectedCost = form.watch('disabled');

  useEffect(() => {
    if (disableSuspectedCost) {
      form.setValue('suspectedProductCostProc', null);
    }
  }, [disableSuspectedCost]);

  const submit = (data: formObj) => {
    globalsMutation.mutate(data, {
      onSuccess: () => {
        refetch();
        form.reset({ ...data });
      },
    });
  };

  return (
    <Paper sx={{ width: '100%', mt: 3, p: 3 }}>
      <form onSubmit={form.handleSubmit(submit)}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography variant="h3Bold">
              {t('global-settings.suspicious-product-cost.header')}
            </Typography>
            <Typography
              variant="h5Text"
              sx={{ whiteSpace: 'pre-line', color: nethansaPalette.main.gray, mt: 1 }}
            >
              {t('global-settings.suspicious-product-cost.description')}
            </Typography>
          </Stack>

          <Stack
            spacing={1}
            sx={{ '> div': { background: nethansaPalette.background.title, px: 2, py: 1 } }}
          >
            <Box>
              <Typography variant="h5Text">
                {t('global-settings.suspicious-product-cost.section1.label1')}
              </Typography>
              <Controller
                control={form.control}
                name="suspectedProductCostProc"
                rules={{
                  required: {
                    value: !disableSuspectedCost,
                    message: t('global-settings.suspicious-product-cost.validation.required'),
                  },
                  max: {
                    value: validationRangeRules.suspectedProductCostProc.max,
                    message: t('global-settings.suspicious-product-cost.validation.valueError', {
                      min: validationRangeRules.suspectedProductCostProc.min,
                      max: validationRangeRules.suspectedProductCostProc.max,
                    }),
                  },
                  min: {
                    value: validationRangeRules.suspectedProductCostProc.min,
                    message: t('global-settings.suspicious-product-cost.validation.valueError', {
                      min: validationRangeRules.suspectedProductCostProc.min,
                      max: validationRangeRules.suspectedProductCostProc.max,
                    }),
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextFieldNumber
                      disabled={disableSuspectedCost === true}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      onBlur={field.onBlur}
                      value={field.value !== null ? field.value : undefined}
                      unit={'%'}
                      precision={2}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      sx={{
                        '& > p': { marginLeft: 0, width: '250px', right: 'auto !important' },
                        mb: fieldState.error ? 2 : 0,
                        width: '120px',
                      }}
                    />
                  );
                }}
              />
              <Typography variant="h5Text">
                {t('global-settings.suspicious-product-cost.section1.label2')}
              </Typography>
            </Box>

            <Box>
              <Typography variant="h5Text">
                <Controller
                  control={form.control}
                  name="disabled"
                  render={({ field }) => (
                    <>
                      <Checkbox
                        value={field.value}
                        checked={!!field.value}
                        label={t('global-settings.suspicious-product-cost.section2.label')}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        sx={{
                          '& + .MuiFormControlLabel-label': {
                            ml: '0 !important',
                            p: 0,
                          },
                        }}
                      />
                    </>
                  )}
                />
              </Typography>
            </Box>
          </Stack>

          <Show when={globalsMutation.isSuccess || globalsMutation.isError}>
            <FormAlert
              show={globalsMutation.isSuccess || globalsMutation.isError}
              isSuccess={globalsMutation.isSuccess}
              isError={globalsMutation.isError}
              successMessage={t('global-settings.suspicious-product-cost.validation.success')}
              errorMessage={t('global-settings.suspicious-product-cost.validation.error-general')}
            />
          </Show>

          <FormButtonsGroup isLoading={isLoading}>
            <Button
              size="small"
              type="button"
              variant="text"
              disabled={!form.formState.isDirty}
              onClick={() => form.reset()}
            >
              {tCommon('cancel')}
            </Button>
            <Button
              size="small"
              type="submit"
              disabled={Object.keys(form.formState.errors).length > 0}
            >
              {tCommon('save')}
            </Button>
          </FormButtonsGroup>
        </Stack>
      </form>
    </Paper>
  );
};
