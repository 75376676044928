import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppGlobalParametersContext } from 'modules/shared/contexts/app-global-parameters.provider';
import { NavigationTabsLayout } from '../../components/navigation-tabs';
import { LimitBarElement } from './components';

export const MonitoringLayout: React.FC = () => {
  const [t] = useTranslation('monitoring');
  const showContext = useContext(AppGlobalParametersContext);

  return (
    <NavigationTabsLayout
      title={t('title')}
      subtitle={t('subtitle')}
      isHidden={!showContext.monitoringModuleWithBars}
      rightSideElement={<LimitBarElement />}
      isNotSticky
    ></NavigationTabsLayout>
  );
};
