import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipWrapper } from 'components';
import { IconButton, List, ListItemButton, Stack } from '@mui/material';
import { MoreIcon } from '../../../components/icons';
import { MonitoredOfferGridDtoFilterInput } from '../../../generated/graphql';
import { mapSelectedMonitoringIdsToFilter } from '../../datagrid';
import { useMonitoringCoordinatorContext } from '../hooks/use-monitoring-coordinator-context';
import { MonitoredOfferRow } from '../types';

type Props = {
  offer: MonitoredOfferRow;
};

export const ButtonMenuMonitoring: React.FC<Props> = (props) => {
  const [t] = useTranslation('monitoring');
  const {
    deleteOffers: [, setDeleteOffers],
    editTags: [, setEditTags],
  } = useMonitoringCoordinatorContext();

  const filters = mapSelectedMonitoringIdsToFilter<MonitoredOfferGridDtoFilterInput>([
    props.offer.id,
  ]).filter;

  const manageMenu = (
    <List sx={{ px: '0!important', '&>*': { fontSize: '13px' } }}>
      <ListItemButton
        data-testid="delete"
        sx={{ color: 'nethansa.main.watermelon' }}
        onClick={() => {
          setDeleteOffers({
            filters: filters,
            row: props.offer,
          });
        }}
      >
        {t('actions.delete')}
      </ListItemButton>
      <ListItemButton
        data-testid="editTags"
        onClick={() => {
          setEditTags({
            filters: filters,
            row: props.offer,
          });
        }}
      >
        {t('actions.edit-tags')}
      </ListItemButton>
    </List>
  );

  return (
    <Stack alignItems="center">
      <TooltipWrapper
        content={manageMenu}
        placement="right-start"
        componentsProps={{
          tooltip: {
            sx: {
              p: 0,
            },
          },
        }}
      >
        <IconButton size="small">
          <MoreIcon sx={{ fontSize: '18px' }} />
        </IconButton>
      </TooltipWrapper>
    </Stack>
  );
};
