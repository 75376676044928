import { LinkBehavior } from 'components/link-behavior';
import { LinkClasses, LinkProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const linkStyles: {
  defaultProps?: Partial<LinkProps<'a', void>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof LinkClasses>> | undefined;
} = {
  defaultProps: {
    component: LinkBehavior,
  } as LinkProps,
  styleOverrides: {
    root: {
      color: nethansaPalette.main.dark,
      textDecoration: 'none',
      transition: 'color .2s',
      '&:hover': {
        color: nethansaPalette.main.dark,
      },
    },
  },
};
