import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ResponseAPIType } from '../../shared/types';

type Tag = {
  name: string;
  numberOfOccurence: number;
};

export const useProductsTags = (): UseQueryResult<Tag[]> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<Tag[]>(
    ['products', 'tags', currentPartnerId],
    async () => {
      const response = await httpClient.get<ResponseAPIType<Tag[]>>(
        API.products(currentPartnerId).tags(),
      );

      return response.data?.data.filter((s) => s.name != null) || [];
    },
    {
      staleTime: 30000,
      keepPreviousData: false,
      enabled: authorized,
    },
  );
};
