import React from 'react';
import { AllViewsIcon } from 'components/icons';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';

type Props = React.PropsWithChildren<{
  anchorEl: null | HTMLElement;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  buttonLabel?: string;
  onClose?: () => void;
}>;

export const MenuListPopup: React.FC<Props> = (props) => (
  <>
    <Button
      size="small"
      color="flat"
      onClick={props.onClick}
      startIcon={<AllViewsIcon sx={{ height: 24, width: 'auto' }} />}
    >
      {props.buttonLabel}
    </Button>

    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id="menu-list-views"
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      PaperProps={{
        style: {
          maxHeight: '480px',
          minWidth: '280px',
          boxShadow: '0px 0px 10px 0px #4040404D',
        },
      }}
    >
      {props.children}
    </Menu>
  </>
);
