import React from 'react';
import { useTranslation } from 'react-i18next';
import { longDateWithMonthDescription } from 'common/format/date';
import { useCommonTranslations } from 'modules/shared';
import { Stack, Typography } from '@mui/material';

type Props = {
  value: number | null;
  currency: string;
  date: Date | string | null;
  source: string | null;
};

export const DataGridSuspiciousCostCell: React.FC<Props> = (props) => {
  if (props.value === null) return <></>;

  const { i18n } = useTranslation('products');
  const { tCommon } = useCommonTranslations();

  return (
    <Stack direction="column" alignItems="flex-end">
      <Stack direction="row">
        <Typography variant="h5Text" sx={{ color: '#B0B1BB', mr: '4px' }}>
          {tCommon(`currencies.${props.currency || 'EUR'}` as 'currencies.EUR')}
        </Typography>
        <Typography variant="h5Text">{props.value.toFixed(2)}</Typography>
      </Stack>
      <Typography variant="h5Text">
        {props.date ? longDateWithMonthDescription(props.date, i18n.language) : ''}
      </Typography>
      <Typography variant="h5Text">{props.source}</Typography>
    </Stack>
  );
};
