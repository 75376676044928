import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Show } from 'components/show';
import { NAVIGATION_APP_HEIGHT, MODULE_NAVIGATION_BAR_HEIGHT, nethansaPalette } from 'theme';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  children?: ReactElement | ReactElement[] | JSX.Element | JSX.Element[] | null;
  title?: string;
  subtitle?: string;
  isHidden?: boolean;
  rightSideElement?: ReactElement | ReactElement[] | JSX.Element | JSX.Element[] | null;
  isNotSticky?: boolean;
};

export const NavigationTabsLayout: React.FC<Props> = (props) => {
  const stackRef = useRef<HTMLDivElement>(null);
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const borderLine: string = ['1px', 'solid', nethansaPalette.line.light].join(' ');

  const rootStyles: SxProps<Theme> = useMemo(
    () => ({
      position: props.isNotSticky ? 'relative' : 'sticky',
      zIndex: 2,
      top: props.isNotSticky ? 'auto' : NAVIGATION_APP_HEIGHT,
      display: props.isHidden ? 'none' : 'flex',
      boxShadow: `0 3px 5px 0 ${nethansaPalette.line.dark}`,
    }),
    [props.isHidden],
  );

  const containerStyles: SxProps<Theme> = useMemo(
    () => ({
      height: MODULE_NAVIGATION_BAR_HEIGHT,
      backgroundColor: 'common.white',
      width: '100%',
      borderBottom: borderLine,
      '& > *': {
        transition: 'width .3s',
        '&:hover': {
          color: 'nethansa.main.gray',
        },
        '&:before': {
          transition: 'all .3s',
          position: 'absolute',
          content: '""',
          width: '0px',
          height: '2px',
          bottom: 0,
          backgroundColor: 'text.primary',
        },
      },
    }),
    [],
  );

  const indicatorStyles: SxProps<Theme> = useMemo(
    () => ({
      content: '""',
      position: 'absolute',
      height: '3px',
      backgroundColor: 'common.black',
      left: `${indicatorPosition}px`,
      width: `${indicatorWidth}px`,
      bottom: 0,
      transition: 'left 0.3s, width 0.3s',
    }),
    [indicatorPosition, indicatorWidth],
  );

  const onHover = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!event.currentTarget) return;
      setIndicatorPosition(event.currentTarget.offsetLeft || 0);
      setIndicatorWidth(event.currentTarget.offsetWidth || 0);
    },
    [setIndicatorPosition, setIndicatorWidth],
  );

  const onExit = useCallback(() => {
    if (!stackRef.current) return;
    const activeItem = stackRef.current.querySelector<HTMLAnchorElement>(
      '.NavigationTabsItemActive',
    );
    if (!activeItem) return;
    setIndicatorPosition(activeItem.offsetLeft || 0);
    setIndicatorWidth(activeItem.offsetWidth || 0);
  }, [setIndicatorPosition, setIndicatorWidth]);

  const onChildReady = useCallback(
    (element: HTMLAnchorElement) => {
      if (!element) return;

      setTimeout(() => {
        setIndicatorPosition(element.offsetLeft || 0);
        setIndicatorWidth(element.offsetWidth || 0);
      }, 100);
    },
    [setIndicatorPosition, setIndicatorWidth],
  );

  const tabs = useMemo(() => {
    if (!props.children) return null;

    return Array.isArray(props.children)
      ? props.children
          ?.filter((x) => !!x && !x.props.hidden)
          .map((x) => ({
            ...x,
            props: {
              ...x.props,
              handleNavigationTabHover: onHover,
              onReady: onChildReady,
              onExit: onExit,
            },
          }))
      : [
          {
            ...props.children,
            props: {
              ...(props.children || { props: {} })?.props,
              handleNavigationTabHover: onHover,
              onReady: onChildReady,
              onExit: onExit,
            },
          },
        ];
  }, [props.children, onHover, onChildReady, onExit]);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'nethasna.main.gray',
      }}
    >
      <Stack direction="row" sx={rootStyles}>
        {props.title && (
          <Stack
            sx={{
              minWidth: '205px',
              pl: 3,
              backgroundColor: 'common.white',
              borderBottom: borderLine,
            }}
            justifyContent="center"
          >
            <Typography variant="h1">{props.title}</Typography>
            <Show when={!!props.subtitle}>
              <Typography variant="h4" color={nethansaPalette.main.gray}>
                {props.subtitle}
              </Typography>
            </Show>
          </Stack>
        )}

        <Stack ref={stackRef} direction="row" justifyContent="flex-start" sx={containerStyles}>
          {tabs}
          <Box sx={indicatorStyles} />
        </Stack>

        {props.rightSideElement}
      </Stack>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
