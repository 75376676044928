import { useCommonTranslations } from 'modules/shared';
import {
  GridFilterItem,
  GridFilterOperator,
  getGridSingleSelectOperators,
} from '@mui/x-data-grid-pro';

export const useDatagridMultiListOperators = (): GridFilterOperator[] => {
  const { tCommon } = useCommonTranslations();

  const emptyList: GridFilterOperator = {
    label: tCommon('datagrid.operators.list.empty-list'),
    value: 'isNull',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) return null;

      return (params: { value?: string }): boolean => {
        return !params.value || params.value.length === 0;
      };
    },
  };

  const notEmptyList: GridFilterOperator = {
    label: tCommon('datagrid.operators.list.not-empty-list'),
    value: 'isNotNull',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) return null;

      return (params: { value?: string }): boolean => {
        return !params.value || params.value.length === 0;
      };
    },
  };

  return [getGridSingleSelectOperators()[2], emptyList, notEmptyList];
};
