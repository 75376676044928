import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';

type TwoFactoryStatusResponse = {
  authenticatorUri: string;
};

export const useTwoFactoryQRCodeQuery = (): UseQueryResult<TwoFactoryStatusResponse> => {
  const { authorized } = useAuth();

  return useQuery(
    ['settings', 'two-factory-qr-code'],
    async () => {
      const response = await httpClient.get<ResponseAPIType<TwoFactoryStatusResponse>>(
        API.profiles().twoFAQRCode(),
      );

      return response.data.data;
    },

    {
      staleTime: 60000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
