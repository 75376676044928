export const operators = {
  is: 'eq',
  not: 'neq',
  contains: 'contains',
  equals: 'eq',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  isEmpty: 'eq',
  isNotEmpty: 'neq',
  isAnyOf: 'in',
  '=': 'eq',
  '!=': 'neq',
  '>': 'gt',
  '>=': 'gte',
  '<': 'lt',
  '<=': 'lte',
  after: 'gt',
  onOrAfter: 'gte',
  before: 'lt',
  onOrBefore: 'lte',
  last: 'gte',
  enum: 'enum',
  between: 'between',
  sharedStatuses: 'sharedStatus',
};

export enum DatagridListOperatorEnum {
  LIST_CONTAINS = 'listContains',
  LIST_NOT_CONTAINS = 'listNotContains',
  EMPTY_LIST = 'emptyList',
  NOT_EMPTY_LIST = 'notEmptyList',
}

export const listOfListOperators: DatagridListOperatorEnum[] = [
  DatagridListOperatorEnum.LIST_CONTAINS,
  DatagridListOperatorEnum.LIST_NOT_CONTAINS,
  DatagridListOperatorEnum.EMPTY_LIST,
  DatagridListOperatorEnum.NOT_EMPTY_LIST,
];
