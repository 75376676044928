import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CongratulationModal } from './congratulation.modal';
import {
  OnboardingWizardContext,
  OnboardingWizardProvider,
  WizardFormStepType,
  WizardStepType,
} from './contexts/onboarding-wizard.provider';
import { FormWithStepsModal } from './form-with-steps.modal';
import { useWizardStatusQuery } from './hooks/default/use-wizard-status.query';
import { WelcomeModal } from './welcome.modal';

const OnboardingWizardCoordinator: React.FC = () => {
  const status = useWizardStatusQuery();
  const context = useContext(OnboardingWizardContext);
  const [initialized, setInitialized] = useState(false);

  const steps = useMemo(
    () => ({
      [WizardStepType.welcome]: <WelcomeModal />,
      [WizardStepType.form]: <FormWithStepsModal />,
      [WizardStepType.congratulation]: <CongratulationModal />,
    }),
    [],
  );

  useEffect(() => {
    if (!status.data || status.isLoading || initialized) return;

    const stepsConditions = {
      [WizardFormStepType.globals]: () => {
        context.goToWelcomeStep();
      },
      [WizardFormStepType.platforms]: () => {
        context.goToFormStep(WizardFormStepType.platforms);
      },
      [WizardFormStepType['vat-and-shipping']]: () => {
        context.goToFormStep(WizardFormStepType['vat-and-shipping']);
      },
      [WizardFormStepType.products]: () => {
        context.goToFormStep(WizardFormStepType.products);
      },
      [WizardFormStepType.summary]: () => {
        context.goToFormStep(WizardFormStepType.summary);
      },
    };
    stepsConditions[status.data]();
    setInitialized(true);
  }, [status.data, status.isLoading, initialized]);

  return <>{steps[context.step]}</>;
};

export const OnboardingWizard: React.FC = () => {
  return (
    <OnboardingWizardProvider>
      <OnboardingWizardCoordinator />
    </OnboardingWizardProvider>
  );
};
