import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';
import { useOfferCoordinatorContext } from './use-offer-coordinator-context';

type InputType = {
  targetAsin: string;
  sendToAmazon: boolean;
};

// `/v1/partners/${currentPartnerId}/offers/${encodeURIComponent(
//   editAsinData?.offerId || '',
// )}/asin`,

export const useOfferEditAsinMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  const {
    editAsin: [editAsinData],
  } = useOfferCoordinatorContext();

  return useMutation(async (body) => {
    const data: InputType = {
      targetAsin: body.targetAsin,
      sendToAmazon: body.sendToAmazon,
    };
    return await envelope(
      httpClient.put(
        API.offers(currentPartnerId).offerAsin(encodeURIComponent(editAsinData?.offerId || '')),
        data,
      ),
    );
  });
};
