import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { parseFloatValue } from '../../common/utils';
import { TextFieldWithTooltip } from './text-field-with-tooltip';

type Props = Omit<
  TextFieldProps,
  'type' | 'inputProps' | 'InputProps' | 'onChange' | 'onBlur' | 'value'
> & {
  unit?: string;
  precision?: number;
  omitZeroDecimal?: boolean;
  clearable?: boolean;
  onChange?: (value: number | null) => void;
  onBlur?: (value: number | null) => void;
  value?: string | number;
  tooltipContent?: string;
  tooltipError?: string;
  step?: number;
};

const parseValue = (value: number | string, props: Props) => {
  const { precision, omitZeroDecimal } = props;
  let val = parseFloatValue(value || '', precision);
  if (omitZeroDecimal === true) val = val.replace('.00', '');
  return val;
};

export const TextFieldNumber: React.FC<Props> = (props) => {
  const { unit, precision, clearable, value, step, omitZeroDecimal, ...inputProps } = props;

  const [inputValue, setInputValue] = useState<string>(
    (typeof value === 'number' ? String(value) : '') as string,
  );

  const [initialized, setInitialized] = useState(false);

  const [focused, setFocused] = useState(false);

  const onChange = useCallback((value: string) => {
    if (value === '') {
      props.onChange && props.onChange(null);
      return;
    }
    const parsedFloat = parseFloat(value);
    if (!isNaN(parsedFloat) && parsedFloat !== undefined && props.onChange) {
      props.onChange(parsedFloat);
    }
  }, []);

  const onBlur = useCallback((value?: string) => {
    setFocused(false);
    if (value === null || value === undefined) {
      props.onBlur && props.onBlur(null);
      return;
    }
    const parsedFloat = parseFloat(value);
    if (!isNaN(parsedFloat) && parsedFloat !== undefined && props.onBlur) {
      props.onBlur(parsedFloat);
    }
  }, []);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInputValue(event.target?.value || '');
    },
    [setInputValue],
  );

  const handleBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const val = parseValue(event.target?.value, props);
      setInputValue(val);
      if (!onBlur) return;
      onBlur(val);
    },
    [setInputValue],
  );

  const handleClear = useCallback(() => {
    setInputValue('');
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const valNumber = !value
        ? 0
        : typeof value === 'number'
        ? value
        : parseFloat((value as string) || '');
      if (event.code === 'ArrowUp') {
        const val = parseValue(valNumber + (step || 1), props);
        setInputValue(val);
      }
      if (event.code === 'ArrowDown') {
        const val = parseValue(valNumber - (step || 1), props);
        setInputValue(val);
      }
    },
    [value, precision, step],
  );

  useEffect(() => {
    if (!onChange) return;
    onChange(inputValue);
    if (inputValue) setInitialized(true);
  }, [inputValue]);

  useEffect(() => {
    if (initialized) return;
    if (value || value === 0) {
      const val = parseFloatValue(value || 0, precision);
      setInputValue(val);
      setInitialized(true);
    }
  }, [value, initialized]);

  useEffect(() => {
    if (focused) return;
    const val = value === null ? '' : parseFloatValue(value || 0, precision);
    if (val === inputValue) return;
    if (initialized) {
      setInputValue(val);
    }
  }, [value]);

  return (
    <TextFieldWithTooltip
      {...inputProps}
      inputProps={{
        inputMode: 'numeric',
      }}
      InputProps={{
        startAdornment: (unit || unit === '') && (
          <InputAdornment position="start">{unit}</InputAdornment>
        ),
        endAdornment: clearable && inputValue && (
          <InputAdornment position="end" onClick={handleClear}>
            <CloseIcon />
          </InputAdornment>
        ),
      }}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
    />
  );
};
