import { useMutation, UseMutationResult } from 'react-query';
import { httpClient } from 'common/api';
import { settingsDefaultMutationKeys } from '../../../common/query-client';

type InputType = {
  platformCode: string;
};

//TODO: check if api endpoint is still valid
export const usePlatformDisconnectMutation = (): UseMutationResult<void, Error, InputType> => {
  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    await httpClient.delete(`/v1/wizard/steps/platforms/${body.platformCode}`);
    return;
  });
};
