import React from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { DialogBaseWrapper, FormButtonsGroup, Show } from '../../../../components';
import { useCommonTranslations } from '../../../shared';
import { useSnackbar } from '../../../snackbar';
import { useDatagridViewContext, useDatagridViewsManager } from '../../hooks';
import { useViewDeleteMutation, useViewsQuery } from '../../hooks/api';

export const ModalDeleteView: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const { deleteViewModal, currentView } = useDatagridViewContext();
  const { updateViewsOrder, changeView, visibleViews } = useDatagridViewsManager();
  const { pushSnackbar } = useSnackbar();
  const { tableId } = useDatagridViewContext();
  const { refetch } = useViewsQuery(tableId);

  const { mutate, isLoading } = useViewDeleteMutation();

  const deletingCurrentView = currentView?.id === deleteViewModal.view?.id;

  const handleDelete = () => {
    mutate(deleteViewModal.view?.id || '', {
      onSuccess: async () => {
        pushSnackbar({
          variant: 'success',
          message: tCommon('views.modal.delete-view.snackbar.delete-success'),
        });
        await refetch();
        updateViewsOrder();
        if (deletingCurrentView) {
          changeView({ id: visibleViews[0]?.id || '' });
        }
        deleteViewModal.change(undefined);
      },
      onError: () => {
        pushSnackbar({
          variant: 'error',
          message: tCommon('views.modal.delete-view.snackbar.delete-error'),
        });
      },
    });
  };

  return (
    <DialogBaseWrapper
      dangerHeader
      header={tCommon('views.modal.delete-view.title')}
      onClose={() => deleteViewModal.change(undefined)}
      open={Boolean(deleteViewModal.view)}
    >
      <Stack sx={{ px: 4, pb: 2 }} spacing={0.5}>
        <Typography variant="h5">{tCommon('views.modal.delete-view.confirm-text')}</Typography>
        <Typography variant="h5Bold">{deleteViewModal.view?.name}</Typography>
      </Stack>
      <Show when={Boolean(deleteViewModal.view?.isShared)}>
        <Typography sx={{ px: 4, pb: 3 }} variant="h5Bold" color="error.main">
          {tCommon('views.modal.delete-view.confirm-text-public')}
        </Typography>
      </Show>
      <Divider />
      <FormButtonsGroup sx={{ py: 2, px: 4 }} isLoading={isLoading}>
        <Button
          type="button"
          size="small"
          variant="text"
          disabled={isLoading}
          onClick={() => deleteViewModal.change(undefined)}
        >
          {tCommon('cancel')}
        </Button>
        <Button type="button" size="small" onClick={handleDelete} disabled={isLoading}>
          {tCommon('confirm')}
        </Button>
      </FormButtonsGroup>
    </DialogBaseWrapper>
  );
};
