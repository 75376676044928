import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { usePriceComponentsBreakdownQuery } from 'modules/offers/hooks';
import { Alert, LinearProgress, Stack } from '@mui/material';
import { ParamsListTable } from './params-list-table';
import { currency, percent } from './utils/float-with-symbol';

export const CardPriceComponentsBreakdown: React.FC = () => {
  const [t] = useTranslation('offers');
  const { isLoading, isError, data } = usePriceComponentsBreakdownQuery();

  return (
    <>
      <Show when={isLoading}>
        <LinearProgress color="primary" />
      </Show>

      <Show when={isError}>
        <Alert severity="error">{t('offer-details.error.general')}</Alert>
      </Show>

      <Show when={data !== undefined}>
        <Stack sx={{ p: 4, gap: 4 }}>
          <ParamsListTable
            rows={[
              [
                t('offer-details.price-components.total-costs'),
                currency(data?.costsTotal.value, data?.costsTotal.currencyCode),
              ],
              [
                t('column.netProductCost.label'),
                currency(data?.productCost.value, data?.productCost.currencyCode).replace(
                  /^0.00/,
                  '-',
                ),
                null,
                currency(
                  data?.productCostInMarketplaceCurrency.value,
                  data?.productCostInMarketplaceCurrency.currencyCode,
                ).replace(/^0.00/, '-'),
              ],
              [
                t('offer-details.price-components.subheader-costs-other'),
                currency(data?.otherCostsTotal.value, data?.otherCostsTotal.currencyCode),
              ],
              [
                t('form.shippingCost.label'),
                currency(data?.shippingCost.value, data?.shippingCost.currencyCode),
                null,
                currency(
                  data?.shippingCostInMarketplaceCurrency.value,
                  data?.shippingCostInMarketplaceCurrency.currencyCode,
                ),
              ],
              [
                t('column.additionalCostValue.label-short'),
                currency(data?.additionalCost.value, data?.additionalCost.currencyCode),
              ],
              [
                t('column.vatRate.label-short'),
                percent(data?.taxRate.value),
                currency(data?.taxValue.value, data?.taxValue.currencyCode),
              ],
              [
                t('column.returnFee.label'),
                percent(data?.returnsReserve.value),
                currency(data?.returnsReserveValue.value, data?.returnsReserveValue.currencyCode),
              ],
              [
                t('column.channelCommissionAmazon.label'),
                percent(
                  data?.marketplaceCommission.value === 0
                    ? null
                    : data?.marketplaceCommission.value,
                ),
                currency(
                  data?.marketplaceCommissionValue.value,
                  data?.marketplaceCommissionValue.currencyCode,
                ),
              ],
              [
                t('offer-details.price-components.marketplace-fixed-fee'),
                currency(
                  data?.marketplaceCommissionFixedValue.value,
                  data?.marketplaceCommissionFixedValue.currencyCode,
                ),
              ],
              [
                t('column.nethansaCommission.label'),
                percent(data?.nethansaCommission.value),
                currency(
                  data?.nethansaCommissionValue.value,
                  data?.nethansaCommissionValue.currencyCode,
                ),
              ],
            ]}
            subParamRowIndex={[1, 2]}
            description={t('offer-details.price-components.total-costs-desc')}
          />

          <ParamsListTable
            rows={[
              [
                t('offer-details.price-components.markup-profit'),
                percent(data?.actualMarkup.value),
                currency(data?.profit.value, data?.profit.currencyCode),
              ],
              [
                t('column.additionalProfit.label'),
                currency(data?.additionalProfit.value, data?.additionalProfit.currencyCode),
              ],
            ]}
            description={t('offer-details.price-components.markup-profit-desc')}
          />

          <ParamsListTable
            rows={[
              [
                t('column.calculatedPrice.label'),
                currency(data?.finalPrice.value, data?.finalPrice.currencyCode),
              ],
            ]}
            description={t('offer-details.price-components.result-price-desc')}
          />
        </Stack>
      </Show>
    </>
  );
};
