import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabsItem, NavigationTabsLayout } from 'components/navigation-tabs';
import { routes } from 'routes';

export const UserProfileLayout: React.FC = () => {
  const [t] = useTranslation('settings');
  return (
    <NavigationTabsLayout>
      <NavigationTabsItem
        href={routes.Settings.children.UserProfile.link()}
        exact
        children={t('tabs.personal-data')}
      />
      <NavigationTabsItem
        href={routes.Settings.children.UserProfile.children.ChangePassword.link()}
        children={t('tabs.change-password')}
      />
      <NavigationTabsItem
        href={routes.Settings.children.UserProfile.children.Security.link()}
        children={t('tabs.security')}
      />
    </NavigationTabsLayout>
  );
};
