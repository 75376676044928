import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonMenu, Checkbox, FormButtonsGroup, Show, TooltipWrapper } from 'components';
import { LockIcon, MoreIcon, ShowViewIcon } from 'components/icons';
import { useCommonTranslations } from 'modules/shared';
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { useDatagridViewContext, useDatagridViewsManager } from '../../../../../../hooks';

type Props = {
  onClose: () => void;
};

type FormProps = {
  tabs: { [key: string]: boolean };
};

export const FormGroupedViews: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const { createViewModal, renameViewModal, deleteViewModal, manageSharingViewModal } =
    useDatagridViewContext();
  const { groupedViews, views, changeViewsVisibleStatus, changeView, currentView } =
    useDatagridViewsManager();

  const { handleSubmit, control } = useForm<FormProps>({
    defaultValues: {
      tabs: views.reduce((acc, v) => {
        return {
          ...acc,
          [v.id]: v.visible,
        };
      }, {}),
    },
  });

  const submit = (data: FormProps) => {
    const parsedTabs = Object.entries(data.tabs).map((x) => ({
      id: String(x[0]),
      visible: Boolean(x[1]),
    }));
    changeViewsVisibleStatus(parsedTabs);
    props.onClose();
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack>
        <Box sx={{ pb: 1, pl: 0 }}>
          <Button
            size="small"
            variant="text"
            onClick={() => {
              createViewModal.change({ tableId: '', name: '', id: '' });
              props.onClose();
            }}
            sx={{ justifyContent: 'flex-start', width: 'auto' }}
          >
            {tCommon('views.button.create-label')}
          </Button>
        </Box>
        <Divider />
        <Stack sx={{ maxHeight: '336px', overflowY: 'auto', px: 2, py: 2, pr: 3 }} spacing={1}>
          {groupedViews.map((group, index) => (
            <Box key={index}>
              <Stack>
                <Typography variant="h5" fontWeight={700}>
                  {tCommon(
                    `views.type.${group.name.toLowerCase()}_many` as 'views.type.predefined_many',
                  )}
                </Typography>
                <Stack>
                  {group.tableViews.map((view, viewIndex) => {
                    const label = group.predefined
                      ? tCommon(`views.list.${view.name}` as 'views.list.all-columns')
                      : view.name;

                    const buttonMenuStyles: SxProps<Theme> = {
                      visibility:
                        !view.ownedByRequestingUser || group.predefined ? 'hidden' : 'visible',
                    };

                    const firstPredefined = index === 0 && viewIndex === 0;

                    return (
                      <Stack
                        key={view.id}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Controller
                          name={`tabs.${view.id}`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              disabled={firstPredefined}
                              value={field.value}
                              checked={Boolean(field.value)}
                              label={
                                <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                                  {label || ''}
                                </Box>
                              }
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                              sx={{
                                ml: -1,
                                '& + .MuiFormControlLabel-label': {
                                  ml: '0 !important',
                                  p: 0,
                                },
                              }}
                            />
                          )}
                        />
                        <Stack direction="row" spacing={1} sx={{ ml: 2 }} alignItems="center">
                          <TooltipWrapper
                            content={tCommon('views.action.open')}
                            enterDelay={500}
                            enterNextDelay={500}
                          >
                            <Box component="span">
                              <IconButton
                                disabled={!view.visible}
                                sx={{ width: '36px', height: '36px' }}
                                onClick={() => changeView({ id: String(view.id) })}
                              >
                                <ShowViewIcon />
                              </IconButton>
                            </Box>
                          </TooltipWrapper>
                          <Show
                            when={!view.public && !view.predefined}
                            fallback={<LockIcon sx={{ visibility: 'hidden' }} />}
                          >
                            <TooltipWrapper
                              content={tCommon('views.tooltip.private-view')}
                              enterDelay={500}
                              enterNextDelay={500}
                            >
                              <Box component="span">
                                <LockIcon />
                              </Box>
                            </TooltipWrapper>
                          </Show>
                          <ButtonMenu
                            edgeIcon="end"
                            iconButton
                            sx={buttonMenuStyles}
                            buttonContent={<MoreIcon sx={{ fontSize: '18px' }} />}
                          >
                            {({ handleClose }) => [
                              <MenuItem
                                key="open-view"
                                disabled={currentView?.id === view.id}
                                onClick={() => {
                                  changeView({ id: String(view.id) });
                                  handleClose();
                                }}
                              >
                                {tCommon('views.action.open')}
                              </MenuItem>,
                              <MenuItem
                                key="rename"
                                onClick={() => {
                                  renameViewModal.change({
                                    id: view.id,
                                    tableId: view.id,
                                    name: view.name,
                                  });
                                  handleClose();
                                }}
                              >
                                {tCommon('views.action.rename')}
                              </MenuItem>,
                              <MenuItem
                                key="manage-sharing"
                                onClick={() => {
                                  manageSharingViewModal.change({
                                    id: view.id,
                                    tableId: view.id,
                                    isShared: view.public,
                                    name: view.name,
                                  });
                                  handleClose();
                                }}
                              >
                                {tCommon('views.action.share')}
                              </MenuItem>,
                              <MenuItem
                                key="delete"
                                disabled={group.predefined}
                                onClick={() => {
                                  deleteViewModal.change({
                                    id: view.id,
                                    tableId: view.id,
                                    name: view.name,
                                    isShared: view.public,
                                  });
                                  handleClose();
                                }}
                              >
                                {tCommon('views.action.delete')}
                              </MenuItem>,
                            ]}
                          </ButtonMenu>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </Box>
          ))}
        </Stack>
        <Divider />
        <FormButtonsGroup sx={{ px: 2, pt: 2, pb: 1 }}>
          <Button variant="text" size="small" onClick={() => props.onClose()}>
            {tCommon('cancel')}
          </Button>
          <Button size="small" type="submit">
            {tCommon('save')}
          </Button>
        </FormButtonsGroup>
      </Stack>
    </form>
  );
};
