import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const HolidaysIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M13.13 14.56L14.56 13.13L20.29 18.86C20.68 19.25 20.68 19.89 20.29 20.29C19.9 20.68 19.26 20.68 18.86 20.29L13.13 14.56ZM17.42 8.83L18.69 7.56C19.58 6.67 19.46 5.13 18.38 4.48C14.49 2.1 9.34999 2.59 5.97999 5.95C9.90999 4.65 14.29 5.7 17.42 8.83ZM5.94999 5.98C2.58999 9.35 2.09999 14.49 4.46999 18.38C5.12999 19.46 6.66 19.59 7.54999 18.69L8.81999 17.42C5.69999 14.29 4.64999 9.91 5.94999 5.98ZM5.96999 5.96L5.95999 5.97C5.57999 8.98 7.12999 12.85 10.26 15.99L15.99 10.26C12.86 7.13 8.97999 5.58 5.96999 5.96Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};
