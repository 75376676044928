import React, { useState } from 'react';
import { Show } from 'components';
import { ChevronSelectIcon } from 'components/icons';
import { nethansaPalette } from 'theme';
import { alpha, Box, ButtonBase, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Param = string | number | null;

type Props = {
  rows: Param[][];
  subParamRowIndex?: number[];
  description?: string;
  sx?: SxProps<Theme>;
};

export const ParamsListTable: React.FC<Props> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const mainParamRowStyles: SxProps<Theme> = {
    backgroundColor: nethansaPalette.background.group,
    minHeight: '40px',
    lineHeight: '40px',
    pl: 2,
    pr: 4,
  };

  const subParamRowStyles: SxProps<Theme> = {
    backgroundColor: alpha(nethansaPalette.background.titleDark, 0.5),
    minHeight: '32px',
    lineHeight: '32px',
    px: 2,
    mx: 2,
    '&:hover': { backgroundColor: alpha(nethansaPalette.background.titleDark, 0.8) },
  };

  const valueCellStyles: SxProps<Theme> = {
    minWidth: '112px',
    textAlign: 'right',
  };

  return (
    <Stack
      sx={{
        m: 'auto',
        pb: props.rows.length > 1 ? 2 : 0,
        width: '608px',
        backgroundColor: nethansaPalette.main.light,
        border: `2px solid ${nethansaPalette.background.group}`,
        borderRadius: '6px',
        gap: 1,
        '& > h3:first-of-type': { mt: 1 },
      }}
    >
      {props.rows.map((row, index) => (
        <Typography
          key={index}
          variant={index === 0 ? 'h2' : 'h3'}
          sx={() => {
            if (index === 0) return mainParamRowStyles;
            if (props.subParamRowIndex?.includes(index)) return subParamRowStyles;
            return {
              mx: 2,
              my: -0.5,
              py: 0.5,
              pl: 4,
              pr: 2,
              '&:hover': { backgroundColor: alpha(nethansaPalette.background.select, 0.5) },
            };
          }}
        >
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
            {row.map((param, idx) => {
              if (idx === 0 && index === 0)
                return (
                  <ButtonBase
                    disableRipple
                    onClick={() => setIsExpanded(!isExpanded)}
                    sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
                    key={idx}
                  >
                    <Typography variant="h2">
                      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                        <ChevronSelectIcon
                          sx={{
                            width: '10px',
                            height: '7px',
                            color: nethansaPalette.main.accent,
                            transform: isExpanded ? 'none' : 'rotate(-0.25turn)',
                          }}
                        />
                        {param}
                      </Stack>
                    </Typography>
                  </ButtonBase>
                );
              return (
                <Box key={idx} sx={idx === 0 ? { flexGrow: 1 } : valueCellStyles}>
                  {param}
                </Box>
              );
            })}
          </Stack>
          <Show when={!!props.description && index === 0 && isExpanded}>
            <Typography
              variant="body2"
              sx={{
                fontSize: '13px',
                fontWeight: 500,
                lineHeight: '18px',
                mb: 2,
                ml: 2,
                color: alpha(nethansaPalette.main.dark, 0.7),
              }}
            >
              {props.description}
            </Typography>
          </Show>
        </Typography>
      ))}
    </Stack>
  );
};
