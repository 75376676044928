import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { sortBy } from 'common/utils';
import { CountryCode, CurrencyCode } from 'modules/shared/types';
import { GlobalsWizardType } from '../../types/globals-wizard.type';

type ResponseType = {
  mainShippingCountry: CountryCode;
  baseCurrency: CurrencyCode;
  countryCodes: CountryCode[];
  currencyCodes: CurrencyCode[];
};

export const useWizardGlobalsQuery = (): UseQueryResult<GlobalsWizardType> => {
  const { i18n } = useTranslation();
  const [countriesTranslation] = useTranslation('countries');
  const [currenciesTranslation] = useTranslation('currencies');

  return useQuery<GlobalsWizardType>(
    ['wizard', 'globals', i18n.language],
    async () => {
      const response = await httpClient.get<ResponseType>(API.wizard().globals());

      return {
        baseCurrency: response.data.baseCurrency || '',
        mainShippingCountry: response.data.mainShippingCountry || '',
        countryCodes: response.data.countryCodes
          .map((x) => ({ id: x, label: countriesTranslation(x) || x }))
          .sort(sortBy('label')),
        currencyCodes: response.data.currencyCodes.map((x) => ({
          id: x,
          label: currenciesTranslation(`${x}.long`) || x,
        })),
      };
    },
    {
      staleTime: 10000,
      keepPreviousData: true,
    },
  );
};
