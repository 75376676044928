import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryUpdateProductHandlingTimeArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

const amazonBulkAction = gql`
  query updateProductHandlingTime(
    $partnerId: String!
    $newHandlingTime: Int!
    $where: ProductGridDtoFilterInput
  ) {
    updateProductHandlingTime(
      newHandlingTime: $newHandlingTime
      partnerId: $partnerId
      where: $where
    ) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

type InputType = Omit<QueryUpdateProductHandlingTimeArgs, 'partnerId'>;

export const useBulkHandlingTimeProductMutation = (): UseMutationResult<
  Query,
  ErrorMeta,
  InputType
> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryUpdateProductHandlingTimeArgs, 'partnerId'>;
    return await graphqlClient.request<QueryUpdateProductHandlingTimeArgs>(amazonBulkAction, {
      partnerId: currentPartnerId || '0',
      where: data.where,
      newHandlingTime: data.newHandlingTime,
    });
  });
};
