import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from '../../../../common/api';
import { ErrorMeta, ResponseAPIDataStatusType } from '../../../shared/types';
import { useDatagridViewContext } from '../use-datagrid-view-context';
import { useViewsQuery } from './use-views.query';

type InputType = {
  reorderViewCommands: {
    viewId: string;
    visible: boolean;
    position: number;
  }[];
};

export const useViewsReorderMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { tableId } = useDatagridViewContext();
  const { refetch } = useViewsQuery(tableId);

  return useMutation(
    async (body: InputType) => {
      await httpClient.patch<ResponseAPIDataStatusType>(API.views().viewsReorder(), body);
      return;
    },
    {
      onSettled: () => {
        refetch();
      },
    },
  );
};
