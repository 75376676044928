import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { NoPlatformCallToAction } from 'modules/settings-sales-global/components';
import { Typography } from '@mui/material';
import { useGlobalSettingsQuery } from '../settings-sales-global';
import {
  Platform,
  PlatformLoaderBridge,
  PlatformSkeleton,
  PlatformsTabs,
  PlatformsTabsSkeleton,
} from './components';
import { PlatformsContext, PlatformsContextProvider } from './contexts';
import { usePlatformsSettingsQuery } from './hooks';

export const SettingsSalesPlatformsPage: React.FC = () => {
  const [t] = useTranslation('platforms');

  const globalSettingsQuery = useGlobalSettingsQuery();
  const platformsQuery = usePlatformsSettingsQuery();

  const isWizardConfigured = globalSettingsQuery.data?.isConfigured || false;
  const isFetched = platformsQuery.isFetched && globalSettingsQuery.isFetched;
  const activePlatforms = (platformsQuery.data || []).filter((x) => x.isActive);

  return (
    <PlatformsContextProvider>
      <Show when={isFetched}>
        <PlatformsTabs />
      </Show>
      <Show when={!isFetched}>
        <PlatformsTabsSkeleton />
        <PlatformSkeleton />
      </Show>
      <Show when={!isWizardConfigured && isFetched}>
        <NoPlatformCallToAction />
      </Show>
      <Show when={activePlatforms.length === 0 && isWizardConfigured && isFetched}>
        <Typography variant="h5" sx={{ p: 3, whiteSpace: 'pre-line' }}>
          {t('page.no-active-platform')}
        </Typography>
      </Show>
      <PlatformsContext.Consumer>
        {({ tab }) => (
          <>
            {tab && !!tab?.[0] && (
              <PlatformLoaderBridge
                key={tab[0]}
                platformType={tab[0]}
                platformForm={<Platform platformType={tab[0]} key={tab[0]} />}
              />
            )}
          </>
        )}
      </PlatformsContext.Consumer>
    </PlatformsContextProvider>
  );
};
