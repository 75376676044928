import { AutocompleteClasses, AutocompleteProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    loading: true;
  }
}

export const autocompleteStyles: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultProps?: Partial<AutocompleteProps<any, any, any, any>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof AutocompleteClasses>> | undefined;
} = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root': {
        '& .MuiInputBase-input': {
          fontSize: '14px',
          fontWeight: 500,
          borderRadius: '6px',
          padding: '5px 10px',
          '&.Mui-disabled': {
            '& + .MuiOutlinedInput-notchedOutline': {
              borderColor: nethansaPalette.button.grayLight,
            },
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0,
          legend: {
            display: 'none',
          },
        },
      },
    },
  },
};
