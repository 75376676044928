import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClaimDefenderComponent } from 'modules/authorization';
import { routes } from 'routes';
import { NAVIGATION_APP_HEIGHT, MODULE_NAVIGATION_BAR_HEIGHT, nethansaPalette } from 'theme';
import { Link, Stack, Typography } from '@mui/material';
import { SidemenuItemSettings } from './components';

export const SideMenuSettings: React.FC = () => {
  const [t] = useTranslation('settings');
  const borderLine: string = ['1px', 'solid', nethansaPalette.line.light].join(' ');

  return (
    <Stack
      sx={{
        top: NAVIGATION_APP_HEIGHT,
        position: 'sticky',
        minWidth: '205px',
        height: `calc(100vh - ${NAVIGATION_APP_HEIGHT})`,
        borderRight: borderLine,
        backgroundColor: 'common.white',
      }}
    >
      <Stack
        justifyContent="center"
        sx={{ height: MODULE_NAVIGATION_BAR_HEIGHT, px: 3, borderBottom: borderLine }}
      >
        <Typography variant="h1">
          <Link href={routes.Settings.link()}>{t('title')}</Link>
        </Typography>
      </Stack>
      <Stack flex={1} sx={{ p: 1.5 }}>
        <Stack>
          <ClaimDefenderComponent
            notFullWidth
            allowed={'SETTINGS_SALES_INTELLIGENCE'}
            hidden={true}
          >
            <SidemenuItemSettings
              href={routes.Settings.children.SalesIntelligence.link()}
              children={t('menu.sales-intelligence')}
            />
          </ClaimDefenderComponent>

          <ClaimDefenderComponent
            notFullWidth
            allowed={'SETTINGS_PAYMENTS_AND_BILLINGS'}
            hidden={true}
          >
            <SidemenuItemSettings
              href={routes.Settings.children.PaymentsAndBillings.link()}
              children={t('menu.payment-and-billings')}
            />
          </ClaimDefenderComponent>

          <ClaimDefenderComponent notFullWidth allowed={'SETTINGS_CARRIERS'} hidden={true}>
            <SidemenuItemSettings
              href={routes.Settings.children.Carriers.link()}
              children={t('menu.carriers')}
            />
          </ClaimDefenderComponent>

          <ClaimDefenderComponent notFullWidth allowed={'SETTINGS_YOUR_PROFILE'} hidden={true}>
            <SidemenuItemSettings
              href={routes.Settings.children.UserProfile.link()}
              children={t('menu.your-profile')}
            />
          </ClaimDefenderComponent>
        </Stack>
      </Stack>
    </Stack>
  );
};
