import React from 'react';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  country: string;
  showCountryCode?: boolean;
  label?: string;
  sx?: SxProps<Theme>;
};

export const FlagIcon: React.FC<Props> = (props) => {
  const flagMaps: { [key: string]: string } = {
    FR: '/images/flags/france.svg',
    DE: '/images/flags/germany.svg',
    PL: '/images/flags/poland.svg',
    IT: '/images/flags/italy.svg',
    ES: '/images/flags/spain.svg',
    SE: '/images/flags/sweden.svg',
    NL: '/images/flags/netherlands.svg',
    UK: '/images/flags/united-kingdom.svg',
    GB: '/images/flags/united-kingdom.svg',
    BE: '/images/flags/belgium.svg',
  };

  const flagImg = flagMaps[props.country] || '';

  if (!flagImg)
    return (
      <Box
        sx={{
          width: '23px',
          height: '23px',
          borderRadius: '50%',
          backgroundColor: 'primary.light',
        }}
      />
    );

  return (
    <Stack direction="row" gap="8px">
      {flagMaps[props.country] && (
        <img loading="lazy" src={flagImg} width={23} height={23} alt={flagMaps[props.country]} />
      )}
      {props.showCountryCode && <Typography variant="h5">{props.country}</Typography>}
      {!!props.label && <Typography variant="h5">{props.label}</Typography>}
    </Stack>
  );
};
