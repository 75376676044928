import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { useDatagridViewsManager } from '../../../../hooks/use-datagrid-views-manager';
import { PopupViewSaveMenu } from './components';

export const MenuButtonSaveView: React.FC = () => {
  const [t] = useTranslation('common');
  const { currentView, resetView, saveAsNewView, saveView } = useDatagridViewsManager();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleResetView = () => {
    resetView();
  };

  const captionsByType = {
    preDefinedViews: {
      title: t('views.type.predefined_one'),
      description: t('views.type.predefined-text'),
    },
    userViews: {
      title: t('views.type.personal_one'),
      description: t('views.type.personal-text'),
    },
    sharedViews: {
      title: t('views.type.shared_one'),
      description: t('views.type.shared-text'),
    },
  };

  const captions = currentView?.predefined
    ? captionsByType.preDefinedViews
    : currentView?.ownedByRequestingUser
    ? captionsByType.userViews
    : captionsByType.sharedViews;

  return (
    <PopupViewSaveMenu
      buttonLabel={t('views.button.save-view-label')}
      anchorEl={anchorEl}
      onClick={(event) => setAnchorEl(event.currentTarget)}
      onClose={() => setAnchorEl(null)}
    >
      <Typography variant="h5Bold" sx={{ mb: 2 }}>
        {captions.title}
      </Typography>
      <Typography variant="h5" lineHeight="18px" whiteSpace="pre-line">
        {captions.description}
      </Typography>
      <Stack direction="column" alignItems="flex-end" spacing={1} sx={{ mt: 2 }}>
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            color="flat"
            onClick={() => {
              handleResetView();
              setAnchorEl(null);
            }}
          >
            {t('reset')}
          </Button>
          <Button
            size="small"
            disabled={!currentView?.ownedByRequestingUser}
            onClick={() => {
              saveView();
              setAnchorEl(null);
            }}
          >
            {t('save')}
          </Button>
        </Stack>
        <Button
          size="small"
          onClick={() => {
            saveAsNewView();
            setAnchorEl(null);
          }}
        >
          {t('views.button.save-as-new-label')}
        </Button>
      </Stack>
    </PopupViewSaveMenu>
  );
};
