import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { DialogConfirmation, Show, Tag2 } from 'components';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { SpinnerIcon } from '../../../../components/icons';
import { Query, TagCountAnimusOffers } from '../../../../generated/graphql';
import { SelectedOfferDetails } from '../../components';
import { SelectedOffers } from '../../components/modal-selected-offer-details/selected-offers';
import { useBulkTagsMutation, useOfferCoordinatorContext, useOffersTagCounts } from '../../hooks';
import { useOffersTags } from '../../hooks/use-offers-tags.query';

export const EditTagsModal: React.FC = () => {
  const {
    editTags: [actionParams, setActionParams],
  } = useOfferCoordinatorContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { pushSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = React.useState<string>('');

  const useAmazonBulkAction = useBulkTagsMutation();
  const [t] = useTranslation('offers');
  const refreshProducts = () => queryClient.refetchQueries({ active: true, queryKey: 'offers' });
  const closeModal = () => setActionParams(undefined);

  const { data: oldTagsResponse, isFetching: isLoading } = useOffersTagCounts(
    actionParams?.filters || undefined,
  );
  const { data: allTagsResponse, isFetching: isLoadingAllTags } = useOffersTags();

  const [currentTags, setCurrentTags] = useState<Array<TagCountAnimusOffers>>([]);
  const [tagsToAdd, setTagsToAdd] = useState<Array<string>>([]);
  const [tagsToRemove, setTagsToRemove] = useState<Array<string>>([]);
  const [allTags, setAllTags] = React.useState<string[]>([]);
  const isSingleElement = actionParams?.row != null;

  useEffect(() => {
    setCurrentTags(
      oldTagsResponse?.sort((a, b) => (a?.tag || '').localeCompare(b?.tag || '')) || [],
    );
  }, [oldTagsResponse]);

  useEffect(() => {
    setAllTags(
      allTagsResponse
        ?.map((e) => {
          return e.name;
        })
        .sort((a, b) => a.localeCompare(b)) || [],
    );
  }, [allTagsResponse]);

  const handleResponse = (data: Query) => {
    const success = data.updateOffersTags;

    if (success) {
      refreshProducts();
      actionParams?.onSuccess && actionParams?.onSuccess();
      closeModal();
      pushSnackbar({
        variant: 'success',
        message: t('modals.edit-tags.messages.success'),
      });
    } else setErrorMessage(t('modals.edit-tags.messages.error'));
  };

  const deleteTag = (tagToDelete: string, isNew: boolean) => () => {
    setTagsToRemove([...tagsToRemove, tagToDelete]);

    if (isNew) {
      setTagsToAdd((tagsToAdd) => tagsToAdd.filter((tag) => tag !== tagToDelete));
      return;
    }

    setCurrentTags((currentTags) =>
      currentTags.filter((productTag) => productTag.tag !== tagToDelete),
    );
  };

  const addTag = () => {
    if (!inputValue) return;

    const newTag = inputValue.trim();

    if (!tagsToAdd.includes(newTag)) {
      setTagsToAdd([...tagsToAdd, newTag]);
      setCurrentTags((currentTags) =>
        currentTags.filter((productTag) => productTag.tag !== newTag),
      );
    }

    setInputValue('');
  };

  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }

    useAmazonBulkAction.mutate(
      {
        tagsToAdd: tagsToAdd,
        tagsToRemove: tagsToRemove,
        where: actionParams?.filters,
      },
      {
        onSuccess: (data) => handleResponse(data),
        onError: () => setErrorMessage(t('modals.edit-tags.messages.error')),
      },
    );
  };

  return (
    <DialogConfirmation
      isLoading={useAmazonBulkAction.isLoading}
      confirmLabel={t('modals.edit-tags.button-label')}
      open
      onClose={confirm}
      header={t('modals.edit-tags.header')}
      tooltip={t('modals.edit-tags.tooltip')}
      description=""
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <Show when={isSingleElement}>
          <SelectedOfferDetails row={actionParams?.row} />
        </Show>
        <Show when={!isSingleElement}>
          <SelectedOffers offersNumber={actionParams?.selectedCount} showWarning={true} />
        </Show>
        <Show when={errorMessage != null}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Show>

        <Stack
          sx={{
            gap: '16px',
            flexDirection: 'column',
            backgroundColor: nethansaPalette.background.title,
            width: '100%',
            pl: 2,
            py: 1,
            fontSize: '13px',
            lineHeight: '18px',
          }}
        >
          <Show when={!isLoading && !isLoadingAllTags}>
            <Typography variant="h5" sx={{ lineHeight: '18px', whiteSpace: 'pre-line' }}>
              <Show when={isSingleElement && currentTags.length > 0}>
                {t('modals.edit-tags.label-single')}
              </Show>
              <Show when={!isSingleElement && currentTags.length > 0}>
                {t('modals.edit-tags.label-multi')}
              </Show>
              <Show when={isSingleElement && currentTags.length === 0}>
                {t('modals.edit-tags.label-single-no-tags')}
              </Show>
              <Show when={!isSingleElement && currentTags.length === 0}>
                {t('modals.edit-tags.label-multi-no-tags')}
              </Show>
            </Typography>
            <Box>
              {currentTags?.map((e) => (
                <Tag2
                  key={e.tag || 'undefined'}
                  onDelete={deleteTag(e.tag || '', false)}
                  title={e.tag || ''}
                  showTagsNumber={!isSingleElement}
                  count={e.count}
                  color={nethansaPalette.other.tagOffer}
                />
              ))}
            </Box>
            <Box>
              {tagsToAdd?.map((e) => (
                <Tag2
                  key={e}
                  onDelete={deleteTag(e, true)}
                  title={e}
                  showTagsNumber={!isSingleElement}
                  count={actionParams?.selectedCount}
                  color={nethansaPalette.other.tagOffer}
                />
              ))}
            </Box>
            <Stack
              sx={{
                gap: '1rem',
                flexDirection: 'row',
                alignItems: 'center',
                mb: '1rem',
              }}
            >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                autoHighlight
                inputValue={inputValue}
                onInputChange={(event: any, newValue: string | undefined) => {
                  setInputValue(newValue || '');
                }}
                options={allTags}
                sx={{ width: '395px' }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search or create tags, start typing" />
                )}
              />
              <Button type="button" size="small" color="flat" onClick={addTag}>
                {t('modals.edit-tags.add-tag')}
              </Button>
            </Stack>
          </Show>
          <Show when={isLoading || isLoadingAllTags}>
            <SpinnerIcon sx={{ width: '150px', height: 'auto', margin: 'auto' }} />
          </Show>
        </Stack>
      </Stack>
    </DialogConfirmation>
  );
};
