import React from 'react';
import { PlatformType } from 'modules/shared/types';
import { usePlatformQuery } from '../../hooks';
import { PlatformSkeleton } from '../platform-skeleton';

type Props = {
  platformType: PlatformType;
  platformForm: JSX.Element;
};

export const PlatformLoaderBridge: React.FC<Props> = (props) => {
  const { isFetched } = usePlatformQuery(props.platformType);

  return <>{isFetched ? props.platformForm : <PlatformSkeleton />}</>;
};
