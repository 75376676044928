import React from 'react';
import { ChangeSkuModal } from './modals/modal-change-sku';
import { EditTagsModal } from './modals/modal-edit-tags/edit-tags.modal';
import { ModalOfferAnalyzeConfirmation } from './modals/modal-offer-analyze-confirmation';
import { ModalOfferDeleteConfirmation } from './modals/modal-offer-delete-confirmation';
import { ModalOfferEditAsin } from './modals/modal-offer-edit-asin';
import { PauseOrActivateOfferModal } from './modals/modal-offer-pause-or-activate-offer';
import { RawPriceCalculationModal } from './modals/modal-offer-raw-price-calculation';
import { ModalOfferSendToPlatform } from './modals/modal-offer-send-to-platform';
import { OfferDetailsModal } from './modals/offer-details';
import { PriceSettingsModal } from './modals/price-settings';
import { OfferModalCoordinatorContext, OfferModalsCoordinatorProvider } from './providers';

type Props = React.PropsWithChildren;

export const OfferModalsCoordinator: React.FC<Props> = (props) => {
  return (
    <OfferModalsCoordinatorProvider>
      {props.children}
      <OfferModalCoordinatorContext.Consumer>
        {(params) => (
          <>
            {params.sendOffers[0] && <ModalOfferSendToPlatform />}
            {params.editAsin[0] && <ModalOfferEditAsin />}
            {params.priceSettings[0] && <PriceSettingsModal />}
            {params.analyzeOffers[0] && <ModalOfferAnalyzeConfirmation />}
            {params.deleteOffers[0] && <ModalOfferDeleteConfirmation />}
            {params.offerDetails[0] && <OfferDetailsModal />}
            {params.rawPriceCalculation[0] && <RawPriceCalculationModal />}
            {params.pauseOffer[0] && <PauseOrActivateOfferModal />}
            {params.changeSku[0] && <ChangeSkuModal />}
            {params.editTags[0] && <EditTagsModal />}
          </>
        )}
      </OfferModalCoordinatorContext.Consumer>
    </OfferModalsCoordinatorProvider>
  );
};
