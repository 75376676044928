import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { StylebookPage } from 'modules/stylebook';
import { LayoutRoot } from '../modules/layout-root';
import {
  OffersRouter,
  ReportsRouter,
  SettingsRouter,
  ProductsRouter,
  MonitoringRouter,
} from './routers';
import { DashboardRouter } from './routers/dashboard.router';
import { routes } from './routes';

// More about: React router v6 Descendant Routes - https://reactrouter.com/docs/en/v6/getting-started/overview#descendant-routes
export const RootRouter: React.FC = () => (
  <Routes>
    <Route element={<LayoutRoot />}>
      <Route path={routes.Stylebook.path} element={<StylebookPage />} />
      <Route path={routes.Dashboard.path} element={<DashboardRouter />} />
      <Route path={`${routes.Products.path}/*`} element={<ProductsRouter />} />
      <Route path={`${routes.Offers.path}/*`} element={<OffersRouter />} />
      <Route path={`${routes.Reports.path}/*`} element={<ReportsRouter />} />
      <Route path={`${routes.Monitoring.path}/*`} element={<MonitoringRouter />} />
      <Route path={`${routes.Settings.path}/*`} element={<SettingsRouter />} />
    </Route>
  </Routes>
);
