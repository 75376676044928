import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { DialogConfirmation, Show, TextFieldNumber } from 'components';
import { ActionDetails } from 'modules/offers/components/modal-action-details/action-details';
import {
  useProductsCoordinatorContext,
  useShoppingBasketMutation,
  useShoppingBasketVeryficationQuery,
} from 'modules/products/hooks';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { usePlatformQuery } from 'modules/settings-sales-platforms/hooks';
import { useCurrenciesTranslations } from 'modules/shared';
import { CurrencyCode } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { Query } from '../../../../generated/graphql';

export interface Props {
  text: string;
  type: 'ok' | 'error';
}
const Bullet: React.FC<Props> = (props) => (
  <Stack sx={{ gap: 1, flexDirection: 'row', ml: 1 }}>
    <Box
      sx={{
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor:
          props.type === 'error'
            ? nethansaPalette.main.watermelon
            : nethansaPalette.other.greenDeep,
        mt: '6px',
        flexShrink: 0,
      }}
    />
    <Typography variant="h5Text">{props.text}</Typography>
  </Stack>
);
interface Message {
  message: string;
  severity: 'error' | 'success' | 'info';
}

export const ShoppingBasket: React.FC = () => {
  const {
    shoppingBasket: [actionParams, setActionParams],
  } = useProductsCoordinatorContext();
  const [t, i18n] = useTranslation('products');
  const [tCurrency] = useCurrenciesTranslations();

  const [message, setMessage] = useState<Message | null>();
  const [bullets, setBullets] = useState<Message[] | null>();
  const [formLocked, setFormLocked] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const [validationMessage, setValidationMessage] = useState<string | null>(null);
  const { data: globalSettings, isFetched: globalSettingsFetched } = useGlobalSettingsQuery();
  const { data: platformData, isFetched: platformDataFetched } = usePlatformQuery('AMZEU');
  const { data: validProducts, isFetched: validProductsFetched } =
    useShoppingBasketVeryficationQuery(actionParams?.filters);
  const sendShoppingBasketMutation = useShoppingBasketMutation();
  const shoppingBasketValueInputRef = React.useRef<HTMLInputElement>(null);

  const refreshProducts = () => queryClient.refetchQueries({ active: true, queryKey: 'products' });
  const closeModal = () => setActionParams(undefined);

  const selectedProducts = actionParams?.selectedCount || 0;

  const currency = tCurrency(`${(globalSettings?.baseCurrency as CurrencyCode) || 'EUR'}.short`);

  useEffect(() => {
    setMessage(null);
    setBullets(null);
    setFormLocked(false);

    if (platformData !== undefined && !platformData.isActive) {
      setMessage({ message: t('modals.shoppingBasket.errors.only-kaufland'), severity: 'error' });
      setFormLocked(true);
      return;
    }

    if (platformData !== undefined && !platformData.isAutoStock) {
      setMessage({
        message: t('modals.shoppingBasket.messages.no-stock-managent'),
        severity: 'info',
      });
    }

    if (validProducts !== undefined) {
      if (selectedProducts == 0 || validProducts === 0) {
        setBullets((bullets) => [
          ...(bullets || []),
          { message: t('modals.shoppingBasket.errors.no-products'), severity: 'error' },
        ]);
        setFormLocked(true);
        return;
      }

      switch (true) {
        case validProducts > 2000:
          setBullets((bullets) => [
            ...(bullets || []),
            { message: t('modals.shoppingBasket.errors.to-many'), severity: 'error' },
          ]);
          setFormLocked(true);
          break;

        case validProducts == 0:
          setBullets((bullets) => [
            ...(bullets || []),
            { message: t('modals.shoppingBasket.errors.no-products'), severity: 'error' },
          ]);
          setFormLocked(true);
          break;

        case validProducts == 1:
          setBullets((bullets) => [
            ...(bullets || []),
            { message: t('modals.shoppingBasket.errors.one-product'), severity: 'error' },
          ]);
          setFormLocked(true);
          break;

        case validProducts == selectedProducts:
          setBullets((bullets) => [
            ...(bullets || []),
            { message: t('modals.shoppingBasket.messages.all-ok'), severity: 'success' },
          ]);
          break;

        case validProducts > 1:
          setBullets((bullets) => [
            ...(bullets || []),
            {
              message: t('modals.shoppingBasket.messages.with-product-cost', {
                count: validProducts,
              }),
              severity: 'success',
            },
          ]);
          setBullets((bullets) => [
            ...(bullets || []),
            {
              message: t('modals.shoppingBasket.messages.without-product-cost', {
                count: selectedProducts - validProducts,
              }),
              severity: 'error',
            },
          ]);
      }
    }
  }, [validProductsFetched, platformDataFetched]);

  useEffect(() => {
    setHasErrors(
      (bullets?.some((b) => b.severity === 'error') &&
        !bullets?.some((b) => b.severity === 'success')) ||
        message?.severity === 'error',
    );
  }, [bullets, message]);

  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }

    const basketValue = Number(shoppingBasketValueInputRef.current?.value);
    if (!verifyValue(basketValue)) return;

    sendShoppingBasketMutation.mutate(
      {
        budgetAmount: basketValue,
        where: actionParams?.filters,
        language: i18n.language,
      },
      {
        onSuccess: (data) => handleResponse(data),
        onError: () =>
          setMessage({ message: t('modals.shoppingBasket.messages.error'), severity: 'error' }),
      },
    );
  };

  const handleResponse = (data: Query) => {
    const errors = data.updateProductHandlingTime?.errors;
    if (!errors || errors.length == 0) {
      refreshProducts();
      actionParams?.onSuccess && actionParams?.onSuccess();

      setMessage({ message: t('modals.shoppingBasket.messages.success'), severity: 'success' });
    } else {
      setMessage({ message: t('modals.shoppingBasket.messages.error'), severity: 'error' });
    }
  };

  const verifyValue = (value: number | null): boolean => {
    if (!value) {
      setValidationMessage(t('modals.shoppingBasket.errors.no-value'));
      return false;
    }

    if (value < 1) {
      setValidationMessage(t('modals.shoppingBasket.errors.to-low', { currency: currency }));
      return false;
    }

    if (value > 1e9) {
      setValidationMessage(t('modals.shoppingBasket.errors.to-high'));
      return false;
    }

    setValidationMessage(null);
    return true;
  };

  return (
    <DialogConfirmation
      isLoading={!validProductsFetched || !platformDataFetched || !globalSettingsFetched}
      confirmLabel={t('modals.shoppingBasket.buttons.send')}
      open
      onClose={confirm}
      header={t('actions.shoppingBasket')}
      showOnlyClose={formLocked || message !== null}
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <ActionDetails
          content={t('modals.shoppingBasket.action-details')}
          title={t('actions.action-details-title')}
        />

        <Show when={message != null}>
          <Alert severity={message?.severity} sx={{ width: '100%' }}>
            {message?.message}
          </Alert>
        </Show>

        <Show when={validProductsFetched && platformDataFetched}>
          <Show when={bullets !== null}>
            <Box
              sx={{
                p: 2,
                width: '100%',
                background: hasErrors
                  ? nethansaPalette.background.error
                  : nethansaPalette.background.title,
              }}
            >
              <Stack>
                <Typography variant="h5Text">
                  {t('modals.shoppingBasket.selected-counter', { count: selectedProducts })}
                </Typography>
              </Stack>

              {bullets
                ?.filter((b) => b.severity === 'success')
                .map((b) => (
                  <Bullet type="ok" text={b.message} />
                ))}
              {bullets
                ?.filter((b) => b.severity === 'error')
                .map((b) => (
                  <Bullet type="error" text={b.message} />
                ))}
            </Box>
          </Show>

          <Show when={!formLocked}>
            <Box sx={{ background: nethansaPalette.background.title, p: 2, width: '100%' }}>
              <Stack
                sx={{
                  gap: '1rem',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="h5Text" sx={{ lineHeight: '40px' }}>
                  {t('modals.shoppingBasket.input-label', { count: validProducts })}
                </Typography>
                <TextFieldNumber
                  value={actionParams?.row?.handlingTime}
                  inputRef={shoppingBasketValueInputRef}
                  onBlur={verifyValue}
                  unit={currency}
                  precision={2}
                  helperText={validationMessage}
                  error={validationMessage != null}
                  sx={{
                    width: '136px',
                    '& > p': {
                      marginLeft: 0,
                      width: '250px',
                      right: 'auto !important',
                      position: 'relative',
                    },
                    '&:has(>p:not(:empty))': { mb: 2 },
                  }}
                />
              </Stack>
            </Box>
          </Show>
        </Show>
      </Stack>
    </DialogConfirmation>
  );
};
