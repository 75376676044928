import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from '../../../common/api';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';
import { TaxRateInputType } from '../types/vat-rate.type';

type InputType = TaxRateInputType;

export const useVatRatesMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    return await envelope(httpClient.put(API.salesIntelligence(currentPartnerId).taxRates(), body));
  });
};
