import { alpha, PaletteOptions } from '@mui/material';
import {
  BackgroundPalette,
  ButtonPalette,
  LinePalette,
  MainPalette,
  NethansaPalette,
  OtherPalette,
  StatusPalette,
} from './types';

declare module '@mui/material/styles' {
  interface Palette {
    flat: Palette['primary'];
    nethansa: NethansaPalette;
  }

  interface PaletteOptions {
    flat?: PaletteOptions['primary'];
    nethansa?: NethansaPalette;
  }
}

const main: MainPalette = {
  dark: '#282828',
  light: '#FFFFFF',
  active: '#FAAD1B',
  watermelon: '#FF515C',
  accent: '#404040',
  gray: '#8B8B8D',
};

const background: BackgroundPalette = {
  title: '#F5F5FA',
  group: '#E3E5F1',
  select: '#FFF1D6',
  done: '#DEFBF3',
  error: '#FFEEED',
  info: '#E4F3FB',
  titleDark: '#EDEDF7',
};

const button: ButtonPalette = {
  grayLight: '#CFD0DC',
  grayHover: '#B0B1BB',
  blueMain: '#4543A6',
  blueHover: '#373584',
  blueInactive: '#8383BD',
};

const line: LinePalette = {
  dark: button.grayLight,
  light: '#DDE8F1',
};

const other: OtherPalette = {
  greenDeep: '#00D791',
  blueDeep: '#48AFE3',
  pale: '#FEEFD1',
  lightBlue: '#CAE7F7',
  tagOffer: '#F7CAF6',
};

const status: StatusPalette = {
  dead: {
    bg: alpha('#B8B8B8', 0.25),
    light: '#B8B8B8',
    dark: '#999999',
  },
  temp: {
    bg: alpha('#9CA3C9', 0.25),
    light: '#9CA3C9',
    dark: '#7A85B8',
  },
  opBefore: {
    bg: alpha('#FFD02F', 0.25),
    light: '#FFD02F',
    dark: '#B58C00',
  },
  opAfter: {
    bg: alpha('#F08B42', 0.25),
    light: '#F08B42',
    dark: '#BD580F',
  },
  active: {
    bg: alpha('#77CC66', 0.25),
    light: '#77CC66',
    dark: '#449933',
  },
};

export const nethansaPalette: NethansaPalette = {
  main,
  background,
  button,
  line,
  other,
  status,
};

export const paletteOptions: PaletteOptions = {
  mode: 'light',
  nethansa: nethansaPalette,
  primary: {
    main: main.active,
  },
  secondary: {
    light: button.blueInactive,
    main: button.blueMain,
    dark: button.blueHover,
  },
  flat: {
    light: button.grayHover,
    main: button.grayLight,
    dark: main.accent,
  },
  success: {
    main: other.greenDeep,
    light: background.done,
  },
  error: {
    main: main.watermelon,
    light: background.error,
  },
  warning: {
    main: other.blueDeep,
    light: background.select,
  },
  info: {
    main: other.blueDeep,
    light: background.info,
  },
  background: {
    paper: main.light,
    default: background.title,
  },
  common: {
    black: main.dark,
  },
  text: {
    primary: main.dark,
    secondary: '#757575',
  },
};
