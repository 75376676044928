import { useMutation, UseMutationResult } from 'react-query';
import { gql } from 'graphql-request';
import { graphqlClient } from '../../../common/graphql/graphql-client';
import { Query, QueryShoppingBasketArgs } from '../../../generated/graphql';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

const amazonBulkAction = gql`
  query senShoppingBasket(
    $partnerId: String!
    $budgetAmount: Decimal!
    $where: ProductGridDtoFilterInput
    $language: String!
  ) {
    shoppingBasket(
      budgetAmount: $budgetAmount
      partnerId: $partnerId
      where: $where
      language: $language
    ) {
      numberOfSucceededOperations
      errors {
        key
        value
      }
    }
  }
`;

type InputType = Omit<QueryShoppingBasketArgs, 'partnerId'>;

export const useShoppingBasketMutation = (): UseMutationResult<Query, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (body) => {
    const data = body as Omit<QueryShoppingBasketArgs, 'partnerId'>;
    return await graphqlClient.request<QueryShoppingBasketArgs>(amazonBulkAction, {
      partnerId: currentPartnerId || '0',
      where: data.where,
      budgetAmount: data.budgetAmount,
      language: data.language,
    });
  });
};
