import { useCommonTranslations } from 'modules/shared';
import {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { SharedStatesInputComponent } from '../components/datagrid-operators/shared-states-input-component';
import { operators } from '../consts';

export const UseDataGridSharedStatesOperator = (): GridFilterOperator[] => {
  const { tCommon } = useCommonTranslations();

  const sharedStatesOperator: GridFilterOperator = {
    label: tCommon('datagrid.operators.numeric.='),
    value: operators.sharedStatuses,
    getApplyFilterFn:
      (_filterItem: GridFilterItem, _column: GridColDef) =>
      (_params: GridCellParams): boolean =>
        true,
    InputComponent: SharedStatesInputComponent,
    InputComponentProps: {},
  };

  return [sharedStatesOperator];
};
