import React from 'react';
import { useTranslation } from 'react-i18next';
import { nethansaPalette } from 'theme';
import { TabList } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { AlertIcon } from '../../../../../../../../components/icons';
import { PriceSettingsTab } from '../../../../../../types';

type PropsType = {
  tabs: { label: PriceSettingsTab; hasError: boolean }[];
  handleTabChange: (event: React.SyntheticEvent, newValue: PriceSettingsTab) => void;
};

export const Tabs: React.FC<PropsType> = (props) => {
  const [t] = useTranslation('offers');

  return (
    <TabList
      onChange={props.handleTabChange}
      TabIndicatorProps={{
        sx: { height: '3px' },
        children: (
          <Box className="MuiTabs-indicatorSpan" component="span" sx={{ borderRadius: '6px' }} />
        ),
      }}
    >
      {props.tabs.map(({ label, hasError }) => (
        <Tab
          sx={{ height: '70px', color: nethansaPalette.main.gray }}
          key={label}
          label={<Typography variant="h2">{t(`modals.price-settings.tabs.${label}`)}</Typography>}
          icon={<AlertIcon sx={{ fontSize: '15px', ml: 0.5, opacity: hasError ? 1 : 0 }} />}
          iconPosition="end"
          value={label}
          disableRipple
        />
      ))}
    </TabList>
  );
};
