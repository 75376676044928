import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { Box, FormControlLabel, Stack, Switch, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { usePlatformsContext } from '../../../../hooks';
import { PlatformInput } from '../../../../types';

type Props = {
  client?: string;
  secret?: string;
};

export const SectionMainSettings: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const {
    tab: [platform],
  } = usePlatformsContext();
  const form = useFormContext<PlatformInput>();

  const labelStyles: SxProps<Theme> = {
    maxWidth: '300px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  return (
    <Box>
      <Stack direction="row" gap={2}>
        <Stack sx={{ minWidth: '430px' }} gap={1}>
          <Typography variant="h3" fontSize="13px">
            {platform === 'AMZEU' && t('amazon.account-identifier.label')}
            {platform === 'KAUF' && t('kaufland.clientKey.label')}
          </Typography>
          <Typography sx={labelStyles} fontSize="13px" textTransform="uppercase" fontWeight="bold">
            {props.client || '-'}
          </Typography>
        </Stack>
        <Stack sx={{ minWidth: '430px' }} gap={1}>
          <Typography variant="h3" fontSize="13px">
            {platform === 'AMZEU' && t('amazon.access-token.label')}
            {platform === 'KAUF' && t('kaufland.secretKey.label')}
          </Typography>
          <Typography sx={labelStyles} fontSize="13px" fontWeight="bold">
            {props.secret || '-'}
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ mt: 4, display: 'inline-block' }}>
        <Stack gap={2}>
          <Controller
            control={form.control}
            name="isAutoStock"
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    value={field.value}
                    checked={!!field.value}
                    onChange={(event, checked) => field.onChange(checked)}
                  />
                }
                label={
                  <Box component="span">
                    {t('form.stockSendingToPlatform.label')}{' '}
                    <Box component="strong" sx={{ '& button': { mb: '3px', ml: 0.8 } }}>
                      {field.value ? tCommon('enabled') : tCommon('disabled')}{' '}
                      {platform === 'AMZEU' && (
                        <Tooltip content={t('form.stockSendingToPlatform.tooltip.amzue.__html')} />
                      )}
                      {platform === 'KAUF' && (
                        <Tooltip content={t('form.stockSendingToPlatform.tooltip.kauf.__html')} />
                      )}
                    </Box>
                  </Box>
                }
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};
