import { SharedOfferStateEnum, SharedOfferSubStateEnum } from 'generated/graphql';
import { PlatformChannelCode } from './types';

export function getPossibleSharedStates(
  platformChannelCode: PlatformChannelCode,
): SharedOfferStateEnum[] {
  return Object.entries(SharedOfferStateEnum)
    .filter((entry) => {
      if (
        entry[1] === SharedOfferStateEnum.Deleted ||
        entry[1] === SharedOfferStateEnum.New ||
        entry[1] === SharedOfferStateEnum.Unknown
      ) {
        return false;
      }

      if (
        platformChannelCode === 'DEFAULT' &&
        entry[1] === SharedOfferStateEnum.MultipleListingsFound
      ) {
        return false;
      }

      return true;
    })
    .map((entry) => entry[0] as SharedOfferStateEnum);
}

export function getPossibleSubStates(
  platformChannelCode: PlatformChannelCode,
  state: SharedOfferStateEnum,
): SharedOfferSubStateEnum[] {
  switch (state) {
    case SharedOfferStateEnum.NotFound:
      return [];
    case SharedOfferStateEnum.Processing:
      return GetPlatformProcessingStates(platformChannelCode);
    case SharedOfferStateEnum.Incomplete:
      return GetPlatformIncompleteSubStates(platformChannelCode);
    case SharedOfferStateEnum.MultipleListingsFound:
      return [];
    case SharedOfferStateEnum.ReadyToSend:
      return [];
    case SharedOfferStateEnum.SendingError:
      return [];
    case SharedOfferStateEnum.ListingError:
      return GetPlatformListingErrorStates(platformChannelCode);
    case SharedOfferStateEnum.OutOfStock:
      return [];
    case SharedOfferStateEnum.OnHold:
      return [];
    case SharedOfferStateEnum.Active:
      return [];
    default:
      throw new Error('Invalid state!');
  }
}

function GetPlatformProcessingStates(channelCode: PlatformChannelCode) {
  let subStates = [
    SharedOfferSubStateEnum.SearchingOffer,
    SharedOfferSubStateEnum.SendingToMarketplace,
    SharedOfferSubStateEnum.DeletingToChangeListing,
    SharedOfferSubStateEnum.Activating,
    SharedOfferSubStateEnum.PuttingOnHold,
  ];

  if (channelCode === 'DEFAULT')
    subStates = [
      ...subStates,
      SharedOfferSubStateEnum.CalculatingInitialPrice,
      SharedOfferSubStateEnum.WaitingForProductData,
      SharedOfferSubStateEnum.Deleting,
    ];

  if (channelCode == 'AMZFBM') subStates = [...subStates, SharedOfferSubStateEnum.SkuOnHold];

  return subStates;
}

function GetPlatformListingErrorStates(channelCode: PlatformChannelCode) {
  if (channelCode === 'DEFAULT')
    return [
      SharedOfferSubStateEnum.ActivatingError,
      SharedOfferSubStateEnum.DeletingError,
      SharedOfferSubStateEnum.PausingError,
    ];
  else return [SharedOfferSubStateEnum.DetailPageRemoved];
}

function GetPlatformIncompleteSubStates(channelCode: PlatformChannelCode) {
  const common = [SharedOfferSubStateEnum.UnableToSendPrice, SharedOfferSubStateEnum.MissingStock];

  if (channelCode === 'AMZFBM') return [...common, SharedOfferSubStateEnum.SkuOnHold];

  return common;
}
