import { useQuery, UseQueryResult } from 'react-query';
import { API, httpClient } from 'common/api';
import { PlatformCode } from 'modules/shared/types';
import { useAuth } from '../../authorization';
import { MarketType } from '../types/market.type';

type ResponseType = MarketType[];

export const usePlatformMarketsQuery = (
  platformCode: PlatformCode,
): UseQueryResult<MarketType[]> => {
  const { authorized } = useAuth();

  return useQuery<MarketType[]>(
    ['platform', 'markets', platformCode],
    async () => {
      const response = await httpClient.get<ResponseType>(
        API.wizard().singlePlatformMarketplaces(platformCode),
      );
      return response.data;
    },
    {
      staleTime: 0,
      enabled: authorized,
    },
  );
};
