import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { parseFloatValueWithUnit } from 'common/utils';
import { TextFieldNumber } from 'components';
import { usePriceSettingsQuery } from 'modules/offers/hooks';
import { useCurrenciesTranslations } from 'modules/shared';
import { CurrencyCode } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import { Box, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CellCheckbox, ColumnHeader, FirstColumnCell } from '../../components';

type Props = {
  disabled: boolean;
};

export const CompetitionTab: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  const [tCurrency] = useCurrenciesTranslations();

  const { control, setValue } = useFormContext();
  const { data } = usePriceSettingsQuery();

  const validationRangeRules = {
    diffFromCompPercent: {
      min: 0,
      max: 100,
    },
    diffFromCompValue: {
      min: 0,
      max: 999,
    },
  };

  const isSingle = data?.isSingleOffer || false;

  const useDefaultPercentageDifferenceFromCompetitionValue = useWatch({
    control,
    name: 'repricerSettings.useDefaultPercentageDifferenceFromCompetition',
  });

  const useOfferPercentageDifferenceFromCompetitionValue = useWatch({
    control,
    name: 'repricerSettings.percentageDifferenceFromCompetition',
  });

  const useDefaultValueDifferenceFromCompetitionValue = useWatch({
    control,
    name: 'repricerSettings.useDefaultValueDifferenceFromCompetition',
  });

  const useOfferValueDifferenceFromCompetitionValue = useWatch({
    control,
    name: 'repricerSettings.valueDifferenceFromCompetition',
  });

  const handleOfferValue = (value: boolean | null, checkboxName: string) => {
    isSingle ? setValue(checkboxName, value) : setValue(checkboxName, false);
  };

  const marketCurrency = isSingle
    ? tCurrency(`${(data?.offerPriceSettingsParams?.currencyCode as CurrencyCode) || 'EUR'}.short`)
    : '$$';

  const getValue = (val?: number, unit?: string) => {
    if (!isSingle) return undefined;
    return !val && val !== 0
      ? t('modals.price-settings.no-set')
      : parseFloatValueWithUnit(val, 2, unit || '%');
  };

  const rowStyles: SxProps<Theme> = {
    height: '56px',
    flexDirection: 'row',
    alignItems: 'center',
    '& > div:not(:first-of-type)': {
      backgroundColor: nethansaPalette.background.title,
      height: '100%',
      width: '112px',
    },
    '& > div:first-of-type': {
      width: '320px',
    },
    '& > div:nth-of-type(2)': {
      width: isSingle ? '112px' : '96px',
    },
  };

  return (
    <>
      <Stack sx={{ ...rowStyles, height: isSingle ? '36px' : '52px' }}>
        <Box></Box>
        <ColumnHeader
          isSmall
          sx={{ p: '10px' }}
          label={t('modals.price-settings.columnHeader.default.label')}
        />
        <ColumnHeader
          isSmall
          sx={{ p: '10px' }}
          label={t('modals.price-settings.columnHeader.individual.label')}
        />
      </Stack>

      <Controller
        control={control}
        name={'repricerSettings.percentageDifferenceFromCompetition'}
        rules={{
          required: {
            value:
              isSingle &&
              !useDefaultPercentageDifferenceFromCompetitionValue &&
              useOfferPercentageDifferenceFromCompetitionValue === null,
            message: t('form.percentageDifferenceFromCompetition.validation.required'),
          },
          min: {
            value: validationRangeRules.diffFromCompPercent.min,
            message: t('form.validation-generic.min', {
              min: validationRangeRules.diffFromCompPercent.min,
            }),
          },
          max: {
            value: validationRangeRules.diffFromCompPercent.max,
            message: t('form.validation-generic.max', {
              max: validationRangeRules.diffFromCompPercent.max,
            }),
          },
        }}
        render={({ field, fieldState }) => (
          <>
            <Stack sx={rowStyles}>
              <FirstColumnCell
                cellContent={{
                  text: t('modals.price-settings.parameters.diff-from-competition-percent.label'),
                  tooltip: t('form.percentageDifferenceFromCompetition.tooltip.__html'),
                }}
              />

              <CellCheckbox
                control={control}
                disabled={
                  props.disabled ||
                  (useOfferPercentageDifferenceFromCompetitionValue === null && isSingle)
                }
                name={'repricerSettings.useDefaultPercentageDifferenceFromCompetition'}
                label={getValue(
                  data?.defaultPriceSettings?.repricerSettings.percentageDifferenceFromCompetition,
                )}
              />
              <Stack sx={{ justifyContent: 'center', p: 1 }}>
                <TextFieldNumber
                  disabled={
                    props.disabled ||
                    (field.value !== null && useDefaultPercentageDifferenceFromCompetitionValue)
                  }
                  onChange={(value) => {
                    handleOfferValue(
                      value === null,
                      'repricerSettings.useDefaultPercentageDifferenceFromCompetition',
                    );
                    field.onChange(value);
                  }}
                  onBlur={field.onBlur}
                  value={field.value}
                  unit={'%'}
                  precision={2}
                  error={!!fieldState.error}
                  tooltipError={fieldState.error?.message}
                />
              </Stack>
            </Stack>
          </>
        )}
      />

      <Controller
        control={control}
        name={'repricerSettings.valueDifferenceFromCompetition'}
        rules={{
          min: {
            value: validationRangeRules.diffFromCompValue.min,
            message: t('form.validation-generic.min', {
              min: validationRangeRules.diffFromCompValue.min,
            }),
          },
          max: {
            value: validationRangeRules.diffFromCompValue.max,
            message: t('form.validation-generic.max', {
              max: validationRangeRules.diffFromCompValue.max,
            }),
          },
        }}
        render={({ field, fieldState }) => (
          <>
            <Stack sx={rowStyles}>
              <FirstColumnCell
                cellContent={{
                  text: t('modals.price-settings.parameters.diff-from-competition-currency.label'),
                  tooltip: t('form.valueDifferenceFromCompetition.tooltip.__html'),
                }}
              />

              <CellCheckbox
                control={control}
                disabled={
                  props.disabled ||
                  (useOfferValueDifferenceFromCompetitionValue === null && isSingle)
                }
                name={'repricerSettings.useDefaultValueDifferenceFromCompetition'}
                label={getValue(
                  data?.defaultPriceSettings?.repricerSettings.valueDifferenceFromCompetition,
                  marketCurrency,
                )}
              />
              <Stack sx={{ justifyContent: 'center', p: 1 }}>
                <TextFieldNumber
                  disabled={
                    props.disabled ||
                    (field.value !== null && useDefaultValueDifferenceFromCompetitionValue)
                  }
                  onChange={(value) => {
                    handleOfferValue(
                      value === null,
                      'repricerSettings.useDefaultValueDifferenceFromCompetition',
                    );
                    field.onChange(value);
                  }}
                  onBlur={field.onBlur}
                  value={field.value}
                  unit={marketCurrency}
                  precision={2}
                  error={!!fieldState.error}
                  tooltipError={fieldState.error?.message}
                />
              </Stack>
            </Stack>
          </>
        )}
      />
    </>
  );
};
