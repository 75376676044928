import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const BigFailIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8724_23675)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2C7.8203 2 2 7.8203 2 15C2 22.1797 7.8203 28 15 28C22.1797 28 28 22.1797 28 15C28 7.8203 22.1797 2 15 2ZM0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z"
          fill="#FF515C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2929 10.2929C10.6834 9.90237 11.3166 9.90237 11.7071 10.2929L15 13.5858L18.2929 10.2929C18.6834 9.90237 19.3166 9.90237 19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071L16.4142 15L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L15 16.4142L11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071C9.90237 19.3166 9.90237 18.6834 10.2929 18.2929L13.5858 15L10.2929 11.7071C9.90237 11.3166 9.90237 10.6834 10.2929 10.2929Z"
          fill="#FF515C"
        />
      </g>
      <defs>
        <clipPath id="clip0_8724_23675">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
