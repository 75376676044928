import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormAlert, FormButtonsGroup, Show } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { Alert, Button } from '@mui/material';
import { ClaimDefenderComponent } from '../../../authorization';
import { useCarriersShippingCountriesQuery } from '../../hooks';
import { useCarriersShippingCountriesMutation } from '../../hooks/use-carriers-shipping-countries.mutation';
import { CarriersShippingInputType } from '../../types';
import { FieldCarrierDefault, ListCarriersOther, ListCountiresNotIntegrated } from './components';

export type ShippingCountryFormProps = CarriersShippingInputType;

export const SectionShippingCountry: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('settings');
  const { data } = useCarriersShippingCountriesQuery();
  const countriesMutation = useCarriersShippingCountriesMutation();

  const form = useForm<ShippingCountryFormProps>({
    defaultValues: {
      defaultCarrierId: data?.defaultCarrierId,
      shippingCountries:
        data?.shippingCountries.filter((x) => x.carrierId !== data?.defaultCarrierId) || [],
      notIntegratedCountries: [],
    },
  });

  const submit = (data: ShippingCountryFormProps) => {
    return countriesMutation.mutate(data, {
      onSuccess: () => {
        form.reset(data);
      },
    });
  };

  const hasAtLeastTwoCarriers = useMemo<boolean>(
    () => (data && data?.shippingCountries.length > 0) || false,
    [data?.shippingCountries],
  );

  const isLoading = countriesMutation.isLoading;
  const isDone = countriesMutation.isSuccess || countriesMutation.isError;
  const isSuccess = countriesMutation.isSuccess;
  const isError = countriesMutation.isError;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <FieldCarrierDefault />
        <Show
          when={hasAtLeastTwoCarriers}
          fallback={
            <Alert color="info" sx={{ mt: 2 }}>
              {t('carriers.shipping-country.explanation')}
            </Alert>
          }
        >
          <ListCarriersOther />
          {/*TODO: Delete <Show /> when integration will be ready on manager side */}
          <Show when={false}>
            <ListCountiresNotIntegrated />
          </Show>
        </Show>
        <FormAlert
          show={isDone}
          isSuccess={isSuccess}
          isError={isError}
          successMessage={t('carriers.shipping-country.success')}
          errorMessage={t('carriers.shipping-country.error.general')}
        />
        <ClaimDefenderComponent hidden allowed={'SETTINGS_CARRIERS_SHIPPING_COUNTRIES_EDIT'}>
          <Show when={hasAtLeastTwoCarriers}>
            <FormButtonsGroup sx={{ mt: 2 }} isLoading={isLoading}>
              <Button type="button" size="small" variant="text" disabled={isLoading}>
                {tCommon('cancel')}
              </Button>
              <Button type="submit" size="small" disabled={isLoading}>
                {tCommon('save')}
              </Button>
            </FormButtonsGroup>
          </Show>
        </ClaimDefenderComponent>
      </form>
    </FormProvider>
  );
};
