import React from 'react';
import { Box, Stack } from '@mui/material';

type Props = React.PropsWithChildren<{
  number: number;
}>;

export const SummarySection: React.FC<Props> = (props) => {
  return (
    <Stack direction="row" alignItems="flex-start" gap="18px" sx={{ mb: 2, width: '100%' }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '36px',
          height: '36px',
          borderRadius: '50%',
          borderWidth: '3px',
          borderStyle: 'solid',
          borderColor: 'primary.main',
          fontSize: '13px',
          fontWeight: '700',
        }}
      >
        {props.number}
      </Stack>
      <Box>{props.children}</Box>
    </Stack>
  );
};
