/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { DynamicPricingModel, PriceManagementMode, PriceValidationStatus } from 'generated/graphql';
import { Icon, List, ListItem, Stack, Typography } from '@mui/material';

type Props = {
  mode: PriceManagementMode;
  pricingModel: DynamicPricingModel;
  repricingDetails?: PriceValidationStatus[];
};

export const DataGridRepricingInfoCell: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');

  return (
    <Show when={props.mode !== null && props.mode !== PriceManagementMode.Unknown}>
      <Stack>
        <Typography variant="h5">
          {t(`offer-priceManagment.priceManagementMode.${props.mode}`)}
        </Typography>

        <Show
          when={
            props.pricingModel !== DynamicPricingModel.Unknown &&
            (!props.repricingDetails || props.repricingDetails[0] === PriceValidationStatus.Valid)
          }
        >
          <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
            <img src="/images/icons/stroke.svg" />
            <Typography sx={{ color: '#449933', fontSize: 13 }}>
              {t(`offer-priceManagment.dynamicPricingModels.${props.pricingModel}`)}
            </Typography>
          </Stack>
        </Show>

        <Show
          when={
            props.repricingDetails != null &&
            props.repricingDetails[0] !== PriceValidationStatus.Valid
          }
        >
          <List sx={{ p: 0 }}>
            {props.repricingDetails != null &&
              props.repricingDetails.map((detail) => (
                <ListItem key={detail} sx={{ p: 0, color: '#FF515C' }}>
                  <Stack flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                    <img src="/images/icons/error.svg" />
                    {t(`offer-priceManagment.offerRepricingDetails.${detail}`)}
                  </Stack>
                </ListItem>
              ))}
          </List>
        </Show>
      </Stack>
    </Show>
  );
};
