import { useContext } from 'react';
import { DatagridViewsContext } from '../contexts';

export const useDatagridViewContext = () => {
  const context = useContext(DatagridViewsContext);

  return {
    tableId: context.tableId,
    currentView: context.view,
    changeView: context.changeView,
    resetView: context.resetView,
    createViewModal: context.createViewModal,
    renameViewModal: context.renameViewModal,
    deleteViewModal: context.deleteViewModal,
    manageSharingViewModal: context.manageSharingViewModal,
  };
};
