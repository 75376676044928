import { useTranslation } from 'react-i18next';
import { parseFloatValueWithUnit } from 'common/utils';
import { CurrencyCode } from 'modules/shared/types';

export const percent = (value?: number | null) => {
  return floatWithSymbol(value);
};

export const currency = (value?: number | null, currency?: CurrencyCode) => {
  return floatWithSymbol(value, currency);
};

export const floatWithSymbol = (value?: number | null, currency?: CurrencyCode) => {
  const [t] = useTranslation('currencies');
  const unit = () => {
    if (currency) return t(`${currency}.short` as 'EUR.short');
    if (!currency && (value || value === 0)) return '%';
    return '-';
  };

  if (value === null) return '- ' + unit();

  if (value === 0) {
    return `${value.toFixed(2)} ${unit()}`;
  }

  return parseFloatValueWithUnit(value || '-', 2, unit());
};
