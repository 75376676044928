import { useQuery, UseQueryResult } from 'react-query';
import {
  ChannelCode,
  PlatformCode,
  SharedOfferStateEnum,
  SharedOfferSubStateEnum,
} from 'generated/graphql';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { useOfferCoordinatorContext } from './use-offer-coordinator-context';

export type OfferToPauseOrActivate = {
  offerId: string;
  platformCode: PlatformCode;
  channelCode: ChannelCode;
  marketplaceCode: string;
  ean: string;
  sku: string;
  platformListingId: string;
  marketplacePrice: number | undefined;
  productQuantity: number | undefined;
  platformQuantity: number | undefined;
  state: number;
  stateName: SharedOfferStateEnum;
  subStates: number[];
  subStatesNames: SharedOfferSubStateEnum[];
  title: string;
  url: string;
  mainImage: string;
};

export const useGetOffersToPauseOrActivateQuery = (): UseQueryResult<OfferToPauseOrActivate[]> => {
  const { authorized, currentPartnerId } = useAuth();
  const {
    pauseOffer: [offer],
  } = useOfferCoordinatorContext();

  return useQuery<OfferToPauseOrActivate[]>(
    [],
    async () => {
      const response = await httpClient.get<ResponseAPIType<OfferToPauseOrActivate[]>>(
        API.offers(currentPartnerId).offersBySku(encodeURIComponent(offer?.[0].sku || '')),
      );
      return response.data.data;
    },
    {
      staleTime: 0,
      keepPreviousData: false,
      enabled: authorized,
      retry: 0,
    },
  );
};
