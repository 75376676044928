import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ArrowDownIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3 14.29C17.91 13.9 17.28 13.9 16.89 14.29L13 18.17V3C13 2.45 12.55 2 12 2C11.45 2 11 2.45 11 3V18.18L7.12 14.3C6.73 13.91 6.1 13.91 5.71 14.3C5.32 14.69 5.32 15.32 5.71 15.71L11.3 21.3C11.69 21.69 12.32 21.69 12.71 21.3L18.3 15.71C18.68 15.32 18.68 14.68 18.3 14.29Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
