import { SkeletonClasses, SkeletonProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { nethansaPalette } from '../pallete';

export const skeletonStyles: {
  defaultProps?: Partial<SkeletonProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof SkeletonClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      backgroundColor: nethansaPalette.button.grayLight,
    },
  },
};
