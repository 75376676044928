import React from 'react';

type Props = React.PropsWithChildren<{
  when: boolean;
  fallback?: JSX.Element | string;
}>;

export const Show: React.FC<Props> = (props) => {
  return (
    <>
      {!props.when && (props.fallback || null)}
      {props.when && props.children}
    </>
  );
};
