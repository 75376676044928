import React from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Collapse, Stack } from '@mui/material';
import { sortPrimitives } from '../../../../../../common/utils';
import { ShippingCountryType } from '../../../../types';
import { ShippingCountryFormProps } from '../../section-shipping-country';
import { OtherCarrierField } from './components/other-carrier-field';

export const ListCarriersOther: React.FC = () => {
  const { control } = useFormContext<ShippingCountryFormProps>();

  const { fields } = useFieldArray({
    control,
    name: 'shippingCountries',
  });

  const defaultCarrierIdValue = useWatch({
    control,
    name: 'defaultCarrierId',
  });

  return (
    <Collapse in={!!defaultCarrierIdValue} timeout="auto" unmountOnExit>
      <Stack sx={{ mt: 2 }}>
        {fields.map((field, index) => {
          return (
            <Controller
              key={field.id}
              name={`shippingCountries.${index}`}
              render={({ field }) => {
                const fieldValue = field.value as ShippingCountryType;
                return (
                  <OtherCarrierField
                    name={fieldValue.carrierName}
                    countryCodes={fieldValue.countryCodes}
                    addCountry={(countryCode) => {
                      field.onChange({
                        ...fieldValue,
                        countryCodes: [...fieldValue.countryCodes, countryCode].sort(
                          sortPrimitives,
                        ),
                      });
                    }}
                    removeCountry={(countryCode) => {
                      field.onChange({
                        ...fieldValue,
                        countryCodes: [...fieldValue.countryCodes]
                          .filter((x) => x !== countryCode)
                          .sort(sortPrimitives),
                      });
                    }}
                  />
                );
              }}
            />
          );
        })}
      </Stack>
    </Collapse>
  );
};
