import { useQuery, UseQueryResult } from 'react-query';
import { ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { BillingDataType } from '../types';

type BillingDataResponse = BillingDataType;

export const useBillingDataQuery = (): UseQueryResult<BillingDataResponse> => {
  const { authorized, currentPartnerId } = useAuth();

  return useQuery<BillingDataResponse>(
    ['settings', 'billing-data', currentPartnerId],
    async () => {
      const response = await httpClient.get<ResponseAPIType<BillingDataResponse>>(
        API.billings().billingData(currentPartnerId),
      );

      return {
        ...response.data.data,
      };
    },

    {
      staleTime: 55 * (60 * 1000),
      cacheTime: 60 * (60 * 1000),
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
