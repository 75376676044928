import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isActiveLocation } from 'common/utils';

export const useActiveLocation = (href: string, exact?: boolean) => {
  const location = useLocation();
  const [active, setActive] = useState(isActiveLocation(href, exact));

  useEffect(() => {
    setActive(isActiveLocation(href, exact));
  }, [location.pathname]);

  return active;
};
