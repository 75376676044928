import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Show, TextFieldNumber, TooltipWrapper } from 'components';
import { WarningIcon } from 'components/icons';
import { usePriceSettingsQuery } from 'modules/offers/hooks';
import { useCurrenciesTranslations } from 'modules/shared';
import { CurrencyCode } from 'modules/shared/types';
import { nethansaPalette } from 'theme';
import { Box, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { CellCheckbox } from '../../components';

type Props = {
  disabled: boolean;
};

export const PriceManagementTab: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');
  const [tCurrency] = useCurrenciesTranslations();

  const { control, trigger } = useFormContext();
  const { data } = usePriceSettingsQuery();

  const validationRangeRules = {
    priceFixed: {
      min: 0.1,
      max: 999999,
    },
  };

  const isSingle = data?.isSingleOffer || false;

  const marketCurrency = isSingle
    ? tCurrency(`${(data?.offerPriceSettingsParams?.currencyCode as CurrencyCode) || 'EUR'}.short`)
    : '$$';

  const useOfferFixedMinimumPriceValue = useWatch({
    control,
    name: 'fixedPricesSettings.fixedMinimumPrice',
  });

  const usePriceManagementModeValue = useWatch({
    control,
    name: 'repricerSettings.priceManagementMode',
  });

  const useOfferFixedPriceValue = useWatch({
    control,
    name: 'fixedPricesSettings.fixedPrice',
  });

  const shouldValidateFixedPrice =
    Number(usePriceManagementModeValue) === 2 && useOfferFixedPriceValue === null;

  const isProductCostMissing =
    isSingle &&
    (data?.offerPriceSettings.parametersSettings.productCostInMarketplaceCurrency.value === 0 ||
      data?.offerPriceSettings.parametersSettings.productCostInMarketplaceCurrency.value === null);

  const radioDescriptionStyles = {
    pl: 5.25,
    pr: 2,
    mt: -1,
    mb: 2,
    width: 0,
    minWidth: '100%',
  };

  return (
    <>
      <Box sx={{ backgroundColor: nethansaPalette.background.title }}>
        <Controller
          control={control}
          name="repricerSettings.priceManagementMode"
          render={({ field }) => {
            return (
              <RadioGroup
                onChange={(e) => {
                  field.onChange(e);
                  if (isSingle) trigger();
                }}
                aria-labelledby="pricing-management-mode-radio-buttons-group-label"
                value={Number(field.value)}
                name="use-radio-group"
              >
                <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label={
                    <Typography variant="h2">
                      {t('modals.price-settings.price-manag=tab.dynamic-pricing.label')}
                      <Show when={isProductCostMissing && useOfferFixedMinimumPriceValue === null}>
                        <TooltipWrapper
                          content={t(
                            'modals.price-settings.price-manag=tab.dynamic-pricing.warning',
                          )}
                          placement="top"
                        >
                          <Box component="span" sx={{ ml: 0.5, verticalAlign: 'middle' }}>
                            <WarningIcon
                              sx={{ height: '17px', color: nethansaPalette.status.opBefore.dark }}
                            />
                          </Box>
                        </TooltipWrapper>
                      </Show>
                    </Typography>
                  }
                />
                <Box sx={radioDescriptionStyles}>
                  <Typography variant="h5" sx={{ lineHeight: '18px' }}>
                    {t('modals.price-settings.price-manag=tab.dynamic-pricing.description')}
                  </Typography>
                </Box>
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={
                    <Typography variant="h2">
                      {t('modals.price-settings.price-manag=tab.fixed-price.label')}
                    </Typography>
                  }
                />
                <Box sx={radioDescriptionStyles}>
                  <Typography
                    variant="h5"
                    sx={{
                      lineHeight: '18px',
                      '& > span': { color: nethansaPalette.main.watermelon },
                    }}
                  >
                    {t('modals.price-settings.price-manag=tab.fixed-price.description')}
                  </Typography>

                  <Controller
                    control={control}
                    name={'fixedPricesSettings.fixedPrice'}
                    rules={{
                      required: {
                        value: shouldValidateFixedPrice,
                        message: t('modals.price-settings.price-manag=tab.fixed-price.warning'),
                      },
                      min: {
                        value: validationRangeRules.priceFixed.min,
                        message: t('form.validation-generic.min', {
                          min: validationRangeRules.priceFixed.min,
                        }),
                      },
                      max: {
                        value: validationRangeRules.priceFixed.max,
                        message: t('form.validation-generic.max', {
                          max: validationRangeRules.priceFixed.max,
                        }),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <Stack sx={{ flexDirection: 'row', pt: 1, gap: 4 }}>
                          <TextFieldNumber
                            disabled={props.disabled}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            value={field.value}
                            unit={marketCurrency}
                            precision={2}
                            error={!!fieldState.error}
                            tooltipError={fieldState.error?.message}
                            sx={{ width: '96px' }}
                          />
                          <Show when={!isSingle}>
                            <CellCheckbox
                              control={control}
                              disabled={props.disabled}
                              name={'fixedPricesSettings.removeFixedPrice'}
                              label={t(
                                'modals.price-settings.price-manag=tab.fixed-price.remove-price',
                              )}
                            />
                          </Show>
                        </Stack>
                      </>
                    )}
                  />
                </Box>
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={
                    <Typography variant="h2">
                      {t('modals.price-settings.price-manag=tab.off.label')}
                    </Typography>
                  }
                />
                <Box sx={radioDescriptionStyles}>
                  <Typography variant="h5" sx={{ lineHeight: '18px' }}>
                    {t('modals.price-settings.price-manag=tab.off.description')}
                  </Typography>
                </Box>

                <Show when={!isSingle}>
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={
                      <Typography variant="h2">
                        {t('modals.price-settings.price-manag=tab.no-change.label')}
                      </Typography>
                    }
                  />
                  <Box sx={radioDescriptionStyles}>
                    <Typography variant="h5" sx={{ lineHeight: '18px' }}>
                      {t('modals.price-settings.price-manag=tab.no-change.description')}
                    </Typography>
                  </Box>
                </Show>
              </RadioGroup>
            );
          }}
        />
      </Box>
    </>
  );
};
