import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = React.PropsWithChildren<{
  label: string;
  roundedCardTop?: boolean;
}>;

export const CardWithLabel: React.FC<Props> = (props) => {
  const borderRadiusStyles: SxProps<Theme> = {
    borderRadius: '6px',
    borderTopLeftRadius: props.roundedCardTop ? '6px' : '0px',
    borderTopRightRadius: props.roundedCardTop ? '6px' : '0px',
  };

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontSize: '12px',
          textTransform: 'uppercase',
          color: 'nethansa.main.gray',
          ml: 2,
          mb: 0.5,
        }}
      >
        {props.label}
      </Typography>
      <Box
        sx={{
          backgroundColor: 'nethansa.background.title',
          py: '12px',
          px: '16px',
          ...borderRadiusStyles,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
