import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabsItem, NavigationTabsLayout } from 'components/navigation-tabs';
import { routes } from 'routes';

export const BillingDataSettingsLayout: React.FC = () => {
  const [t] = useTranslation('settings');

  return (
    <NavigationTabsLayout>
      <NavigationTabsItem
        href={routes.Settings.children.PaymentsAndBillings.children.Payments.link()}
        children={t('configuration.payments')}
      />
      <NavigationTabsItem
        href={routes.Settings.children.PaymentsAndBillings.children.BillingData.link()}
        children={t('tabs.billing-data')}
      />
    </NavigationTabsLayout>
  );
};
