import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertLoader, Dialog, FormButtonsGroup, KauflandLogo } from 'components';
import { useCommonTranslations } from 'modules/shared';
import { nethansaPalette } from 'theme';
import { Alert, Box, Button, DialogContent, Stack, TextField, Typography } from '@mui/material';
import { PlatformConnectorHeader } from '../components';
import {
  usePlatformConnectionMutation,
  usePlatformQuery,
  usePlatformsSettingsQuery,
  usePlatformIsActiveMutation,
} from '../hooks';

type FormProps = {
  clientKey: string;
  secretKey: string;
};

type Props = {
  onClose: () => void;
};

export const KauflandConnectorModal: React.FC<Props> = (props) => {
  const { tCommon } = useCommonTranslations();
  const [t] = useTranslation('platforms');
  const platformConnectMutation = usePlatformConnectionMutation('kauf');
  const platformData = usePlatformsSettingsQuery();
  const platformQuery = usePlatformQuery('KAUF');
  const platformIsActiveMutation = usePlatformIsActiveMutation();

  const { control, handleSubmit } = useForm<FormProps>({
    defaultValues: {
      clientKey: platformData.data?.find((x) => x.platformCode === 'KAUF')?.clientKey || '',
      secretKey: '',
    },
  });

  const submit = (values: FormProps) => {
    platformConnectMutation.mutate({
      clientKey: values.clientKey,
      secretKey: values.secretKey,
    });
    if (platformQuery.data?.platformId) {
      platformIsActiveMutation.mutate({
        platformId: platformQuery.data?.platformId,
        isActive: true,
      });
    }
  };

  const handleClose = () => {
    props.onClose();
    platformData.refetch();
    platformQuery.refetch();
  };

  return (
    <Dialog
      open
      onClose={props.onClose}
      sx={{
        '& .MuiDialogContent-root': {
          maxWidth: 'unset',
          minWidth: 'unset',
          width: '750px',
        },
      }}
    >
      <DialogContent>
        <form onSubmit={handleSubmit(submit)}>
          <Box sx={{ p: 4 }}>
            <PlatformConnectorHeader logo={<KauflandLogo />} close={props.onClose} />
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ mb: 3 }} variant="h2">
                {t('kaufland.title')}
              </Typography>
              <Alert severity="info" sx={{ mb: 3 }}>
                {t('kaufland.subtitle.leftSide')}
                <a
                  style={{ margin: '0 3.5px' }}
                  href={t('kaufland.subtitle.linkHref')}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('kaufland.subtitle.linkText')}
                </a>
                {t('kaufland.subtitle.rightSide')}
              </Alert>
            </Box>
            <Stack gap="30px" sx={{ mt: 6 }}>
              <Controller
                control={control}
                name="clientKey"
                rules={{
                  required: {
                    value: true,
                    message: t('kaufland.form.clientKey.validation.required'),
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      disabled={
                        platformConnectMutation.isSuccess ||
                        platformConnectMutation.isLoading ||
                        platformData.isLoading
                      }
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      label={t('kaufland.form.clientKey.label')}
                      placeholder={t('kaufland.form.clientKey.placeholder')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      sx={{ mb: 2, flex: 1 }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="secretKey"
                rules={{
                  required: {
                    value: true,
                    message: t('kaufland.form.secretKey.validation.required'),
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      disabled={
                        platformConnectMutation.isSuccess ||
                        platformConnectMutation.isLoading ||
                        platformData.isLoading
                      }
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      label={t('kaufland.form.secretKey.label')}
                      placeholder={t('kaufland.form.secretKey.placeholder')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      sx={{ flex: 2.5 }}
                    />
                  );
                }}
              />
            </Stack>
          </Box>
          <Box sx={{ px: 4, pb: 2 }}>
            {platformConnectMutation.isSuccess && (
              <Alert severity="success">{t('kaufland.integrate.success')}</Alert>
            )}
            {platformConnectMutation.isLoading && (
              <AlertLoader>{t('kaufland.integrate.loading')}</AlertLoader>
            )}
            {platformConnectMutation.isError && (
              <Alert severity="error">{t('kaufland.integrate.error.general')}</Alert>
            )}
          </Box>
          <FormButtonsGroup
            isLoading={platformConnectMutation.isLoading}
            sx={{ py: 2, px: 4, borderTop: `1px solid ${nethansaPalette.line.light}` }}
          >
            {!platformConnectMutation.isSuccess && (
              <>
                <Button
                  variant="text"
                  size="small"
                  onClick={props.onClose}
                  disabled={platformConnectMutation.isLoading || platformData.isLoading}
                >
                  {tCommon('cancel')}
                </Button>
                <Button
                  size="small"
                  type="submit"
                  disabled={platformConnectMutation.isLoading || platformData.isLoading}
                >
                  {tCommon('connect')}
                </Button>
              </>
            )}
            {platformConnectMutation.isSuccess && (
              <Button
                size="small"
                type="button"
                onClick={handleClose}
                disabled={platformConnectMutation.isLoading || platformData.isLoading}
              >
                {tCommon('ok')}
              </Button>
            )}
          </FormButtonsGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
};
