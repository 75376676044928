import React, { useState } from 'react';
import { Dialog } from 'components';
import { PlatformCode } from 'modules/shared/types';
import { DialogContent } from '@mui/material';
import { AmazonConnector } from './components/amazon-connector';
import { KauflandConnector } from './components/kaufland-connector';
import { PlatformsConnectorContext } from './contexts/platfroms-modal.provider';

type Props = {
  open?: boolean;
  keepCloseButton?: boolean;
  onClose: () => void;
  provider?: PlatformCode;
};

export const WizardPlatformsConnectorModal: React.FC<Props> = (props) => {
  const [step, setStep] = useState(0);

  const viewByProvider: { [key in PlatformCode]: JSX.Element } = {
    amzeu: <AmazonConnector />,
    kauf: <KauflandConnector />,
  };

  return (
    <PlatformsConnectorContext.Provider
      value={{ close: props.onClose, step, setStep, keepCloseButton: !!props.keepCloseButton }}
    >
      <Dialog
        open={!!props.open}
        sx={{
          backdropFilter: 'blur(2px)',
          '& .MuiDialogContent-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: '750px',
          },
        }}
      >
        <DialogContent>{props.provider ? viewByProvider[props.provider] : null}</DialogContent>
      </Dialog>
    </PlatformsConnectorContext.Provider>
  );
};
