import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogBaseWrapper } from '../../../../components';
import { useOfferASINQuery, useOfferCoordinatorContext } from '../../hooks';
import { EditASINForm, EditASINFormSkeleton } from './components';

export const ModalOfferEditAsin: React.FC = () => {
  const { isFetched } = useOfferASINQuery();
  const { t } = useTranslation('offers');

  const {
    editAsin: [, setEditAsinData],
  } = useOfferCoordinatorContext();

  return (
    <DialogBaseWrapper
      open
      header={t('modals.edit-asin-modal.header')}
      onClose={() => setEditAsinData(undefined)}
      disableCloseOnBackdropClick
    >
      {isFetched && <EditASINForm />}
      {!isFetched && <EditASINFormSkeleton />}
    </DialogBaseWrapper>
  );
};
