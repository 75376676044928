import { useMutation, UseMutationResult } from 'react-query';
import { ErrorMeta } from 'modules/shared/types';
import { AnimusActionResponse } from 'modules/shared/types/animus-action-response';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';

export const useChangeSkuMutation = (): UseMutationResult<
  AnimusActionResponse,
  ErrorMeta,
  { offerId: string; selectedOfferIds: string[]; newSku: string }
> => {
  const { currentPartnerId } = useAuth();

  return useMutation(async (data) => {
    return (
      await httpClient.post<AnimusActionResponse>(
        API.offers(currentPartnerId).changeSku(data.offerId),
        { newSku: data.newSku, offerIds: data.selectedOfferIds },
      )
    ).data;
  });
};
