import { getGridSingleSelectOperators, GridFilterOperator } from '@mui/x-data-grid-pro';
import { CountryCheckboxListOperator } from '../components';

export const useDatagridCountryListOperators = (): GridFilterOperator[] => {
  const selectOperators: GridFilterOperator = {
    ...getGridSingleSelectOperators()[2],
    InputComponent: CountryCheckboxListOperator,
  };

  return [selectOperators];
};
