import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormAlert, FormButtonsGroup, SelectField, TextFieldPhone } from 'components';
import { useCommonTranslations, useCountriesTranslations } from 'modules/shared';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { ClaimDefenderComponent } from '../../../authorization';
import { useShipFromAddressMutation, useShipFromAddressQuery } from './hooks';
import { ShipFromAddressType } from './types';

type FormProps = Omit<ShipFromAddressType, 'countries' | 'vatId'>;

export const ShipFromAddressForm: React.FC = () => {
  const { data, isLoading, refetch } = useShipFromAddressQuery();
  const [t] = useTranslation('settings');
  const [tCountries] = useCountriesTranslations();
  const { tCommon } = useCommonTranslations();
  const shipFromAddressMutation = useShipFromAddressMutation();

  const form = useForm<FormProps>({
    defaultValues: {
      id: data?.id,
      companyName: data?.companyName || '',
      street: data?.street || '',
      house: data?.house || '',
      zipCode: data?.zipCode || '',
      city: data?.city || '',
      country: data?.country || 'PL',
      phone: data?.phone || '',
      email: data?.email || '',
    },
  });

  const submit = (data: FormProps) => {
    shipFromAddressMutation.mutate(data, {
      onSuccess: async () => {
        const response = await refetch();
        form.reset({ ...data, id: response?.data?.id || 0 });
      },
      onError: (error) => {
        Object.entries<string[]>(error.errors).forEach(([key, errors]) => {
          errors.forEach((errorKey) => {
            form.setError(key as keyof FormProps, {
              type: 'Validation Error',
              message: t(
                `form.${key}.validation.${errorKey}` as 'form.companyName.validation.required',
              ),
            });
          });
        });
      },
    });
  };

  const disabled = isLoading || shipFromAddressMutation.isLoading;

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <Box sx={{ pb: 2 }}>
        <Typography variant="h5" sx={{ lineHeight: '18px' }}>
          {t('ship-from-address.description')}
        </Typography>
      </Box>
      <Stack gap="30px" sx={{ mt: 3 }}>
        <Controller
          control={form.control}
          name="companyName"
          rules={{
            required: {
              value: true,
              message: t('form.companyName.validation.required'),
            },
            maxLength: {
              value: 100,
              message: t('form.companyName.validation.maxLengthExceeded', { length: 100 }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                autoComplete="companyName"
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.companyName.label')}
                placeholder=""
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={form.control}
          name="street"
          rules={{
            required: {
              value: true,
              message: t('form.street.validation.required'),
            },
            maxLength: {
              value: 100,
              message: t('form.street.validation.maxLengthExceeded', { length: 100 }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                autoComplete="street"
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.street.label')}
                placeholder=""
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Controller
          control={form.control}
          name="house"
          rules={{
            required: {
              value: true,
              message: t('form.house.validation.required'),
            },
            maxLength: {
              value: 100,
              message: t('form.house.validation.maxLengthExceeded', { length: 100 }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                autoComplete="house"
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.house.label')}
                placeholder=""
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Stack direction="row" gap="10px">
          <Controller
            control={form.control}
            name="zipCode"
            rules={{
              required: {
                value: true,
                message: t('form.zipCode.validation.required'),
              },
              maxLength: {
                value: 10,
                message: t('form.zipCode.validation.maxLengthExceeded', { length: 10 }),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  autoComplete="zipCode"
                  disabled={disabled}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label={t('form.zipCode.label')}
                  placeholder=""
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 2, width: '132px' }}
                />
              );
            }}
          />
          <Controller
            control={form.control}
            name="city"
            rules={{
              required: {
                value: true,
                message: t('form.city.validation.required'),
              },
              maxLength: {
                value: 60,
                message: t('form.city.validation.maxLengthExceeded', { length: 60 }),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  autoComplete="city"
                  disabled={disabled}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label={t('form.city.label')}
                  placeholder=""
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 2, flex: 1 }}
                />
              );
            }}
          />
        </Stack>
        <Controller
          control={form.control}
          name="country"
          rules={{
            required: {
              value: true,
              message: t('form.country.validation.required'),
            },
            maxLength: {
              value: 50,
              message: t('form.country.validation.maxLengthExceeded', { length: 50 }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <SelectField
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.country.label')}
                placeholder={t('form.country.placeholder')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1, m: 0 }}
                options={
                  data?.countries
                    .map((x) => ({
                      value: x,
                      children: tCountries(x),
                    }))
                    .sort((a, b) => a.children.localeCompare(b.children)) || []
                }
              />
            );
          }}
        />
        <Controller
          control={form.control}
          name="phone"
          rules={{
            required: {
              value: true,
              message: t('form.phone.validation.required'),
            },
            minLength: {
              value: 7,
              message: t('form.phone.validation.minLengthNotExceeded', { length: 7 }),
            },
            maxLength: {
              value: 20,
              message: t('form.phone.validation.maxLengthExceeded', { length: 20 }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextFieldPhone
                country="pl"
                regions="europe"
                value={field.value}
                placeholder={''}
                disabled={disabled}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputProps={{ autoComplete: 'phone' }}
              />
            );
          }}
        />
        <Controller
          control={form.control}
          name="email"
          rules={{
            required: {
              value: true,
              message: t('form.email.validation.required'),
            },
            maxLength: {
              value: 60,
              message: t('form.email.validation.maxLengthExceeded', { length: 60 }),
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t('form.email.validation.incorrectFormatOfEmail'),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                autoComplete="email"
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.email.labelRequired')}
                placeholder=""
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 3, flex: 1 }}
              />
            );
          }}
        />
      </Stack>
      <ClaimDefenderComponent hidden allowed={'SETTINGS_CARRIERS_SHIP_FORM_ADDRESS_EDIT'}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h5">{t('form.mandatory-field')}</Typography>
        </Box>
        <FormAlert
          show={shipFromAddressMutation.isSuccess || shipFromAddressMutation.isError}
          isSuccess={shipFromAddressMutation.isSuccess}
          isError={shipFromAddressMutation.isError}
          successMessage={t('ship-from-address.success')}
          errorMessage={t('ship-from-address.error')}
        />
        <FormButtonsGroup sx={{ mt: 1 }} isLoading={disabled}>
          <Button
            variant="text"
            color="secondary"
            size="small"
            disabled={disabled}
            onClick={() => {
              form.reset();
            }}
          >
            {tCommon('cancel')}
          </Button>
          <Button size="small" type="submit" disabled={disabled}>
            {tCommon('update')}
          </Button>
        </FormButtonsGroup>
      </ClaimDefenderComponent>
    </form>
  );
};
