import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'common/query-client';
import { Checkbox, DialogConfirmation, Show, TextFieldNumber } from 'components';
import { useProductsCoordinatorContext } from 'modules/products/hooks';
import { useSnackbar } from 'modules/snackbar';
import { nethansaPalette } from 'theme';
import { Alert, Stack, Typography } from '@mui/material';
import { Query } from '../../../../generated/graphql';
import { useCurrenciesTranslations } from '../../../shared';
import { CurrencyCode } from '../../../shared/types';
import { useBulkProductCostMutation } from '../../hooks';
import { SelectedProductDetails } from '../components/selected-product-details';
import { SelectedProducts } from '../components/selected-products';

export const EditProductCostModal: React.FC = () => {
  const {
    editProductCost: [actionParams, setActionParams],
  } = useProductsCoordinatorContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);
  const { pushSnackbar } = useSnackbar();
  const [isRemoveProductCost, setIsRemoveProductCost] = useState<boolean>(false);
  const useAmazonBulkAction = useBulkProductCostMutation();
  const [t] = useTranslation('products');
  const [tCurrency] = useCurrenciesTranslations();
  const productCostInputRef = React.useRef<HTMLInputElement>(null);
  const refreshProducts = () => queryClient.refetchQueries({ active: true, queryKey: 'products' });
  const closeModal = () => setActionParams(undefined);

  const minProductCost = 0.01;
  const maxProductCost = 99999;

  const validationError = t('modals.editProductCost.validation.minmax', {
    min: minProductCost,
    max: maxProductCost,
  });

  const marketCurrency =
    actionParams?.row != null
      ? tCurrency(`${(actionParams?.row.baseCurrencyCode as CurrencyCode) || 'EUR'}.short`)
      : '$$';

  const handleResponse = (data: Query) => {
    const errors = data.updateProductCost?.errors;
    if (!errors || errors.length == 0) {
      refreshProducts();
      actionParams?.onSuccess && actionParams?.onSuccess();
      closeModal();
      pushSnackbar({
        variant: 'success',
        message: t('modals.editProductCost.messages.success'),
      });
    } else setErrorMessage(t('modals.editProductCost.messages.error'));
  };

  const verifyValue = (value: number | null): boolean => {
    if (!value) {
      setValidationMessage(validationError);
      return false;
    }

    if (isNaN(value) || value < minProductCost || value > maxProductCost) {
      setValidationMessage(validationError);
      return false;
    }

    setValidationMessage(null);
    return true;
  };

  const confirm = (confirmed: boolean) => {
    if (!confirmed) {
      closeModal();
      return;
    }

    let newProductCost: number | undefined = undefined;
    if (!isRemoveProductCost) {
      const valueInput = productCostInputRef.current?.value;
      if (valueInput == undefined) setValidationMessage(validationError);

      newProductCost = Number(valueInput);
      if (!verifyValue(newProductCost)) return;
    }

    setValidationMessage(null);
    useAmazonBulkAction.mutate(
      {
        newProductCost: newProductCost,
        where: actionParams?.filters,
      },
      {
        onSuccess: (data) => handleResponse(data),
        onError: () => setErrorMessage(t('modals.editProductCost.messages.error')),
      },
    );
  };

  return (
    <DialogConfirmation
      isLoading={useAmazonBulkAction.isLoading}
      confirmLabel={t('modals.buttons.save')}
      open
      onClose={confirm}
      header={t('modals.editProductCost.header')}
      tooltip={t('modals.editProductCost.tooltip')}
      description=""
    >
      <Stack sx={{ width: '540px', mx: 4, mb: 4, gap: 2, alignItems: 'flex-start' }}>
        <Show when={actionParams?.row != null}>
          <SelectedProductDetails row={actionParams?.row} />
        </Show>
        <Show when={actionParams?.row == null}>
          <SelectedProducts productsNumber={actionParams?.selectedCount} />
        </Show>
        <Show when={errorMessage != null}>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Show>

        <Stack
          sx={{
            gap: '16px',
            flexDirection: 'column',
            backgroundColor: nethansaPalette.background.title,
            width: '100%',
            pl: 2,
            py: 1,
            fontSize: '13px',
            lineHeight: '18px',
          }}
        >
          <Typography variant="h5" sx={{ lineHeight: '18px', whiteSpace: 'pre-line' }}>
            {t('modals.editProductCost.description')}
          </Typography>

          <Stack
            sx={{
              gap: '1rem',
              flexDirection: 'row',
              alignItems: 'center',
              mb: '1rem',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: '700' }}>
              {t('modals.editProductCost.handlingTime-label')}
            </Typography>
            <TextFieldNumber
              value={actionParams?.row?.productCost || undefined}
              inputRef={productCostInputRef}
              helperText={validationMessage}
              disabled={isRemoveProductCost}
              unit={marketCurrency}
              onBlur={verifyValue}
              precision={2}
              error={validationMessage != null}
              sx={{
                width: '96px',
                '& > p': { marginLeft: 0, width: '250px', right: 'auto !important' },
              }}
            />
            <Checkbox
              label={t('modals.editProductCost.checkbox-label')}
              onChange={(_, value: boolean) => {
                setValidationMessage(null);
                setIsRemoveProductCost(value);
              }}
              sx={{
                '& + .MuiFormControlLabel-label': {
                  ml: '0 !important',
                  p: 0,
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </DialogConfirmation>
  );
};
