import { nethansaPalette } from 'theme';
import { alpha, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  disableCenterValign?: boolean;
};

export const datagridWrapperStyles: (props: Props) => SxProps<Theme> = (props) => ({
  borderInlineWidth: 0,
  fontSize: '13px',
  '& .MuiDataGrid-columnHeaders': {
    zIndex: 2,
    boxShadow: `0 3px 5px 0 ${nethansaPalette.line.dark}`,
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'nethansa.background.titleDark',
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      pr: 1,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer:hover': {
      pr: 0,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: '15.73px',
      letterSpacing: '0.5px',
    },
    '& .MuiDataGrid-columnSeparator': {
      color: 'nethansa.button.grayHover',
    },
    '& .MuiDataGrid-columnSeparator:hover': {
      color: 'nethansa.main.accent',
    },
  },
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    pr: 0,
  },
  '& .MuiDataGrid-row': {
    backgroundColor: 'common.white',
    '&:nth-of-type(odd)': {
      backgroundColor: 'common.white',
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'nethansa.background.title',
    },
    '&.Mui-hovered': {
      backgroundColor: alpha(nethansaPalette.background.select, 0.5),
    },
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: 'common.white',
    '& .MuiTablePagination-select': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'nethansa.button.grayLight',
    },
  },
  '& .mui-datagrid-cell-span > span': {
    color: 'nethansa.button.grayHover',
    mx: 1,
  },
  '& .MuiDataGrid-cell': {
    alignItems: props.disableCenterValign ? 'flex-start' : 'center',
  },
  '& .MuiDataGrid-cell > *': {
    mt: props.disableCenterValign ? 1.5 : 0,
  },
  '& .MuiDataGrid-detailPanel': {
    overflow: 'visible',
  },
  '& .MuiDataGrid-detailPanelToggleCell': {
    marginTop: '7px',
  },
});
