import { useQuery, UseQueryResult } from 'react-query';
import { ChannelCode } from 'generated/graphql';
import { PlatformType, ResponseAPIType } from 'modules/shared/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';
import { OfferType, ProductType } from '../types';
import { useOfferCoordinatorContext } from './use-offer-coordinator-context';

type EditAsinDataResponse = {
  product: ProductType;
  offers: OfferType[];
  targetAsin: string;
  platformCode: PlatformType;
  channelCode: ChannelCode;
  marketplaceCode: string;
};

export const useOfferASINQuery = (): UseQueryResult<EditAsinDataResponse> => {
  const { authorized, currentPartnerId } = useAuth();
  const {
    editAsin: [data],
  } = useOfferCoordinatorContext();

  const offerId = data?.offerId || '';

  return useQuery<EditAsinDataResponse>(
    ['offers', 'edit-asin', currentPartnerId, data?.offerId],
    async () => {
      const response = await httpClient.get<ResponseAPIType<EditAsinDataResponse>>(
        API.offers(currentPartnerId).offerAsin(encodeURIComponent(offerId)),
      );
      return response.data.data;
    },
    {
      staleTime: 30000,
      keepPreviousData: false,
      enabled: authorized,
      retry: 0,
    },
  );
};
