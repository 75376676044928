import { SharedOfferStateEnum, SharedOfferSubStateEnum } from 'generated/graphql';
import { OfferAction } from './types/offer-action';

export function CanExecuteAction(
  action: OfferAction,
  state: SharedOfferStateEnum,
  subStates: SharedOfferSubStateEnum[],
): boolean {
  switch (action) {
    case OfferAction.Pause:
      return canPause(state, subStates);
    case OfferAction.Activate:
      return canActivate(state, subStates);
    case OfferAction.Send:
      return canSend(state);
    case OfferAction.AsinEdit:
      return canEditAsin(state);
    case OfferAction.Analyze:
      return canAnalyze(state);
    case OfferAction.EditPrices:
      return canEditPrices(state);
    case OfferAction.ShowDetails:
      return canSeeDetails(state);
    case OfferAction.ChangeSku:
      return canChangeSku(state);
    case OfferAction.Delete:
      return canDelete(state);
    default:
      return false;
  }
}

const canSend = (state: SharedOfferStateEnum): boolean =>
  state === SharedOfferStateEnum.ReadyToSend || state === SharedOfferStateEnum.SendingError;

const canPause = (state: SharedOfferStateEnum, subStates: SharedOfferSubStateEnum[]): boolean =>
  (state == SharedOfferStateEnum.Incomplete &&
    subStates.some((subState) => subState !== SharedOfferSubStateEnum.SkuOnHold)) ||
  [
    SharedOfferStateEnum.ReadyToSend,
    SharedOfferStateEnum.SendingError,
    SharedOfferStateEnum.ListingError,
    SharedOfferStateEnum.OutOfStock,
    SharedOfferStateEnum.Active,
    SharedOfferStateEnum.Deleted,
  ].includes(state);

const canActivate = (state: SharedOfferStateEnum, subStates: SharedOfferSubStateEnum[]): boolean =>
  state == SharedOfferStateEnum.OnHold ||
  (state == SharedOfferStateEnum.Incomplete &&
    subStates.some((subState) => subState === SharedOfferSubStateEnum.SkuOnHold));

const canAnalyze = (state: SharedOfferStateEnum): boolean =>
  ![
    SharedOfferStateEnum.New,
    SharedOfferStateEnum.Processing,
    SharedOfferStateEnum.Deleted,
  ].includes(state);

const canEditPrices = (state: SharedOfferStateEnum): boolean =>
  [
    SharedOfferStateEnum.Incomplete,
    SharedOfferStateEnum.ReadyToSend,
    SharedOfferStateEnum.SendingError,
    SharedOfferStateEnum.ListingError,
    SharedOfferStateEnum.OutOfStock,
    SharedOfferStateEnum.OnHold,
    SharedOfferStateEnum.Active,
  ].includes(state);

const canSeeDetails = (state: SharedOfferStateEnum): boolean =>
  ![SharedOfferStateEnum.New, SharedOfferStateEnum.Deleted].includes(state);

const canChangeSku = (state: SharedOfferStateEnum): boolean =>
  ![
    SharedOfferStateEnum.New,
    SharedOfferStateEnum.Processing,
    SharedOfferStateEnum.Deleted,
  ].includes(state);

const canDelete = (state: SharedOfferStateEnum): boolean =>
  ![
    SharedOfferStateEnum.New,
    SharedOfferStateEnum.Processing,
    SharedOfferStateEnum.Deleted,
  ].includes(state);

const canEditAsin = (state: SharedOfferStateEnum): boolean =>
  ![
    SharedOfferStateEnum.New,
    SharedOfferStateEnum.Processing,
    SharedOfferStateEnum.Deleted,
  ].includes(state);
