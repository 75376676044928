import * as React from 'react';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import {
  gridFilteredTopLevelRowCountSelector,
  gridPaginationSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import DatagridPaginationActions from './datagrid-pagination-actions';

export const DatagridPagination = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(function GridPagination(props, ref) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const paginationState = useGridSelector(apiRef, gridPaginationSelector);
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);

  const rowCount = React.useMemo(
    () => rootProps.rowCount ?? visibleTopLevelRowCount ?? 0,
    [rootProps.rowCount, visibleTopLevelRowCount],
  );

  const lastPage = React.useMemo(
    () => Math.floor(rowCount / (paginationState.paginationModel.pageSize || 1)),
    [rowCount, paginationState.paginationModel.pageSize],
  );

  const handlePageSizeChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newPageSize = Number(event.target.value);
      apiRef.current.setPageSize(newPageSize);
    },
    [apiRef],
  );

  const handlePageChange = React.useCallback<TablePaginationProps['onPageChange']>(
    (event, page) => {
      apiRef.current.setPage(page);
    },
    [apiRef],
  );

  return (
    <TablePagination
      ref={ref}
      component="div"
      ActionsComponent={
        DatagridPaginationActions as unknown as React.ElementType<TablePaginationActionsProps>
      }
      count={rowCount}
      page={
        paginationState.paginationModel.page <= lastPage
          ? paginationState.paginationModel.page
          : lastPage
      }
      rowsPerPageOptions={
        rootProps.pageSizeOptions?.includes(paginationState.paginationModel.pageSize)
          ? rootProps.pageSizeOptions
          : []
      }
      rowsPerPage={paginationState.paginationModel.pageSize}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handlePageSizeChange}
      {...apiRef.current.getLocaleText('MuiTablePagination')}
      {...props}
    />
  );
});
