import { useEffect } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { graphqlClient } from 'common/graphql/graphql-client';
import {
  InputMaybe,
  OrderStatus,
  SaleRevenueProfitReportFilterInput,
  SaleRevenueProfitReportQuery,
  SaleRevenueProfitReportQueryVariables,
  SaleRevenueProfitReportSortInput,
  SortEnumType,
} from 'generated/graphql';
import { gql } from 'graphql-request';
import { useAuth } from 'modules/authorization';
import { useDatagridContext, useDatagridContextGraphqlTranslator } from 'modules/datagrid';
import { SaleRevenueProfitGridColDefCustom } from '../types';

export const getSaleRevenueProfitReport = gql`
  query SaleRevenueProfitReport(
    $partnerId: String!
    $first: Int!
    $after: String
    $sort: [SaleRevenueProfitReportSortInput!]
    $filter: SaleRevenueProfitReportFilterInput
  ) {
    saleRevenueProfitReport(
      partnerId: $partnerId
      first: $first
      after: $after
      where: $filter
      order: $sort
    ) {
      totalCount
      nodes {
        ...saleRevenueProfitReportNode
      }
      pageInfo {
        ...paginationMeta
      }
      edges {
        cursor
      }
    }
  }

  fragment saleRevenueProfitReportNode on SaleRevenueProfitReport {
    addCostNet
    baseCurrency
    category
    eAN
    effectiveMarkupProc
    fixedMinPrice
    fixedPrice
    fulfillment
    grandTotal
    grandTotalBase
    grandTotalEUR
    grandTotalTax
    itemAddProfitNet
    itemAddProfitNetBase
    itemAddProfitNetEUR
    itemProfitNet
    itemProfitNetBase
    itemProfitNetEUR
    lineItemId
    marketplace
    minMarkupPercent
    minPrice
    nethansaFee
    nethansaFeePercent
    orderCurrency
    orderDate
    orderHasDiscount
    otherTotal
    partnerOrderNumber
    platformChargebackFeeNet
    platformClosingFeeNet
    platformCommission
    platformCommissionPercent
    platformFeeFulfillmentNetValue
    platformFeeSource
    priceSettingsType
    productBaseCostNet
    productExternalId
    productName
    productTotal
    quantity
    returnsReserve
    returnsReservePercent
    shipCostNet
    shipCountry
    shipHandleCalcCostNet
    shippedDate
    shippingTotal
    status
    vcsActive
    orderId
  }

  fragment paginationMeta on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const useSaleRevenueProfitReportsQuery = (
  columns: SaleRevenueProfitGridColDefCustom[],
): UseQueryResult<SaleRevenueProfitReportQuery> => {
  const { currentPartnerId } = useAuth();
  const gridContext = useDatagridContext();
  const gridFilterSort = useDatagridContextGraphqlTranslator(columns, [
    'productName',
    'eAN',
    'category',
    'orderId',
  ]);

  const queryResult = useQuery<SaleRevenueProfitReportQuery>(
    [
      'reports',
      'sales-revenue-profit',
      currentPartnerId,
      gridFilterSort.first,
      gridFilterSort.after,
      gridFilterSort.sort,
      gridFilterSort.filter,
    ],
    async () => {
      const definedStatus = [OrderStatus.Unshipped, OrderStatus.Shipped, OrderStatus.Closed];
      const parsedFilter: InputMaybe<SaleRevenueProfitReportFilterInput> = {
        ...gridFilterSort.filter,
        or: definedStatus.map((x) => ({
          status: {
            eq: x,
          },
        })),
      };

      const parsedSort: InputMaybe<SaleRevenueProfitReportSortInput> = gridFilterSort.sort ?? {
        orderDate: SortEnumType.Desc,
      };

      const data = (await graphqlClient.request<SaleRevenueProfitReportQueryVariables>(
        getSaleRevenueProfitReport,
        {
          partnerId: String(currentPartnerId),
          first: gridFilterSort.first,
          after: gridFilterSort.after,
          sort: parsedSort,
          filter: parsedFilter,
        },
      )) as SaleRevenueProfitReportQuery;

      return {
        ...data,
        saleRevenueProfitReport: {
          ...data.saleRevenueProfitReport,
          nodes: data.saleRevenueProfitReport?.nodes?.map((x, index) => ({
            ...x,
            lineItemId: String(x.lineItemId) + index,
          })),
        },
      } as SaleRevenueProfitReportQuery;
    },
    {
      staleTime: 50000,
      keepPreviousData: false,
      retry: false,
    },
  );

  useEffect(() => {
    // Update rowCount to calculate current page
    gridContext.rowCount[1](queryResult.data?.saleRevenueProfitReport?.totalCount || 0);
  }, [queryResult.data?.saleRevenueProfitReport?.totalCount]);

  return queryResult;
};
