import { DialogClasses, DialogProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const dialogStyles: {
  defaultProps?: Partial<DialogProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof DialogClasses>> | undefined;
} = {
  defaultProps: {
    maxWidth: 'md',
    PaperProps: { elevation: 24 },
  },
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0,0,0,0.2)',
        backdropFilter: 'blur(2px)',
      },
      '& .MuiDialogContent-root': {
        minWidth: '800px',
        padding: 0,
      },
    },
  },
};
