import { useContext } from 'react';
import { SnackbarContext } from '../contexts';

export const useSnackbar = () => {
  const snackbarContext = useContext(SnackbarContext);

  return {
    pushSnackbar: snackbarContext.push,
  };
};
