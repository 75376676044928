import React from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from 'components';
import { NavigationTabsItem, NavigationTabsLayout } from 'components/navigation-tabs';
import { useGlobalSettingsQuery } from 'modules/settings-sales-global';
import { routes } from 'routes';

export const SettingsSalesLayout: React.FC = () => {
  const { t } = useTranslation('platforms');
  const globalSettingsQuery = useGlobalSettingsQuery();

  const isFetched = globalSettingsQuery.isFetched;
  const isWizardConfigured = globalSettingsQuery.data?.isConfigured || false;

  return (
    <Show when={isWizardConfigured && isFetched} fallback={<NavigationTabsLayout />}>
      <NavigationTabsLayout>
        <NavigationTabsItem
          href={routes.Settings.children.SalesIntelligence.children.Platforms.link()}
          exact
          children={t('tab.platforms')}
        />
        <NavigationTabsItem
          href={routes.Settings.children.SalesIntelligence.children.VatRates.link()}
          exact
          children={t('tab.vat-rates')}
        />
        <NavigationTabsItem
          href={routes.Settings.children.SalesIntelligence.children.GlobalSettings.link()}
          exact
          children={t('tab.global-settings')}
        />
      </NavigationTabsLayout>
    </Show>
  );
};
