import React, { useCallback } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select as MuiSelect,
  SelectChangeEvent,
  Stack,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { SpinnerIcon } from '../icons';
import { Tooltip } from '../tooltip';

type Props = {
  value?: string;
  onChange: (value?: string) => void;
  onBlur?: (value?: string) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  tooltipContent?: string;
  options: MenuItemProps[];
  disabled?: boolean;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  formControlSx?: SxProps<Theme>;
  darkMode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: React.Ref<any>;
};

export const SelectField: React.FC<Props> = (props) => {
  const handleOnChange = useCallback(
    (event: SelectChangeEvent) => {
      props.onChange(event.target?.value || '');
    },
    [props.onChange],
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!props.onBlur) return;
      props.onBlur(event.target?.value || '');
    },
    [props.onBlur],
  );

  const rootStyles: SxProps<Theme> = {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    m: 1,
    color: props.darkMode ? 'white' : 'nethansa.main.dark',
    '& .MuiInputBase-root': props.darkMode
      ? {
          '& .MuiSelect-select': {
            color: 'white',
            backgroundColor: 'nethansa.main.dark',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'nethansa.button.grayHover',
          },
          '& .MuiSvgIcon-root': {
            fill: 'white',
          },
        }
      : {},
    ...props.sx,
  };

  const labelStyles: SxProps<Theme> = {
    fontSize: '12px',
    fontWeight: 700,
    color: props.darkMode ? 'white' : 'nethansa.main.gray',
    textTransform: 'uppercase',
    letterSpacing: '0.22px',
    lineHeight: '16px',
    mb: 0.75,
    position: 'absolute',
    top: '-20px',
    left: 0,
  };

  const inputLabelStyles: SxProps<Theme> = {
    '&.MuiFormLabel-filled': { display: 'none' },
  };

  const helperTextStyles: SxProps<Theme> = {
    mx: 0,
    position: 'absolute',
    right: 0,
    top: '100%',
    textAlign: 'right',
    visibility: !props.helperText ? 'hidden' : 'visible',
  };

  return (
    <Box sx={rootStyles}>
      {props.label && (
        <Stack direction="row" alignItems="center" gap="8px" sx={labelStyles}>
          {props.label}
          {props.tooltipContent && <Tooltip content={props.tooltipContent} />}
        </Stack>
      )}
      <FormControl
        sx={{ m: 0, minWidth: 128, pt: 0, ...(props.formControlSx || {}) }}
        disabled={props.disabled}
      >
        <InputLabel shrink={false} className="MuiFormLabel-root" sx={inputLabelStyles}>
          {props.placeholder}
        </InputLabel>
        <Box sx={{ position: 'relative' }}>
          <MuiSelect
            inputRef={props.inputRef}
            disabled={props.disabled}
            value={props.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={props.error}
            sx={{ width: '100%' }}
          >
            {props.options.map(({ children, ...rest }, index) => (
              <MenuItem key={index} {...rest}>
                {children}
              </MenuItem>
            ))}
          </MuiSelect>
          {props.isLoading && (
            <Stack
              sx={{ ml: 2, position: 'absolute', right: '-46px', top: '-4px' }}
              alignItems="center"
              justifyContent="center"
            >
              <SpinnerIcon sx={{ fontSize: '43px' }} />
            </Stack>
          )}
        </Box>
        <FormHelperText error={props.error} sx={helperTextStyles}>
          {props.helperText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
