import { GridFilterItem } from '@mui/x-data-grid-pro';

export const OFFERS_FILTER_STORAGE_KEY = 'offersFilter';

export const useFiltersFromStorageUtils = () => {
  const getSessionStorageValue = () => {
    const offersFilterStorageValue = window.sessionStorage.getItem(OFFERS_FILTER_STORAGE_KEY);

    if (!offersFilterStorageValue) return undefined;

    try {
      const offersFilter: GridFilterItem[] = JSON.parse(offersFilterStorageValue || '[]');
      return offersFilter;
    } catch (error) {
      console.error(error);
    }
  };

  const cleanSessionStorageValue = () => {
    window.sessionStorage.removeItem(OFFERS_FILTER_STORAGE_KEY);
  };

  return {
    getOffersFilter: getSessionStorageValue,
    cleanSessionStorageValue,
  };
};
