import React from 'react';

type PlatformsConnectorContextType = {
  close: () => void;
  step: number;
  setStep: (step: number) => void;
  keepCloseButton: boolean;
};

export const PlatformsConnectorContext = React.createContext<PlatformsConnectorContextType>({
  close: () => null,
  step: 0,
  setStep: () => null,
  keepCloseButton: false,
});
