import { useMutation, UseMutationResult } from 'react-query';
import { API, httpClient } from '../../../../common/api';
import { ResponseAPIDataStatusErrorType, ResponseAPIDataStatusType } from '../../../shared/types';

export const useViewDeleteMutation = (): UseMutationResult<
  ResponseAPIDataStatusType,
  ResponseAPIDataStatusErrorType,
  string
> => {
  return useMutation(async (id: string) => {
    const response = await httpClient.delete(API.views().viewDelete(id));
    return response.data;
  });
};
