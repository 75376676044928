import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const CarrierIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="54"
      height="54"
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0H54V54H0z"
                transform="translate(-744 -410) translate(731 352) translate(13 58)"
              />
              <g fill="#000" fillRule="nonzero">
                <path
                  d="M24.264.025c.828 0 1.5.672 1.5 1.5v1.712h2.76c2.761 0 5 2.239 5 5v4.948h2.053c2.56.003 4.633 2.077 4.636 4.636v9.669c0 .828-.672 1.5-1.5 1.5l-2.128.001c-.671 2.633-3.057 4.581-5.899 4.584-2.462 0-4.682-1.483-5.624-3.758-.112-.271-.204-.547-.275-.826h-7.566c-.671 2.633-3.057 4.581-5.899 4.584-2.462 0-4.682-1.483-5.624-3.758-.112-.271-.204-.547-.275-.826l-3.91-.001c-.828 0-1.5-.672-1.5-1.5V15.999h2.999l.001 9.991h2.41c.263-1.04.803-2.014 1.595-2.807 1.741-1.74 4.36-2.26 6.634-1.318 1.8.746 3.105 2.292 3.57 4.125h5.542V3.025l-6.765-.001V.025h8.265zM11.322 24.4v.002c-1.696 0-3.073 1.368-3.087 3.062.002.008.002.017.002.026l-.001.026.004.154c.094 1.62 1.438 2.906 3.082 2.906 1.705 0 3.087-1.382 3.087-3.087 0-1.705-1.382-3.087-3.087-3.089zm19.364 0v.002c-1.7 0-3.08 1.376-3.087 3.075l.001.013-.001.012.005.168c.094 1.62 1.438 2.906 3.082 2.906 1.705 0 3.087-1.382 3.087-3.087 0-1.705-1.382-3.087-3.087-3.089zm4.891-8.215h-9.813v7.722c.184-.254.391-.496.618-.724 1.741-1.74 4.36-2.26 6.634-1.318 1.8.746 3.105 2.292 3.57 4.125h.627V21.1H36.7c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h.513v-.279c-.001-.903-.733-1.635-1.636-1.636zm-7.053-9.948h-2.76v6.948h4.76V8.237c0-1.105-.895-2-2-2z"
                  transform="translate(-744 -410) translate(731 352) translate(13 58) translate(7 10)"
                />
                <path
                  d="M7.961 0L7.961 13.122 5.161 13.122 5.161 0z"
                  transform="translate(-744 -410) translate(731 352) translate(13 58) translate(7 10)"
                />
                <path
                  d="M13.122 5.161L13.122 7.961 0 7.961 0 5.161z"
                  transform="translate(-744 -410) translate(731 352) translate(13 58) translate(7 10)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
