import { useQuery, UseQueryResult } from 'react-query';
import { PaymentMethodsResponseInterface } from '@adyen/adyen-web/dist/types/types';
import { API, httpClient } from '../../../common/api';
import { useAuth } from '../../authorization';

type InputType = {
  countryCode: 'PL'; //country of available payment methods
};

export const useAdyenPaymentMethodsQuery = (): UseQueryResult<PaymentMethodsResponseInterface> => {
  const { authorized, currentPartnerId } = useAuth();
  const body: InputType = { countryCode: 'PL' };

  return useQuery<PaymentMethodsResponseInterface>(
    ['settings', 'payment-methods', currentPartnerId],
    async () => {
      const response = await httpClient.post<PaymentMethodsResponseInterface>(
        API.payments().adyenPaymentMethods(),
        body,
      );
      return response.data;
    },

    {
      staleTime: 20000,
      keepPreviousData: true,
      enabled: authorized,
    },
  );
};
