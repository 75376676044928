import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { parseDateAndFixIso } from '../../../common/format/date';

export type DateEnumOperatorType =
  | 'lastWeek'
  | 'lastMonth'
  | 'lastYear'
  | 'currentWeek'
  | 'currentMonth'
  | 'currentYear';

export const getDateRanges: () => {
  [key in DateEnumOperatorType]: [Date] | [Date, Date];
} = () => {
  const date = new Date();

  return {
    lastWeek: [
      parseDateAndFixIso(startOfDay(startOfWeek(subWeeks(date, 1), { weekStartsOn: 1 }))),
      parseDateAndFixIso(endOfDay(endOfWeek(subWeeks(date, 1), { weekStartsOn: 1 }))),
    ],
    lastMonth: [
      parseDateAndFixIso(startOfDay(startOfMonth(subMonths(date, 1)))),
      parseDateAndFixIso(endOfDay(endOfMonth(subMonths(date, 1)))),
    ],
    lastYear: [
      parseDateAndFixIso(startOfDay(startOfYear(subYears(date, 1)))),
      parseDateAndFixIso(endOfDay(endOfYear(subYears(date, 1)))),
    ],
    currentWeek: [parseDateAndFixIso(startOfDay(startOfWeek(date)))],
    currentMonth: [parseDateAndFixIso(startOfDay(startOfMonth(date)))],
    currentYear: [parseDateAndFixIso(startOfDay(startOfYear(date)))],
  };
};
