import { useMutation, UseMutationResult } from 'react-query';
import { API, envelope, httpClient } from 'common/api';
import { settingsDefaultMutationKeys } from '../../../common/query-client';
import { useAuth } from '../../authorization';
import { ErrorMeta } from '../../shared/types';

type InputType = {
  carrierId: number;
  userName: string;
  password: string;
  partnerCarrierId: string;
  clientId?: string;
  licenseNumber?: string;
};

export const useCarriersConnectMutation = (): UseMutationResult<void, ErrorMeta, InputType> => {
  const { currentPartnerId } = useAuth();

  return useMutation([...settingsDefaultMutationKeys], async (body) => {
    if (!body.partnerCarrierId) {
      return await envelope(httpClient.post(API.partnerCarrier(currentPartnerId).carriers(), body));
    }

    return await envelope(
      httpClient.put(
        API.partnerCarrier(currentPartnerId).singleCarrier(body.partnerCarrierId),
        body,
      ),
    );
  });
};
