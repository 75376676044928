import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DetailedActionResponseOfOfferErrorCode,
  KeyValuePairOfOfferErrorCodeAndInt32,
} from 'generated/graphql';
import { Box, Stack, Typography } from '@mui/material';
import { BigFailIcon } from '../../../components/icons/big-fail.icon';
import { BigSuccessIcon } from '../../../components/icons/big-success.icon';
import { nethansaPalette } from '../../../theme';

export interface Props {
  data: DetailedActionResponseOfOfferErrorCode;
}

export const BulkActionSummary: React.FC<Props> = (props) => {
  const [t] = useTranslation('offers');

  const numberErrors =
    props.data.errors?.map((item) => item.value).reduce((prev, next) => prev + next, 0) ?? 0;

  return (
    <Stack
      sx={{
        gap: '8px',
        backgroundColor: nethansaPalette.background.title,
        padding: '16px',
        width: '100%',
      }}
    >
      <Typography variant="h2">{t('bulk-action-summary.title')}</Typography>
      <Stack sx={{ gap: '10px', flexDirection: 'row' }}>
        <BigSuccessIcon />
        <Typography variant="h5" sx={{ lineHeight: '18px' }}>
          {t('bulk-action-summary.successfully-processed')}:{' '}
          <b>
            {t('actions.generic-labels.offers', { count: props.data.numberOfSucceededOperations })}
          </b>
        </Typography>
      </Stack>
      <Stack sx={{ gap: '10px', flexDirection: 'row' }}>
        <BigFailIcon />
        <Typography variant="h5" sx={{ lineHeight: '18px' }}>
          {t('bulk-action-summary.processing-problems')}:{' '}
          <b>{t('actions.generic-labels.offers', { count: numberErrors })}</b>
        </Typography>
      </Stack>

      {props.data.errors?.map((error: KeyValuePairOfOfferErrorCodeAndInt32) => (
        <Stack sx={{ gap: '10px', flexDirection: 'row', ml: '30px' }}>
          <Box
            sx={{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              backgroundColor: nethansaPalette.main.watermelon,
              mt: '6px',
            }}
          />
          <Typography variant="h5" sx={{ lineHeight: '18px' }}>
            {t('actions.generic-labels.offers', { count: error.value })}:{' '}
            {t(`errors.${error.key}` as any)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
