import React from 'react';
import { Box, Paper, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = React.PropsWithChildren<{
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}>;

export const CardForm: React.FC<Props> = (props) => {
  const rootStyles: SxProps<Theme> = { p: 3, ...(props.sx || {}) };

  const paperStyles: SxProps<Theme> = {
    p: 3,
    width: '100%',
    maxWidth: props.fullWidth ? 'unset' : '448px',
  };

  return (
    <Box sx={rootStyles}>
      <Paper sx={paperStyles}>{props.children}</Paper>
    </Box>
  );
};
