import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  GridFooterContainer,
  GridFooterContainerProps,
  GridRowCount,
  gridTopLevelRowCountSelector,
  selectedGridRowsCountSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { useCommonTranslations } from '../../../shared';
import { useDatagridContext } from '../../hooks';
import { BulkActionsProps } from '../../types';
import { DatagridFooterBulkActions } from './components/datagrid-footer-bulk-actions';

const darkThemeFooter: SxProps<Theme> = {
  '&.MuiDataGrid-footerContainer': {
    backgroundColor: 'nethansa.main.accent',
    color: 'common.white',
    '& .MuiTablePagination-root': {
      color: 'common.white',
      overflowX: 'hidden',
      '& .MuiInputBase-root': {
        backgroundColor: 'nethansa.main.dark',
        color: 'common.white',
        borderColor: 'nethansa.main.gray',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '6px',
        '&.Mui-disabled': {
          color: 'common.white',
          borderWidth: 0,
          input: {
            borderWidth: '0',
            backgroundColor: 'nethansa.main.dark',
          },
        },
        '& .MuiSvgIcon-root': {
          fill: '#ffffff',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiSelect-select': {
        backgroundColor: 'nethansa.main.dark',
        borderRadius: '6px',
        borderWidth: '0',
      },
      '& .MuiIconButton-root': {
        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.25)',
        },
      },
    },
  },
};

type GridFooterContainerExtendedWithBulkActionsProps<TBulkType extends string = string> =
  GridFooterContainerProps &
    BulkActionsProps<TBulkType> & { entitiesType?: 'offers' | 'products'; totalAllCount?: number };

export const DatagridFooter = React.forwardRef<
  HTMLDivElement,
  GridFooterContainerExtendedWithBulkActionsProps
>(function GridFooter(props, ref) {
  const { tCommon } = useCommonTranslations();
  const { handleBulkAction, bulkActions, entitiesType, totalAllCount, ...restProps } = props;

  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const {
    rowCount: [datagridRowCount],
  } = useDatagridContext();
  const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector);
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridTopLevelRowCountSelector);

  const hasAnySelectedRows = selectedRowCount > 0;

  const handleBulkActions = !!bulkActions?.length;

  const bulkActionsElement = hasAnySelectedRows ? (
    handleBulkActions ? (
      <DatagridFooterBulkActions
        handleBulkAction={handleBulkAction}
        bulkActions={bulkActions}
        entitiesType={entitiesType}
      />
    ) : (
      <Typography variant="h3" sx={{ mx: 4 }}>
        {tCommon('datagrid.footer.selected', { count: selectedRowCount })}
      </Typography>
    )
  ) : (
    <div />
  );

  const totalCount =
    !hasAnySelectedRows && totalAllCount ? (
      <Box sx={{ ml: 3 }}>
        <Trans
          t={tCommon}
          i18nKey="total-count-of"
          values={{ count: datagridRowCount ?? 0, total: totalAllCount }}
        >
          Total count: <strong>number</strong> of number
        </Trans>
      </Box>
    ) : null;

  const rowCountElement =
    !rootProps.hideFooterRowCount && !rootProps.pagination ? (
      <GridRowCount rowCount={datagridRowCount} visibleRowCount={visibleTopLevelRowCount} />
    ) : null;

  const paginationElement = rootProps.pagination &&
    !rootProps.hideFooterPagination &&
    rootProps.slots.pagination && <rootProps.slots.pagination {...rootProps.slots?.pagination} />;

  return (
    <GridFooterContainer
      ref={ref}
      {...restProps}
      sx={hasAnySelectedRows && handleBulkActions ? darkThemeFooter : {}}
      id="test"
    >
      {totalCount}
      {bulkActionsElement}
      {rowCountElement}
      {paginationElement}
    </GridFooterContainer>
  );
});
