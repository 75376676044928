import React from 'react';
import { SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const ArrowsNochangeIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      sx={props.sx}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5929 8.29289C17.9834 7.90237 18.6166 7.90237 19.0071 8.29289L21.1284 10.4142L21.1249 10.4178L22.3536 11.6465C22.5488 11.8417 22.5488 12.1583 22.3536 12.3536L21.1249 13.5822L21.1284 13.5858L19.0071 15.7071C18.6166 16.0976 17.9834 16.0976 17.5929 15.7071C17.2024 15.3166 17.2024 14.6834 17.5929 14.2929L18.8858 13H5.11421L6.40711 14.2929C6.79763 14.6834 6.79763 15.3166 6.40711 15.7071C6.01658 16.0976 5.38342 16.0976 4.99289 15.7071L1.99289 12.7071L1.99644 12.7036L1.64645 12.3536C1.45118 12.1583 1.45118 11.8417 1.64645 11.6465L1.99645 11.2964L1.99289 11.2929L4.99289 8.29289C5.38342 7.90237 6.01658 7.90237 6.40711 8.29289C6.79763 8.68342 6.79763 9.31658 6.40711 9.70711L5.11421 11H18.8858L17.5929 9.70711C17.2024 9.31658 17.2024 8.68342 17.5929 8.29289Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
