import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Show, TextFieldPassword } from 'components';
import { Stack, TextField } from '@mui/material';
import { CarrierName } from '../../../../../shared/types';
import { useSnackbar } from '../../../../../snackbar';
import {
  useCarrierData,
  useCarriersConnectMutation,
  useCarriersShippingCountriesQuery,
  useIntegrationContext,
} from '../../../../hooks';
import { IntegrationGenericModal } from './components/integration-generic-modal';

type FormProps = {
  login: string;
  password: string;
  clientId?: string;
  licenseNumber?: string;
};

type Props = {
  carrierName: CarrierName;
};

export const ModalCarrierForm: React.FC<Props> = (props) => {
  const [t] = useTranslation('settings');
  const carriersConnectMutation = useCarriersConnectMutation();
  const carriersShippingCountriesQuery = useCarriersShippingCountriesQuery();
  const { data, refetch } = useCarrierData(props.carrierName);
  const { pushSnackbar } = useSnackbar();

  const {
    connector: [, setConnector],
  } = useIntegrationContext();

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting },
  } = useForm<FormProps>({
    mode: 'onBlur',
    defaultValues: {
      clientId: data?.clientIdString || '',
      login: data?.userName || '',
      password: '',
      licenseNumber: '',
    },
  });

  const submit = (body: FormProps) => {
    const carrierId = data?.carrierId || 0;

    return carriersConnectMutation.mutate(
      {
        carrierId: carrierId,
        clientId: body.clientId,
        userName: body.login,
        password: body.password,
        licenseNumber: body.licenseNumber,
        partnerCarrierId: String(data?.id || ''),
      },
      {
        onSuccess: () => {
          refetch();
          carriersShippingCountriesQuery.refetch();
          setConnector(undefined);
          pushSnackbar({ variant: 'success', message: t('carriers.connect.success') });
          reset(body);
        },
        onError: (error) => {
          Object.entries(error.errors || {}).forEach(([key, value]) => {
            setError(key as 'password', {
              type: 'validate',
              message: t(
                `carriers.connect.error.${value[0]}` as 'carriers.connect.error.testConnectionsProvidedLoginOrPasswordIsIncorrect',
              ),
            });
          });
        },
      },
    );
  };

  const nameDictionary: { [key in CarrierName]: string } = {
    DHL: 'DHL',
    UPS: 'UPS',
    GLS: 'GLS',
    DPD: 'DPD Poland',
    'EH DHL': 'DHL',
    'EH COLSYG': 'COLSYG',
    'EH RML48T': 'RML48T',
  };

  const imageByCarrierName: { [key in CarrierName]: string } = {
    DHL: '/images/carriers/logo-carrier-dhl.svg',
    UPS: '/images/carriers/logo-carrier-ups.svg',
    GLS: '/images/carriers/logo-carrier-gls.svg',
    DPD: '/images/carriers/logo-carrier-dpd.svg',
    'EH COLSYG': '/images/carriers/logo-carrier-eurohermes.svg',
    'EH DHL': '/images/carriers/logo-carrier-eurohermes.svg',
    'EH RML48T': '/images/carriers/logo-carrier-eurohermes.svg',
  };

  const hasClientId = ['DHL', 'DPD', 'UPS'].some((x) => x === props.carrierName);

  const hasLicenseNumberRequired = ['UPS'].some((x) => x === props.carrierName);

  return (
    <IntegrationGenericModal
      open
      loading={carriersConnectMutation.isLoading}
      onClose={() => setConnector(undefined)}
      submit={handleSubmit(submit)}
      header={t('carriers.connect.modal-title', { carrierName: nameDictionary[props.carrierName] })}
      logoElement={<img alt="carrier-logo" src={imageByCarrierName[props.carrierName]} />}
    >
      <Stack gap="30px">
        <Show when={hasClientId}>
          <Controller
            control={control}
            name="clientId"
            rules={{
              required: {
                value: true,
                message: t('form.carrierClientId.validation.required', {
                  carrier: props.carrierName,
                }),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  name="carrier-client-id"
                  id="carrier-client-id"
                  autoComplete="new-carrier-client-id"
                  aria-autocomplete="none"
                  disabled={isSubmitting}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label={t('form.carrierClientId.label')}
                  placeholder={t('form.carrierClientId.placeholder', {
                    carrier: props.carrierName,
                  })}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 2, flex: 1 }}
                />
              );
            }}
          />
        </Show>
        <Controller
          control={control}
          name="login"
          rules={{
            required: {
              value: true,
              message: t('form.carrierLogin.validation.required', { carrier: props.carrierName }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                name="carrier-login"
                id="carrier-login"
                autoComplete="new-carrier-login"
                aria-autocomplete="none"
                disabled={isSubmitting}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.carrierLogin.label')}
                placeholder={t('form.carrierLogin.placeholder', { carrier: props.carrierName })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
        <Show when={hasLicenseNumberRequired}>
          <Controller
            control={control}
            name="licenseNumber"
            rules={{
              required: {
                value: true,
                message: t('form.licenseNumber.validation.required'),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  name="carrier-license-number"
                  id="carrier-license-number"
                  autoComplete="new-carrier-license-number"
                  aria-autocomplete="none"
                  disabled={isSubmitting}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label={t('form.licenseNumber.label')}
                  placeholder={t('form.licenseNumber.placeholder', {
                    carrier: props.carrierName,
                  })}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 2, flex: 1 }}
                />
              );
            }}
          />
        </Show>
        <Controller
          control={control}
          name="password"
          rules={{
            required: {
              value: true,
              message: t('form.carrierPassword.validation.required', {
                carrier: props.carrierName,
              }),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <TextFieldPassword
                name="carrier-password"
                id="carrier-password"
                autoComplete="new-password"
                disabled={isSubmitting}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={t('form.carrierPassword.label')}
                placeholder={t('form.carrierPassword.placeholder', { carrier: props.carrierName })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, flex: 1 }}
              />
            );
          }}
        />
      </Stack>
    </IntegrationGenericModal>
  );
};
