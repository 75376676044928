import { AxiosRequestHeaders } from 'axios';
import { RequestDocument } from 'graphql-request';
import { environment } from '../../environment';
import { httpClient } from '../api';

export const graphqlClient = {
  request: async <Variables>(
    document: RequestDocument,
    variables?: Variables,
    headers?: AxiosRequestHeaders,
  ) => {
    const response = await httpClient.post(
      environment.GRAPHQL_URL,
      {
        query: document,
        variables: variables,
      },
      {
        headers,
      },
    );
    return response?.data?.data || {};
  },
};
