import { useTranslation } from 'react-i18next';
import { ProductGridRow } from 'modules/products/types';
import { nethansaPalette } from 'theme';
import { Box, Stack, Typography } from '@mui/material';

export interface Props {
  row?: ProductGridRow;
}

export const SelectedProductDetails: React.FC<Props> = (props) => {
  const [t] = useTranslation('products');
  return (
    <Stack
      sx={{
        gap: '40px',
        flexDirection: 'row',
        backgroundColor: nethansaPalette.background.title,
        width: '100%',
        pl: 2,
        py: 1,
        fontSize: '13px',
        lineHeight: '18px',
      }}
    >
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Typography
          variant="h5"
          sx={{ lineHeight: '18px', '& > span': { color: nethansaPalette.main.gray } }}
        >
          <span>{t('column.externalId.label')}: </span>
          {props.row?.externalId}
        </Typography>
        <Typography
          variant="h5"
          sx={{ lineHeight: '18px', '& > span': { color: nethansaPalette.main.gray } }}
        >
          <span>{t('column.ean.label')}: </span>
          {props.row?.ean}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ lineHeight: '18px' }}>
          {props.row?.name}
        </Typography>
      </Box>
    </Stack>
  );
};
