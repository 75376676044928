import { FormControlLabelClasses, FormControlLabelProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const formControlLabelStyles: {
  defaultProps?: Partial<FormControlLabelProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof FormControlLabelClasses>> | undefined;
} = {
  defaultProps: {},
  styleOverrides: {
    root: {
      marginLeft: 0,
      '&.MuiFormControlLabel-labelPlacementEnd': {
        '& .MuiFormControlLabel-label': {
          marginLeft: '16px',
        },
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '13px',
      },
    },
  },
};
