import React from 'react';
import { emptyArray } from 'common/utils';
import { CardForm } from 'components';
import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { tableSkeletonStyles } from './components';

export const PlatformSkeleton: React.FC = () => {
  return (
    <>
      <CardForm fullWidth sx={{ maxWidth: '1186px' }}>
        <Skeleton height="200px" />
      </CardForm>
      <CardForm fullWidth sx={{ pt: 0, maxWidth: '1186px' }}>
        <Skeleton height="16px" width="120px" sx={{ mb: 4 }} />
        <Box sx={{ px: 2 }}>
          <Box sx={{ display: 'block' }}>
            <form>
              <Table sx={{ ...tableSkeletonStyles, width: '100%' }}>
                <TableHead>
                  <TableRow>
                    {emptyArray(6).map((v, i) => (
                      <TableCell key={i}>
                        <Skeleton height="32px" />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emptyArray(12).map((value, index) => (
                    <TableRow key={index}>
                      {emptyArray(6).map((v, i) => (
                        <TableCell key={i}>
                          <Skeleton height="34px" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </form>
          </Box>
        </Box>
      </CardForm>
    </>
  );
};
